import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <span *ngIf="value === 'true';else otherwise" style="color: green">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
    </span>
    <ng-template #otherwise><span style="color: red"><i class="fa fa-times-circle" aria-hidden="true"></i></span></ng-template>
  `,
})
export class UserEmailConfirmedRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;
}
