import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  styleUrls: ['./confirm.component.scss'],
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent {
  title = 'Confirmation';
  body = 'Are you sure?';
  confirm = 'Confirm';
  cancel = 'Cancel';

  constructor(public modal: NgbActiveModal) {}
}
