import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { Router } from '@angular/router';
import { SubjectService } from '../../../shared/subject.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  active = 1;

  constructor(
    private router: Router,
    private subjectService: SubjectService,
    private apiService: ApiService,
    private formBuilder: FormBuilder
  ) {
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      Email: [''],
      Password: [''],
      Remember: [true],
    });
  }

  ngOnInit() {}

  onSubmit() {
    const those = this;
    const raw = this.loginForm.getRawValue();
    if (!raw.Email) {
      raw.Email = (document.getElementById('email') as HTMLInputElement).value;
    }
    if (!raw.Password) {
      raw.Password = (document.getElementById('password') as HTMLInputElement).value;
    }
    this.apiService
      .login(raw, true)
      .then(obj => {
        if (obj.MESSAGE === 'OK') {
          those.apiService
            .getInfo()
            .then(info => {
              those.subjectService.infoSubject.next(info);
              return those.router.navigate(['/']);
            })
            .catch(err => {
              console.log('err', err);
            });
        }
      })
      .catch(err => {
        const obj = err.error;
        if (obj && obj.ERROR) {
          alert(obj.ERROR);
        } else {
          alert('Something went wrong');
        }
      });
  }
}
