import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {VariationsComponent} from './variations.component';
import {VariationComponent} from './variation/variation.component';
import {CanDeactivateGuard} from '../../shared/guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: VariationsComponent,
    data: {
      title: 'Variations',
      breadcrumb: 'Variations'
    }
  },
  {
    path: ':id',
    component: VariationComponent,
    data: {
      title: 'Variation',
      breadcrumb: 'Variation'
    },
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VariationsRoutingModule { }
