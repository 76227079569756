<div class="thumbnail-input">
  <app-media-library [multiple]="false" (onSelected)="onInsertMedia($event)" #mediaLibrary></app-media-library>
  <div
    *ngIf="thumbnail && !queued"
    style="display: flex; align-items: center; justify-items: center;padding-right: 5px;">
    <a [href]="absolutizeUrl.transform('/storage' + thumbnail)" target="_blank"><img
      [src]="absolutizeUrl.transform('/api/v1/resize?path=/storage' + thumbnail + '&updated=' + updated)"
      style="max-height: 44px"></a>
    <div class="body-4-regular" style="padding: 0 5px 0 5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
      {{filename(thumbnail)}}
    </div>
    <div style="cursor: pointer; display: flex; margin-left: auto"
         (click)="change('')">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#D0D4E4"/>
        <rect x="7.87891" y="6.46458" width="8" height="2" transform="rotate(45 7.87891 6.46458)" fill="white"/>
        <rect x="13.5356" y="7.8788" width="8" height="2" transform="rotate(135 13.5356 7.8788)" fill="white"/>
      </svg>
    </div>
  </div>
  <div *ngIf="!thumbnail || queued" class="drop-area" [ngClass]="{'drop-area-preview': previews && previews.length > 0, 'drop-area-empty': !previews || previews.length == 0}">
    <div *ngIf="files && files.length > 0" class="gallery">
      <div *ngFor="let preview of previews" class="gallery-item">
        <img [src]="preview.src" class="gallery__image" [alt]="preview.name" />
        <div class="gallery__label">
          <div class="body-4-regular">{{preview.name}}</div>
          <div *ngIf="progress" class="progress gallery__progress">
            <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="gallery__remove" style="cursor: pointer;" (click)="reset()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#D0D4E4"/>
            <rect x="7.87891" y="6.46458" width="8" height="2" transform="rotate(45 7.87891 6.46458)" fill="white"/>
            <rect x="13.5356" y="7.8788" width="8" height="2" transform="rotate(135 13.5356 7.8788)" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
    <div
      class="file-loader__wrapper"
      [ngClass]="{ 'drop-area__active': isDraggedOver }"
      (drop)="handleDrop($event)"
      (dragover)="handleDragOver($event)"
      (dragenter)="handleDragEnter()"
      (dragleave)="handleDragLeave($event)"
    >
      <div class="file-loader__container">
        <div class="file-loader__icon" (click)="file.click()">
          <svg width="38" height="25" viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.6562 12.0549C32.7527 7.32938 28.1586 3.73887 23.4977 5.11869C22.0801 2.11424 19.0371 0 15.4375 0C10.6355 0 6.70937 3.81306 6.53867 8.57567C2.74609 9.39911 0 12.7671 0 16.6914C0 21.276 3.72578 25 8.3125 25H17.0555V17.8042H14.9551C13.9605 17.8042 13.4039 16.6543 14.0199 15.8754L18.0723 10.7196C18.5473 10.1113 19.4676 10.1113 19.95 10.7196L24.0023 15.8754C24.6184 16.6543 24.0617 17.8042 23.0672 17.8042H20.9668V25H31.4688C35.0684 25 38 22.0697 38 18.4718C38 15.2745 35.6918 12.6113 32.6562 12.0549Z" fill="#5C22CF"/>
          </svg>
        </div>
        <div class="file-loader__message">
          <div class="file-loader__message-1">
            <div class="body-7-medium" style="text-align: left;">
              <span [translate]>Drag or</span><span class="color-primary-violet" style="cursor: pointer; padding: 0 3px;" (click)="onOpenMedia(mediaLibrary, 'images', $event)" [translate]>browse media library</span> <span [translate]>to start uploading</span>
            </div>
          </div>
          <div class="file-loader__message-2" style="width: 100%">
            <div class="body-5" style="padding-right: 45px; text-align: center; width: 100%;">
              Drop file
            </div>
          </div>
        </div>
      </div>
      <div class="file-loader">
        <input
          class="file-loader__input"
          (change)="onFileChange($event)"
          type="file"
          accept="image/png, image/jpeg"
          #file
        />
      </div>
    </div>
  </div>
</div>
