import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { SubjectService } from '../../../shared/subject.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-property',
  styles: [
    `
      .d-block {
        padding-right: 20px;
      }
      .radio_animated {
        margin-right: 5px;
      }
      .actions {
        min-width: 75px;
      }
      .delete {
        color: red;
      }
    `,
  ],
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!property || !property['ID']" class="modal-title" id="exampleModalLabel" translate>Add Property</h5>
        <h5 *ngIf="property && property['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Property</h5>
        <div style="float: right">
          <button
            type="button"
            class="close"
            style="float: initial; margin-right: 0.25rem; padding-right: 0.25rem; outline: none;"
            aria-label="Toggle"
            (click)="toggle()">
            <span *ngIf="fullSize" aria-hidden="true" style="font-size: 0.75rem;vertical-align: middle;"
              ><i class="fa fa-window-minimize" aria-hidden="true"></i
            ></span>
            <span *ngIf="!fullSize" aria-hidden="true" style="font-size: 0.75rem;vertical-align: middle;"
              ><i class="fa fa-window-maximize" aria-hidden="true"></i
            ></span>
          </button>
          <button
            type="button"
            class="close"
            style="float: initial; margin-left: 0.25rem; padding-left: 0.25rem; outline: none;"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="form">
          <div class="form-group">
            <div class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="mb-1" style="margin-right: 5px;padding-right: 5px;" translate>Type</label>
              <label class="d-block" for="select">
                <input class="radio_animated" id="select" type="radio" value="select" [(ngModel)]="property['Type']" />
                {{ 'Select' | translate }}
              </label>
              <label class="d-block" for="rectangle">
                <input
                  class="radio_animated"
                  id="rectangle"
                  type="radio"
                  value="rectangle"
                  [(ngModel)]="property['Type']" />
                {{ 'Rectangle' | translate }}
              </label>
              <label class="d-block" for="swatch">
                <input class="radio_animated" id="swatch" type="radio" value="swatch" [(ngModel)]="property['Type']" />
                {{ 'Swatch' | translate }}
              </label>
              <label class="d-block" for="radio">
                <input class="radio_animated" id="radio" type="radio" value="radio" [(ngModel)]="property['Type']" />
                {{ 'Radio' | translate }}
              </label>
            </div>
          </div>
          <div *ngIf="property['Type'] == 'radio' || property['Type'] == 'swatch'" class="form-group">
            <div class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="mb-1" style="margin-right: 5px;padding-right: 15px;" translate>Size</label>
              <label class="d-block" for="small">
                <input class="radio_animated" id="small" type="radio" value="small" [(ngModel)]="property['Size']" />
                {{ 'Small' | translate }}
              </label>
              <label class="d-block" for="medium">
                <input class="radio_animated" id="medium" type="radio" value="medium" [(ngModel)]="property['Size']" />
                {{ 'Medium' | translate }}
              </label>
              <label class="d-block" for="large">
                <input class="radio_animated" id="large" type="radio" value="large" [(ngModel)]="property['Size']" />
                {{ 'Large' | translate }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="options" class="mb-1" translate>Option</label>
                <div *ngIf="!property || !property['ID']" style="float: right;">
                  <app-edit-option (onSaved)="refresh()" #optionEditor>
                    <button
                      type="button"
                      class="btn btn-primary btn-xs"
                      data-toggle="modal"
                      data-original-title="add option"
                      data-target="#exampleModal"
                      (click)="
                        optionEditor.onCreate(optionEditor.content, { backdropClass: 'backdropClassTransparent' })
                      "
                      translate>
                      Add Option
                    </button>
                  </app-edit-option>
                </div>
                <div>
                  <ngx-bootstrap-multiselect
                    *ngIf="!property['ID']"
                    id="options"
                    class="multiselect"
                    [options]="options"
                    [settings]="settings"
                    [(ngModel)]="option"
                    (ngModelChange)="switch()"></ngx-bootstrap-multiselect>
                  <input
                    *ngIf="property['ID']"
                    type="text"
                    class="form-control"
                    [value]="property['Option']['Title']"
                    disabled />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="title" class="mb-1" translate>Title</label>
                <input
                  class="form-control"
                  id="title"
                  type="text"
                  (change)="change($event)"
                  [(ngModel)]="property['Title']" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="name" class="mb-1" translate>Name</label>
                <input class="form-control" id="name" type="text" [(ngModel)]="property['Name']" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="d-block" for="filtering">
                  <input class="checkbox_animated" id="filtering" type="checkbox" [(ngModel)]="property['Filtering']" />
                  {{ 'To use in filter' | translate }}
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="property['Rates'] && property['Rates'].length > 0">
            <div class="row" style="padding-bottom: 5px">
              <div class="col-md-6">
                <div *ngIf="selected" style="padding-top: 7px">
                  <span class="btn btn-primary btn-xs" style="margin-right: 5px" (click)="bulk('delete')" translate
                    >Delete</span
                  >
                  <span class="btn btn-primary btn-xs" style="margin-right: 5px" (click)="bulk('uncheck')" translate
                    >Uncheck</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  style="float: right;"
                  placeholder="{{ 'search' | translate }}"
                  (keyup)="filter($event.target.value)"
                  style="height: 38px" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table id="values" class="table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          class="checkbox_animated"
                          (change)="select($event)"
                          [(ngModel)]="all"
                          [ngModelOptions]="{ standalone: true }" />
                      </th>
                      <th translate>Title</th>
                      <th translate>Value</th>
                      <th translate>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rate of property['Rates']; let i = index" [ngClass]="{ hidden: !rate['filtered'] }">
                      <td>
                        <input
                          type="checkbox"
                          class="checkbox_animated"
                          (change)="check()"
                          [(ngModel)]="rate['Selected']" />
                      </td>
                      <td>{{ rate['Value']['Title'] }}</td>
                      <td>{{ rate['Value']['Value'] }}</td>
                      <td>
                        <div class="actions">
                          <span *ngIf="!rate['ID']" class="btn btn-xs btn-default up" (click)="up(i)">
                            <i class="fa fa-angle-up" aria-hidden="true"></i>
                          </span>
                          <span *ngIf="!rate['ID']" class="btn btn-xs btn-default down" (click)="down(i)">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </span>
                          <span *ngIf="!rate['ID']" class="btn btn-xs btn-default delete" (click)="delete(i)">
                            <i class="fa fa-minus-circle"></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-default"
          type="button"
          data-dismiss="modal"
          (click)="modal.dismiss('Cross click')"
          translate>
          Close
        </button>
        <button
          class="btn btn-primary"
          [disabled]="
            (!property['OptionId'] && (!property['Option'] || !property['Option']['Title'])) ||
            !property['Rates'] ||
            property['Rates'].length == 0
          "
          (click)="onSubmit()"
          translate>
          Save
        </button>
      </div>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class PropertyComponent implements OnInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  property;
  options: IMultiSelectOption[] = [];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'fontawesome',
    selectionLimit: 1,
    closeOnSelect: true,
  };
  values;
  modal;
  closeResult;
  info;
  term;
  option;
  optionId;
  all;
  selected = 0;
  action = '';
  fullSize = false;

  constructor(
    private apiService: ApiService,
    private subjectService: SubjectService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  toggle() {
    if (this.fullSize) {
      this.content.elementRef.nativeElement.ownerDocument.querySelector('.modal-dialog').classList.remove('fullsize');
    } else {
      this.content.elementRef.nativeElement.ownerDocument.querySelector('.modal-dialog').classList.add('fullsize');
    }
    this.fullSize = !this.fullSize;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === 'Cross click') {
      this.property['Rates'] = this.property['Rates'].filter(item => item['ID']);
    } else {
      return `with: ${reason}`;
    }
  }

  refresh() {
    const those = this;
    those.apiService
      .getOptions()
      .then(options => {
        those.options = options.map(item => {
          return { id: item['ID'], name: item['Title'] + ' (' + item['Name'] + ') ' };
        });
        this.property['OptionId'] = options[options.length - 1]['ID'];
      })
      .catch(err => {
        console.error('Load options', err);
      });
  }

  onCreate(content) {
    this.property = { Type: 'select', Mode: 'image', Opened: false };
    this.option = undefined;
    this.optionId = 0;
    this.apiService
      .getOptions()
      .then(options => {
        this.options = options.map(item => {
          return {
            ...{
              id: item['ID'],
              name: '#' + item['ID'] + ' ' + item['Title'] + ' (' + item['Name'] + ') ',
              title: item['Title'],
            },
            ...item,
          };
        });
        this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
        this.modal.result.then(
          result => {
            this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            this.onClosed.emit();
          }
        );
      })
      .catch(err => {
        console.error('Load options', err);
      });
  }

  onEdit(content, property, extra?: any) {
    property['Rates'] = property['Rates'].map(item => {
      item['filtered'] = true;
      return item;
    });
    this.property = property;
    this.apiService
      .getValues(this.property['OptionId'])
      .then(values => {
        for (let i = 0; i < values.length; i++) {
          let found = false;
          for (let j = 0; j < this.property['Rates'].length; j++) {
            if (values[i]['ID'] == this.property['Rates'][j]['Value']['ID']) {
              found = true;
              break;
            }
          }
          if (!found) {
            this.property['Rates'].push({
              Enabled: true,
              Availability: 'available',
              Value: {
                ID: values[i]['ID'],
                Title: values[i]['Title'],
                Color: values[i]['Color'],
                Thumbnail: values[i]['Thumbnail'],
                Availability: 'available',
                Value: values[i]['Value'],
                OptionId: this.property['OptionId'],
              },
              ValueId: values[i]['ID'],
              filtered: true,
            });
          }
        }
      })
      .catch(err => {
        console.error('Load values', err);
      });
    //
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    this.modal.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.onClosed.emit();
      }
    );
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete property?';
    modal.componentInstance.body = `Are you sure you want to delete property? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteProperty(id)
          .then(resp => {
            those.success('Delete property', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete property', err);
          });
      },
      reason => {}
    );
  }

  switch() {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i]['ID'] == this.option[0]) {
        this.property['Option'] = this.options[i];
        this.property['OptionId'] = this.options[i]['ID'];
        this.property['Name'] = this.options[i]['Name'];
        this.property['Title'] = this.options[i]['Title'];
        this.property['Type'] = this.options[i]['Type'] ? this.options[i]['Type'] : 'select';
        this.property['Size'] = this.options[i]['Size'] ? this.options[i]['Size'] : 'medium';
        break;
      }
    }
    //
    this.apiService
      .getValues(this.property['OptionId'])
      .then(values => {
        this.property['Rates'] = [];
        for (let i = 0; i < values.length; i++) {
          this.property['Rates'].push({
            Enabled: true,
            Availability: 'available',
            Value: {
              ID: values[i]['ID'],
              Title: values[i]['Title'],
              Color: values[i]['Color'],
              Thumbnail: values[i]['Thumbnail'],
              Availability: 'available',
              Value: values[i]['Value'],
              OptionId: this.property['OptionId'],
            },
            ValueId: values[i]['ID'],
            filtered: true,
          });
        }
      })
      .catch(err => {
        this.error('Load values', err);
      });
  }

  change(event) {
    this.property['Name'] = this.property['Title']
      .toLowerCase()
      .replace(/[!@#$%^&*\s]+/g, '-')
      .replace(/-{1,}/, '-');
  }

  select(event) {
    this.selected = 0;
    for (let i = 0; i < this.property['Rates'].length; i++) {
      if (this.property['Rates'][i]['filtered']) {
        this.property['Rates'][i]['Selected'] = event.target.checked;
      }
    }
    this.check();
  }

  filter(term) {
    this.term = term;
    //
    const terms = this.term
      .toLowerCase()
      .split(/;\s*/g)
      .filter(item => item);
    for (let i = 0; i < this.property['Rates'].length; i++) {
      let allowed = this.term == '';
      if (!allowed) {
        if (
          terms.filter(item => this.property['Rates'][i]['Value']['Title'].toLowerCase().indexOf(item) >= 0).length > 0
        ) {
          allowed = true;
        }
      }
      if (!allowed) {
        if (
          terms.filter(item => this.property['Rates'][i]['Value']['Value'].toLowerCase().indexOf(item) >= 0).length > 0
        ) {
          allowed = true;
        }
      }
      this.property['Rates'][i]['filtered'] = allowed;
    }
  }

  bulk(action) {
    if (action == 'uncheck') {
      for (let i = 0; i < this.property['Rates'].length; i++) {
        this.property['Rates'][i]['Selected'] = false;
      }
    } else if (action == 'delete') {
      for (let i = 0; i < this.property['Rates'].length; i++) {
        if (this.property['Rates'][i]['filtered'] && this.property['Rates'][i]['Selected']) {
          this.property['Rates'].splice(i, 1);
          i--;
        }
      }
    }
    this.action = '';
    this.all = false;
    this.selected = 0;
  }

  check() {
    this.selected = 0;
    for (let i = 0; i < this.property['Rates'].length; i++) {
      if (this.property['Rates'][i]['filtered'] && this.property['Rates'][i]['Selected']) {
        this.selected++;
      }
    }
    if (this.selected == 0) {
      this.all = false;
    }
  }

  up(index) {
    if (index > 0) {
      const x = this.property['Rates'][index - 1];
      if (!this.property['Rates'][index - 1]['ID']) {
        this.property['Rates'][index - 1] = this.property['Rates'][index];
        this.property['Rates'][index] = x;
      }
    }
    this.check();
  }

  down(index) {
    if (index < this.property['Rates'].length - 1) {
      const x = this.property['Rates'][index + 1];
      if (!this.property['Rates'][index]['ID']) {
        this.property['Rates'][index + 1] = this.property['Rates'][index];
        this.property['Rates'][index] = x;
      }
    }
  }

  delete(index) {
    this.property['Rates'].splice(index, 1);
    this.check();
  }

  onSubmit() {
    this.modal.close('Saved');
    this.onSaved.emit(this.property);
  }
}
