import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import {ApiService} from '../../shared/api.service';
import {ToastrService} from 'ngx-toastr';
import {Chart} from '../../shared/data/chart';
import {credentials} from '../../shared/credentials.data';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currency = credentials.currency;
  dashboard;
  transfers;
  countries = credentials.countries;

  public doughnutData = doughnutData;
  public pieData = pieData;
  constructor(private apiService: ApiService, private toastr: ToastrService) {
    Object.assign(this, { doughnutData, pieData })
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;


  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;



  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  ngOnInit() {
    this.refersh();
  }

  refersh() {
    const those = this;
    this.dashboard = null;
    this.transfers = null;
    this.apiService.getDashboard().then(dashboard => {
      if (dashboard.LastCustomers) {
        dashboard.LastCustomers.forEach((customer, i) => {
          const country = those.countries.find(item => customer['Country'] && item['Code'] === customer['Country'].toUpperCase());
          if (country) {
            dashboard.LastCustomers[i]['CountryName'] = country['Name'];
          }
        });
      }
      if (dashboard.LastOrders) {
        dashboard.LastOrders.forEach((order, i) => {
          const country = those.countries.find(item => order['BillingProfileCountry'] && item['Code'] === order['BillingProfileCountry'].toUpperCase());
          if (country) {
            dashboard.LastOrders[i]['BillingProfileCountryName'] = country['Name'];
          }
        });
      }
      those.dashboard = dashboard;
      those.transfers = {
        type: 'Bar',
        data: {
          labels: dashboard['Transfers']['Labels'],
          series: dashboard['Transfers']['Series'],
        },
        options: {
          height: 303,
          seriesBarDistance: 12,
          axisX: {
            showGrid: false,
            /*labelInterpolationFnc: (value) => {
              return value[0];
            }*/
          }
        },
        events: {
          created: (data) => {

          }
        }
      };
    }).catch(err => {
      this.error('Delete category', err);
    });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }
}
