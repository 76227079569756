import {Component, OnDestroy, OnInit} from '@angular/core';
import {credentials} from './shared/credentials.data';
import {SubjectService} from './shared/subject.service';
import {Subscription} from 'rxjs';
import {ApiService} from './shared/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'angular-2-local-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  title = credentials.name;
  info;
  subscription: Subscription;
  interval: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private localStorageService: LocalStorageService,
              private subjectService: SubjectService,
              private apiService: ApiService,
              translate: TranslateService){
    let language = 'en';
    if (localStorageService.get('language')) {
      language = localStorageService.get('language');
    }
    translate.setDefaultLang('en');
    translate.use(language);

  }

  ngOnInit() {
    const those = this;
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      those.info = info;
    });
    //
    const f1 = (error?) => {
      if (!those.router.url.match(/^\/auth\/login/)) {
        those.apiService.getInfo().then(info => {
          those.subjectService.infoSubject.next(info);
        }).catch(err => {
          if (error) {
            error(err);
          }
        });
      }
    };
    setTimeout( () => {
      f1((err) => {
        if (err.status === 500) {
          const obj = err.error;
          if (obj && obj.ERROR && obj.ERROR.indexOf('connection refused') > -1) {
            console.log('err', err);
          }
        }
      });
    }, 1000);
    this.interval = setInterval(() => {
      f1();
    }, 60000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
