<ng-content></ng-content>
<ng-template #content let-modal>
  <div class="modal-header" style="justify-content: initial;">
    <span class="cross border-right" style="margin-right: 10px" (click)="modal.dismiss('Cross click')">
      <svg style="margin-right:10px; height: 12px; width: 12px;">
        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
      </svg>
    </span>
    <h5 class="modal-title body-2" id="exampleModalLabel" translate>
      Add media
    </h5>
  </div>
  <div class="modal-body" style="background-image: linear-gradient(to bottom, rgba(0,0,0,0) 90%, rgba(0,0,0,0.1));">
    <div class="row">
      <div class="col-md-2 col-sm-3">
        <ul class="nav-left-sidebar">
          <li [ngClass]="{selected: tab == 'upload'}" (click)="toggle('upload')">
            <svg style="margin-right:5px; height: 20px; width: 20px;vertical-align: middle">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-download"></use>
            </svg>
            <span class="body-1" style="vertical-align: middle" translate>Upload</span>
          </li>
          <li [ngClass]="{selected: tab == 'select'}" (click)="toggle('select')">
            <svg style="margin-right:5px; height: 20px; width: 20px; vertical-align: middle">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-img"></use>
            </svg>
            <span class="body-1" style="vertical-align: middle" translate>Media Library</span>
          </li>
          <li [ngClass]="{selected: tab == 'remote'}" (click)="toggle('remote')">
            <svg style="margin-right:5px; height: 20px; width: 20px; vertical-align: middle">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-link"></use>
            </svg>
            <span class="body-1" style="vertical-align: middle" translate>From URL</span>
          </li>
        </ul>
      </div>
      <div class="col-md-10 col-sm-9">
        <div *ngIf="tab === 'upload'">
          <div class="dropzone-custom" style="padding-bottom: 10px">
            <div class="dropzone" (error)="onImageUploadError($event)"
                 (queueComplete)="onImageUploadComplete()" [dropzone]="dropzoneConfig">
              <div class="dz-message needsclick" (click)="onImageUploadClick($event)">
                <div class="dz-message-content">
                  <div class="dz-message-icon">
                    <svg class="fill-primary-violet">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-upload"></use>
                    </svg>
                  </div>
                  <div class="dz-message-text" style="min-height: 15px;">
                    <div class="dz-message-text-1">
                      <span>Drag to start uploading</span>
                    </div>
                    <div class="dz-message-text-2">
                      <span class="body-1-regular">Drop file</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row" style="padding-bottom: 10px">
              <div class="col" translate>Most recent uploads</div>
              <div class="col" style="text-align: right">
                <ul class="view">
                  <li style="display: inline-block" [ngClass]="{selected: view === 'tile'}" (click)="view='tile'">
                    <svg style="height: 20px; width: 20px; vertical-align: text-bottom">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiGridAlt"></use>
                    </svg>
                  </li>
                  <li style="display: inline-block" [ngClass]="{selected: view === 'list'}" (click)="view='list'">
                    <svg style="height: 20px; width: 20px; vertical-align: text-bottom">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiListUl"></use>
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div *ngIf="['list', 'tile'].indexOf(view) >= 0" [ngClass]="{absolute: !visible || !filtered, transparent: !visible || !filtered}">
                  <div class="view-list">
                    <div class="modal-table custom-datatable">
                      <div class="table-responsive" [ngClass]="{transparent: !visible || !filtered, tile: view === 'tile'}">
                        <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
                        <div *ngIf="source && source.count() > 0" class="records-count">
                          <span *ngIf="source.filtered() != source.total()">
                            {{'Filtered' | translate}}
                            : {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                          </span>
                                    <span *ngIf="source.filtered() == source.total()">
                            {{'Records' | translate}}: {{source.total()}}
                          </span>
                        </div>
                      </div>
                      <div *ngIf="!visible || !filtered" style="position: absolute;top: 100px;left: 46%;"><img
                        src="assets/images/loading.svg" alt="Loading1..." style="width: 100px"></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!visible || !filtered" style="min-height: 200px;">
                  <div style="position: absolute;top: 100px;left: 46%;"><img
                    src="assets/images/loading.svg" alt="Loading2..." style="width: 100px"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab === 'select'">
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-md-4">
              <div class="table-top" style="position: relative">
                <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 10px;">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
                  </svg>
                </div>
                <input type="text" class="form-control search" placeholder="{{'search' | translate}}" (keyup)="searchChanged.next()" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4">
              <div *ngIf="filter && filter.On" class="row" style="padding-bottom: 10px;">
                <div class="filters" style="display: inline-block; padding-right: 10px;">
                  <select class="form-control" (change)="apply()" [(ngModel)]="filter.UpdatedIn" [ngModelOptions]="{standalone: true}">
                    <option value="" translate>All Dates</option>
                    <option value="today" translate>Today</option>
                    <option value="yesterday" translate>Yesterday</option>
                    <option value="week" translate>Last week</option>
                    <option value="month" translate>Last month</option>
                  </select>
                </div>
                <div class="filters" style="display: flex;align-items: center;">
                  <div class="filter button-group-dropdown">
                    <div class="btn" style="margin: 0;padding: 0.5rem 0.5rem;display: flex;align-items: center;" (click)="setFilter('Favorite', !filter.Favorite)">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="{favorite: filter.Favorite, 'not-favorite': !filter.Favorite}">
                        <path d="M9 1.61803L10.4328 6.02786C10.5667 6.43989 10.9507 6.71885 11.3839 6.71885H16.0207L12.2694 9.44427C11.919 9.69891 11.7723 10.1503 11.9062 10.5623L13.339 14.9721L9.58779 12.2467C9.2373 11.9921 8.7627 11.9921 8.41222 12.2467L4.66099 14.9721L6.09383 10.5623C6.2277 10.1503 6.08105 9.69892 5.73056 9.44427L1.97933 6.71885H6.6161C7.04933 6.71885 7.43329 6.43989 7.56716 6.02786L9 1.61803Z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selected && selected.length > 0" class="row">
            <div class="col" style="margin-top: auto; margin-bottom: auto">
              <div style="padding-bottom: 10px">
                <div class="body-2" style="display: inline-block; padding-right: 10px">
                  <span style="padding-right: 5px;">{{selected.length}}</span>
                  <span translate>of</span> {{source.filtered()}} <span translate>media objects selected</span>
                </div>
              </div>
            </div>
          </div>
          <div id="batchDelete" class="modal-table custom-datatable">
            <div class="table-responsive" [ngClass]="!visible || !filtered?'transparent':''">
              <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
              <div style="display: flex;justify-content: space-between;align-items: end;">
                <div *ngIf="source && source.count() > 0" class="records-count">
                  <span *ngIf="source.filtered() != source.total()">
                    {{'Filtered' | translate}}
                    : {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                  </span>
                          <span *ngIf="source.filtered() == source.total()">
                    {{'Records' | translate}}: {{source.total()}}
                  </span>
                </div>
                <div *ngIf="widths" class="columns-reset" (click)="resetColumns($event)">reset width of columns</div>
              </div>
            </div>
            <div *ngIf="!visible || !filtered" style="position: absolute;top: 100px;left: 46%;"><img
              src="assets/images/loading.svg" alt="Loading3..." style="width: 100px"></div>
          </div>
        </div>
        <div *ngIf="tab === 'remote'" class="tab-remote">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <!--div class="form-group" style="position: relative">
                  <label for="url" class="mb-1" translate>URL</label>
                  <div class="input-button">
                    <input class="form-control" style="padding-right: 115px;" id="url" type="text" (change)="changed($event)" formControlName="Orig">
                    <button type="submit" class="btn btn-primary" style="" [disabled]="!form.valid" translate>Submit </button>
                  </div>
                </div-->
                <div class="form-group" style="position: relative">
                  <label for="url" class="mb-1" translate>URL</label>
                  <div class="input-button">
                    <input class="form-control" id="url" type="text" (change)="changed($event)" formControlName="Orig">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="preview" class="col-md-4">
                <div class="row">
                  <div class="col">
                    <img *ngIf="preview.Thumbnail" id="preview" [src]="preview.Thumbnail" style="width: 100%" crossorigin="anonymous">
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="preview-property body-4">
                      <label class="mb-1" translate>Size</label><span>{{preview.Size | humanizeSize}}</span>
                    </div>
                    <div *ngIf="preview.Width" class="preview-property body-4">
                      <label class="mb-1" translate>Dimension</label><span>{{preview.Width}} x {{preview.Height}}</span>
                    </div>
                    <div class="preview-property body-4">
                      <label class="mb-1" translate>Format</label><span>{{preview.ContentType}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Title</label>
                  <input class="form-control" id="title" type="text" formControlName="Title">
                </div>
                <div class="form-group">
                  <label for="alt" class="mb-1" translate>Alternative text</label>
                  <input class="form-control" id="alt" type="text" formControlName="Alt">
                </div>
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <textarea class="form-control" id="description" rows="3" formControlName="Description"></textarea>
                </div>
                <div style="text-align: center">
                  <button type="submit" class="btn btn-primary" style="" [disabled]="!form.valid" translate>Send</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="width: 100%;display: flex;justify-content: flex-end;">
    <button class="btn btn-cancel" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')"
            translate>Close
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!selected || selected.length === 0" (click)="onInsert()"
            translate>Insert
    </button>
  </div>
</ng-template>
