<ng-content></ng-content>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title body-2" id="exampleModalLabel" translate>
      Tags
    </h5>
    <span class="cross" (click)="modal.dismiss('Cross click')">
      <svg style="margin-right:10px; height: 12px; width: 12px;">
        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
      </svg>
    </span>
  </div>
  <div class="modal-body">
    <div class="row" style="padding-bottom: 10px;">
      <div class="col">
        <div class="table-top" style="position: relative">
          <div style="padding-right: 90px;">
            <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 13px;">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
              </svg>
            </div>
            <input type="text" class="form-control search" placeholder="{{'search' | translate}}" (keyup)="searchChanged.next()" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
          </div>
          <button
            type="button"
            class="btn btn-filter facon no-expand-icon dropdown-toggle"
            style="position: absolute; top: 0; right: 0; height: 44px; margin: 0;"
          (click)="toggleSort()">
            <svg style="margin-right: 5px; height: 20px; width: 20px; vertical-align: bottom;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-sort"></use>
            </svg>
            <span translate>Sort</span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="selected && selected.length > 0" class="row">
      <div class="col">
        <label class="mb-0" translate>Applied tags</label>
        <div class="tags selected-tags mb-2">
          <div *ngFor="let tag of selected; let i = index;" class="tag">
            <a class="link" [routerLink]="['/tags/' + tag.ID]" style="cursor: pointer">{{tag.Title}}</a>
            <span class="remove" (click)="remove(tag)">&times;</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="filtered && filtered.length > 0" class="row">
      <div class="col">
        <label class="mb-0" translate>All tags</label>
        <div class="tags all-tags mb-2">
          <div *ngFor="let tag of filtered; let i = index;" class="tag">
            <span style="cursor: pointer" (click)="insert(tag)">{{tag.Title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="term && !contains(tags, term)" class="row">
      <div class="col">
        <div class="tags">
          <span class="color-primary-violet pr-2">+ Add tag</span>
          <div class="tag">
            <span style="cursor: pointer" (click)="add(term)">{{term}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="justify-content: normal;">
    <button type="button" class="btn btn-primary" [disabled]="!selected || selected.length === 0 || equals(input, selected)" (click)="onInsert()"
            translate>Apply changes
    </button>
    <button class="btn btn-cancel" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')"
            translate>Close
    </button>
  </div>
</ng-template>
