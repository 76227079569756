<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Product</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row align-items-center">
              <div class="col-sm-12 col-md-8 col-lg-6">
                <div class="form-group">
                  <label style="padding-right: 5px" for="absolute-price">
                    <input class="checkbox_animated" id="absolute-price" type="checkbox" formControlName="AbsolutePrice">
                    {{'Absolute Price' | translate}}
                  </label>
                  <span class="tooltip-question" placement="top" [ngbTooltip]="'use absolute price view by default' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="dimensions" class="mb-1" translate>Pattern</label>
              <select id="dimensions" class="form-control" formControlName="Pattern">
                <option value="whd" translate>Width x Height x Depth</option>
                <option value="wdh" translate>Width x Depth x Height</option>
                <option value="hwd" translate>Height x Width x Depth</option>
                <option value="hdw" translate>Height x Depth x Width</option>
                <option value="dwh" translate>Depth x Width x Height</option>
                <option value="dhw" translate>Depth x Height x Width</option>
              </select>
            </div>
            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col">
                <div class="form-group">
                  <label for="dimensionUnit" class="mb-1" translate>DimensionUnit</label>
                  <select id="dimensionUnit" class="form-control" formControlName="DimensionUnit">
                    <option value="mm" translate>millimeter</option>
                    <option value="cm" translate>centimeter</option>
                    <option value="m" translate>meter</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="weightUnit" class="mb-1" translate>WeightUnit</label>
                  <select id="weightUnit" class="form-control" formControlName="WeightUnit">
                    <option value="g" translate>gram</option>
                    <option value="kg" translate>kilogram</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row align-items-center">
              <div class="col-sm-12 col-md-8 col-lg-6">
                <div class="form-group">
                  <label style="padding-right: 5px" for="overselling">
                    <input class="checkbox_animated" id="overselling" type="checkbox" formControlName="Overselling">
                    {{'Overselling' | translate}}
                  </label>
                  <span class="tooltip-question" placement="top" [ngbTooltip]="'ability to sell more than in stock (according to inventory)' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
              <svg>
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
              </svg>
              <span style="vertical-align: text-bottom">Save</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
