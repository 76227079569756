import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-block',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <div style="display: flex;align-items: center;">
          <h5 class="modal-title body-2" id="exampleModalLabel">
            {{ (!block || !block['ID'] ? 'Add Infobox' : 'Edit Infobox') | translate }}
          </h5>
          <div *ngIf="form">
            <div class="button-group-dropdown" style="display: inline-block">
              <button
                type="button"
                class="btn btn-status dropdown-toggle"
                data-toggle="dropdown"
                [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
                <span [innerHTML]="form.get('Enabled').value ? 'active' : ('inactive' | translate)"></span>
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li (click)="form.get('Enabled').setValue(true)">Active</li>
                <li (click)="form.get('Enabled').setValue(false)">Inactive</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
          <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5" />
            <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5" />
          </svg>
        </div>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <!-- -->
            <div *ngIf="form.get('Post')" class="row" formGroupName="Post">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Title</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Name</label>
                  <input class="form-control" id="name" type="text" formControlName="Name" />
                </div>
                <div class="form-group mb-0">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                  <div
                    *ngIf="
                      form.controls['Post'].controls['Thumbnail'] &&
                      form.controls['Post'].controls['Thumbnail'].value &&
                      !form.controls['Post'].controls['Thumbnail'].dirty
                    "
                    style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                    <a [href]="form.controls['Post'].controls['Thumbnail'].value" target="_blank"
                      ><img
                        [src]="
                          absolutizeUrl.transform(
                            '/api/v1/resize?path=/storage' + form.controls['Post'].controls['Thumbnail'].value
                          )
                        "
                        style="max-height: 100px;border-radius: 5px;margin-bottom: 5px;"
                    /></a>
                    <div style="cursor: pointer; position: absolute; right: 5px; top: 5px" (click)="unset()">
                      &times;
                    </div>
                  </div>
                  <app-file-uploader
                    *ngIf="
                      !form.controls['Post'].controls['Thumbnail'].value ||
                      form.controls['Post'].controls['Thumbnail'].dirty
                    "
                    id="thumbnail"
                    (filesLoaded)="upload($event)"></app-file-uploader>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label for="content" class="mb-1" translate>Content</label>
                  <app-tinymce
                    id="content"
                    [options]="config"
                    (focus)="$event.target?.iframeElement?.classList?.add('focused')"
                    (blur)="$event.target?.iframeElement?.classList?.remove('focused')"
                    formControlName="Content"></app-tinymce>
                </div>
              </div>
            </div>
            <div *ngIf="form.get('PostId')" class="row">
              <div class="col">
                <div *ngIf="options" class="form-group">
                  <label for="location" class="mb-1" translate>Post</label>
                  <ngx-bootstrap-multiselect
                    id="posts"
                    class="multiselect"
                    [options]="options"
                    [settings]="settings"
                    formControlName="PostId"></ngx-bootstrap-multiselect>
                </div>
              </div>
            </div>
            <!------------>
            <div *ngIf="productId" class="form-group">
              <label for="location" class="mb-1" translate>Location</label>
              <select id="location" class="form-control" formControlName="Location">
                <option value="product" translate>Product</option>
                <option value="variation" translate>Variation</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: normal;align-self: flex-end;">
          <button
            type="submit"
            id="save"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            translate>
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span style="vertical-align: text-bottom">Save</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [``],
  providers: [AbsolutizeUrlPipe],
})
export class BlockComponent implements OnInit {
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('propertyId') propertyId = 0;
  @Input('optionId') optionId = 0;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onCreated: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  mode = 'create';
  block;
  modal;
  closeResult;
  form;
  timer;
  config: {};
  options: IMultiSelectOption[];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    closeOnSelect: true,
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 5,
    selectionLimit: 1,
  };

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    this.config = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp['Url']);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err['ERROR'] ? err['ERROR'] : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content, mode = 'create') {
    const those = this;
    const form = this.formBuilder.group({
      ID: [],
      Enabled: [true],
      Location: ['product', Validators.required],
      ProductId: [those.productId],
      VariationId: [those.variationId],
      PropertyId: [those.propertyId],
      OptionId: [those.optionId],
    });
    if (mode === 'create') {
      form.addControl(
        'Post',
        this.formBuilder.group({
          Enabled: [true],
          Name: ['', Validators.required],
          Title: ['', Validators.required],
          Thumbnail: [''],
          Content: [''],
        })
      );
    } else {
      form.addControl('PostId', new FormControl([], Validators.required));
      this.apiService
        .getPosts()
        .then(posts => {
          those.options = posts.map(item => {
            return { id: item.ID, name: `#${item.ID} ${item.Title} (${item.Name})` };
          });
        })
        .catch(err => {
          those.error('Load posts', err);
        });
    }
    this.form = form;
    //
    those.modal = those.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: mode === 'create' ? 'xl' : 'md',
    });
    those.modal.result.then(
      result => {
        those.onCreated.emit(those.block);
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getBlock(id)
      .then(block => {
        this.block = block;
        const form = this.formBuilder.group({
          ID: [block.ID],
          Enabled: [block.Enabled ? true : false],
          Location: [block.Location, Validators.required],
        });
        const mode = !block.Count || block.Count === 1 ? 'create' : 'select';
        if (mode === 'create') {
          form.addControl(
            'Post',
            this.formBuilder.group({
              ID: [block.Post?.ID],
              Enabled: [block.Post?.Enabled ? true : false],
              Name: [block.Post?.Name, Validators.required],
              Title: [block.Post?.Title, Validators.required],
              Thumbnail: [block.Post?.Thumbnail ? block.Post.Thumbnail : ''],
              Content: [block.Post?.Content ? block.Post.Content : ''],
            })
          );
        } else {
          form.addControl('PostId', new FormControl([block.PostId], Validators.required));
        }
        those.form = form;
        those.modal = those.modalService.open(content, {
          ariaLabelledBy: 'modal-basic-title',
          size: mode === 'create' ? 'xl' : 'md',
        });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit(those.block);
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load block', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete infobox?';
    modal.componentInstance.body = `Are you sure you want to delete infobox? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        this.apiService
          .deleteBlock(id)
          .then(resp => {
            this.success('Delete infobox', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            this.error('Delete infobox', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form
        .get('Post')
        .get('Name')
        .setValue(
          event.target.value
            .toLowerCase()
            .replace(/[!@#$%^&*\s]+/g, '-')
            .replace(/-{1,}/, '-')
        );
    }
  }

  upload(files) {
    const file = files[0];
    this.form.get('Post').patchValue({
      Thumbnail: file,
    });
    this.form.get('Post').get('Thumbnail').markAllAsTouched();
    this.form.get('Post').get('Thumbnail').updateValueAndValidity();
  }

  unset() {
    this.form.get('Post').get('Thumbnail').setValue('');
    this.form.get('Post').get('Thumbnail').markAsTouched();
    this.form.get('Post').get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    console.log('onSubmit');
    const those = this;
    if (!this.form.get('ID').value) {
      // new
      if (this.form.get('Post')) {
        // post create
        const data = new FormData();
        data.append('Enabled', this.form.get('Enabled').value);
        data.append('Name', this.form.get('Post').get('Name').value);
        data.append('Title', this.form.get('Post').get('Title').value);
        data.append('Thumbnail', this.form.get('Post').get('Thumbnail').value);
        data.append('Content', this.form.get('Post').get('Content').value);
        data.append('Location', this.form.get('Location').value);
        data.append('ProductId', this.form.get('ProductId').value);
        data.append('VariationId', this.form.get('VariationId').value);
        data.append('PropertyId', this.form.get('PropertyId').value);
        data.append('OptionId', this.form.get('OptionId').value);
        /*if (this.productId) {
          data.append('ProductId', String(this.productId));
        }
        if (this.variationId) {
          data.append('VariationId', String(this.variationId));
        }
        if (this.propertyId) {
          data.append('PropertyId', String(this.propertyId));
        }
        if (this.optionId) {
          data.append('OptionId', String(this.optionId));
        }*/
        this.apiService
          .postBlock(data)
          .then(block => {
            console.log('block', block);
            those.block = block;
            those.modal.close('Created');
          })
          .catch(err => {
            those.error('Create block', err);
          });
      } else {
        // block create
        const raw = this.form.getRawValue();
        console.log('raw', raw);
        raw.PostId = raw.PostId[0];
        this.apiService
          .postBlock(raw)
          .then(block => {
            console.log('block', block);
            those.block = block;
            those.modal.close('Created');
          })
          .catch(err => {
            those.error('Create block', err);
          });
      }
    } else {
      // existing
      if (this.form.get('Post')) {
        // post edit
        const data = new FormData();
        data.append('Enabled', this.form.get('Enabled').value);
        data.append('Name', this.form.get('Post').get('Name').value);
        data.append('Title', this.form.get('Post').get('Title').value);
        data.append('Thumbnail', this.form.get('Post').get('Thumbnail').value);
        data.append('Content', this.form.get('Post').get('Content').value);
        data.append('Location', this.form.get('Location').value);
        if (this.productId) {
          data.append('ProductId', String(this.productId));
        }
        if (this.variationId) {
          data.append('VariationId', String(this.variationId));
        }
        if (this.propertyId) {
          data.append('PropertyId', String(this.propertyId));
        }
        if (this.optionId) {
          data.append('OptionId', String(this.optionId));
        }
        this.apiService
          .putBlock(this.block.ID, data)
          .then(block => {
            console.log('block', block);
            those.block = block;
            those.modal.close('Updated');
          })
          .catch(err => {
            those.error('Update block', err);
          });
      } else {
        // block edit
      }
    }
  }

  debug() {
    console.log('form', this.form);
  }
}
