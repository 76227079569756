import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../utils.pipe';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  providers: [ AbsolutizeUrlPipe ]
})
export class DemoComponent implements OnInit, OnDestroy {

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
