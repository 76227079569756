import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { CategoryTitleRenderComponent } from './category-title-render.component';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { CategoriesDataSource } from './cetagories.data-source';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { CategorySelectedRenderComponent } from './category-selected-render.component';
import { NumberRenderComponent } from '../../shared/render/number-render.component';
import { CategoryProductsRenderComponent } from './category-products-render.component';
import { CategoryEnabledRenderComponent } from './category-enabled-render.component';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-category',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      const widths = {};
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          const classes = item.classList
            .toString()
            .split(/\s+/)
            .filter(cl => ['ng2-smart-th', 'ng-star-inserted'].indexOf(cl) < 0);
          if (classes && classes.length > 0) {
            const style = item.getAttribute('style');
            const res = style.match(/width: (\d+)px;?/);
            if (res) {
              widths[classes[0]] = res[1];
            }
          }
        }
      });
      if (widths && Object.keys(widths).length > 0) {
        this.widths = widths;
        this.localStorageService.set('categories_columns_widths', JSON.stringify(widths));
      }
    }
  }
  @ViewChild('content') content;
  @ViewChild('table') table: Ng2SmartTableComponent;
  queryParamsSubscription: Subscription;
  id = 0;
  tree;
  settings;
  source: CategoriesDataSource;
  dragging;
  entered;
  filtered = false;
  form;
  modal;
  config;
  public closeResult: string;
  visible = false;
  term;
  selected;
  hover = {};
  changes = [];
  widths;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params.id) {
        those.id = params.id;
      }
      this.apiService
        .getCategories(those.id)
        .then(tree => {
          those.tree = tree;
          those.visible = false;
          // set columns widths
          this.widths = JSON.parse(this.localStorageService.get('categories_columns_widths'));
          if (this.widths && Object.keys(this.widths).length > 0) {
            for (const [key, value] of Object.entries(this.widths)) {
              if (this.settings.columns[key] && value) {
                this.settings.columns[key]['width'] = value + 'px';
              }
            }
          }
          those.source = new CategoriesDataSource(those.http, those.apiService, those.localStorageService);
          this.source.callback = () => {
            const table = document.querySelector('table');
            const cols = table.querySelectorAll('thead tr.ng2-smart-titles th:not(.Selected):not(.Favorite)');
            [].forEach.call(cols, col => {
              const resizer = document.createElement('div');
              resizer.classList.add('resizer');
              resizer.style.height = `${table['offsetHeight']}px`;
              col.appendChild(resizer);
              window['createResizableColumn'](col, resizer);
            });
          };
          those.source.parent = those;
          those.source.id = those.id;
        })
        .catch(err => {
          those.error('Load categories', err);
        });
    });
    this.settings = {
      mode: 'external',
      draggable: true,
      actions: {
        add: false,
        edit: false,
        delete: false,
        columnTitle: those.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        Selected: {
          title: '',
          type: 'custom',
          renderComponent: CategorySelectedRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        Title: {
          title: those.translate.instant('Category'),
          type: 'custom',
          renderComponent: CategoryTitleRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        Count: {
          title: those.translate.instant('Products'),
          type: 'custom',
          renderComponent: NumberRenderComponent,
          filter: false,
          sort: false,
        },
        Products: {
          title: those.translate.instant('Products in sub cats.'),
          type: 'custom',
          renderComponent: CategoryProductsRenderComponent,
          filter: false,
          sort: false,
        },
        Enabled: {
          title: those.translate.instant('Visible in menu'),
          type: 'custom',
          renderComponent: CategoryEnabledRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
      },
      pager: {
        display: true,
      },
    };
    /*this.settings.columns.Sort.onComponentInitFunction = (instance) => {
      instance.parent = those;
    };*/
    this.config = {
      baseURL: those.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        those.apiService
          .postImage(data)
          .then(resp => {
            those.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            those.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 250,
      branding: false,
    };
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  resetColumns(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          item.removeAttribute('style');
        }
      });
    }
    this.widths = undefined;
    this.localStorageService.remove('categories_columns_widths');
    event.stopPropagation();
  }

  bulk(action?) {
    const those = this;
    switch (action) {
      case 'set-active':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patchCategory(those.source.data[i]['ID'], 'setEnable', { Enabled: true })
                  .then(resp => {
                    those.source.data[i]['Enabled'] = true;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-inactive':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patchCategory(those.source.data[i]['ID'], 'setEnable', { Enabled: false })
                  .then(resp => {
                    those.source.data[i]['Enabled'] = false;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'copy':
        this.error('In progress', { status: 500, error: { ERROR: 'not implemented yet' } });
      default:
        //this.selected = this.source.data.filter(item => item['Selected']).length > 0;
        this.selected = this.source.data.filter(item => item['Selected']);
    }
  }

  onCreate() {
    let parentId;
    const rows = this.table.grid.getRows();
    for (const row of rows) {
      if (row.isSelected) {
        const data = row.getData();
        parentId = data.id;
        break;
      }
    }
    this.router.navigate(['/categories/new'], { queryParams: { pid: parentId } });
    return;
  }

  onEdit(event) {
    const those = this;
    those.router.navigate(['/categories/' + event.data.ID], { queryParams: { pid: those.tree.id } });
    return;
  }

  onCustom(event) {
    const those = this;
    if (event.action === 'list') {
      those.router.navigate(['/products'], { queryParams: { id: event.data.id } });
    }
  }

  onDelete(event) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete category?';
    modal.componentInstance.body = `Are you sure you want to delete category? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteCategory(event.data.id)
          .then(resp => {
            those.success('Delete category', resp);
            those.source.refresh();
          })
          .catch(err => {
            those.error('Delete category', err);
          });
      },
      reason => {}
    );
  }

  search(event?) {
    const term = (event ? event.target.value : this.term).toLowerCase();
    if (term == '') {
      let data = [];
      for (let i = 0; i < this.source.categories['Children'].length; i++) {
        data.push({ ...this.source.categories['Children'][i], ...{ term: term, level: 0 } });
      }
      this.source.data = data;
      this.source.local = true;
      this.source.refresh();
      return;
    } else if (term.length < 2) {
      return;
    }
    let opened = [];
    const f1 = (root, ids) => {
      if (
        root.id == term ||
        root['Name'].toLowerCase().indexOf(term) >= 0 ||
        root['Title'].toLowerCase().indexOf(term) >= 0
      ) {
        opened = opened.concat(ids);
        opened = opened.filter((item, pos) => opened.indexOf(item) === pos);
      }
      if (root['Children']) {
        for (let i = 0; i < root['Children'].length; i++) {
          f1(root['Children'][i], ids.concat(root.id));
        }
      }
    };
    f1(this.source.categories, []);
    let data = [];
    const f2 = (root, level) => {
      if (opened.indexOf(root.id) >= 0) {
        data.push({ ...root, ...{ expanded: true, term: term, level: level } });
        if (root['Children']) {
          for (let i = 0; i < root['Children'].length; i++) {
            f2(root['Children'][i], level + 1);
          }
        }
      } else if (
        root.id == term ||
        root['Name'].toLowerCase().indexOf(term) >= 0 ||
        root['Title'].toLowerCase().indexOf(term) >= 0
      ) {
        data.push({ ...root, ...{ term: term, level: level } });
      }
    };
    for (let i = 0; i < this.source.categories['Children'].length; i++) {
      f2(this.source.categories['Children'][i], 0);
    }
    if (data.length > 0) {
      data[data.length - 1].last = true;
    }
    this.source.data = data;
    this.source.local = true;
    this.source.refresh();
  }

  refresh(hard?: boolean) {
    if (hard) {
      window.location.reload();
      return;
    } else {
      this.source.refresh();
    }
  }

  setPerPage(value: number) {
    this.settings.pager.perPage = value;
    this.localStorageService.set('categories_per_page', JSON.stringify(value));
    this.refresh(true);
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onRowDragStart(event) {
    this.dragging = event;
  }

  onRowDragEnter(event) {
    const those = this;
    if (event.data.Children && event.data.Children.length > 0 && !event.data['expanded']) {
      if (this.hover[event.data.ID]) {
        clearTimeout(this.hover[event.data.ID]['timer']);
        this.hover[event.data.ID] = undefined;
      }
      const id = event.data.ID;
      this.hover[id] = {
        created: new Date(),
        timer: setTimeout(() => {
          const button = document.querySelector('#id-' + id + '-expand-button');
          if (button) {
            those.hover[id] = undefined;
            button['click']();
          }
        }, 1500),
      };
    }
    this.entered = event;
  }

  onRowDragLeave(event) {
    if (
      this.hover[event.data.ID] &&
      this.hover[event.data.ID]['created'] &&
      new Date().getTime() - this.hover[event.data.ID]['created'].getTime() > 100
    ) {
      clearTimeout(this.hover[event.data.ID]['timer']);
      this.hover[event.data.ID] = undefined;
    }
  }

  onRowDrop(event) {
    const source = this.dragging.data;
    if (source['expanded']) {
      return;
    }
    const destination = event.data;
    let from = -1;
    let to = -1;
    let fromStart = -1;
    let fromEnd = -1;
    let toStart = -1;
    let toEnd = -1;
    for (let i = 0; i < this.source.data.length; i++) {
      if (source.ID === this.source.data[i]['ID']) {
        from = i;
      }
      if (destination.ID === this.source.data[i]['ID']) {
        to = i;
      }
      if (fromStart === -1 && this.source.data[i]['Path'] === source.Path) {
        fromStart = i;
      } else if (this.source.data[i]['Path'] === source.Path) {
        fromEnd = i;
      }
      if (toStart === -1 && this.source.data[i]['Path'] === destination.Path) {
        toStart = i;
      } else if (this.source.data[i]['Path'] === destination.Path) {
        toEnd = i;
      }
    }
    //
    this.changes.push({ from, to });
    this.swap(from, to);
    //
    for (const id in this.hover) {
      if (id && this.hover[id]) {
        clearTimeout(this.hover[id]['timer']);
      }
    }
    this.hover = {};
  }

  swap(from, to) {
    const source = this.source.data[from];
    const destination = this.source.data[to];
    const those = this;
    let newParent = -1;
    const row = this.source.data.splice(from, 1);
    if (row && row.length > 0) {
      if (source.Path !== destination.Path) {
        source.Path = destination.Path;
        row[0]['Path'] = destination.Path;
        newParent = source.ParentId === destination.ParentId ? -1 : destination.ParentId ? destination.ParentId : 0;
        source.ParentId = destination.ParentId;
        row[0]['ParentId'] = destination.ParentId;
        source.level = destination.level;
        row[0]['level'] = destination.level;
      }
      const last =
        to === this.source.data.length - 1 ||
        (to + 1 < this.source.data.length && this.source.data[to + 1]['level'] !== this.source.data[to]['level']);
      source.last = last;
      row[0]['last'] = last;
      this.source.data.splice(to, 0, row[0]);
      //
      const sorts = [];
      for (let i = 0; i < this.source.data.length; i++) {
        if (this.source.data[i]['Path'] === source.Path || this.source.data[i]['Path'] === destination.Path) {
          if (i !== this.source.data[i]['Sort']) {
            sorts.push({ ID: this.source.data[i]['ID'], Sort: i });
          }
          this.source.data[i]['Sort'] = i;
        }
        if (i === this.source.data.length - 1 || this.source.data[i]['level'] !== this.source.data[i + 1]['level']) {
          this.source.data[i]['last'] = true;
        } else {
          this.source.data[i]['last'] = false;
        }
      }
      const update = [];
      if (sorts && sorts.length > 0) {
        for (let i = 0; i < sorts.length; i++) {
          update.push(
            those.apiService.patchCategory(sorts[i]['ID'], 'setSort', { Sort: sorts[i]['Sort'] }).catch(err => {
              console.log(err);
            })
          );
        }
      }
      Promise.all(update)
        .then(() => {
          if (newParent >= 0) {
            those.apiService
              .patchCategory(source['ID'], 'setParent', { ID: newParent })
              .then(() => {})
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    this.source.local = true;
    this.source.refresh();
  }

  onUndo() {
    if (this.changes.length > 0) {
      const change = this.changes.pop();
      this.swap(change['to'], change['from']);
    }
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    if (this.form.get('Thumbnail')) {
      data.append('Thumbnail', this.form.get('Thumbnail').value);
    }
    data.append('Description', this.form.get('Description').value);
    data.append('Content', this.form.get('Content').value);
    data.append('ParentId', this.form.get('ParentId').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postCategory(data)
        .then(resp => {
          those.success('Create category', resp);
          those.modal.close('Saved');
          those.router.navigate([], { queryParams: { id: those.tree.id } });
          those.source.refresh();
        })
        .catch(err => {
          those.error('Delete categories', err);
        });
    } else {
      // update
      this.apiService
        .putCategory(this.form.get('ID').value, data)
        .then(resp => {
          those.success('Update category', resp);
          those.modal.close('Updated');
          those.router.navigate([], { queryParams: { id: those.tree.id } });
          those.source.refresh();
        })
        .catch(err => {
          those.error('Update category', err);
        });
    }
  }
}
