import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../utils.pipe';
import {ApiService} from '../../api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SubjectService} from '../../subject.service';
import {FormBuilder, Validators} from '@angular/forms';
import {credentials} from '../../credentials.data';

@Component({
  selector: 'app-order',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title body-2" id="exampleModalLabel" translate>
          Edit Order
        </h5>
        <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
          <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5"/>
            <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5"/>
          </svg>
        </div>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div *ngFor="let type of ['Billing', 'Shipping']" class="col">
                <h2 [innerHtml]="type | translate"></h2>
                <div class="form-group">
                  <label for="country" class="mb-1" translate>Country</label>
                  <app-country-input id="country" [formControlName]="type + 'ProfileCountry'"></app-country-input>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="name" class="mb-1" translate>Name</label>
                      <input class="form-control" id="name" type="text" [formControlName]="type + 'ProfileName'">
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="lastname" class="mb-1" translate>Lastname</label>
                      <input class="form-control" id="lastname" type="text" [formControlName]="type + 'ProfileLastname'">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="company" class="mb-1" translate>Company</label>
                  <input class="form-control" id="company" type="text" [formControlName]="type + 'ProfileCompany'">
                </div>
                <div class="form-group">
                  <label for="address" class="mb-1" translate>Address</label>
                  <input class="form-control" id="address" type="text" [formControlName]="type + 'ProfileAddress'">
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="city" class="mb-1" translate>City</label>
                      <input class="form-control" id="city" type="text" [formControlName]="type + 'ProfileCity'">
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="zip" class="mb-1" translate>ZIP</label>
                      <input class="form-control" id="zip" type="text" [formControlName]="type + 'ProfileZip'">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phone" class="mb-1" translate>Phone</label>
                  <app-phone-input id="phone" [formControlName]="type + 'ProfilePhone'"></app-phone-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="comment" class="mb-1" translate>Comment</label>
                  <textarea class="form-control" id="comment" formControlName="Comment"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" translate>
              <svg>
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
              </svg>
              <span style="vertical-align: text-bottom">Save</span>
            </button>
            <button class="btn btn-cancel" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')" translate>Cancel
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  `
})
export class OrderComponent implements OnInit, OnDestroy {
  @Input('type') type = 'billing';
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  info;
  modal;
  closeResult;
  order;
  form;

  countries = credentials.countries;

  constructor(private apiService: ApiService, private subjectService: SubjectService, private modalService: NgbModal,
              private formBuilder: FormBuilder, private translate: TranslateService, private toastr: ToastrService,
              public absolutizeUrl: AbsolutizeUrlPipe, ) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {closeButton: true, timeOut: 15000});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else {
      return `with: ${reason}`;
    }
  }

  onEdit(content, id) {
    const those = this;
    this.apiService.getOrder(id).then(order => {
      those.order = order;
      those.form = those.formBuilder.group({
        ID: [order.ID],
        BillingProfileName: [order.BillingProfileName, Validators.required],
        BillingProfileLastname: [order.BillingProfileLastname, Validators.required],
        BillingProfileCompany: [order.BillingProfileCompany],
        BillingProfileCountry: [order.BillingProfileCountry, Validators.required],
        BillingProfileAddress: [order.BillingProfileAddress, Validators.required],
        BillingProfileCity: [order.BillingProfileCity, Validators.required],
        BillingProfileZip: [order.BillingProfileZip, Validators.required],
        BillingProfilePhone: [order.BillingProfilePhone],
        BillingStatus: [order.BillingStatus],
        ShippingProfileName: [order.ShippingProfileName, Validators.required],
        ShippingProfileLastname: [order.ShippingProfileLastname, Validators.required],
        ShippingProfileCompany: [order.ShippingProfileCompany],
        ShippingProfileCountry: [order.ShippingProfileCountry, Validators.required],
        ShippingProfileAddress: [order.ShippingProfileAddress, Validators.required],
        ShippingProfileCity: [order.ShippingProfileCity, Validators.required],
        ShippingProfileZip: [order.ShippingProfileZip, Validators.required],
        ShippingProfilePhone: [order.ShippingProfilePhone],
        ShippingStatus: [order.ShippingStatus],
        Comment: [order.Comment],
      });
      those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      those.modal.result.then((result) => {
        those.closeResult = `Closed with: ${result}`;
        those.onSaved.emit(those.order);
      }, (reason) => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      });
    }).catch(err => {
      those.error('Load order', err);
    });
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putOrder(this.order.ID, {...this.order, ...raw}).then(resp => {
      those.order = resp;
      those.modal.close('Saved');
    }).catch(err => {
      those.error('Save order', err);
    });
  }

}
