import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersDataSource } from './users.data-source';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ApiService } from '../../shared/api.service';
import { UserOrdersRenderComponent } from './user-orders-render.component';
import { UserEmailConfirmedRenderComponent } from './user-email-confirmed-render.component';
import { UserRoleRenderComponent } from './user-role.render.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { DateRenderComponent } from '../../shared/render/date-render.component';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class UsersComponent implements OnInit {
  queryParamsSubscription: Subscription;
  id;
  tree;
  settings;
  source: UsersDataSource;
  user;
  form;
  modal;
  closeResult;
  visible = false;
  timer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params['id']) {
        those.id = params['id'];
      }
    });
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        columnTitle: those.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        ID: {
          title: 'Id',
        },
        CreatedAt: {
          title: this.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
        Login: {
          title: this.translate.instant('Login'),
        },
        Email: {
          title: this.translate.instant('Email'),
        },
        EmailConfirmed: {
          title: this.translate.instant('Confirmed'),
          type: 'custom',
          renderComponent: UserEmailConfirmedRenderComponent,
        },
        Role: {
          title: this.translate.instant('Role'),
          type: 'custom',
          renderComponent: UserRoleRenderComponent,
        },
        Orders: {
          title: this.translate.instant('Orders'),
          type: 'custom',
          renderComponent: UserOrdersRenderComponent,
        },
        UpdatedAt: {
          title: this.translate.instant('Updated'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    this.source = new UsersDataSource(those.http, those.apiService, those.localStorageService, those.absolutizeUrl);
    this.source.parent = those;
    those.source.id = those.id;
  }

  setPerPage(value: number) {
    this.settings.pager.perPage = value;
    this.localStorageService.set('users_per_page', JSON.stringify(value));
    this.refresh(true);
  }

  refresh(hard?: boolean) {
    if (hard) {
      window.location.reload();
      return;
    } else {
      this.source.refresh();
    }
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getUser(id)
      .then(user => {
        those.user = user;
        those.form = those.formBuilder.group({
          ID: [{ disabled: true, value: user['ID'] }],
          Login: [{ disabled: true, value: user['Login'] }],
          Email: [user['Email']],
          EmailConfirmed: [user['EmailConfirmed'] ? true : false],
          Password: [''],
          Role: [user['Role']],
          Notification: [user['Notification'] ? true : false],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
          }
        );
      })
      .catch(err => {
        console.log('err', err);
        those.error('Load order', err);
      });
  }

  onDelete(event) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete user?';
    modal.componentInstance.body = `Are you sure you want to delete user? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteUser(event.data['ID'])
          .then(resp => {
            those.success('Delete user', resp);
            those.refresh();
          })
          .catch(err => {
            those.error('Delete user', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = those.form.getRawValue();
    this.apiService
      .putUser(those.user['ID'], raw)
      .then(resp => {
        those.success('Update user', resp);
        those.modal.close('Saved');
        those.refresh();
      })
      .catch(err => {
        those.error('Update user', err);
      });
  }
}
