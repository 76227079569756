import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ApiService } from '../../../shared/api.service';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  styles: [
    `
      .thumbnail-wrapper {
        border: 1px solid lightgray;
        border-radius: 5px;
        margin-bottom: 5px;
        padding: 5px;
        position: relative;
        text-align: center;
        width: 48px;
      }
      .thumbnail {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 36px;
      }
      .unset {
        background-color: white;
        border-radius: 50%;
        color: gray;
        cursor: pointer;
        line-height: 1;
        padding: 0 2px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    `,
  ],
  template: `
    <div [attr.id]="'prices-' + rowData['ID'] + '-thumbnail'" class="upload-tiny" style="height: 42px;">
      <div *ngIf="value" class="thumbnail-wrapper">
        <a [href]="value" target="_blank">
          <!--img
          [src]="absolutizeUrl.transform('/api/v1/resize?path=/storage' + value)"
          style="max-height: 45px;border-radius: 5px;margin-bottom: 5px;"-->
          <div
            class="thumbnail"
            [style]="{
              backgroundImage:
                'url(' +
                absolutizeUrl.transform(
                  '/api/v1/resize?path=' + (value ? '/storage' + value : '/assets/images/no-image.svg') + ')'
                )
            }"></div>
        </a>
        <div class="unset" (click)="unset()">&times;</div>
      </div>
      <app-file-uploader
        *ngIf="!value"
        id="thumbnail"
        [title]="''"
        [message]="''"
        (filesLoaded)="upload($event)"></app-file-uploader>
    </div>
  `,
})
export class PriceThumbnailRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;
  parent;

  constructor(
    public apiService: ApiService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const id = 'prices-' + this.rowData['ID'] + '-thumbnail';
    setTimeout(() => {
      const element = document.querySelector('#' + id);
      if (element) {
        const tr =
          element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
        if (tr) {
          if (this.rowData['CreatedAt'] === this.rowData['UpdatedAt']) {
            tr.classList.add('new');
          }
          tr.classList.remove('selected');
        }
      }
    }, 100);
  }

  unset() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete price thumbnail?';
    modal.componentInstance.body = `Are you sure you want to delete price thumbnail? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        const data = new FormData();
        data.append('Thumbnail', '');
        those.apiService
          .patchPrice(this.rowData.ID, 'setThumbnail', data)
          .then(resp => {
            for (let i = 0; i < those.parent.source.data.length; i++) {
              if (those.parent.source.data[i]['ID'] === those.rowData['ID']) {
                those.parent.source.data[i]['Thumbnail'] = resp.Thumbnail;
                those.parent.source.local = true;
                those.parent.source.refresh();
                break;
              }
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      },
      reason => {}
    );
  }

  upload(files) {
    const data = new FormData();
    data.append('Thumbnail', files[0]);
    this.apiService
      .patchPrice(this.rowData.ID, 'setThumbnail', data)
      .then(resp => {
        console.log('resp', resp);
        for (let i = 0; i < this.parent.source.data.length; i++) {
          if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
            this.parent.source.data[i]['Thumbnail'] = resp.Thumbnail;
            this.parent.source.local = true;
            this.parent.source.refresh();
            break;
          }
        }
        //price.Thumbnail = resp.Thumbnail;
      })
      .catch(err => {
        //this.error('Update prices', err);
        console.log('err', err);
      });
  }
}
