import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styles: [
    `
      div > span {
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div style="text-align: center;width: 100%;">
      <span style="cursor: pointer;" (click)="patch(!value)">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          [ngClass]="{ favorable: true, favorite: value, 'not-favorite': !value }">
          <path
            d="M9 1.61803L10.4328 6.02786C10.5667 6.43989 10.9507 6.71885 11.3839 6.71885H16.0207L12.2694 9.44427C11.919 9.69891 11.7723 10.1503 11.9062 10.5623L13.339 14.9721L9.58779 12.2467C9.2373 11.9921 8.7627 11.9921 8.41222 12.2467L4.66099 14.9721L6.09383 10.5623C6.2277 10.1503 6.08105 9.69892 5.73056 9.44427L1.97933 6.71885H6.6161C7.04933 6.71885 7.43329 6.43989 7.56716 6.02786L9 1.61803Z" />
        </svg>
      </span>
    </div>
  `,
})
export class FavoriteRenderComponent implements ViewCell {
  parent;
  method = 'patchProduct';
  @Input() value: string;
  @Input() rowData: any;

  constructor(public apiService: ApiService, private translate: TranslateService) {}

  patch(value) {
    let method = this.method;
    const name = this.parent?.parent?.constructor?.name;
    if ( name === 'MediasComponent') {
      method = 'patchMedia';
    }

    this.apiService[method](this.rowData['ID'], 'setFavorite', { Favorite: value })
      .then(resp => {
        for (let i = 0; i < this.parent.source.data.length; i++) {
          if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
            this.parent.source.data[i]['Favorite'] = value === true;
            this.parent.source.local = true;
            this.parent.source.refresh();
            break;
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}
