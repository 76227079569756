import { Component, Input } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      <div translate>{{value ? 'Yes' : 'No'}}</div>
  `,
})
export class BooleanRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;
}
