import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {AbsolutizeUrlPipe, MediaThumbnailPipe} from '../../../../shared/utils.pipe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'angular-2-local-storage';
import { SubjectService } from '../../../../shared/subject.service';
import { ApiService } from '../../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PricesBlockComponent } from '../prices-block.component';
import { credentials } from '../../../../shared/credentials.data';
import { BlocksBlockComponent } from '../blocks-block.component';
import { PropertiesBlockComponent } from '../properties-block.component';
import { ConfirmComponent } from '../../../../shared/modals/confirm/confirm.component';

declare var $: any;

@Component({
  selector: 'app-variation',
  templateUrl: './variation.component.html',
  styleUrls: ['./variation.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class VariationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(BlocksBlockComponent) blocksBlock: BlocksBlockComponent;
  @ViewChild(PropertiesBlockComponent) propertiesBlock: PropertiesBlockComponent;
  @ViewChild(PricesBlockComponent) pricesBlock: PricesBlockComponent;
  paramsSubscription: Subscription;
  pid;
  product;
  id;
  variation;
  form;
  formSubscription: Subscription;
  raw = false;
  changed = false;
  decimal = ',';
  prefix = '';
  thousands = '.';
  pattern = 'whd';
  dimensionUnit = 'cm';
  weightUnit = 'kg';
  tinymceConfig;
  info;
  infoSubscription: Subscription;
  maximized = false;
  filteredVariations = [];
  hasNext = false;
  hasPrev = false;
  term;
  loading = false;
  displayed = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private subjectService: SubjectService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private titleService: Title,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    public mediaThumbnail: MediaThumbnailPipe,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const those = this;
    //
    this.infoSubscription = this.subjectService.infoSubject.subscribe(info => {
      those.info = info;
      those.decimal = info.Decimal ? info.Decimal : '.';
      those.thousands = info.Thousand ? info.Thousand : '';
      those.prefix = info.Symbol ? info.Symbol : '$';
      those.pattern = info.Pattern;
      those.dimensionUnit = info.DimensionUnit;
      those.weightUnit = info.WeightUnit;
    });
    //
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params.pid) {
        those.pid = params.pid;
        those.loading = true;
        those.apiService
          .getProduct(params.pid)
          .then(product => {
            //console.log('product', product);
            product.Prices = product.Prices || [];
            those.product = product;
            those.filteredVariations = product.Variations;
            if (params.id) {
              if (params.id === 'new') {
                const variation = { Enabled: true };
                those.form = those.createForm(variation);
              } else {
                those.id = params.id;
                those.apiService
                  .getVariation(params.id)
                  .then(variation => {
                    //console.log('variation', variation);
                    variation.Prices = variation.Prices || [];
                    those.titleService.setTitle(variation.Title + ' :: ' + product.Title + ' :: ' + credentials.name);
                    those.form = those.createForm(variation);
                    those.variation = variation;
                    const index = those.product.Variations.findIndex(item => item.ID === those.variation.ID);
                    those.hasPrev = index > 0;
                    those.hasNext = index < those.product.Variations.length - 1;
                    those.loading = false;
                  })
                  .catch(err => {
                    those.error('Get variation', err);
                    those.router.navigate(['/products/' + those.product.ID]);
                  });
              }
            }
          })
          .catch(err => {
            those.error('Get product', err);
          });
      }
    });
    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: true,
      toolbar:
        'undo redo | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | ' +
        'forecolor backcolor emoticons',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  ngAfterViewInit(): void {
    $('body').scrollspy({ offset: 100, target: '#navbar-left' });
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(credentials.name);
    this.infoSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  scroll(id) {
    const yOffset = -90;
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return false;
  }

  deepCompare() {
    let i;
    let l;
    let leftChain;
    let rightChain;
    function compare2Objects(x, y) {
      let p;
      if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
        return true;
      }
      if (x === y) {
        return true;
      }
      if (
        (typeof x === 'function' && typeof y === 'function') ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)
      ) {
        return x.toString() === y.toString();
      }
      if (!(x instanceof Object && y instanceof Object)) {
        return false;
      }
      if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false;
      }
      if (x.constructor !== y.constructor) {
        return false;
      }
      if (x.prototype !== y.prototype) {
        return false;
      }
      if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
        return false;
      }
      for (p in y) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false;
        } else if (typeof y[p] !== typeof x[p]) {
          return false;
        }
      }
      for (p in x) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false;
        } else if (typeof y[p] !== typeof x[p]) {
          return false;
        }
        switch (typeof x[p]) {
          case 'object':
          case 'function':
            leftChain.push(x);
            rightChain.push(y);
            if (!compare2Objects(x[p], y[p])) {
              return false;
            }
            leftChain.pop();
            rightChain.pop();
            break;
          default:
            if (x[p] !== y[p]) {
              return false;
            }
            break;
        }
      }
      return true;
    }
    if (arguments.length < 1) {
      return true;
    }
    for (i = 1, l = arguments.length; i < l; i++) {
      leftChain = [];
      rightChain = [];
      if (!compare2Objects(arguments[0], arguments[i])) {
        return false;
      }
    }
    return true;
  }

  createForm(variation) {
    const those = this;
    const form = this.formBuilder.group({
      ID: [variation.ID],
      Enabled: [variation.Enabled ? true : false],
      Name: [variation.Name, [Validators.required]],
      Title: [variation.Title, [Validators.required]],
      Thumbnail: [variation.Thumbnail ? those.absolutizeUrl.transform('/storage' + variation.Thumbnail) : ''],
      Description: [variation.Description ? variation.Description : ''],
      Notes: [variation.Notes ? variation.Notes : ''],
      MediaId: [
        variation.MediaId
          ? variation.MediaId
          : variation.Medias && variation.Medias.length > 0
          ? variation.Medias[0].ID
          : '',
      ],
      Pattern: [variation.Pattern ? variation.Pattern : this.pattern],
      Dimensions: [variation.Dimensions ? variation.Dimensions : ''],
      DimensionUnit: [variation.DimensionUnit ? variation.DimensionUnit : this.dimensionUnit],
      Width: [variation.Width ? variation.Width : 0],
      Height: [variation.Height ? variation.Height : 0],
      Depth: [variation.Depth ? variation.Depth : 0],
      Volume: [variation.Volume ? variation.Volume : 0.0],
      Weight: [variation.Weight ? variation.Weight : 0.0],
      WeightUnit: [variation.WeightUnit ? variation.WeightUnit : this.weightUnit],
      Packages: [variation.Packages ? variation.Packages : 1],
      Sku: [variation.Sku ? variation.Sku : ''],
      Barcode: [variation.Barcode ? variation.Barcode : ''],
      Availability: [variation.Availability ? variation.Availability : 'available'],
      TrackQuantity: [variation.TrackQuantity ? variation.TrackQuantity : false],
      Overselling: [variation.Overselling ? variation.Overselling : false],
      Stock: [variation.Stock ? variation.Stock : 0],
      BasePrice: [variation.BasePrice ? variation.BasePrice : 0],
      ManufacturerPrice: [variation.ManufacturerPrice ? variation.ManufacturerPrice : null],
      ItemPrice: [variation.ItemPrice ? variation.ItemPrice : null],
      OnSale: [variation.OnSale ? true : false],
      SalePrice: [variation.SalePrice ? variation.SalePrice : null],
      Start: [
        variation.Start && variation.Start.indexOf('0001-01-01') === -1
          ? moment(variation.Start).format('YYYY-MM-DD HH:mm:ss')
          : null,
      ],
      End: [
        variation.End && variation.End.indexOf('0001-01-01')
          ? moment(variation.End).format('YYYY-MM-DD HH:mm:ss')
          : null,
      ],
      AdvancedPurchasability: [variation.AdvancedPurchasability ? true : false],
      MinQuantity: [variation.MinQuantity ? variation.MinQuantity : 0],
      MaxQuantity: [variation.MaxQuantity ? variation.MaxQuantity : 0],
      PurchasableMultiply: [variation.PurchasableMultiply ? variation.PurchasableMultiply : 0],
      Measurement: [variation.Measurement ? true : false],
      BaseMeasurement: [variation.BaseMeasurement ? variation.BaseMeasurement : 0],
      BaseMeasurementUnit: [variation.BaseMeasurementUnit ? variation.BaseMeasurementUnit : 'kg'],
      TotalMeasurement: [variation.TotalMeasurement ? variation.TotalMeasurement : 0],
      TotalMeasurementUnit: [variation.TotalMeasurementUnit ? variation.TotalMeasurementUnit : 'kg'],
      AdvancedPricing: [variation.DiscountType ? true : false],
      Discount: [variation.Discount ? true : false],
      DiscountType: [variation.DiscountType ? variation.DiscountType : 'percent'],
      Sort: [variation.Sort ? variation.Sort : 0],
      /**/
      //Properties: [''], // Virtual object
      Prices: [''], // Virtual object
    });
    form.addControl(
      'Tiers',
      this.formBuilder.array(
        (variation.Tiers || []).map(tier => {
          return this.formBuilder.group({
            MinQuantity: [tier.MinQuantity, [Validators.required]],
            Discount: [tier.Discount, [Validators.required]],
          });
        })
      )
    );
    //console.log('form', form);
    // Need to wait for form fully created
    setTimeout(() => {
      those.raw = form.getRawValue();
      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
      }
      this.formSubscription = form.valueChanges.subscribe(x => {
        const raw = form.getRawValue();
        those.changed = !those.deepCompare.call(
          this,
          JSON.parse(JSON.stringify(those.raw)),
          JSON.parse(JSON.stringify(raw))
        );
      });
    }, 1000);
    return form;
  }

  setEnabled(enabled) {
    this.form.get('Enabled').setValue(enabled);
    this.form.markAsDirty();
  }

  onChangeTitle(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLocaleLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  search(event) {
    const value = event.target['value'].toLowerCase();
    console.log('search', value);
    this.filteredVariations = this.product.Variations.filter(item => item.Title.toLowerCase().indexOf(value) >= 0);
    console.log('found', this.filteredVariations);
    event.preventDefault();
    event.stopPropagation();
  }

  next() {
    const those = this;
    const index = this.product.Variations.findIndex(item => item.ID === those.variation.ID);
    if (index < this.product.Variations.length - 1) {
      this.select(this.product.Variations[index + 1].ID);
    }
  }

  prev() {
    const those = this;
    const index = this.product.Variations.findIndex(item => item.ID === those.variation.ID);
    if (index > 0) {
      this.select(this.product.Variations[index - 1].ID);
    }
  }

  select(id) {
    const those = this;
    this.term = '';
    this.loading = true;
    those.router.navigate(['/products/' + those.product.ID + '/variations/' + id]);
  }

  refresh(event?) {
    const those = this;
    switch (event) {
      case 'properties':
        this.apiService
          .getVariation(this.variation.ID)
          .then(variation => {
            those.variation.Properties = [...variation.Properties];
            those.variation.Prices = [...(variation.Prices || [])];
            //those.subjectService.events.next({type: 'refresh-properties'});
            setTimeout(() => {
              those.pricesBlock.onGenerate();
            }, 500);
          })
          .catch(err => {
            those.error('Load variation', err);
          });
        break;
    }
  }

  onClone() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Clone variation?';
    modal.componentInstance.body = 'Are you sure you want to clone variation?';
    modal.componentInstance.confirm = 'Clone';
    modal.result.then(
      result => {
        those.form.get('ID').setValue(undefined);
        let name = this.form.get('Name').value;
        let title = this.form.get('Title').value;
        for (;;) {
          let found = false;
          for (let i = 0; i < this.product.Variations.length; i++) {
            if (this.product.Variations[i].Name === name) {
              found = true;
            }
          }
          if (found) {
            const res = name.match(/\((\d+)\)$/);
            if (res) {
              name.replace(new RegExp('\\(' + res[1] + '\\)$', ''), '(' + (res[1] + 1) + ')');
              title.replace(new RegExp('\\(' + res[1] + '\\)$', ''), '(' + (res[1] + 1) + ')');
            } else {
              name += ' (2)';
              title += ' (2)';
            }
          } else {
            break;
          }
        }
        those.apiService
          .patchVariation(those.variation.ID, 'clone', {
            Name: name,
            Title: title,
            Sort: those.product.Variations.length + 1,
          })
          .then((resp: { ID: number }) => {
            those.router.navigate(['/products/' + those.product.ID + '/variations/' + resp.ID]);
          })
          .catch(err => {
            those.error('Clone variation', err);
          });
      },
      reason => {}
    );
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete variation?';
    modal.componentInstance.body = `Are you sure you want to delete variation? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVariation(id)
          .then(resp => {
            those.router.navigate(['/products/' + those.product.ID]);
          })
          .catch(err => {
            those.error('Delete variation', err);
          });
      },
      reason => {}
    );
  }

  async onSubmit() {
    const those = this;
    let generate = false;
    // Blocks
    if (this.form.get('Blocks')?.dirty && this.blocksBlock) {
      this.blocksBlock.onSave();
    }
    // Properties
    if (this.form.get('Properties')?.dirty && this.propertiesBlock) {
      await this.propertiesBlock.onSave();
      generate = true;
    }
    // Prices
    if (this.form.get('Prices')?.dirty && this.pricesBlock) {
      this.pricesBlock.onSave();
    }
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Description', this.form.get('Description').value ? this.form.get('Description').value : '');
    data.append('Notes', this.form.get('Notes').value);
    data.append('MediaId', this.form.get('MediaId').value);
    data.append('BasePrice', this.form.get('BasePrice').value);
    data.append('ManufacturerPrice', this.form.get('ManufacturerPrice').value);
    data.append('ItemPrice', this.form.get('ItemPrice').value);
    data.append('OnSale', this.form.get('OnSale').value);
    data.append('SalePrice', this.form.get('SalePrice').value);
    if (this.form.get('Start').value) {
      data.append('Start', moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (this.form.get('End').value) {
      data.append('End', moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    data.append('AdvancedPurchasability', this.form.get('AdvancedPurchasability').value);
    data.append('MinQuantity', this.form.get('MinQuantity').value);
    data.append('MaxQuantity', this.form.get('MaxQuantity').value);
    data.append('PurchasableMultiply', this.form.get('PurchasableMultiply').value);
    data.append('Measurement', this.form.get('Measurement').value);
    data.append('TotalMeasurement', this.form.get('TotalMeasurement').value);
    data.append('TotalMeasurementUnit', this.form.get('TotalMeasurementUnit').value);
    data.append('BaseMeasurement', this.form.get('BaseMeasurement').value);
    data.append('BaseMeasurementUnit', this.form.get('BaseMeasurementUnit').value);
    data.append('Discount', this.form.get('Discount').value);
    data.append('DiscountType', this.form.get('DiscountType').value);
    data.append('Pattern', this.form.get('Pattern').value);
    data.append('Dimensions', this.form.get('Dimensions').value);
    data.append('DimensionUnit', this.form.get('DimensionUnit').value);
    data.append(
      'Tiers',
      JSON.stringify(
        this.form
          .get('Tiers')
          .getRawValue()
          .map(tier => {
            tier.MinQuantity = Number(tier.MinQuantity);
            tier.Discount = Number(tier.Discount);
            return tier;
          })
      )
    );
    data.append('Width', this.form.get('Width').value);
    data.append('Height', this.form.get('Height').value);
    data.append('Depth', this.form.get('Depth').value);
    data.append('Volume', this.form.get('Volume').value);
    data.append('Weight', this.form.get('Weight').value);
    data.append('WeightUnit', this.form.get('WeightUnit').value);
    data.append('Packages', this.form.get('Packages').value);
    data.append('Sku', this.form.get('Sku').value);
    data.append('Barcode', this.form.get('Barcode').value);
    data.append('Availability', this.form.get('Availability').value);
    data.append('TrackQuantity', this.form.get('TrackQuantity').value);
    data.append('Overselling', this.form.get('Overselling').value);
    data.append('Stock', this.form.get('Stock').value);
    data.append('Sort', this.form.get('Sort').value);
    //data.append('Parameters', JSON.stringify(this.product.Parameters));
    //data.append('CustomParameters', this.form.get('CustomParameters').value);
    data.append('ProductId', this.pid);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postVariation(data)
        .then(variation => {
          console.log('variation', variation);
          those.form.markAsPristine();
          those.form.markAsUntouched();
          those.router.navigate(['/products/' + those.product.ID + '/variations/' + variation.ID]);
        })
        .catch(err => {
          those.error('Create variation', err);
        });
    } else {
      // update
      this.apiService
        .putVariation(those.form.get('ID').value, data)
        .then(resp => {
          console.log('resp', resp);
          those.changed = false;
          those.raw = those.form.getRawValue();
          those.form.markAsPristine();
          those.form.markAsUntouched();
          if (generate) {
            those.refresh('properties');
          }
        })
        .catch(err => {
          those.error('Update variation', err);
        });
    }
  }
}
