import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {AbsolutizeUrlPipe} from '../../shared/utils.pipe';

@Component({
  template: `
    <a [href]="absolutizeUrl.transform('/storage' + rowData.Path)" target="_blank" download>
      <svg style="margin-right:5px; height: 20px; width: 20px;vertical-align: middle">
        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-download"></use>
      </svg>
    </a>
  `,
})
export class MediaActionsRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {
  }

  ngOnInit(): void { }
}
