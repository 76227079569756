import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-message',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title body-2" id="exampleModalLabel" translate>Message</h5>
        <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
          <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5" />
            <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5" />
          </svg>
        </div>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div *ngIf="message && message.Created" class="row">
              <div class="col">
                <div class="form-group">
                  <label for="created" class="mb-1" translate>Created</label>
                  <input
                    class="form-control"
                    id="created"
                    type="text"
                    [value]="message.Created | amDateFormat: 'DD.MM.YYYY hh:mm'"
                    disabled="true" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="updated" class="mb-1" translate>Updated</label>
                  <input
                    class="form-control"
                    id="updated"
                    type="text"
                    [value]="message.Updated | amDateFormat: 'DD.MM.YYYY hh:mm'"
                    disabled="true" />
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="message" class="col">
                <div class="form-group">
                  <label for="from" class="mb-1" translate>From</label>
                  <input class="form-control" id="from" type="text" formControlName="From" />
                </div>
              </div>
              <div *ngIf="!message || layout != 'form'" class="col">
                <div class="form-group">
                  <label for="to" class="mb-1" translate>To</label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="to" formControlName="To" />
                    <!--div class="input-group-append">
                          <button class="btn btn-secondary">Send</button>
                        </div-->
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Topic</label>
                  <input class="form-control" id="title" type="text" formControlName="Topic" />
                </div>
              </div>
            </div>
            <!-- -->
            <div *ngIf="message && message.Properties; else raw">
              <div *ngIf="message.Properties.FormId" class="form-layer">
                <div [ngSwitch]="message.Properties.Module">
                  <div *ngSwitchCase="'Samples'">
                    <div class="form-group">
                      <label class="mb-1" translate>Message</label>
                      <div class="row">
                        <div *ngIf="message.Message['Address']" class="col-lg-6">
                          <div class="to">
                            <div>
                              <span translate>Name</span
                              ><span
                                >{{ message.Message['Address']['name'] }}
                                {{ message.Message['Address']['lastname'] }}</span
                              >
                            </div>
                            <div>
                              <span translate>Email</span><span>{{ message.Message['Address']['email'] }}</span>
                            </div>
                            <div>
                              <span translate>Phone</span><span>{{ message.Message['Address']['phone'] }}</span>
                            </div>
                            <div>
                              <span translate>Address</span
                              ><span
                                >{{ message.Message['Address']['address'] }} {{ message.Message['Address']['city'] }}
                                {{ message.Message['Address']['country'] }}</span
                              >
                            </div>
                            <div>
                              <span translate>ZIP</span><span>{{ message.Message['Address']['zip'] }}</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="message.Message['Samples']" class="col-lg-6">
                          <div *ngFor="let sample of message.Message['Samples']; let i = index">
                            <span style="padding-right: 5px;">#{{ sample['ID'] }}</span>
                            <span style="padding-right: 5px;">{{ sample['Topic'] }}</span>
                            <span>({{ sample['Value'] }})</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchDefault>[Another form]</div>
                </div>
              </div>
            </div>
            <ng-template #raw>
              <div class="form-group">
                <label for="body" class="mb-1" translate>Message</label>
                <textarea
                  class="form-control"
                  id="body"
                  rows="5"
                  (focusout)="touched = true"
                  formControlName="Message"></textarea>
              </div>
            </ng-template>
            <div *ngIf="message && message.Error" class="row">
              <div class="col">
                <div class="form-group">
                  <label for="body" class="mb-1" translate>Error</label>
                  <textarea
                    class="form-control"
                    id="body"
                    rows="3"
                    [ngModel]="message.Error"
                    [ngModelOptions]="{ standalone: true }"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            id="save"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            translate>
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span style="vertical-align: text-bottom">Save</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      .to span:nth-child(2) {
        float: right;
      }
      .key-value .key:after {
        content: ':';
        padding-right: 5px;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class MessageComponent implements OnInit, AfterViewInit {
  @Input('formType') formType;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  touched = false;
  message;
  modal;
  closeResult;
  form;
  timer;
  layout = 'raw';

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    those.form = those.formBuilder.group({
      ID: [],
      Type: ['outgoing'],
      Kind: ['email'],
      From: [{ disabled: true, value: '' }],
      To: [''],
      Topic: ['', Validators.required],
      Message: [''],
    });
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
        those.onSaved.emit();
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getMessage(id)
      .then(message => {
        if (message.Properties && message.Properties.FormId) {
          this.layout = 'form';
          if (message.Properties.Module === 'Samples') {
            try {
              message.Message = JSON.parse(message.Message);
            } catch (e) {
              console.log('error', e);
            }
          }
        }
        those.message = message;
        those.form = those.formBuilder.group({
          ID: [message['ID']],
          From: [{ disabled: message['Type'] === 'outgoing', value: message['From'] }],
          To: [message['To']],
          Topic: [message['Topic'], Validators.required],
          Message: [message['Message']],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load message', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete message?';
    modal.componentInstance.body = `Are you sure you want to delete message? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteMessage(id)
          .then(resp => {
            those.success('Delete message', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete message', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = { ...this.message, ...this.form.getRawValue() };
    if (!this.form.get('ID').value) {
      this.apiService
        .postMessage(raw)
        .then(resp => {
          those.success('Create message', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create message', err);
        });
    } else {
      // update
      this.apiService
        .putMessage(those.form.get('ID').value, raw)
        .then(resp => {
          those.success('Update message', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update message', err);
        });
    }
  }
}
