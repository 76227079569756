import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-transfer',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title body-2" id="exampleModalLabel" translate>Add transfer</h5>
        <span class="cross" (click)="modal.dismiss('Cross click')">
          <svg style="margin-right:10px; height: 12px; width: 12px;">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
          </svg>
        </span>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label for="adjustment" class="mb-1" translate>Adjustment</label>
              <input
                class="form-control"
                id="adjustment"
                type="number"
                (focusin)="trim(form.get('Adjustment'))"
                (focusout)="format(form.get('Adjustment'))"
                (change)="setQuantity($event.target['value'])"
                formControlName="Adjustment" />
            </div>
          </div>
          <div class="form">
            <div class="form-group">
              <label for="quantity" class="mb-1" translate>Description</label>
              <textarea class="form-control" rows="3" formControlName="Description"></textarea>
            </div>
          </div>
          <div class="form">
            <div class="form-group">
              <label for="quantity" class="mb-1" translate>Quantity</label>
              <input
                class="form-control"
                id="quantity"
                type="number"
                (focusin)="trim(form.get('Quantity'))"
                (focusout)="format(form.get('Quantity'))"
                formControlName="Quantity" />
            </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: normal">
          <button
            type="submit"
            id="save"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid || !form.touched"
            translate>
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span style="vertical-align: text-bottom">Save</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [``],
  providers: [AbsolutizeUrlPipe],
})
export class TransferComponent implements OnInit {
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('priceId') priceId = 0;
  @Input('stock') stock = 0;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  transfer;
  modal;
  closeResult;
  form;
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  trim(control) {
    if (control && control.value !== undefined) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control, decimal?) {
    const value = Number.parseFloat(control.value)
      .toFixed(decimal)
      .replace(/([0-9]+\.[0-9]+?)0*$/, '$1');
    if (value) {
      control.setValue(value);
    }
    return true;
  }

  setQuantity(value) {
    this.form.get('Quantity').setValue(this.stock + Number(value));
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Adjustment: [0, Validators.required],
      Description: [''],
      Quantity: [those.stock],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        those.onSaved.emit({ ...those.transfer });
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getTransfer(id)
      .then(transfer => {
        those.transfer = transfer;
        those.form = those.formBuilder.group({
          ID: [transfer.ID],
          Quantity: [transfer.Quantity, Validators.required],
          Description: [transfer.Description],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.onSaved.emit({ ...those.transfer });
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load transfer', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete transfer?';
    modal.componentInstance.body = `Are you sure you want to delete transfer? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteTransfer(id)
          .then(resp => {
            those.success('Delete transfer', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete transfer', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = {
      ProductId: this.productId,
      VariationId: this.variationId,
      PriceId: this.priceId,
      ...this.form.getRawValue(),
    };
    raw['Adjustment'] = Number(raw['Adjustment']) || 0;
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postTransfer(raw)
        .then(transfer => {
          those.transfer = transfer;
          those.modal.close('Saved');
        })
        .catch(err => {
          those.error('Create transfer', err);
        });
    } else {
      // update
      this.apiService
        .putTransfer(this.form.get('ID').value, raw)
        .then(transfer => {
          those.transfer = transfer;
          those.modal.close('Updated');
        })
        .catch(err => {
          those.error('Update transfer', err);
        });
    }
  }
}
