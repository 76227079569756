import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { TransactionsDataSource } from './transactions.data-source';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedRenderComponent } from '../../../shared/render/selected-render.component';
import { DateRenderComponent } from '../../../shared/render/date-render.component';
import { TransactionNameRenderComponent } from './transaction-name-render.component';
import { TransactionStatusRenderComponent } from './transaction-status-render.component';
import { TransactionAmountRenderComponent } from './transaction-amount-render.component';
import { TransactionOrderRenderComponent } from './transaction-order-render.component';
import { TransactionPaymentRenderComponent } from './transaction-payment-render.component';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class TransactionsComponent implements OnInit {
  id;
  tree;
  settings;
  source: TransactionsDataSource;
  term;
  form;
  modal;
  closeResult;
  visible = false;
  filtered = true;
  timer;
  widths;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Transactions',
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '2',
      Name: 'new',
      Label: 'New',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Created',
            Label: 'Created',
            Type: 'date',
            Value: {
              Label: 'Last 7 days',
              Value: 'last 7 days',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'pending',
      Label: 'Pending',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Status',
            Label: 'Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'pending',
                name: 'Pending',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '4',
      Name: 'complete',
      Label: 'Complete',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Status',
            Label: 'Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'complete',
                name: 'Complete',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '5',
      Name: 'rejected',
      Label: 'Rejected',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Status',
            Label: 'Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'rejected',
                name: 'Rejected',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [
    {
      Name: 'set-standard',
      Label: 'Set standard',
    },
    {
      Name: 'set-not-standard',
      Label: 'Set not standard',
    },
  ];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    ID: {
      title: 'Id',
      filter: false,
      sort: true,
    },
    Created: {
      title: 'Created',
      type: 'custom',
      renderComponent: DateRenderComponent,
      filter: false,
      sort: true,
    },
    Name: {
      title: 'Customer',
      type: 'custom',
      renderComponent: TransactionNameRenderComponent,
      filter: false,
      sort: true,
    },
    OrderId: {
      title: 'Order',
      type: 'custom',
      renderComponent: TransactionOrderRenderComponent,
      filter: false,
      sort: true,
    },
    Payment: {
      title: 'Payment',
      type: 'custom',
      renderComponent: TransactionPaymentRenderComponent,
      filter: false,
      sort: true,
    },
    Status: {
      title: 'Status',
      type: 'custom',
      renderComponent: TransactionStatusRenderComponent,
      filter: false,
      sort: true,
    },
    Amount: {
      title: 'Total',
      type: 'custom',
      renderComponent: TransactionAmountRenderComponent,
      filter: false,
      sort: true,
    },
  };

  filters = [
    {
      Name: 'Created',
      Label: 'Created',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'Status',
      Label: 'Status',
      Type: 'multiselect',
      Values: [
        {
          id: 'new',
          name: 'New',
        },
        {
          id: 'pending',
          name: 'Pending',
        },
        {
          id: 'completed',
          name: 'Completed',
        },
        {
          id: 'rejected',
          name: 'Rejected',
        },
      ],
    },
    {
      Name: 'Amount',
      Label: 'Total',
      Placeholder: '>0',
      Value: '',
    },
  ];

  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Amount',
      Label: 'Total',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
  ];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    protected http: HttpClient,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit() {}
}
