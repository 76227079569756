import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { LocalStorageService } from 'angular-2-local-storage';
import {SubjectService} from "../../shared/subject.service";

@Injectable()
export class MediasDataSource extends LocalDataSource {
  parent: any;
  defaultFilter;
  local = false;
  arguments;
  data: any[];
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;
  perPage;
  callback;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    perPage = this.perPage || JSON.parse(this.localStorageService.get('medias_per_page')) || 10;

    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    // Local sort refresh
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    //
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.parent.term) {
      request['Search'] = this.parent.term;
    }
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters || this.defaultFilter) {
      request['Filter'] = this.defaultFilter || {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    //
    const u = new URL((url += '/medias/list'));
    for (const key in those.arguments) {
      if (those.arguments.hasOwnProperty(key)) {
        u.searchParams.set(key, those.arguments[key]);
      }
    }
    return this.http
      .post(u.toString(), request, { headers: headersObject, withCredentials: true })
      .pipe(
        map(response => {
          those.lastRequestFiltered = +response['Filtered'];
          those.lastRequestCount = +response['Filtered'];
          those.lastRequestTotal = +response['Total'];
          those.parent.visible = true;
          if (those.parent && those.parent.selected) {
            response['Data'].forEach((item, i) => {
              const index = those.parent.selected.findIndex(item2 => item2.ID === item.ID);
              if (index >= 0) {
                response['Data'][i].Selected = true;
              }
            });
          }
          if (response['Columns']) {
            if (
              response['Columns']['ContentTypes'] &&
              those.parent &&
              those.parent.filters &&
              (!those.parent.filters.ContentType ||
                those.parent.filters.ContentType.length < response['Columns']['ContentTypes'].length)
            ) {
              those.parent.filters.ContentType = [
                { id: '^image/(jpg|jpeg|png)', name: 'All images' },
                { id: '!^image/(jpg|jpeg|png)', name: 'All files' },
              ];
              response['Columns']['ContentTypes'].forEach(item => {
                those.parent.filters.ContentType.push({ id: item, name: item });
              });
            }
          }
          const info = those.subjectService.infoSubject.getValue();
          this.data = (response['Data'] || []).map(item => {
            if (item.Path && info.StoragePublicUrl) {
              item.Path = info.StoragePublicUrl + item.Path;
            }
            return item;
          });
          if (those.callback && typeof those.callback === 'function') {
            setTimeout(() => {
              those.callback.call(those);
            }, 1000);
          }
          return this.data;
        })
      )
      .toPromise();
  }
}
