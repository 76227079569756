<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="page-header" style="padding-bottom: 15px">
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <div class="pull-right">
          <div class="dropdown">
            <span class="btn btn-plain btn-add" [ngClass]="{disabled: page || !pages}" data-toggle="dropdown" translate> Create new</span>
            <ul class="dropdown-menu">
              <li class="dropdown-item" data-toggle="modal" data-original-title="test"
                  data-target="#exampleModal" (click)="onCreateFolder(content)" translate>Folder</li>
              <li class="dropdown-item" (click)="onCreateFile(content)" translate>Page</li>
            </ul>
          </div>
          <!-- Modal -->
          <ng-template #content let-modal>
            <div class="modal-header">
              <h5 *ngIf="createForm.get('Type').value == 'folder'" class="modal-title f-w-600" translate>
                Create Folder
              </h5>
              <h5 *ngIf="createForm.get('Type').value == 'file'" class="modal-title f-w-600" translate>
                Create Page
              </h5>
              <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form class="needs-validation" [formGroup]="createForm" (ngSubmit)="onCreateFormSubmit()">
              <div class="modal-body">
                <div class="form">
                  <div class="form-group">
                    <label class="mb-1" translate>Title</label>:
                    <input class="form-control" type="text" (keyup)="change(createForm, $event)" formControlName="Title">
                  </div>
                </div>
                <div class="form">
                  <div class="form-group">
                    <label class="mb-1" translate>Name</label>:
                    <input class="form-control" type="text" pattern="^[^\/]+$" formControlName="Name">
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-default" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')" translate>Close</button>
                <button type="submit" class="btn btn-primary" translate>Save</button>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div>
            <div *ngIf="!page">
              <!-- Top -->
              <div *ngIf="pages;else loading0">
                <div style="display: inline-block; padding-left: 10px">
                  <span class="chunk" (click)="cd('/')" translate>Pages</span>
                  <span *ngFor="let chunk of split(pages['Path'])" class="chunk" (click)="cd(chunk['path'])">{{chunk['name']}}</span>
                </div>
              </div>
              <ng-template #loading0>
                <div style="text-align:center; width: 100%"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
              </ng-template>
              <!-- List -->
              <div *ngIf="pages" [style]="{opacity: loading1 ? 0.3 : 1}" class="pages" style="padding-top: 10px">
                <div *ngIf="pages['Children'] && pages['Children'].length > 0; else empty">
                  <div *ngFor="let page of pages['Children']" class="page">
                    <div *ngIf="!page['File']" class="folder">
                      <span (click)="cd(page['Path'])">{{page['Title'] ? page['Title'] : page['Name']}}</span>
                      <div class="d-inline-block group-menu">
                        <div class="dropdown">
                          <span class="btn dropdown-toggle no-caret" style="padding: 6px 10px;" data-toggle="dropdown">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 10.0002C8.33203 9.07969 9.07822 8.3335 9.9987 8.3335C10.9192 8.3335 11.6654 9.07969 11.6654 10.0002C11.6654 10.9206 10.9192 11.6668 9.9987 11.6668C9.07822 11.6668 8.33203 10.9206 8.33203 10.0002Z" fill="#616573"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 4.16667C8.33203 3.24619 9.07822 2.5 9.9987 2.5C10.9192 2.5 11.6654 3.24619 11.6654 4.16667C11.6654 5.08714 10.9192 5.83333 9.9987 5.83333C9.07822 5.83333 8.33203 5.08714 8.33203 4.16667Z" fill="#616573"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 15.8332C8.33203 14.9127 9.07822 14.1665 9.9987 14.1665C10.9192 14.1665 11.6654 14.9127 11.6654 15.8332C11.6654 16.7536 10.9192 17.4998 9.9987 17.4998C9.07822 17.4998 8.33203 16.7536 8.33203 15.8332Z" fill="#616573"/>
                            </svg>
                          </span>
                          <ul class="dropdown-menu dropdown-menu-right">
                            <li class="dropdown-item" (click)="delete(page['Path'])" translate>Delete</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="page['File']" class="file" style="position: relative;">
                      <div style="margin-bottom: 0" (click)="open(page['Path'])">
                        <span *ngIf="page['Name'] == 'index.html'" style="padding-right: 5px">{{page['Name']}}:</span>
                        <span *ngIf="page['Draft']" style="padding-right: 10px;color: gray;" translate>[DRAFT]</span>
                        <span>{{page['Title'] ? page['Title'] : page['Name']}}</span>
                        <div *ngIf="page['Title']" class="description">{{page['Name']}}</div>
                      </div>
                      <div style="position: relative">
                        <div class="description" style="display: inline-block"><div translate>last edited <span title="{{page['Modified'] | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}">{{page['Modified'] | amTimeAgo}}</span></div></div>
                        <div class="d-inline-block group-menu" style="display: inline-block">
                          <div class="dropdown">
                            <span class="btn dropdown-toggle no-caret" style="padding: 6px 10px;" data-toggle="dropdown">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 10.0002C8.33203 9.07969 9.07822 8.3335 9.9987 8.3335C10.9192 8.3335 11.6654 9.07969 11.6654 10.0002C11.6654 10.9206 10.9192 11.6668 9.9987 11.6668C9.07822 11.6668 8.33203 10.9206 8.33203 10.0002Z" fill="#616573"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 4.16667C8.33203 3.24619 9.07822 2.5 9.9987 2.5C10.9192 2.5 11.6654 3.24619 11.6654 4.16667C11.6654 5.08714 10.9192 5.83333 9.9987 5.83333C9.07822 5.83333 8.33203 5.08714 8.33203 4.16667Z" fill="#616573"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 15.8332C8.33203 14.9127 9.07822 14.1665 9.9987 14.1665C10.9192 14.1665 11.6654 14.9127 11.6654 15.8332C11.6654 16.7536 10.9192 17.4998 9.9987 17.4998C9.07822 17.4998 8.33203 16.7536 8.33203 15.8332Z" fill="#616573"/>
                              </svg>
                            </span>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li class="dropdown-item" (click)="draft(page['Path'])"><span *ngIf="!page['Draft']" translate>Set as draft</span><span *ngIf="page['Draft']" translate>Unset as draft</span></li>
                              <li class="dropdown-item" (click)="open(page['Path'], true)" translate>Duplicate</li>
                              <li class="dropdown-item" (click)="delete(page['Path'])" translate>Delete</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="loading1" style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
              </div>
              <ng-template #empty>
                <div style="color: lightgray;padding: 10px;text-align: center" translate translate>Empty</div>
              </ng-template>
            </div>
            <div *ngIf="page && !loading2">
              <form class="needs-validation" [formGroup]="editForm" (ngSubmit)="onEditFormSubmit()">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form">
                      <input type="hidden" formControlName="Type">
                      <div class="form-group">
                        <label class="mb-1" translate>Title</label>:
                        <input class="form-control" type="text" (keyup)="change(editForm, $event)" formControlName="Title">
                      </div>
                      <div class="form-group">
                        <label class="mb-1" translate>Name</label>:
                        <input class="form-control" type="text" formControlName="Name">
                        <div *ngIf="editForm.controls['Name'].touched && editForm.controls['Name'].errors" style="color: red">
                          <div *ngIf="editForm.controls['Name'].errors['invalidExtension']" translate>should contains trailing .html</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="mb-1" translate>Description</label>:
                        <textarea class="form-control" rows="3" formControlName="Description"></textarea>
                      </div>
                      <div class="form-group">
                        <label class="d-block" for="draft">
                          <input class="checkbox_animated" id="draft" type="checkbox" formControlName="Draft">
                          {{'Draft' | translate}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="name" class="mb-1">
                        <a href="{{page['Url']}}" target="_blank">{{page['Url']}}</a>
                      </label>
                      <app-tinymce [options]="config" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Content"></app-tinymce>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col buttons">
                    <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="editForm.invalid" translate>
                      <svg>
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                      </svg>
                      <span style="vertical-align: text-bottom">Save</span>
                    </button>
                    <button class="btn btn-cancel" type="button" (click)="close()" translate>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="loading2" style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
