import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenusRoutingModule } from './menus-routing.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import {MenusComponent} from './menus.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MenuComponent} from './menu/menu.component';
import {MomentModule} from 'ngx-moment';
import {TreeModule} from 'angular-tree-component';



@NgModule({
  declarations: [
    MenusComponent,
    MenuComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CKEditorModule,
    MenusRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    MomentModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    SharedModule,
    TranslateModule,
    TreeModule,
  ],
  providers: [
    NgbActiveModal
  ],
})
export class MenusModule { }
