<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col">
        <h3 translate>Publisher</h3>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <div class="card tab2-card">
                <div class="card-body">
                  <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form">
                      <div class="form-group">
                        <label class="d-block" for="enabled">
                          <input class="checkbox_animated" id="enabled" type="checkbox" formControlName="Enabled">
                          {{'Enabled' | translate}}
                        </label>
                      </div>
                      <div class="form-group">
                        <label for="bin" class="mb-1" translate>Bin</label>:
                        <input class="form-control" id="bin" type="text" placeholder="/path/to/binary" formControlName="Bin">
                      </div>
                      <div class="form-group">
                        <label for="api_token" class="mb-1" translate>ApiToken</label>:
                        <input class="form-control" id="api_token" type="text" formControlName="ApiToken">
                      </div>
                      <div class="form-group">
                        <label for="name" class="mb-1" translate>Name</label>:
                        <input class="form-control" id="name" type="text" formControlName="Name">
                      </div>
                      <div class="form-group">
                        <label for="account_id" class="mb-1" translate>AccountId</label>:
                        <input class="form-control" id="account_id" type="text" formControlName="AccountId">
                      </div>
                      <div class="form-group">
                        <label class="d-block" for="workers_dev">
                          <input class="checkbox_animated" id="workers_dev" type="checkbox" formControlName="WorkersDev">
                          {{'Developer Mode' | translate}}
                        </label>
                      </div>
                      <div class="form-group">
                        <label for="route" class="mb-1" translate>Route</label>:
                        <input class="form-control" id="route" type="text" formControlName="Route">
                      </div>
                      <div class="form-group">
                        <label for="zone_id" class="mb-1" translate>ZoneId</label>:
                        <input class="form-control" id="zone_id" type="text" formControlName="ZoneId">
                      </div>

                    </div>
                    <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
                  </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
