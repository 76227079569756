import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styles: [
    `
      div > span {
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div class="button-group-dropdown">
      <button
        type="button"
        class="btn btn-status dropdown-toggle"
        data-toggle="dropdown"
        [ngClass]="value ? 'status-active' : 'status-inactive'">
        <span [innerHTML]="value ? 'active' : ('inactive' | translate)"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li (click)="patch(true)">Active</li>
        <li (click)="patch(false)">Inactive</li>
      </ul>
    </div>
  `,
})
export class EnabledRenderComponent implements ViewCell {
  parent;
  method = 'patchProduct';
  @Input() value: string;
  @Input() rowData: any;

  constructor(public apiService: ApiService) {}

  patch(value) {
    this.apiService
      .patch(this.parent.type, this.rowData['ID'], 'setEnabled', {
        Value: value,
      })
      .then(resp => {
        for (let i = 0; i < this.parent.source.data.length; i++) {
          if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
            this.parent.source.data[i]['Enabled'] = value === true;
            this.parent.source.local = true;
            this.parent.source.refresh();
            break;
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}
