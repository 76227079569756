import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class VariationsDataSource extends LocalDataSource {
  parent: any;
  id;
  data: any[];
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;
  stack = 0;

  constructor(protected http: HttpClient, private apiService: ApiService, private absolutizeUrl: AbsolutizeUrlPipe) {
    super();
  }

  setFilter(conf: Array<any>, andOperator?: boolean, doEmit?: boolean): any {
    this.stack++;
    LocalDataSource.prototype.setFilter.call(this, conf, andOperator, doEmit);
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  getElements(): Promise<any> {
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    //
    const u = new URL((url += '/variations/list'));
    if (this.id) {
      u.searchParams.set('id', this.id);
    }
    if (those.stack === 0) {
      return this.http
        .post(u.toString(), request, { headers: headersObject, withCredentials: true })
        .pipe(
          map(response => {
            those.lastRequestFiltered = +response['Filtered'];
            those.lastRequestCount = +response['Filtered'];
            those.lastRequestTotal = +response['Total'];
            those.parent.visible = true;
            return (response['Data'] || []).map(item => {
              item['Thumbnail'] =
                '<img src="' +
                those.absolutizeUrl.transform(
                  item['Thumbnail'] ? '/storage' + item['Thumbnail'] : '/assets/images/no-image.svg'
                ) +
                '" class="category-thumbnail">';
              return item;
            });
          })
        )
        .toPromise();
    } else {
      those.stack--;
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    }
  }
}
