import { Component, Input } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../../shared/api.service';

@Component({
  styles: [`
    div>span {
      cursor: pointer;
    }
  `],
  template: `
    <div class="button-group-dropdown" style="text-align: center">
      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="{'status-new': rowData.Status == 'new', 'status-pending': rowData.Status == 'pending', 'status-complete': rowData.Status == 'complete', 'status-rejected': rowData.Status == 'rejected'}">
        <span [innerText]="rowData.Status | translate"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li (click)="patch('new')" translate>
          New
        </li>
        <li (click)="patch('pending')" translate>
          Pending
        </li>
        <li (click)="patch('complete')" translate>
          Complete
        </li>
        <li (click)="patch('rejected')" translate>
          Rejected
        </li>
      </ul>
    </div>
  `,
})
export class TransactionStatusRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  constructor(public apiService: ApiService){  }

  patch(value) {
    this.apiService['patchTransaction'](this.rowData['ID'], 'setStatus', {Value: value}).then(resp => {
      for (let i = 0; i < this.parent.source.data.length; i++) {
        if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
          this.parent.source.data[i]['Status'] = value;
          this.parent.source.local = true;
          this.parent.source.refresh();
          break;
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }
}
