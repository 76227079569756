<div class="card" style="margin-bottom: 20px">
  <div class="card-body" style="padding-bottom: 0;">
    <div class="row">
      <div class="col">
        <h2 style="margin-bottom: 20px" translate>Media</h2>
      </div>
    </div>
    <app-edit-media [productId]="productId" [variationId]="variationId" [source]="{data: images}" (onSaved)="onImageSaved($event)" #imageEditor></app-edit-media>
    <div class="row">
      <div class="col" style="margin-top: auto; margin-bottom: auto">
        <div *ngIf="selectedImages && selectedImages.length > 0" style="display: flex;align-items: center;padding-bottom: 20px">
          <input type="checkbox" class="checkbox_animated" [(ngModel)]="selectedImagesFlag"
                 (ngModelChange)="unselectImages()" [ngModelOptions]="{standalone: true}">
          <div class="images-selected body-1">
            <span class="images-selected-count">{{selectedImages.length}}</span>
            <span translate>of</span> {{images.length}} <span translate>images selected</span>
          </div>
          <span class="btn btn-sm btn-default facon" style="padding: 0;" (click)="deleteImages()">
            <svg style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div>
      <app-media-library [productId]="productId" (onSelected)="onInsertMedia($event)" #mediaLibrary></app-media-library>
      <div class="row card-fluid-block images-list" style="margin-bottom: 10px;padding-bottom: 10px;" dragula="images" [(dragulaModel)]="images">
        <div *ngFor="let image of images; let i = index"
             class="col-4 col-md-3 image-item-wrapper draggable-wrapper" [attr.data-id]="image.ID">
          <div class="image-item">
            <div class="image-item-header" [title]="image['Name']">
              <div class="image-item-header-dots">
                <svg class="fill-black draggable draggable-point" style="height: 10px; width: 10px;">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-bid"></use>
                </svg>
              </div>
              <div class="image-item-header-name">{{image['Name']}}</div>
              <div style="margin-top: -4px;">
                <input type="checkbox" class="checkbox_animated" style="margin-right: 3px;" [(ngModel)]="image['selected']"
                       (ngModelChange)="selectImage()" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="image-item-body">
              <div class="image-item-body-thumbnail">
                <div [style]="{backgroundImage: 'url(' + (mediaThumbnail.transform(image) + ')'), backgroundSize: 'cover'}"></div>
              </div>
              <div *ngIf="mediaIdFormControl" class="image-item-body-default">
                <label [attr.for]="'case-' + i" class="body-5-regular">
                  {{'Thumbnail' | translate}}
                </label>
                <input type="radio" [attr.id]="'case-' + i" class="radio_animated" [value]="image['ID']" [formControl]="mediaIdFormControl">
              </div>
              <div class="image-item-body-properties">
                <div *ngIf="image['Width'] || image['Height']" [title]="image['Width'] + 'x' + image['Height']">
                  {{image['Width']}}x{{image['Height']}}
                </div>
                <div *ngIf="image['Size']" [title]="image['Size'] | humanizeSize">{{image['Size'] | humanizeSize}}</div>
                <div *ngIf="image['Extension']" [title]="image['Extension']">{{image['Extension']}}</div>
              </div>
            </div>
            <div class="image-item-footer">
              <div class="btn-sm btn-fourth" (click)="imageEditor.onEdit(imageEditor.content, image['ID'])">Edit</div>
              <div class="btn-sm btn-delete-2" (click)="onDelete(image.ID)">Delete</div>
            </div>
          </div>
        </div>
        <div class="image-item-wrapper" [ngClass]="{'col-4 col-md-3': images.length % n != 0, 'col-md-12': images.length % n === 0}">
          <div class="image-item-new">
            <div class="image-item-body-upload">
              <div class="dropzone-custom">
                <div class="dropzone" (success)="onImageUploadSuccess($event)" (error)="onImageUploadError($event)"
                     (queueComplete)="onImageUploadComplete()" [dropzone]="imagesDropzoneConfig">
                  <div class="dz-message needsclick" (click)="onImageUploadClick($event)">
                    <div class="dz-message-content" >
                      <div class="dz-message-icon">
                        <svg class="fill-primary-violet">
                          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-upload"></use>
                        </svg>
                      </div>
                      <div class="dz-message-text">
                        <div class="dz-message-text-1">
                          <span>Drag or</span><a class="body-4 link2" style="padding: 0 3px;" (click)="open(mediaLibrary, 'images', $event)" translate>browse media library</a><span>to start uploading</span>
                        </div>
                        <div class="dz-message-text-2">
                          <span class="body-1-regular">Drop file</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <!--app-edit-file [productId]="productId" [variationId]="variationId" (onSaved)="refresh('files')" #fileEditor></app-edit-file-->
    <app-edit-media [productId]="productId" [variationId]="variationId" [size]="'md'" (onCreated)="onFileCreated($event)" (onSaved)="onFileSaved($event)" #fileEditor></app-edit-media>
    <div class="row">
      <div class="col">
        <label class="body-1 d-block" style="font-weight: 400" for="container">
          <input class="checkbox_animated" id="container" type="checkbox" [disabled]="files.length > 0" [(ngModel)]="hasFiles" [ngModelOptions]="{standalone: true}">
          {{'Upload Files' | translate}}
        </label>
      </div>
      <!--div *ngIf="hasFiles" class="col" style="text-align: right">
        <button type="button" class="btn btn-label facon-plus" style="padding: 5px 10px; text-transform: none;"
                data-toggle="modal" data-original-title="test"
                data-target="#exampleModal" (click)="fileEditor.onCreate(fileEditor.content)" translate> Add File
        </button>
      </div-->
    </div>
    <div class="card-fluid-block">
      <div *ngIf="hasFiles" class="table-scrollable-wrapper">
        <table *ngIf="files && files.length > 0" class="table table-fluid table-files pb-2" style="margin-bottom: 0">
          <thead>
          <tr>
            <th translate>Name</th>
            <th translate>Type</th>
            <th style="min-width: 75px" translate>Size</th>
            <th style="min-width: 80px"></th>
          </tr>
          </thead>
          <tbody dragula="files" [(dragulaModel)]="files">
          <tr *ngFor="let file of files" [attr.data-id]="file.ID">
            <td><span class="color-primary-violet" style="cursor: pointer;"
                      (click)="fileEditor.onEdit(fileEditor.content, file.ID)" [title]="file.Name">{{file.Title ? file.Title : file.Name}}</span></td>
            <td>{{file['ContentType']}}</td>
            <td>
              <div *ngIf="file['Size']">{{file['Size'] | humanizeSize}}</div>
            </td>
            <td class="buttons" style="text-align: center">
              <span class="btn-sm btn-default facon-minus" title="delete" (click)="onDelete(file.ID)"></span>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="image-item-wrapper col-md-12">
          <div class="image-item-new">
            <div class="image-item-body-upload">
              <div class="dropzone-custom">
                <div class="dropzone" (success)="onImageUploadSuccess($event)" (error)="onImageUploadError($event)"
                     (queueComplete)="onImageUploadComplete()" [dropzone]="filesDropzoneConfig">
                  <div class="dz-message needsclick" (click)="onImageUploadClick($event)">
                    <div class="dz-message-content">
                      <div class="dz-message-icon">
                        <svg class="fill-primary-violet">
                          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-upload"></use>
                        </svg>
                      </div>
                      <div class="dz-message-text">
                        <div class="dz-message-text-1">
                        <div><span>Drag or</span><a class="body-7 link2" style="padding: 0 3px;" (click)="open(mediaLibrary, 'files', $event)" translate>browse media library</a><span>to start uploading</span></div>
                        </div>
                        <div class="dz-message-text-2">
                          <span class="body-1-regular">Drop file</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
