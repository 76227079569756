import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { RateComponent } from '../../../shared/modals/rate/rate.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from '../../../shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-property-inline',
  styleUrls: ['./property-inline.component.scss'],
  templateUrl: './property-inline.component.html',
  providers: [AbsolutizeUrlPipe],
})
export class PropertyInlineComponent implements OnInit, OnDestroy {
  @Input('form') form: FormGroup;
  @Input('index') index;
  @Input('createBlockForm') createBlockForm;
  @Input('createRateForm') createRateForm;
  @Input('basePrice') basePrice;
  @Input('salePrice') salePrice;
  @Input('tinymceConfig') tinymceConfig;
  @ViewChild('content') content;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();
  optionsOptions: IMultiSelectOption[];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'fontawesome',
    selectionLimit: 1,
    closeOnSelect: true,
  };
  options;
  option;
  dragulaSubscription = new Subscription();
  expanded = false;
  expandedAll = false;
  loading = false;
  optionSubscription: Subscription;

  showBlocks = false;

  timeout;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private dragulaService: DragulaService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private toastr: ToastrService,
    public translate: TranslateService,
    private modalService: NgbModal,
    public router: Router
  ) {}

  ngOnInit() {
    const those = this;
    if (this.form.get('OptionId').value) {
      those.apiService
        .getOptions()
        .then(options => {
          those.options = options;
          those.optionsOptions = options.map(item => {
            return { id: item.ID, name: '#' + item.ID + ' ' + item.Title + ' (' + item.Name + ') ' };
          });
        })
        .catch(err => {
          console.error('Load options', err);
        });
    }
    //
    this.optionSubscription = those.form.get('OptionId').valueChanges.subscribe(value => {
      console.log('OptionId changed', value);
      const option = value;
      if (option && option.length > 0) {
        const optionId = option[0];
        const selected = those.options.find(item => item.ID === optionId);
        if (selected) {
          those.form.get('Name').setValue(selected.Name);
          those.form.get('Title').setValue(selected.Title);
          those.form.get('Option').patchValue(selected);
        }
        this.apiService
          .getOption(optionId)
          .then(option2 => {
            console.log('option2', option2);
            if (option2.Blocks) {
              const array = this.form.get('Customization').get('Blocks') as FormArray;
              option2.Blocks.forEach(block => {
                array.push(
                  this.formBuilder.group({
                    ID: [block.ID],
                    Enabled: [block.Enabled],
                    Title: [block.Title],
                    Post: this.formBuilder.group({
                      ID: [block.Post.ID],
                      Title: [block.Post.Title],
                      Content: [block.Post.Content],
                    }),
                    Custom: [block.Custom],
                    PostId: [{ disabled: true, value: [block.PostId] }],
                    Location: [block.Location],
                  })
                );
              });
              those.showBlocks = those.showBlocks || option2.Blocks.length > 0;
            }
          })
          .catch(err => {
            those.error('Get option', err);
          });
        this.apiService
          .getValues(optionId)
          .then(values => {
            const rates = [];
            for (let i = 0; i < values.length; i++) {
              const rate = {
                ID: i === 0 ? 0 : undefined,
                Enabled: true,
                Availability: 'available',
                Value: {
                  ID: values[i]['ID'],
                  Mode: values[i]['Mode'],
                  Title: values[i]['Title'],
                  Description: values[i]['Description'],
                  Color: values[i]['Color'],
                  Media: values[i]['Media'],
                  Availability: values[i]['Availability'],
                  Value: values[i]['Value'],
                },
                ValueId: values[i]['ID'],
                Sort: i + 1,
                Selected: true,
              };
              if (rate.Value.Media) {
                rate.Value.Mode = 'image';
              } else if (rate.Value.Color) {
                rate.Value.Mode = 'color';
              }
              console.log('rate', rate)
              rates.push(rate);
              const array = this.form.get('Rates') as FormArray;
              array.push(this.createRateForm(rate));
            }
          })
          .catch(err => {
            those.error('Get values', err);
          });
      }
    });
    // Blocks
    this.dragulaService.createGroup('property-' + this.index + '-blocks', {
      moves: (el, container, handle) => {
        const classes =
          (handle.className &&
            handle.className['baseVal'] &&
            handle.className['baseVal']['split'] &&
            handle.className['baseVal'].split(/\s+/)) ||
          [];
        if (classes.indexOf('draggable') !== -1) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
    this.dragulaSubscription.add(
      this.dragulaService.drop('property-' + this.index + '-blocks').subscribe(({ name, el, sibling }) => {
        console.log('el', el);
        if (el) {
          let element = el.parentNode.firstChild;
          console.log('element', element);
          do {
            if (element.nodeType === 3) continue; // text node
            const classList = (element as Element).classList;
            if (classList && classList.contains('draggable-wrapper')) {
              if (classList.contains('gu-transit')) {
                classList.add('draggable-dropped');
              } else {
                classList.remove('draggable-dropped');
              }
            }
          } while ((element = element.nextSibling));
          if (those.timeout) {
            clearTimeout(those.timeout);
            those.timeout = undefined;
          }
          those.timeout = setTimeout(() => {
            el?.classList?.remove('draggable-dropped');
          }, 10000);
        }
        those.form.get('Blocks').updateValueAndValidity();
        those.form.get('Blocks').markAsDirty();
      })
    );
    // Rates
    this.dragulaService.createGroup('property-' + this.index + '-rates', {
      moves: (el, container, handle) => {
        if (el) {
          let element = el.parentNode.parentNode.firstChild;
          do {
            if (element.nodeType === 3) continue; // text node
            const classList = (element as Element).classList;
            if (classList && classList.contains('draggable-wrapper')) {
              if (classList.contains('gu-transit')) {
                classList.add('draggable-dropped');
              } else {
                classList.remove('draggable-dropped');
              }
            }
          } while ((element = element.nextSibling));
          if (those.timeout) {
            clearTimeout(those.timeout);
            those.timeout = undefined;
          }
          those.timeout = setTimeout(() => {
            el?.classList?.remove('draggable-dropped');
          }, 10000);
        }
        const classes =
          (handle.className &&
            handle.className['baseVal'] &&
            handle.className['baseVal']['split'] &&
            handle.className['baseVal'].split(/\s+/)) ||
          [];
        if (classes.indexOf('draggable') !== -1) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
    this.dragulaSubscription.add(
      this.dragulaService.drop('property-' + this.index + '-rates').subscribe(({ name, el, sibling }) => {
        those.form.get('Rates').updateValueAndValidity();
        those.form.get('Rates').markAsDirty();
      })
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('property-' + this.index + '-blocks');
    this.dragulaService.destroy('property-' + this.index + '-rates');
    this.dragulaSubscription.unsubscribe();
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  refresh(type?) {
    this.onSaved.emit(type);
  }

  check() {
    const rates = (this.form.get('Rates') as FormArray).getRawValue();
    this.expandedAll =
      rates &&
      rates.length > 0 &&
      rates.reduce((acc, curr) => {
        if (curr.Opened) {
          return acc + 1;
        }
        return acc;
      }, 0) === rates.length;
  }

  toggleAll() {
    const array = this.form.get('Rates') as FormArray;
    for (let i = 0; i < array.length; i++) {
      array.at(i).get('Opened').setValue(!this.expandedAll);
    }
    this.check();
  }

  onChangeName(event) {
    const name = event.target.value
      .toLowerCase()
      .replace(/ö/gi, 'oe')
      .replace(/ü/gi, 'ue')
      .replace(/ä/gi, 'ae')
      .replace(/ß/gi, 'ss')
      .replace(/[^-a-z0-9\(\)]+/gi, '-');
    this.form.get('Name').setValue(name);
    const control = this.form.get('Title');
    if (!control.value || control.value === '') {
      control.setValue(event.target.value);
    }
  }

  onChangeTitle(event) {
    const control = this.form.get('Name');
    if (!control.value || control.value === '') {
      control.setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  addRate() {
    const those = this;
    const array = this.form.get('Rates') as FormArray;
    const rate = {
      Enabled: true,
      Opened: true,
      Availability: 'available',
      Value: {
        Mode: '',
        Title: those.translate.instant('New'),
        Value: those.translate.instant('new'),
        Availability: 'available',
      },
      Sort: array.length + 1,
    };
    const length = array.length;
    array.push(this.createRateForm(rate));
    array.markAsDirty();
    setTimeout(() => {
      const item = document.querySelector(
        '[data-target="#property-' + this.index + '-rate-inline-collapse-' + length + '"]'
      );
      if (item) {
        item['click']();
      }
    }, 100);
  }

  duplicateRate(i) {
    const array = this.form.get('Rates') as FormArray;
    const rate = {
      ...JSON.parse(JSON.stringify((array.at(i) as FormGroup).getRawValue())),
      ...{
        ID: undefined,
        Opened: true,
        Sort: array.length + 1,
      },
    };
    rate.Value.Title = '';
    rate.Value.Value = '';
    array.push(this.createRateForm(rate));
    array.markAsDirty();
  }

  deleteRate(index) {
    const those = this;
    const array = this.form.get('Rates') as FormArray;
    const item = array.at(index) as FormGroup;
    const id = item.get('ID').value;
    if (id) {
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Delete value?';
      modal.componentInstance.body = `Are you sure you want to delete value? This can't be undone.`;
      modal.componentInstance.confirm = 'Delete';
      modal.result.then(
        result => {
          those.apiService
            .deleteRate(id)
            .then(resp => {
              array.removeAt(index);
            })
            .catch(err => {
              those.error('Delete rate', err);
            });
        },
        reason => {}
      );
    } else {
      array.removeAt(index);
    }
  }
}
