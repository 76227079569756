import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {SubjectService} from '../../subject.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sender',
  templateUrl: './sender.component.html',
  styleUrls: ['./sender.component.scss'],
})
export class SenderComponent implements OnInit {
  @Input('templateId') templateId;
  @Input('topic') topic;
  @Input('message') message;

  form;

  constructor(public router: Router, private route: ActivatedRoute, private apiService: ApiService,
              private subjectService: SubjectService, private formBuilder: FormBuilder, protected http: HttpClient,
              private toastr: ToastrService, private translate: TranslateService) {
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      Name: [''],
      Email: ['', Validators.required],
      Data: ['', Validators.required],
    });
  }

  patchData(data) {
    console.log('patchData', data);
    if (!this.form.get('Data').value) {
      this.form.get('Data').setValue(JSON.stringify(data));
      this.form.get('Data').markAsTouched();
    }
  }

  onSend() {
    const raw = {...{Topic: this.topic, Message: this.message}, ...this.form.getRawValue()};
    this.apiService.patchTemplate(this.templateId, 'send', raw).then(resp => {
      this.success('Message sent, please check', resp);
    }).catch(err => {
      this.error('Message sent, please check', err);
    });
  }
}
