import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';

@Component({
  styles: [
    `
      .image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 60px;
        width: 60px;
        margin-right: 10px;
        vertical-align: middle;
        transition: transform 0.2s;
      }
      div > a {
        padding-right: 5px;
      }
    `,
  ],
  template: `
    <div class="image-wrapper" style="vertical-align: middle">
      <a [href]="rowData['Url']" target="_blank">
        <div
          *ngIf="thumbnail"
          class="image"
          [style]="{
            backgroundImage:
              'url(' +
              absolutizeUrl.transform(
                (thumbnail ? thumbnail : '/api/v1/resize?path=/assets/images/no-image.svg') + ')'
              )
          }"></div>
      </a>
      <div style="display:inline-block;vertical-align: middle">
        {{ value }}
      </div>
    </div>
  `,
})
export class ImageNameRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  thumbnail;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngOnInit() {
    if (this.rowData['Thumbnail']) {
      const thumbnail = this.rowData['Thumbnail'];
      const res = thumbnail.split(/,/);
      if (res && res.length > 1) {
        this.thumbnail = res[1].split(/ /)[0];
      }
    }
  }
}
