import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../shared/api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-hint',
  template: `
    <ng-content></ng-content>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class HintComponent implements OnInit, AfterViewInit {
  @Input('title') title;
  @Input('body') body;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  modal;
  closeResult;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder, private toastr: ToastrService, private absolutizeUrl: AbsolutizeUrlPipe) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onOpen(content) {
    this.modal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.onClosed.emit();
    });
  }
}
