import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbCollapse, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { SharedModule } from '../../shared/shared.module';
import { ProductTitleRenderComponent } from './product-title-render.component';
import { ProductComponent } from './product/product.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { SWIPER_CONFIG, SwiperModule } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TinymceModule } from 'angular2-tinymce';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule } from 'angular-tree-component';
import { DragulaModule } from 'ng2-dragula';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ProductDescriptionRenderComponent } from './product-description-render.component';
import { ProductSortRenderComponent } from './product-sort-render.component';
import { VariationComponent } from './product/variation/variation.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ProductSelectedRenderComponent } from './product-selected-render.component';
import { ProductPriceRenderComponent } from './product-price-render.component';
import { PropertyInlineComponent } from './product/property-inline.component';
import { RateInlineComponent } from './product/rate-inline.component';
import { ValueInlineComponent } from './product/value-inline.component';
import { ParameterInlineComponent } from './product/parameter-inline.component';
import { TransferAdjustmentRenderComponent } from './transfer-adjustment-render.component';
import { VendorBlockComponent } from './product/vendor-block.component';
import { BlocksBlockComponent } from './product/blocks-block.component';
import { ParametersBlockComponent } from './product/parameters-block.component';
import { InventoryBlockComponent } from './product/inventory-block.component';
import { PropertiesBlockComponent } from './product/properties-block.component';
import { PricesBlockComponent } from './product/prices-block.component';
import { PriceInlineComponent } from './product/price-inline.component';
import { PriceSelectedRenderComponent } from './product/price-selected-render.component';
import { PriceDynamicRenderComponent } from './product/price-dynamic-render.component';
import { PriceEditableRenderComponent } from './product/price-editable-render.component';
import { PriceThumbnailRenderComponent } from './product/price-thumbnail-render.component';
import { PriceOptionsRenderComponent } from './product/price-options-render.component';
import { DimensionsBlockComponent } from './product/dimensions-block.component';
import { VariationsBlockComponent } from './product/variations-block.component';
import { TierInlineComponent } from './product/tier-inline.component';
import { BlockInlineComponent } from './product/block-inline.component';
import { MomentModule } from 'ngx-moment';
import 'hammerjs';
import 'mousetrap';
import { AngularCropperjsModule, CropperComponent } from 'angular-cropperjs';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  acceptedFiles: 'image/*',
};

@NgModule({
  declarations: [
    ProductsComponent,
    ProductsComponent,
    ProductComponent,
    ProductTitleRenderComponent,
    ProductPriceRenderComponent,
    ProductDescriptionRenderComponent,
    ProductSortRenderComponent,
    ProductSelectedRenderComponent,
    TransferAdjustmentRenderComponent,
    PropertyInlineComponent,
    RateInlineComponent,
    ValueInlineComponent,
    BlockInlineComponent,
    TierInlineComponent,
    ParameterInlineComponent,
    BlocksBlockComponent,
    ParametersBlockComponent,
    DimensionsBlockComponent,
    InventoryBlockComponent,
    PricesBlockComponent,
    PriceInlineComponent,
    PropertiesBlockComponent,
    VendorBlockComponent,
    VariationComponent,
    PriceSelectedRenderComponent,
    PriceDynamicRenderComponent,
    VariationsBlockComponent,
    PriceEditableRenderComponent,
    PriceThumbnailRenderComponent,
    PriceOptionsRenderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CKEditorModule,
    ProductsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    NguiAutoCompleteModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    NgxBootstrapMultiselectModule,
    SwiperModule,
    MomentModule,
    TinymceModule.withConfig({ baseURL: '/assets/tinymce' }),
    TreeModule,
    DragulaModule.forRoot(),
    DpDatePickerModule,
    NgxCurrencyModule,
    AngularCropperjsModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    NgbActiveModal,
  ],
  entryComponents: [ProductTitleRenderComponent, ProductDescriptionRenderComponent],
  exports: [
    ProductTitleRenderComponent,
    ProductDescriptionRenderComponent,
    TransferAdjustmentRenderComponent,
    BlockInlineComponent,
    PropertyInlineComponent,
    RateInlineComponent,
    ValueInlineComponent,
    ParameterInlineComponent,
  ],
})
export class ProductsModule {}
