<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
        src="assets/images/logo.svg" alt="" style="max-width: 123px"></a></div>
</div>
<div class="sidebar custom-scrollbar" [ngClass]="{minimized: minimized}" style="position: relative; white-space: nowrap;overflow: hidden; overflow-y: auto;">
  <div class="sidebar-badge">
    <div style="position:relative;">
      <div class="badge-avatar" [style]="{backgroundImage: 'url(' + absolutizeUrl.transform((avatar ? avatar : '/assets/images/avatar.png') + ')'), backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', display: 'inline-block', marginRight: '10px', verticalAlign: 'middle', zIndex: 9}">
      </div>
      <div class="badge-name">
        <div class="menu-dropdown dropdown" style="display: flex;min-height: 50px;">
          <button id="dropdownButton" class="btn dropdown-toggle" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-width: 180px;padding-left: 30px;" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ info['User']['Name'] || info['User']['Lastname'] ? ((info['User']['Name'] ? info['User']['Name'] : '') + ' ' + (info['User']['Lastname'] ? info['User']['Lastname'] : '')) : info['User']['Login'] }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownButton">
            <a [routerLink]="'/settings/account'" class="dropdown-item" translate>Edit Profile</a>
            <span class="dropdown-item cancel" translate>Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ul class="sidebar-menu first-menu">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}" style="position: relative; white-space: nowrap; overflow: hidden;">
      <!-- Sub -->
      <a href="javascript:void(0)" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem, $event)">
        <svg>
          <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+menuItem.icon"></use>
        </svg>
        <span class="body-2">{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- Link -->
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-header"
        *ngIf="menuItem.type === 'link'">
        <svg>
          <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+menuItem.icon"></use>
        </svg>
        <span class="body-2">{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header" *ngIf="menuItem.type === 'extLink'">
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Tab Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header"
        *ngIf="menuItem.type === 'extTabLink'">
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- 2nd Level Menu -->
      <!--div *ngIf="menuItem.children" style="display: none">
        <span class="body-2">{{menuItem.title}}<span
          class="badge badge-{{menuItem.badgeType}} ml-3"
          *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
      </div-->
      <ul class="sidebar-submenu" style="padding: 0 0 15px 0;" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
        *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i><span style="vertical-align: text-bottom;"> {{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span> </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (click)="goto(childrenItem)">
            <i class="fa fa-circle"></i><span style="padding-left: 14px">{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Tab Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
            *ngIf="childrenItem.type === 'extTabLink'">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children">
            <li *ngFor="let childrenSubItem of childrenItem.children">
              <!-- Link -->
              <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span> {{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span> </span>
              </a>
              <!-- External Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span>{{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span></span>
              </a>
              <!-- External Tab Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                *ngIf="childrenSubItem.type === 'extTabLink'">
                <i class="fa fa-circle"></i><span>{{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span></span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <a class="sidebar-header" style="cursor: pointer;" (click)="logout()" >
        <svg>
          <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'menu-exit'"></use>
        </svg>
        <span class="body-2">{{'Logout' | translate}}</span>
      </a>
    </li>
  </ul>
  <div class="toggle-arrow" (click)="toggle()">
    <svg *ngIf="!minimized" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9999 0.000244141C11.0217 0.000244141 7.20647 1.58052 4.39331 4.39356C1.58028 7.20659 0 11.0221 0 15.0001C0 18.9781 1.58028 22.7935 4.39331 25.6067C7.20634 28.4197 11.0218 30 14.9999 30C18.9779 30 22.7933 28.4197 25.6064 25.6067C28.4195 22.7936 29.9997 18.9781 29.9997 15.0001C29.9997 12.3672 29.3067 9.78041 27.9903 7.49986C26.6737 5.2197 24.78 3.32602 22.4999 2.00944C20.2194 0.693055 17.633 0.000244141 14.9999 0.000244141ZM20.9998 16.5006L12.615 16.5003L14.5501 18.4354C14.8808 18.8217 14.9933 19.349 14.8492 19.8364C14.7051 20.3241 14.3236 20.7053 13.8362 20.8494C13.3488 20.9938 12.8212 20.8811 12.4352 20.5504L7.93517 16.0503C7.65568 15.7693 7.49898 15.3892 7.49898 14.9929C7.49898 14.5966 7.65568 14.2165 7.93517 13.9355L12.4352 9.43544C12.7069 9.11802 13.0989 8.92843 13.5165 8.91211C13.9341 8.89604 14.3397 9.055 14.6349 9.35056C14.9305 9.64588 15.0895 10.0514 15.0731 10.469C15.0571 10.8866 14.8675 11.2786 14.5501 11.5503L12.6149 13.5005H20.9998C21.5356 13.5005 22.0308 13.7863 22.2988 14.2503C22.5667 14.7144 22.5667 15.2864 22.2988 15.7505C22.0308 16.2146 21.5357 16.5006 20.9998 16.5006Z" fill="#5C22CF" fill-opacity="0.2"/>
    </svg>
    <svg *ngIf="minimized" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0001 29.9998C18.9783 29.9998 22.7935 28.4195 25.6067 25.6064C28.4197 22.7934 30 18.9779 30 14.9999C30 11.0219 28.4197 7.20648 25.6067 4.39332C22.7937 1.58029 18.9782 1.33514e-05 15.0001 1.33514e-05C11.0221 1.33514e-05 7.20673 1.58029 4.39357 4.39332C1.58054 7.20635 0.000253677 11.0219 0.000253677 14.9999C0.000253677 17.6328 0.693323 20.2196 2.00971 22.5001C3.32629 24.7803 5.22003 26.674 7.50013 27.9906C9.78055 29.3069 12.367 29.9998 15.0001 29.9998ZM9.00018 13.4994L17.385 13.4997L15.4499 11.5646C15.1192 11.1783 15.0067 10.651 15.1508 10.1636C15.2949 9.67591 15.6764 9.29468 16.1638 9.15055C16.6512 9.00616 17.1788 9.11891 17.5648 9.44963L22.0648 13.9497C22.3443 14.2307 22.501 14.6108 22.501 15.0071C22.501 15.4034 22.3443 15.7835 22.0648 16.0645L17.5648 20.5646C17.2931 20.882 16.9011 21.0716 16.4835 21.0879C16.0659 21.104 15.6603 20.945 15.3651 20.6494C15.0695 20.3541 14.9105 19.9486 14.9269 19.531C14.9429 19.1134 15.1325 18.7214 15.4499 18.4497L17.3851 16.4995L9.00024 16.4995C8.46436 16.4995 7.96916 16.2137 7.70122 15.7497C7.43327 15.2856 7.43327 14.7136 7.70122 14.2495C7.96916 13.7854 8.46429 13.4994 9.00018 13.4994Z" fill="#5C22CF" fill-opacity="0.2"/>
    </svg>
  </div>
  <div *ngIf="info" class="version-info">
    <div>
      <span translate>Version</span>: <span>{{info['Version']}}</span>
    </div>
    <div>
      <span translate>Backend</span>: <span>{{info['Compiled']}}</span>
    </div>
    <div>
      <span translate>Frontend</span>: <span>{{build}}</span>
    </div>
    <div *ngIf="hostname === 'localhost'">
      <span translate>Server</span>: <span>{{server}}</span>
    </div>
  </div>
</div>
