<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="btn-popup pull-right" style="margin-bottom: 10px;">
            <ng-template #content let-modal>
              <div class="modal-header">
                <h5 class="modal-title f-w-600" id="exampleModalLabel" translate>
                  Edit User
                </h5>
                <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                  <div class="form">
                    <div class="form-group">
                      <label for="id" class="mb-1" translate>Id</label>:
                      <input class="form-control" id="id" type="text" formControlName="ID">
                    </div>
                    <div class="form-group">
                      <label for="login" class="mb-1" translate>Login</label>:
                      <input class="form-control" id="login" type="text" formControlName="Login">
                    </div>
                    <div class="form-group">
                      <label for="email" class="mb-1" translate>Email</label>:
                      <input class="form-control" id="email" type="text" formControlName="Email">
                    </div>
                    <div class="form-group">
                      <label class="d-block" for="confirmed">
                        {{'Confirmed' | translate}}:
                        <input class="checkbox_animated" id="confirmed" type="checkbox" formControlName="EmailConfirmed">
                      </label>
                    </div>
                    <div class="form-group">
                      <label for="password" class="mb-1" translate>Password</label>:
                      <input class="form-control" id="password" type="text" formControlName="Password">
                    </div>
                    <div class="form-group">
                      <label for="password" class="mb-1" translate>Role</label>:
                      <select class="form-control" id="role" formControlName="Role">
                        <option value="0" translate>Root</option>
                        <option value="1" translate>Admin</option>
                        <option value="2" translate>Manager</option>
                        <option value="3" translate>User</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="d-block" for="notifications">
                        {{'Notification' | translate}}:
                        <input class="checkbox_animated" id="notifications" type="checkbox" formControlName="Notification">
                      </label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-default" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')" translate>Close</button>
                  <button type="submit" class="btn btn-primary" translate>Save</button>
                </div>
              </form>
            </ng-template>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive" [ngClass]="!visible?'transparent':''">
              <ng2-smart-table (edit)="onEdit(content, $event.data['ID'])" (delete)="onDelete($event)" [settings]="settings" [source]="source"></ng2-smart-table>
              <div *ngIf="source && source.count() > 0" class="records-count">
                <span *ngIf="source.filtered() != source.total()">
                  {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                </span>
                <span *ngIf="source.filtered() == source.total()">
                  {{'Records' | translate}}: {{source.total()}}
                </span>
                <div class="per-page" style="text-align: center">
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(10)">10</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(20)">20</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(50)">50</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(100)">100</span>
                </div>
              </div>
            </div>
            <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
