import { Component, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductComponent } from '../../components/products/product/product.component';
import { VariationComponent } from '../../components/products/product/variation/variation.component';
import { PagesComponent } from '../../components/pages/pages.component';
import { ConfirmComponent } from '../modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<Component> {
  constructor(private modalService: NgbModal, private translate: TranslateService) {}

  canDeactivate(component: Component) {
    if (component instanceof ProductComponent) {
      //
    } else if (component instanceof VariationComponent) {
      //
    } else if (component instanceof PagesComponent) {
      //
    }
    if (
      (component && component['form'] && component['form']['dirty']) ||
      (component['createForm'] && component['createForm']['dirty']) ||
      (component['editForm'] && component['editForm']['dirty'])
    ) {
      Object.keys(component['form'].controls).forEach(key => {
        console.log('dirty', component['form'].controls[key]?.dirty, 'key', key);
      });
      const subject = new Subject<boolean>();
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Leave page with unsaved changes?';
      modal.componentInstance.body = 'Leaving this page will delete all unsaved changes';
      modal.componentInstance.cancel = 'Stay';
      modal.componentInstance.confirm = 'Leave Page';
      modal.result.then(
        result => {
          subject.next(true);
        },
        reason => {
          subject.next(false);
        }
      );
      return subject.asObservable();
    }
    return true;
  }
}
