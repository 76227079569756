import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { SubjectService } from '../../subject.service';
import { Subscription } from 'rxjs';
import { credentials } from '../../credentials.data';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../api.service';
import { AbsolutizeUrlPipe } from '../../utils.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AbsolutizeUrlPipe],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() minimized: boolean;
  @Output() minimizedChange = new EventEmitter<boolean>();
  subscription: Subscription;
  info;
  build;
  server;
  hostname;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  avatar;

  constructor(
    private router: Router,
    public navServices: NavService,
    private apiService: ApiService,
    private subjectService: SubjectService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private localStorageService: LocalStorageService,
    public translate: TranslateService
  ) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) this.setNavActive(items);
            if (!items.children) return false;
            items.children.filter(subItems => {
              if (subItems.path === event.url) this.setNavActive(subItems);
              if (!subItems.children) return false;
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });
  }

  ngOnInit(): void {
    const those = this;
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info['User']) {
        if (info['User']['Thumbnail']) {
          those.avatar = '/storage' + info['User']['Thumbnail'];
        }
        info['User']['Role'] = ['Root', 'Admin', 'Manager'][info['User']['Role']];
      }
      those.info = info;
    });
    this.build = credentials.build;
    this.server = credentials.apiUrl.replace(/https:?\/\/([^\/]+).*/, '$1');
    this.hostname = window.location.hostname;
    try {
      const minimized = JSON.parse(this.localStorageService.get('minimized'));
      this.minimized = minimized;
      if (this.minimized) {
        this.minimizedChange.emit(this.minimized);
      }
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item, event?) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    if (event?.target?.tagName !== 'I' && item.redirect) {
      if (item.page) {
        this.resetPage(item.page);
      }
      this.router.navigate([item.redirect]);
    }
    item.active = !item.active;
  }

  goto(item) {
    if (item.page) {
      this.resetPage(item.page);
    }
  }

  resetPage(name) {
    this.localStorageService.remove(name + '_page');
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.url = reader.result;
    };
  }

  toggle() {
    this.minimized = !this.minimized;
    this.localStorageService.set('minimized', JSON.stringify(this.minimized));
    this.minimizedChange.emit(this.minimized);
  }

  logout() {
    const those = this;
    this.apiService
      .logout()
      .then(obj => {
        if (obj['MESSAGE'] === 'OK') {
          those.apiService
            .getInfo()
            .then(info => {
              those.subjectService.infoSubject.next(info);
              those.router.navigate(['/auth/login']);
            })
            .catch(err => {
              console.log('err', err);
            });
        }
      })
      .catch(err => {
        const obj = err.error;
        if (err.status === 500) {
          if (obj && obj['ERROR']) {
            alert(obj['ERROR']);
          } else {
            alert('Something went wrong');
          }
        }
      });
  }
}
