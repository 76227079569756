import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-variation',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!variation || !variation['ID']" class="modal-title" id="exampleModalLabel" translate>
          Add Variation
        </h5>
        <h5 *ngIf="variation && variation['ID']" class="modal-title" id="exampleModalLabel" translate>
          Edit Variation
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label for="title" class="mb-1" translate>Title</label>:
              <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
            </div>
            <div class="form-group">
              <label for="name" class="mb-1" translate>Name</label>:
              <input class="form-control" id="name" type="text" formControlName="Name" />
            </div>
            <div class="form-group mb-0">
              <label for="thumbnail" class="mb-1" translate>Thumbnail</label>:
              <div
                *ngIf="
                  form.controls['Thumbnail'] &&
                  form.controls['Thumbnail'].value &&
                  !form.controls['Thumbnail'].touched &&
                  !updating
                "
                style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center">
                <img
                  [src]="form.controls['Thumbnail'].value"
                  style="max-height: 300px;border-radius: 5px;margin-bottom: 5px;" />
                <div
                  style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                  (click)="form.controls['Thumbnail'].value = ''">
                  &times;
                </div>
              </div>
              <app-file-uploader
                *ngIf="!form.controls['Thumbnail'].value || form.controls['Thumbnail'].touched"
                id="thumbnail"
                (filesLoaded)="upload($event)"></app-file-uploader>
            </div>
            <div class="form-group">
              <label for="description" class="mb-1" translate>Description</label>:
              <textarea class="form-control" id="description" rows="2" formControlName="Description"></textarea>
            </div>
            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col-md-6">
                <label for="baseprice" class="mb-1" translate>BasePrice</label>:
                <input
                  class="form-control"
                  id="baseprice"
                  type="text"
                  (focusin)="trim(form.get('BasePrice'))"
                  (focusout)="format(form.get('BasePrice'))"
                  formControlName="BasePrice" />
                <div *ngIf="form.controls['BasePrice'].errors" class="error">
                  <div
                    *ngIf="
                      form.controls['BasePrice'].errors['pattern'] &&
                      form.controls['BasePrice'].errors['pattern']['requiredPattern']
                    ">
                    Valid price value required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="sku" class="mb-1" translate>Sku</label>:
                <input class="form-control" id="sku" type="text" formControlName="Sku" />
              </div>
            </div>
            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col-md-6">
                <label for="saleprice" class="mb-1" translate>SalePrice</label>:
                <input
                  class="form-control"
                  id="saleprice"
                  type="text"
                  (focusin)="trim(form.get('SalePrice'))"
                  (focusout)="format(form.get('SalePrice'))"
                  formControlName="SalePrice" />
              </div>
              <div class="col-md-3 sale">
                <label for="start" class="mb-1" translate>Start</label>:
                <div>
                  <dp-date-picker
                    id="start"
                    mode="daytime"
                    theme="dp-material"
                    [config]="{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      firstDayOfWeek: 'mo',
                      showTwentyFourHours: true,
                      showMultipleYearsNavigation: true
                    }"
                    formControlName="Start"></dp-date-picker>
                </div>
              </div>
              <div class="col-md-3 sale">
                <label for="end" class="mb-1" translate>End</label>:
                <div>
                  <dp-date-picker
                    id="end"
                    mode="daytime"
                    theme="dp-material"
                    [config]="{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      firstDayOfWeek: 'mo',
                      showTwentyFourHours: true,
                      showMultipleYearsNavigation: true
                    }"
                    formControlName="End"></dp-date-picker>
                </div>
              </div>
            </div>
            <!-- -->
            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col-md-6">
                <label for="dimensions" translate>Dimensions</label>
                <input
                  type="text"
                  class="form-control mb-2"
                  id="dimensions"
                  placeholder="{{ 'width x height x depth in cm' | translate }}"
                  formControlName="Dimensions" />
              </div>
              <div class="col-md-6">
                <label for="weight" translate>Weight</label>
                <input
                  type="number"
                  min="0.00"
                  step="0.01"
                  class="form-control mb-2"
                  id="weight"
                  placeholder="{{ 'weight in kg' | translate }}"
                  formControlName="Weight" />
              </div>
            </div>
            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col-md-6">
                <label for="availability" class="mb-1" translate>Availability</label>:
                <select
                  class="form-control"
                  id="availability"
                  (ngModelChange)="select()"
                  formControlName="Availability">
                  <option value="available">{{ 'Available' | translate }}</option>
                  <option value="pre-order">{{ 'Pre-order' | translate }}</option>
                  <option value="out of stock">{{ 'Out of stock' | translate }}</option>
                </select>
              </div>
              <div *ngIf="form.controls['Availability'].value == 'available'" class="col-md-6">
                <label for="sending" class="mb-1" translate>Sending</label>:
                <select class="form-control" id="sending" formControlName="Sending">
                  <option value="instant">{{ 'Instant' | translate }}</option>
                  <option value="in 1 day">{{ 'In 1 day' | translate }}</option>
                  <option value="in 2 days">{{ 'In 2 days' | translate }}</option>
                  <option value="in 3 days">{{ 'In 3 days' | translate }}</option>
                  <option value="in 4 days">{{ 'In 4 days' | translate }}</option>
                  <option value="in 5 days">{{ 'In 5 days' | translate }}</option>
                  <option value="in 6 days">{{ 'In 6 days' | translate }}</option>
                  <option value="in 1 week">{{ 'In 1 week' | translate }}</option>
                  <option value="in 2 weeks">{{ 'In 2 weeks' | translate }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class VariationComponent implements OnInit {
  @Input('productId') productId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  product;
  variation;
  modal;
  closeResult;
  form;
  updating = false;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Description: [''],
      Thumbnail: [''],
      BasePrice: [0, Validators.required],
      SalePrice: [0],
      Start: [moment().format('YYYY-MM-DD 00:00:00'), Validators.required],
      End: [moment('2099-12-31').format('YYYY-MM-DD 00:00:00'), Validators.required],
      Dimensions: [''],
      Weight: [0],
      Availability: ['available'],
      Sending: ['instant'],
      Sku: [],
    });
    //
    this.format(this.form.get('BasePrice'));
    this.format(this.form.get('SalePrice'));
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onEdit(content, id, extra?: any) {
    const those = this;
    if (extra && extra['ProductId']) {
      this.productId = extra['ProductId'];
    }
    this.apiService
      .getVariation(id)
      .then(variation => {
        those.variation = variation;
        those.form = those.formBuilder.group({
          ID: [variation['ID']],
          Name: [{ disabled: true, value: variation['Name'] }, Validators.required],
          Title: [variation['Title'], Validators.required],
          Description: [variation['Description'] ? variation['Description'] : ''],
          Thumbnail: [variation['Thumbnail'] ? those.absolutizeUrl.transform('/storage' + variation['Thumbnail']) : ''],
          BasePrice: [variation['BasePrice'], Validators.required],
          SalePrice: [variation['SalePrice'], Validators.required],
          Start: [
            variation['Start']
              ? moment(variation['Start']).format('YYYY-MM-DD HH:mm:ss')
              : moment().format('YYYY-MM-DD 00:00:00'),
            Validators.required,
          ],
          End: [
            variation['End']
              ? moment(variation['End']).format('YYYY-MM-DD HH:mm:ss')
              : moment('2099-12-31').format('YYYY-MM-DD 00:00:00'),
            Validators.required,
          ],
          Dimensions: [variation['Dimensions']],
          Weight: [variation['Weight']],
          Availability: [variation['Availability'] ? variation['Availability'] : 'available'],
          Sending: [variation['Sending']],
          Sku: [variation['Sku']],
        });
        //
        this.format(this.form.get('BasePrice'));
        this.format(this.form.get('SalePrice'));
        //
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load variation', err);
      });
  }

  /*onCustom(event) {
    const those = this;
    if (event['action'] === 'list') {
      those.router.navigate([event['data']['ID'] + '/properties'], {relativeTo: those.route});
    }
  }*/

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete variation?';
    modal.componentInstance.body = `Are you sure you want to delete variation? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVariation(id)
          .then(resp => {
            those.success('Delete variation', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete variation', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  upload(files) {
    this.updating = true;
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  select() {
    if (this.form.get('Availability').value === 'available' && !this.form.get('Sending').value) {
      this.form.get('Sending').setValue('instant');
    }
  }

  trim(control) {
    if (control && control.value) {
      control.setValue(control.value.replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Description', this.form.get('Description').value);
    data.append('BasePrice', this.form.get('BasePrice').value);
    data.append('SalePrice', this.form.get('SalePrice').value);
    data.append('Start', moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    data.append('End', moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    data.append('Dimensions', this.form.get('Dimensions').value);
    data.append('Weight', this.form.get('Weight').value);
    data.append('Availability', this.form.get('Availability').value);
    data.append('Sending', this.form.get('Sending').value);
    data.append('Sku', this.form.get('Sku').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postVariation(data)
        .then(resp => {
          those.success('Create variation', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create variation', err);
        });
    } else {
      // update
      this.apiService
        .putVariation(those.form.get('ID').value, data)
        .then(resp => {
          those.success('Update variation', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update variation', err);
        });
    }
    this.updating = false;
  }
}
