import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DateRenderComponent } from '../../render/date-render.component';
import { PostsDataSource } from '../../../components/posts/posts.data-source';
import { LocalStorageService } from 'angular-2-local-storage';
import { TitleRenderComponent } from '../../render/title-render.component';

@Component({
  selector: 'app-edit-posts',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" translate>Posts Library</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="btn-popup pull-right" style="margin-bottom: 10px">
          <app-edit-post (onSaved)="refresh()" #editor>
            <button
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-original-title="test"
              data-target="#exampleModal"
              (click)="editor.onCreate(editor.content)"
              translate>
              Add Post
            </button>
          </app-edit-post>
        </div>
        <div id="batchDelete" class="posts-table custom-datatable">
          <div class="table-responsive" [ngClass]="!visible ? 'transparent' : ''">
            <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
            <div *ngIf="source && source.count() > 0" class="records-count">
              <span *ngIf="source.filtered() != source.total()">
                {{ 'Filtered' | translate }}
                : {{ source.filtered() }} {{ 'of' | translate }} {{ source.total() }} {{ 'records' | translate }}
              </span>
              <span *ngIf="source.filtered() == source.total()">
                {{ 'Records' | translate }}: {{ source.total() }}
              </span>
            </div>
          </div>
          <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;">
            <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class PostsComponent implements OnInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  id;
  modal;
  closeResult;
  timer;
  source: PostsDataSource;
  settings;
  visible = false;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'yourAction',
            title: '<span class="btn btn-primary btn-xs">' + this.translate.instant('Add') + '</span>',
          },
        ],
        columnTitle: this.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        ID: {
          title: 'Id',
        },
        Created: {
          title: this.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
        Title: {
          title: this.translate.instant('Title'),
          type: 'custom',
          renderComponent: TitleRenderComponent,
          onComponentInitFunction: instance => {
            instance.prefix = '/vendors/';
            instance.thumbnail = undefined;
          },
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    this.source = new PostsDataSource(this.http, this.apiService, this.localStorageService);
    this.source.parent = this;
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  refresh() {
    this.source.refresh();
  }
}
