<div class="drop-area">
  <div *ngIf="files" class="gallery">
    <img class="gallery__image" *ngFor="let preview of previews" [src]="preview" />
  </div>
  <div class="file-loader__wrapper"
      [ngClass]="{ 'drop-area__active': isDraggedOver }"
      (drop)="handleDrop($event)"
      (dragover)="handleDragOver($event)"
      (dragenter)="handleDragEnter()"
      (dragleave)="handleDragLeave()"
  >
    <div class="file-loader__container">
      <div class="file-loader__box">
        <i class="fa fa-cloud-upload drop-loader__icon" aria-hidden="true" (click)="file.click()"></i>
        <div *ngIf="message" class="file-loader__message">
          <div class="file-loader__message-1">
            <div class="body-5" [innerHTML]="message|translate"></div>
          </div>
          <div class="file-loader__message-2" style="width: 100%">
            <div class="body-5" style="text-align: center; width: 100%;">
              Drop file
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="file-loader">
      <input
          class="file-loader__input"
          (change)="onFileChange($event)"
          type="file"
          accept="image/png, image/jpeg"
          #file
      />
    </div>
  </div>
</div>
