<div id="tab-basic-information" class="container-fluid" style="padding-top: 30px; position:relative;">
  <div class="row pb-4">
    <div [ngClass]="{'col-md-3': !maximized, 'col-lg-2': !maximized, 'hidden': maximized}"></div>
    <div [ngClass]="{'col-md-9': !maximized, 'col-lg-10': !maximized, 'col': maximized}">
      <div *ngIf="product" class="back-title-button">
        <a class="back" [routerLink]="['/products']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div *ngIf="product['Title']">
          <h1>{{product['Title']}}</h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" style="margin: 0 10px 0 10px;" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setEnabled(true)">
                Active
              </li>
              <li (click)="setEnabled(false)">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="z-index: 10">
  <form *ngIf="form" class="row product needs-validation" [formGroup]="form">
    <div [ngClass]="{'col-md-3': !maximized, 'col-lg-2': !maximized, 'hidden': maximized}">
      <div>
        <div class="menu-header" (click)="displayed=!displayed">
          <svg width="24px" height="24px">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-navigation-right"></use>
          </svg>
        </div>
        <nav id="navbar-left" class="navbar navbar-light bg-light menu-body fixed-desktop" [ngClass]="{'displayed': displayed, 'not-displayed': !displayed}" style="margin: 0 -30px;padding: 0 30px 30px 30px">
          <nav class="nav nav-pills flex-column">
            <div class="product-nav">
              <a class="nav-link" href="#tab-basic-information" class="nav-link active" (click)="scroll('tab-basic-information')" translate>Basic Information</a>
              <a *ngIf="product" class="nav-link" href="#tab-media" (click)="scroll('tab-media')" translate>Media</a>
              <a *ngIf="product && !product['Container']" class="nav-link" href="#tab-inventory" (click)="scroll('tab-inventory')" translate>Inventory</a>
              <a *ngIf="!product || !product['Container']" class="nav-link" href="#tab-dimensions" (click)="scroll('tab-dimensions')" translate>Dimensions</a>
              <a *ngIf="product" class="nav-link" href="#tab-parameters" (click)="scroll('tab-parameters')" translate>Custom Fields</a>
              <a *ngIf="product && !product['Container']" class="nav-link" href="#tab-properties" (click)="scroll('tab-properties')"><span translate>Options</span><span *ngIf="product && product['Properties'] && product['Properties'].length > 0"> ({{product['Properties'].length}})</span></a>
              <a *ngIf="product && !product['Container']" class="nav-link" href="#tab-prices" (click)="scroll('tab-prices')"><span translate>Prices</span><span *ngIf="product && product['Prices'] && product['Prices'].length > 0"> ({{product['Prices'].length}})</span></a>
              <a *ngIf="product" class="nav-link" href="#tab-posts" (click)="scroll('tab-posts')" translate>Infoboxes</a>
              <a *ngIf="product" class="nav-link" href="#tab-variations" (click)="scroll('tab-variations')"><span translate>Variations</span><span *ngIf="product && product['Variations'] && product['Variations'].length > 0"> ({{product['Variations'].length}})</span></a>
            </div>
            <div class="submenu-actions" style="padding-top: 25px">
              <div style="padding-top:5px; text-align: center">
                <button type="button" class="btn btn-primary facon" style="height: 44px;width: 100%;" [title]="'Save' | translate" [disabled]="!form.valid || !form.dirty || !changed" (click)="onSubmit()" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span translate>Save</span>
                </button>
              </div>
              <div *ngIf="(!form.valid || !form.dirty)" style="padding-top:5px; text-align: center">
                <app-preview #previewer>
                  <button type="button" class="btn btn-secondary"
                          [ngClass]="{'facon': previewer.previewing === -1, 'step-0': previewer.previewing === 0, 'step-30': previewer.previewing === 1, 'step-100': previewer.previewing === 2}"
                          style="width: 100%;" data-toggle="modal" data-original-title="test"
                          [ngStyle]="{height: previewer.previewing === -1 ? '44px' : 'initial'}"
                          data-target="#exampleModal" (click)="previewer.onPreview(previewer.content)">
                    <svg>
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-view"></use>
                    </svg>
                    <span translate>Preview</span>
                  </button>
                </app-preview>
              </div>
              <div *ngIf="product" style="padding-top: 20px; text-align: center;">
                <button class="btn btn-copy" style="width: 34px;height: 34px;padding: 3px 0 0 0;margin-right: 10px;" (click)="onClone()">
                  <svg width="12px" height="12px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy"></use>
                  </svg>
                </button>
                <button class="btn btn-delete" style="width: 34px;height: 34px;padding: 3px 0 0 0;" (click)="onDelete(product.ID)">
                  <svg width="16px" height="16px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
    <div [ngClass]="{'col-md-9': !maximized, 'col-lg-10': !maximized, 'col': maximized}">
      <div *ngIf="form" class="row">
        <div [ngClass]="{'col-lg-8': !maximized, 'col-lg-12': maximized, maximized: maximized}">
          <!-- -->
          <div>
            <div class="card" style="position: relative; z-index: 9">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h2 style="margin-bottom: 20px" translate>Basic Information</h2>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="title" class="mb-1" translate>Title</label>
                      <input class="form-control" id="title" type="text" (keyup)="onChangeTitle($event)" formControlName="Title">
                    </div>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" class="mb-1" translate>Permalink</label>
                      <input class="form-control" id="name" type="text" formControlName="Name">
                    </div>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12" style="min-height: 300px">
                    <label class="mb-1" translate>Description</label>
                    <span class="tooltip-question" style="padding-left: 5px;vertical-align: sub;" placement="top" [ngbTooltip]="'This content will be displayed on product page independent of selected variation, good for common information and promotion' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <app-tinymce [options]="tinymceConfig" formControlName="Content" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')"></app-tinymce>
                  </div>
                </div>
              </div>
              <div style="position: absolute; right: -15px; top: 5px">
                <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                  <svg *ngIf="maximized" width="16px" height="16px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                  </svg>
                  <svg *ngIf="!maximized" width="16px" height="16px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!-- Media -->
          <div *ngIf="product" id="tab-media" style="position: relative">
            <app-media-block [type]="'product'" [productId]="product['ID']" [medias]="product['Medias']" [mediaIdFormControl]="form.get('MediaId')"></app-media-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Inventory -->
          <div *ngIf="product && !product['Container']" id="tab-inventory" style="position: relative">
            <app-inventory-block [productId]="product['ID']" [form]="form" [hasTransfers]="product.HasTransfers?true:false" (onSaved)="refresh($event)"></app-inventory-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Dimensions -->
          <div *ngIf="product && !product['Container']" id="tab-dimensions" style="position: relative">
            <app-dimensions-block [form]="form"></app-dimensions-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Parameters / Custom Fields -->
          <div *ngIf="product" id="tab-parameters" style="position: relative">
            <app-parameters-block [productId]="product['ID']" [parameters]="product['Parameters']" [parametersFormControl]="form.get('Parameters')" (onSaved)="refresh()"></app-parameters-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Properties -->
          <div *ngIf="product && !product['Container']" id="tab-properties" style="position: relative">
            <app-properties-block [basePrice]="product.BasePrice" [salePrice]="product.SalePrice" [properties]="product.Properties" [productId]="product.ID" [form]="form" [info]="info" [tinymceConfig]="tinymceConfig" (onSaved)="refresh('properties')" (onSubmit)="onSubmitWithArguments($event)"></app-properties-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Prices -->
          <div *ngIf="product && !product['Container']" id="tab-prices" style="position: relative">
            <app-prices-block [properties]="product.Properties" [(prices)]="product.Prices" [productId]="product.ID"
                              [basePriceFormControl]="form.get('BasePrice')" [salePriceFormControl]="form.get('SalePrice')"
                              [manufacturerPriceFormControl]="form.get('ManufacturerPrice')" [itemPriceFormControl]="form.get('ItemPrice')"
                              [onSaleFormControl]="form.get('OnSale')"
                              [startFormControl]="form.get('Start')" [endFormControl]="form.get('End')"
                              [form]="form"
                              [decimal]="decimal"
                              [prefix]="prefix"
                              [thousands]="thousands"
                              [dimensionUnit]="dimensionUnit"
                              [weightUnit]="weightUnit"
                              [pattern]="pattern"
                              [tinymceConfig]="tinymceConfig"

            ></app-prices-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Blocks -->
          <div *ngIf="product" id="tab-posts" style="position:relative;">
            <app-blocks-block [productId]="product['ID']" [form]="form" [blocks]="product['Blocks']" [properties]="product['Properties']" [tinymceConfig]="tinymceConfig"></app-blocks-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Variations -->
          <div *ngIf="product" id="tab-variations" style="position: relative">
            <app-variations-block [productId]="product['ID']" [variations]="product['Variations']" [dimensionUnit]="dimensionUnit" [weightUnit]="weightUnit" [form]="form" [containerFormControl]="form.get('Container')" [typeFormControl]="form.get('Type')" [sizeFormControl]="form.get('Size')" (onSaved)="onSubmit($event)"></app-variations-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth fill-white stroke-primary-text-gray" (click)="maximized=!maximized">
                <svg *ngIf="maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize"></use>
                </svg>
                <svg *ngIf="!maximized" width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                </svg>
              </div>
            </div>
          </div>
          <!-- Landings -->
          <div *ngIf="landings">
            <div class="card">
              <div class="card-body" style="padding-bottom: 10px">
                <div class="row">
                  <div class="col">
                    <h2 style="margin-bottom: 20px" translate>Landings</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col" style="display: flex; gap: 15px;">
                    <select id="screen-width" class="form-control" [(ngModel)]="landing" [ngModelOptions]="{standalone: true}">
                      <option *ngFor="let landing of landings" [ngValue]="landing">
                        {{landing.Title}}
                      </option>
                    </select>
                    <a class="btn btn-default" [routerLink]="'/landings/'+landing.ID" [queryParams]="{pid: product.ID}" routerlink translate> Try </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-4': !maximized, 'col-lg-12': maximized}">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h2 style="display: inline-block; margin-bottom: 20px" translate>Internal Note</h2>
                  <span class="tooltip-question" style="display: inline-block; padding-left: 5px;" placement="top" [ngbTooltip]="'Here you can put a note for employees' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <textarea class="form-control" id="notes" rows="3" formControlName="Notes"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h2 style="margin-bottom: 20px" translate>Category</h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-category-input formControlName="Categories"></app-category-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <h2 style="margin-bottom: 20px" translate>Tags</h2>
                    <app-tags-library [input]="product['Tags']" (onSelected)="onInsertTags($event)" #tagsLibrary></app-tags-library>
                    <span class="body-3 color-primary-violet" style="cursor: pointer; margin-bottom: 20px" (click)="tagsLibrary.onOpen(tagsLibrary.content)" translate> View all tags
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-tag-input formControlName="Tags"></app-tag-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h2 style="margin-bottom: 20px" translate>Vendor</h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-vendor-block [vendorFormControl]="form.get('Vendors')" [timeFormControl]="form.get('Times')"></app-vendor-block>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h2 style="margin-bottom: 20px" translate>Related Products</h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-product-input formControlName="RelatedProducts"></app-product-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="!form" class="row">
    <div class="col">
      <div style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
    </div>
  </div>
</div>
