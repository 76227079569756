import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {ApiService} from '../../../shared/api.service';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable()
export class PricesDataSource extends LocalDataSource {
  parent: any;
  local = false;
  id;
  data: any[];
  columns: {
    Ids: []
  };
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;

  constructor(protected http: HttpClient, private apiService: ApiService, private localStorageService: LocalStorageService) {
    super();
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    page = (JSON.parse(this.localStorageService.get('prices_page')) || 1);
    perPage = (JSON.parse(this.localStorageService.get('prices_per_page')) || 10);

    this.pagingConf['page'] = page;
    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    // Local sort refresh
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    //
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.parent && this.parent.term) {
      request['Search'] = this.parent.term;
    }
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach((fieldConf) => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    const u = new URL(url += '/prices/list');
    if (this.parent) {
      if (this.parent.productId) {
        u.searchParams.set('product_id', this.parent.productId);
      }
      if (this.parent.variationId) {
        u.searchParams.set('variation_id', this.parent.variationId);
      }
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach((fieldConf) => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    //
    return this.http.post(u.toString(), request, {headers: headersObject, withCredentials: true}).pipe(map(response => {
      those.lastRequestFiltered = +response['Filtered'];
      those.lastRequestCount = +response['Filtered'];
      those.lastRequestTotal = +response['Total'];
      if (response['Columns']){
        those.columns = response['Columns'];
      }
      those.parent.visible = true;
      const data = (response['Data'] || []).map(item => {
        const properties = item.Properties.split(';');
        const values = item.Values.split(';');
        for (let i = 0; i < properties.length; i++) {
          if (values[i]) {
            item[properties[i]] = values[i];
          }
        }
        return item;
      });
      /*if (properties && those.parent && those.parent.settings) {
        console.log('here');
        for (let i = 0; i < properties.length; i++) {
          those.parent.settings.columns[properties[i]] = {
            title: properties[i],
            filter: false,
            sort: true,
          };
        }
      }*/
      this.data = data;
      return this.data;
    })).toPromise();
  }
}
