import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VariationPropertiesRenderComponent } from './variation-properties-render.component';
import { VariationBasepriceRenderComponent } from './variation-baseprice-render.component';
import { VariationsDataSource } from './variations.data-source';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ApiService } from '../../shared/api.service';
import { VariationProductTitleRenderComponent } from './variation-product-title-render.component';
import { VariationTitleRenderComponent } from './variation-title-render.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-product-variations',
  templateUrl: './variations.component.html',
  styleUrls: ['./variations.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class VariationsComponent implements OnInit, OnDestroy {
  @ViewChild('content') content;
  queryParamsSubscription: Subscription;
  productId;
  source: VariationsDataSource;
  product;
  //
  tree;
  settings;
  form;
  modal;
  public closeResult: string;
  visible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const those = this;
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params['pid']) {
        this.productId = params['pid'];
      }
      those.settings = {
        mode: 'external',
        actions: {
          add: false,
          columnTitle: those.translate.instant('Actions'),
          position: 'right',
          custom: [
            {
              name: 'list',
              title: 'list',
            },
          ],
        },
        columns: {
          ProductTitle: {
            title: those.translate.instant('Product'),
            type: 'custom',
            renderComponent: VariationProductTitleRenderComponent,
            onComponentInitFunction: instance => {
              instance.parent = those;
            },
          },
          Title: {
            title: those.translate.instant('Variation'),
            type: 'custom',
            renderComponent: VariationTitleRenderComponent,
            onComponentInitFunction: instance => {
              instance.parent = those;
            },
          },
          BasePrice: {
            title: those.translate.instant('BasePrice'),
            type: 'custom',
            renderComponent: VariationBasepriceRenderComponent,
          },
          Sku: {
            title: those.translate.instant('SKU'),
          },
          Stock: {
            title: those.translate.instant('Stock'),
          },
          Properties: {
            title: those.translate.instant('Properties'),
            type: 'custom',
            renderComponent: VariationPropertiesRenderComponent,
          },
        },
        pager: {
          display: true,
          perPage: 10,
        },
      };
      //
      those.source = new VariationsDataSource(those.http, those.apiService, those.absolutizeUrl);
      those.source.parent = those;
      those.source.id = those.productId;
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  refresh() {
    this.source.refresh();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  setFilter(newFilter) {
    const filter = [];
    const existingFilter = this.source.getFilter().filters;
    for (let i = 0; i < newFilter.length; i++) {
      let found = false;
      for (let j = 0; j < existingFilter.length; j++) {
        if (newFilter[i]['field'] === existingFilter[j]['field']) {
          if (newFilter[i]['search'] !== existingFilter[j]['search']) {
            filter.push(newFilter[i]);
          }
          found = true;
        }
      }
      if (!found) {
        filter.push(newFilter[i]);
      }
    }
    this.source.setFilter(filter);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate() {
    const those = this;
    those.router.navigate(['/variations/new'], { queryParams: { product_id: those.productId } });
    return;
  }

  onEdit(data) {
    const id = data['ID'];
    this.router.navigate(['/products/' + data['ProductId'] + '/variations/' + id]);
    return;
  }

  onDelete(event) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete variation?';
    modal.componentInstance.body = `Are you sure you want to delete variation? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVariation(event.data['ID'])
          .then(resp => {
            those.success('Delete variation', resp);
            those.source.refresh();
          })
          .catch(err => {
            those.error('Delete variation', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  upload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Description', this.form.get('Description').value);
    data.append('BasePrice', this.form.get('BasePrice').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postVariation(data)
        .then(resp => {
          those.success('Create variation', resp);
          those.modal.close('Saved');
          those.source.refresh();
        })
        .catch(err => {
          those.error('Create variation', err);
        });
    } else {
      // update
      this.apiService
        .putVariation(this.form.get('ID').value, data)
        .then(resp => {
          those.success('Update variation', resp);
          those.modal.close('Saved');
          those.source.refresh();
        })
        .catch(err => {
          those.error('Update variation', err);
        });
    }
  }
}
