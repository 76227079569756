import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../../shared/utils.pipe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmComponent } from '../../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  providers: [AbsolutizeUrlPipe, NgbTypeaheadConfig],
})
export class TimeComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: true }) instance;
  paramsSubscription: Subscription;
  vendorId;
  id;
  time;
  form;
  formSubscription: Subscription;

  tinymceConfig;
  tags;
  tag;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['vendorId']) {
        this.vendorId = params['vendorId'];
      }
      if (params['id']) {
        if (params.id === 'new') {
          those.form = those.formBuilder.group({
            Enabled: [true],
            Name: ['', Validators.required],
            Title: ['', Validators.required],
            Media: [''],
            Description: [''],
            ShowBadge: [false],
            ShowList: [false],
            Position: ['bottom-right'],
          });
          this.formSubscription = this.form.valueChanges.subscribe(x => {
            those.form.markAllAsTouched();
          });
        } else {
          this.id = params.id;
          this.apiService
            .getTime(this.id)
            .then(time => {
              those.time = time;
              those.form = those.formBuilder.group({
                ID: [time['ID']],
                Enabled: [time['Enabled'] ? true : false],
                Name: [time['Name'], Validators.required],
                Title: [time['Title'], Validators.required],
                Media: [time['Media'] ? time['Media'] : 0],
                Description: [time['Description']],
                ShowBadge: [time['ShowBadge'] ? true : false],
                ShowList: [time['ShowList'] ? true : false],
                Position: [time['Position']],
              });
              //
              this.formSubscription = this.form.valueChanges.subscribe(x => {
                those.form.markAllAsTouched();
              });
            })
            .catch(err => {
              those.error('Load time', err);
            });
        }
      }
    });

    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | table | ' +
        'forecolor backcolor emoticons',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  back(pitfall) {
    const state = this.location.getState();
    if (state && state['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate([pitfall]);
    }
  }

  setFormValue(field, value) {
    this.form.get(field).setValue(value);
    this.form.get(field).updateValueAndValidity();
    this.form.get(field).markAsDirty();
  }

  change(event, type = 'name') {
    if (type === 'name') {
      if (event.target.value && !this.form.get('Title').value) {
        this.form.get('Title').setValue(event.target.value);
      }
    } else if (type === 'title') {
      if (event.target.value && !this.form.get('Name').value) {
        this.form.get('Name').setValue(event.target.value);
      }
    }
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Media: file,
    });
    this.form.get('Media').markAsDirty();
  }

  unset() {
    this.form.controls['Media'].setValue('');
    this.form.get('Media').markAsTouched();
    this.form.get('Media').updateValueAndValidity();
  }

  trim(control) {
    if (control) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  onDelete() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete time?';
    modal.componentInstance.body = `Are you sure you want to delete time? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteTime(those.time.ID)
          .then(resp => {
            those.success('Delete time', resp);
            those.router.navigate(['/vendors/' + those.vendorId]);
          })
          .catch(err => {
            those.error('Delete time', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Media', this.form.get('Media').value);
    data.append('Description', this.form.get('Description').value);
    data.append('ShowBadge', this.form.get('ShowBadge').value);
    data.append('ShowList', this.form.get('ShowList').value);
    data.append('Position', this.form.get('Position').value);
    if (!this.time) {
      // create
      data.append('VendorId', this.vendorId);
      this.apiService
        .postTime(data)
        .then(resp => {
          those.time = resp;
          those.success('Create time', resp);
          those.router.navigate(['/vendors/' + those.vendorId]);
        })
        .catch(err => {
          those.error('Create time', err);
        });
    } else {
      // update
      this.apiService
        .putTime(this.time.ID, data)
        .then(resp => {
          those.time = resp;
          those.success('Update time', resp);
        })
        .catch(err => {
          those.error('Update time', err);
        });
    }
  }
}
