<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col"><h1 *ngIf="route.snapshot.data['title']" [innerText]="route.snapshot.data['title'] | translate"></h1></div>
      <div class="col">
        <div class="btn-popup pull-right" style="margin-bottom: 0">
          <app-edit-media [source]="source" (onCreated)="table.refresh(true)" (onSaved)="table.refresh(true)" #editor>
            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                    data-target="#exampleModal" (click)="editor.onCreate(editor.content, 'lg')" translate>Add media to library
            </button>
          </app-edit-media>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="route.snapshot.data['subtitle'] || route.snapshot.data['description']" class="row">
            <div class="col-sm-12">
              <h2 *ngIf="route.snapshot.data['subtitle']" [innerText]="route.snapshot.data['subtitle'] | translate"></h2>
              <div *ngIf="route.snapshot.data['description']" class="body-3 mb-4" [innerText]="route.snapshot.data['description'] | translate">
              </div>
            </div>
          </div>
          <app-my-smart-table [type]="'medias'" [(columns)]="columns" [presets]="presets" [bulks]="bulks" [filters]="filters" [sorts]="sorts" [(source)]="source" [parent]="this" #table></app-my-smart-table>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
