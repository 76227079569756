<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col">
        <h1 *ngIf="route.snapshot.data['title']" [innerText]="route.snapshot.data['title'] | translate"></h1>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row" style="padding-bottom: 20px">
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-settings'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/global']" class="body-1" translate>Global</a>
          <div class="body-3 description" translate>
            View and update your store details
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-users-and-permissions'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/account']" class="body-1" translate>User & Permissions</a>
          <div class="body-3 description" translate>
            Manage what users can see or do in your store
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-notifications'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/notification']" class="body-1" translate>Notifications</a>
          <div class="body-3 description" translate>
            Manage notifications sent to you and your customers.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-locations'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings']" class="body-1" translate>Locations</a>
          <div class="body-3 description" translate>
            Manage the places you stock inventory, fulfill orders, sell products (not implemented yet)
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-legal'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/pages']" class="body-1" translate>Pages</a>
          <div class="body-3 description" translate>
            Manage your store's pages.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-themes'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/themes']" class="body-1" translate>Themes</a>
          <div class="body-3 description" translate>
            Frontend theme basic settings
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-webforms'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/forms']" class="body-1" translate>Webforms</a>
          <div class="body-3 description" translate>
            Forms on site
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-menues'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/menus']" class="body-1" translate>Menus</a>
          <div class="body-3 description" translate>
            Menus on site
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-payments'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/payment']" class="body-1" translate>Payments</a>
          <div class="body-3 description" translate>
            Enable and manage your store's payment providers.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-checkout'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/']" class="body-1" translate>Checkout</a>
          <div class="body-3 description" translate>
            Customize your online checkout process (not implemented yet)
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-shipping-and-delivery'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/transports/companies']" class="body-1" translate>Shipping & Delivery</a>
          <div class="body-3 description" translate>
            Manage how you ship orders to customers.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-taxes'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/tax']" class="body-1" translate>Taxes</a>
          <div class="body-3 description" translate>
            Manage how your store charges taxes.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-voucher-and-gift-cards'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/sales/coupons']" class="body-1" translate>Voucher & Gift Cards</a>
          <div class="body-3 description" translate>
            Manage your store’s voucher and gift card settings.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-product-settings'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/product']" class="body-1" translate>Product Settings</a>
          <div class="body-3 description" translate>
            Manage your store's product settings.
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-media'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/media']" class="body-1" translate>Media</a>
          <div class="body-3 description" translate>
            Media settings
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <div class="panel">
        <div class="panel-icon" style="width: 60px">
          <svg>
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-'+'settings-appearance'"></use>
          </svg>
        </div>
        <div class="panel-text">
          <a [routerLink]="['/settings/appearance']" class="body-1" translate>Appearance</a>
          <div class="body-3 description" translate>
            UI settings
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
