import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <div>{{value | amDateFormat:'DD.MM.YYYY'}}</div>
      <div class="time" style="font-size: smaller">{{value | amDateFormat:'HH:mm'}}</div>
    </div>
  `,
})
export class MediaDateRenderComponent implements OnInit, ViewCell {
  n: number;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    this.n = parseInt(this.value, 10);
  }
}
