import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {credentials} from '../credentials.data';

@Component({
  template: `
      {{currency}}{{theValue}}
  `,
})
export class PriceRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  currency = credentials.currency;
  theValue = '0.00';

  ngOnInit(): void {
    this.theValue = Number(this.value).toFixed(2);
  }
}
