import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-service',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h2
          class="modal-title"
          id="exampleModalLabel"
          [innerHTML]="(!service ? 'Add' : 'Edit') + ' service' | translate"
          translate></h2>
        <span class="cross" (click)="modal.dismiss('Cross click')">
          <svg style="margin-right:10px; height: 12px; width: 12px;">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
          </svg>
        </span>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Display Name</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Name</label>
                  <input class="form-control" id="name" type="text" formControlName="Name" />
                </div>
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <textarea class="form-control" id="description" formControlName="Description"></textarea>
                </div>
                <div class="form-group">
                  <label for="price" class="mb-1" translate>Price</label>
                  <input class="form-control" id="price" type="text" formControlName="Price" />
                </div>
                <div class="form-group">
                  <label for="enabled" class="mb-1" translate>Status</label>
                  <select class="form-control" id="enabled" formControlName="Enabled">
                    <option [value]="true">Active</option>
                    <option [value]="false">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="display: block">
          <button
            type="submit"
            id="save"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            translate>
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span style="vertical-align: text-bottom">Save</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
          <button
            *ngIf="service"
            class="btn btn-sm btn-default delete"
            type="button"
            style="padding: 8px;"
            (click)="onDelete()">
            <svg style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
            </svg>
          </button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [``],
  providers: [AbsolutizeUrlPipe],
})
export class ServiceComponent implements OnInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onDeleted: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  service;
  modal;
  closeResult;
  form;
  timer;
  config;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Description: [''],
      Price: [0],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, service) {
    const those = this;
    this.service = service.getRawValue();
    this.form = service;
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
        those.onSaved.emit();
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onDelete() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete service?';
    modal.componentInstance.body = `Are you sure you want to delete service? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.onDeleted.emit(this.service);
      },
      reason => {}
    );
  }

  change(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  onSubmit() {
    this.service = this.form.getRawValue();
    this.modal.close('Saved');
    this.onSaved.emit(this.service);
  }
}
