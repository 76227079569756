import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div class="datetime">
      <div class="date">{{value | amDateFormat:'DD.MM.YYYY'}}</div>
      <div class="time">{{value | amDateFormat:'HH:mm'}}</div>
    </div>
  `,
})
export class DateRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;

}
