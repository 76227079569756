import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {AbsolutizeUrlPipe, MediaThumbnailPipe} from '../../utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { ApiService } from '../../api.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-media-block',
  styleUrls: ['./media-block.component.scss'],
  templateUrl: './media-block.component.html',
})
export class MediaBlockComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.n = window.innerWidth <= 767 ? 3 : 4;
  }
  @Input('type') type = 'product';
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('commentId') commentId = 0;
  @Input('medias') medias = [];
  @Input('mediaIdFormControl') mediaIdFormControl: FormControl;
  @ViewChild(DropzoneDirective) dropzone: DropzoneDirective;
  media;
  hasImages = false;
  hasFiles = false;
  dragulaSubscription = new Subscription();
  //
  imagesDropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: 'image/*',
    autoReset: 1000,
    clickable: true,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    parallelUploads: 1,
    timeout: 300000,
  };
  filesDropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: 'application/*',
    autoReset: 1000,
    clickable: true,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    parallelUploads: 1,
    timeout: 300000,
  };
  images = [];
  files = [];
  selectedImages = [];
  selectedImagesFlag = true;
  timeout;
  n = 4;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    public mediaThumbnail: MediaThumbnailPipe,
    private modalService: NgbModal
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  ngOnInit() {
    const those = this;
    const url = new URL(this.apiService.getUrl() + '/medias');
    if (this.productId) {
      url.searchParams.set('pid', String(this.productId));
    } else if (this.variationId) {
      url.searchParams.set('vid', String(this.variationId));
    } else if (this.commentId) {
      url.searchParams.set('cid', String(this.commentId));
    }
    this.imagesDropzoneConfig.url = url.toString();
    this.imagesDropzoneConfig.headers = this.apiService.getAuthorizationHeader();
    this.imagesDropzoneConfig.paramName = 'Body';
    this.filesDropzoneConfig.url = url.toString();
    this.filesDropzoneConfig.headers = this.apiService.getAuthorizationHeader();
    this.filesDropzoneConfig.paramName = 'Body';
    //
    this.dragulaService.createGroup('images', {
      moves: (el, container, handle) => {
        const classes =
          (handle.className &&
            handle.className['baseVal'] &&
            handle.className['baseVal']['split'] &&
            handle.className['baseVal'].split(/\s+/)) ||
          [];
        if (classes.indexOf('draggable') !== -1) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
    this.dragulaSubscription.add(
      this.dragulaService.drop('images').subscribe(({ name, el, sibling }) => {
        console.log('el', el);
        if (el) {
          let element = el.parentNode.firstChild;
          console.log('element', element);
          do {
            if (element.nodeType === 3) continue; // text node
            const classList = (element as Element).classList;
            if (classList && classList.contains('draggable-wrapper')) {
              if (classList.contains('gu-transit')) {
                classList.add('draggable-dropped');
              } else {
                classList.remove('draggable-dropped');
              }
            }
          } while ((element = element.nextSibling));
          if (those.timeout) {
            clearTimeout(those.timeout);
            those.timeout = undefined;
          }
          those.timeout = setTimeout(() => {
            el?.classList?.remove('draggable-dropped');
          }, 10000);
        }
        for (let i = 0; i < this.images.length; i++) {
          const index = this.medias.findIndex(item => item.ID === this.images[i].ID);
          if (index >= 0) {
            this.medias[index].Sort = i + 1;
          }
        }
        this.onSubmit();
      })
    );
    this.reload();
  }

  ngAfterViewInit() {
    this.n = window.innerWidth <= 767 ? 3 : 4;
  }

  ngOnDestroy() {
    this.dragulaSubscription.unsubscribe();
    this.dragulaService.destroy('images');
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  open(component, typ, event) {
    console.log('open', typ);
    let defaultFilter = {};
    if (typ === 'images') {
      defaultFilter = { ContentType: '^image/' };
    } else if (typ === 'files') {
      defaultFilter = { ContentType: '!^image/' };
    }
    component.onOpen(component.content, defaultFilter);
    event.preventDefault();
    event.stopPropagation();
  }

  reload() {
    const those = this;
    this.medias = this.medias || [];
    if (this.medias) {
      this.medias.forEach((item, i) => {
        if (item.ContentType && item.ContentType.indexOf('image/') >= 0) {
          those.medias[i].Preview =
            '/api/v1/resize?path=/storage' +
            item.Path +
            '&width=160&updated=' +
            Math.floor(new Date(item.UpdatedAt).getTime() / 1000).toString(36);
        } else if (item.ContentType && item.ContentType === 'application/pdf') {
          those.medias[i].Preview = '/admin/assets/images/file-pdf.svg';
        } else if (item.ContentType && item.ContentType === 'application/zip') {
          those.medias[i].Preview = '/admin/assets/images/file-zip.svg';
        } else if (
          item.ContentType &&
          ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(
            item.ContentType
          ) >= 0
        ) {
          those.medias[i].Preview = '/admin/assets/images/file-doc.svg';
        } else if (
          item.ContentType &&
          ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(
            item.ContentType
          ) >= 0
        ) {
          those.medias[i].Preview = '/admin/assets/images/file-xls.svg';
        } else {
          those.medias[i].Preview = '/admin/assets/images/file.svg';
        }
      });
      this.files = this.medias.filter(
        media => !media.ContentType || !media.ContentType.match(/^image\/(jpg|jpeg|png|webp)$/)
      );
      this.images = this.medias
        .filter(media => media.ContentType && media.ContentType.match(/^image\/(jpg|jpeg|png|webp)$/))
        .sort((a, b) => {
          return a.Sort - b.Sort;
        });
      if (this.images.length > 0) {
        if (those.mediaIdFormControl && !those.mediaIdFormControl.value) {
          those.mediaIdFormControl.setValue(this.images[0].ID);
          those.mediaIdFormControl.markAsTouched();
          those.mediaIdFormControl.markAsDirty();
        } else if (those.mediaIdFormControl) {
          const id = those.mediaIdFormControl.value;
          if (this.images.findIndex(item => id == item.ID) == -1) {
            those.mediaIdFormControl.setValue(this.images[0].ID);
            those.mediaIdFormControl.markAsTouched();
            those.mediaIdFormControl.markAsDirty();
          }
        }
      } else {
        if (those.mediaIdFormControl?.value) {
          those.mediaIdFormControl.setValue(0);
          those.mediaIdFormControl.markAsTouched();
          those.mediaIdFormControl.markAsDirty();
        }
      }
    }
    this.hasImages = this.images && this.images.length > 0;
    this.hasFiles = this.files && this.files.length > 0;
  }

  onInsertMedia(selected) {
    this.medias = this.medias || [];
    this.medias = [...this.medias, ...selected];
    this.reload();
    this.onSubmit();
  }

  selectImage() {
    this.selectedImages = this.images.filter(item => item['selected']);
    this.selectedImagesFlag = true;
  }

  unselectImages() {
    this.images = this.images.map(item => {
      item['selected'] = false;
      return item;
    });
    this.selectedImages = [];
  }

  deleteImages() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete file?';
    modal.componentInstance.body = `Are you sure you want to delete file? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.medias = those.medias.filter(item => !those.selectedImages.find(item2 => item2.ID === item.ID));
        those.selectedImages = [];
        those.onSubmit();
      },
      reason => {}
    );
  }

  onDrop(event) {
    const those = this;
    const from = this.medias.findIndex(item => item.ID === those.media.ID);
    const to = this.medias.findIndex(item => item.ID === event.ID);
    const v = this.medias.splice(from, 1);
    this.medias.splice(to, 0, v[0]);
    this.onSubmit();
  }

  onImageUploadClick(event) {
    if (!event.target.closest('.dz-message-icon')) {
      event.stopPropagation();
    }
  }

  onImageUploadSuccess(event) {
    if (event && event.length > 1) {
      this.medias.push(event[1]);
      this.reload();
    }
  }

  onImageUploadError($event) {
    if ($event && $event[1]) {
      if ($event[1]['ERROR']) {
        this.error('Create image', { status: 500, error: $event[1] });
      } else {
        this.error('Create image', { status: 500, error: { ERROR: $event[1] } });
      }
    } else {
      this.error('Create image', { status: 500, error: { ERROR: 'Something went wrong' } });
    }
  }

  onImageUploadComplete() {
    this.dropzone.reset();
  }

  onEditedMedia(event) {
    console.log('onEditedMedia', event);
  }

  onImageSaved(media) {
    const index = this.medias.findIndex(item => item.ID == media.ID);
    if (index >= 0) {
      this.medias[index] = media;
      this.reload();
    }
  }

  onFileCreated(media) {
    console.log('onFileCreated', media);
    this.medias.push(media);
    this.reload();
  }

  onFileSaved(media) {
    console.log('onFileSaved', media);
    const index = this.medias.findIndex(item => item.ID === media.ID);
    if (index >= 0) {
      this.medias[index] = media;
    }
    this.reload();
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete file?';
    modal.componentInstance.body = `Are you sure you want to delete file? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.medias = those.medias.filter(media => media.ID !== id);
        those.reload();
        those.onSubmit();
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    this.apiService['patch' + this.capitalizeFirstLetter(this.type)](
      this[this.type + 'Id'],
      'setMedias',
      those.medias.map(item => ({ ID: item.ID, Sort: item.Sort }))
    )
      .then(() => {
        those.reload();
      })
      .catch(err => {
        those.error('Save media', err);
      });
  }
}
