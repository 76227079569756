import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div class="datetime">
      <div class="date">{{date | amTimeAgo}}</div>
    </div>
  `,
})
export class DateAgoRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;
  date;

  ngOnInit() {
    this.date = new Date(this.value);
  }
}
