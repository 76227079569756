import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { credentials } from '../../../shared/credentials.data';

@Component({
  selector: 'app-inventory-block',
  template: `
    <div class="card" style="margin-bottom: 20px;">
      <div class="card-body pb-2">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 20px" translate>Inventory</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="mb-1" for="sku" translate>SKU (Stock Keeping Units)</label>
              <input
                class="form-control"
                id="sku"
                type="text"
                (click)="$event.target['select']()"
                [formControl]="form.get('Sku')" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="mb-1" for="barcode" translate>Barcode</label>
              <input
                class="form-control"
                id="barcode"
                type="text"
                (click)="$event.target['select']()"
                [formControl]="form.get('Barcode')" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="padding-right: 5px">
              <input class="checkbox_animated" type="checkbox" [formControl]="form.get('TrackQuantity')" />
              {{ 'Track Quantity' | translate }}
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="padding-right: 5px">
              <input class="checkbox_animated" type="checkbox" [formControl]="form.get('Overselling')" />
              {{ 'Continue selling when out of stock' | translate }}
            </label>
            <span
              class="tooltip-question"
              style="vertical-align: sub;"
              placement="top"
              [ngbTooltip]="'ability to sell more than in stock (according to inventory)' | translate">
              <svg width="16px" height="16px">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
              </svg>
            </span>
          </div>
        </div>
        <div *ngIf="form.get('TrackQuantity').value == true">
          <div class="row border-top mt-2 pt-2">
            <div class="col">
              <h2 style="margin-bottom: 19px" translate>Quantity</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="mb-1" for="availability" translate>Availability</label>
                <app-select [options]="availabilities" [formControl]="form.get('Availability')"></app-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="mb-1" for="stock" translate>Stock</label>
                <input
                  class="form-control"
                  id="stock"
                  type="text"
                  (click)="$event.target['select']()"
                  [formControl]="form.get('Stock')" />
              </div>
            </div>
          </div>
          <div class="row" style="padding-bottom: 15px;">
            <div class="col">
              <div *ngIf="hasTransfers">
                <app-transfers [productId]="productId" [variationId]="variationId" #transfers></app-transfers>
                <span
                  class="btn-sm btn-default"
                  style="padding-left: 0"
                  data-toggle="modal"
                  data-original-title="test"
                  data-target="#exampleModal"
                  (click)="transfers.onOpen(transfers.content)"
                  translate>
                  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.90702 0.5C6.12134 0.5 2.68828 2.55923 0.155035 5.90397C-0.0516783 6.178 -0.0516783 6.56083 0.155035 6.83486C2.68828 10.1836 6.12134 12.2429 9.90702 12.2429C13.6927 12.2429 17.1258 10.1836 19.659 6.83889C19.8657 6.56486 19.8657 6.18203 19.659 5.908C17.1258 2.55923 13.6927 0.5 9.90702 0.5ZM10.1786 10.506C7.66561 10.6632 5.59037 8.60394 5.74845 6.10143C5.87815 4.03817 7.56022 2.3658 9.63546 2.23685C12.1484 2.07968 14.2237 4.13892 14.0656 6.64143C13.9318 8.70066 12.2498 10.373 10.1786 10.506ZM10.0529 8.59588C8.69917 8.68051 7.58049 7.57231 7.66966 6.22636C7.73856 5.11413 8.64648 4.21548 9.76516 4.14295C11.1189 4.05832 12.2376 5.16652 12.1484 6.51247C12.0755 7.62873 11.1676 8.52738 10.0529 8.59588Z"
                      fill="#5C22CF" />
                  </svg>
                  {{ 'View inventory history' | translate }}
                </span>
              </div>
            </div>
            <div class="col" style="text-align: right">
              <app-edit-transfer
                [productId]="productId"
                [variationId]="variationId"
                [stock]="form.get('Stock').value"
                (onSaved)="refresh($event)"
                #transferEditor>
                <span
                  class="btn-sm btn-default facon-plus"
                  (click)="transferEditor.onCreate(transferEditor.content)"
                  translate>
                  Add Transfer</span
                >
              </app-edit-transfer>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class InventoryBlockComponent implements OnInit {
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('form') form: FormGroup;
  @Input('hasTransfers') hasTransfers = false;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();

  availabilities = credentials.availabilities;

  ngOnInit() {}

  getAvailability(name: string) {
    const pattern = this.availabilities.find(item => item.Name === name);
    if (pattern) {
      return pattern;
    }
    return this.availabilities[0];
  }

  refresh(event) {
    console.log('event', event);
    if (event && event.Quantity) {
      this.form.get('Stock').setValue(event.Quantity);
    }
    this.onSaved.emit(event);
  }
}
