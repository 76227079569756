<ng-content></ng-content>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title body-2" id="exampleModalLabel" translate>
      Transfers
    </h5>
    <span class="cross" (click)="modal.dismiss('Cross click')">
      <svg style="margin-right:10px; height: 12px; width: 12px;">
        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
      </svg>
    </span>
  </div>
  <div class="modal-body" style="background-image: linear-gradient(to bottom, rgba(0,0,0,0) 90%, rgba(0,0,0,0.1));">
    <div class="row">
      <div class="col">
        <div class="modal-table custom-datatable transfers">
          <div class="table-responsive" [ngClass]="!visible || !filtered?'transparent':''">
            <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
            <div *ngIf="source && source.count() > 0" class="records-count">
                          <span *ngIf="source.filtered() != source.total()">
                            {{'Filtered' | translate}}
                            : {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                          </span>
              <span *ngIf="source.filtered() == source.total()">
                            {{'Records' | translate}}: {{source.total()}}
                          </span>
            </div>
          </div>
          <div *ngIf="!visible || !filtered" style="position: absolute;top: 100px;left: 46%;"><img
            src="assets/images/loading.svg" alt="Loading..." style="width: 100px" ngbAutofocus></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
