import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../shared/api.service';
import {fromEvent, Subscription} from 'rxjs';

@Component({
  styles: [`
    .title {
      display: none;
    }
    .dragging {
      //border: 1px solid red;
    }
    .dragging .title {
      display: block;
      left: -200px;
      min-width: 200px;
      position: absolute;
    }
  `],
  template: `
    <div *ngIf="!rowData['term']" class="custom" style="position:relative; text-align: center" #ref>
      <div
        draggable="true"
        [attr.id]="rowData.ID"
        (dragstart)="onDragStart($event)"
        (dragend)="onDragEnd($event)"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)"
      >
        <!--div class="title">{{rowData.Title}}</div-->
        <svg style="cursor: pointer; height: 24px; width: 24px">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-move"></use>
        </svg>
      </div>
    </div>
  `,
})
export class ProductSortRenderComponent implements OnInit, ViewCell, AfterViewInit {
  @ViewChild('ref') ref;
  parent;
  @Input() value: string;
  @Input() rowData: any;
  timer;

  subscription: Subscription;
  left;
  top;

  counter = -1;

  // https://www.digitalocean.com/community/tutorials/js-drag-and-drop-vanilla-js

  constructor(public apiService: ApiService){

  }

  ngOnInit(): void {
    //console.log('rowData', this.rowData);
  }

  ngAfterViewInit(): void {
    if (this.rowData['Updated']){
      let element = this.ref.nativeElement.parentElement;
      while (element) {
        if (element.classList.contains('ng2-smart-row')) {
          break;
        }
        element = element.parentElement;
      }
      element.style.outline = '1px solid red';
      //element.style.backgroundColor = 'gray';
      setTimeout(() => {
        this.rowData['Updated'] = false;
        element.style.outline = 'none';
        //element.style.backgroundColor = 'initial';
      }, 1500);
    }
  }

  onDragStart(event) {
    console.log('onDragStart', event);
    const id = event.target.id;
    event
      .dataTransfer
      .setData('text/plain', id);
    console.log('sent id', id);

    event.srcElement.classList.add("dragging");
    const tr = event.srcElement.closest('tr');
    if (tr) {
      tr.style.opacity = 0.2;
    }
  }

  onDragEnd(event) {
    console.log('onDragEnd', event);
    event.srcElement.classList.remove("dragging");
    const tr = event.srcElement.closest('tr');
    if (tr) {
      tr.style.opacity = 1;
    }
  }

  onDragEnter(event) {
    console.log('onDragEnter', event);
  }

  onDragLeave(event) {
    console.log('onDragLeave', event);
  }

  onDrop(event) {
    const those = this;
    console.log('onDrop', event);
    const source = event
      .dataTransfer
      .getData('text');
    const destination = this.rowData.ID;
    console.log('source', source, 'destination', destination);
    //
    const ids = [];
    const sorts = [];
    let from, to;
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === Number(source)) {
        from = i;
      }
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        to = i;
      }
      ids.push(this.parent.source.data[i]['ID']);
      sorts.push(this.parent.source.data[i]['Sort']);
    }
    const idsOrig = [...ids];
    const sortsOrig = [...sorts];
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    const id = ids.splice(from, 1);
    ids.splice(to, 0, id[0]);
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    //
    const update = [];
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] !== idsOrig[i] || sorts[i] !== sortsOrig[i]) {
        /*update.push(those.apiService.patchProduct(idsOrig[i], 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(sorts[i])}).catch(err => {
          console.log(err);
        }));*/
        //update.push({ID: idsOrig[i], Sort: sorts[i]});
      }
    }
    //
    const row = this.parent.source.data.splice(from, 1);
    this.parent.source.data.splice(to, 0, row[0]);
    for (let i = 0; i < this.parent.source.data.length; i++) {
      this.parent.source.data[i]['Sort'] = sorts[i];
    }
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    this.timer = setTimeout(() => {
      /*Promise.all(update).catch(err => {
        console.log(err);
      });*/
    }, 1000);
    //
    event.preventDefault();
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  /**/

  onMouseDown(event) {
    const those = this;
    console.log('onMouseDown', event);
    those.left = event.clientX;
    those.top = event.clientY;
    console.log('offsetX', event.offsetX, 'offsetY', event.offsetY);
    event.srcElement.style.position = 'fixed';

    event.srcElement.style.left = those.left - (event.target.clientWidth / 2) + 'px';
    event.srcElement.style.top = those.top - (event.target.clientHeight / 2) + 'px';

    this.subscription =
      fromEvent(window.document, 'mousemove')
        .subscribe(e => {
          //console.log('e', e);
          event.srcElement.style.left = e['clientX'] - (event.target.clientWidth / 2) + 'px';
          event.srcElement.style.top = e['clientY'] - (event.target.clientHeight / 2) + 'px';
        });

      /*fromEvent(window.document, 'mouseover')
        .subscribe(e => {
          console.log('mouseover', e);
        });*/
    event.stopPropagation();
  }

  onMouseUp(event) {
    console.log('onMouseUp', event);
    event.srcElement.style.position = 'initial';
    event.srcElement.style.left = 'initial';
    event.srcElement.style.top = 'initial';
    /*event.srcElement.style.left = this.left + 'px';
    event.srcElement.style.top = this.top + 'px';*/
    //this.subscription.unsubscribe();
    event.stopPropagation();
  }

  up() {
    const a = {ID: 0, Sort: 0};
    const b = {ID: 0, Sort: 0};
    if (this.timer) {
      clearTimeout(this.timer);
    }
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (i > 0) {
          if (this.parent.source.data[i - 1]['level'] != this.parent.source.data[i]['level']) {
            return;
          }
          a.ID = this.parent.source.data[i]['ID'];
          a.Sort = this.parent.source.data[i - 1]['Sort'];
          b.ID = this.parent.source.data[i - 1]['ID'];
          b.Sort = this.parent.source.data[i]['Sort'];
          this.parent.source.data[i - 1]['Sort'] = b['Sort'];
          this.parent.source.data[i]['Sort'] = a['Sort'];
          this.parent.source.data[i]['Updated'] = true;
          //
          const item = this.parent.source.data[i];
          this.parent.source.data[i] = this.parent.source.data[i - 1];
          this.parent.source.data[i - 1] = item;
          break;
        }else{
          return;
        }
      }
    }
    //this.parent.source.data.sort((a, b) => { if (a['Sort'] > b['Sort']) { return 1; }else if (a['Sort'] < b['Sort']) { return -1; }else{ return 0; }});
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all([
        this.apiService.patchProduct(a.ID, 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(a.Sort)}).catch(err => {
          console.log(err);
        }),
        this.apiService.patchProduct(b.ID, 'setSort',{CategoryId: Number(this.parent.cid), Sort: Number(b.Sort)}).catch(err => {
          console.log(err);
        }),
      ]).catch(err => {
        console.log(err);
      });
    }, 1000);
  }

  down() {
    const a = {ID: 0, Sort: 0};
    const b = {ID: 0, Sort: 0};
    if (this.timer) {
      clearTimeout(this.timer);
    }
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (i < this.parent.source.data.length - 1) {
          if (this.parent.source.data[i]['level'] != this.parent.source.data[i + 1]['level']) {
            return;
          }
          a.ID = this.parent.source.data[i]['ID'];
          a.Sort = this.parent.source.data[i + 1]['Sort'];
          b.ID = this.parent.source.data[i + 1]['ID'];
          b.Sort = this.parent.source.data[i]['Sort'];
          this.parent.source.data[i + 1]['Sort'] = b['Sort'];
          //this.parent.source.data[i + 1]['Updated'] = true;
          this.parent.source.data[i]['Sort'] = a['Sort'];
          this.parent.source.data[i]['Updated'] = true;
          //
          const item = this.parent.source.data[i];
          this.parent.source.data[i] = this.parent.source.data[i + 1];
          this.parent.source.data[i + 1] = item;
          break;
        }else{
          return;
        }
      }
    }
    //this.parent.source.data.sort((a, b) => { if (a['Sort'] > b['Sort']) { return 1; }else if (a['Sort'] < b['Sort']) { return -1; }else{ return 0; }});
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all([
        this.apiService.patchProduct(a.ID, 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(a.Sort)}).catch(err => {
          console.log(err);
        }),
        this.apiService.patchProduct(b.ID, 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(b.Sort)}).catch(err => {
          console.log(err);
        }),
      ]).catch(err => {
        console.log(err);
      });
    }, 1000);
  }

  swap(){
    const selected = [];
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        this.parent.source.data[i]['selected'] = !this.rowData['selected'];
      }
      if (this.parent.source.data[i]['selected']) {
        selected.push(i);
      }
    }
    if (selected.length <= 1) {
      console.log('case1');
    }else if (selected.length === 2) {
      console.log('case2', selected);
      const a = {ID: 0, Sort: 0};
      const b = {ID: 0, Sort: 0};
      if (this.timer) {
        clearTimeout(this.timer);
      }
      //
      a.ID = this.parent.source.data[selected[0]]['ID'];
      a.Sort = this.parent.source.data[selected[1]]['Sort'];
      b.ID = this.parent.source.data[selected[1]]['ID'];
      b.Sort = this.parent.source.data[selected[0]]['Sort'];
      this.parent.source.data[selected[1]]['Sort'] = b['Sort'];
      this.parent.source.data[selected[1]]['Updated'] = true;
      this.parent.source.data[selected[0]]['Sort'] = a['Sort'];
      this.parent.source.data[selected[0]]['Updated'] = true;
      const item = this.parent.source.data[selected[0]];
      this.parent.source.data[selected[0]] = this.parent.source.data[selected[1]];
      this.parent.source.data[selected[1]] = item;
      //
      for (let i = 0; i < this.parent.source.data.length; i++) {
        this.parent.source.data[i]['selected'] = false;
      }
      this.parent.source.local = true;
      this.parent.source.refresh();
      //
      this.timer = setTimeout(() => {
        Promise.all([
          this.apiService.patchProduct(a.ID, 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(a.Sort)}).catch(err => {
            console.log(err);
          }),
          this.apiService.patchProduct(b.ID, 'setSort', {CategoryId: Number(this.parent.cid), Sort: Number(b.Sort)}).catch(err => {
            console.log(err);
          }),
        ]).catch(err => {
          console.log(err);
        });
      }, 1000);
    }else{
      console.log('case3');
      for (let i = 0; i < this.parent.source.data.length; i++) {
        this.parent.source.data[i]['selected'] = false;
      }
    }
  }

}
