import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-value',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!value || !value['ID']" class="modal-title" id="exampleModalLabel" translate>Add Value</h5>
        <h5 *ngIf="value && value['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Value</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Title</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="value" class="mb-1" translate>Value</label>
                  <input
                    class="form-control"
                    id="value"
                    type="text"
                    (focusout)="touched = true"
                    formControlName="Value" />
                </div>
                <div class="tab">
                  <button
                    [ngClass]="{ tablinks: true, active: selectedTab === 'ColorTab' }"
                    type="button"
                    (click)="onClickTab($event, 'ColorTab')"
                    translate>
                    Color
                  </button>
                  <button
                    [ngClass]="{ tablinks: true, active: selectedTab === 'ThumbnailTab' }"
                    type="button"
                    (click)="onClickTab($event, 'ThumbnailTab')"
                    translate>
                    Thumbnail
                  </button>
                </div>
                <div
                  class="form-group"
                  style="margin-bottom: 1rem"
                  id="ColorTab"
                  class="tabcontent"
                  *ngIf="selectedTab === 'ColorTab'">
                  <label for="value" class="mb-1" translate>Color</label>
                  <input
                    class="form-control"
                    id="value"
                    type="color"
                    (focusout)="touched = true"
                    formControlName="Color" />
                </div>
                <div
                  class="form-group mb-0"
                  style="margin-bottom: 1rem"
                  id="ThumbnailTab"
                  class="tabcontent"
                  *ngIf="selectedTab === 'ThumbnailTab'">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                  <div
                    *ngIf="
                      form.controls['Thumbnail'] &&
                      form.controls['Thumbnail'].value &&
                      !form.controls['Thumbnail'].touched
                    "
                    style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                    <a [href]="form.controls['Thumbnail'].value" target="_blank"
                      ><img
                        [src]="form.controls['Thumbnail'].value"
                        style="max-height: 100px;border-radius: 5px;margin-bottom: 5px;"
                    /></a>
                    <div
                      style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                      (click)="form.controls['Thumbnail'].value = ''">
                      &times;
                    </div>
                  </div>
                  <app-file-uploader
                    *ngIf="!form.controls['Thumbnail'].value"
                    (filesLoaded)="upload($event)"></app-file-uploader>
                </div>
                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col-md-12">
                    <label for="availability" class="mb-1" translate>Availability</label>
                    <select class="form-control" id="availability" formControlName="Availability">
                      <option value="available">{{ 'Available' | translate }}</option>
                      <option value="pre-order">{{ 'Pre-order' | translate }}</option>
                      <option value="out of stock">{{ 'Out of stock' | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="description" class="mb-1" translate>Description</label>
                <app-tinymce
                  id="description"
                  [options]="tinymceConfig"
                  (focus)="$event.target?.iframeElement?.classList?.add('focused')"
                  (blur)="$event.target?.iframeElement?.classList?.remove('focused')"
                  formControlName="Description"></app-tinymce>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
      .tab {
        overflow: hidden;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
      }

      /* Style the buttons inside the tab */
      .tab button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
      }

      /* Change background color of buttons on hover */
      .tab button:hover {
        background-color: #ddd;
      }

      /* Create an active/current tablink class */
      .tab button.active {
        background-color: #ccc;
      }

      /* Style the tab content */
      .tabcontent {
        padding: 6px 12px;
        border: 1px solid #ccc;
        border-top: none;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class ValueComponent implements OnInit, AfterViewInit {
  @Input('propertyId') propertyId;
  @Input('optionId') optionId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  touched = false;
  value;
  modal;
  closeResult;
  form;
  tinymceConfig;
  timer;
  files: File[];
  selectedTab = 'ColorTab';
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      //document_base_url: those.absolutizeUrl.transform('/admin'),
      //relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp['Url']);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err['ERROR'] ? err['ERROR'] : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  change(event) {
    if (!this.form.get('ID').value && !this.touched) {
      this.form.get('Value').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  onCreate(content, options?) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Title: ['', Validators.required],
      Description: [''],
      Thumbnail: [''],
      Value: ['', Validators.required],
      Availability: ['available'],
      Sort: [0],
      Color: [''],
    });
    //
    those.modal = those.modalService.open(content, {
      ...options,
      ...{ ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' },
    });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onClickTab(evt, tabName) {
    this.selectedTab = tabName;
  }
  onEdit(content, id, options?) {
    const those = this;
    this.apiService
      .getValue(id)
      .then(value => {
        those.value = value;
        those.form = those.formBuilder.group({
          ID: [value['ID']],
          Title: [value['Title'], Validators.required],
          Description: [value['Description']],
          Thumbnail: [value['Thumbnail'] ? those.absolutizeUrl.transform(value['Thumbnail']) : ''],
          Value: [value['Value'], Validators.required],
          Availability: [value['Availability'] ? value['Availability'] : 'available'],
          Sort: [value['Sort'] ? value['Sort'] : 0],
          Color: [value['Color'] ? value['Color'] : ''],
        });
        those.modal = those.modalService.open(content, {
          ...options,
          ...{ ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' },
        });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load value', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete value?';
    modal.componentInstance.body = `Are you sure you want to delete value? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteValue(id)
          .then(resp => {
            those.success('Delete value', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete value', err);
          });
      },
      reason => {}
    );
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Title', this.form.get('Title').value);
    data.append('Description', this.form.get('Description').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Value', this.form.get('Value').value);
    data.append('Availability', this.form.get('Availability').value);
    data.append('Sort', this.form.get('Sort').value);
    data.append('Color', this.form.get('Color').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postValue(those.optionId, data)
        .then(resp => {
          those.success('Create value', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create value', err);
        });
    } else {
      // update
      this.apiService
        .putValue(those.form.get('ID').value, data)
        .then(resp => {
          those.success('Update value', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update value', err);
        });
    }
  }
}
