import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesComponent } from './categories.component';
import { CategoryComponent } from './category/category.component';

const routes: Routes = [
  {
    path: '',
    component: CategoriesComponent,
    data: {
      title: 'Product Categories',
      breadcrumb: '',
      subtitle: 'Product Categories',
      description:
        'Categories allow you to group products by similar attributes. So you can decide which categories you want to display in your store and which products should be included in which category. The categories in your store are displayed below.',
    },
  },
  {
    path: ':id',
    component: CategoryComponent,
    data: {
      title: 'Category',
      breadcrumb: 'Category',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoriesRoutingModule {}
