import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnabledRenderComponent } from '../../shared/render/enabled-render.component';
import { LocalStorageService } from 'angular-2-local-storage';
import { SelectedRenderComponent } from '../../shared/render/selected-render.component';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { ListRenderComponent } from '../../shared/render/list-render.component';
import { TitleRenderComponent } from '../../shared/render/title-render.component';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class VendorsComponent implements OnInit {
  @ViewChild('content') content;
  patch = 'Vendor';
  source: LocalDataSource;
  term;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Vendors',
      Columns: [
        {
          Name: 'Name',
          Label: 'Vendor Name',
          Selected: true,
        },
        {
          Name: 'Title',
          Label: 'Display Name',
          Selected: true,
        },
        {
          Name: 'Enabled',
          Label: 'Status',
          Selected: true,
          Width: 65,
        },
        {
          Name: 'Times',
          Label: 'Times',
          Selected: true,
          Width: 450,
        },
      ],
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [
    {
      Name: 'set-active',
      Label: 'Set active',
    },
    {
      Name: 'set-inactive',
      Label: 'Set inactive',
    },
  ];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    Name: {
      title: 'Option Name',
      type: 'custom',
      renderComponent: TitleRenderComponent,
      filter: false,
      sort: true,
    },
    Title: {
      title: 'Display Name',
      filter: false,
      sort: true,
    },
    Enabled: {
      title: 'Status',
      type: 'custom',
      renderComponent: EnabledRenderComponent,
      filter: false,
      sort: false,
      width: 65,
    },
    Times: {
      title: 'Times',
      type: 'custom',
      renderComponent: ListRenderComponent,
      filter: false,
      sort: true,
      width: 450,
    },
  };

  filters = [
    {
      Name: 'Enabled',
      Label: 'Status',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'active',
          Label: 'Active',
          Value: 'true',
        },
        {
          Name: 'inactive',
          Label: 'Inactive',
          Value: 'false',
        },
      ],
    },
  ];

  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Name',
      Label: 'Vendor Name',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
    {
      Name: 'Title',
      Label: 'Display Name',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
  ];

  selected;
  selectedAll = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {}
}
