import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subject, Subscription } from 'rxjs';
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { MediaNameRenderComponent } from './media-name-render.component';
import { FavoriteRenderComponent } from '../../shared/render/favorite-render.component';
import { MediaActionsRenderComponent } from './media-actions-render.component';
import { MediasDataSource } from './medias.data-source';
import { MediaSizeRenderComponent } from './media-size-render.component';
import { MediaDateRenderComponent } from './media-date-render.component';
import { SelectedRenderComponent } from '../../shared/render/selected-render.component';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class MediasComponent implements OnInit {
  @ViewChild('content') content;
  @ViewChild('editor') editor;
  tree;
  settings;
  source: MediasDataSource;
  term;
  filtered = true;
  form;
  modal;
  closeResult: string;
  visible = false;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Medias',
      Columns: [
        {
          Name: 'Name',
          Label: 'Name',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'ContentType',
          Label: 'Type',
          Selected: true,
        },
        {
          Name: 'Size',
          Label: 'Size',
          Selected: true,
        },
        {
          Name: 'Used',
          Label: 'Used',
          Selected: true,
        },
        {
          Name: 'Updated',
          Label: 'Updated',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '2',
      Name: 'Favorite',
      Label: 'Favorite',
      Columns: [
        {
          Name: 'Name',
          Label: 'Name',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'ContentType',
          Label: 'Type',
          Selected: true,
        },
        {
          Name: 'Size',
          Label: 'Size',
          Selected: true,
        },
        {
          Name: 'Used',
          Label: 'Used',
          Selected: true,
        },
        {
          Name: 'Updated',
          Label: 'Updated',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Favorite',
            Label: 'Featured',
            Type: 'select',
            Value: {
              Label: 'Yes',
              Value: 'true',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'Images',
      Label: 'Images',
      Columns: [
        {
          Name: 'Name',
          Label: 'Name',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'ContentType',
          Label: 'Type',
          Selected: true,
        },
        {
          Name: 'Size',
          Label: 'Size',
          Selected: true,
        },
        {
          Name: 'Used',
          Label: 'Used',
          Selected: true,
        },
        {
          Name: 'Updated',
          Label: 'Updated',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'ContentType',
            Label: 'ContentType',
            Type: 'multiselect',
            Values: [{ id: '^image/', name: 'Any Images' }],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '4',
      Name: 'Files',
      Label: 'Files',
      Columns: [
        {
          Name: 'Name',
          Label: 'Name',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'ContentType',
          Label: 'Type',
          Selected: true,
        },
        {
          Name: 'Size',
          Label: 'Size',
          Selected: true,
        },
        {
          Name: 'Used',
          Label: 'Used',
          Selected: true,
        },
        {
          Name: 'Updated',
          Label: 'Updated',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'ContentType',
            Label: 'ContentType',
            Type: 'multiselect',
            Values: [{ id: '!^image/', name: 'Any Files' }],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '5',
      Name: 'Used',
      Label: 'Used',
      Columns: [
        {
          Name: 'Name',
          Label: 'Name',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'ContentType',
          Label: 'Type',
          Selected: true,
        },
        {
          Name: 'Size',
          Label: 'Size',
          Selected: true,
        },
        {
          Name: 'Used',
          Label: 'Used',
          Selected: true,
        },
        {
          Name: 'Updated',
          Label: 'Updated',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Used',
            Label: 'Used',
            Value: '>0',
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    Name: {
      title: 'Name',
      type: 'custom',
      renderComponent: MediaNameRenderComponent,
      filter: false,
      sort: true,
    },
    Favorite: {
      title: '',
      type: 'custom',
      renderComponent: FavoriteRenderComponent,
      filter: false,
      sort: false,
    },
    ContentType: {
      title: 'Type',
      filter: false,
      sort: true,
    },
    Size: {
      title: 'Size',
      type: 'custom',
      renderComponent: MediaSizeRenderComponent,
      filter: false,
      sort: true,
    },
    Used: {
      title: 'Used',
      filter: false,
      sort: true,
    },
    Updated: {
      title: 'Updated',
      type: 'custom',
      renderComponent: MediaDateRenderComponent,
      filter: false,
      sort: true,
    },
  };


  filters = [
    {
      Name: 'ContentType',
      Label: 'ContentType',
      Type: 'multiselect',
      Values: [],
    },
    {
      Name: 'Favorite',
      Label: 'Featured',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'favorite',
          Label: 'Yes',
          Value: 'true',
        },
        {
          Name: 'not-favorite',
          Label: 'No',
          Value: 'false',
        },
      ],
    },
  ];

  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Size',
      Label: 'Size',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
    {
      Name: 'Updated',
      Label: 'Updated',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Used',
      Label: 'Used',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
  ];

  /*sorts = [
    {
      Name: 'created:asc',
      Label: 'Created asc',
      Field: 'created_at',
      Direction: 'asc',
    },
    {
      Name: 'created:desc',
      Label: 'Created desc',
      Field: 'created_at',
      Direction: 'desc',
    },
    {
      Name: 'title:asc',
      Label: 'Title asc',
      Field: 'title',
      Direction: 'asc',
    },
    {
      Name: 'title:desc',
      Label: 'Title desc',
      Field: 'title',
      Direction: 'desc',
    },
    {
      Name: 'type:asc',
      Label: 'Type asc',
      Field: 'type',
      Direction: 'asc',
    },
    {
      Name: 'type:desc',
      Label: 'Type desc',
      Field: 'type',
      Direction: 'desc',
    },
  ];
  sort;

  filters = {
    Favorite: [
      {
        Name: 'all',
        Label: 'All',
        Value: '',
      },
      {
        Name: 'favorite',
        Label: 'Yes',
        Field: 'Favorite',
        Value: 'true',
      },
      {
        Name: 'not-favorite',
        Label: 'No',
        Field: 'Favorite',
        Value: 'false',
      },
    ],
    ContentType: [],
  };
  preset;
  termChanged: Subject<string> = new Subject<string>();
  termSubscription: Subscription;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Media',
      Filter: {
        On: true,
        Fields: {
          Used: {
            Label: 'Used',
            Value: '',
          },
        },
      },
      Sort: 'created_at:desc',
    },
    {
      Id: '2',
      Name: 'favorite',
      Label: 'Favorite',
      Filter: {
        On: true,
        Fields: {
          Favorite: {
            Label: 'Yes',
            Value: 'true',
          },
          Used: {
            Label: 'Used',
            Value: '',
          },
        },
      },
      Sort: 'created_at:desc',
    },
    {
      Id: '3',
      Name: 'images',
      Label: 'Images',
      Filter: {
        On: true,
        Fields: {
          Favorite: {
            Label: 'All',
            Value: '',
          },
          ContentType: {
            Label: 'Images',
            Value: ['^image/'],
          },
          Used: {
            Label: 'Used',
            Value: '',
          },
        },
      },
      Sort: 'created_at:desc',
    },
    {
      Id: '4',
      Name: 'files',
      Label: 'Files',
      Filter: {
        On: true,
        Fields: {
          Favorite: {
            Label: 'All',
            Value: '',
          },
          ContentType: {
            Label: 'Files',
            Value: ['!^image/'],
          },
          Used: {
            Label: 'Used',
            Value: '',
          },
        },
      },
      Sort: 'created_at:desc',
    },
    {
      Id: '5',
      Name: 'used',
      Label: 'Used',
      Filter: {
        On: true,
        Fields: {
          Favorite: {
            Label: 'All',
            Value: '',
          },
          Used: {
            Label: 'Used',
            Value: '>0',
          },
        },
      },
      Sort: 'created_at:desc',
    },
  ];
  selected;
  timer;
  perPage = 10;
  widths;*/

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    /*const those = this;
    this.perPage = JSON.parse(this.localStorageService.get('medias_per_page')) || 10;
    this.termSubscription = this.termChanged.pipe(debounceTime(1000)).subscribe(() => {
      those.source.refresh();
    });
    if (this.sorts.length > 1) {
      const sort = this.sorts[1];
      this.sort = sort.Field + ':' + sort.Direction;
    }
    this.settings = {
      mode: 'external',
      draggable: false,
      actions: {
        add: false,
        edit: false,
        delete: false,
        columnTitle: '',
        position: 'right',
      },
      columns: {
        Selected: {
          title: '',
          type: 'custom',
          renderComponent: SelectedRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        Name: {
          title: this.translate.instant('Name'),
          type: 'custom',
          renderComponent: MediaNameRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: true,
        },
        Favorite: {
          title: '',
          type: 'custom',
          renderComponent: FavoriteRenderComponent,
          onComponentInitFunction: instance => {
            instance.method = 'patchMedia';
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        ContentType: {
          title: those.translate.instant('Type'),
          filter: false,
          sort: true,
        },
        Size: {
          title: this.translate.instant('Size'),
          type: 'custom',
          renderComponent: MediaSizeRenderComponent,
          filter: false,
          sort: true,
        },
        Used: {
          title: those.translate.instant('Used'),
          filter: false,
          sort: true,
        },
        Updated: {
          title: this.translate.instant('Updated'),
          type: 'custom',
          renderComponent: MediaDateRenderComponent,
          filter: false,
          sort: true,
        },
        Actions: {
          title: '',
          type: 'custom',
          renderComponent: MediaActionsRenderComponent,
          filter: false,
          sort: false,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    // set columns widths
    this.widths = JSON.parse(this.localStorageService.get('medias_columns_widths'));
    if (this.widths && Object.keys(this.widths).length > 0) {
      for (const [key, value] of Object.entries(this.widths)) {
        if (this.settings.columns[key] && value) {
          this.settings.columns[key]['width'] = value + 'px';
        }
      }
    }
    this.source = new MediasDataSource(this.http, this.apiService, this.absolutizeUrl, this.localStorageService);
    this.source.callback = () => {
      const table = document.querySelector('table');
      const cols = table.querySelectorAll('thead tr.ng2-smart-titles th:not(.Selected):not(.Favorite)');
      [].forEach.call(cols, col => {
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');
        resizer.style.height = `${table['offsetHeight']}px`;
        col.appendChild(resizer);
        window['createResizableColumn'](col, resizer);
      });
    };
    this.source.parent = those;
    setTimeout(() => {
      const presets = JSON.parse(those.localStorageService.get('medias_presets')) || false;
      if (presets) {
        for (let i = 0; i < presets.length; i++) {
          if (!this.presets.find(item => item.Id === presets[i].Id)) {
            this.presets.push(presets[i]);
          }
        }
      }
      this.setPreset(this.presets[0]);
    }, 100);*/

    /*this.columns.Favorite.onComponentInitFunction = (instance) => {
      instance.method = 'patchMedia';
    };*/
  }
}
