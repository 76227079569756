import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { DragulaService } from 'ng2-dragula';
import { LocalStorageService } from 'angular-2-local-storage';
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RenameComponent } from '../../modals/rename/rename.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-smart-filter',
  templateUrl: './smart-filter.component.html',
  styleUrls: ['./smart-filter.component.scss'],
})
export class SmartFilterComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  clickout(event) {
    const filter = event.target.closest('.filter-block');
    if (!filter) {
      this.filterExpanded = false;
    }
    const sort = event.target.closest('.sort-block');
    if (!sort) {
      this.sortExpanded = false;
    }
  }
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      const widths = {};
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          const classes = item.classList
            .toString()
            .split(/\s+/)
            .filter(cl => ['ng2-smart-th', 'ng-star-inserted'].indexOf(cl) < 0);
          if (classes && classes.length > 0) {
            const style = item.getAttribute('style');
            const res = style.match(/width: (\d+)px;?/);
            if (res) {
              widths[classes[0]] = res[1];
            }
          }
        }
      });
      if (widths && Object.keys(widths).length > 0) {
        this.widths = widths;
        this.localStorageService.set(this.type + '_columns_widths', JSON.stringify(widths));
      }
    }
  }
  @Input('type') type = 'products';

  @Input('columns') columns;
  @Output() columnsChange = new EventEmitter<boolean>();

  @Input('isFiltering') isFiltering = false;

  @Input('presets') presets;

  @Input('preset') preset;
  @Output() presetChange = new EventEmitter<boolean>();

  /*_filters;
  @Input()
  set filters(filters) {
    console.log('set filters', filters);
    this._filters = filters;
  }
  get filters() {
    return this._filters;
  }*/
  @Input('filters') filters;
  filter;
  filterExpanded = false;

  @Input('sorts') sorts;
  sort;
  sortExpanded = false;

  @Input('selected') selected;
  @Output() selectedChange = new EventEmitter<boolean>();

  @Input('visible') visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input('filtered') filtered = true;
  @Output() filteredChange = new EventEmitter<boolean>();

  @Output('onSearch') public onSearch: EventEmitter<any> = new EventEmitter();

  @Input('source') source: DataSource;

  @Input('term') term;
  @Output() termChange = new EventEmitter<boolean>();

  @Input('parent') parent;
  @Input('refresh') refresh;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
  };

  multiSelectTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    defaultTitle: 'Select value',
    allSelected: 'All selected',
  };

  //
  field;
  value;
  //

  widths;
  termChanged: Subject<string> = new Subject<string>();
  termSubscription: Subscription;

  expanded = false;

  cid;
  row;
  timer;
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit', 'presets', this.presets, 'preset', this.preset, 'filters', this.filters);
    const those = this;
    this.termSubscription = this.termChanged.pipe(debounceTime(1000)).subscribe(() => {
      console.log('refresh');
      those.source.refresh();
    });
    if (this.presets.length > 0 && !this.preset) {
      this.setPreset(this.presets[0]);
    }
    /*setInterval(() => {
      those.filtered = false;
      those.filteredChange.emit(those.filtered);
    }, 3000);*/
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.termSubscription.unsubscribe();
  }

  objectKeys = Object.keys;

  findIndex(array, name) {
    return array.findIndex(item => item.Name === name);
  }

  availableFilters(array) {
    return array.filter(item => this.preset.Filter.Fields.findIndex(item2 => item2.Name === item.Name) < 0);
  }

  /* Columns */

  setColumns(event) {
    this.localStorageService.set(this.type + '_columns', JSON.stringify(this.columns));
    if (typeof this.refresh === 'function') {
      this.refresh.call(this.parent, true);
    }
    event.stopPropagation();
  }

  resetColumns(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          item.removeAttribute('style');
        }
      });
    }
    this.widths = undefined;
    this.localStorageService.remove(this.type + '_columns_widths');
    event.stopPropagation();
  }

  clearColumns(event) {
    this.localStorageService.remove(this.type + '_columns');
    if (typeof this.refresh === 'function') {
      this.refresh.call(this.parent, true);
    }
    event.stopPropagation();
  }

  /* Presets */
  applyPreset() {
    const those = this;
    const filters = [];
    if (this.preset.Filter && this.preset.Filter.On) {
      for (let i = 0; i < this.preset.Filter.Fields.length; i++) {
        const name = this.preset.Filter.Fields[i].Name;
        if (['Category', 'Vendor'].indexOf(name) >= 0) {
          filters.push({ field: name, search: this.preset.Filter.Fields[i]['Value'].join(';') });
        } else {
          filters.push({ field: name, search: this.preset.Filter.Fields[i]['Value']['Value'] });
        }
      }
    }
    const sort = [];
    if (this.preset.Sort) {
      sort.push({
        field: this.preset.Sort.Name,
        direction: this.preset.Sort.Direction ? this.preset.Sort.Direction : 'asc',
      });
    } else {
      sort.push({ field: 'Created', direction: 'desc' });
    }
    console.log('sort', sort);
    this.filtered = false;
    this.source['reset']();
    setTimeout(() => {
      those.source.setFilter(filters, true, false);
      those.source.setSort(sort, true);
      those.filtered = true;
    }, 250);
  }
  setPreset(p) {
    this.preset = p;
    const sort = this.sorts.find(item => item.Name === p.Sort?.Name);
    if (sort) {
      this.sort = sort;
      this.sort.Direction = p.Sort.Direction ? p.Sort.Direction : 'asc';
    }
    this.applyPreset();
  }

  deletePreset() {
    this.presets = this.presets.filter(item => item.Id !== this.preset.Id);
    this.setPreset(this.presets[0]);
    this.localStorageService.set(this.type + '_presets', JSON.stringify(this.presets));
  }

  renamePreset() {
    const those = this;
    const modal = this.modalService.open(RenameComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    const name = this.preset.Label;
    modal.componentInstance.title = `Rename ${name}?`;
    modal.componentInstance.description = `Saved filters will be saved as tabs at the top of this page.`;
    modal.componentInstance.message = `Filter name`;
    modal.componentInstance.placeholder = `enter your filter name`;
    modal.componentInstance.value = name;
    modal.componentInstance.confirm = 'Save';
    modal.result.then(
      result => {
        console.log('result', result);
        those.preset.Label = result;
      },
      reason => {}
    );
  }

  savePresets() {
    if (this.preset.Type !== 'custom') {
      const preset = {
        ...JSON.parse(JSON.stringify(this.preset)),
        Id: new Date().getTime().toString(36),
        Type: 'custom',
        Label: this.translate.instant('Custom Filter'),
      };
      while (true) {
        const res = preset.Label.match(/(\d+)$/);
        if (res) {
          preset.Label = preset.Label.replace(/(\d+)$/, Number(res[1]) + 1);
        } else {
          preset.Label += ' 2';
        }
        if (this.presets.findIndex(item => item.Label === preset.Label) < 0) {
          break;
        }
      }
      this.presets.splice(this.presets.length, 0, preset);
      this.setPreset(preset);
    }
    this.localStorageService.set(this.type + '_presets', JSON.stringify(this.presets));
  }

  /* Filters */
  addFilter() {}

  setFilter(index, value?) {
    if (this.preset && this.preset.Filter && this.preset.Filter.Fields) {
      if (['Category', 'Stock', 'Vendor'].indexOf(this.preset.Filter.Fields[index].Name) >= 0) {
        if (this.preset.Filter.Fields[index]) {
          this.preset.Filter.Fields[index].Value = value;
        }
      } else {
        this.preset.Filter.Fields[index].Value = value;
      }
    }
    this.applyPreset();
  }

  deleteFilter() {}

  onFilterAdd(filter) {
    console.log('onFilterAdd', filter);
    this.preset.Filter.Fields.push({ Name: filter.Name, Label: filter.Label, Value: filter.Value });
    this.filter = undefined;
    this.filterExpanded = false;
    this.applyPreset();
  }

  onFilterChange(filter) {
    console.log('onFilterChange', filter);
    this.filter = { ...filter };
  }

  onFilterClear() {
    this.filter = undefined;
    this.filterExpanded = false;
  }

  onFilterDelete(index) {
    this.preset.Filter.Fields.splice(index, 1);
    this.applyPreset();
  }

  onFiltersDelete() {
    this.preset.Filter.Fields = [];
    this.applyPreset();
  }

  onSortToggle() {
    if (this.sort) {
      this.sort = undefined;
      this.sortExpanded = false;
    } else {
      this.sort = {};
      this.sortExpanded = true;
    }
  }

  onSortChange(sort, direction?) {
    console.log('onSortChange', sort, direction);
    const those = this;
    setTimeout(() => {
      if (direction) {
        if (direction === 'asc') {
          those.sort.Direction = those.sort.Direction !== 'asc' ? 'asc' : 'desc';
        } else {
          those.sort.Direction = those.sort.Direction !== 'desc' ? 'desc' : 'asc';
        }
      } else {
        those.sort.Direction = those.sort.Order?.length > 0 ? those.sort.Order[0].Type : 'asc';
      }
      those.preset.Sort = those.sort;
      those.applyPreset();
    }, 100);
  }

  bulk(action?) {
    const those = this;
    switch (action) {
      case 'set-active':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patchProduct(those.source['data'][i]['ID'], 'setEnabled', { Enabled: true })
                  .then(resp => {
                    those.source['data'][i]['Enabled'] = true;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-inactive':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patchProduct(those.source['data'][i]['ID'], 'setEnabled', { Enabled: false })
                  .then(resp => {
                    those.source['data'][i]['Enabled'] = false;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'put-to-position':

      case 'delete':
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        const name = those.source['data'].filter(item => item['Selected']).length <= 1 ? 'product' : 'products';
        modal.componentInstance.title = `Delete ${name}?`;
        modal.componentInstance.body = `Are you sure you want to delete selected ${name}? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            (async function loop() {
              for (let i = 0; i < those.source['data'].length; i++) {
                if (those.source['data'][i]['Selected']) {
                  await new Promise(resolve =>
                    those.apiService.deleteProduct(those.source['data'][i]['ID']).then(resp => {
                      those.source['data'][i]['Enabled'] = false;
                      those.source['data'][i]['Deleted'] = true;
                      resolve();
                    })
                  );
                }
              }
              await new Promise(resolve => {
                those.source['data'] = those.source['data'].filter(item => !item['Deleted']);
                those.selected = false;
                those.source['local'] = true;
                those.source.refresh();
                resolve();
              });
            })();
          },
          reason => {}
        );
        break;
      default:
        //this.selected = this.source['data'].filter(item => item['Selected']).length > 0;
        this.selected = this.source['data'].filter(item => item['Selected']);
    }
  }

  onRowDragStart(event) {
    console.log('rowDragStart', event);
    this.row = event;
  }

  onRowDrop(event) {
    console.log('onRowDrop', event);
    const those = this;
    const source = this.row['data']['ID'];
    const destination = event['data']['ID'];
    const ids = [];
    const sorts = [];
    let from, to;
    for (let i = 0; i < this.source['data'].length; i++) {
      if (this.source['data'][i]['ID'] === Number(source)) {
        from = i;
      }
      if (this.source['data'][i]['ID'] === Number(destination)) {
        to = i;
      }
      ids.push(this.source['data'][i]['ID']);
      sorts.push(this.source['data'][i]['Sort']);
      console.log('CategoryId', this.cid, 'ProductID', ids[i], 'Sort', sorts[i]);
    }
    const idsOrig = [...ids];
    const sortsOrig = [...sorts];
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    const id = ids.splice(from, 1);
    ids.splice(to, 0, id[0]);
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    const update = [];
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] !== idsOrig[i] || sorts[i] !== sortsOrig[i]) {
        console.log('CategoryId', this.cid, 'ProductID', ids[i], 'Sort', sorts[i]);
        update.push(
          those.apiService
            .patchProduct(ids[i], 'setSort', { CategoryId: Number(this.cid), Sort: Number(sorts[i]) })
            .catch(err => {
              console.log(err);
            })
        );
      }
    }
    const row = this.source['data'].splice(from, 1);
    this.source['data'].splice(to, 0, row[0]);
    for (let i = 0; i < this.source['data'].length; i++) {
      this.source['data'][i]['Sort'] = sorts[i];
    }
    this.source['local'] = true;
    this.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all(update).catch(err => {
        console.log(err);
      });
    }, 1000);
  }

  toggle(event) {
    for (let i = 0; i < this.source['data'].length; i++) {
      this.source['data'][i]['Selected'] = event;
    }
    this.source['local'] = true;
    this.source.refresh();
    this.bulk();
  }
}
