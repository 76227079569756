import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      <span *ngFor="let tag of tags" class="tag">{{tag}}</span>
  `,
})
export class OrderTagsRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  tags = [];

  ngOnInit(): void {
    if (this.value) {
      if (typeof this.value === 'string') {
        this.tags = this.value.split(/;\s*/);
      }else{
        this.tags = Array.from(this.value).map(item => item['Title']);
      }
    }
  }
}
