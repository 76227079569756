import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImagesComponent } from './images/images.component';
import { FilesComponent } from './files/files.component';
import { MediasComponent } from './medias.component';

const routes: Routes = [
  {
    path: '',
    component: MediasComponent,
    data: {
      title: 'Media',
      breadcrumb: '',
      subtitle: 'Media Library',
      description:
        'Using your media library, you can edit, view, and delete media that you have previously uploaded. Media that has already been uploaded can be reused in other places without re-uploading. Multiple media items can be selected for deletion. You can also search and filter to find the media you want.',
    },
  },
  /*{
    path: 'files',
    component: FilesComponent,
    data: {
      title: 'Files',
      breadcrumb: 'Files'
    }
  },
  {
    path: 'images',
    component: ImagesComponent,
    data: {
      title: 'Images',
      breadcrumb: 'Images'
    }
  }*/
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MediasRoutingModule {}
