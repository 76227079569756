import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from '../../api.service';

@Injectable()
export class RemoteDataSource extends LocalDataSource {
  type;
  data: any[];
  lastRequestCount = 0;
  lastRequestFiltered = 0;
  lastRequestShown = 0;
  lastRequestTotal = 0;

  parent: any;
  local = false;
  callback;
  filtering;

  constructor(protected http: HttpClient, private apiService: ApiService) {
    super();
  }

  count(): number {
    return this.lastRequestCount;
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  shown(): number {
    return this.lastRequestShown;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    this.pagingConf['page'] = page || 1;
    this.pagingConf['perPage'] = perPage || 10;
    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    const those = this;
    // Fix duplicates requests
    if (
      this.parent &&
      this.filtering &&
      (JSON.stringify({
        filterConf: those.parent.filterConf,
        /*sortConf: those.parent.sortConf,*/
      }) !==
        JSON.stringify({
          filterConf: those.filterConf,
          /*sortConf: those.sortConf,*/
        }) ||
        those.parent.perPage !== this.pagingConf.perPage)
    ) {
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    // Local sort refresh
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    //
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const request = {};
    if (this.parent.term) {
      request['Search'] = this.parent.term;
    }
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    // Inject some params
    const url = new URL(this.apiService.getUrl() + '/' + this.type + '/list');
    try {
      const curr = new URL(window.location.toString());
      curr.searchParams.forEach((value, key) => {
        if (['cid'].indexOf(key) >= 0) {
          url.searchParams.set(key, value);
        }
      });
    } catch (err) {}
    //
    return this.http
      .post(url.toString(), request, { headers: headersObject, withCredentials: true })
      .pipe(
        map(response => {
          those.lastRequestShown = +response['Data']?.length;
          those.lastRequestFiltered = +response['Filtered'];
          those.lastRequestCount = +response['Filtered'];
          those.lastRequestTotal = +response['Total'];
          if (response['Columns']) {
            let index = those.parent.filters.findIndex(item => item.Name === 'Category');
            if (
              index >= 0 &&
              response['Columns']['Categories'] &&
              (!those.parent.filters[index].Values ||
                those.parent.filters[index].Values.length < response['Columns']['Categories'].length)
            ) {
              those.parent.filters[index].Values = [];
              response['Columns']['Categories'].forEach(item => {
                those.parent.filters[index].Values.push({ id: item, name: item });
              });
            }
            //
            index = those.parent.filters.findIndex(item => item.Name === 'ContentType');
            if (
              index >= 0 &&
              response['Columns']['ContentTypes'] &&
              (!those.parent.filters[index].Values ||
                those.parent.filters[index].Values.length < response['Columns']['ContentTypes'].length)
            ) {
              those.parent.filters[index].Values = [
                { id: '^image/', name: 'Any Images' },
                { id: '!^image/', name: 'Any Files' },
              ];
              response['Columns']['ContentTypes'].forEach(item => {
                those.parent.filters[index].Values.push({ id: item, name: item });
              });
            }
            //
            index = those.parent.filters.findIndex(item => item.Name === 'MetricsValues');
            if (
              index >= 0 &&
              response['Columns']['MetricsValues'] &&
              (!those.parent.filters[index].Values ||
                those.parent.filters[index].Values.length < response['Columns']['MetricsValues'].length)
            ) {
              those.parent.filters[index].Values = [];
              response['Columns']['MetricsValues'].forEach(item => {
                those.parent.filters[index].Values.push({ id: item, name: item });
              });
            }
            //
            those.parent.filters.forEach((filter, index) => {
              if (
                filter.Kind === 'Tag' &&
                response['Columns']['Tags'] &&
                (!those.parent.filters[index].Values ||
                  those.parent.filters[index].Values.length < response['Columns']['Tags'].length)
              ) {
                those.parent.filters[index].Values = [];
                response['Columns']['Tags'].forEach(item => {
                  those.parent.filters[index].Values.push({ id: item, name: item });
                });
              }
            });
            //
            index = those.parent.filters.findIndex(item => item.Name === 'ValuesValues');
            if (
              index >= 0 &&
              response['Columns']['ValuesValues'] &&
              (!those.parent.filters[index].Values ||
                those.parent.filters[index].Values.length < response['Columns']['ValuesValues'].length)
            ) {
              those.parent.filters[index].Values = [];
              response['Columns']['ValuesValues'].forEach(item => {
                those.parent.filters[index].Values.push({ id: item, name: item });
              });
            }
            //
            index = those.parent.filters.findIndex(item => item.Name === 'Vendor');
            if (
              index >= 0 &&
              response['Columns']['Vendors'] &&
              (!those.parent.filters[index].Values ||
                those.parent.filters[index].Values.length < response['Columns']['Vendors'].length)
            ) {
              those.parent.filters[index].Values = [];
              response['Columns']['Vendors'].forEach(item => {
                those.parent.filters[index].Values.push({ id: item, name: item });
              });
            }
          }
          those.parent.visible = true;
          those.data = response['Data'] || [];
          if (those.callback && typeof those.callback === 'function') {
            setTimeout(() => {
              those.callback.call(those);
            }, 1000);
          }
          return this.data;
        })
      )
      .toPromise();
  }
}
