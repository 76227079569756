<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/sales/coupons']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>{{'Coupon' | translate}}: {{coupon && coupon['Title'] ? coupon['Title'] : ('New' | translate)}}</h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card">
          <div class="card-body">
            <div class="form">
              <div class="form-group">
                <label for="title" class="mb-1" translate>Title</label>
                <input class="form-control" id="title" type="text" formControlName="Title">
              </div>
              <div class="form-group">
                <label for="code" class="mb-1" translate>Code</label>
                <input class="form-control" id="code" type="text" formControlName="Code">
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="type" class="mb-1" style="padding-right: 5px" translate>Type</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'to Order, Item or Shipment' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <select class="form-control" id="type" formControlName="Type">
                      <option value="item">{{'Product' | translate}}</option>
                      <option value="shipment">{{'Shipment' | translate}}</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="form && form.get('Type').value == 'item'" class="col">
                  <div class="form-group">
                    <label for="type" class="mb-1" style="padding-right: 5px" translate>Apply to</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'apply to all in nothing selected' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <div style="min-height: 44px;display: flex;align-items: center;">
                      <div style="padding-right: 15px">
                        <label style="display: flex; align-items: center" for="categories">
                          <input class="radio_animated" style="margin: 0 0.5rem 0 0" id="categories" type="radio"
                                 value="categories" formControlName="ApplyTo">
                          {{'Categories' | translate}}
                        </label>
                      </div>
                      <div>
                        <label style="display: flex; align-items: center" for="products">
                          <input class="radio_animated" style="margin: 0 0.5rem 0 0" id="products" type="radio"
                                 value="products" formControlName="ApplyTo">
                          {{'Products' | translate}}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mb-0 custom-radio-ml radio-animated" [ngClass]="{hidden: form && form.get('Type').value != 'item'}">
                <div>
                  <div class="form-group" [ngClass]="{hidden: !(form && form.get('ApplyTo') && form.get('ApplyTo').value == 'categories')}">
                    <label for="type" class="mb-1" style="padding-right: 5px" translate>Categories</label>
                    <div style="padding-bottom: 10px">
                      <input class="form-control" type="text" placeholder="{{'csv list' | translate}}" (change)="onChangeCategories1(categoriesTree, form.get('Categories').value, $event)" formControlName="Categories">
                    </div>
                    <div class="product-categories">
                      <tree-root [nodes]="categories" [options]="options" (event)="onChangeCategories2(categoriesTree,'Categories', form.get('Categories').value, $event)" #categoriesTree>
                        <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                          <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                            <tree-node-checkbox [node]="node"></tree-node-checkbox>
                            <tree-node-expander [node]="node"></tree-node-expander>
                            <div class="node-content-wrapper"
                                 [class.node-content-wrapper-active]="node.isActive"
                                 [class.node-content-wrapper-focused]="node.isFocused"
                                 (click)="node.isExpanded ? node.collapse() : node.expand()"
                                 (dblclick)="node.mouseAction('dblClick', $event)"
                                 (contextmenu)="node.mouseAction('contextMenu', $event)"
                                 (treeDrop)="node.onDrop($event)"
                                 [treeAllowDrop]="node.allowDrop"
                                 [treeDrag]="node"
                                 [treeDragEnabled]="node.allowDrag()">
                              <tree-node-content [node]="node" [index]="index"></tree-node-content>
                              <span style="padding-left: 5px">({{ node.data.id }})</span>
                            </div>
                          </div>
                        </ng-template>
                      </tree-root>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group" [ngClass]="{hidden: !(form && form.get('ApplyTo') && form.get('ApplyTo').value == 'products')}">
                    <label for="type" class="mb-1" style="padding-right: 5px" translate>Products</label>
                    <div style="padding-bottom: 10px">
                      <input class="form-control" type="text" placeholder="{{'csv list' | translate}}" (change)="onChangeCategories1(productsTree, form.get('Products').value, $event)" formControlName="Products">
                    </div>
                    <div class="product-categories">
                      <tree-root [nodes]="products" [options]="options" (event)="onChangeCategories2(productsTree,'Products', form.get('Products').value, $event)" #productsTree>
                        <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                          <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                            <tree-node-checkbox *ngIf="node.data['Type'] == 'product'" [node]="node"></tree-node-checkbox>
                            <tree-node-expander [node]="node"></tree-node-expander>
                            <div class="node-content-wrapper"
                                 [class.node-content-wrapper-active]="node.isActive"
                                 [class.node-content-wrapper-focused]="node.isFocused"
                                 (click)="node.isExpanded ? node.collapse() : node.expand()"
                                 (dblclick)="node.mouseAction('dblClick', $event)"
                                 (contextmenu)="node.mouseAction('contextMenu', $event)"
                                 (treeDrop)="node.onDrop($event)"
                                 [treeAllowDrop]="node.allowDrop"
                                 [treeDrag]="node"
                                 [treeDragEnabled]="node.allowDrag()">
                              <tree-node-content [node]="node" [index]="index"></tree-node-content>
                              <span *ngIf="node.data['Type'] == 'product'" style="padding-left: 5px">({{ node.data.id }})</span>
                            </div>
                          </div>
                        </ng-template>
                      </tree-root>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="start" class="mb-1" style="padding-right: 5px" translate>Start</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'not valid before' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <div><dp-date-picker id="start" mode="daytime" theme="dp-material" style="width: 100%" [config]="{format:'YYYY-MM-DD HH:mm:ss', firstDayOfWeek:'mo', showTwentyFourHours: true, showMultipleYearsNavigation: true}" formControlName="Start"></dp-date-picker></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="end" class="mb-1" style="padding-right: 5px" translate>End</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'not valid after' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <div><dp-date-picker id="end" mode="daytime" theme="dp-material" style="width: 100%" [config]="{format:'YYYY-MM-DD HH:mm:ss', firstDayOfWeek:'mo', showTwentyFourHours: true, showMultipleYearsNavigation: true}" formControlName="End"></dp-date-picker></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="price" class="mb-1" style="padding-right: 5px" translate>Minimum</label>
                    <span class="tooltip-question" placement="top" ngbTooltip="starting sum to use coupon">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <input class="form-control" id="price" type="text" (focusin)="trim(form.get('Minimum'))" (focusout)="format(form.get('Minimum'))"
                           formControlName="Minimum">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="amount" class="mb-1" style="padding-right: 5px" translate>Amount</label>
                    <span class="tooltip-question" placement="top" ngbTooltip="float value or %">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <input class="form-control" id="amount" type="text" formControlName="Amount">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="count" class="mb-1" style="padding-right: 5px" translate>Count</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'coupons count'|translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <input class="form-control" id="count" type="number" min="0" max="999999" step="1" formControlName="Count">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="limit" class="mb-1" style="padding-right: 5px" translate>Limit</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'per user limit to use coupon' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <input class="form-control" id="limit" type="number" min="0" max="999999" step="1" formControlName="Limit">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding: 20px;">
          <div class="col">
            <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="!form.valid || !form.touched" translate>
              <svg>
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
              </svg>
              <span style="vertical-align: text-bottom">Save</span>
            </button>
            <a class="btn btn-cancel" [routerLink]="['/sales/coupons']" translate>Cancel</a>
            <button *ngIf="coupon" class="btn btn-sm btn-default delete" type="button" style="padding: 8px;" (click)="onDelete()">
              <svg style="height: 18px; width: 18px">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div *ngIf="!form" style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
    </div>
  </div>
</div>
