<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Account</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-xl-4">
      <div *ngIf="info && info['User']" class="card">
        <div class="card-body" style="padding-bottom: 20px;">
          <div class="profile-details text-center">
              <h5 class="f-w-600 mb-0">{{info['User']['Login']}}</h5>
              <span>{{info['User']['Email']}}</span>
          </div>
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Name</label>
                  <input class="form-control" type="text" formControlName="Name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Lastname</label>
                  <input class="form-control" type="text" formControlName="Lastname">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Avatar</label>
                  <app-thumbnail-input formControlName="Thumbnail"></app-thumbnail-input>
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 20px;">
              <div class="col">
                <div class="form">
                  <div class="form-group">
                    <label for="old-password" class="mb-1" translate>Old Password</label>
                    <input class="form-control" id="old-password" type="password" formControlName="OldPassword">
                  </div>
                  <div class="form-group">
                    <label for="new-password" class="mb-1" translate>New Password</label>
                    <input class="form-control" id="new-password" type="password" (keyup)="form.controls['NewPassword2'].setValue('')" formControlName="NewPassword">
                    <div *ngIf="form.controls['NewPassword'].touched && form.controls['NewPassword'].errors" style="color: red">
                      <div *ngIf="form.controls['NewPassword'].errors['shortPassword']" translate>Too short password</div>
                      <div *ngIf="form.controls['NewPassword'].errors['longPassword']" translate>Too long password</div>
                      <div *ngIf="form.controls['NewPassword'].errors['noDigits']" translate>Should contains digits</div>
                      <div *ngIf="form.controls['NewPassword'].errors['noUpper']" translate>Should contains uppercase chars</div>
                      <div *ngIf="form.controls['NewPassword'].errors['noLower']" translate>Should contains lowercase chars</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="new-password2" class="mb-1" translate>Repeat</label>
                    <input class="form-control" id="new-password2" type="password" formControlName="NewPassword2">
                    <div *ngIf="form.controls['NewPassword2'].touched && form.controls['NewPassword2'].errors" style="color: red">
                      <div *ngIf="form.controls['NewPassword2'].errors['mismatch']" translate>Passwords mismatch</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="display: flex; gap: 10px">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
