import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [``],
  template: `
    <div [ngClass]="{negative: adjustment <= 0, positive: adjustment > 0}">{{value}}</div>
  `,
})
export class TransferAdjustmentRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;
  adjustment: number;

  ngOnInit() {
    this.adjustment = Number(this.value);
  }
}
