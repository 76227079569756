import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { HttpClient } from '@angular/common/http';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EnabledRenderComponent } from '../../../shared/render/enabled-render.component';
import { Subject, Subscription } from 'rxjs';
import { SubjectService } from '../../../shared/subject.service';
import { CustomerBlockComponent } from './customer-block.component';
import { SelectedRenderComponent } from '../../../shared/render/selected-render.component';
import { CustomerIdRenderComponent } from './customer-id-render.component';
import { DateRenderComponent } from '../../../shared/render/date-render.component';
import { CustomerNameRenderComponent } from './customer-name-render.component';
import { CustomerOrdersRenderComponent } from './customer-orders-render.component';
import { CustomerTotalRenderComponent } from './customer-total-render.component';
import { CustomerTagsRenderComponent } from './customer-tags-render.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class CustomersComponent implements OnInit, OnDestroy {
  @ViewChildren('block') blocks: QueryList<CustomerBlockComponent>;
  @ViewChild('content') content;
  form;
  modal;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Customers',
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '2',
      Name: 'new',
      Label: 'New',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Created',
            Label: 'Created',
            Type: 'date',
            Value: {
              Label: 'Last 7 days',
              Value: 'last 7 days',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'active',
      Label: 'Active',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Enabled',
            Label: 'Status',
            Type: 'select',
            Value: {
              Label: 'Yes',
              Value: 'true',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '4',
      Name: 'inactive',
      Label: 'Inactive',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Enabled',
            Label: 'Status',
            Type: 'select',
            Value: {
              Label: 'No',
              Value: 'false',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '5',
      Name: 'subscribed',
      Label: 'Subscribed',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'AllowReceiveEmails',
            Label: 'Subscribed',
            Type: 'select',
            Value: {
              Label: 'Yes',
              Value: 'true',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [
    {
      Name: 'set-active',
      Label: 'Set active',
    },
    {
      Name: 'set-inactive',
      Label: 'Set inactive',
    },
  ];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    ID: {
      title: 'Id',
      type: 'custom',
      renderComponent: CustomerIdRenderComponent,
      filter: false,
      sort: true,
    },
    Created: {
      title: 'Created',
      type: 'custom',
      renderComponent: DateRenderComponent,
      filter: false,
      sort: true,
    },
    Name: {
      title: 'Name',
      type: 'custom',
      renderComponent: CustomerNameRenderComponent,
      filter: false,
      sort: true,
    },
    Orders: {
      title: 'Orders',
      type: 'custom',
      renderComponent: CustomerOrdersRenderComponent,
      filter: false,
      sort: true,
    },
    Total: {
      title: 'Total',
      type: 'custom',
      renderComponent: CustomerTotalRenderComponent,
      filter: false,
      sort: true,
    },
    Tags: {
      title: 'Tags',
      type: 'custom',
      renderComponent: CustomerTagsRenderComponent,
      filter: false,
      sort: true,
    },
    Enabled: {
      title: 'Status',
      type: 'custom',
      renderComponent: EnabledRenderComponent,
      filter: false,
      sort: false,
    },
  };

  filters = [
    {
      Name: 'Created',
      Label: 'Created',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'OrderCreated',
      Label: 'Date of order',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'Orders',
      Label: 'Number of orders',
      Placeholder: '>0',
      Value: '',
    },
    {
      Name: 'Enabled',
      Label: 'Account Status',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'active',
          Label: 'Active',
          Value: 'true',
        },
        {
          Name: 'inactive',
          Label: 'Inactive',
          Value: 'false',
        },
      ],
    },
    {
      Name: 'AllowReceiveEmails',
      Label: 'Subscribed',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'subscribed',
          Label: 'Subscribed',
          Value: 'true',
        },
        {
          Name: 'not-subscribed',
          Label: 'Not subscribed',
          Value: 'false',
        },
      ],
    },
    {
      Name: 'Tag',
      Label: 'Tagged with',
      Kind: 'Tag',
      Type: 'multiselect',
      Values: [],
    },
    {
      Name: 'TagNot',
      Label: 'Not tagged with',
      Kind: 'Tag',
      Type: 'multiselect',
      Values: [],
    },
    {
      Name: 'Total',
      Label: 'Amount spent',
      Placeholder: '>0',
      Value: '',
    },
  ];

  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Name',
      Label: 'Customer Name',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
    {
      Name: 'Orders',
      Label: 'Orders',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
    {
      Name: 'Total',
      Label: 'Total',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
  ];

  customerIds = [];
  author = 'me';
  subscription: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    protected http: HttpClient,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit() {
    const those = this;
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info && info.User && info.User.Login) {
        those.author = info.User.Login;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
