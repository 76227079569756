import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../utils.pipe';
import { ApiService } from '../../api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from '../../subject.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-publish',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel2" translate>Publishing</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="panel-group" id="accordion">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a class="accordion-toggle" data-toggle="collapse" href="#collapseOne2">
                  <span translate>Prepare</span>
                  <div style="float: right">
                    <div *ngIf="loading1">
                      <img src="./assets/images/loading.svg" alt="Loading..." style="width: 30px" />
                    </div>
                    <div *ngIf="!loading1">
                      <div *ngIf="status1 == 'OK'; else else1">
                        <i class="fa fa-check" style="color: green;" aria-hidden="true"></i>
                      </div>
                      <ng-template #else1>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </ng-template>
                    </div>
                  </div>
                </a>
              </h4>
            </div>
            <div id="collapseOne2" class="panel-collapse collapse in">
              <div
                class="panel-body"
                style="border: 1px solid lightgray;border-radius: 5px;font-family: monospace;max-height: 250px;overflow: auto;white-space: pre;padding: 0px 0 5px 0;margin: 5px 0;">
                {{ output1 }}
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a class="accordion-toggle" data-toggle="collapse" href="#collapseTwo2">
                  <span translate>Render</span>
                  <div style="float: right">
                    <div *ngIf="loading2">
                      <img src="./assets/images/loading.svg" alt="Loading..." style="width: 30px" />
                    </div>
                    <div *ngIf="!loading2">
                      <div [ngSwitch]="status2">
                        <div *ngSwitchCase="'OK'">
                          <i class="fa fa-check" style="color: green;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchCase="undefined">
                          <i class="fa fa-clock-o" style="color: black;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchDefault>
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </h4>
            </div>
            <div id="collapseTwo2" class="panel-collapse collapse">
              <div
                class="panel-body"
                style="border: 1px solid lightgray;border-radius: 5px;font-family: monospace;max-height: 250px;overflow: auto;white-space: pre;padding: 0px 0 5px 0;margin: 5px 0;">
                {{ output2 }}
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a class="accordion-toggle" data-toggle="collapse" href="#collapseThree">
                  <span translate>Publish</span>
                  <div style="float: right">
                    <div *ngIf="loading3">
                      <img src="./assets/images/loading.svg" alt="Loading..." style="width: 30px" />
                    </div>
                    <div *ngIf="!loading3">
                      <div [ngSwitch]="status3">
                        <div *ngSwitchCase="'READY'">
                          <i class="fa fa-question-circle" style="color: black;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchCase="'OK'">
                          <i class="fa fa-check" style="color: green;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchCase="undefined">
                          <i class="fa fa-clock-o" style="color: black;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchDefault>
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </h4>
            </div>
            <div id="collapseThree" class="panel-collapse collapse">
              <div
                class="panel-body"
                style="border: 1px solid lightgray;border-radius: 5px;font-family: monospace;max-height: 250px;overflow: auto;white-space: pre;padding: 0px 0 5px 0;margin: 5px 0;">
                {{ output3 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="status1 == 'OK' && status2 == 'OK'" class="modal-footer" style="justify-content: center;">
        <button *ngIf="status3 != 'OK'" class="btn btn-primary" type="button" (click)="publish2()" translate>
          Publish</button
        >&nbsp;
        <button
          *ngIf="status3 == 'OK'"
          class="btn btn-primary"
          type="button"
          data-dismiss="modal"
          (click)="go('/')"
          translate>
          Open
        </button>
      </div>
    </ng-template>
  `,
})
export class PublishComponent implements OnInit, OnDestroy {
  @Input('debug') debug = false;
  @ViewChild('content') content;
  subscription: Subscription;
  info;

  deploying = -1;
  modal;
  loading1 = true;
  status1 = undefined;
  output1;
  loading2 = false;
  status2 = undefined;
  output2;
  loading3 = false;
  status3 = undefined;
  output3;
  closeResult;

  constructor(
    private apiService: ApiService,
    private subjectService: SubjectService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info['User']) {
        info['User']['Role'] = ['Root', 'Admin', 'Manager'][info['User']['Role']];
      }
      this.info = info;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else {
      return `with: ${reason}`;
    }
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  run(name, interval = 3000, limit = 100) {
    const those = this;
    return new Promise((resolve1, reject1) => {
      let response;
      (async function loop() {
        let counter = 1;
        let running = true;
        while (running) {
          await new Promise(resolve2 =>
            those.apiService[name]({ Interval: interval })
              .then(resp => {
                if (resp) {
                  switch (resp.Status) {
                    case 'Started':
                      setTimeout(() => resolve2(), interval);
                      break;
                    case 'Executing':
                      setTimeout(() => resolve2(), interval);
                      break;
                    case 'Finished':
                      response = resp;
                      running = false;
                      if (resp.Return) {
                        reject1(
                          new HttpErrorResponse({ error: { OUTPUT: resp.Output, RETURN: resp.Return }, status: 500 })
                        );
                      } else {
                        resolve2();
                      }
                      break;
                  }
                  if (counter >= limit) {
                    running = false;
                    reject1(new HttpErrorResponse({ error: 'Limit reached', status: 500 }));
                  }
                  counter++;
                }
              })
              .catch(err => {
                reject1(err);
                running = false;
              })
          );
        }

        await new Promise(resolve2 => {
          response.Status = 'OK';
          resolve1(response);
          resolve2();
        });
      })();
    });
  }

  onPublish(content) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Publish';
    modal.componentInstance.body = 'Are you sure you want to publish changes?';
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        if (those.info['Debug']) {
          those.status1 = undefined;
          those.status2 = undefined;
          those.status3 = undefined;
          those.loading1 = true;
          those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
          those
            .run('postPrepare')
            .then(resp => {
              those.loading1 = false;
              those.output1 = resp['Output'];
              those.status1 = resp['Status'];
              those.loading2 = true;
              those
                .run('postRender')
                .then(resp => {
                  those.loading2 = false;
                  those.output2 = resp['Output'];
                  those.status2 = resp['Status'];
                  those.status3 = 'READY';
                })
                .catch(err => {
                  those.error('Render', err);
                  those.loading2 = false;
                  those.output2 = err.error?.Output;
                  those.status2 = 'Fail';
                });
            })
            .catch(err => {
              those.error('Prepare', err);
              those.loading1 = false;
              those.output1 = err.error?.Output;
              those.status1 = 'Fail';
            });
          those.modal.result.then(
            result => {
              those.closeResult = `Closed with: ${result}`;
            },
            reason => {
              those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            }
          );
        } else {
          those.status1 = undefined;
          those.status2 = undefined;
          those.status3 = undefined;
          those.deploying = 0;
          those.loading1 = true;
          those
            .run('postPrepare')
            .then(resp => {
              those.loading1 = false;
              those.output1 = resp['Output'];
              those.status1 = resp['Status'];
              those.deploying = 1;
              those.loading2 = true;
              those
                .run('postRender')
                .then(resp => {
                  those.loading2 = false;
                  those.output2 = resp['Output'];
                  those.status2 = resp['Status'];
                  those.deploying = 2;
                  those.loading3 = true;
                  those
                    .run('postPublish')
                    .then(resp => {
                      those.loading3 = false;
                      those.output3 = resp['Output'];
                      those.status3 = resp['Status'];
                      those.deploying = 3;
                      those.apiService
                        .getInfo()
                        .then(info => {
                          those.subjectService.infoSubject.next(info);
                        })
                        .catch(err => {
                          those.error('Info', err);
                        });
                      setTimeout(() => (those.deploying = -1), 1000);
                    })
                    .catch(err => {
                      console.log('err', err);
                      those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
                      those.modal.result.then(
                        result => {
                          those.closeResult = `Closed with: ${result}`;
                        },
                        reason => {
                          those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
                        }
                      );
                      those.error('Publish', err);
                      those.loading3 = false;
                      those.output3 = err.error.OUTPUT ? err.error.OUTPUT : 'no output';
                      those.status3 = 'Fail';
                    });
                })
                .catch(err => {
                  console.log('err', err);
                  those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
                  those.modal.result.then(
                    result => {
                      those.closeResult = `Closed with: ${result}`;
                    },
                    reason => {
                      those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
                    }
                  );
                  those.error('Render', err);
                  those.loading2 = false;
                  those.output2 = err.error.OUTPUT ? err.error.OUTPUT : 'no output';
                  those.status2 = 'Fail';
                });
            })
            .catch(err => {
              console.log('err', err);
              those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
              those.modal.result.then(
                result => {
                  those.closeResult = `Closed with: ${result}`;
                },
                reason => {
                  those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
                }
              );
              those.error('Prepare', err);
              those.loading1 = false;
              those.output1 = err.error.OUTPUT ? err.error.OUTPUT : 'no output';
              those.status1 = 'Fail';
            });
        }
      },
      reason => {}
    );
  }

  publish2() {
    const those = this;
    those.loading3 = true;
    those
      .run('postPublish')
      .then(resp => {
        those.loading3 = false;
        those.output3 = resp['Output'];
        those.status3 = resp['Status'];
        those.apiService
          .getInfo()
          .then(info => {
            those.subjectService.infoSubject.next(info);
          })
          .catch(err => {
            those.error('Publish', err);
          });
      })
      .catch(err => {
        those.error('Publish', err);
        those.loading3 = false;
        those.output3 = err.error?.Output;
        those.status3 = 'Fail';
      });
  }

  go(url, path?) {
    window.open(this.absolutizeUrl.transform(url + (path ? '?path=' + window.location.pathname : '')), '_blank');
  }
}
