<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div *ngIf="product" class="card-header">
          <h5><a [routerLink]="['/variations']" [queryParams]="{id: product['ID']}" class="crumb link">{{product['Title']}}</a> :: <span class="crumb" translate>Files</span></h5>
        </div>
        <div class="card-body">
          <div *ngIf="product">
            <div class="row" style="margin-bottom: 20px">
              <div class="col">
                <div class="product-page-details product-right mb-0">
                  <h2>{{product['Title']}}</h2>
                  <hr>
                  <div class="row">
                    <div *ngIf="product['Thumbnail']" class="col-lg-2 col-md-3">
                      <img src="{{product['Thumbnail'] | absolutizeUrl}}" style="width: 100%">
                    </div>
                    <div *ngIf="product['Description']" class="col-lg-10 col-md-9">
                      <h6 class="product-title" translate>product details</h6>
                      <p class="mb-0">{{product['Description']}}</p>
                    </div>
                  </div>
                  <hr>
                  <div><h5 translate>Categories</h5>: <a *ngFor="let category of product['Categories']" class="link" style="cursor: pointer; padding-right: 5px">{{category['Title']}}</a></div>
                  <hr>
                </div>
              </div>
              <div class="col">
                <div class="dropzone-custom">
                  <div class="dropzone dropzone-primary" (sending)="onUploadStart($event)" (success)="onUploadSuccess($event)" (error)="onUploadError($event)" [dropzone]="dropzoneConfig">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600" translate>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="text-align: center">
                <h2 translate>Files</h2>
              </div>
            </div>
          </div>
          <div class="btn-popup pull-right" style="margin-bottom: 10px;">
            <app-edit-file [productId]="id" (onSaved)="refresh()" #editor>
              <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                      data-target="#exampleModal" (click)="editor.onCreate(editor.content)" translate>Add File
              </button>
            </app-edit-file>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive" [ngClass]="!visible?'transparent':''">
              <ng2-smart-table (edit)="editor.onEdit(editor.content, $event.data['ID'])" (delete)="editor.onDelete($event.data['ID'])" [settings]="settings" [source]="source"></ng2-smart-table>
              <div *ngIf="source && source.count() > 0" class="records-count">
                <span *ngIf="source.filtered() != source.total()">
                  {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                </span>
                <span *ngIf="source.filtered() == source.total()">
                  {{'Records' | translate}}: {{source.total()}}
                </span>
              </div>
            </div>
            <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
