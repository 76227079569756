import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [``],
  template: `
    <div class="button-group-dropdown">
      <button type="button" class="btn dropdown-toggle no-caret" style="padding: 0" data-toggle="dropdown">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 10.0002C8.33203 9.07969 9.07822 8.3335 9.9987 8.3335C10.9192 8.3335 11.6654 9.07969 11.6654 10.0002C11.6654 10.9206 10.9192 11.6668 9.9987 11.6668C9.07822 11.6668 8.33203 10.9206 8.33203 10.0002Z" fill="#616573"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 4.16667C8.33203 3.24619 9.07822 2.5 9.9987 2.5C10.9192 2.5 11.6654 3.24619 11.6654 4.16667C11.6654 5.08714 10.9192 5.83333 9.9987 5.83333C9.07822 5.83333 8.33203 5.08714 8.33203 4.16667Z" fill="#616573"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 15.8332C8.33203 14.9127 9.07822 14.1665 9.9987 14.1665C10.9192 14.1665 11.6654 14.9127 11.6654 15.8332C11.6654 16.7536 10.9192 17.4998 9.9987 17.4998C9.07822 17.4998 8.33203 16.7536 8.33203 15.8332Z" fill="#616573"/>
        </svg>
      </button>
      <ul class="dropdown-menu">
        <li (click)="parent.editor.onEdit(parent.editor.content,rowData['ID'])" translate>Edit</li>
      </ul>
    </div>
  `,
})
export class OrderOptionsRenderComponent implements OnInit, ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {

  }

  patch(label) {
    console.log('patch', label);
  }
}
