import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-title-input',
  template: `<div class="row" style="padding-bottom: 10px">
    <div *ngIf="expanded" class="col">
      <label class="mb-1" translate>Name</label>
      <input type="text" class="form-control" [(ngModel)]="name">
    </div>
    <div class="col">
      <label class="mb-1" translate>Display Name</label>
      <span style="float: right" (click)="toggle()">
        <svg *ngIf="!expanded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#5C22CF"/>
          <line x1="4.76562" y1="8.0332" x2="11.2323" y2="8.0332" stroke="#5C22CF" stroke-linecap="square"/>
          <line x1="7.96484" y1="4.7666" x2="7.96484" y2="11.2333" stroke="#5C22CF" stroke-linecap="square"/>
        </svg>
        <svg *ngIf="expanded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7.5" fill="white" stroke="#5C22CF"/>
          <line x1="4.76562" y1="8.0332" x2="11.2323" y2="8.0332" stroke="#5C22CF" stroke-linecap="square"/>
        </svg>
      </span>
      <input type="text" (change)="change($event.target['value'])" class="form-control" [(ngModel)]="title">
    </div>
  </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TitleInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TitleInputComponent
    }
  ]
})
export class TitleInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() input;
  @Input() name: string;
  title;
  value;
  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  expanded = false;

  ngOnInit(): void { }

  toggle() {
    this.expanded = !this.expanded;
  }

  change(value) {
    this.name = value.toLocaleLowerCase()
      .replace(/[!@#$%^&*\s]+/g, '-').replace(/-{1,}/, '-');
    this.markAsTouched();
    this.onChange(this.title);
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
