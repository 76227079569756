import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { credentials } from './credentials.data';
import {environment} from '../../environments/environment';

@Injectable()
export class ApiService {
  url = '/api/v1';
  websocket: WebSocket;
  interval;

  private headersObject = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
    const address = window.location.href.split('/')[2].split(':');
    if (address[0] === 'localhost' || +address[1] === 4200 || +address[1] === 4201 || credentials.apiUrl) {
      this.url = credentials.apiUrl;
    } else{
      this.url = window.location.protocol + '//' + window.location.host + this.url;
    }
    try {
      this.connect();
    } catch (err) {
      console.log('err', err);
    }
  }

  getHttp(): HttpClient {
    return this.http;
  }

  getUrl(): string {
    return this.url;
  }

  getBaseUrl(): string {
    return new URL(this.getFullUrl()).origin;
  }

  getFullUrl(): string {
    const url = this.getUrl();
    if (url.substr(0, 1) === '/') {
      return window.location.protocol + '//' + window.location.host + url;
    }
    return url;
  }

  getAuthorizationHeader(): any {
    const address = window.location.href.split('/')[2].split(':');
    if (address[0] === 'localhost' || +address[1] === 4200 || +address[1] === 4201) {
      return {
        Authorization: credentials.authorization,
      };
    }
  }

  login(creds: any, noInterruption?: boolean): Promise<any> {
    const url = `${this.url}/login` + (noInterruption ? '?no-interruption=true' : '');
    return this.http
      .post(url, creds, { headers: this.headersObject, withCredentials: true })
      .toPromise()
      .then(response => response as any[])
      .catch();
  }

  logout(noInterruption?: boolean): Promise<any> {
    const url = `${this.url}/logout` + (noInterruption ? '?no-interruption=true' : '');
    return this.http
      .get(url, { headers: this.headersObject, withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  connect() {
    console.log('connect');
    const those = this;
    if (those.interval) {
      clearInterval(those.interval);
      those.interval = undefined;
    }
    //
    const url = new URL(this.url.replace(/^http/, 'ws') + '/ws');
    const address = window.location.href.split('/')[2].split(':');
    if (address[0] === 'localhost' || +address[1] === 4200 || +address[1] === 4201) {
      url.searchParams.append('authorization', credentials.authorization.replace('Basic ', ''));
    }
    const websocket = new WebSocket(url.toString());
    websocket.onopen = event => {
      console.log('opened');
      those.interval = setInterval(() => {
        if (websocket) {
          try {
            websocket.send(JSON.stringify({ Type: 'WebsocketMessagePing' }));
          } catch (err) {
            console.log(err);
          }
        }
      }, 60000);
    };
    /*websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      switch (message.Type) {
        case 'WebsocketMessageEvent':
          switch (message.Event.Name) {
            case 'WebsocketMessagePong':
              break;
          }
          break;
      }
    };*/
    websocket.onclose = event => {
      console.log('closed');
      setTimeout(() => {
        console.log('try to reconnect...');
        those.connect();
      }, 3000);
    };
    websocket.onerror = event => {
      console.log('error', event['data']);
    };
    this.websocket = websocket;
  }

  delete(type, id: number): Promise<any> {
    const url = `${this.url}/${type}/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  patch(type, id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/${type}/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getWebsocket(): WebSocket {
    return this.websocket;
  }

  getInfo(noInterruption?: boolean): Promise<any> {
    const u = new URL(this.url + '/info');
    if (noInterruption) {
      u.searchParams.set('no-interruption', 'true');
    }
    u.searchParams.set('build', String(credentials.build));
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', ignoreLoadingBar: '' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getDashboard(): Promise<any> {
    const url = `${this.url}/dashboard`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getSettings(type): Promise<any> {
    const url = `${this.url}/settings/${type}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*putSettings(type, data): Promise<any> {
    const url = `${this.url}/settings/${type}`;
    return this.http.put(url, data, {headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
    }), withCredentials: true})
      .toPromise()
      .then(response => response as any)
      .catch();
  }*/

  putSettings(type, data): Promise<any> {
    const url = `${this.url}/settings/${type}`;
    return this.http
      .put(url, data, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putAccountSettings(data): Promise<any> {
    const url = `${this.url}/account`;
    return this.http
      .put(url, data, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getBasicSettings(): Promise<any> {
    const url = `${this.url}/settings/basic`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putBasicSettings(data): Promise<any> {
    const url = `${this.url}/settings/basic`;
    return this.http
      .put(url, data, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*postDump(): Observable<any> {
    const url = `${this.url}/dump`;
    return this.http.template(url, {}, {withCredentials: true});
  }*/

  postDump(): Promise<any> {
    const url = `${this.url}/dump`;
    return this.http
      .post(url, {}, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getHugoSettings(): Promise<any> {
    const url = `${this.url}/settings/hugo`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putHugoSettings(formData): Promise<any> {
    const url = `${this.url}/settings/hugo`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getPublisherSettings(): Promise<any> {
    const url = `${this.url}/settings/publisher`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putPublisherSettings(formData): Promise<any> {
    const url = `${this.url}/settings/publisher`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getCategories(id?: number, depth?: number, noProducts?: boolean): Promise<any> {
    const u = new URL(this.url + '/categories');
    if (id) {
      u.searchParams.set('id', id.toString());
    }
    if (depth) {
      u.searchParams.set('depth', depth.toString());
    }
    u.searchParams.set('no-products', noProducts ? 'true' : 'false');
    const url = u.toString();
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postCategory(formData): Promise<any> {
    const url = `${this.url}/categories`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getCategory(id: number): Promise<any> {
    const url = `${this.url}/categories/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*patchCategory(id: number, raw): Promise<any> {
    const url = `${this.url}/categories/${id}`;
    return this.http.patch(url, raw, {headers: new HttpHeaders({Accept: 'application/json'}), withCredentials: true})
      .toPromise()
      .then(response => response as any)
      .catch();
  }*/

  patchCategory(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/categories/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putCategory(id: number, formData): Promise<any> {
    const url = `${this.url}/categories/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteCategory(id: number): Promise<any> {
    const url = `${this.url}/categories/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postCategoriesAutocomplete(search: string, include?: string): Promise<any> {
    const url = `${this.url}/categories/autocomplete`;
    return this.http
      .post(url, { Search: search, Include: include }, { headers: this.headersObject, withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getContents(home?: string, depth?: number): Promise<any> {
    const u = new URL(this.url + '/contents');
    if (depth) {
      u.searchParams.set('depth', depth.toString());
    }
    if (home) {
      u.searchParams.set('home', home);
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getContent(path: string): Promise<any> {
    const url = `${this.url}/contents/${path}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchContent(path: string, action): Promise<any> {
    const url = `${this.url}/contents/${path}`;
    return this.http
      .patch(url, action, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postContent(path: string, file): Promise<any> {
    const url = `${this.url}/contents/${path}`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http
      .post(url, file, { headers, withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putContent(path: string, file): Promise<any> {
    const url = `${this.url}/contents/${path}`;
    return this.http
      .put(url, file, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postProduct(formData): Promise<any> {
    const url = `${this.url}/products`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getProduct(id: number): Promise<any> {
    const url = `${this.url}/products/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchProduct(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/products/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putProduct(id: number, formData): Promise<any> {
    const url = `${this.url}/products/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteProduct(id: number): Promise<any> {
    const url = `${this.url}/products/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getParameter(id: number): Promise<any> {
    const url = `${this.url}/parameters/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postParameter(raw): Promise<any> {
    const u = new URL(this.url + '/parameters');
    return this.http
      .post(u.toString(), raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchParameter(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/parameters/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putParameter(id: number, raw): Promise<any> {
    const url = `${this.url}/parameters/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteParameter(id: number): Promise<any> {
    const url = `${this.url}/parameters/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postVariation(formData): Promise<any> {
    const u = new URL(this.url + '/variations');
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getVariation(id: number): Promise<any> {
    const url = `${this.url}/variations/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*patchVariation(id: string, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/variations/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    }else{
      headers = new HttpHeaders({'Content-Type': 'application/json'});
    }
    return this.http.patch(u.toString(), raw, {
      headers,
      withCredentials: true
    })
      .toPromise()
      .then(response => response as any)
      .catch();
  }*/

  patchVariation(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/variations/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putVariation(id: number, formData): Promise<any> {
    const url = `${this.url}/variations/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteVariation(id: number): Promise<any> {
    const url = `${this.url}/variations/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getProperty(id: number): Promise<any> {
    const url = `${this.url}/properties/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchProperty(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/properties/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getBlocks(): Promise<any[]> {
    const url = `${this.url}/blocks`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getBlock(id: number): Promise<any> {
    const url = `${this.url}/blocks/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postBlock(formData): Promise<any> {
    const url = `${this.url}/blocks`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchBlock(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/blocks/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putBlock(id: number, formData): Promise<any> {
    const url = `${this.url}/blocks/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteBlock(id: number, recursive?: boolean): Promise<any> {
    const url = new URL(`${this.url}/blocks/${id}`);
    if (recursive) {
      url.searchParams.set('recursive', 'true');
    }
    return this.http
      .delete(url.toString(), { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getPosts(): Promise<any[]> {
    const url = `${this.url}/posts`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getPost(id: number): Promise<any> {
    const url = `${this.url}/posts/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postPost(formData): Promise<any> {
    const url = `${this.url}/posts`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchPost(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/posts/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putPost(id: number, formData): Promise<any> {
    const url = `${this.url}/posts/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deletePost(id: number): Promise<any> {
    const url = `${this.url}/posts/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getTags(): Promise<any[]> {
    const url = `${this.url}/tags`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTag(id: number): Promise<any> {
    const url = `${this.url}/tags/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postTag(formData): Promise<any> {
    const url = `${this.url}/tags`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTag(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/tags/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTag(id: number, formData): Promise<any> {
    const url = `${this.url}/tags/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTag(id: number): Promise<any> {
    const url = `${this.url}/tags/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getOptions(): Promise<any[]> {
    console.log('getOptions');
    const url = `${this.url}/options`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getOption(id: number): Promise<any> {
    const url = `${this.url}/options/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postOption(formData): Promise<any> {
    const url = `${this.url}/options`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchOption(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/options/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putOption(id: number, formData): Promise<any> {
    const url = `${this.url}/options/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteOption(id: number): Promise<any> {
    const url = `${this.url}/options/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getValues(optionId?): Promise<any[]> {
    const u = new URL(this.url + '/values');
    if (optionId) {
      u.searchParams.set('option_id', optionId);
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getValue(id: number): Promise<any> {
    const url = `${this.url}/values/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postValue(optionId, formData): Promise<any> {
    const u = new URL(this.url + '/values');
    if (optionId) {
      u.searchParams.set('option_id', optionId);
    }
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchValue(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/values/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putValue(id: number, formData): Promise<any> {
    const url = `${this.url}/values/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteValue(id: number): Promise<any> {
    const url = `${this.url}/values/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getDimensions(): Promise<any[]> {
    const url = `${this.url}/dimensions`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getDimension(id: number): Promise<any> {
    const url = `${this.url}/dimensions/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postDimension(formData): Promise<any> {
    const url = `${this.url}/dimensions`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchDimension(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/dimensions/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putDimension(id: number, formData): Promise<any> {
    const url = `${this.url}/dimensions/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteDimension(id: number): Promise<any> {
    const url = `${this.url}/dimensions/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getMetrics(dimensionId?): Promise<any[]> {
    const u = new URL(this.url + '/metrics');
    if (dimensionId) {
      u.searchParams.set('dimension_id', dimensionId);
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postProperty(raw): Promise<any> {
    const url = `${this.url}/properties`;
    return this.http
      .post(url, raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putProperty(id: number, raw): Promise<any> {
    const url = `${this.url}/properties/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteProperty(id: number): Promise<any> {
    const url = `${this.url}/properties/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getRates(propertyId): Promise<any[]> {
    const u = new URL(this.url + '/rates');
    if (propertyId) {
      u.searchParams.set('property_id', propertyId);
    }
    return this.http
      .get(u.toString(), { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getRate(id: number): Promise<any> {
    const url = `${this.url}/rates/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postRate(propertyId, raw): Promise<any> {
    const u = new URL(this.url + '/rates');
    if (propertyId) {
      u.searchParams.set('property_id', propertyId);
    }
    return this.http
      .post(u.toString(), raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchRate(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/rates/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putRate(id: number, raw): Promise<any> {
    const url = `${this.url}/rates/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteRate(id: number): Promise<any> {
    const url = `${this.url}/rates/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getPrices(params): Promise<any> {
    const u = new URL(this.url + '/prices');
    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        u.searchParams.set(p, params[p]);
      }
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postPricesExport(raw): Promise<any> {
    const u = new URL(this.url + '/prices/export');
    return this.http
      .post(u.toString(), raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postPrices(raw): Promise<any> {
    const u = new URL(this.url + '/prices');
    return this.http
      .post(u.toString(), raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putPrices(raw): Promise<any> {
    const u = new URL(this.url + '/prices/all');
    return this.http
      .put(u.toString(), raw, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchPrices(action, raw): Promise<any> {
    const url = new URL(`${this.url}/prices`);
    if (action) {
      url.searchParams.set('action', action);
    }
    return this.http
      .patch(url.toString(), raw, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getPrice(id: number): Promise<any> {
    const url = `${this.url}/prices/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postPrice(formData): Promise<any> {
    const url = `${this.url}/prices`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchPrice(id: number, action: string, formData): Promise<any> {
    const u = new URL(`${this.url}/prices/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    return this.http
      .patch(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putPrice(id: number, formData): Promise<any> {
    const url = `${this.url}/prices/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deletePrice(id: number): Promise<any> {
    const url = `${this.url}/prices/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postFile(formData, params?): Promise<any> {
    const u = new URL(this.url + '/files');
    if (params && params['pid']) {
      u.searchParams.set('pid', params['pid']);
    } else if (params && params['vid']) {
      u.searchParams.set('vid', params['vid']);
    }
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getFile(id: number): Promise<any> {
    const url = `${this.url}/files/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putFile(id: number, formData): Promise<any> {
    const url = `${this.url}/files/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteFile(id: number): Promise<any> {
    const url = `${this.url}/files/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postImage(formData, params?): Promise<any> {
    let url = `${this.url}/images`;
    if (params) {
      const str = [];
      for (let p in params)
        if (params.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
        }
      url += '?' + str.join('&');
    }
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getImage(id: number): Promise<any> {
    const url = `${this.url}/images/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putImage(id: number, formData): Promise<any> {
    const url = `${this.url}/images/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteImage(id: number): Promise<any> {
    const url = `${this.url}/images/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getMedias(request, params?): Promise<any> {
    let url = `${this.url}/medias/list`;
    if (params) {
      const str = [];
      for (let p in params)
        if (params.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
        }
      url += '?' + str.join('&');
    }
    return this.http
      .post(url, request, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchMedia(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/medias/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postMedia(formData, params?): Promise<any> {
    const url = new URL(`${this.url}/medias`);
    if (params) {
      for (const p in params) {
        if (params.hasOwnProperty(p)) {
          url.searchParams.set(p, params[p]);
        }
      }
    }
    return this.http
      .post(url.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getMedia(id: number): Promise<any> {
    const url = `${this.url}/medias/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putMedia(id: number, formData): Promise<any> {
    const url = `${this.url}/medias/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteMedia(id: number): Promise<any> {
    const url = `${this.url}/medias/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postFetch(raw): Promise<any> {
    const url = `${this.url}/fetch`;
    return this.http
      .post(url, raw, { headers: this.headersObject, withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getOrder(id: number): Promise<any> {
    const url = `${this.url}/orders/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*patchOrder(id: number, raw): Promise<any> {
    const url = `${this.url}/orders/${id}`;
    return this.http.patch(url, raw, {headers: new HttpHeaders({Accept: 'application/json'}), withCredentials: true})
      .toPromise()
      .then(response => response as any)
      .catch();
  }*/

  patchOrder(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/orders/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putOrder(id: number, raw): Promise<any> {
    const url = `${this.url}/orders/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteOrder(id: number): Promise<any> {
    const url = `${this.url}/orders/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getCustomer(id: number): Promise<any> {
    const url = `${this.url}/customers/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postCustomer(raw): Promise<any> {
    const url = `${this.url}/customers`;
    return this.http
      .post(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putCustomer(id: number, raw): Promise<any> {
    const url = `${this.url}/customers/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchCustomer(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/customers/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteCustomer(id: number): Promise<any> {
    const url = `${this.url}/customers/${id}`;
    return this.http
      .delete(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getCustomerDetails(id): Promise<any> {
    const url = `${this.url}/customers/${id}/details`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postProfile(type: string, formData): Promise<any> {
    const url = `${this.url}/${type}_profiles`;
    return this.http
      .post(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getProfile(type, id): Promise<any> {
    const url = `${this.url}/${type}_profiles/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putProfile(type: string, id: number, formData): Promise<any> {
    const url = `${this.url}/${type}_profiles/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteProfile(type: string, id: number): Promise<any> {
    const url = `${this.url}/${type}_profiles/${id}`;
    return this.http
      .delete(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTransfers(): Promise<any[]> {
    const url = `${this.url}/transfers`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTransfer(id: number): Promise<any> {
    const url = `${this.url}/transfers/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postTransfer(formData): Promise<any> {
    const url = `${this.url}/transfers`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTransfer(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/transfers/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTransfer(id: number, formData): Promise<any> {
    const url = `${this.url}/transfers/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTransfer(id: number): Promise<any> {
    const url = `${this.url}/transfers/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getCoupons(): Promise<any[]> {
    const url = `${this.url}/coupons`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getCoupon(id: number): Promise<any> {
    const url = `${this.url}/coupons/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postCoupon(formData): Promise<any> {
    const url = `${this.url}/coupons`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchCoupon(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/coupons/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putCoupon(id: number, formData): Promise<any> {
    const url = `${this.url}/coupons/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteCoupon(id: number): Promise<any> {
    const url = `${this.url}/coupons/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getWebsites(): Promise<any[]> {
    const url = `${this.url}/websites`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getWebsite(id: number): Promise<any> {
    const url = `${this.url}/websites/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postWebsite(formData): Promise<any> {
    const url = `${this.url}/websites`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchWebsite(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/websites/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putWebsite(id: number, formData): Promise<any> {
    const url = `${this.url}/websites/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteWebsite(id: number): Promise<any> {
    const url = `${this.url}/websites/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getThemes(): Promise<any> {
    const url = `${this.url}/themes`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTheme(name): Promise<any> {
    const url = `${this.url}/themes/${name}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getLayout(theme, path): Promise<any> {
    const url = `${this.url}/themes/${theme}/${path}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putLayout(theme, path, raw): Promise<any> {
    const url = `${this.url}/themes/${theme}/${path}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTemplateTest(path: string): Promise<any> {
    const url = `${this.url}/${path}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  /*postTemplate(formData): Promise<any> {
    const url = `${this.url}/widgets`;
    return this.http.post(url, formData, {headers: new HttpHeaders({Accept: 'application/json'}), withCredentials: true})
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTemplate(path, template): Promise<any> {
    const url = `${this.url}/themes/default/${path}`;
    return this.http.put(url, template, {headers: new HttpHeaders({Accept: 'application/json'}), withCredentials: true})
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTemplate(id: number): Promise<any> {
    const url = `${this.url}/widgets/${id}`;
    return this.http.delete(url, {withCredentials: true})
      .toPromise()
      .then(response => response)
      .catch();
  }*/

  getLayoutWidget(id: number): Promise<any> {
    const url = `${this.url}/widgets/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postLayoutWidget(formData): Promise<any> {
    const url = `${this.url}/widgets`;
    return this.http
      .post(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putLayoutWidget(id: number, formData): Promise<any> {
    const url = `${this.url}/widgets/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteLayoutWidget(id: number): Promise<any> {
    const url = `${this.url}/widgets/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }
  getForms(): Promise<any[]> {
    const url = `${this.url}/forms`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getForm(id: number): Promise<any> {
    const url = `${this.url}/forms/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postForm(formData): Promise<any> {
    const url = `${this.url}/forms`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putForm(id: number, formData): Promise<any> {
    const url = `${this.url}/forms/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteForm(id: number): Promise<any> {
    const url = `${this.url}/forms/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postMessagesDump(id): Promise<any> {
    const url = `${this.url}/forms/${id}/messages/dump`;
    return this.http
      .post(url, {}, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getMessage(id: number): Promise<any> {
    const url = `${this.url}/messages/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postMessage(formData): Promise<any> {
    const url = `${this.url}/messages`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchMessage(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/messages/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putMessage(id: number, formData): Promise<any> {
    const url = `${this.url}/messages/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteMessage(id: number): Promise<any> {
    const url = `${this.url}/messages/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getMenus(): Promise<any[]> {
    const url = `${this.url}/menus`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getMenu(id: number): Promise<any> {
    const url = `${this.url}/menus/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postMenu(formData, params?): Promise<any> {
    const u = new URL(this.url + '/menus');
    if (params && params['vid']) {
      u.searchParams.set('vid', params['vid']);
    }
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchMenu(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/menus/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putMenu(id: number, formData): Promise<any> {
    const url = `${this.url}/menus/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteMenu(id: number): Promise<any> {
    const url = `${this.url}/menus/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getTransaction(id: number): Promise<any> {
    const url = `${this.url}/transactions/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTransaction(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/transactions/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTransaction(id: number, raw): Promise<any> {
    const url = `${this.url}/transactions/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTransaction(id: number): Promise<any> {
    const url = `${this.url}/transactions/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getTariffs(zoneId): Promise<any[]> {
    const u = new URL(this.url + '/tariffs');
    if (zoneId) {
      u.searchParams.set('zoneId', zoneId.toString());
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTransports(): Promise<any[]> {
    const url = `${this.url}/transports`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTransport(id: number): Promise<any> {
    const url = `${this.url}/transports/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postTransport(formData): Promise<any> {
    const url = `${this.url}/transports`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTransport(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/transports/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTransport(id: number, formData): Promise<any> {
    const url = `${this.url}/transports/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTransport(id: number): Promise<any> {
    const url = `${this.url}/transports/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getZones(): Promise<any[]> {
    const url = `${this.url}/zones`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getZone(id: number): Promise<any> {
    const url = `${this.url}/zones/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postZone(formData): Promise<any> {
    const url = `${this.url}/zones`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchZone(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/zones/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putZone(id: number, formData): Promise<any> {
    const url = `${this.url}/zones/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteZone(id: number): Promise<any> {
    const url = `${this.url}/zones/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getVendors(): Promise<any[]> {
    const url = `${this.url}/vendors`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getVendor(id: number): Promise<any> {
    const url = `${this.url}/vendors/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postVendor(formData): Promise<any> {
    const url = `${this.url}/vendors`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchVendor(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/vendors/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putVendor(id: number, formData): Promise<any> {
    const url = `${this.url}/vendors/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteVendor(id: number): Promise<any> {
    const url = `${this.url}/vendors/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getTimes(params?): Promise<any[]> {
    const u = new URL(this.url + '/times');
    if (params && params['vid']) {
      u.searchParams.set('vid', params['vid']);
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTime(id: number): Promise<any> {
    const url = `${this.url}/times/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postTime(formData): Promise<any> {
    const u = new URL(this.url + '/times');
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTime(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/times/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTime(id: number, formData): Promise<any> {
    const url = `${this.url}/times/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTime(id: number): Promise<any> {
    const url = `${this.url}/times/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getComments(params?): Promise<any[]> {
    const u = new URL(this.url + '/account/comments');
    if (params && params['pid']) {
      u.searchParams.set('pid', params['pid']);
    }
    if (params && params['offset']) {
      u.searchParams.set('offset', params['offset']);
    }
    if (params && params['count']) {
      u.searchParams.set('count', params['count']);
    }
    return this.http
      .get(u.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getComment(id: number): Promise<any> {
    const url = `${this.url}/comments/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postComment(formData, params?): Promise<any> {
    const u = new URL(this.url + '/comments');
    if (params && params['vid']) {
      u.searchParams.set('vid', params['vid']);
    }
    return this.http
      .post(u.toString(), formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putComment(id: number, formData): Promise<any> {
    const url = `${this.url}/comments/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteComment(id: number): Promise<any> {
    const url = `${this.url}/comments/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  patchComment(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/comments/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getEmailTemplate(id: number): Promise<any> {
    const url = `${this.url}/notification/email/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postEmailTemplate(formData): Promise<any> {
    const url = `${this.url}/notification/email`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchEmailTemplate(id: number, formData): Promise<any> {
    const url = `${this.url}/notification/email/${id}`;
    return this.http
      .patch(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putEmailTemplate(id: number, formData): Promise<any> {
    const url = `${this.url}/notification/email/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteEmailTemplate(id: number): Promise<any> {
    const url = `${this.url}/notification/email/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getTemplates(): Promise<any[]> {
    const url = `${this.url}/templates`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  getTemplate(id: number): Promise<any> {
    const url = `${this.url}/templates/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postTemplate(formData): Promise<any> {
    const url = `${this.url}/templates`;
    return this.http
      .post(url, formData, {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  patchTemplate(id, action: string, raw): Promise<any> {
    const u = new URL(`${this.url}/templates/${id}`);
    if (action) {
      u.searchParams.set('action', action);
    }
    let headers;
    if (raw instanceof FormData) {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    return this.http
      .patch(u.toString(), raw, {
        headers,
        withCredentials: true,
      })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putTemplate(id: number, formData): Promise<any> {
    const url = `${this.url}/templates/${id}`;
    return this.http
      .put(url, formData, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteTemplate(id: number): Promise<any> {
    const url = `${this.url}/templates/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  getUser(id: number): Promise<any> {
    const url = `${this.url}/users/${id}`;
    return this.http
      .get(url, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  putUser(id: number, raw): Promise<any> {
    const url = `${this.url}/users/${id}`;
    return this.http
      .put(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  deleteUser(id: number): Promise<any> {
    const url = `${this.url}/users/${id}`;
    return this.http
      .delete(url, { withCredentials: true })
      .toPromise()
      .then(response => response)
      .catch();
  }

  postPrepare(raw): Promise<any> {
    const url = `${this.url}/prepare`;
    return this.http
      .post(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postRender(raw): Promise<any> {
    const url = `${this.url}/render`;
    return this.http
      .post(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  postPublish(raw): Promise<any> {
    const url = `${this.url}/publish`;
    return this.http
      .post(url, raw, { headers: new HttpHeaders({ Accept: 'application/json' }), withCredentials: true })
      .toPromise()
      .then(response => response as any)
      .catch();
  }

  convertJSONtoFormData(jsonObject: Object, parentKey, carryFormData: FormData): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
          let propName = parentKey || key;
          if (parentKey && this.isObject(jsonObject)) {
            propName = parentKey + '[' + key + ']';
          }
          if (parentKey && this.isArray(jsonObject)) {
            propName = parentKey + '[' + index + ']';
          }
          if (jsonObject[key] instanceof File) {
            formData.append(propName, jsonObject[key]);
          } else if (jsonObject[key] instanceof FileList) {
            for (let j = 0; j < jsonObject[key].length; j++) {
              formData.append(propName + '[' + j + ']', jsonObject[key].item(j));
            }
          } else if (this.isArray(jsonObject[key]) || this.isObject(jsonObject[key])) {
            this.convertJSONtoFormData(jsonObject[key], propName, formData);
          } else if (typeof jsonObject[key] === 'boolean') {
            formData.append(propName, +jsonObject[key] ? '1' : '0');
          } else {
            formData.append(propName, jsonObject[key]);
          }
        }
      }
      index++;
    }
    return formData;
  }

  isArray(val) {
    const toString = {}.toString;
    return toString.call(val) === '[object Array]';
  }

  isObject(val) {
    return !this.isArray(val) && typeof val === 'object' && !!val;
  }
}
