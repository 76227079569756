import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../../shared/utils.pipe';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../../shared/api.service';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

import * as moment from 'moment';
import {ITreeOptions, TreeComponent} from 'angular-tree-component';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})

export class CouponComponent implements OnInit, OnDestroy {
  @ViewChild('categoriesTree', { read: TreeComponent }) categoriesTree: TreeComponent;
  @ViewChild('productsTree', { read: TreeComponent }) productsTree: TreeComponent;
  paramsSubscription: Subscription;
  id;
  coupon;
  type;
  form;
  categories;
  products;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false
  };
  lock1 = false;

  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder, protected http: HttpClient, private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe) { }

  ngOnInit(): void {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params.id === 'new') {
          those.form = those.form = those.createForm({Enabled: true, Type: 'item', Minimum: 1, Count: 100});
          //
          this.apiService.getCategories(0, 999, false).then(result => {
            // Categories
            those.categories = this.filterCategories(JSON.parse(JSON.stringify(result)));
            those.categoriesTree.treeModel.update();
            // Products
            those.products = this.filterProducts(JSON.parse(JSON.stringify(result)));
            those.productsTree.treeModel.update();
          }).catch(err => {
            those.error('Get categories', err);
          });
        } else {
          this.id = params['id'];
          this.apiService.getCoupon(this.id).then(coupon => {
            those.coupon = coupon;
            those.form = those.createForm(coupon);
            //
            this.format(this.form.get('Minimum'));
            //
            this.apiService.getCategories(0, 999, false).then(result => {
              // Categories
              those.categories = this.filterCategories(JSON.parse(JSON.stringify(result)));
              those.categoriesTree.treeModel.update();
              setTimeout(() => {
                those.setSelectedNodes(this.categoriesTree, (coupon['Categories'] || []).map(item => item['ID']));
              }, 100);
              // Products
              those.products = this.filterProducts(JSON.parse(JSON.stringify(result)));
              those.productsTree.treeModel.update();
              setTimeout(() => {
                those.setSelectedNodes(this.productsTree, (coupon['Products'] || []).map(item => item['ID']));
              }, 100);
            }).catch(err => {
              those.error('Get categories', err);
            });
          }).catch(err => {
            those.error('Load coupon', err);
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    console.log('err', err);
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  createForm(coupon) {
    return this.formBuilder.group({
      ID: [coupon['ID']],
      Enabled: [{disabled: true, value: coupon['Enabled'] ? true : false}],
      Title: [{disabled: coupon['Title'] ? true : false, value: coupon['Title'] ? coupon['Title'] : ''}, Validators.required],
      Code: [{disabled: coupon['Title'] ? true : false, value: coupon['Code'] ? coupon['Code'] : ''}],
      Type: [coupon['Type'] ? coupon['Type'] : '', Validators.required],
      Start: [coupon['Start'] ? moment(coupon['Start']).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD 00:00:00'), Validators.required],
      End: [coupon['End'] ? moment(coupon['End']).format('YYYY-MM-DD HH:mm:ss') : moment('2099-12-31').format('YYYY-MM-DD 00:00:00'), Validators.required],
      Amount: [coupon['Amount'] ? coupon['Amount'] : '', Validators.required],
      Minimum: [coupon['Minimum'] ? coupon['Minimum'] : 0],
      Count: [coupon['Count'] ? coupon['Count'] : 0],
      Limit: [coupon['Limit'] ? coupon['Limit'] : 0],
      ApplyTo: [coupon['ApplyTo'] ? coupon['ApplyTo'] : 'all'],
      Categories: [(coupon['Categories'] || []).map(item => item['ID']).join(',')],
      Products: [(coupon['Products'] || []).map(item => item['ID']).join(',')],
    });
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  trim(control){
    if (control && control.value) {
      control.setValue(control.value.replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control){
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    }else{
      control.setValue('0.00');
    }
  }

  debug() {
    const tree = {Children: [{ID: 1, Type: 'category', Name: 'Cat1', Children: [{ID: 2, Type: 'category', Name: 'SubCat1', Children: [{ID: 4, Type: 'category', Name: 'SubSubCat1'}, {ID: 11, Type: 'product', Name: 'Product11'}]},{ID: 3, Type: 'category', Name: 'SubCat2', Children: [{ID: 12, Type: 'product', Name: 'Product12'}]}]}]}
    this.filterCategories(tree);
  }

  //
  filterCategories(tree) {
    let f;
    f = (nodes) => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++){
        nodes[i]['id'] = nodes[i]['ID'];
        nodes[i]['type'] = nodes[i]['Type'];
        nodes[i]['name'] = nodes[i]['Title'];
        if (nodes[i]['Type'] === 'category') {
          const children = f(nodes[i]['Children'] || []);
          if (children && children.length > 0) {
            nodes[i]['children'] = children;
          }
          delete nodes[i]['Children'];
          nodes2.push(nodes[i]);
        }
      }
      return nodes2;
    };
    return f(tree['Children']);
  }

  filterProducts(tree) {
    let f;
    f = (nodes) => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++){
        nodes[i]['id'] = nodes[i]['Type'] === 'product' ? nodes[i]['ID'] : (nodes[i]['ID'] * -1);
        nodes[i]['type'] = nodes[i]['Type'];
        nodes[i]['name'] = nodes[i]['Title'];
        const children = f(nodes[i]['Children'] || []);
        if (children && children.length > 0) {
          nodes[i]['children'] = children;
        }
        nodes2.push(nodes[i]);
      }
      return nodes2;
    };
    return f(tree['Children']);
  }

  getSelectedNodes(tree){
    const acc = [];
    let f;
    f = (nodes) => {
      if (nodes) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i]['id']) {
            const node = tree.treeModel.getNodeById(nodes[i]['id']);
            if (node && node.isSelected) {
              acc.push(nodes[i]['id']);
            }
          }
          if (nodes[i]['children']) {
            f(nodes[i]['children']);
          }
        }
      }
    };
    f(tree.treeModel.nodes);
    return acc;
  }

  setSelectedNodes(tree, ids){
    for (let i = 0; i < ids.length; i++){
      let node = tree.treeModel.getNodeById(ids[i]);
      if (node) {
        node.setIsSelected(true);
        while (node.parent) {
          node.parent.expand();
          node = node.parent;
        }
      }
    }
  }

  selectNode(id){
    let node = this.categoriesTree.treeModel.getNodeById(id);
    node.setIsSelected(true);
    while (node.parent) {
      node.parent.expand();
      node = node.parent;
    }
  }

  onChangeCategories1(tree, ids, $event){
    const those = this;
    const categories = ids.split(/,\s*/).map(item => +item);
    this.lock1 = true;
    let f;
    f = (nodes) => {
      for (let i = 0; i < nodes.length; i++){
        if (nodes[i]['id']){
          const node = tree.treeModel.getNodeById(nodes[i]['id']);
          if (node) {
            const selected = categories.indexOf(node['id']) > -1;
            if (selected) {
              those.selectNode(node['id']);
            } else {
              node.setIsSelected(selected);
            }
          }
        }
        if (nodes[i]['children']){
          f(nodes[i]['children']);
        }
      }
    };
    f(this.categories);
    this.lock1 = false;
  }

  onChangeCategories2(tree, name, ids, $event){
    if (($event['eventName'] === 'select' || $event['eventName'] === 'deselect') && !this.lock1){
      const categories1 = ids.split(/,\s*/).map(item => +item);
      categories1.sort((a, b) => a - b);
      const categories2 = this.getSelectedNodes(tree).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      categories2.sort((a, b) => a - b);
      if (JSON.stringify(categories1) !== JSON.stringify(categories2)) {
        this.form.get(name).setValue(categories2.join(','));
        this.form.get(name).updateValueAndValidity();
        this.form.get(name).markAsDirty();
      }
    }
  }

  onDelete() {
    alert('Not implemented yet');
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    raw.Start = moment(raw.Start).format();
    raw.End = moment(raw.End).format();
    raw.Minimum = +raw.Minimum;
    raw.Categories = this.getSelectedNodes(this.categoriesTree).join(',');
    raw.Products = this.getSelectedNodes(this.productsTree).join(',');
    console.log('raw', raw);
    if (!this.form.get('ID').value) {
      // create
      this.apiService.postCoupon(raw).then(resp => {
        those.success('Create coupon', resp);
        those.router.navigate(['/sales/coupons/' + resp.ID]);
      }).catch(err => {
        those.error('Create coupon', err);
      });
    } else {
      // update
      this.apiService.putCoupon(this.form.get('ID').value, {...this.coupon, ...raw}).then(resp => {
        this.success('Update coupons', resp);
      }).catch(err => {
        this.error('Update coupons', err);
      });
    }
  }
}
