<div class="container-fluid">
  <div class="page-header">
    <!--div class="row">
      <div *ngIf="category" class="col">
        <a class="facon-previous" style="color: initial;position: absolute; left: 15px;top: 5px;" [routerLink]="['/products']"></a>
        <h3 style="display: inline-block; padding-left: 20px;">{{'Category' | translate}}: {{category['Title']}}</h3>
      </div>
      <div *ngIf="!category" class="col">
        <h3 style="display: inline-block;padding-left: 20px;" translate>Products</h3>
      </div>
    </div-->
    <div class="row">
      <div class="col">
        <div *ngIf="category">
          <a class="facon-previous" style="color: initial;position: absolute; left: 15px;top: 5px;" [routerLink]="['/products']"></a>
          <h3 style="display: inline-block; padding-left: 20px;">{{'Category' | translate}}: {{category['Title']}}</h3>
        </div>
        <h1 *ngIf="!category && route.snapshot.data['title']" [innerText]="route.snapshot.data['title'] | translate"></h1>
      </div>
      <div class="col">
        <div class="btn-popup pull-right" style="margin-bottom: 0">
          <span class="btn btn-default" style="cursor: pointer" (click)="error('In progress', {status: 500, error: {ERROR: 'not implemented yet'}})">Import</span>
          <span class="btn btn-default" style="cursor: pointer" (click)="error('In progress', {status: 500, error: {ERROR: 'not implemented yet'}})">Export</span>
          <app-edit-product (onSaved)="refresh()" #editor>
            <span class="btn btn-plain btn-add" (click)="onAddProduct()" translate> Add Product</span>
          </app-edit-product>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="route.snapshot.data['subtitle'] || route.snapshot.data['description']" class="row">
            <div class="col-sm-12">
              <h2 *ngIf="route.snapshot.data['subtitle']" [innerText]="route.snapshot.data['subtitle'] | translate"></h2>
              <div *ngIf="route.snapshot.data['description']" class="body-3 mb-4" [innerText]="route.snapshot.data['description'] | translate">
              </div>
            </div>
          </div>
          <app-my-smart-table [type]="'products'" [(columns)]="columns" [presets]="presets" [bulks]="bulks" [filters]="filters" [sorts]="sorts" [(source)]="source" [draggable]="cid ? true : false" [parent]="this" ></app-my-smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
