import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
      {{theValue}}
  `,
})
export class CategoryProductsRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  theValue = '0';

  ngOnInit(): void {
    this.theValue = (Number(this.rowData['Count'] ? this.rowData['Count'] : 0) - Number(this.value ? this.value : 0)).toFixed(0);
  }
}
