import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { credentials } from '../../../shared/credentials.data';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';

@Component({
  selector: 'app-value-inline',
  template: `
    <div [formGroup]="form">
      <div class="row" style="padding-bottom: 10px;">
        <div class="col">
          <label class="mb-1" translate>Name</label>
          <div><input class="form-control" type="text" (change)="onChangeValue($event)" formControlName="Value" /></div>
        </div>
        <div class="col">
          <label class="mb-1" translate>Display Name</label>
          <div>
            <input
              class="form-control"
              type="text"
              name="title"
              (change)="onChangeTitle($event)"
              formControlName="Title" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="mb-1" translate>Type</label>
          <div>
            <app-select
              [options]="[
                { Name: undefined, Label: 'Text' },
                { Name: 'color', Label: 'Color' },
                { Name: 'image', Label: 'Image' }
              ]"
              formControlName="Mode"></app-select>
          </div>
        </div>
        <div *ngIf="form.get('Mode').value == 'color'" class="col-md-6">
          <label class="mb-1" translate>&nbsp;</label>
          <app-color-input formControlName="Color"></app-color-input>
        </div>
        <div *ngIf="form.get('Mode').value == 'image'" class="col-md-6">
          <label class="mb-1" translate>&nbsp;</label>
          <app-media-input formControlName="Media"></app-media-input>
        </div>
      </div>
      <div class="row border-bottom" style="margin: 10px -10px 10px -10px;">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col" style="display: flex;justify-content: space-between;">
          <div style="cursor: pointer; display: flex;" (click)="editor = !editor">
            <svg
              class="fill-primary-violet"
              style="margin: 0 0 0 4px; height: 20px; width: 20px; vertical-align: bottom;">
              <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiMessageSquareDetail"></use>
            </svg>
            <span class="color-primary-violet" style="padding-left: 5px" translate>Description</span
            ><!--span *ngIf="value['Description']">*</span-->
          </div>
        </div>
      </div>
      <div *ngIf="!optionId && editor" class="row mb-2">
        <div class="col" style="padding-top: 10px">
          <app-tinymce
            [options]="tinymceConfig"
            (focus)="$event.target?.iframeElement?.classList?.add('focused')"
            (blur)="$event.target?.iframeElement?.classList?.remove('focused')"
            formControlName="Description"></app-tinymce>
        </div>
      </div>
    </div>
  `,
})
export class ValueInlineComponent implements OnInit, AfterViewInit {
  @Input('propertyId') propertyId;
  @Input('optionId') optionId;
  @Input('property') property;
  @Input('form') form;
  @Input('valueEditor') valueEditor;
  @Input('tinymceConfig') tinymceConfig;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  currency = credentials.currency;
  price;
  prices = [];
  values;
  modal;
  closeResult;
  editor = false;
  expanded = false;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  trim(control) {
    if (control && control.value) {
      control.setValue(control.value.replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  onChangeValue(event) {
    const control = this.form.get('Title');
    if (!control || control.value === 'New') {
      control.setValue(event.target.value);
    }
  }

  onChangeTitle(event) {
    const control = this.form.get('Value');
    if (!control || !control.value) {
      control.setValue(event.target.value);
    }
  }
}
