import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SalesRoutingModule } from './sales-routing.module';
import {CouponsComponent} from './coupons/coupons.component';
import { OrdersComponent } from './orders/orders.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrderTotalRenderComponent} from './orders/order-total-render.component';
import { TransactionsComponent } from './transactions/transactions.component';
import {TransactionNameRenderComponent} from './transactions/transaction-name-render.component';
import {TransactionOrderRenderComponent} from './transactions/transaction-order-render.component';
import {TransactionPaymentRenderComponent} from './transactions/transaction-payment-render.component';
import {TransactionAmountRenderComponent} from './transactions/transaction-amount-render.component';
import {TransactionStatusRenderComponent} from './transactions/transaction-status-render.component';
import {MomentModule} from 'ngx-moment';
import {TranslateModule} from '@ngx-translate/core';
import {TinymceModule} from 'angular2-tinymce';
import {SharedModule} from '../../shared/shared.module';
import {CouponComponent} from './coupons/coupon/coupon.component';
import {CouponDiscountsRenderComponent} from './coupons/coupon-discounts-render.component';
import {CouponTitleRenderComponent} from './coupons/coupon-title-render.component';
import {CouponUsedRenderComponent} from './coupons/coupon-used-render.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {TreeModule} from 'angular-tree-component';
import {OrderIdRenderComponent} from './orders/order-id-render.component';
import {CustomersComponent} from './customers/customers.component';
import {CustomerComponent} from './customers/customer/customer.component';
import {OrderTagsRenderComponent} from './orders/order-tags-render.component';
import {CustomerIdRenderComponent} from './customers/customer-id-render.component';
import {CustomerNameRenderComponent} from './customers/customer-name-render.component';
import {CustomerTotalRenderComponent} from './customers/customer-total-render.component';
import {CustomerTagsRenderComponent} from './customers/customer-tags-render.component';
import {CustomerOrdersRenderComponent} from './customers/customer-orders-render.component';
import {CustomerBlockComponent} from './customers/customer-block.component';
import {OrderBlockComponent} from './orders/order-block.component';
import {OrderNameRenderComponent} from './orders/order-name-render.component';
import {OrderBillingStatusRenderComponent} from './orders/order-billing-status-render.component';
import {OrderShippingStatusRenderComponent} from './orders/order-shipping-status-render.component';
import {OrderOptionsRenderComponent} from './orders/order-options-render.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxBootstrapMultiselectModule} from 'ngx-bootstrap-multiselect';

@NgModule({
  declarations: [CouponsComponent, CouponTitleRenderComponent, CouponUsedRenderComponent, CouponDiscountsRenderComponent,
    TransactionStatusRenderComponent, CouponComponent, OrdersComponent, OrderIdRenderComponent, OrderIdRenderComponent,
    CustomerOrdersRenderComponent, CustomerBlockComponent, OrderNameRenderComponent, OrderBillingStatusRenderComponent,
    OrderShippingStatusRenderComponent, OrderTotalRenderComponent, OrderTagsRenderComponent, OrderOptionsRenderComponent,
    OrderBlockComponent, TransactionsComponent, TransactionNameRenderComponent, TransactionOrderRenderComponent,
    TransactionPaymentRenderComponent, TransactionAmountRenderComponent, CustomersComponent, CustomerComponent,
    CustomerIdRenderComponent, CustomerNameRenderComponent, CustomerTotalRenderComponent, CustomerTagsRenderComponent,
  ],
  imports: [
    CommonModule,
    SalesRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    MomentModule,
    NgbModule,
    TranslateModule,
    NgxBootstrapMultiselectModule,
    TinymceModule.withConfig({baseURL: '/assets/tinymce'}),
    SharedModule,
    DpDatePickerModule,
    TreeModule,
    SharedModule,
    FormsModule,
  ]
})
export class SalesModule { }
