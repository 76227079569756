import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ImagesDataSource } from './images.data-source';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ApiService } from '../../../shared/api.service';
import { ImageSizeRenderComponent } from './image-size-render.component';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { TranslateService } from '@ngx-translate/core';
import { ImageNameRenderComponent } from './image-name-render.component';
import { DateRenderComponent } from '../../../shared/render/date-render.component';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class ImagesComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  queryParamsSubscription: Subscription;
  arguments = {};
  product;
  id;
  tree;
  settings;
  source: ImagesDataSource;
  image;
  form;
  modal;
  closeResult;
  visible = false;
  timer;

  dropzoneConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    timeout: 300000,
  };
  counter = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (/^\/products\//gi.test(those.router.url)) {
        if (params['id']) {
          //those.id = params['id'];
          those.arguments['product_id'] = params['id'];
          those.apiService
            .getProduct(params['id'])
            .then(product => {
              const url = new URL(those.apiService.getUrl() + '/images/');
              url.searchParams.set('pid', this.id);
              those.dropzoneConfig.url = url.toString();
              those.dropzoneConfig.headers = those.apiService.getAuthorizationHeader();
              those.dropzoneConfig.paramName = 'Image';
              those.product = product;
            })
            .catch(err => {
              those.error('Load product', err);
            });
        }
      }
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params['id']) {
        those.id = params['id'];
        those.arguments['product_id'] = params['id'];
        those.apiService
          .getProduct(params['id'])
          .then(product => {
            const url = new URL(those.apiService.getUrl() + '/images/');
            url.searchParams.set('pid', this.id);
            those.dropzoneConfig.url = url.toString();
            those.dropzoneConfig.headers = those.apiService.getAuthorizationHeader();
            those.dropzoneConfig.paramName = 'Image';
            those.product = product;
          })
          .catch(err => {
            those.error('Load product', err);
          });
      }
    });
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        columnTitle: those.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        ID: {
          title: 'Id',
        },
        Created: {
          title: those.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
        Name: {
          title: those.translate.instant('Name'),
          type: 'custom',
          renderComponent: ImageNameRenderComponent,
          filter: false,
        },
        Size: {
          title: those.translate.instant('Size'),
          type: 'custom',
          renderComponent: ImageSizeRenderComponent,
        },
        Updated: {
          title: those.translate.instant('Updated'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    this.source = new ImagesDataSource(those.http, those.apiService, those.absolutizeUrl);
    this.source.parent = those;
    those.source.arguments = those.arguments;
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  refresh() {
    this.source.refresh();
  }

  onUploadStart($event) {
    this.counter++;
  }

  onUploadSuccess($event) {
    const those = this;
    if ($event && $event.length > 1) {
      this.success('Create image', $event[1]);
      those.counter--;
      if (those.counter === 0) {
        setTimeout(() => {}, 1000);
      }
    }
  }

  onUploadError($event) {
    this.error('Create image', { status: 500, error: { ERROR: 'Something went wrong' } });
  }

  upload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      Image: file,
    });
    this.form.get('Image').markAllAsTouched();
    this.form.get('Image').updateValueAndValidity();
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getImage(id)
      .then(image => {
        those.image = image;
        those.form = those.formBuilder.group({
          ID: [{ disabled: true, value: image['ID'] }],
          Name: [image['Name']],
          Image: [image['Url'] ? those.absolutizeUrl.transform(image['Url']) : ''],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
          }
        );
      })
      .catch(err => {
        console.log('err', err);
        those.error('Load image', err);
      });
  }

  onDelete(event) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    const name = those.source.data.filter(item => item['Selected']).length <= 1 ? 'image' : 'images';
    modal.componentInstance.title = `Delete ${name}?`;
    modal.componentInstance.body = `Are you sure you want to delete ${name}? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteImage(event.data['ID'])
          .then(resp => {
            those.success('Delete image', resp);
            those.source.refresh();
          })
          .catch(err => {
            those.error('Delete image', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Image', this.form.get('Image').value);
    this.apiService
      .putImage(those.image['ID'], data)
      .then(resp => {
        those.success('Update image', resp);
        those.modal.close('Saved');
        those.source.refresh();
      })
      .catch(err => {
        those.error('Update image', err);
      });
  }
}
