import {Component} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {credentials} from '../../../shared/credentials.data';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CountryInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CountryInputComponent
    }
  ]
})
export class CountryInputComponent implements ControlValueAccessor, Validator {
  code;

  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  countries = [...credentials.countries];

  constructor() { }

  change() {
    if (this.code) {
      this.markAsTouched();
      this.onChange(this.code.toLowerCase());
    }
  }

  writeValue(value) {
    if (value) {
      const country = this.countries.find(item => item.Code.toLowerCase() === value.toLowerCase());
      if (country) {
        this.code = country.Code;
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
