import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'categories',
    loadChildren: () => import('../../components/categories/categories.module').then(m => m.CategoriesModule),
    data: {
      breadcrumb: 'Categories',
    },
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/components/components.module').then(m => m.CommentsModule),
    data: {
      breadcrumb: 'Components',
    },
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: 'Products',
    },
  },
  {
    path: 'variations',
    loadChildren: () => import('../../components/variations/variations.module').then(m => m.VariationsModule),
    data: {
      breadcrumb: 'Products',
    },
  },
  {
    path: 'properties',
    loadChildren: () => import('../../components/properties/properties.module').then(m => m.PropertiesModule),
    data: {
      breadcrumb: 'Variations',
    },
  },
  {
    path: 'prices',
    loadChildren: () => import('../../components/rates/rates.module').then(m => m.RatesModule),
    data: {
      breadcrumb: 'Properties',
    },
  },
  {
    path: 'options',
    loadChildren: () => import('../../components/options/options.module').then(m => m.OptionsModule),
    data: {
      breadcrumb: 'Options',
    },
  },
  {
    path: 'values',
    loadChildren: () => import('../../components/values/values.module').then(m => m.ValuesModule),
    data: {
      breadcrumb: 'Options',
    },
  },
  {
    path: 'dimensions',
    loadChildren: () => import('../../components/dimensions/dimensions.module').then(m => m.DimensionsModule),
    data: {
      breadcrumb: 'Dimensions',
    },
  },
  {
    path: 'posts',
    loadChildren: () => import('../../components/posts/posts.module').then(m => m.PostsModule),
    data: {
      breadcrumb: 'Posts',
    },
  },
  {
    path: 'tags',
    loadChildren: () => import('../../components/tags/tags.module').then(m => m.TagsModule),
    data: {
      breadcrumb: 'Tags',
    },
  },
  {
    path: 'comments',
    loadChildren: () => import('../../components/comments/comments.module').then(m => m.CommentsModule),
    data: {
      breadcrumb: 'Comments',
    },
  },
  {
    path: 'templates',
    loadChildren: () => import('../../components/templates/templates.module').then(m => m.TemplatesModule),
    data: {
      breadcrumb: 'Templates',
    },
  },
  /**/
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: 'Sales',
    },
  },
  {
    path: 'transports',
    loadChildren: () => import('../../components/transports/transports.module').then(m => m.TransportsModule),
  },
  /*{
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
  },*/
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/medias/medias.module').then(m => m.MediasModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: 'Menus',
    },
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Users',
    },
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: 'Vendors',
    },
  },
  {
    path: 'search',
    loadChildren: () => import('../../components/search/search.module').then(m => m.SearchModule),
    data: {
      breadcrumb: 'Search',
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/settings/settings.module').then(m => m.SettingsModule),
    data: {
      breadcrumb: 'Settings',
    },
  },
  {
    path: 'forms',
    loadChildren: () => import('../../components/forms/forms.module').then(m => m.FormsModule),
  },
  {
    path: 'websites',
    loadChildren: () => import('../../components/websites/websites.module').then(m => m.WebsitesModule),
    data: {
      breadcrumb: 'Website',
    },
  },
  {
    path: 'messages',
    loadChildren: () => import('../../components/messages/messages.module').then(m => m.MessagesModule),
  },
  {
    path: 'themes',
    loadChildren: () => import('../../components/themes/themes.module').then(m => m.ThemesModule),
    data: {
      breadcrumb: 'Layout',
    },
  },
];
