import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../shared/api.service';
import {SubjectService} from '../../../shared/subject.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {credentials} from '../../../shared/credentials.data';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [AbsolutizeUrlPipe]
})
export class FormComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription;
  currency = credentials.currency;
  id;
  item;
  form;
  loading = true;
  tinymceConfig;
  media;

  constructor(public router: Router, private route: ActivatedRoute, private modalService: NgbModal, private apiService: ApiService,
              private subjectService: SubjectService, private formBuilder: FormBuilder, protected http: HttpClient,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe) {
  }

  ngOnInit() {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params.id) {
        if (params.id === 'new') {
          those.form = those.formBuilder.group({
            ID: [''],
            Enabled: [true],
            Name: ['', Validators.required],
            Title: ['', Validators.required],
            Description: [''],
            Type: [''],
          });
        } else {
          those.loading = true;
          those.id = params.id;
          those.apiService.getForm(those.id).then(form => {
            those.loading = false;
            those.item = form;
            those.form = those.formBuilder.group({
              ID: [form.ID],
              Enabled: [form.Enabled],
              Name: [form.Name, Validators.required],
              Title: [form.Title, Validators.required],
              Description: [form.Description],
              Type: [form.Type],
            });
          }).catch(err => {
            those.error('Load form', err);
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(event.target.value.toLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-').replace(/-{1,}/, '-')
      );
    }
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    if (!this.form.get('ID').value) {
      // create
      this.apiService.postForm(raw).then(resp => {
        those.success('Create form', resp);
        those.router.navigate(['/forms']);
      }).catch(err => {
        those.error('Delete form', err);
      });
    } else {
      // update
      this.apiService.putForm(this.form.get('ID').value, raw).then(resp => {
        those.success('Update form', resp);
      }).catch(err => {
        those.error('Update form', err);
      });
    }
  }
}
