import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products.component';
import { ProductComponent } from './product/product.component';
import { ImagesComponent } from '../medias/images/images.component';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';
import { VariationComponent } from './product/variation/variation.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
    data: {
      title: 'All Products',
      breadcrumb: '',
      subtitle: 'All Products',
      description:
        'In the product overview you will find all products created so far. From here you can manage your products, edit them or create new products.',
    },
  },
  {
    path: ':id',
    component: ProductComponent,
    data: {
      title: 'Product',
      breadcrumb: 'Products',
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ':id/images',
    component: ImagesComponent,
    data: {
      title: 'Images',
      breadcrumb: 'Products',
    },
  },
  {
    path: ':pid/variations/:id',
    component: VariationComponent,
    data: {
      title: 'Variation',
      breadcrumb: 'Variation',
    },
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
