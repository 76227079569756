<div class="modal-header" style="padding: 0.5rem 1rem;">
  <div class="title">
    <svg style="height: 24px; width: 24px;">
      <use [attr.xlink:href]="'/assets/icons/bi-symbol-defs.svg#icon-BiMessageSquareError'"></use>
    </svg>
    <span class="body-2" [innerHTML]="title | translate"></span>
  </div>
  <div class="button-icon" style="display: flex" ngbAutofocus (click)="modal.dismiss('Cross click')">
    <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5" />
      <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5" />
    </svg>
  </div>
</div>
<div class="modal-body">
  <div class="body-3" [innerHTML]="body | translate"></div>
</div>
<div class="modal-footer" style="justify-content: flex-start;padding: 0.2rem 0.5rem; gap: 5px;">
  <button
    class="btn btn-primary"
    style="line-height: 20px;"
    type="button"
    data-dismiss="modal"
    (click)="modal.dismiss('Cross click')"
    [innerHTML]="cancel | translate"></button>
  <button
    type="submit"
    class="btn btn-danger-2"
    style="line-height: 20px;"
    (click)="modal.close('Confirmed');"
    [innerHTML]="confirm | translate"></button>
</div>
