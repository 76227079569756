import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';

@Component({
  selector: 'app-parameters-block',
  template: `
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 style="display: inline-block; margin-bottom: 20px; padding: 5px 5px 0 0;" translate>Custom fields</h2>
            <span
              class="tooltip-question"
              style="vertical-align: middle;"
              placement="top"
              [ngbTooltip]="
                'Custom fields allow you to specify additional information that will appear on the products page. Custom fields appear automatically in the product\\'s details if they are defined on the product. If you don\\'t want to show any custom fields, simply remove them from the product.'
                  | translate
              ">
              <svg width="16px" height="16px">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
              </svg>
            </span>
          </div>
        </div>
        <div *ngIf="parameters && parameters.length > 0" class="row">
          <div class="col">
            <div class="form-group" style="margin-bottom: 0;"><label class="mb-1" translate>Display Name</label></div>
          </div>
          <div class="col">
            <div class="form-group" style="margin-bottom: 0;"><label class="mb-1" translate>Value</label></div>
          </div>
        </div>
        <div *ngIf="dimensions" class="parameters-list" style="padding-bottom: 15px;">
          <app-parameter-inline
            *ngFor="let parameter of parameters; index as i"
            [productId]="productId"
            [variationId]="variationId"
            [parameters]="parameters"
            [parametersFormControl]="parametersFormControl"
            [parameter]="parameter"
            [dimensions]="dimensions"
            [index]="i"
            [parent]="this"
            [ngClass]="{
              toggle:
                parameter['DimensionId'] >= 0 && parameters[i + 1] && parameters[i + 1]['DimensionId'] === undefined,
              'regular-parameter': parameter['DimensionId'] >= 0,
              'custom-parameter': parameter['DimensionId'] === undefined
            }"></app-parameter-inline>
        </div>
        <!--div class="row">
          <div class="col">
            <app-edit-parameter
              [productId]="productId"
              [variationId]="variationId"
              (onCreated)="onParameterCreated($event)"
              (onSaved)="addParameter($event)"
              #parameterEditor></app-edit-parameter>
            <div style="display: flex; gap: 15px;">
              <span class="btn btn-third facon-plus" (click)="addParameter({})" translate>Add Custom Field</span>
              <span
                class="btn btn-third facon-plus"
                (click)="parameterEditor.onCreate(parameterEditor.content)"
                translate
                >Add Custom Field From Library</span
              >
            </div>
          </div>
        </div-->
      </div>
    </div>
  `,
})
export class ParametersBlockComponent implements OnInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('parameters') parameters;
  @Input('parametersFormControl') parametersFormControl: FormControl;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();

  dimensions;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    const those = this;
    this.apiService
      .getDimensions()
      .then(dimensions => {
        those.dimensions = dimensions;
      })
      .catch(err => {
        console.error('Load options', err);
      });
    //
    if (!this.parameters || this.parameters.length === 0 || this.parameters[this.parameters.length - 1].Title !== '') {
      this.addAnotherParameter();
    }
    this.addParameter({ Title: '', DimensionId: 0, CustomValue: '' }, false);
  }

  refresh(type?) {
    this.onSaved.emit(type);
  }

  onParameterCreated(parameter) {
    let index = 0;
    for (let i = 0; i < this.parameters.length; i++) {
      if (this.parameters[i].DimensionId) {
        index = i;
      }
    }
    this.parameters.splice(index + 1, 0, parameter);
  }

  addParameter(parameter, dirty?) {
    this.parameters = this.parameters || [];
    if (parameter['ID']) {
      for (let i = 0; i < this.parameters.length; i++) {
        if (this.parameters[i]['ID'] === parameter['ID']) {
          this.parameters[i] = parameter;
          break;
        }
      }
    } else {
      let last = 0;
      this.parameters.forEach((item, index) => {
        if (item.DimensionId) {
          last = index;
        }
      });
      this.parameters.splice(last + 1, 0, parameter);
    }
    this.parameters.sort((a, b) => {
      if (a.DimensionId >= 0 && !b.DimensionId) {
        return -1;
      } else {
        return 1;
      }
    });
    if (dirty) {
      this.parametersFormControl.markAllAsTouched();
      this.parametersFormControl.updateValueAndValidity();
      this.parametersFormControl.markAsDirty();
    }
  }

  /*x() {
    this.parameters = this.parameters || [];
    this.parameters.push({ Title: '', DimensionId: 0, CustomValue: '' });
  }*/

  addAnotherParameter() {
    if (!this.parameters || this.parameters.filter(item => item.Title === '').length === 0) {
      this.parameters = this.parameters || [];
      this.parameters.push({ Title: '', DimensionId: undefined, CustomValue: '' });
    }
  }
}
