import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ActivatedRoute } from '@angular/router';
import {AbsolutizeUrlPipe, MediaThumbnailPipe} from '../../shared/utils.pipe';

@Component({
  styles: [
    `
      .block {
        display: inline-block;
        position: relative;
      }
      .block:not(.searching)::before {
        content: '';
        position: absolute;
        border-bottom: 1px dotted #23527c;
        border-left: 1px dotted #23527c;
        width: 30px;
        margin-bottom: 40px;
        left: 0;
        height: 40px;
      }
      .block:not(.searching)::after {
        content: '';
        position: absolute;
        border-left: 1px dotted #23527c;
        height: 40px;
        top: 0px;
        left: 0px;
        margin-top: 40px;
      }
      .block-last:not(.searching)::after {
        border-left: none;
      }
      .block-expanded:not(.searching)::after {
        border-left: none;
        border-right: 1px dotted #23527c;
        width: 33px;
      }
      .collapsed,
      .expanded {
        width: 15px;
        height: 15px;
        border: 1px solid #5c22cf;
        border-radius: 50%;
        position: relative;
        margin-top: 0;
        margin-left: 25px;
        display: inline-block;
        background-color: #fff;
        z-index: 1;
      }
      .collapsed::before,
      .expanded::before {
        content: '';
        display: inline-block;
        width: 7px;
        border-top: 1px solid #5c22cf;
        position: absolute;
        top: 6px;
        left: 3px;
      }
      .collapsed::after {
        content: '';
        display: inline-block;
        height: 7px;
        border-left: 1px solid #5c22cf;
        position: absolute;
        top: 3px;
        left: 6px;
      }
      .offset {
        display: inline;
        width: 32px;
      }
      .offset::before {
        border-left: 1px dotted #23527c;
        content: '';
      }
    `,
  ],
  template: `
    <div
      class="block block-level-{{ rowData['level'] }}"
      [ngClass]="{ 'block-last': rowData['last'], 'block-expanded': rowData['expanded'], searching: rowData['term'] }"
      style="vertical-align: middle"
      [ngStyle]="{ marginLeft: rowData['level'] * 32 + 'px' }">
      <div *ngIf="rowData['Children']" style="display: inline-block;">
        <div *ngIf="!rowData['term']; else placeholder" class="expandable">
          <div *ngIf="!rowData['expanded']" style="cursor: pointer; display: inline-block;">
            <span
              [attr.id]="'id-' + rowData['ID'] + '-expand-button'"
              class="btn btn-xs collapsed"
              (click)="expand()"></span>
          </div>
          <div *ngIf="rowData['expanded']" style="cursor: pointer; display: inline-block;">
            <span
              [attr.id]="'id-' + rowData['ID'] + '-collapse-button'"
              class="btn btn-xs expanded"
              (click)="collapse()"></span>
          </div>
        </div>
        <ng-template #placeholder
          ><div class="not-expandable" style="display: inline-block;width: 20px">&nbsp;</div></ng-template
        >
      </div>
      <div *ngIf="!rowData['Children']" class="not-expandable" style="display: inline-block; min-width: 40px">
        &nbsp;
      </div>
      <div
        *ngIf="rowData['Media']"
        [style]="{
          backgroundImage:
            'url(' +
            mediaThumbnail.transform(rowData['Media']) +
            ')',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'inline-block',
          height: '60px',
          width: '60px',
          marginRight: '10px',
          verticalAlign: 'middle'
        }"></div>
      <div style="display:inline-block;vertical-align: middle">
        <div>
          <span style="padding-right: 5px;">#{{ rowData['ID'] }}</span
          ><a class="link" [routerLink]="['/categories/' + rowData['ID']]" style="cursor: pointer">{{ value }}</a
          ><span *ngIf="!rowData['Enabled']" class="status-inactive" style="line-height: 1.4;" translate>inactive</span>
        </div>
        <div>
          <a
            class="link"
            style="text-decoration: none;"
            [routerLink]="['/products']"
            [queryParams]="{ search: 'category:' + rowData['ID'] }"
            ><span translate>products</span></a
          >
        </div>
      </div>
    </div>
  `,
})
export class CategoryTitleRenderComponent implements ViewCell, OnInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  thumbnail;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe, public mediaThumbnail: MediaThumbnailPipe, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.rowData['Thumbnail']) {
      const thumbnail = this.rowData['Thumbnail'];
      const res = thumbnail.split(/,/);
      if (res && res.length > 1) {
        this.thumbnail = res[1].split(/ /)[0];
      } else {
        this.thumbnail = '/api/v1/resize?path=/storage' + encodeURIComponent(res[0]);
      }
    }
  }

  expand() {
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] == this.rowData['ID']) {
        if (this.rowData['Children']) {
          this.parent.source.data[i]['expanded'] = true;
          this.parent.source.data.splice(
            i + 1,
            0,
            ...this.rowData['Children'].map((item, i) => {
              item['level'] = this.rowData['level'] ? this.rowData['level'] + 1 : 1;
              if (i == this.rowData['Children'].length - 1) {
                item['last'] = true;
              }
              return item;
            })
          );
        }
        break;
      }
    }
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    setTimeout(() => {
      const rows = this.parent.table.grid.getRows();
      for (let i = 0; i < rows.length; i++) {
        const data = rows[i].getData();
        if (data['ID'] == this.rowData['ID']) {
          this.parent.table.grid.selectRow(rows[i]);
        }
      }
    }, 100);
  }

  collapse() {
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] == this.rowData['ID']) {
        if (this.rowData['Children']) {
          let length = 0;
          let f1 = root => {
            if (root['expanded'] && root['Children']) {
              root['expanded'] = false;
              length += root['Children'].length;
            }
            if (root['Children']) {
              for (let i = 0; i < root['Children'].length; i++) {
                f1(root['Children'][i]);
              }
            }
          };
          f1(this.parent.source.data[i]);
          this.parent.source.data[i]['expanded'] = false;
          this.parent.source.data.splice(i + 1, length);
          if (
            i === this.parent.source.data.length - 1 ||
            (i + 1 < this.parent.source.data.length &&
              this.parent.source.data[i + 1]['level'] !== this.rowData['level'])
          ) {
            this.parent.source.data[i]['last'] = true;
          } else {
            this.parent.source.data[i]['last'] = false;
          }
          break;
        }
      }
    }
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    setTimeout(() => {
      const rows = this.parent.table.grid.getRows();
      for (let i = 0; i < rows.length; i++) {
        const data = rows[i].getData();
        if (data['ID'] == this.rowData['ID']) {
          this.parent.table.grid.selectRow(rows[i]);
        }
      }
    }, 100);
  }
}
