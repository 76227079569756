import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {UsersComponent} from './users.component';
import {UserOrdersRenderComponent} from './user-orders-render.component';
import {MomentModule} from 'ngx-moment';
import {UserEmailConfirmedRenderComponent} from './user-email-confirmed-render.component';
import {UserRoleRenderComponent} from './user-role.render.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [UsersComponent, UserEmailConfirmedRenderComponent, UserRoleRenderComponent, UserOrdersRenderComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    MomentModule,
    TranslateModule,
  ],
})
export class UsersModule { }
