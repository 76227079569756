import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../utils.pipe';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MediasDataSource } from '../../../components/medias/medias.data-source';
import { ConfirmComponent } from '../confirm/confirm.component';
import {SubjectService} from '../../subject.service';

@Component({
  selector: 'app-edit-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class MediaComponent implements OnInit, OnDestroy {
  @Input('productId') public productId;
  @Input('variationId') public variationId;
  @Input('size') public size = 'md';
  @Input('source') public source: MediasDataSource;
  @ViewChild('content') content;
  //
  id;
  media;
  form;
  icon;
  preview;
  modal;
  curr = 0;
  hasPrev = false;
  hasNext = false;
  closeResult;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onCreated: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();

  url;
  edit = false;
  image;
  canvas;
  context;
  files: File[];
  config = { autoCrop: false, movable: true, scalable: true, zoomable: true };
  flip = {
    h: {
      x: -1,
      y: 1,
    },
    v: {
      x: 1,
      y: -1,
    },
  };
  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content, size?) {
    const those = this;
    this.id = undefined;
    this.edit = false;
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Name: ['', Validators.required],
      Extension: [''],
      Body: [''],
      ContentType: ['', Validators.required],
      Title: [''],
      Alt: [''],
      Caption: [''],
      Description: [''],
    });
    //
    those.modal = those.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: size ? size : those.size,
    });
    those.modal.result.then(
      result => {
        those.onCreated.emit({ ...those.media });
        those.media = undefined;
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.media = undefined;
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.id = id;
    this.edit = false;
    this.form = undefined;
    this.load(id);
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: those.size });
    those.modal.result.then(
      result => {
        those.onSaved.emit({ ...those.media });
        those.media = undefined;
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.media = undefined;
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  load(id) {
    const those = this;
    const info = those.subjectService.infoSubject.getValue();
    this.apiService
      .getMedia(id)
      .then(media => {
        those.media = media;
        those.form = those.formBuilder.group({
          ID: [media['ID']],
          Enabled: [media['Enabled'] ? true : false],
          Name: [{ disabled: true, value: media['Name'] }, Validators.required],
          Extension: [media['Extension']],
          ContentType: [{ disabled: true, value: media['ContentType'] }, Validators.required],
          Body: [this.absolutizeUrl.transform(info?.StoragePublicUrl ? info.StoragePublicUrl + media['Path'] : media['Path']), Validators.required],
          Title: [media['Title'] ? media['Title'] : ''],
          Alt: [media['Alt'] ? media['Alt'] : ''],
          Caption: [media['Caption'] ? media['Caption'] : ''],
          Description: [media['Description'] ? media['Description'] : ''],
        });
        this.url = this.absolutizeUrl.transform(
          (info?.StoragePublicUrl ? info.StoragePublicUrl + media['Path'] : media['Path'])+ '?u=' + Math.floor(new Date(media['UpdatedAt']).getTime() / 1000).toString(36)
        );
        if (media.ContentType && media.ContentType.indexOf('image/') >= 0) {
          if (info?.StoragePublicUrl && media.Path) {
            this.icon = info.StoragePublicUrl + media.Path;
          }else{
            this.icon = media.Path;
          }
        } else if (media.ContentType === 'application/pdf') {
          this.icon = '/admin/assets/images/file-pdf.svg';
        } else if (media.ContentType === 'application/zip') {
          this.icon = '/admin/assets/images/file-zip.svg';
        } else if (
          ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(
            media.ContentType
          ) >= 0
        ) {
          this.icon = '/admin/assets/images/file-doc.svg';
        } else if (
          ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(
            media.ContentType
          ) >= 0
        ) {
          this.icon = '/admin/assets/images/file-xls.svg';
        } else {
          this.icon = '/admin/assets/images/file.svg';
        }
        this.preview = this.absolutizeUrl.transform(this.icon);
        if (those.source) {
          those.curr = those.source.data.findIndex(item => item.ID === id);
          if (those.curr > 0) {
            those.hasPrev = true;
          }
          if (those.curr < those.source.data.length - 1) {
            those.hasNext = true;
          }
        }
      })
      .catch(err => {
        those.error('Load media', err);
      });
  }

  prev() {
    this.load(this.source.data[this.curr - 1].ID);
    this.curr--;
    this.hasPrev = this.curr > 0;
  }

  next() {
    this.load(this.source.data[this.curr + 1].ID);
    this.curr++;
    this.hasNext = this.curr < this.source.data.length - 1;
  }

  upload(files) {
    const file = files[0];
    if (this.form.get('Name').value === '') {
      const match = file.name.match(/(.+)\.([^\.]+)$/);
      if (match.length > 2 && match[1] && match[2]) {
        this.form.get('Name').setValue(match[1]);
      }else{
        this.form.get('Name').setValue(file.name);
      }
    }
    if (this.form.get('ContentType').value === '') {
      this.form.get('ContentType').setValue(file.type);
    } else if (this.form.get('ContentType').value !== file.type) {
      this.error('Update media', { status: 500, ERROR: 'Type cant be changed' });
      return;
    }
    this.form.patchValue({
      Body: file,
    });
    this.form.get('Body').markAsTouched();
    this.form.get('Body').updateValueAndValidity();
  }

  onCopy() {
    const element = document.getElementById('url');
    element['select']();
    element['setSelectionRange'](0, 99999);
    navigator.clipboard.writeText(element['value']);
  }

  onEditToggle() {
    this.edit = !this.edit;
  }

  onRotate(container, degrees) {
    container.cropper.rotate(degrees);
  }

  onFlip(container, direction) {
    if (direction === 'horizontal') {
      container.cropper.scale(this.flip.h.x, this.flip.h.y);
      this.flip.h.x *= -1;
    } else {
      container.cropper.scale(this.flip.v.x, this.flip.v.y);
      this.flip.v.y *= -1;
    }
  }

  onCancel(container) {
    container.cropper.clear();
    this.edit = false;
  }

  onCrop(container) {
    const canvas = container.cropper.getCroppedCanvas({ imageSmoothingQuality: 'medium' });
    const data = canvas.toDataURL(this.media.ContentType);

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const chunks = this.media.Path.split('/');
    const file = new File([u8arr], chunks[chunks.length - 1], { type: mime });

    this.files = [file];
    this.upload(this.files);

    //this.preview = data;
    this.edit = false;
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Extension', this.form.get('Extension').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Alt', this.form.get('Alt').value);
    data.append('Caption', this.form.get('Caption').value);
    data.append('Description', this.form.get('Description').value);
    if (this.form.get('Body').touched) {
      data.append('Body', this.form.get('Body').value);
    }
    if (!this.media) {
      // create
      const params = {};
      if (those.variationId) {
        params['vid'] = those.variationId;
      } else if (those.productId) {
        params['pid'] = those.productId;
      }
      this.apiService
        .postMedia(data, params)
        .then(media => {
          those.media = media;
          those.success('Create media', { MESSAGE: 'OK' });
          those.modal.close('Created');
        })
        .catch(err => {
          those.error('Create media', err);
        });
    } else {
      // update
      this.apiService
        .putMedia(this.media.ID, data)
        .then(media => {
          those.media = media;
          those.success('Update media', { MESSAGE: 'OK' });
          those.modal.close('Updated');
        })
        .catch(err => {
          those.error('Update media', err);
        });
    }
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete file?';
    modal.componentInstance.body = `Are you sure you want to delete file? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteMedia(id)
          .then(resp => {
            those.success('Delete media', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete media', err);
          });
      },
      reason => {}
    );
  }
}
