import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsComponent } from './forms.component';
import {FormComponent} from './form/form.component';

const routes: Routes = [
  {
    path: '',
    component: FormsComponent,
    data: {
      title: 'Forms',
      breadcrumb: ''
    }
  },
  {
    path: ':id',
    component: FormComponent,
    data: {
      title: 'Form',
      breadcrumb: 'Form'
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormsRoutingModule { }
