import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../shared/api.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {DropzoneDirective} from 'ngx-dropzone-wrapper';
import {TransfersDataSource} from '../../../components/products/transfers.data-source';
import {DateAgoRenderComponent} from '../../render/date-ago-render.component';
import {TransferAdjustmentRenderComponent} from '../../../components/products/transfer-adjustment-render.component';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss'],
  providers: [AbsolutizeUrlPipe]
})
export class TransfersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('priceId') priceId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild(DropzoneDirective) dropzone: DropzoneDirective;
  id;
  media;
  form;
  modal;
  closeResult;
  timer;
  term;
  source: TransfersDataSource;
  settings;
  visible = false;
  filtered = true;

  constructor(protected http: HttpClient, private apiService: ApiService, private translate: TranslateService,
              private modalService: NgbModal, private formBuilder: FormBuilder, private toastr: ToastrService,
              public absolutizeUrl: AbsolutizeUrlPipe) { }

  ngOnInit(): void {
    const those = this;
    //
    this.settings = {
      mode: 'external',
      draggable: false,
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      columns: {
        Created: {
          title: this.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateAgoRenderComponent,
          filter: false,
          sort: true
        },
        Adjustment: {
          title: this.translate.instant('Adjustment'),
          type: 'custom',
          renderComponent: TransferAdjustmentRenderComponent,
          filter: false,
          sort: true
        },
        Description: {
          title: this.translate.instant('Description'),
          filter: false,
          sort: true
        },
        Quantity: {
          title: this.translate.instant('Quantity'),
          filter: false,
          sort: true
        },
      },
      pager: {
        display: true,
        perPage: 10,
      }
    };
    this.settings.pager.perPage = 12;
    this.source = new TransfersDataSource(this.http, this.apiService);
    const filters = [];
    if (this.productId) {
      filters.push({
        field: 'ProductId',
        search: String(those.productId)
      });
    }
    if (this.variationId) {
      filters.push({
        field: 'VariationId',
        search: String(those.variationId)
      });
    }
    if (this.priceId) {
      filters.push({
        field: 'PriceId',
        search: String(those.priceId)
      });
    }
    this.source.setFilter(filters, false, false);
    this.source.parent = this;
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy() {

  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  refresh() {
    this.source.refresh();
  }

  onOpen(content) {
    const those = this;
    those.modal = those.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    those.modal.result.then((result) => {
      console.log(`Closed with: ${result}`);
      those.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log(`Dismissed ${those.getDismissReason(reason)}`);
      those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
    });
  }

  /*onSubmit() {
    const those = this;
    this.apiService.getHttp().get(this.apiService.getUrl() + '/fetch?path=' + this.form.get('File').value,
      {observe: 'response', responseType: 'blob'})
      .subscribe((resp) => {
      const data = new FormData();
      data.append('Name', this.form.get('Name').value);
      data.append('Orig', this.form.get('Orig').value);
      data.append('Body', resp.body);
      those.apiService.postMedia(data).then(resp2 => {
        those.success('Create media', resp2);
        those.selected = [resp2];
        if (those.source) {
          those.source.refresh();
        }
        those.toggle('upload');
      }).catch(err => {
        those.error('Create media', err);
      });
    });
    return;
  }*/

  /*onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Orig', this.form.get('Name').value);
    data.append('Body', this.form.get('Body').value);
    // create
    this.apiService.postMedia(data).then(resp => {
      those.success('Create media', resp);
    }).catch(err => {
      those.error('Delete media', err);
    });
  }*/
}
