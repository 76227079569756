<div class="container-fluid" style="padding-top: 30px">
  <div class="row pb-2">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/vendors/' + vendorId]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1 [innerHTML]="('Delivery Time' | translate) + ': ' + (time ? time['Title'] : ('New Time' | translate))"></h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body" style="padding: 15px 15px 0 15px;">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Delivery Time Name</label>
                  <input class="form-control" id="name" type="text" (change)="change($event, 'name')" formControlName="Name" required>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Display Name</label>
                  <input class="form-control" id="title" type="text" (change)="change($event, 'title')" formControlName="Title" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                  <app-media-input id="thumbnail" formControlName="Media"></app-media-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <app-tinymce id="description" [options]="tinymceConfig" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Description"></app-tinymce>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-badge" formControlName="ShowBadge">
                  <label for="show-badge" class="mb-1" translate>ShowBadge</label>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-list" formControlName="ShowList">
                  <label for="show-list" class="mb-1" translate>ShowList</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h2 translate>Position of badge</h2>
              </div>
            </div>
            <div class="row border-bottom">
              <div class="col">
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <input type="radio" class="radio_animated" id="top-left" formControlName="Position" value="top-left">
                      <label for="top-left" class="mb-1" translate>Top Left</label>
                    </div>
                    <div class="col">
                      <input type="radio" class="radio_animated" id="top-right" formControlName="Position" value="top-right">
                      <label for="top-right" class="mb-1" translate>Top Right</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input type="radio" class="radio_animated" id="bottom-left" formControlName="Position" value="bottom-left">
                      <label for="bottom-left" class="mb-1" translate>Bottom Left</label>
                    </div>
                    <div class="col">
                      <input type="radio" class="radio_animated" id="bottom-right" formControlName="Position" value="bottom-right">
                      <label for="bottom-right" class="mb-1" translate>Bottom Right</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col buttons" style="padding: 10px 0">
                <a class="btn btn-cancel" [routerLink]="['/vendors/' + vendorId]" translate>Cancel</a>
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="!form.valid || !form.touched" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span>Save</span>
                </button>
                <button *ngIf="time" class="btn btn-sm btn-default delete" type="button" style="float: right; margin-right: 10px; padding: 8px;" (click)="onDelete()">
                  <svg style="height: 18px; width: 18px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
