import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { v4 } from 'uuid';
import { ITreeOptions, TreeComponent } from 'angular-tree-component';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('tree', { read: TreeComponent }) tree: TreeComponent;
  @ViewChild('categoriesTree', { read: TreeComponent }) categoriesTree: TreeComponent;
  @ViewChild('productsTree', { read: TreeComponent }) productsTree: TreeComponent;
  @ViewChild('pagesTree', { read: TreeComponent }) pagesTree: TreeComponent;
  paramsSubscription: Subscription;
  id;
  menu;
  type;
  form;
  options1: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };
  options2: ITreeOptions = {
    allowDrag: node => node.isLeaf,
    getNodeClone: node => ({
      ...node.data,
      id: v4(),
      name: `copy of ${node.data.name}`,
    }),
  };
  nodes = [];
  categories;
  products;
  pages;
  lock1 = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params.id === 'new') {
          those.form = those.formBuilder.group({
            ID: [''],
            Enabled: [true],
            Title: ['', Validators.required],
            Name: ['', Validators.required],
            Location: ['', Validators.required],
          });
        } else {
          this.id = params['id'];
          this.apiService
            .getMenu(those.id)
            .then(menu => {
              those.menu = menu;
              those.form = those.formBuilder.group({
                ID: [menu['ID']],
                Enabled: [{ disabled: true, value: menu['Enabled'] ? true : false }],
                Title: [{ disabled: true, value: menu['Title'] }, Validators.required],
                Location: [menu['Location'], Validators.required],
                Name: [{ disabled: true, value: menu['Name'] }, Validators.required],
                Products: [''],
                Categories: [''],
                Pages: [''],
              });
              //
              this.nodes = JSON.parse(menu['Description'] || '[]');
              //
              this.apiService
                .getCategories(0, 999, false)
                .then(result => {
                  // Categories
                  those.categories = this.filterCategories(JSON.parse(JSON.stringify(result)));
                  those.categoriesTree.treeModel.update();
                  // Products
                  those.products = this.filterProducts(JSON.parse(JSON.stringify(result)));
                  those.productsTree.treeModel.update();
                })
                .catch(err => {
                  those.error('Get categories', err);
                });
              //
              this.apiService
                .getContents('/', 999)
                .then(result => {
                  // Pages
                  those.pages = this.filterPages(JSON.parse(JSON.stringify(result)));
                  those.pagesTree.treeModel.update();
                })
                .catch(err => {
                  those.error('Get categories', err);
                });
            })
            .catch(err => {
              those.error('Load menu', err);
            });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    console.log('err', err);
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  setFormValue(field, value) {
    this.form.get(field).setValue(value);
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  trim(control) {
    if (control && control.value) {
      control.setValue(control.value.replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  stringify(object) {
    return JSON.stringify(object);
  }

  addCategory() {
    const ids = this.getSelectedNodes(this.categoriesTree);
    if (ids && ids.length) {
      const id = ids[0];
      const node = this.categoriesTree.treeModel.getNodeById(id);
      const value = {
        name: node.data.Title,
        data: {
          type: 'category',
          id: node.data.ID,
          path: node.data.Path,
          name: node.data.Name,
          title: node.data.Title,
        },
        children: [],
      };
      node.setIsSelected(false);
      this.categoriesTree.treeModel.update();
      this.nodes.push(value);
      this.tree.treeModel.update();
    }
  }

  addProduct() {
    const ids = this.getSelectedNodes(this.productsTree);
    if (ids && ids.length) {
      const id = ids[0];
      const node = this.productsTree.treeModel.getNodeById(id);
      const value = {
        name: node.data.Title,
        data: {
          type: 'product',
          id: node.data.ID,
          path: node.data.Path,
          name: node.data.Name,
          title: node.data.Title,
        },
        children: [],
      };
      node.setIsSelected(false);
      this.productsTree.treeModel.update();
      this.nodes.push(value);
      this.tree.treeModel.update();
    }
  }

  addPage() {
    const ids = this.getSelectedNodes(this.pagesTree);
    if (ids && ids.length) {
      const id = ids[0];
      const node = this.pagesTree.treeModel.getNodeById(id);
      const value = {
        name: node.data.Title ? node.data.Title : node.data.Name,
        data: {
          type: 'page',
          title: node.data.Title ? node.data.Title : node.data.Name,
          path: node.data.Path,
        },
        children: [],
      };
      node.setIsSelected(false);
      this.pagesTree.treeModel.update();
      this.nodes.push(value);
      this.tree.treeModel.update();
    }
  }

  addCustomLink(path, title) {
    if (path === '') {
      return;
    }
    const value = {
      name: title.value ? title.value : path.value,
      data: {
        type: 'custom',
        path: path.value,
        title: title.value,
      },
      children: [],
    };
    path.value = '';
    title.value = '';
    this.nodes.push(value);
    this.tree.treeModel.update();
  }

  deleteNode(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete node?';
    modal.componentInstance.body = `Are you sure you want to delete node? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        const node = those.tree.treeModel.getNodeById(id);
        if (node.parent != null) {
          node.parent.data.children.splice(node.parent.data.children.indexOf(node.data), 1);
          those.tree.treeModel.update();
        }
      },
      reason => {}
    );
  }

  filterCategories(tree) {
    let f;
    f = nodes => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++) {
        nodes[i]['id'] = nodes[i]['ID'];
        nodes[i]['type'] = nodes[i]['Type'];
        nodes[i]['name'] = nodes[i]['Title'];
        if (nodes[i]['Type'] === 'category') {
          const children = f(nodes[i]['Children'] || []);
          if (children && children.length > 0) {
            nodes[i]['children'] = children;
          }
          delete nodes[i]['Children'];
          nodes2.push(nodes[i]);
        }
      }
      return nodes2;
    };
    return f(tree['Children']);
  }

  filterProducts(tree) {
    let f;
    f = nodes => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++) {
        nodes[i]['id'] = nodes[i]['Type'] === 'product' ? nodes[i]['ID'] : nodes[i]['ID'] * -1;
        nodes[i]['type'] = nodes[i]['Type'];
        nodes[i]['name'] = nodes[i]['Title'];
        const children = f(nodes[i]['Children'] || []);
        if (children && children.length > 0) {
          nodes[i]['children'] = children;
        }
        nodes2.push(nodes[i]);
      }
      return nodes2;
    };
    return f(tree['Children']);
  }

  filterPages(tree) {
    let f;
    let counter = 1;
    f = nodes => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++) {
        nodes[i]['id'] = counter;
        nodes[i]['ID'] = counter;
        nodes[i]['name'] = nodes[i]['Title'] ? nodes[i]['Title'] : nodes[i]['Name'];
        nodes[i]['Title'] = nodes[i]['Title'];
        nodes[i]['Name'] = nodes[i]['Name'];
        nodes[i]['Path'] = nodes[i]['Path'].replace(/\.html$/i, '/');
        counter++;
        const children = f(nodes[i]['Children'] || []);
        if (children && children.length > 0) {
          nodes[i]['children'] = children;
        }
        nodes2.push(nodes[i]);
      }
      return nodes2;
    };
    return f(tree['Children']);
  }

  getSelectedNodes(tree) {
    const acc = [];
    let f;
    f = nodes => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i]['id']) {
          const node = tree.treeModel.getNodeById(nodes[i]['id']);
          if (node && node.isSelected) {
            acc.push(nodes[i]['id']);
          }
        }
        if (nodes[i]['children']) {
          f(nodes[i]['children']);
        }
      }
    };
    f(tree.treeModel.nodes);
    return acc;
  }

  setSelectedNodes(tree, ids) {
    for (let i = 0; i < ids.length; i++) {
      let node = tree.treeModel.getNodeById(ids[i]);
      if (node) {
        node.setIsSelected(true);
        while (node.parent) {
          node.parent.expand();
          node = node.parent;
        }
      }
    }
  }

  selectNode(id) {
    let node = this.categoriesTree.treeModel.getNodeById(id);
    node.setIsSelected(true);
    while (node.parent) {
      node.parent.expand();
      node = node.parent;
    }
  }

  onChangeCategories2(tree, name, ids, $event) {
    if (($event['eventName'] === 'select' || $event['eventName'] === 'deselect') && !this.lock1) {
      const categories1 = ids.split(/,\s*/).map(item => +item);
      categories1.sort((a, b) => a - b);
      let categories2 = this.getSelectedNodes(tree).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      categories2.sort((a, b) => a - b);
      categories2.forEach(item => {
        if (item !== $event.node.data.ID) {
          this.lock1 = true;
          const node = tree.treeModel.getNodeById(item);
          node.setIsSelected(false);
          this.lock1 = false;
        }
      });
      if ($event['eventName'] === 'select') {
        categories2 = [$event.node.data.ID];
      }
      if (JSON.stringify(categories1) !== JSON.stringify(categories2)) {
        this.form.get(name).setValue(categories2.join(','));
        this.form.get(name).updateValueAndValidity();
        this.form.get(name).markAsDirty();
      }
    }
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    raw['Description'] = JSON.stringify(this.nodes);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postMenu(raw)
        .then(resp => {
          those.success('Create menu', resp);
          those.router.navigate(['/menus/' + resp.ID]);
        })
        .catch(err => {
          those.error('Create menu', err);
        });
    } else {
      // update
      this.apiService
        .putMenu(this.form.get('ID').value, raw)
        .then(resp => {
          those.success('Update menu', resp);
        })
        .catch(err => {
          those.error('Update menu', err);
        });
    }
  }
}
