import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from './api.service';
import {SubjectService} from './subject.service';

@Pipe({ name: 'absolutizeUrl' })
export class AbsolutizeUrlPipe implements PipeTransform {
  constructor(private apiService: ApiService, private subjectService: SubjectService) {}
  transform(value) {
    try {
      if (value.match(/^\/\//)) {
        // short full
        return value;
      } else if (value.match(/^https?:\/\//i)) {
        // full
        return value;
      } else if (value.match(/^\//)) {
        // absolute
        const info = this.subjectService.infoSubject.getValue();
        if (info.storagePublicUrl) {
          return info.storagePublicUrl + encodeURI(value).replace(/\+/, '%2B');
        }
      } else {
        // relative
        const pathname = window.location.pathname;
        const info = this.subjectService.infoSubject.getValue();
        if (info.StoragePublicUrl) {
          //debugger;
          const folder = pathname.substring(0, pathname.lastIndexOf('/'));
          return (
            info.StoragePublicUrl +
            (folder ? folder + '/' : folder) +
            value.replace(/\+/, '%2B')
          );
        }
      }
    }catch(err) {
      //console.log('AbsolutizeUrlPipe transform error', err);
    }
    return value;
  }
}

@Pipe({ name: 'mediaThumbnail' })
export class MediaThumbnailPipe implements PipeTransform {
  constructor(private apiService: ApiService, private subjectService: SubjectService) {}
  transform(value) {
    let media;
    if (typeof value === 'object') {
      media = value;
    }else if (typeof value === 'string') {
      try {
        media = JSON.parse(value);
      }catch (err) {
        console.log(err);
      }
    }

    if (typeof media.Resize === 'string') {
      try {
        media.Resize = JSON.parse(media.Resize);
      }catch (err) {
        console.log(err);
      }
    }

    if (media.Resize) {
      const keys = Object.keys(media.Resize);
      const array = [];
      for (let i = 0; i < keys.length; i++) {
        const resolution = keys[i].split('x');
        array.push({src: media.Resize[keys[i]], width: resolution[0], height: resolution[1]});
      }
      array.sort((a, b) => {
        if (a.width !== b.width) {
          return a.width - b.width;
        } else {
          return a.height - b.height;
        }
      });
      if (array.length > 0) {
        const info = this.subjectService.infoSubject.getValue();
        if (info?.StoragePublicUrl) {
          const url = new URL(info.StoragePublicUrl + array[0].src);
          if (media.Updated) {
            url.searchParams.set('u', new Date(media.Updated).getTime().toString(36));
          }
          return url.toString();
        } else {
          return array[0].src;
        }
      }
    }else if (media.Path) {
      const info = this.subjectService.infoSubject.getValue();
      if (info?.StoragePublicUrl) {
        const url = new URL(info.StoragePublicUrl + media.Path);
        if (media.Updated) {
          url.searchParams.set('u', new Date(media.Updated).getTime().toString(36));
        }
        return url.toString();
      } else {
        return media.Path;
      }
    }else {
      return '/assets/images/no-image.svg';
    }
    return value;
  }
}

type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
};

@Pipe({ name: 'humanizeSize' })
export class HumanizeSizePipe implements PipeTransform {
  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }
}

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    console.log('items', items, 'filter', filter);
    if (!items || !filter) {
      return items;
    }
    const keys = Object.keys(filter);
    console.log('keys', keys);
    return items.filter(item => {
      let allowed = keys && keys.length > 0;
      console.log('allowed1', allowed);
      for (const key of keys) {
        console.log(
          'key',
          key,
          'item[key]',
          item[key],
          'filter[key]',
          filter[key],
          'item[key] !== filter[key]',
          item[key] !== filter[key]
        );
        if (item[key] !== filter[key]) {
          allowed = false;
          break;
        }
      }
      console.log('allowed2', allowed);
      return allowed;
    });
  }
}

@Pipe({
  name: 'highlight',
  pure: false,
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, args: any, type: string): unknown {
    if (!args) return value;
    if (type === 'full') {
      const re = new RegExp(`\\b(` + args + `\\b)`, 'igm');
      value = value.replace(re, '<span class="highlighted-text">$1</span>');
    } else {
      const re = new RegExp(args, 'igm');
      value = value.replace(re, '<span class="highlighted-text">$&</span>');
    }

    return value;
  }
}
