import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../shared/api.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImagesDataSource} from '../../../components/medias/images/images.data-source';
import {ImageNameRenderComponent} from '../../../components/medias/images/image-name-render.component';
import {ImageSizeRenderComponent} from '../../../components/medias/images/image-size-render.component';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {DateRenderComponent} from '../../render/date-render.component';

@Component({
  selector: 'app-edit-images',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" translate>
          Media Library
        </h5>
        <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2 col-sm-3">
            <ul class="nav-left-sidebar">
              <li>
                <svg style="margin-right:5px; height: 20px; width: 20px;vertical-align: middle">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-download"></use>
                </svg>
                <span style="vertical-align: middle" translate>Upload</span>
              </li>
              <li>
                <svg style="margin-right:5px; height: 20px; width: 20px; vertical-align: middle">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-img"></use>
                </svg>
                <span style="vertical-align: middle" translate>Media Library</span>
              </li>
              <li>
                <svg style="margin-right:5px; height: 20px; width: 20px; vertical-align: middle">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-popup-link"></use>
                </svg>
                <span style="vertical-align: middle" translate>From URL</span>
              </li>
            </ul>
          </div>
          <div class="col-md-10 col-sm-9">
            <div id="batchDelete" class="images-table custom-datatable">
              <div class="table-responsive" [ngClass]="!visible?'transparent':''">
                <ng2-smart-table (custom)="onCustomAction($event.data['ID'])" [settings]="settings"
                                 [source]="source"></ng2-smart-table>
                <div *ngIf="source && source.count() > 0" class="records-count">
            <span *ngIf="source.filtered() != source.total()">
              {{'Filtered' | translate}}
              : {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
            </span>
                  <span *ngIf="source.filtered() == source.total()">
              {{'Records' | translate}}: {{source.total()}}
            </span>
                </div>
              </div>
              <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img
                src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
            </div>
          </div>
        </div>
        <!-- -->
        <!--div class="btn-popup pull-right">
          <app-edit-image [productId]="id" #editor>
          </app-edit-image>
        </div-->
      </div>
    </ng-template>
  `,
  styles: [`span {
    font-weight: bolder;
  }
  `],
  providers: [AbsolutizeUrlPipe]
})
export class ImagesComponent implements OnInit, AfterViewInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  id;
  image;
  modal;
  closeResult;
  timer;
  source: ImagesDataSource;
  settings;
  visible = false;

  constructor(protected http: HttpClient, private apiService: ApiService, private translate: TranslateService, private modalService: NgbModal, private formBuilder: FormBuilder, private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe) {

  }

  ngOnInit(): void {
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'yourAction',
            title: '<span class="btn btn-primary btn-xs">' + this.translate.instant('Add') + '</span>'
          },
        ],
        columnTitle: this.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        ID: {
          title: 'Id',
        },
        Created: {
          title: this.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
        Name: {
          title: this.translate.instant('Name'),
          type: 'custom',
          renderComponent: ImageNameRenderComponent,
        },
        Size: {
          title: this.translate.instant('Size'),
          type: 'custom',
          renderComponent: ImageSizeRenderComponent,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      }
    };
    this.source = new ImagesDataSource(this.http, this.apiService, this.absolutizeUrl);
    this.source.parent = this;
    //this.source.arguments = this.arguments;
  }

  ngAfterViewInit(): void {
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onOpen(content) {
    const those = this;
    //
    those.modal = those.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'});
    those.modal.result.then((result) => {
      console.log(`Closed with: ${result}`);
      those.closeResult = `Closed with: ${result}`;
      those.onSaved.emit();
    }, (reason) => {
      console.log(`Dismissed ${those.getDismissReason(reason)}`);
      those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      those.onSaved.emit();
    });
  }

  onCustomAction(id) {
    if (this.productId) {
      this.apiService.patchProduct(this.productId, 'addImage', {Image: id}).then(resp => {
        this.success('Add image', resp);
        this.onSaved.emit();
      }).catch(err => {
        this.error(this.translate.instant('Add image'), err);
      });
    } else if (this.variationId) {
      this.apiService.patchVariation(this.variationId, 'addImage', {Image: id}).then(resp => {
        this.success('Add image', resp);
        this.onSaved.emit();
      }).catch(err => {
        this.error(this.translate.instant('Add image'), err);
      });
    }
  }
}
