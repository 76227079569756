<div class="container-fluid" style="padding-top: 30px">
  <div class="row pb-2">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/vendors']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1 [innerHTML]="('Vendor' | translate) + ': ' + (vendor ? vendor['Title'] : ('New Vendor' | translate))"></h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!loading" class="col-md-8">
      <div class="card">
        <div class="card-body" style="padding: 15px 15px 0 15px;">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Vendor Name</label>
                  <input class="form-control" id="name" type="text" (change)="change($event, 'name')" formControlName="Name">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Display Name</label>
                  <input class="form-control" id="title" type="text" (change)="change($event, 'title')" formControlName="Title">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                  <app-media-input id="thumbnail" formControlName="Media"></app-media-input>
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="margin-bottom: 30px;">
              <div class="col">
                <div class="form-group">
                  <label for="content" class="mb-1" translate>Content</label>
                  <app-tinymce id="content" [options]="tinymceConfig" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Content"></app-tinymce>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-name" formControlName="ShowName">
                  <label for="show-name" class="mb-1" translate>ShowName</label>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-logo" formControlName="ShowLogo">
                  <label for="show-logo" class="mb-1" translate>ShowLogo</label>
                </div>
              </div>
            </div>
            <div *ngIf="vendor">
              <div class="row">
                <div class="col">
                  <h2 style="padding-bottom: 10px" translate>Delivery time</h2>
                </div>
              </div>
              <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 10px;">
                <div class="col">
                  <div class="card-fluid-block">
                    <table *ngIf="form && form.get('Times') && form.get('Times').length > 0" class="table times">
                      <thead style="text-align: center">
                      <tr><th translate>ID</th><th translate>Name</th><th translate>Badge</th><th translate>List</th><th translate>Status</th><th translate></th></tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let time of vendor.Times; let i = index;">
                        <td>
                          <a [routerLink]="['/vendors/' + vendor.ID + '/times/' + time.ID]">{{time.ID}}</a>
                        </td>
                        <td>
                          {{time.Title}}
                        </td>
                        <td>
                          <input type="checkbox" class="checkbox_animated" (change)="patchTimeField(time.ID,'ShowBadge', !time.ShowBadge)" [checked]="time.ShowBadge">
                        </td>
                        <td>
                          <input type="checkbox" class="checkbox_animated" (change)="patchTimeField(time.ID, 'ShowList', !time.ShowList)" [checked]="time.ShowList">
                        </td>
                        <td>
                          <div class="button-group-dropdown" style="display: inline-block">
                            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="time.Enabled? 'status-active' : 'status-inactive'">
                              <span [innerHTML]="time.Enabled ? 'active' : 'inactive' | translate"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li (click)="patchTimeField(time.ID, 'Enabled', true)">
                                Active
                              </li>
                              <li (click)="patchTimeField(time.ID, 'Enabled', false)">
                                Inactive
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td style="text-align: right">
                          <span title="delete" class="btn-sm btn-default facon-minus"
                                style="font-size: 16px;" (click)="removeTime(time.ID)"></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style="padding-top: 20px">
                    <a class="btn-sm btn-default facon-plus" [routerLink]="['/vendors/' + vendor.ID + '/times/new']" translate> Add another delivery time</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col buttons" style="padding: 10px 0">
                <a class="btn btn-cancel" [routerLink]="['/vendors']" translate>Cancel</a>
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="!form.valid || !form.touched" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span>Save</span>
                </button>
                <button *ngIf="vendor" class="btn btn-sm btn-default delete" type="button" style="float: right; margin-right: 10px; padding: 8px;" (click)="onDelete()">
                  <svg style="height: 18px; width: 18px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="loading" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
  </div>
</div>
