import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [`.new{
    background-color: green;
    border-radius: 5px;
    color: white;
    padding: 0 5px;
  }`],
  template: `
    <div class="price-dynamic" [title]="value">{{value}}</div>
    <span *ngIf="this.rowData['CreatedAt'] === this.rowData['UpdatedAt'] && index === 0" class="new">new</span>
  `,
})
export class PriceDynamicRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;
  index: number;

  ngOnInit() { }
}
