import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../../shared/utils.pipe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../shared/api.service';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SubjectService } from '../../../../shared/subject.service';
import { credentials } from '../../../../shared/credentials.data';
import { ConfirmComponent } from '../../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [AbsolutizeUrlPipe, NgbTypeaheadConfig],
})
export class CustomerComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: true }) instance;
  paramsSubscription: Subscription;
  id;
  customer;
  form;
  types = ['Shipping']; // ['Billing', 'Shipping']
  subscription: Subscription;
  formSubscription: Subscription;

  countries = credentials.countries;

  author = 'me';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params.id === 'new') {
          this.form = this.createForm({});
        } else {
          this.id = params.id;
          this.apiService
            .getCustomer(this.id)
            .then(customer => {
              those.customer = customer;
              those.form = those.createForm(customer);
            })
            .catch(err => {
              those.error('Load customer', err);
            });
        }
      }
    });
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info && info.User && info.User.Login) {
        those.author = info.User.Login;
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  createForm(customer) {
    /*let splitProfiles = false;
    if (customer.BillingProfiles && customer.ShippingProfiles && customer.BillingProfiles.length > 0 && customer.ShippingProfiles.length > 0) {
      splitProfiles = JSON.stringify(customer['BillingProfiles'].map(p => { const profile = {...p}; delete profile.ID; return profile; }))
        !== JSON.stringify(customer['ShippingProfiles'].map(p => { const profile = {...p}; delete profile.ID; return profile; }));
    }
    //
    if (splitProfiles) {
      this.types = ['Billing', 'Shipping'];
    }else{
      this.types = ['Shipping'];
    }*/
    if (
      customer.BillingProfiles &&
      customer.ShippingProfiles &&
      customer.BillingProfiles.length > 0 &&
      customer.ShippingProfiles.length > 0
    ) {
      this.types = ['Billing', 'Shipping'];
    }
    const form = this.formBuilder.group({
      ID: [customer['ID']],
      Enabled: [customer['Enabled'] ? true : false],
      Name: [customer['Name'], Validators.required],
      Lastname: [customer['Lastname'], Validators.required],
      Company: [customer['Company']],
      Email: [customer['Email']],
      Phone: [customer['Phone']],
      AllowReceiveEmails: [customer['AllowReceiveEmails']],
      AllowReceiveSMS: [customer['AllowReceiveSMS']],
      SplitProfiles: [false],
      BillingProfiles: this.formBuilder.array([]),
      ShippingProfiles: this.formBuilder.array([]),
      Notes: [customer['Notes']],
      Tags: new FormControl([]),
    });
    let hasProfile = false;
    // Billing
    if (customer.BillingProfiles && customer.BillingProfiles.length > 0) {
      const profiles = <FormArray>form.get('BillingProfiles');
      customer.BillingProfiles.forEach((profile, i) => {
        profiles.push(this.createProfile(profile));
        hasProfile = true;
      });
    }
    // Shipping
    if (customer.ShippingProfiles && customer.ShippingProfiles.length > 0) {
      const profiles = <FormArray>form.get('ShippingProfiles');
      customer.ShippingProfiles.forEach((profile, i) => {
        profiles.push(this.createProfile(profile));
        hasProfile = true;
      });
    }
    if (!hasProfile) {
      const profiles = <FormArray>form.get('ShippingProfiles');
      profiles.push(this.createProfile({}));
    }
    //
    if (customer['Tags']) {
      form.get('Tags').setValue(customer['Tags']);
    }
    //
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    this.formSubscription = form.valueChanges.subscribe(x => {
      form.markAsTouched();
    });
    return form;
  }

  createProfile(profile) {
    return this.formBuilder.group({
      ID: [profile['ID']],
      Name: [profile['Name'], Validators.required],
      Lastname: [profile['Lastname'], Validators.required],
      Company: [profile['Company']],
      Phone: [profile['Phone']],
      Country: [profile['Country'], Validators.required],
      Address: [profile['Address'], Validators.required],
      City: [profile['City'], Validators.required],
      Zip: [profile['Zip'], Validators.required],
    });
  }

  back(pitfall) {
    const state = this.location.getState();
    if (state && state['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate([pitfall]);
    }
  }

  toggle() {
    const those = this;
    const value = this.form.get('SplitProfiles').value;
    if (value) {
      this.types = ['Billing', 'Shipping'];
      const profiles = this.form
        .get('ShippingProfiles')
        .getRawValue()
        .map(item => {
          const profile = { ...item };
          delete profile.ID;
          return profile;
        });
      for (let i = 0; i < profiles.length; i++) {
        this.form.get('BillingProfiles').push(this.createProfile(profiles[i]));
      }
    } else {
      this.types = ['Billing'];
    }
  }

  setFormValue(field, value) {
    this.form.get(field).setValue(value);
  }

  change(field, event) {
    const profiles = this.form.get('ShippingProfiles').controls;
    if (profiles && profiles.length === 1 && event.target.value) {
      const profile = profiles.at(0);
      const control = profile.get(field);
      if (field === 'Phone') {
        // Phone
        const value = this.form.get('Phone').value;
        for (let i = value.length - 1; i > 0; i--) {
          const country = this.countries.find(item => item.Phone === value.substr(0, i));
          if (country) {
            profile.get('Country').setValue(country.Code.toLowerCase());
            break;
          }
        }
        if (control && !control.value) {
          control.setValue(value);
        }
      } else {
        // all another fields
        if (control && !control.value) {
          control.setValue(event.target.value);
        }
      }
    }
  }

  debug(argument) {
    console.log('debug', argument);
  }

  trim(control) {
    if (control) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  addAddress(type, profile) {
    const those = this;
    const field = type + 'Profiles';
    const profiles = <FormArray>this.form.get(field);
    profiles.push(
      those.formBuilder.group({
        ID: [],
        Name: [profile.Name ? profile.Name : '', Validators.required],
        Lastname: [profile.Lastname ? profile.Lastname : '', Validators.required],
        Company: [profile.Company ? profile.Company : ''],
        Phone: [profile.Phone ? profile.Phone : ''],
        Country: [profile.Country ? profile.Country : ''],
        Address: [profile.Address ? profile.Address : ''],
        City: [profile.City ? profile.City : ''],
        Zip: [profile.Zip ? profile.Zip : ''],
      })
    );
  }

  removeAddress(type, index, event) {
    const those = this;
    const field = type + 'Profiles';
    if (index >= 0) {
      const value = this.form.get(field).at(index).value;
      if (value.ID) {
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        modal.componentInstance.title = 'Delete address?';
        modal.componentInstance.body = `Are you sure you want to delete address? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            those.apiService
              .deleteProfile(type.toLowerCase(), value.ID)
              .then(resp => {
                those.success('Delete profile', resp);
                those.form.get(field).removeAt(index);
              })
              .catch(err => {
                those.error('Delete profile', err);
              });
          },
          reason => {}
        );
      } else {
        those.form.get(field).removeAt(index);
      }
      event.preventDefault();
    }
  }

  addNote(note) {
    console.log('addNote', note);
    const those = this;
    if (note && note.value) {
      this.customer.Notes = this.customer.Notes || [];
      this.customer.Notes.unshift({ Created: new Date(), Author: those.author, Note: note.value });
      this.apiService
        .patchCustomer(this.customer.ID, 'setNotes', { Value: this.customer.Notes })
        .then(resp => {
          note.value = '';
        })
        .catch(err => {
          those.error('add note', err);
        });
    }
  }

  deleteNote(index) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete note?';
    modal.componentInstance.body = `Are you sure you want to delete note? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.customer.Notes.splice(index, 1);
        those.apiService
          .patchCustomer(those.customer.ID, 'setNotes', { Value: those.customer.Notes })
          .then(resp => {})
          .catch(err => {
            those.error('delete note', err);
          });
      },
      reason => {}
    );
  }

  onDelete() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete customer?';
    modal.componentInstance.body = `Are you sure you want to delete customer? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteCustomer(those.customer.ID)
          .then(resp => {
            those.success('Delete customer', resp);
            those.router.navigate(['/sales/customers']);
          })
          .catch(err => {
            those.error('Delete customer', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    if (raw.BillingProfiles.length === 0 && raw.ShippingProfiles.length > 0) {
      raw.BillingProfiles = raw.ShippingProfiles.map(item => {
        const profile = { ...item };
        delete profile.ID;
        return profile;
      });
    } else if (raw.ShippingProfiles.length === 0 && raw.BillingProfiles.length > 0) {
      raw.ShippingProfiles = raw.BillingProfiles.map(item => {
        const profile = { ...item };
        delete profile.ID;
        return profile;
      });
    }
    /*if (!raw.SplitProfiles) {
      for (let i = 0; i < raw.ShippingProfiles.length; i++) {
        const profile = {...raw.ShippingProfiles[i]};
        if (raw.BillingProfiles[i]){
          profile.ID = raw.BillingProfiles[i].ID;
        }
        raw.BillingProfiles[i] = profile;
      }
    }*/
    raw.Tags = this.form
      .get('Tags')
      .value.map(item => item.ID)
      .join(',');
    console.log('raw', raw);
    //return;
    if (!this.customer) {
      // create
      this.apiService
        .postCustomer(raw)
        .then(resp => {
          those.success('Create customer', resp);
          those.router.navigate(['/sales/customers/' + resp.ID]);
        })
        .catch(err => {
          those.error('Create customer', err);
        });
    } else {
      // update
      this.apiService
        .putCustomer(this.customer.ID, raw)
        .then(resp => {
          those.customer = resp;
          those.createForm(those.customer);
          those.success('Update customer', resp);
        })
        .catch(err => {
          those.error('Update customer', err);
        });
    }
  }
}
