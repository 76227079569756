import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

export interface ILocation {
  Name: string;
  Label: string;
}

@Component({
  selector: 'app-block-inline',
  templateUrl: './block-inline.component.html',
  styleUrls: ['./block-inline.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class BlockInlineComponent implements OnInit, OnDestroy {
  @Input() events: Observable<{}>;
  @Input() form;
  @Input() block;
  @Input() locations: ILocation[] = [
    { Name: 'product', Label: 'Product' },
    { Name: 'variation', Label: 'Variation' },
  ];
  @Input() tinymceConfig;
  custom = false;
  texts: IMultiSelectTexts = {
    defaultTitle: 'select post',
  };
  posts = [];
  options: IMultiSelectOption[];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    closeOnSelect: true,
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 5,
    selectionLimit: 1,
  };

  constructor(
    private formBuilder: FormBuilder,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private apiService: ApiService,
    private toastr: ToastrService,
    public router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const those = this;
    this.custom = this.form.get('Custom').value;
    if (!this.custom) {
      this.apiService
        .getPosts()
        .then(posts => {
          those.posts = posts;
          those.options = posts.map(item => ({ id: item.ID, name: item.Title }));
        })
        .catch(err => {
          those.error('Get posts', err);
        });
    }
    this.texts.defaultTitle = this.translate.instant('(Select post from library)');
  }

  ngOnDestroy() {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  change(event) {
    if (event && event.length > 0) {
      const post = this.posts.find(item => item.ID === event[0]);
      if (post) {
        const title = this.form.get('Title');
        if (!title.value) {
          title.setValue(post.Title);
        }
        (this.form.get('Post') as FormGroup).patchValue(post);
      }
    }
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Media', this.form.get('Media').value);
    data.append('Content', this.form.get('Content').value);
    this.apiService
      .putPost(this.form.get('ID').value, data)
      .then(resp => {})
      .catch(err => {
        those.error('Update template', err);
      });
  }
}
