import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';

@Component({
  template: `
    <div style="vertical-align: middle">
      <a class="link" [routerLink]="[prefix + rowData['ID']]" style="cursor: pointer">{{ value }}</a>
    </div>
  `,
})
export class NameRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  parent;
  prefix;

  constructor() {}

  ngOnInit() {
    if (this.parent) {
      this.prefix = `/${this.parent.type}/`;
    }
  }
}
