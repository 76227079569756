import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../shared/api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  styles: [`

  `],
  template: `
    <label class="switch_animated">
      <input type="checkbox" (ngModelChange)="patch($event)" [(ngModel)]="checked">
      <span></span>
    </label>
  `,
})
export class CategoryEnabledRenderComponent implements ViewCell, OnInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  checked;

  constructor(public apiService: ApiService, private translate: TranslateService){

  }

  ngOnInit() {
    this.checked = this.value ? true : false;
  }

  patch(value) {
    this.apiService.patchCategory(this.rowData['ID'], 'setEnable',{Enabled: value}).then(resp => {
        /*for (let i = 0; i < this.parent.source.data.length; i++) {
          if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
            this.parent.source.data[i]['Enabled'] = value === true;
            this.parent.source.local = true;
            this.parent.source.refresh();
            break;
          }
        }*/
      }).catch(err => {
        console.log(err);
      });
  }
}
