<div class="container-fluid">
  <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="card-body">
        <div class="form">
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="enabled">Category Status</label>
                <select id="enabled" class="form-control" formControlName="Enabled">
                  <option [value]="true" translate>Active</option>
                  <option [value]="false" translate>Inactive</option>
                </select>
              </div>
              <div class="form-group">
                <label for="title" class="mb-1" translate>Title</label>
                <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title">
              </div>
              <div class="form-group">
                <label for="name" class="mb-1" translate>Page URL</label>
                <input class="form-control" id="name" type="text" formControlName="Name">
              </div>
              <div class="form-group">
                <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                <!--app-thumbnail-input id="thumbnail" formControlName="Thumbnail" [updated]="category?.UpdatedAt"></app-thumbnail-input-->
                <app-media-input id="thumbnail" formControlName="Media"></app-media-input>
              </div>
              <div class="form-group">
                <label for="description" class="mb-1" translate>Description</label>
                <textarea class="form-control" id="description" rows="3" formControlName="Description"></textarea>
              </div>
              <div class="form-group">
                <label for="parent" class="mb-1" translate>Parent</label>
                <div id="parent">
                  <div class="form-group">
                    <div style="padding-bottom: 10px">
                      <div style="display:inline-block;padding-right: 5px;width: 50%"><input class="form-control" type="text" placeholder="{{'csv list' | translate}}" (change)="onChangeCategories1(categoriesTree, form.get('Categories').value, $event)" formControlName="Categories"></div>
                      <div style="display:inline-block;padding-left: 5px;width: 50%"><input class="form-control" type="text" placeholder="{{'search' | translate}}" (keyup)="categoriesTree.treeModel.filterNodes(filter.value)" #filter/></div>
                    </div>
                    <div class="product-categories">
                      <tree-root class="expand-tree" [nodes]="categories" [options]="options" (event)="onChangeCategories2(categoriesTree,'Categories', form.get('Categories').value, $event)" #categoriesTree>
                        <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                          <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                            <tree-node-expander [node]="node"></tree-node-expander>
                            <tree-node-checkbox *ngIf="!category || category['ID'] != node.data.id" [node]="node"></tree-node-checkbox>
                            <div class="node-content-wrapper"
                                 [class.node-content-wrapper-active]="node.isActive"
                                 [class.node-content-wrapper-focused]="node.isFocused"
                                 (click)="node.isExpanded ? node.collapse() : node.expand()"
                                 (dblclick)="node.mouseAction('dblClick', $event)"
                                 (contextmenu)="node.mouseAction('contextMenu', $event)"
                                 (treeDrop)="node.onDrop($event)"
                                 [treeAllowDrop]="node.allowDrop"
                                 [treeDrag]="node"
                                 [treeDragEnabled]="node.allowDrag()">
                              <tree-node-content [node]="node" [index]="index"></tree-node-content>
                              <span style="padding-left: 5px">({{ node.data.id }})</span>
                            </div>
                          </div>
                        </ng-template>
                      </tree-root>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="d-block" for="show">
                      <input class="checkbox_animated" id="show" type="checkbox" formControlName="Show">
                      {{'Show empty' | translate}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="form-group">
                <label for="content" class="mb-1" translate>Content</label>
                <app-tinymce id="content" [options]="config" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Content"></app-tinymce>
              </div>
              <div class="form-group">
                <label for="content" class="mb-1" translate>Location</label>
                <select class="form-control" formControlName="Location">
                  <option value="sidebar">Sidebar</option>
                  <option value="footer">Footer</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group" style="text-align: center">
                <button class="btn btn-default" style="margin-left: 5px" type="button" (click)="back()" translate>Back</button>
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="loading" style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>

