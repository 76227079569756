import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SelectComponent
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, Validator {
  @Input() options: { Name: string, Label: string }[];
  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  value: string;

  constructor() { }

  change() {  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  getLabel(name: string) {
    if (this.options) {
      const index = this.options.findIndex(item => item.Name === name);
      if (index >= 0) {
        return this.options[index].Label;
      }
    }
    return this.options[0].Label;
  }

  setValue(value: string) {
    this.value = value;
    this.markAsTouched();
    this.onChange(this.value);
  }
}
