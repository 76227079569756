import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  styles: [
    `
      div {
        text-align: center;
        width: 100%;
      }
    `,
  ],
  template: ` <div>{{ theValue }}</div> `,
})
export class NumberRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  theValue = '0';

  ngOnInit(): void {
    this.theValue = Number(this.value).toFixed(0);
  }
}
