import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { credentials } from '../../../shared/credentials.data';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-properties-block',
  styles: [
    `
      #properties-accordion .collapse .card-body,
      #properties-accordion .collapsing .card-body {
        background-color: white;
      }
      .accordion > .card {
        margin-bottom: 5px !important;
      }
      .card-header .col {
        margin-top: auto;
        margin-bottom: auto;
      }
      .property-inline-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .property-inline-option,
      .property-inline-type {
        font-size: small;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .property-inline-rates-item:not(:last-child)::after {
        content: ' / ';
      }
      .property-inline-rates-more {
        font-style: italic;
        color: gray;
        font-size: smaller;
      }
    `,
  ],
  template: `
    <div class="card" style="margin-bottom: 20px;">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 20px" translate>Options</h2>
          </div>
        </div>
        <div
          *ngIf="form.controls['Properties'] && form.controls['Properties']['controls'].length > 0; else noproperties">
          <div class="row">
            <div class="col">
              <div
                id="properties-accordion"
                class="accordion"
                style="padding: 0 0 20px 0;"
                [dragula]="'properties'"
                [(dragulaModel)]="form.controls['Properties']['controls']">
                <div
                  *ngFor="let propertyControl of form.controls['Properties']['controls']; let i = index"
                  class="card inline-card draggable-wrapper">
                  <div
                    class="card-header inline-heading inline-heading-property"
                    [attr.aria-invalid]="'property-heading-' + i">
                    <div style="display: flex; align-items: center; width: 30%">
                      <div class="btn-sm btn-default" style="height: 26px; padding-left: 2px;">
                        <svg
                          class="fill-black draggable draggable-point draggable-property-point"
                          style="height: 16px; width: 16px;">
                          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-bid"></use>
                        </svg>
                      </div>
                      <div
                        class="property-inline-title accordion-link"
                        data-toggle="collapse"
                        [attr.data-target]="'#property-collapse-' + i"
                        [attr.aria-controls]="'property-collapse-' + i"
                        [title]="propertyControl.get('Title').value">
                        {{ propertyControl.get('Title').value || ('New' | translate) }}
                      </div>
                    </div>
                    <div style="width: 20%">
                      <div
                        *ngIf="propertyControl.get('OptionId').value"
                        class="property-inline-option color-text-gray cursor-pointer"
                        style="padding-left: 5px"
                        [title]="propertyControl.get('Option').get('Title').value"
                        (click)="navigate('/options/' + propertyControl.get('Option').get('ID').value)">
                        {{ propertyControl.get('Option').get('Title').value }}
                      </div>
                    </div>
                    <div style="width: 20%">
                      <div class="property-inline-type" [title]="propertyControl.get('Type').value">
                        {{ propertyControl.get('Type').value }}
                      </div>
                    </div>
                    <div style="width: 30%">
                      <div
                        *ngIf="getRawValue(propertyControl) as property"
                        style="font-size: small; text-align: center;">
                        <div *ngIf="property['Rates']" class="property-inline-rates">
                          <span
                            *ngFor="let rate of slice(property['Rates'], 3); let j = index"
                            class="property-inline-rates-item"
                            >{{ rate['Value'] ? rate['Value']['Title'] : '' }}</span
                          >
                        </div>
                        <div
                          *ngIf="property['Rates'] && property['Rates'].length > 3"
                          class="property-inline-rates-more">
                          <span
                            [innerHtml]="
                              '+ (' + (property['Rates'].length - 3) + ' ' + ('values' | translate) + ')'
                            "></span>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; justify-content: end;margin-right: 5px;">
                      <div class="inline-heading-buttons">
                        <div class="btn-sm btn-default delete" (click)="deleteProperty(i)">
                          <svg style="height: 16px; width: 16px;">
                            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    [attr.id]="'property-collapse-' + i"
                    class="collapse"
                    [attr.aria-labelledby]="'property-heading-' + i"
                    data-parent="#properties-accordion">
                    <div class="card-body" style="padding: 15px 15px 0px 15px">
                      <app-property-inline
                        [form]="form.get('Properties')['at'](i)"
                        [index]="i"
                        [createBlockForm]="createBlockForm"
                        [createRateForm]="createRateForm"
                        [basePrice]="basePrice"
                        [salePrice]="salePrice"
                        [tinymceConfig]="tinymceConfig"
                        (onSaved)="refresh('properties')"
                        (onSubmit)="onSubmit.emit($event)"
                        (onDelete)="deleteProperty(i)"></app-property-inline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div style="display: flex; gap: 15px;">
                <span class="btn btn-third facon-plus" (click)="addProperty()" translate>Add Option</span>
                <span class="btn btn-third facon-plus" (click)="addProperty(true)" translate
                  >Add Option From Library</span
                >
              </div>
            </div>
          </div>
        </div>
        <ng-template #noproperties>
          <div class="row">
            <div class="col">
              <div style="text-align: center">
                <svg class="fill-primary-violet" style="height: 50px; width: 50px;">
                  <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiSelectMultiple"></use>
                </svg>
                <div class="body-3 color-text-gray" style="padding: 10px 0 20px 0">
                  <span translate>You can add different types of options to the product</span><br />
                  <span translate>Add as many (or as few) options as you need</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mb-3 text-center">
              <span class="btn btn-third facon-plus" (click)="addProperty()" translate>Add Option</span>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <span class="btn btn-third facon-plus" (click)="addProperty(true)" translate
                >Add Option From Library</span
              >
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  `,
})
export class PropertiesBlockComponent implements OnInit, OnDestroy {
  @Input() productId = 0;
  @Input() variationId = 0;
  @Input() basePrice;
  @Input() salePrice;
  @Input() properties = [];
  @Input() form: FormGroup;
  @Input() info;
  @Input() tinymceConfig;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();
  decimal = ',';
  prefix = credentials.currency + ' ';
  thousands = '.';
  touched = false;
  saving = false;
  dragulaSubscription = new Subscription();
  timeout;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dragulaService: DragulaService,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    const those = this;
    if (this.info) {
      this.decimal = this.info['Decimal'] ? this.info['Decimal'] : '.';
      this.thousands = this.info['Thousands'] ? this.info['Thousands'] : '';
      this.prefix = this.info['Symbol'] ? this.info['Symbol'] : '$';
    }
    //
    this.form.addControl(
      'Properties',
      this.formBuilder.array(
        (those.properties || []).map(property => {
          return those.createPropertyForm(property);
        }),
        []
      )
    );
    //
    this.dragulaService.createGroup('properties', {
      moves: (el, container, handle) => {
        const classes =
          (handle.className &&
            handle.className['baseVal'] &&
            handle.className['baseVal']['split'] &&
            handle.className['baseVal'].split(/\s+/)) ||
          [];
        if (classes.indexOf('draggable') !== -1 && classes.indexOf('draggable-rate-point') < 0) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
    this.dragulaSubscription.add(
      this.dragulaService.drop('properties').subscribe(({ name, el, sibling }) => {
        if (el) {
          let element = el.parentNode.firstChild;
          do {
            if (element.nodeType === 3) continue; // text node
            const classList = (element as Element).classList;
            if (classList && classList.contains('draggable-wrapper')) {
              if (classList.contains('gu-transit')) {
                classList.add('draggable-dropped');
              } else {
                classList.remove('draggable-dropped');
              }
            }
          } while ((element = element.nextSibling));
          if (those.timeout) {
            clearTimeout(those.timeout);
            those.timeout = undefined;
          }
          those.timeout = setTimeout(() => {
            el?.classList?.remove('draggable-dropped');
          }, 10000);
        }
        those.form.get('Properties').updateValueAndValidity({ onlySelf: false, emitEvent: true });
        those.form.get('Properties').markAsDirty();
      })
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('properties');
    this.dragulaSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    console.log('err', err);
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  createPropertyForm(property) {
    console.log('createPropertyForm', property);
    const those = this;
    const form = this.formBuilder.group({
      ID: [property.ID],
      Name: [property.Name],
      Title: [property.Title],
      OptionId: [property.OptionId >= 0 ? [property.OptionId] : undefined],
      Option: this.formBuilder.group({
        ID: [property.Option?.ID],
        Name: [property.Option?.Name],
        Title: [property.Option?.Title],
        Type: [property.Option?.Type],
        Size: [property.Option?.Size],
        Blocks: this.formBuilder.array(
          (property.Option?.Blocks || []).map(item => those.formBuilder.group({ ID: [item.ID] }))
        ),
      }),
      Type: [property.Type],
      Size: [property.Size],
      ProductId: [property.ProductId],
      VariationId: [property.VariationId],
      Sort: [property.Sort],
      Opened: [false],
    });
    form.addControl(
      'Blocks',
      this.formBuilder.array(
        (property.Blocks || []).map(block => {
          return those.createBlockForm(block);
        }),
        []
      )
    );
    form.addControl(
      'Rates',
      this.formBuilder.array(
        (property.Rates || []).map(rate => {
          return those.createRateForm(rate);
        }),
        [Validators.required]
      )
    );
    // Customization
    /*form.addControl('Customization', this.formBuilder.group({
      Blocks: this.formBuilder.array((property.Option?.Blocks || []).map(item => {
        const item3 = (property.Customization?.Blocks || []).find(item2 => item2.ID === item.ID);
        if (item3) {
          item.Enabled = item3.Enabled;
        }
        item.Title = item.Post.Title;
        return item;
      }).map(block => {
        return this.formBuilder.group({
          ID: [block.ID],
          Enabled: [block.Enabled],
          Title: [block.Title],
          Post: this.formBuilder.group({
            ID: [block.Post.ID],
            Title: [block.Post.Title],
            Content: [block.Post.Content],
          }),
          Custom: [block.Custom],
          PostId: [{disabled: true, value: [block.PostId]}],
          Location: [block.Location],
        });
      }), [])
    }));*/
    return form;
  }

  createRateForm(rate) {
    console.log('createRateForm', rate);
    const those = this;
    const form = this.formBuilder.group({
      ID: [rate.ID],
      Enabled: [rate.Enabled],
      Sort: [rate.Sort],
      Value: this.formBuilder.group({
        ID: [rate.Value?.ID],
        Title: [{ disabled: rate.Value?.OptionId > 0, value: rate.Value?.Title }, [Validators.required]],
        Value: [{ disabled: rate.Value?.OptionId > 0, value: rate.Value?.Value }, [Validators.required]],
        Mode: [rate.Value?.Mode],
        Color: [rate.Value?.Color],
        Media: [rate.Value?.Media],
        Description: [rate.Value?.Description],
        OptionId: [rate.Value?.OptionId],
        Sort: [rate.Value?.Sort],
      }),
      ValueId: [rate.Value?.ID],
      Opened: [false],
    });
    return form;
  }

  createBlockForm(block) {
    const those = this;
    const form = this.formBuilder.group({
      ID: [block.ID],
      Enabled: [block.Enabled],
      Title: [block.Title],
      Post: those.formBuilder.group({
        ID: [block.Post.ID],
        Name: [block.Post.Name, [Validators.required]],
        Title: [block.Post.Title, [Validators.required]],
        Media: [block.Post.Media],
        Content: [block.Post.Content],
        Sort: [block.Post.Sort],
      }),
      PostId: [[block.PostId]],
      Custom: [block.Custom],
      Location: [block.Location],
      Sort: [block.Sort],
    });
    return form;
  }

  refresh(type?) {
    this.onSaved.emit(type);
  }

  slice(array, limit) {
    if (array && array.length > 0) {
      return array.slice(0, limit);
    }
    return array;
  }

  getRawValue(control: FormGroup) {
    return control.getRawValue();
  }

  addProperty(library = false) {
    console.log('addProperty', library);
    const property = {
      Type: 'select',
      Size: 'medium',
      Mode: '',
      ProductId: this.productId,
      VariationId: this.variationId,
    };
    if (library) {
      property['OptionId'] = 0;
    }
    const array = this.form.get('Properties') as FormArray;
    const length = array.length;
    array.push(this.createPropertyForm(property));
    array.at(array.length - 1).markAsDirty();
    array.markAsDirty();
    setTimeout(() => {
      const item = document.querySelector('[data-target="#property-collapse-' + length + '"]');
      if (item) {
        item['click']();
      }
    }, 100);
  }

  navigate(path, confirmation?) {
    const those = this;
    if (confirmation) {
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Leave page with unsaved changes?';
      modal.componentInstance.body = 'Leaving this page will delete all unsaved changes.';
      modal.componentInstance.confirm = 'Leave';
      modal.result.then(
        result => {
          those.router.navigate([path]);
        },
        reason => {}
      );
    } else {
      this.router.navigate([path]);
    }
  }

  deleteProperty(index) {
    const those = this;
    const array = this.form.get('Properties') as FormArray;
    const item = array.at(index) as FormGroup;
    const id = item.get('ID').value;
    if (id) {
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Delete option?';
      modal.componentInstance.body = `Are you sure you want to delete option? This can't be undone.`;
      modal.componentInstance.confirm = 'Delete';
      modal.result.then(
        result => {
          those.apiService
            .deleteProperty(id)
            .then(resp => {
              array.removeAt(index);
              array.markAsDirty();
            })
            .catch(err => {
              those.error('Delete property', err);
            });
        },
        reason => {}
      );
    } else {
      array.removeAt(index);
      array.markAsDirty();
    }
  }

  async onSave() {
    const those = this;
    const array = this.form.get('Properties') as FormArray;
    if (array && array.dirty && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        const group = array.at(i) as FormGroup;
        const raw = group.getRawValue();
        if (array.at(i).dirty || raw.Sort !== i + 1) {
          const id = raw.ID;
          if (raw.Blocks) {
            for (let j = 0; j < raw.Blocks.length; j++) {
              if (raw.Blocks[j].PostId) {
                raw.Blocks[j].PostId =
                  raw.Blocks[j].PostId.length > 0 && raw.Blocks[j].PostId[0] ? raw.Blocks[j].PostId[0] : 0;
              }
              raw.Blocks[j].Sort = j + 1;
            }
          }
          raw.OptionId = Array.isArray(raw.OptionId) ? raw.OptionId[0] : 0;
          if (raw.Rates) {
            for (let j = 0; j < raw.Rates.length; j++) {
              raw.Rates[j].Sort = j + 1;
            }
          }
          raw.Sort = i + 1;
          if (!id) {
            await this.apiService
              .postProperty({
                ...raw,
                ...{
                  ProductId: those.productId,
                  VariationId: those.variationId,
                },
              })
              .then(async property => {
                const rates = group.get('Rates') as FormArray;
                for (let j = 0; j < raw.Rates.length; j++) {
                  const rate = rates.at(j) as FormGroup;
                  rate.get('ID').setValue(property.Rates[j].ID);
                  if (
                    !raw.Rates[j].OptionId &&
                    raw.Rates[j].Value &&
                    (!raw.Rates[j].Value.Media || raw.Rates[j].Value.Media instanceof File)
                  ) {
                    const data = new FormData();
                    data.append('Value', raw.Rates[j].Value.Media);
                    await those.apiService
                      .patchValue(property.Rates[j].Value.ID, 'setMedia', data)
                      .then(value => {
                        rate.get('Value').patchValue(value);
                      })
                      .catch(err => {
                        those.error('Update value', err);
                      });
                  }
                }
                group.patchValue(property);
              })
              .catch(err => {
                those.error('Create property', err);
              });
          } else {
            await this.apiService
              .putProperty(id, raw)
              .then(async property => {
                const rates = group.get('Rates') as FormArray;
                for (let j = 0; j < raw.Rates.length; j++) {
                  const rate = rates.at(j) as FormGroup;
                  rate.get('ID').setValue(property.Rates[j].ID);
                  if (
                    !raw.Rates[j].OptionId &&
                    raw.Rates[j].Value &&
                    (!raw.Rates[j].Value.Media || raw.Rates[j].Value.Media instanceof File)
                  ) {
                    const data = new FormData();
                    data.append('Value', raw.Rates[j].Value.Media);
                    await those.apiService
                      .patchValue(property.Rates[j].Value.ID, 'setMedia', data)
                      .then(value => {
                        rate.get('Value').patchValue(value);
                      })
                      .catch(err => {
                        those.error('Update value', err);
                      });
                  }
                }
                group.patchValue(property);
              })
              .catch(err => {
                those.error('Update property', err);
              });
          }
        }
      }
      //
      (this.form.get('Properties') as FormGroup).markAsPristine();
    }
  }
}
