<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="offset-md-3 col-md-6">
          <div class="card tab2-card">
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink><i class="icon-user mr-2"></i>Login</a>
                  <ng-template ngbNavContent>
                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                         aria-labelledby="account-tab">
                      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"
                            class="form-horizontal auth-form" novalidate="">
                        <div class="form-group">
                          <input required="" name="login[Email]" type="email"
                                 id="email" class="form-control" placeholder="Email">
                        </div>
                        <div class="form-group">
                          <input required="" name="login[Password]" type="password"
                                 id="password" class="form-control" placeholder="Password">
                        </div>
                        <div class="form-terms">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input name="login[Remember]" type="checkbox" class="custom-control-input"
                                   id="customControlAutosizing">
                            <label class="custom-control-label"
                                   for="customControlAutosizing">Remember me</label>
                            <!--a href="javascript:void(0)"
                                class="btn btn-default forgot-pass">lost your
                                password</a-->
                          </div>
                        </div>
                        <div class="form-button">
                          <button class="btn btn-primary" type="submit">Login</button>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
