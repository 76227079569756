import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { SalesModule } from './components/sales/sales.module';
import { PagesModule } from './components/pages/pages.module';
import { MediasModule } from './components/medias/medias.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { UsersModule } from './components/users/users.module';
import { SettingsModule } from './components/settings/settings.module';
import { AuthModule } from './components/auth/auth.module';
import {ApiService} from './shared/api.service';
import {AuthInterceptorService} from './shared/auth-interceptor.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {SubjectService} from './shared/subject.service';
import {AuthService} from './shared/auth.service';
import {AuthGuardService} from './shared/auth-guard.service';
import {CategoriesModule} from './components/categories/categories.module';
import {ToastrModule} from 'ngx-toastr';
import {VariationsModule} from './components/variations/variations.module';
import {NguiAutoCompleteModule} from '@ngui/auto-complete';
import {MomentModule} from 'ngx-moment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LocalStorageModule} from 'angular-2-local-storage';
import {FormsModule} from './components/forms/forms.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    LocalStorageModule.forRoot({
      prefix: 'goshop',
      storageType: 'localStorage'
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NguiAutoCompleteModule,
    MomentModule,
    DashboardModule,
    SettingsModule,
    AuthModule,
    SharedModule,
    CategoriesModule,
    ProductsModule,
    VariationsModule,
    SalesModule,
    VendorsModule,
    PagesModule,
    MediasModule,
    MenusModule,
    FormsModule,
    UsersModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    SubjectService,
    ApiService,
    AuthService,
    AuthGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
