<div *ngIf="enabled" class="container-fluid">
    <div class="page-header">
        <div class="row">
            <div class="col-lg-6">
                <div class="page-header-left">
                  <a *ngIf="parent" class="facon-previous" style="color: initial;position: absolute;left: 15px;top: 5px;" [routerLink]="parent"></a> <h3 style="display: inline-block; padding-left: 20px;">{{title | translate}}</h3>
                </div>
            </div>
            <div class="col-lg-6">
                <ol class="breadcrumb pull-right">
                    <li class="breadcrumb-item"><a [routerLink]="'/dashboard/default'">
                            <app-feather-icons [icon]="'home'"></app-feather-icons>
                        </a></li>
                    <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb | translate}}
                    </li>
                    <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">
                        {{breadcrumbs?.childBreadcrumb | translate}}
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
