<div class="my-smart-table">

  <div class="smart-filter">
    <div *ngIf="presets && presets.length > 1" class="row" style="padding-bottom: 20px">
      <div class="col">
        <div class="presets">
          <span *ngFor="let item of presets; let i = index" class="body-2 preset no-selection" [ngClass]="{selected: (!preset && i == 0) || (preset && preset.Id == item.Id)}" (click)="onPresetClick(item)" (dblclick)="onPresetDblclick(item)" [innerHTML]="item.Label | translate"></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mb-2">
        <div class="table-top" style="position: relative">
          <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
            </svg>
          </div>
          <input type="text" class="form-control search" placeholder="{{'Search' + (preset?.Label ? (' in ' + preset?.Label) : '') | translate}}" (keyup)="termChanged.next()" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <!-- Filter -->
    <div class="row" style="padding-bottom: 10px">
      <div class="col" style="display: flex; gap: 5px;">

        <!-- Selected -->
        <div *ngIf="selected && selected.length > 0" class="selected">
          <div style="display: flex; align-items: center; gap: 5px;">
            <div class="body-2" style="display: flex;gap: 5px;">
              <span>{{selected.length}}</span>
              <span translate>of</span><span>{{source['shown']()}}</span><span [innerText]="type | translate"></span><span translate>selected</span>
            </div>
            <div class="btn btn-sm btn-default delete" style="display: flex; padding: 7px;" (click)="onBulkAction('delete')">
              <svg style="height: 18px; width: 18px">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
              </svg>
            </div>
            <div *ngIf="bulks && bulks.length > 0" class="button-group-dropdown">
              <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                <span translate>Choose actions</span>
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let bulk of bulks" class="body-2" (click)="onBulkAction(bulk.Name)" [innerText]="bulk.Label | translate"></li>
                <!--li *ngIf="cid && selected && selected.length == 1" class="body-2" (click)="bulk('put-to')">
                  <app-edit-position (onSaved)="onEditPosition($event)" #positionEditor>
                    <span data-target="#exampleModal" (click)="positionEditor.onEdit(positionEditor.content, 1)" translate> Set position </span>
                  </app-edit-position>
                </li-->
              </ul>
            </div>
          </div>
        </div>
        <!-- /Selected -->

        <div *ngIf="preset && preset.Filter && preset.Filter" class="filters">
          <div *ngFor="let field of preset.Filter.Fields; let i = index" class="filter-existing">
            <!-- Date -->
            <div *ngIf="field.Type === 'date'" class="filter filter-button button-group-dropdown date" [ngClass]="{show: field.Expanded}" [attr.data-field]="field.Name" style="position: relative">
              <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" style="min-width: 70px;" aria-expanded="false" data-display="static" [attr.aria-expanded]="expanded" (click)="field.Expanded=!field.Expanded">
                <span [innerText]="field.Label | translate"></span>
                <span *ngIf="field.Value" class="before-colon" [innerHtml]="field.Value.Label | translate"></span>
              </button>
              <div class="dropdown-menu date" style="padding: 0;" [ngClass]="{show: field.Expanded}">
                <div class="body-4-regular" translate>
                  <ul style="box-shadow: none;">
                    <li *ngFor="let v of filters[findIndex(filters, field.Name)].Values; let j = index" class="body-2" (click)="onFilterSet(i, v); field.Expanded = false" translate>
                      {{v.Label}}
                    </li>
                    <li class="body-2" (click)="field.Custom=true" (click)="field.Custom = true" translate>Custom</li>
                  </ul>
                </div>
                <div *ngIf="field.Custom" class="sort__body pb-2">
                  <div>
                    <label for="start" class="mb-1" style="padding-right: 5px" translate>Start</label>
                    <div><dp-date-picker id="start" mode="day" theme="dp-material" style="width: 100%" [config]="{format:'YYYY-MM-DD', firstDayOfWeek:'mo', showTwentyFourHours: false, showMultipleYearsNavigation: true}" (onChange)="onFilterSet(i)" [(ngModel)]="field.Start"></dp-date-picker></div>
                  </div>
                  <div>
                    <label for="end" class="mb-1" style="padding-right: 5px" translate>End</label>
                    <div><dp-date-picker id="end" mode="day" theme="dp-material" style="width: 100%" [config]="{format:'YYYY-MM-DD', firstDayOfWeek:'mo', showTwentyFourHours: false, showMultipleYearsNavigation: true}" (onChange)="onFilterSet(i)" [(ngModel)]="field.End"></dp-date-picker></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Date -->

            <!-- Multiselect -->
            <div *ngIf="field.Type === 'multiselect'" class="filter filter-categories button-group-dropdown" [ngClass]="{
                'filter-billing' : field.Name === 'BillingStatus' && field.Value?.length > 0,
                'filter-category' : field.Name === 'Category' && field.Value?.length == 1, 'filter-categories' : field.Name === 'Category' && field.Value?.length > 1,
                'filter-content-type' : field.Name === 'ContentType' && field.Value?.length == 1, 'filter-content-types' : field.Name === 'ContentType' && field.Value?.length > 1,
                'filter-shipping' : field.Name === 'ShippingStatus' && field.Value?.length > 0,
                'filter-status' : field.Name === 'Status' && field.Value?.length == 1, 'filter-Statuses' : field.Name === 'Status' && field.Value?.length > 1,
                'filter-tag' : field.Kind === 'Tag' && field.Value?.length == 1, 'filter-tags' : field.Kind === 'Tag' && field.Value?.length > 1,
                'filter-value' : ['MetricsValues', 'ValuesValues'].indexOf(field.Name) >= 0 && field.Value?.length == 1, 'filter-values' : ['MetricsValues', 'ValuesValues'].indexOf(field.Name) >= 0 && field.Value?.length > 1,
                'filter-vendor' : field.Name === 'Vendor' && field.Value?.length == 1, 'filter-vendors' : field.Name === 'Vendor' && field.Value?.length > 1
                }">
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'BillingStatus'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="billingMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'Category'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="categoryMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'ContentType'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="contentTypeMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'ShippingStatus'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="shippingMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'Status'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="statusMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="['MetricsValues', 'ValuesValues'].indexOf(field.Name) >= 0" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="valueMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Name === 'Vendor'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="vendorMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
              <ngx-bootstrap-multiselect *ngIf="field.Kind === 'Tag'" class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                         [settings]="multiselectSettings" [texts]="tagMultiSelectTexts" (ngModelChange)="onFilterSet(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
            </div>
            <!-- /Multiselect -->

            <!-- Select -->
            <div *ngIf="field.Type === 'select'" class="filter button-group-dropdown select">
              <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                <span [innerText]="field.Label | translate"></span>
                <span *ngIf="field.Value" class="before-colon" [innerHtml]="field.Value.Label | translate"></span>
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let v of filters[findIndex(filters, field.Name)].Values; let j = index" class="body-2" (click)="onFilterSet(i, v)" translate>
                  {{v.Label}}
                </li>
              </ul>
            </div>
            <!-- /Select -->

            <!-- Input -->
            <div *ngIf="!field.Type" class="filter input">
              <label [attr.for]="'filter-' + field.Name" class="mb-0" [innerText]="field.Label|translate"></label>
              <input type="text" [attr.id]="'filter-' + field.Name" class="form-control" [placeholder]="field.Placeholder ? field.Placeholder : ''" (change)="onFilterSet(i, field.Value)" [(ngModel)]="field.Value">
            </div>
            <!-- /Input -->

            <div class="filter-existing__delete" (click)="onFilterDelete(i)">
              <svg style="stroke: red;" width="9" height="9" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0466 11.664L6 7.61864L1.95341 11.664C1.84738 11.7705 1.72135 11.855 1.58257 11.9127C1.44379 11.9703 1.29499 12 1.14471 12C0.994428 12 0.845626 11.9703 0.706846 11.9127C0.568067 11.855 0.442043 11.7705 0.33601 11.664V11.664C0.229512 11.558 0.145007 11.4319 0.087345 11.2932C0.029683 11.1544 0 11.0056 0 10.8553C0 10.705 0.029683 10.5562 0.087345 10.4174C0.145007 10.2786 0.229512 10.1526 0.33601 10.0466L4.38136 6L0.33601 1.95341C0.229512 1.84738 0.145007 1.72135 0.087345 1.58257C0.029683 1.44379 0 1.29499 0 1.14471C0 0.994428 0.029683 0.845626 0.087345 0.706846C0.145007 0.568067 0.229512 0.442043 0.33601 0.33601V0.33601C0.442043 0.229512 0.568067 0.145007 0.706846 0.087345C0.845626 0.029683 0.994428 0 1.14471 0C1.29499 0 1.44379 0.029683 1.58257 0.087345C1.72135 0.145007 1.84738 0.229512 1.95341 0.33601L6 4.38136L10.0466 0.33601C10.1526 0.229512 10.2786 0.145007 10.4174 0.087345C10.5562 0.029683 10.705 0 10.8553 0C11.0056 0 11.1544 0.029683 11.2932 0.087345C11.4319 0.145007 11.558 0.229512 11.664 0.33601V0.33601C11.7705 0.442043 11.855 0.568067 11.9127 0.706846C11.9703 0.845626 12 0.994428 12 1.14471C12 1.29499 11.9703 1.44379 11.9127 1.58257C11.855 1.72135 11.7705 1.84738 11.664 1.95341L7.61864 6L11.664 10.0466C11.7705 10.1526 11.855 10.2786 11.9127 10.4174C11.9703 10.5562 12 10.705 12 10.8553C12 11.0056 11.9703 11.1544 11.9127 11.2932C11.855 11.4319 11.7705 11.558 11.664 11.664V11.664C11.558 11.7705 11.4319 11.855 11.2932 11.9127C11.1544 11.9703 11.0056 12 10.8553 12C10.705 12 10.5562 11.9703 10.4174 11.9127C10.2786 11.855 10.1526 11.7705 10.0466 11.664V11.664Z" fill="#ff0000"/>
              </svg>
            </div>
          </div>

          <!-- Filter -->
          <div class="filter filter-button button-group-dropdown filter-block" style="position: relative" [ngClass]="{show: filterExpanded}">
            <button type="button" class="btn btn-filter no-expand-icon dropdown-toggle" style="margin: 0;" data-display="static" [attr.aria-expanded]="filterExpanded" (click)="filterExpanded=!filterExpanded">
              <svg class="stroke-primary-text-gray" style="height: 16px;width: 16px; transform: rotate(90deg); vertical-align: initial;">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-settings-product-settings"></use>
              </svg>
              <span style="padding-left: 8px;" translate>Filter</span>
            </button>
            <ul class="dropdown-menu" [ngClass]="{show: filterExpanded}" style="min-width: initial;">
              <li *ngFor="let filter of availableFilters(filters); let i = index" class="body-2" (click)="onFilterSelect(filter)" translate>{{filter.Label}}</li>
            </ul>
          </div>
          <!-- /Filter -->

          <!-- Columns -->
          <div *ngIf="preset.Columns" class="filter filter-button button-group-dropdown column-block" [ngClass]="{show: columnExpanded}">
            <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" style="margin: 0;" data-display="static" [attr.aria-expanded]="columnExpanded" (click)="columnExpanded=!columnExpanded">
              <svg style="height: 20px; width: 20px; vertical-align: bottom;">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-columns"></use>
              </svg>
              <span translate>Columns</span>
            </button>
            <ul class="columns dropdown-menu" [ngClass]="{show: columnExpanded}" dragula="columns" [(dragulaModel)]="preset['Columns']">
              <li *ngFor="let column of preset['Columns']; let i = index" class="column" (click)="$event.stopPropagation()" [attr.data-name]="column.Name">
                <div>
                  <input type="checkbox" [attr.id]="'column-' + i" class="checkbox_animated" (change)="setColumns($event)" [(ngModel)]="column['Selected']">
                  <label [attr.for]="'column-' + i" class="mb-0" translate>{{column.Label}}</label>
                </div>
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="15" height="22" rx="2" fill="#5C22CF" fill-opacity="0.3"/>
                  <circle cx="4.77778" cy="4.77778" r="1.77778" fill="white"/>
                  <circle cx="4.77778" cy="10.9999" r="1.77778" fill="white"/>
                  <circle cx="4.77778" cy="17.2222" r="1.77778" fill="white"/>
                  <circle cx="10.1108" cy="4.77778" r="1.77778" fill="white"/>
                  <circle cx="10.1108" cy="10.9999" r="1.77778" fill="white"/>
                  <circle cx="10.1108" cy="17.2222" r="1.77778" fill="white"/>
                </svg>
              </li>
            </ul>
          </div>
          <!-- /Columns -->

          <!-- Sort -->
          <div *ngIf="source && source['sortConf']" class="filter filter-button button-group-dropdown sort-block" [ngClass]="{show: sortExpanded}" style="position: relative">
            <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" aria-expanded="false" data-display="static" [attr.aria-expanded]="sortExpanded" (click)="sortExpanded=!sortExpanded">
              <svg class="stroke-primary-text-gray" style="height: 16px;width: 16px; vertical-align: initial;">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-sort"></use>
              </svg>
              <span translate>Sort</span>
            </button>
            <div *ngIf="visible" class="dropdown-menu sort" [ngClass]="{show: sortExpanded}">
              <div class="sort__label body-4-regular" translate>
                Sort by
              </div>
              <div *ngIf="source && source['sortConf'] && source['sortConf'].length > 0 && sorts && sorts.length > 0" class="sort__body pb-2">
                <div *ngFor="let s of sorts; let i = index">
                  <input type="radio" [attr.id]="'sort-' + i" name="sort" class="radio_animated" [value]="s.Name" (ngModelChange)="onSortChange(s.Name)" [(ngModel)]="source['sortConf'][0]['field']">
                  <label [attr.for]="'sort-' + i" class="body-5-regular">
                    {{s.Label | translate}}
                  </label>
                </div>
              </div>
              <div *ngIf="source && source['sortConf'] && source['sortConf'].length > 0 && sorts && sorts.length > 0" class="sort__footer">
                <div *ngFor="let order of getOrders(sorts, source['sortConf'][0]['field']); let i = index" class="sort__direction" [ngClass]="{selected: source['sortConf'][0]['direction'] === order.Type}" (click)="onSortChange(undefined, order.Type)">
                  <div class="body-3" [innerText]="order.Label | translate"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Sort -->

          <!-- Save -->
          <div class="filter filter-button button-group-dropdown">
            <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" (click)="onPresetSave()">
              <svg style="height: 20px; width: 20px; vertical-align: bottom;">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-save"></use>
              </svg>
              <span translate>Save</span>
            </button>
          </div>
          <!-- /Save -->

          <!-- Other -->
          <div class="filter filter-button button-group-dropdown">
            <button type="button" class="btn btn-filter no-expand-icon dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
              <svg style="margin: 0 0 0 4px; height: 20px; width: 20px; vertical-align: bottom;">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-more---horizontal"></use>
              </svg>
            </button>
            <ul class="dropdown-menu">
              <li (click)="onFiltersDelete()" translate>
                Clear
              </li>
              <li *ngIf="preset.Type === 'custom'" (click)="onPresetRename()" translate>
                Rename
              </li>
              <li *ngIf="preset.Type === 'custom'" style="color: red;" (click)="onPresetDelete(preset)" translate>
                Delete
              </li>
            </ul>
          </div>
          <!-- /Other -->

        </div>
      </div>
    </div>
  </div>
  <div id="batchDelete" class="products-table custom-datatable">
    <div class="table-responsive" [ngClass]="!visible || !filtered?'transparent':''">
      <ng2-smart-table *ngIf="source" (create)="router.navigate(['/products/new'])" [settings]="settings" [source]="source" [ngClass]="hideCols" (rowDragStart)="onRowDragStart($event)" (rowDrop)="onRowDrop($event)"></ng2-smart-table>
      <div style="position: absolute; top: 13px; left: 15px; z-index: 2;">
        <input type="checkbox" class="checkbox_animated" (ngModelChange)="onSelectAllToggle($event)" [(ngModel)]="selectedAll">
      </div>
      <div style="display: flex; justify-content: space-between; align-items: end;">
        <div>
          <div *ngIf="source && source.count() > 0" class="records-count">
                    <span *ngIf="source['filtered']() != source['total']()">
                      {{'Filtered' | translate}}: {{source['filtered']()}} {{'of' | translate}} {{source['total']()}} {{'records' | translate}}
                    </span>
            <span *ngIf="source['filtered']() == source['total']()">
                      {{'Records' | translate}}: {{source['total']()}}
                    </span>

            <div class="per-page" style="width: 75px;">
              <div class="select-dropdown dropdown">
                <button
                  id="dropdownButton"
                  class="btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {{ perPage }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownButton">
                            <span
                              *ngFor="let n of [10, 20, 50, 100]; let i = index"
                              class="dropdown-item"
                              (click)="onPerPageSet(n)"
                            >{{ n }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="resized" class="columns-reset" (click)="onColumnsReset($event)">reset width of columns</div>
      </div>
    </div>
    <div *ngIf="!visible || !filtered" style="position: absolute;top: 300px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
  </div>
</div>
