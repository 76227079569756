import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class UsersDataSource extends LocalDataSource {
  parent: any;
  id;
  data: any[];
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {
    super();
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    page = JSON.parse(this.localStorageService.get('users_page')) || 1;
    perPage = JSON.parse(this.localStorageService.get('users_per_page')) || 10;

    this.pagingConf['page'] = page;
    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    //
    const u = new URL((url += '/users/list'));
    if (this.id) {
      u.searchParams.set('id', this.id);
    }
    return this.http
      .post(u.toString(), request, { headers: headersObject, withCredentials: true })
      .pipe(
        map(response => {
          those.lastRequestFiltered = +response['Filtered'];
          those.lastRequestCount = +response['Filtered'];
          those.lastRequestTotal = +response['Total'];
          those.parent.visible = true;
          return (response['Data'] || []).map(item => {
            item['Thumbnail'] =
              '<img src="' +
              those.absolutizeUrl.transform(
                item['Thumbnail'] ? item['Thumbnail'] : '/assets/images/no-image.svg'
              ) +
              '" class="category-thumbnail">';
            return item;
          });
        })
      )
      .toPromise();
  }
}
