import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-time',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h2
          class="modal-title"
          id="exampleModalLabel"
          [innerHTML]="(!service ? 'Add' : 'Edit') + ' time' | translate"
          translate></h2>
        <span class="cross" (click)="modal.dismiss('Cross click')">
          <svg style="margin-right:10px; height: 12px; width: 12px;">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-close"></use>
          </svg>
        </span>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Display Name</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Name</label>
                  <input class="form-control" id="name" type="text" formControlName="Name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>
                  <div
                    *ngIf="
                      form.controls['Thumbnail'] &&
                      form.controls['Thumbnail'].value &&
                      !form.controls['Thumbnail'].touched
                    "
                    style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                    <a [href]="form.controls['Thumbnail'].value" target="_blank"
                      ><img
                        [src]="
                          absolutizeUrl.transform('/api/v1/resize?path=/storage' + form.controls['Thumbnail'].value)
                        "
                        style="max-height: 100px;border-radius: 5px;margin-bottom: 5px;"
                    /></a>
                    <div
                      style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                      (click)="form.controls['Thumbnail'].value = ''">
                      &times;
                    </div>
                  </div>
                  <app-file-uploader
                    *ngIf="!form.controls['Thumbnail'].value || form.controls['Thumbnail'].touched"
                    id="thumbnail"
                    (filesLoaded)="upload($event)"></app-file-uploader>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <textarea class="form-control" id="description" formControlName="Description"></textarea>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-badge" formControlName="ShowBadge" />
                  <label for="show-badge" class="mb-1" translate>ShowBadge</label>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="show-list" formControlName="ShowList" />
                  <label for="show-list" class="mb-1" translate>ShowList</label>
                </div>
                <div class="form-group">
                  <label class="mb-1" translate>Position of badge</label>
                  <div class="row">
                    <div class="col">
                      <input
                        type="radio"
                        class="radio_animated"
                        id="top-left"
                        formControlName="Position"
                        value="top-left" />
                      <label for="top-left" class="mb-1" translate>Top Left</label>
                    </div>
                    <div class="col">
                      <input
                        type="radio"
                        class="radio_animated"
                        id="top-right"
                        formControlName="Position"
                        value="top-right" />
                      <label for="top-right" class="mb-1" translate>Top Right</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <input
                        type="radio"
                        class="radio_animated"
                        id="bottom-left"
                        formControlName="Position"
                        value="bottom-left" />
                      <label for="bottom-left" class="mb-1" translate>Bottom Left</label>
                    </div>
                    <div class="col">
                      <input type="radio" class="radio_animated" formControlName="Position" value="bottom-right" />
                      <label for="bottom-right" class="mb-1" translate>Bottom Right</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="enabled" class="mb-1" translate>Status</label>
                  <select class="form-control" id="enabled" formControlName="Enabled">
                    <option [value]="true">Active</option>
                    <option [value]="false">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="display: block">
          <button
            type="submit"
            id="save"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            translate>
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span style="vertical-align: text-bottom">Save</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
          <button
            *ngIf="service"
            class="btn btn-sm btn-default delete"
            type="button"
            style="padding: 8px;"
            (click)="onDelete()">
            <svg style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
            </svg>
          </button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [``],
  providers: [AbsolutizeUrlPipe],
})
export class TimeComponent implements OnInit {
  @Input('vendorId') vendorId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onDeleted: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  service;
  modal;
  closeResult;
  form;
  time;
  timer;
  config;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.time = undefined;
    this.form = this.formBuilder.group({
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Thumbnail: [''],
      Description: [''],
      ShowBadge: [false],
      ShowList: [false],
      Position: ['bottom-right'],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
        those.onSaved.emit(those.time);
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getTime(id)
      .then(time => {
        those.time = time;
        those.form = those.formBuilder.group({
          ID: [time['ID']],
          Enabled: [time['Enabled'] ? true : false],
          Name: [{ disabled: true, value: time['Name'] }, Validators.required],
          Title: [time['Title'], Validators.required],
          Thumbnail: [time['Thumbnail'] ? time['Thumbnail'] : ''],
          Description: [time['Description']],
          ShowBadge: [time['ShowBadge'] ? true : false],
          ShowList: [time['ShowList'] ? true : false],
          Position: [time['Position']],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit(those.time);
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load template', err);
      });
  }

  onDelete() {
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete time?';
    modal.componentInstance.body = `Are you sure you want to delete time? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        this.onDeleted.emit(this.service);
      },
      reason => {}
    );
  }

  change(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Description', this.form.get('Description').value);
    data.append('ShowBadge', this.form.get('ShowBadge').value);
    data.append('ShowList', this.form.get('ShowList').value);
    data.append('Position', this.form.get('Position').value);
    if (!this.time) {
      // create
      data.append('VendorId', this.vendorId);
      this.apiService
        .postTime(data)
        .then(resp => {
          those.time = resp;
          those.success('Create time', resp);
          those.modal.close('Saved');
        })
        .catch(err => {
          those.error('Delete time', err);
        });
    } else {
      // update
      this.apiService
        .putTime(this.time.ID, data)
        .then(resp => {
          those.time = resp;
          those.success('Update time', resp);
          those.modal.close('Saved');
        })
        .catch(err => {
          those.error('Update time', err);
        });
    }
  }
}
