import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { MediaThumbnailPipe} from '../../shared/utils.pipe';

@Component({
  styles: [
    `
      div > a {
        padding-right: 5px;
      }
    `,
  ],
  template: `
    <div style="vertical-align: middle">
      <div
        *ngIf="rowData['Media']"
        [style]="{
          backgroundImage:
            'url(' +
            mediaThumbnail.transform(rowData['Media']) +
            ')',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'inline-block',
          height: '60px',
          width: '60px',
          marginRight: '10px',
          verticalAlign: 'middle'
        }"></div>
      <div *ngIf="prefix; else text" style="display:inline-block;vertical-align: middle">
        <a class="link" [routerLink]="[prefix + rowData['ID']]" style="cursor: pointer">{{ value }}</a>
      </div>
      <ng-template #text>{{ value }}</ng-template>
    </div>
  `,
})
export class TitleRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  parent;
  prefix;

  constructor(public mediaThumbnail: MediaThumbnailPipe) {}

  ngOnInit() {
    if (this.parent) {
      this.prefix = `/${this.parent.type}/`;
    }
  }
}
