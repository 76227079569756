import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsRoutingModule } from './forms-routing.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import {FormsComponent} from './forms.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormComponent} from './form/form.component';
import {MomentModule} from 'ngx-moment';
import {FormMessagesRenderComponent} from './form-messages-render.component';

@NgModule({
  declarations: [
    FormsComponent,
    FormComponent,
    FormMessagesRenderComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CKEditorModule,
    FormsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    MomentModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    SharedModule,
    TranslateModule,
  ],
  providers: [
    NgbActiveModal
  ],
})
export class FormsModule { }
