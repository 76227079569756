import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles:[`
      ul{
          list-style: none;
          text-align: left;
      }
      ul li {
          display: inline-block;
          margin: 0 3px 3px 0px;
          padding: 0 3px;
      }
      ul li:not(:last-child):after{
          content: ', ';
      }
      .more{
          cursor: pointer;
          color: gray;
          font-style: italic;
      }
  `],
  template: `    
    <ul *ngIf="properties.length > limit; else otherwise">
        <li *ngFor="let n of counter(limit); let i = index"><a class="link" [routerLink]="['/products/' + rowData['ProductId'] + '/variations/' + rowData['ID']]" style="cursor: pointer;">{{properties[i]['Title']}}</a></li>
        <li class="more" (click)="limit=999">({{properties.length - limit}} <span translate>more</span>)</li>
    </ul>
    <ng-template #otherwise>
        <ul>
            <li *ngFor="let property of properties"><a class="link" [routerLink]="['/products/' + rowData['ProductId'] + '/variations/' + rowData['ID']]" style="cursor: pointer;">{{property['Title']}}</a></li>
        </ul>
    </ng-template>
  `,
})
export class VariationPropertiesRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  separator = ', ';
  properties;
  limit = 5;

  ngOnInit(): void {
    const ids = this.rowData['PropertiesIds'] ? this.rowData['PropertiesIds'].split(this.separator) : [];
    const titles = this.rowData['PropertiesTitles'] ? this.rowData['PropertiesTitles'].split(this.separator) : [];
    this.properties = ids.map((item, i) => {
      const property = {ID: item};
      if (titles[i]) {
        property['Title'] = titles[i];
      }
      return property;
    });
  }
  counter(n: number){
    return new Array(n);
  }
}
