import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SubjectService} from '../../../shared/subject.service';

@Component({
  selector: 'app-settings-basic',
  templateUrl: './global.component.html',
  styleUrls: ['../settings.component.scss', './global.component.scss']
})
export class GlobalComponent implements OnInit {
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private subjectService: SubjectService, private toastr: ToastrService,
              private translate: TranslateService) { }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }


  ngOnInit() {
    const those = this;
    this.apiService.getSettings('global').then(resp => {
      those.form = this.formBuilder.group({
        Preview: resp.Preview ? resp.Preview : '',
        Products: resp.Products ? resp.Products : '',
        FlatUrl: resp.FlatUrl ? true : false,
      });
    }).catch(err => {
      those.error('Update settings', err);
    });
  }

  onDump() {
    this.apiService.postDump().then(resp => {
      if (resp && resp['Url']) {
        window.open(resp['Url'], '_blank').focus();
      }
    }).catch(err => {
      this.error('Dump', err);
    });
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putSettings('global', raw).then(resp => {
      those.success('Update settings', resp);
    }).catch(err => {
      those.error('Update settings', err);
    });
  }
}
