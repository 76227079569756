import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [`div>span:nth-child(2)::before{content: ': ';}`],
  template: `
    <div *ngIf="payment['AdvancePayment']">
      <span translate>Advance Payment</span>
      <span>{{value}}</span>
    </div>
    <div *ngIf="payment['OnDelivery']">
      <span translate>On delivery</span>
    </div>
    <div *ngIf="payment['Mollie']">
      <span translate>Mollie</span>
      <span>{{payment['Mollie']['Id']}}</span>
      <span *ngIf="payment['Mollie']['Error']">{{payment['Mollie']['Error']}}</span>
    </div>
    <div *ngIf="payment['Stripe']">
      <span translate>Stripe</span>
      <span>{{payment['Stripe']['Id']}}</span>
      <span *ngIf="payment['Mollie']['Error']">{{payment['Mollie']['Error']}}</span>
    </div>
  `,
})
export class TransactionPaymentRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  payment;

  ngOnInit(): void {
    try {
      this.payment = JSON.parse(this.value);
    }catch (e){
      console.log(e);
    }
  }
}
