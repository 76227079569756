import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import {GlobalComponent} from './global/global.component';
import {PublisherComponent} from './publisher/publisher.component';
import {SettingsComponent} from './settings.component';
import {PaymentComponent} from './payment/payment.component';
import {NotificationComponent} from './notification/notification.component';
import {MediaComponent} from './media/media.component';
import {ProductComponent} from './product/product.component';
import {AppearanceComponent} from './appearance/appearance.component';
import {TaxComponent} from './tax/tax.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    data: {
      title: 'Settings',
      breadcrumb: ''
    },
  },
  {
    path: 'account',
    component: AccountComponent,
    data: {
      title: 'Account',
      breadcrumb: 'Account'
    }
  },
  {
    path: 'appearance',
    component: AppearanceComponent,
    data: {
      title: 'Account',
      breadcrumb: 'Account'
    }
  },
  {
    path: 'global',
    component: GlobalComponent,
    data: {
      title: 'Global',
      breadcrumb: 'Global'
    }
  },
  {
    path: 'media',
    component: MediaComponent,
    data: {
      title: 'Media',
      breadcrumb: 'Media'
    }
  },
  {
    path: 'notification',
    component: NotificationComponent,
    data: {
      title: 'Notification',
      breadcrumb: 'Notification'
    }
  },
  {
    path: 'payment',
    component: PaymentComponent,
    data: {
      title: 'Payment',
      breadcrumb: 'Payment'
    }
  },
  {
    path: 'product',
    component: ProductComponent,
    data: {
      title: 'Product',
      breadcrumb: 'Product'
    }
  },
  {
    path: 'publisher',
    component: PublisherComponent,
    data: {
      title: 'Publisher',
      breadcrumb: 'Publisher'
    }
  },
  {
    path: 'tax',
    component: TaxComponent,
    data: {
      title: 'Hugo',
      breadcrumb: 'Hugo'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
