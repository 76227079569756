import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PagesComponent} from './pages.component';
import {CanDeactivateGuard} from '../../shared/guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    data: {
      title: 'Pages',
      breadcrumb: ''
    },
    canDeactivate: [CanDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
