import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { ApiService } from '../../api.service';
import { SubjectService } from '../../subject.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AbsolutizeUrlPipe } from '../../utils.pipe';
import { credentials } from '../../credentials.data';
import { CompleterData, CompleterService } from '@akveo/ng2-completer';
import { debounceTime, filter } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('content') content;
  @ViewChild('input') input;
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.input.nativeElement?.parentElement?.parentElement.contains(event.target)) {
      this.term = '';
      this.searching = false;
      this.results = { term: '' };
      this.input.nativeElement.blur();
    }
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    //console.log('evt', evt);
    if (this.searching) {
      if (evt.code === 'Escape') {
        this.term = '';
        this.searching = false;
        this.results = { term: '' };
        this.input.nativeElement.blur();
      } else if (evt.keyCode === 38) {
        const activeElement = document.activeElement;
        const parentElement = activeElement.parentElement;
        if (!activeElement || !activeElement.classList.contains('search-result')) {
          const element = document.querySelector('.search-result');
          if (element && typeof element['focus'] === 'function') {
            element['focus']();
          }
        } else {
          const previousSibling = activeElement.previousSibling;
          if (
            previousSibling &&
            typeof previousSibling['focus'] === 'function' &&
            previousSibling['classList'].contains('search-result')
          ) {
            previousSibling['focus']();
          } else if (parentElement?.parentElement?.previousSibling) {
            const elements = parentElement.parentElement.previousSibling['querySelectorAll']('.search-result');
            if (elements && elements.length > 0) {
              const element = elements[elements.length - 1];
              if (element && typeof element['focus'] === 'function') {
                element['focus']();
              }
            }
          }
        }
      } else if (evt.keyCode === 40) {
        const activeElement = document.activeElement;
        const parentElement = activeElement.parentElement;
        if (!activeElement || !activeElement.classList.contains('search-result')) {
          const element = document.querySelector('.search-result');
          if (element && typeof element['focus'] === 'function') {
            element['focus']();
          }
        } else {
          if (activeElement.nextSibling && typeof activeElement.nextSibling['focus'] === 'function') {
            activeElement.nextSibling['focus']();
          } else if (
            parentElement?.parentElement?.nextSibling &&
            parentElement?.parentElement?.nextSibling['querySelector']
          ) {
            const element = parentElement.parentElement.nextSibling['querySelector']('.search-result');
            if (element && typeof element['focus'] === 'function') {
              element['focus']();
            }
          }
        }
      }
    } else {
      if (
        evt.code === 'KeyS' &&
        evt.target &&
        evt.target['tagName'] &&
        evt.target['tagName'].toLowerCase() === 'body'
      ) {
        const those = this;
        this.searching = true;
        setTimeout(() => {
          those.input.nativeElement.focus();
        }, 100);
      }
    }
  }
  subscription: Subscription;
  language = 'en';
  info;
  upgrade = false;
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  notifications = [];
  count = 5;
  limit = 5;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  opened = false;
  modal;
  closeResult;
  id;
  messages = [];
  clients = [];
  ws;

  term: string;
  termChanged: Subject<string> = new Subject<string>();
  termSubscription: Subscription;
  searching = false;
  includes = [
    /*{ name: 'any', label: 'All' },*/
    { name: 'products', label: 'Products', selected: true },
    { name: 'options', label: 'Options', selected: true },
    { name: 'values', label: 'Option Values', selected: true },
    { name: 'dimensions', label: 'Custom Fields', selected: true },
    { name: 'metrics', label: 'Custom Field Values', selected: true },
    { name: 'posts', label: 'Infoboxes', selected: true },
    { name: 'vendors', label: 'Vendors', selected: true },
    { name: 'categories', label: 'Categories', selected: true },
    { name: 'tags', label: 'Tags', selected: true },
    { name: 'customers', label: 'Customers', selected: true },
    { name: 'menu', label: 'Menu', selected: true },
    { name: 'orders', label: 'Orders', selected: true },
  ];
  include = { name: '', label: '' };
  loading = false;
  results = {
    term: '',
  };
  navigation = [];
  countries = credentials.countries;
  all = true;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private localStorageService: LocalStorageService,
    public navServices: NavService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    public translate: TranslateService,
    private completerService: CompleterService
  ) {
    const those = this;
    if (this.localStorageService.get('language')) {
      this.language = this.localStorageService.get('language');
    }
    this.subjectService.searchSubject.subscribe(terms => {
      those.navigation = terms;
    });
    /*this.dataService = completerService.local(this.search, 'title', 'title');*/
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  hover() {
    const results = document.querySelectorAll('.search-result');
    if (results) {
      for (let i = 0; i < results.length; i++) {
        if (results[i]) {
          results[i]['blur']();
        }
      }
    }
  }

  close() {
    this.term = '';
    this.searching = false;
    this.results = { term: '' };
    this.input.nativeElement.blur();
  }

  ngOnInit(): void {
    const those = this;
    this.include = this.includes[0];
    this.termSubscription = this.termChanged.pipe(debounceTime(500)).subscribe(() => {
      if (!those.results || !those.results.term || (those.results.term !== those.term && those.term.length > 2)) {
        those.search();
      }
    });
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info['User']) {
        info['User']['Role'] = ['Root', 'Admin', 'Manager'][info['User']['Role']];
      }
      if (info['Ui'] && info['Ui']['Build'] && Number(info['Ui']['Build']) > credentials.build) {
        this.upgrade = true;
      }
      those.info = info;
      this.refresh();
    });
    this.apiService.getWebsocket().addEventListener('message', event => {
      const message = JSON.parse(event.data);
      switch (message.Type) {
        case 'WebsocketMessageEvent':
          switch (message.Event.Name) {
            case 'Connected':
              those.id = message.Event.Id;
              those.clients.push(those.id);
              those.clients.sort((a, b) => a - b);
              break;
            case 'Disconnected':
              those.clients = those.clients.filter(item => item !== message.Event.Id);
              break;
            case 'ClientsListUpdated':
              those.clients = message.Event.Ids;
              those.clients.sort((a, b) => a - b);
              break;
            case 'Changed':
              those.notifications.push(message.Message);
              break;
          }
          break;
        case 'WebsocketMessageText':
          if (message.To && message.To === those.id && message.Message.indexOf('/force reload') === 0) {
            location.reload();
            return;
          }
          //
          if (message.To && message.To === those.id && !those.opened) {
            those.onOpenChat(those.content);
          }
          those.messages.push({ ...message, Date: new Date() });
          const container = document.querySelector('.messages');
          if (container) {
            setTimeout(() => {
              container.scrollTop = container.scrollHeight;
            }, 100);
          }
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onConnect() {
    const those = this;
    this.ws = new WebSocket(this.apiService.getBaseUrl().replace(/^http/, 'ws') + '/ws');
    this.ws.onopen = evt => {
      console.log('open');
      setInterval(() => {
        if (those.ws) {
          try {
            those.ws.send(JSON.stringify({ Type: 'WebsocketMessagePing' }));
          } catch (err) {
            console.log(err);
          }
        }
      }, 60000);
    };
    this.ws.onclose = evt => {
      console.log('close');
      setTimeout(() => {
        those.onConnect();
      }, 1000);
    };
    this.ws.onmessage = evt => {
      console.log('onmessage', evt.data);
    };
    this.ws.onerror = evt => {
      console.log('onerror', evt.data);
    };
  }

  onOpenChat(content) {
    this.opened = true;
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    this.modal.result.then(
      result => {
        this.opened = false;
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.opened = false;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  onSelect(client, input) {
    input.value = '#' + client + ', ';
    input.focus();
  }

  onSend(input) {
    if (input.value) {
      const pair = input.value.match(/^#(\d+), (.+)/);
      if (pair && pair.length >= 2) {
        const index = this.clients.findIndex(item => item === pair[1]);
        if (index >= 0) {
          this.ws.send(JSON.stringify({ Type: 'WebsocketMessageText', To: pair[1], Message: input.value }));
        }
      } else {
        this.ws.send(JSON.stringify({ Type: 'WebsocketMessageText', Message: input.value }));
      }
      input.value = '';
    }
  }

  setCount(count) {
    this.count = count;
    this.refresh();
  }

  setInclude(include) {
    this.include = include;
    this.search();
  }

  search = async () => {
    const those = this;
    const client = this.apiService.getHttp();
    const endpoint = this.apiService.getUrl();
    const term = this.term;
    if (this.term) {
      const entities = [
        { name: 'menu', title: 'menu' },
        { name: 'customers', title: 'customer' },
        { name: 'categories', title: 'category' },
        { name: 'products', title: 'product' },
        { name: 'options', title: 'option' },
        { name: 'values', title: 'value' },
        { name: 'dimensions', title: 'custom fields' },
        { name: 'metrics', title: 'value' },
        { name: 'posts', title: 'post' },
        { name: 'tags', title: 'tag' },
        { name: 'vendors', title: 'vendor' },
        { name: 'orders', title: 'order' },
      ];
      //
      this.results = { term: those.term };
      this.loading = true;
      for (let i = 0; i < entities.length; i++) {
        const include = this.includes.find(item => item.name === entities[i].name);
        if (include?.selected) {
          //console.log('ask', entities[i].name);
          switch (entities[i].name) {
            case 'menu':
              const results = [];
              those.navigation
                .filter(
                  item =>
                    item.path.toLowerCase().indexOf(those.term.toLowerCase()) > -1 ||
                    item.title.toLowerCase().indexOf(those.term.toLowerCase()) > -1
                )
                .forEach(item => {
                  results.push({
                    Title: item.title,
                    Description: item.description ? item.description : 'description will be here',
                    Path: item.path,
                    Icon: 'navigation-right-arrow',
                    Type: item.type,
                  });
                });
              if (results) {
                results.sort((a, b) => {
                  return those.levenshtein(JSON.stringify(a), JSON.stringify(b)) * -1;
                });
                those.results['menu'] = {
                  Label: 'Admin',
                  Results: results,
                };
              }
              break;
            default:
              await client
                .post(endpoint + '/' + entities[i].name + '/list', {
                  Start: 0,
                  Length: 20,
                  Search: term,
                  Strict: true,
                })
                .toPromise()
                .then(resp => {
                  if (resp['Data'] && resp['Data'].length > 0) {
                    const results = [];
                    for (let j = 0; j < resp['Data'].length; j++) {
                      let thumbnail;
                      // Customer
                      if (resp['Data'][j].Login) {
                        resp['Data'][j].Title = `#${resp['Data'][j].ID}&nbsp;`;
                        if (resp['Data'][j].Country) {
                          resp['Data'][
                            j
                          ].Title += `&nbsp;<img src="/assets/fonts/flag-icon/${resp['Data'][j].Country}.svg" style="width: 22px;height: 14px;" />&nbsp; `;
                        }
                        if (resp['Data'][j].Name) {
                          resp['Data'][j].Title += `${resp['Data'][j].Name}&nbsp;`;
                        }
                        if (resp['Data'][j].Lastname) {
                          resp['Data'][j].Title += `${resp['Data'][j].Lastname}&nbsp;`;
                        }
                        if (resp['Data'][j].City) {
                          const country = this.countries.find(
                            item =>
                              resp['Data'][j]['Country'] && item['Code'] === resp['Data'][j]['Country'].toUpperCase()
                          );
                          if (country) {
                            resp['Data'][j].Title += `${resp['Data'][j].City}, ${country.Name} `;
                          }
                        }
                        resp['Data'][j].Description = resp['Data'][j].Email;
                      }
                      // Orders
                      if (resp['Data'][j].Total) {
                        resp['Data'][j].Title = `#${resp['Data'][j].ID}&nbsp;`;
                        if (resp['Data'][j].BillingProfileCountry) {
                          resp['Data'][
                            j
                          ].Title += `&nbsp;<img src="/assets/fonts/flag-icon/${resp['Data'][j].BillingProfileCountry}.svg" style="width: 22px;height: 14px;" />&nbsp; `;
                        }
                        if (resp['Data'][j].BillingProfileName) {
                          resp['Data'][j].Title += `${resp['Data'][j].BillingProfileName}&nbsp;`;
                        }
                        if (resp['Data'][j].BillingProfileLastname) {
                          resp['Data'][j].Title += `${resp['Data'][j].BillingProfileLastname}&nbsp;`;
                        }
                        if (resp['Data'][j].BillingProfileCity) {
                          const country = this.countries.find(
                            item =>
                              resp['Data'][j]['BillingProfileCountry'] &&
                              item['Code'] === resp['Data'][j]['BillingProfileCountry'].toUpperCase()
                          );
                          if (country) {
                            resp['Data'][j].Title += `${resp['Data'][j].BillingProfileCity}, ${country.Name} `;
                          }
                        }
                        if (resp['Data'][j].BillingStatus || resp['Data'][j].ShippingStatus) {
                          const statuses = [];
                          if (resp['Data'][j].BillingStatus) {
                            statuses.push(resp['Data'][j].BillingStatus);
                          }
                          if (resp['Data'][j].ShippingStatus) {
                            statuses.push(resp['Data'][j].ShippingStatus);
                          }
                          resp['Data'][j].Description = statuses.join(' / ');
                        }
                      }
                      //
                      if (resp['Data'][j].Thumbnail) {
                        if (resp['Data'][j].Thumbnail.substring(0, 1) === '/') {
                          thumbnail = those.absolutizeUrl.transform(
                            '/api/v1/resize?path=/storage' + resp['Data'][j].Thumbnail
                          );
                        } else if (
                          resp['Data'][j].Thumbnail &&
                          (resp['Data'][j].Thumbnail.indexOf('http://') === 0 ||
                            resp['Data'][j].Thumbnail.indexOf('https://') === 0)
                        ) {
                          thumbnail = resp['Data'][j].Thumbnail.split(',')[0];
                        }
                      }
                      let path = entities[i].name;
                      let query = {};
                      //
                      if (entities[i].name === 'customers') {
                        path = `/sales/customers/${resp['Data'][j].ID}`;
                      } else if (entities[i].name === 'orders') {
                        path = `/sales/orders/${resp['Data'][j].ID}`;
                      } else if (entities[i].name === 'values') {
                        path = `/options/${resp['Data'][j].OptionId}`;
                        query = { value_id: resp['Data'][j].ID };
                      } else if (entities[i].name === 'metrics') {
                        path = `/dimensions/${resp['Data'][j].DimensionId}`;
                        query = { metric_id: resp['Data'][j].ID };
                      } else {
                        path += '/' + resp['Data'][j].ID;
                      }
                      //
                      if (entities[i].name === 'categories') {
                        if (resp['Data'][j].Titles) {
                          resp['Data'][j].Title =
                            resp['Data'][j].Titles.join(' / ') + ' / &nbsp;' + resp['Data'][j].Title;
                        }
                      } else if (entities[i].name === 'products') {
                        if (resp['Data'][j].Sku) {
                          resp['Data'][j].Title += ' (' + resp['Data'][j].Sku + ')';
                        }
                        if (!thumbnail) {
                          thumbnail = those.absolutizeUrl.transform(
                            '/api/v1/resize?path=/assets/images/no-image.svg'
                          );
                        }
                      }
                      results.push({
                        ID: resp['Data'][j].ID,
                        Name: resp['Data'][j].Name,
                        Title: resp['Data'][j].Title,
                        Description: resp['Data'][j].Description,
                        Thumbnail: thumbnail ? thumbnail : undefined,
                        Path: path,
                        Query: query,
                        Type: entities[i].title,
                      });
                    }
                    if (results) {
                      results.sort((a, b) => {
                        return those.levenshtein(JSON.stringify(a), JSON.stringify(b)) * -1;
                      });
                      if (!those.results[include.name]) {
                        those.results[include.name] = {
                          //Label: `${include.label} results`,
                          Label: include.label,
                          Results: [],
                        };
                      }
                      those.results[include.name].Results = those.results[include.name].Results.concat(results);
                    }
                  }
                })
                .catch(err => {
                  console.log('err', err);
                });
          }
        } else {
          //console.log('skip', entities[i].name);
        }
      }
      this.loading = false;
    }
    this.all = this.includes.length === this.includes.filter(item => item.selected).length;
  };

  refresh() {
    if (
      this.info &&
      this.info['HasChanges'] &&
      this.info['HasChanges']['Messages'] &&
      this.info['HasChanges']['Messages'].length > 0
    ) {
      this.notifications = this.info['HasChanges']['Messages'];
    }
  }

  keys(object) {
    return Object.keys(object);
  }

  slice(array, count): string[] {
    return array.slice(0, this.count);
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  levenshtein(str1 = '', str2 = '') {
    const track = Array(str2.length + 1)
      .fill(null)
      .map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
      track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
      track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
      for (let i = 1; i <= str1.length; i += 1) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(track[j][i - 1] + 1, track[j - 1][i] + 1, track[j - 1][i - 1] + indicator);
      }
    }
    return track[str2.length][str1.length];
  }

  toggleAll(event) {
    for (let i = 0; i < this.includes.length; i++) {
      this.includes[i]['selected'] = !this.all;
    }
    event.preventDefault();
    event.stopPropagation();
    this.search();
  }

  setLanguage(language) {
    this.language = language;
    this.localStorageService.set('language', language);
    this.translate.use(language);
    location.reload();
  }

  logout() {
    const those = this;
    this.apiService
      .logout()
      .then(obj => {
        if (obj['MESSAGE'] === 'OK') {
          those.apiService
            .getInfo()
            .then(info => {
              those.subjectService.infoSubject.next(info);
              those.router.navigate(['/auth/login']);
            })
            .catch(err => {
              console.log('err', err);
            });
        }
      })
      .catch(err => {
        const obj = err.error;
        if (err.status === 500) {
          if (obj && obj['ERROR']) {
            alert(obj['ERROR']);
          } else {
            alert('Something went wrong');
          }
        }
      });
  }
}
