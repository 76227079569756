import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {credentials} from '../../../shared/credentials.data';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class PaymentComponent implements OnInit {
  form;
  countries = credentials.countries;
  molliePaymentMethods = [
    {Enabled: false, Name: 'applepay', Title: ''},
    {Enabled: false, Name: 'bancontact', Title: ''},
    {Enabled: false, Name: 'banktransfer', Title: ''},
    {Enabled: false, Name: 'belfius', Title: ''},
    {Enabled: false, Name: 'creditcard', Title: ''},
    {Enabled: false, Name: 'eps', Title: ''},
    {Enabled: false, Name: 'giftcards', Title: ''},
    {Enabled: false, Name: 'giropay', Title: ''},
    {Enabled: false, Name: 'ideal', Title: ''},
    {Enabled: false, Name: 'kbc', Title: ''},
    {Enabled: false, Name: 'klarnapaylater', Title: ''},
    {Enabled: false, Name: 'klarnasliceit', Title: ''},
    {Enabled: false, Name: 'paypal', Title: ''},
    {Enabled: false, Name: 'paysafecard', Title: ''},
    {Enabled: false, Name: 'przelewy24', Title: ''},
    {Enabled: false, Name: 'sofort', Title: ''},
  ];

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, public translate: TranslateService) { }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getSettings('payment').then(resp => {
      those.form = those.formBuilder.group({
        Enabled: [resp.Enabled ? resp.Enabled : false],
        Default: [resp.Default ? resp.Default : 'stripe'],
        Country: [resp.Country ? resp.Country : 'DE'],
        VAT: [resp.VAT ? resp.VAT : 19],
        Stripe: those.formBuilder.group({
          Enabled: [resp.Stripe?.Enabled ? resp.Stripe?.Enabled : false],
          PublishedKey: [resp.Stripe?.PubliTshedKey ? resp.Stripe?.PublishedKey : ''],
          SecretKey: [resp.Stripe?.SecretKey ? resp.Stripe?.SecretKey : ''],
        }),
        Mollie: those.formBuilder.group({
          Enabled: [resp.Mollie?.Enabled ? resp.Mollie?.Enabled : false],
          Key: [resp.Mollie?.Key ? resp.Mollie?.Key : ''],
          ProfileID: [resp.Mollie?.ProfileID ? resp.Mollie?.ProfileID : ''],
          PaymentMethods: those.formBuilder.array([])
        }),
        AdvancePayment: those.formBuilder.group({
          Enabled: [resp.AdvancePayment?.Enabled ? resp.AdvancePayment?.Enabled : false],
          Details: [resp.AdvancePayment?.Details ? resp.AdvancePayment?.Details : ''],
        }),
        OnDelivery: those.formBuilder.group({
          Enabled: [resp.OnDelivery?.Enabled ? resp.OnDelivery?.Enabled : false],
        }),
      });
      // Mollie PaymentMethods
      const molliePaymentMethods = this.form.get('Mollie').get('PaymentMethods');
      this.molliePaymentMethods.forEach(item => {
        const method = resp.Mollie?.PaymentMethods?.find(item2 => item2.Name === item.Name);
        molliePaymentMethods.push(those.formBuilder.group({
          Enabled: [method ? method.Enabled : false],
          Name: [item.Name],
          Title: [method ? method.Title : ''],
        }));
      });
    }).catch(err => {
      those.error('Get payment settings', err);
    });
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putSettings('payment', raw).then(resp => {
      those.success('Update payment settings', resp);
    }).catch(err => {
      those.error('Update payment settings', err);
    });
  }
}
