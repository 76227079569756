<div class="drop-area" [ngClass]="{preview: previews && previews.length > 0}">
  <div *ngIf="files" class="gallery">
    <div *ngFor="let preview of previews" class="gallery-item">
      <img [src]="preview.src" class="gallery__image" [alt]="preview.name" />
      <div class="gallery__label">
        <div class="body-4-regular">{{preview.name}}</div>
        <div class="progress gallery__progress">
          <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="gallery__remove">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="10" cy="10" r="10" fill="#D0D4E4"/>
          <rect x="7.87891" y="6.46458" width="8" height="2" transform="rotate(45 7.87891 6.46458)" fill="white"/>
          <rect x="13.5356" y="7.8788" width="8" height="2" transform="rotate(135 13.5356 7.8788)" fill="white"/>
        </svg>
      </div>
    </div>
  </div>
  <div
    [ngClass]="{ 'drop-area__active': isDraggedOver }"
    (drop)="handleDrop($event)"
    (dragover)="handleDragOver($event)"
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
  >
    <div class="file-loader__container" (click)="file.click()">
      <i class="fa fa-cloud-upload drop-loader__icon" aria-hidden="true"></i>
      <div class="file-loader__title" [innerHTML]="title|translate"></div>
      <div class="file-loader__message" [innerHTML]="message|translate"></div>
    </div>
    <div class="file-loader">
      <input
        class="file-loader__input"
        (change)="onFileChange($event)"
        type="file"
        accept="image/png, image/jpeg"
        #file
      />
    </div>
  </div>
</div>
