<form *ngIf="form" [formGroup]="form">
  <div class="row" style="padding-bottom: 10px">
    <div *ngIf="expanded" class="col">
      <label class="mb-1" translate>Name</label>
      <input type="text" class="form-control" [placeholder]="'new' | translate" (change)="onChangeName($event)"
             formControlName="Name">
    </div>
    <div *ngIf="!form.get('ID').value && form.get('OptionId').value && options" class="col">
      <label class="mb-1" translate>Option</label>
      <ngx-bootstrap-multiselect *ngIf="options" id="options" class="multiselect single-selected" [options]="optionsOptions"
                               [settings]="settings"
                               formControlName="OptionId"></ngx-bootstrap-multiselect>
    </div>
    <div class="col">
      <label class="mb-1" (click)="expanded=!expanded" translate>Display Name</label>
      <input type="text" class="form-control" required="true" [placeholder]="'New' | translate"
             (change)="onChangeTitle($event)" formControlName="Title">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label class="mb-1" translate>Type</label>
    </div>
  </div>
  <div class="row border-bottom pb-3 mb-3">
    <div class="col">
      <app-select [options]="[{Name: 'select', Label: 'Select'}, {Name: 'rectangle', Label: 'Rectangle'}, {Name: 'swatch', Label: 'Swatch'}, {Name: 'radio', Label: 'Radio'}]" formControlName="Type"></app-select>
    </div>
    <div *ngIf="['radio', 'swatch'].indexOf(form.get('Type').value) >= 0" class="col">
      <app-select [options]="[{Name: 'small', Label: 'Small'}, {Name: 'medium', Label: 'Medium'}, {Name: 'large', Label: 'Large'}]" formControlName="Size"></app-select>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h2 class="color-primary-violet" translate>Values</h2>
    </div>
  </div>

  <div *ngIf="form.get('Rates')['length'] > 0" class="row pb-1">
    <div class="col">
      <div style="font-size: 14px;" [innerHTML]="form.get('Rates')['length'] + ' ' + ('values in this option' | translate)"></div>
    </div>
    <div class="col" style="text-align: right">
      <span class="btn btn-fourth" style="font-size:14px; padding: 0;" (click)="toggleAll()" [innerHTML]="((expandedAll ? 'Collapse' : 'Extend' ) + ' ' + 'Values') | translate"></span>
    </div>
  </div>
  <div *ngIf="form.controls['Rates']['length'] > 0;else norates" class="row" style="padding-bottom: 15px;">
    <div class="col col-wide" style="background-color: white;">
      <div [attr.id]="'property-' + index + '-rates-inline-accordion'" class="accordion"
           [dragula]="'property-' + index + '-rates'" [(dragulaModel)]="form.controls['Rates']['controls']">
        <div *ngFor="let rateControl of form.controls['Rates']['controls'];let i = index" class="card inline-card">
          <div [attr.id]="'property-' + index + '-rate-inline-heading-' + i"
               class="card-header inline-heading draggable-wrapper" style="padding: 10px 10px 10px 5px">
            <div style="display: flex; align-items: center;">
              <div class="btn-sm btn-default" style="height: 26px; padding-left: 2px;">
                <svg class="fill-black draggable draggable-point draggable-rate-point" style="height: 16px; width: 16px;">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-bid"></use>
                </svg>
              </div>
              <div class="color-primary-violet cursor-pointer" style="display: flex;align-items: center;" data-toggle="collapse" (click)="check()"
                   [attr.data-target]="'#property-' + index + '-rate-inline-collapse-' + i"
                   [attr.aria-controls]="'property-' + index + '-rate-inline-collapse-' + i">
                <span class="link3">{{rateControl.get('Value').get('Title').value}}</span>
                <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                  <use [attr.xlink:href]="'/assets/icons/bi-symbol-defs.svg#icon-BiChevron' + 'Down'"></use>
                </svg>
              </div>
            </div>
            <div style="display: inline-block; float: right">
              <div class="inline-heading-buttons">
                <div *ngIf="!form.get('OptionId').value" class="btn-sm btn-default" (click)="duplicateRate(i)">
                  <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy"></use>
                  </svg>
                </div>
                <div class="btn-sm btn-default delete" (click)="deleteRate(i)">
                  <svg style="height: 16px; width: 16px;">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div [attr.id]="'property-' + index + '-rate-inline-collapse-' + i"
               class="collapse inline-body" [ngClass]="{show: rateControl.get('Opened').value}"
               [attr.aria-labelledby]="'property-' + index + '-rate-inline-heading-' + i"
               [attr.data-parent]="'#property-' + index + '-rates-inline-accordion'">
            <div class="card-body">
              <app-rate-inline [basePrice]="basePrice"
                               [salePrice]="salePrice"
                               [form]="rateControl"
                               [optionId]="rateControl.get('Value').get('OptionId').value"
                               [tinymceConfig]="tinymceConfig"></app-rate-inline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #norates>
    <div style="text-align: center">
      <svg class="fill-primary-violet" style="height: 50px; width: 50px;">
        <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiSelectMultiple"></use>
      </svg>
      <div class="body-3 color-text-gray" style="padding: 10px 0 20px 0">
        <span translate>You can add custom values to use in pricing</span><br/>
        <span translate>Add as many (or as few) as you need</span>
      </div>
    </div>
  </ng-template>
  <div *ngIf="!form.get('OptionId').value" class="row" style="padding-bottom: 15px;">
    <div class="col" style="text-align: center">
      <span class="btn btn-third facon-plus" (click)="addRate()" [innerHtml]="(form.get('Rates')['length'] > 0 ? 'Add Another Value' : 'Add Value') | translate"></span>
    </div>
  </div>
</form>
