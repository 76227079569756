import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-option',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!option || !option['ID']" class="modal-title" id="exampleModalLabel" translate>Add Option</h5>
        <h5 *ngIf="option && option['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Option</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Title</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Name</label>
                  <input class="form-control" id="name" type="text" formControlName="Name" />
                </div>
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <textarea class="form-control" id="description" rows="3" formControlName="Description"></textarea>
                </div>
              </div>
              <div class="col">
                <div *ngIf="option && option['ID']" class="form-group">
                  <label for="description" class="mb-1" translate>Infoboxes</label>
                  <div>
                    <div>
                      <app-edit-block [optionId]="option['ID']" (onSaved)="addBlock()" #blockEditor></app-edit-block>
                      <table class="table" style="margin-bottom: 0">
                        <tbody>
                          <tr *ngFor="let block of option['Blocks']; let i = index">
                            <td>
                              <span class="link2" (click)="blockEditor.onEdit(blockEditor.content, block['ID'])">{{
                                block['Post']['Title']
                              }}</span>
                            </td>
                            <td>
                              <div style="text-align: right;">
                                <span
                                  title="delete"
                                  class="btn-sm btn-default facon-minus"
                                  style="font-size: 16px;"
                                  (click)="blockEditor.onDelete(block['ID'])"></span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="row" style="padding-bottom: 20px">
                      <div class="col" style="">
                        <span
                          class="btn btn-xs btn-secondary add"
                          style="margin-right: 10px;text-transform: none;"
                          (click)="blockEditor.onCreate(blockEditor.content)"
                          translate
                          >Add Infobox</span
                        >
                        <span
                          class="btn btn-xs btn-secondary add"
                          style="float: right; text-transform: none;"
                          (click)="blockEditor.onCreate(blockEditor.content, 'select')"
                          translate
                          >Add infobox From Library</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="options && options.length > 0" class="form-group">
                  <label for="default" class="mb-1" translate>Default Value</label>
                  <ngx-bootstrap-multiselect
                    id="options"
                    class="multiselect"
                    [options]="options"
                    [settings]="settings"
                    [texts]="texts"
                    formControlName="ValueId"></ngx-bootstrap-multiselect>
                </div>
                <div class="form-group">
                  <div class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                    <label class="mb-1" style="margin-right: 5px;padding-right: 5px;" translate>Type</label>
                    <label class="d-block" for="select">
                      <input class="radio_animated" id="select" type="radio" value="select" formControlName="Type" />
                      {{ 'Select' | translate }}
                    </label>
                    <label class="d-block" for="rectangle">
                      <input
                        class="radio_animated"
                        id="rectangle"
                        type="radio"
                        value="rectangle"
                        formControlName="Type" />
                      {{ 'Rectangle' | translate }}
                    </label>
                    <label class="d-block" for="swatch">
                      <input class="radio_animated" id="swatch" type="radio" value="swatch" formControlName="Type" />
                      {{ 'Swatch' | translate }}
                    </label>
                    <label class="d-block" for="radio">
                      <input class="radio_animated" id="radio" type="radio" value="radio" formControlName="Type" />
                      {{ 'Radio' | translate }}
                    </label>
                  </div>
                </div>
                <div *ngIf="['radio', 'swatch'].indexOf(form.get('Type').value) >= 0" class="form-group">
                  <div class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                    <label class="mb-1" style="margin-right: 5px;padding-right: 15px;" translate>Size</label>
                    <label class="d-block" for="small">
                      <input class="radio_animated" id="small" type="radio" value="small" formControlName="Size" />
                      {{ 'Small' | translate }}
                    </label>
                    <label class="d-block" for="medium">
                      <input class="radio_animated" id="medium" type="radio" value="medium" formControlName="Size" />
                      {{ 'Medium' | translate }}
                    </label>
                    <label class="d-block" for="large">
                      <input class="radio_animated" id="large" type="radio" value="large" formControlName="Size" />
                      {{ 'Large' | translate }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="d-block" for="standard">
                    {{ 'Standard' | translate }}&nbsp;
                    <input class="checkbox_animated" id="standard" type="checkbox" formControlName="Standard" />
                  </label>
                </div>
                <div class="form-group">
                  <label class="d-block" for="samples" [title]="'display free samples select button' | translate">
                    {{ 'Samples' | translate }}
                    <input class="checkbox_animated" id="samples" type="checkbox" formControlName="Samples" />&nbsp;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      .table tbody tr:first-child td {
        border-top: none !important;
      }
      span {
        font-sort: bolder;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class OptionComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  option;
  modal;
  closeResult;
  form;
  options: IMultiSelectOption[] = [];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'fontawesome',
    selectionLimit: 1,
  };
  texts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    this.texts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: this.translate.instant('Find'),
      defaultTitle: this.translate.instant('Select'),
      allSelected: 'All selected',
    };
  }

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content, options?) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Description: [''],
      Type: ['select'],
      Size: ['medium'],
      ValueId: [],
      Samples: [false],
      Standard: [false],
    });
    //
    those.modal = those.modalService.open(content, {
      ...options,
      ...{ ariaLabelledBy: 'modal-basic-title', size: 'xl' },
    });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getOption(id)
      .then(option => {
        those.option = option;
        those.form = those.formBuilder.group({
          ID: [option.ID],
          Name: [option.Name, Validators.required],
          Title: [option.Title, Validators.required],
          Description: [option.Description],
          Type: [option.Type ? option.Type : 'select'],
          Size: [option.Size ? option.Size : 'medium'],
          ValueId: [option.Value && option.Value.ID ? option.Value.ID : undefined],
          Samples: [option.Samples ? true : false],
          Standard: [option.Standard ? true : false],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
        //
        those.apiService
          .getValues(id)
          .then(resp => {
            those.options = resp.map(item => {
              return { id: item.ID, name: item.Title };
            });
          })
          .catch(err => {
            those.error('Load values', err);
          });
        //
      })
      .catch(err => {
        those.error('Load option', err);
      });
  }

  onCustom(id) {
    console.log('id', id);
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete option?';
    modal.componentInstance.body = `Are you sure you want to delete option? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteOption(id)
          .then(resp => {
            those.success('Delete option', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete option', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  onSubmit() {
    const those = this;
    const raw = { ...this.option, ...this.form.getRawValue() };
    if (raw.ValueId) {
      raw.ValueId = raw.ValueId[0];
    } else {
      raw.ValueId = 0;
    }
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postOption(raw)
        .then(resp => {
          those.success('Create option', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create option', err);
        });
    } else {
      // update
      this.apiService
        .putOption(this.form.get('ID').value, raw)
        .then(resp => {
          those.success('Update option', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update option', err);
        });
    }
  }

  addBlock() {
    this.apiService
      .getOption(this.option['ID'])
      .then(option => {
        this.option = option;
      })
      .catch(err => {
        this.error('Get option', err);
      });
  }
}
