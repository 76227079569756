import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class MediaComponent implements OnInit {
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, public translate: TranslateService) { }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getSettings('media').then(resp => {
      those.form = those.formBuilder.group({
        Resize: those.formBuilder.group({
          Enabled: [resp.Resize?.Enabled ? resp.Resize?.Enabled : false],
          Quality: [resp.Resize?.Quality ? resp.Resize?.Quality : 50],
          Thumbnail: those.formBuilder.group({
            Enabled: [resp.Resize?.Thumbnail?.Enabled ? resp.Resize?.Thumbnail?.Enabled : false],
            Size: [resp.Resize?.Thumbnail?.Size ? resp.Resize?.Thumbnail?.Size : '64x0,128x0,256x0'],
          }),
          Image: those.formBuilder.group({
            Enabled: [resp.Resize?.Image?.Enabled ? resp.Resize?.Image?.Enabled : false],
            Size: [resp.Resize?.Image?.Size ? resp.Resize?.Image?.Size : '128x0,324x0,512x0'],
          })
        }),
        Storage: those.formBuilder.group({
          Enabled: [resp.Storage?.Enabled ? resp.Storage?.Enabled : false],
          S3: those.formBuilder.group({
            Enabled: [resp.Storage?.S3?.Enabled ? resp.Storage?.S3?.Enabled : false],
            AccessKeyID: [resp.Storage?.S3?.AccessKeyID ? resp.Storage?.S3?.AccessKeyID : ''],
            SecretAccessKey: [resp.Storage?.S3?.SecretAccessKey ? resp.Storage?.S3?.SecretAccessKey : ''],
            Region: [resp.Storage?.S3?.Region ? resp.Storage?.S3?.Region : ''],
            Bucket: [resp.Storage?.S3?.Bucket ? resp.Storage?.S3?.Bucket : ''],
            CDN: [resp.Storage?.S3?.CDN ? resp.Storage?.S3?.CDN : ''],
          })
        })});
    }).catch(err => {
      those.error('Get settings', err);
    });
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putSettings('media', raw).then(resp => {
      those.success('Update settings', resp);
    }).catch(err => {
      those.error('Update settings', err);
    });
  }
}
