import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'angular-2-local-storage';
import { SubjectService } from '../../../shared/subject.service';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PricesBlockComponent } from './prices-block.component';
import { credentials } from '../../../shared/credentials.data';
import { BlocksBlockComponent } from './blocks-block.component';
import { PropertiesBlockComponent } from './properties-block.component';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(BlocksBlockComponent) blocksBlock: BlocksBlockComponent;
  @ViewChild(PropertiesBlockComponent) propertiesBlock: PropertiesBlockComponent;
  @ViewChild(PricesBlockComponent) pricesBlock: PricesBlockComponent;
  paramsSubscription: Subscription;
  queryParamsSubscription: Subscription;
  categoryId;
  id;
  product;
  defaultProduct;
  form;
  formSubscription: Subscription;
  raw = false;
  changed = false;
  decimal = ',';
  prefix = '';
  thousands = '.';
  pattern = 'whd';
  dimensionUnit = 'cm';
  weightUnit = 'kg';
  tinymceConfig;
  info;
  infoSubscription: Subscription;
  maximized = false;
  displayed = false;

  landings;
  landing;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private subjectService: SubjectService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private titleService: Title,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const those = this;
    /*setTimeout(() => {
      those.show = true;
    }, 3000);*/
    //
    this.infoSubscription = this.subjectService.infoSubject.subscribe(info => {
      those.info = info;
      those.decimal = info.Decimal ? info.Decimal : '.';
      those.thousands = info.Thousand ? info.Thousand : '';
      those.prefix = info.Symbol ? info.Symbol : '$';
      those.pattern = info.Pattern;
      those.dimensionUnit = info.DimensionUnit;
      those.weightUnit = info.WeightUnit;
    });
    //
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params.id) {
        if (params.id === 'new') {
          // create
          setTimeout(() => {
            const product = { Enabled: true, Categories: [] };
            if (those.categoryId) {
              product.Categories = [{ ID: those.categoryId }];
            }
            those.form = those.createForm(product);
          });
        } else {
          // load
          those.apiService
            .getProduct(params.id)
            .then(product => {
              those.titleService.setTitle(product.Title + ' :: ' + credentials.name);
              product.Blocks = product.Blocks || [];
              product.Medias = product.Medias || [];
              product.Properties = product.Properties || [];
              product.Prices = product.Prices || [];
              product.Tags = product.Tags || [];
              those.form = those.createForm(product);
              those.product = product;
              those.defaultProduct = JSON.parse(JSON.stringify(product));
            })
            .catch(err => {
              those.error('Get product', err);
              console.log(err);
            });
        }
      }
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params.category_id) {
        this.categoryId = params.category_id;
      }
    });
    //
    /*this.apiService
      .getLandings()
      .then(landings => {
        those.landings = landings;
        those.landing = landings[landings.length - 1];
      })
      .catch(err => {
        console.log(err);
      });*/
    //
    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: true,
      toolbar:
        'undo redo | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | ' +
        'forecolor backcolor emoticons',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  ngAfterViewInit(): void {
    $('body').scrollspy({ offset: 100, target: '#navbar-left' });
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(credentials.name);
    this.infoSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  scroll(id) {
    const yOffset = -90;
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return false;
  }

  createForm(product) {
    const those = this;
    const form = this.formBuilder.group({
      ID: [product.ID],
      Enabled: [product.Enabled ? true : false],
      Name: [product.Name, [Validators.required]],
      Title: [product.Title, [Validators.required]],
      //Thumbnail: [product.Thumbnail ? those.absolutizeUrl.transform('/storage' + product.Thumbnail) : ''],
      Description: [product.Description ? product.Description : ''],
      Notes: [product.Notes ? product.Notes : ''],
      Container: [{ disabled: product.Container, value: product.Container ? product.Container : false }],
      Type: [product.Type ? product.Type : 'select'],
      Size: [product.Size ? product.Size : 'medium'],
      Content: [product.Content],
      LandingId: [product.LandingId],
      MediaId: [
        product.MediaId ? product.MediaId : product.Images && product.Images.length > 0 ? product.Images[0].ID : '',
      ],
      Parameters: [product.Parameters ? product.Parameters : '[]'],
      Pattern: [product.Pattern ? product.Pattern : this.pattern],
      Dimensions: [product.Dimensions ? product.Dimensions : ''],
      DimensionUnit: [product.DimensionUnit ? product.DimensionUnit : this.dimensionUnit],
      Width: [product.Width ? product.Width : 0],
      Height: [product.Height ? product.Height : 0],
      Depth: [product.Depth ? product.Depth : 0],
      Volume: [product.Volume ? product.Volume : 0.0],
      Weight: [product.Weight ? product.Weight : 0.0],
      WeightUnit: [product.WeightUnit ? product.WeightUnit : this.weightUnit],
      Packages: [product.Packages ? product.Packages : 1],
      Sku: [product.Sku ? product.Sku : ''],
      Barcode: [product.Barcode ? product.Barcode : ''],
      Availability: [product.Availability ? product.Availability : 'available'],
      TrackQuantity: [product.TrackQuantity ? product.TrackQuantity : false],
      Overselling: [product.Overselling ? product.Overselling : false],
      Stock: [product.Stock ? product.Stock : 0],
      //Customization: [product.Customization ? product.Customization : '{}'],
      BasePrice: [product.BasePrice ? product.BasePrice : 0],
      ManufacturerPrice: [product.ManufacturerPrice ? product.ManufacturerPrice : null],
      ItemPrice: [product.ItemPrice ? product.ItemPrice : null],
      OnSale: [product.OnSale ? true : false],
      SalePrice: [product.SalePrice ? product.SalePrice : null],
      Start: [
        product.Start && product.Start.indexOf('0001-01-01') === -1
          ? moment(product.Start).format('YYYY-MM-DD HH:mm:ss')
          : null,
      ],
      End: [
        product.End && product.End.indexOf('0001-01-01') ? moment(product.End).format('YYYY-MM-DD HH:mm:ss') : null,
      ],
      AdvancedPurchasability: [product.AdvancedPurchasability ? true : false],
      MinQuantity: [product.MinQuantity ? product.MinQuantity : 0],
      MaxQuantity: [product.MaxQuantity ? product.MaxQuantity : 0],
      PurchasableMultiply: [product.PurchasableMultiply ? product.PurchasableMultiply : 0],
      Measurement: [product.Measurement ? true : false],
      BaseMeasurement: [product.BaseMeasurement ? product.BaseMeasurement : 0],
      BaseMeasurementUnit: [product.BaseMeasurementUnit ? product.BaseMeasurementUnit : 'kg'],
      TotalMeasurement: [product.TotalMeasurement ? product.TotalMeasurement : 0],
      TotalMeasurementUnit: [product.TotalMeasurementUnit ? product.TotalMeasurementUnit : 'kg'],
      AdvancedPricing: [product.DiscountType ? true : false],
      Discount: [product.Discount ? true : false],
      DiscountType: [product.DiscountType ? product.DiscountType : 'percent'],
      /**/
      //Properties: [''], // virtual object
      Prices: [''], // virtual object
      /**/
      Categories: [product.Categories || []],
      Tags: [product.Tags || []],
      Vendors: [product.VendorId ? [product.VendorId] : []],
      Times: [product.TimeId ? [product.TimeId] : []],
      RelatedProducts: [product.RelatedProducts || []],
    });
    form.addControl(
      'Tiers',
      this.formBuilder.array(
        (product.Tiers || []).map(tier => {
          return this.formBuilder.group({
            MinQuantity: [tier.MinQuantity, [Validators.required]],
            Discount: [tier.Discount, [Validators.required]],
          });
        })
      )
    );
    // Customization
    const customization = this.formBuilder.group({});
    form.addControl('Customization', customization);
    if (product.Properties && product.Properties.length > 0) {
      const properties = this.formBuilder.array([]);
      product.Properties.forEach(property => {
        //console.log('property', property);
        const blocks = this.formBuilder.array([]);
        if (property.Option && property.Option.Blocks && property.Option.Blocks.length > 0) {
          property.Option.Blocks.forEach(block => {
            //console.log('block', block);
            let customized;
            if (product.Customization && product.Customization.Properties) {
              const custom = product.Customization.Properties.find(item => item.ID === property.ID);
              if (custom && custom.Blocks) {
                customized = custom.Blocks.find(item => item.ID === block.ID);
              }
            }
            blocks.push(
              this.formBuilder.group({
                ID: [block.ID],
                Enabled: [customized ? customized.Enabled : block.Enabled],
                Title: [customized ? customized.Title : block.Title],
                Post: this.formBuilder.group({
                  ID: [block.Post.ID],
                  Title: [block.Post.Title],
                  Content: [block.Post.Content],
                }),
                // readonly
                Option: this.formBuilder.group({
                  ID: [property.Option.ID],
                  Name: [property.Option.Name],
                  Title: [property.Option.Title],
                }),
                Custom: [false],
                PostId: [{ disabled: true, value: [block.PostId] }],
                Location: [customized ? customized.Location : block.Location],
                Customized: [customized ? true : false],
              })
            );
          });
          properties.push(this.formBuilder.group({ ID: property.ID, Blocks: blocks }));
        }
      });
      customization.addControl('Properties', properties);
    }
    // Need to wait for form fully created
    setTimeout(() => {
      those.raw = JSON.parse(JSON.stringify(form.getRawValue()));
      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
      }
      this.formSubscription = form.valueChanges.subscribe(x => {
        const raw = form.getRawValue();
        those.changed = !those.deepCompare.call(
          this,
          JSON.parse(JSON.stringify(those.raw)),
          JSON.parse(JSON.stringify(raw))
        );
      });
    }, 2000);
    return form;
  }

  setEnabled(enabled) {
    this.form.get('Enabled').setValue(enabled);
    this.form.markAsDirty();
  }

  patchForm(product) {}

  onChangeTitle(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLocaleLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  onInsertTags(tags) {
    this.form.get('Tags').setValue(tags);
    this.form.get('Tags').markAsDirty();
  }

  deepCompare() {
    let i;
    let l;
    let leftChain;
    let rightChain;
    function compare2Objects(x, y) {
      let p;
      if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
        return true;
      }
      if (x === y) {
        return true;
      }
      if (
        (typeof x === 'function' && typeof y === 'function') ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)
      ) {
        return x.toString() === y.toString();
      }
      if (!(x instanceof Object && y instanceof Object)) {
        return false;
      }
      if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
        return false;
      }
      if (x.constructor !== y.constructor) {
        return false;
      }
      if (x.prototype !== y.prototype) {
        return false;
      }
      if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
        return false;
      }
      for (p in y) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false;
        } else if (typeof y[p] !== typeof x[p]) {
          return false;
        }
      }
      for (p in x) {
        if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
          return false;
        } else if (typeof y[p] !== typeof x[p]) {
          return false;
        }
        switch (typeof x[p]) {
          case 'object':
          case 'function':
            leftChain.push(x);
            rightChain.push(y);
            if (!compare2Objects(x[p], y[p])) {
              return false;
            }
            leftChain.pop();
            rightChain.pop();
            break;
          default:
            if (x[p] !== y[p]) {
              return false;
            }
            break;
        }
      }
      return true;
    }
    if (arguments.length < 1) {
      return true;
    }
    for (i = 1, l = arguments.length; i < l; i++) {
      leftChain = [];
      rightChain = [];
      if (!compare2Objects(arguments[0], arguments[i])) {
        return false;
      }
    }
    return true;
  }

  refresh(event?) {
    const those = this;
    switch (event) {
      case 'properties':
        this.apiService
          .getProduct(this.product.ID)
          .then(product => {
            console.log('product2', product);
            those.product.Properties = [...product.Properties];
            those.product.Prices = [...(product.Prices || [])];
            //those.subjectService.events.next({type: 'refresh-properties'});
            setTimeout(() => {
              those.pricesBlock.onGenerate();
            }, 500);
          })
          .catch(err => {
            those.error('Load product', err);
            console.log('refresh', event, err);
          });
        break;
    }
  }

  debug() {
    this.subjectService.events.next({ type: 'refresh-properties' });
  }

  onClone() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Clone product?';
    modal.componentInstance.body = 'Are you sure you want to clone product?';
    modal.componentInstance.confirm = 'Clone';
    modal.result.then(
      result => {
        those.apiService
          .patchProduct(this.product.ID, 'clone', {})
          .then((resp: { ID: number }) => {
            those.router.navigate(['/products/' + resp.ID]);
          })
          .catch(err => {
            those.error('Clone variation', err);
          });
      },
      reason => {}
    );
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete product?';
    modal.componentInstance.body = `Are you sure you want to delete product? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteProduct(id)
          .then(resp => {
            those.router.navigate(['/products']);
          })
          .catch(err => {
            those.error('Delete product', err);
          });
      },
      reason => {}
    );
  }

  async onSubmit(reload = false) {
    const those = this;
    let generate = false;
    // Blocks
    if (this.form.get('Blocks')?.dirty && this.blocksBlock) {
      this.blocksBlock.onSave();
    }
    // Properties
    if (this.form.get('Properties')?.dirty && this.propertiesBlock) {
      await this.propertiesBlock.onSave();
      generate = true;
    }
    // Prices
    if (this.form.get('Prices')?.dirty && this.pricesBlock) {
      this.pricesBlock.onSave();
    }
    reload = reload || (this.product && this.product.Container !== this.form.get('Container').value);
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Description', this.form.get('Description').value ? this.form.get('Description').value : '');
    data.append('Notes', this.form.get('Notes').value);
    data.append('Container', this.form.get('Container').value);
    data.append('Type', this.form.get('Type').value);
    data.append('Size', this.form.get('Size').value);
    //data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('MediaId', this.form.get('MediaId').value);
    data.append('BasePrice', this.form.get('BasePrice').value);
    data.append('ManufacturerPrice', this.form.get('ManufacturerPrice').value);
    data.append('ItemPrice', this.form.get('ItemPrice').value);
    data.append('OnSale', this.form.get('OnSale').value);
    data.append('SalePrice', this.form.get('SalePrice').value);
    if (this.form.get('Start').value) {
      data.append('Start', moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (this.form.get('End').value) {
      data.append('End', moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    data.append('AdvancedPurchasability', this.form.get('AdvancedPurchasability').value);
    data.append('MinQuantity', this.form.get('MinQuantity').value);
    data.append('MaxQuantity', this.form.get('MaxQuantity').value);
    data.append('PurchasableMultiply', this.form.get('PurchasableMultiply').value);
    data.append('Measurement', this.form.get('Measurement').value);
    data.append('BaseMeasurement', this.form.get('BaseMeasurement').value);
    data.append('BaseMeasurementUnit', this.form.get('BaseMeasurementUnit').value);
    data.append('TotalMeasurement', this.form.get('TotalMeasurement').value);
    data.append('TotalMeasurementUnit', this.form.get('TotalMeasurementUnit').value);
    data.append('Discount', this.form.get('Discount').value);
    data.append('DiscountType', this.form.get('DiscountType').value);
    data.append(
      'Tiers',
      JSON.stringify(
        this.form
          .get('Tiers')
          .getRawValue()
          .map(tier => {
            tier.MinQuantity = Number(tier.MinQuantity);
            tier.Discount = Number(tier.Discount);
            return tier;
          })
      )
    );
    data.append('Pattern', this.form.get('Pattern').value);
    data.append('Dimensions', this.form.get('Dimensions').value);
    data.append('DimensionUnit', this.form.get('DimensionUnit').value);
    data.append('Width', this.form.get('Width').value);
    data.append('Height', this.form.get('Height').value);
    data.append('Depth', this.form.get('Depth').value);
    data.append('Volume', this.form.get('Volume').value);
    data.append('Weight', this.form.get('Weight').value);
    data.append('WeightUnit', this.form.get('WeightUnit').value);
    data.append('Packages', this.form.get('Packages').value);
    data.append('Sku', this.form.get('Sku').value);
    data.append('Barcode', this.form.get('Barcode').value);
    data.append('Availability', this.form.get('Availability').value);
    data.append('TrackQuantity', this.form.get('TrackQuantity').value);
    data.append('Overselling', this.form.get('Overselling').value);
    data.append('Stock', this.form.get('Stock').value);
    data.append('Categories', (this.form.get('Categories').value || []).map(item => item.ID).join(','));
    data.append('RelatedProducts', (this.form.get('RelatedProducts').value || []).map(item => item.ID).join(','));
    data.append('Content', this.form.get('Content').value);
    data.append('LandingId', this.form.get('LandingId').value);
    data.append(
      'Tags',
      this.form
        .get('Tags')
        .value.map(item => item.ID)
        .join(',')
    );
    const vendors = this.form.get('Vendors').value;
    if (vendors && vendors.length > 0) {
      data.append('VendorId', vendors[0]);
    }
    const times = this.form.get('Times').value;
    if (times && times.length > 0) {
      data.append('TimeId', times[0]);
    }
    data.append('Customization', JSON.stringify(this.form.get('Customization').getRawValue()));
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postProduct(data)
        .then(product => {
          those.form.markAsPristine();
          those.form.markAsUntouched();
          those.router.navigate(['/products/' + product.ID]);
        })
        .catch(err => {
          those.error('Create product', err);
        });
    } else {
      // update
      this.apiService
        .putProduct(this.form.get('ID').value, data)
        .then(product => {
          console.log('product', product);
          those.changed = false;
          those.raw = those.form.getRawValue();
          those.form.markAsPristine();
          those.form.markAsUntouched();
          if (reload) {
            window.location.reload();
          } else if (generate) {
            those.refresh('properties');
          }
        })
        .catch(err => {
          those.error('Update product', err);
        });
    }
  }

  onSubmitWithArguments(event) {
    console.log('onSubmitWithArguments', event);
  }
}
