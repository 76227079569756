<div class="product-input">
  <div class="form-group">
    <label class="mb-1" translate>Product ID</label>
    <div>
      <input class="form-control" type="text" placeholder="{{'ex. 11,34,56' | translate}}" (change)="onChange1(tree, $event.target['value'])" [(ngModel)]="csv">
    </div>
  </div>
  <div class="form-group">
    <label class="mb-1" translate>Search</label>
    <div>
      <input class="form-control" type="text" placeholder="{{'search' | translate}}" (keyup)="tree.treeModel.filterNodes(filter1.value)" #filter1/>
    </div>
  </div>
  <div class="product-categories" style="position: relative">
    <tree-root class="products-tree expand-tree" [ngClass]="{transparent: loading}" [nodes]="products" [options]="options" (event)="onChange2(tree, selected, $event)" #tree>
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
          <tree-node-expander [node]="node"></tree-node-expander>
          <tree-node-checkbox *ngIf="node.data['Type'] == 'product'" [node]="node"></tree-node-checkbox>
          <div class="node-content-wrapper"
               [class.node-content-wrapper-active]="node.isActive"
               [class.node-content-wrapper-focused]="node.isFocused"
               (click)="node.isExpanded ? node.collapse() : node.expand()"
               (dblclick)="node.mouseAction('dblClick', $event)"
               (contextmenu)="node.mouseAction('contextMenu', $event)"
               (treeDrop)="node.onDrop($event)"
               [treeAllowDrop]="node.allowDrop"
               [treeDrag]="node"
               [treeDragEnabled]="node.allowDrag()">
            <tree-node-content [node]="node" [index]="index" class="node-content"></tree-node-content>
            <span *ngIf="node.data['Type'] == 'product'" style="padding-left: 5px">({{ node.data.id }})</span>
          </div>
        </div>
      </ng-template>
    </tree-root>
    <div *ngIf="loading" style="position: absolute; top:5px; text-align: center; width: 100%"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
  </div>
</div>
