import { Component, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <span
        class="btn btn-secondary"
        style="display: flex;min-height: 34px;align-items: center;padding: 5px 14px;"
        (click)="parent.onEdit(rowData['ID'])">
        <svg class="fill-black" width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.09522 2.0553L1.44343 8.70709C1.4232 8.72732 1.40283 8.76792 1.3826 8.78815C1.3826 8.78815 1.3826 8.80838 1.36237 8.80838C1.34214 8.82861 1.34214 8.86921 1.32177 8.88944V8.90966L0.0238635 13.351C-0.0369652 13.5334 0.0238631 13.7159 0.145521 13.8579C0.246949 13.9594 0.368606 14 0.510486 14C0.551085 14 0.611914 14 0.652514 13.9797L5.0938 12.6818H5.11403C5.13426 12.6818 5.17486 12.6616 5.19509 12.6412C5.19509 12.6412 5.21532 12.6412 5.21532 12.621C5.25592 12.6008 5.27615 12.5804 5.29637 12.5602L11.9482 5.90839L8.09522 2.0553ZM3.99869 11.9721L1.26084 12.7631L2.05175 10.0252L3.99869 11.9721Z" />
          <path
            d="M9.47429 0.676309L8.80505 1.34554L12.6785 5.219L13.3478 4.54976C14.3009 3.59661 14.2401 1.85254 13.2057 0.818268C12.1714 -0.216077 10.4273 -0.276917 9.47422 0.67624L9.47429 0.676309Z" />
        </svg>
      </span>
    </div>
  `,
})
export class PriceOptionsRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;
}
