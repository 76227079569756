import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ApiService } from '../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ILocation } from './block-inline.component';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-blocks-block',
  template: `
    <div class="card" style="margin-bottom: 20px;">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 20px" translate>Infoboxes</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              *ngIf="
                form.get('Customization') &&
                form.get('Customization').get('Properties') &&
                form.get('Customization').get('Properties')['length'] > 0
              "
              class="customization"
              [formGroup]="form.get('Customization')">
              <div
                [attr.id]="'properties-blocks-inline-accordion'"
                class="accordion"
                style="padding-bottom: 0"
                formArrayName="Properties">
                <div class="row">
                  <div class="col col-wide" style="background-color: white; margin-bottom: -5px;">
                    <div
                      *ngFor="
                        let propertyControl of form.controls['Customization']['controls']['Properties']['controls'];
                        let i = index
                      "
                      [formGroupName]="i">
                      <div formArrayName="Blocks">
                        <div
                          *ngFor="let blockControl of propertyControl['controls']['Blocks']['controls']; let j = index"
                          class="card inline-card"
                          [formGroupName]="j">
                          <div
                            [attr.id]="'property-' + i + '-block-inline-heading-' + j"
                            class="card-header inline-heading">
                            <div
                              class="color-primary-violet cursor-pointer"
                              style="display: flex;align-items: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"
                              data-toggle="collapse"
                              [attr.data-target]="'#property-' + i + '-block-inline-collapse-' + j"
                              [attr.aria-controls]="'property-' + i + '-block-inline-collapse-' + j">
                              <div class="link3" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                {{
                                  blockControl.get('Title').value
                                    ? blockControl.get('Title').value
                                    : blockControl.get('Post').get('Title').value
                                }}
                                ({{ 'Option' | translate }}: {{ blockControl.get('Option').get('Title').value }})
                              </div>
                              <svg class="fill-primary-violet" style="height: 16px; width: 16px;min-width: 16px;">
                                <use
                                  [attr.xlink:href]="'/assets/icons/bi-symbol-defs.svg#icon-BiChevron' + 'Down'"></use>
                              </svg>
                            </div>
                            <div style="max-width: 140px;">
                              <div class="inline-heading-buttons">
                                <div class="button-group-dropdown">
                                  <button
                                    type="button"
                                    class="btn btn-status dropdown-toggle"
                                    style="margin: 0 10px 0 10px;"
                                    data-toggle="dropdown"
                                    [ngClass]="blockControl.get('Enabled').value ? 'status-active' : 'status-inactive'">
                                    <span
                                      [innerHTML]="
                                        blockControl.get('Enabled').value ? 'active' : ('inactive' | translate)
                                      "></span>
                                    <span class="caret"></span>
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li (click)="setEnabled(blockControl, true)">Active</li>
                                    <li (click)="setEnabled(blockControl, false)">Inactive</li>
                                  </ul>
                                </div>
                                <div class="btn-sm btn-secondary" (click)="open(blockControl.get('PostId').value)">
                                  <svg style="height: 16px; width: 16px;">
                                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-folder2"></use>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            [attr.id]="'property-' + i + '-block-inline-collapse-' + j"
                            class="collapse inline-body"
                            [attr.aria-labelledby]="'property-' + i + '-block-inline-heading-' + j"
                            [attr.data-parent]="'#properties-blocks-inline-accordion'">
                            <div class="card-body">
                              <app-block-inline
                                [form]="blockControl"
                                [tinymceConfig]="tinymceConfig"
                                [locations]="locations"></app-block-inline>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="form.get('Blocks') && form.get('Blocks').length > 0; else noblocks"
              id="blocks-accordion"
              class="accordion"
              style="padding: 0;">
              <div class="row" style="padding-bottom: 15px;">
                <div class="col col-wide" style="background-color: white">
                  <div [attr.id]="'blocks-inline-accordion'" class="accordion">
                    <div
                      *ngFor="let blockControl of form.controls['Blocks']['controls']; let i = index"
                      class="card inline-card"
                      style="overflow: inherit;">
                      <div [attr.id]="'block-inline-heading-' + i" class="card-header inline-heading">
                        <div style="display: flex; align-items: center;">
                          <div
                            class="link3 color-primary-violet cursor-pointer"
                            style="display: flex;align-items: center;"
                            data-toggle="collapse"
                            [attr.data-target]="'#block-inline-collapse-' + i"
                            [attr.aria-controls]="'block-inline-collapse-' + i">
                            <div class="link3">
                              {{
                                blockControl.get('Post') && blockControl.get('Post').get('Title').value
                                  ? blockControl.get('Post').get('Title').value
                                  : ('(select post from library)' | translate)
                              }}
                            </div>
                            <svg class="fill-primary-violet" style="height: 16px; width: 16px;min-width: 16px;">
                              <use [attr.xlink:href]="'/assets/icons/bi-symbol-defs.svg#icon-BiChevron' + 'Down'"></use>
                            </svg>
                          </div>
                        </div>
                        <div style="display: inline-block; float: right">
                          <div class="inline-heading-buttons">
                            <div class="button-group-dropdown">
                              <button
                                type="button"
                                class="btn btn-status dropdown-toggle"
                                style="margin: 0 10px 0 10px;"
                                data-toggle="dropdown"
                                [ngClass]="blockControl.get('Enabled').value ? 'status-active' : 'status-inactive'">
                                <span
                                  [innerHTML]="
                                    blockControl.get('Enabled').value ? 'active' : ('inactive' | translate)
                                  "></span>
                                <span class="caret"></span>
                              </button>
                              <ul class="dropdown-menu">
                                <li (click)="setEnabled(blockControl, true)">Active</li>
                                <li (click)="setEnabled(blockControl, false)">Inactive</li>
                              </ul>
                            </div>
                            <div class="btn-sm btn-default delete" (click)="deleteBlock(i)">
                              <svg style="height: 16px; width: 16px;">
                                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        [attr.id]="'block-inline-collapse-' + i"
                        class="collapse inline-body"
                        [attr.aria-labelledby]="'block-inline-heading-' + i"
                        [attr.data-parent]="'#blocks-inline-accordion'">
                        <div class="card-body">
                          <app-block-inline
                            [form]="blockControl"
                            [tinymceConfig]="tinymceConfig"
                            [locations]="locations"></app-block-inline>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div style="display: flex; gap: 15px;">
                    <span class="btn btn-third facon-plus" (click)="addBlock()" translate>Add Infobox</span>
                    <span class="btn btn-third facon-plus" (click)="addBlock(true)" translate
                      >Add Infobox From Library</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noblocks>
              <div class="row pt-2">
                <div class="col">
                  <div style="text-align: center">
                    <svg class="fill-primary-violet" style="height: 50px; width: 50px;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiSelectMultiple"></use>
                    </svg>
                    <div class="body-3 color-text-gray" style="padding: 10px 0 20px 0">
                      <span translate>You can add infoboxes to the main product and/or variation</span><br />
                      <span translate>Create a infobox or add a infobox from library</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3 text-center">
                  <span class="btn btn-third facon-plus" (click)="addBlock()" translate>Add Infobox</span>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <span class="btn btn-third facon-plus" (click)="addBlock(true)" translate
                    >Add Infobox From Library</span
                  >
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class BlocksBlockComponent implements OnInit, OnDestroy {
  @Input() productId;
  @Input() variationId;
  @Input() form;
  @Input() blocks;
  @Input() properties;
  @Input() dimensionUnit = 'cm';
  @Input() weightUnit = 'kg';
  @Input() tinymceConfig;
  @Output('onSaved') public onSaved: EventEmitter<any> = new EventEmitter();
  forms;
  term = '';
  filtered = 0;
  locations: ILocation[];

  constructor(
    public router: Router,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit() {
    const those = this;
    this.form.addControl(
      'Blocks',
      this.formBuilder.array(
        (those.blocks || []).map(block => {
          return those.createBlockForm(block);
        }),
        []
      )
    );
    //
    const locations = [
      { Name: 'product', Label: 'Product' },
      { Name: 'variation', Label: 'Variation' },
    ];
    if (this.properties) {
      this.properties.forEach(property => {
        if (property.ID) {
          locations.push({
            Name: 'property-' + property.ID,
            Label: those.translate.instant('Option ') + property.Title,
          });
        }
      });
    }
    this.locations = locations;
  }

  ngOnDestroy() {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  open(id) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/posts/' + id]));
    window.open(url, '_blank');
  }

  createBlockForm(block) {
    const form = this.formBuilder.group({
      ID: [block.ID],
      Enabled: [block.Enabled],
      Title: [block.Title],
      Post: this.formBuilder.group({
        ID: [block.Post?.ID],
        Name: [block.Post?.Name, [Validators.required]],
        Title: [block.Post?.Title, [Validators.required]],
        Media: [block.Post?.Media],
        Content: [block.Post?.Content],
        Sort: [block.Post?.Sort],
      }),
      PostId: [[block.PostId]],
      Opened: [block.Opened],
      Custom: [block.Custom],
      Location: [block.Location],
      Sort: [block.Sort],
    });
    return form;
  }

  setEnabled(group, value) {
    group.get('Enabled').setValue(value);
    group.markAsDirty();
  }

  addBlock(library = false) {
    const those = this;
    const block = {
      Enabled: true,
      Custom: !library,
      Location: 'product',
    };
    if (!library) {
      block['Post'] = {
        Name: those.translate.instant('new'),
        Title: those.translate.instant('New'),
      };
    } else {
      block['PostId'] = undefined;
    }
    const array = this.form.get('Blocks') as FormArray;
    const length = array.length;
    array.push(this.createBlockForm(block));
    array.at(array.length - 1).markAsDirty();
    array.markAsDirty();
    setTimeout(() => {
      const item = document.querySelector('[data-target="#block-inline-collapse-' + length + '"]');
      if (item) {
        item['click']();
      }
    }, 100);
  }

  deleteBlock(index) {
    const those = this;
    const array = this.form.get('Blocks') as FormArray;
    const item = array.at(index) as FormGroup;
    const id = item.get('ID').value;
    if (id) {
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Delete infobox?';
      modal.componentInstance.body = `Are you sure you want to delete infobox? This can't be undone.`;
      modal.componentInstance.confirm = 'Delete';
      modal.result.then(
        result => {
          those.apiService
            .deleteBlock(id)
            .then(resp => {
              array.removeAt(index);
            })
            .catch(err => {
              those.error('Delete infobox', err);
            });
        },
        reason => {}
      );
    } else {
      array.removeAt(index);
    }
  }

  async onSave() {
    const those = this;
    const array = this.form.get('Blocks') as FormArray;
    if (array && array.dirty && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        const raw = (array.at(i) as FormGroup).getRawValue();
        if (array.at(i).dirty || raw.Sort !== i + 1) {
          const id = raw.ID;
          if (raw.PostId) {
            raw.PostId = raw.PostId.length > 0 && raw.PostId[0] ? raw.PostId[0] : 0;
          }
          raw.Sort = i + 1;
          if (!id) {
            await this.apiService
              .postBlock({ ...raw, ...{ ProductId: those.productId, VariationId: those.variationId } })
              .then(block => {
                const group = array.at(i) as FormGroup;
                if (!group.get('ID').value) {
                  group.get('ID').setValue(block.ID);
                }
              })
              .catch(err => {
                those.error('Create block', err);
              });
          } else {
            await this.apiService
              .putBlock(id, raw)
              .then(block => {
                //
              })
              .catch(err => {
                those.error('Update block', err);
              });
          }
        }
      }
      (this.form.get('Blocks') as FormGroup).markAsPristine();
    }
  }
}
