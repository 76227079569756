import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorsComponent } from './vendors.component';
import { VendorComponent } from './vendor/vendor.component';
import { TimeComponent } from './vendor/time/time.component';

const routes: Routes = [
  {
    path: '',
    component: VendorsComponent,
    data: {
      title: 'Vendors',
      breadcrumb: '',
      subtitle: 'Vendor Library',
      description:
        'With help of the selection of vendors and associated delivery times, you can create uniformity in the products. In addition, changes in the master also immediately affect all linked products.',
    },
  },
  {
    path: ':id',
    component: VendorComponent,
    data: {
      title: 'Vendor',
      breadcrumb: '',
    },
  },
  {
    path: ':vendorId/times/:id',
    component: TimeComponent,
    data: {
      title: 'Time',
      breadcrumb: '',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VendorsRoutingModule {}
