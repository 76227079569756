import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import { Subscription } from 'rxjs';
import { SubjectService } from '../../subject.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  public breadcrumbs;
  public title: string;
  snapshot;
  parent;
  enabled = true;

  constructor(
    private subjectService: SubjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        this.snapshot = this.router.routerState.snapshot;
        if (this.snapshot) {
          const url = this.snapshot.url;
          this.enabled =
            !url.match(/\/products/) &&
            !url.match(/\/categories$/) &&
            !url.match(/\/components$/) &&
            !url.match(/\/comments/) &&
            !url.match(/\/dashboard\/default/) &&
            !url.match(/\/forms/) &&
            !url.match(/\/options/) &&
            !url.match(/\/dimensions/) &&
            !url.match(/\/posts/) &&
            !url.match(/\/websites/) &&
            !url.match(/\/media/) &&
            !url.match(/\/menus/) &&
            !url.match(/\/messages/) &&
            !url.match(/\/sales\/coupons/) &&
            !url.match(/\/sales\/customers/) &&
            !url.match(/\/sales\/orders/) &&
            !url.match(/\/sales\/transactions/) &&
            !url.match(/\/search/) &&
            !url.match(/\/settings/) &&
            !url.match(/\/pages/) &&
            !url.match(/\/transports\/companies/) &&
            !url.match(/\/transports\/zones/) &&
            !url.match(/\/tags/) &&
            !url.match(/\/themes/) &&
            !url.match(/\/templates/) &&
            !url.match(/\/vendors/);
          const v = url.replace(/(\/[^\/]+$)/, '');
          if (v.match(/\/products\/\d+\/variations/)) {
            this.parent = v.replace(/(\/[^\/]+$)/, '');
          } else {
            this.parent = v;
          }
        }
        let title = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
    this.subscription = this.subjectService.breadcrumbsSubject.subscribe(breadcrumbs => {
      //console.log('breadcrumbs', breadcrumbs);
      if (breadcrumbs) {
        if (breadcrumbs['title']) {
          this.title = breadcrumbs['title'];
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
