<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/menus']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>{{'Menu' | translate}}: {{menu ? menu['Title'] : ('New' | translate)}}</h1>
        </div>
        <div *ngIf="menu">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-8">
        <div class="card">
          <div class="card-body" style="padding-bottom: 0;">
            <div class="form">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="title" class="mb-1" translate>Title</label>
                    <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title">
                  </div>
                  <div class="form-group">
                    <label for="location" class="mb-1" translate>Location</label>
                    <select class="form-control" id="location" formControlName="Location">
                      <option value="header">Header</option>
                      <option value="filter">Filter</option>
                      <option value="card">Card</option>
                      <option value="footer">Footer</option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="menu" class="row">
                <div class="col">
                  <h2 translate>Links</h2>
                </div>
              </div>
              <div *ngIf="menu" class="row">
                <div class="col-md-4">
                  <div>
                    <div id="accordion">
                      <div class="card" style="margin-bottom: 20px;">
                        <div id="head-1" class="card-header" style="padding: 10px">
                          <h5 class="mb-0">
                            <a class="btn" style="padding: 5px;" data-toggle="collapse" data-target="#body-1" aria-expanded="true" aria-controls="body-1" translate>
                              Categories
                            </a>
                          </h5>
                        </div>

                        <div id="body-1" class="collapse show" aria-labelledby="head-1" data-parent="#accordion">
                          <div class="card-body" style="padding: 0 10px 10px 10px;">
                            <tree-root class="expand-tree" [nodes]="categories" [options]="options1" (event)="onChangeCategories2(categoriesTree,'Categories', form.get('Categories').value, $event)" #categoriesTree>
                              <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                                  <tree-node-expander [node]="node"></tree-node-expander>
                                  <tree-node-checkbox [node]="node"></tree-node-checkbox>
                                  <div class="node-content-wrapper"
                                       [class.node-content-wrapper-active]="node.isActive"
                                       [class.node-content-wrapper-focused]="node.isFocused"
                                       (click)="node.isExpanded ? node.collapse() : node.expand()"
                                       (dblclick)="node.mouseAction('dblClick', $event)"
                                       (contextmenu)="node.mouseAction('contextMenu', $event)"
                                       (treeDrop)="node.onDrop($event)"
                                       [treeAllowDrop]="node.allowDrop"
                                       [treeDrag]="node"
                                       [treeDragEnabled]="node.allowDrag()">
                                    <tree-node-content [node]="node" [index]="index"></tree-node-content>
                                    <span style="padding-left: 5px">({{ node.data.id }})</span>
                                  </div>
                                </div>
                              </ng-template>
                            </tree-root>
                            <div style="text-align: center">
                              <a class="btn-sm btn-default facon-plus" (click)="addCategory()" translate> Add</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card" style="margin-bottom: 20px;">
                        <div id="head-2" class="card-header" style="padding: 10px;">
                          <h5 class="mb-0">
                            <a class="btn" style="padding: 5px;" data-toggle="collapse" data-target="#body-2" aria-expanded="true" aria-controls="body-2">
                              Products
                            </a>
                          </h5>
                        </div>

                        <div id="body-2" class="collapse" aria-labelledby="head-2" data-parent="#accordion">
                          <div class="card-body" style="padding: 0 10px 10px 10px;">
                            <tree-root class="expand-tree" [nodes]="products" [options]="options1" (event)="onChangeCategories2(productsTree,'Products', form.get('Products').value, $event)" #productsTree>
                              <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                                  <tree-node-checkbox *ngIf="node.data['Type'] == 'product'" [node]="node"></tree-node-checkbox>
                                  <tree-node-expander [node]="node"></tree-node-expander>
                                  <div class="node-content-wrapper"
                                       [class.node-content-wrapper-active]="node.isActive"
                                       [class.node-content-wrapper-focused]="node.isFocused"
                                       (click)="node.isExpanded ? node.collapse() : node.expand()"
                                       (dblclick)="node.mouseAction('dblClick', $event)"
                                       (contextmenu)="node.mouseAction('contextMenu', $event)"
                                       (treeDrop)="node.onDrop($event)"
                                       [treeAllowDrop]="node.allowDrop"
                                       [treeDrag]="node"
                                       [treeDragEnabled]="node.allowDrag()">
                                    <tree-node-content [node]="node" [index]="index"></tree-node-content>
                                    <span *ngIf="node.data['Type'] == 'product'" style="padding-left: 5px">({{ node.data.id }})</span>
                                  </div>
                                </div>
                              </ng-template>
                            </tree-root>
                            <div style="text-align: center">
                              <a class="btn-sm btn-default facon-plus" (click)="addProduct()" translate> Add</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card" style="margin-bottom: 20px;">
                        <div id="head-3" class="card-header" style="padding: 10px;">
                          <h5 class="mb-0">
                            <a class="btn collapsed" style="padding: 5px;" data-toggle="collapse" data-target="#body-3" aria-expanded="false" aria-controls="body-3">
                              Page
                            </a>
                          </h5>
                        </div>
                        <div id="body-3" class="collapse" aria-labelledby="head-3" data-parent="#accordion">
                          <div class="card-body" style="padding: 0 10px 10px 10px;">
                            <tree-root class="expand-tree" [nodes]="pages" [options]="options1" (event)="onChangeCategories2(pagesTree,'Pages', form.get('Pages').value, $event)" #pagesTree>
                              <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
                                  <tree-node-expander [node]="node"></tree-node-expander>
                                  <tree-node-checkbox [node]="node"></tree-node-checkbox>
                                  <div class="node-content-wrapper"
                                       [class.node-content-wrapper-active]="node.isActive"
                                       [class.node-content-wrapper-focused]="node.isFocused"
                                       (click)="node.isExpanded ? node.collapse() : node.expand()"
                                       (dblclick)="node.mouseAction('dblClick', $event)"
                                       (contextmenu)="node.mouseAction('contextMenu', $event)"
                                       (treeDrop)="node.onDrop($event)"
                                       [treeAllowDrop]="node.allowDrop"
                                       [treeDrag]="node"
                                       [treeDragEnabled]="node.allowDrag()">
                                    <tree-node-content [node]="node" [index]="index"></tree-node-content>
                                    <span *ngIf="node.data['Type'] == 'product'" style="padding-left: 5px">({{ node.data.id }})</span>
                                  </div>
                                </div>
                              </ng-template>
                            </tree-root>
                            <div style="text-align: center">
                              <a class="btn-sm btn-default facon-plus" (click)="addPage()" translate> Add</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card" style="margin-bottom: 20px;">
                        <div id="head-4" class="card-header" style="padding: 10px;">
                          <h5 class="mb-0">
                            <a class="btn collapsed" style="padding: 5px;" data-toggle="collapse" data-target="#body-4" aria-expanded="false" aria-controls="body-4">
                              Custom Link
                            </a>
                          </h5>
                        </div>
                        <div id="body-4" class="collapse" aria-labelledby="head-4" data-parent="#accordion">
                          <div class="card-body" style="padding: 0 10px 10px 10px">
                            <div class="form-group">
                              <label for="url" class="mb-1" translate>Url</label>:
                              <input class="form-control" id="url" type="text" #path>
                            </div>
                            <div class="form-group">
                              <label for="text" class="mb-1" translate>Text</label>:
                              <input class="form-control" id="text" type="text" #title>
                            </div>
                            <div style="text-align: center">
                              <a class="btn-sm btn-default facon-plus" (click)="addCustomLink(path, title)" translate> Add</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div *ngIf="nodes && nodes.length > 0" class="menu-tree">
                    <tree-root class="expand-tree" [options]="options2" [nodes]="nodes" #tree>
                      <ng-template #treeNodeTemplate let-node let-index="index">
                        <div *ngIf="node.data.data?.type == 'category'" class="menu-tree-type"><div class="menu-tree-key"><span translate>Category</span> #{{node.data.data.id}}:</div> <span>{{ node.data.name }}</span></div>
                        <div *ngIf="node.data.data?.type == 'product'" class="menu-tree-type"><div class="menu-tree-key"><span translate>Product</span> #{{node.data.data.id}}:</div> <span>{{ node.data.name }}</span></div>
                        <div *ngIf="node.data.data?.type == 'page'" class="menu-tree-type"><div class="menu-tree-key"><span translate>Page</span> {{ node.data.data.path }}:</div> <span>{{ node.data.name }}</span></div>
                        <div *ngIf="node.data.data?.type == 'custom'" class="menu-tree-type"><div class="menu-tree-key">{{ node.data.data.path }}:</div> <span>{{ node.data.name }}</span></div>
                        <span title="delete" class="btn-sm btn-default tree-action-remove delete" (click)="deleteNode(node.data.id)"><i aria-hidden="true" class="fa fa-trash"></i></span>
                      </ng-template>
                    </tree-root>
                  </div>
                </div>
              </div>
              <div class="form-group" style="text-align: center">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


