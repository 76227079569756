import { Component, Input } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../../shared/api.service';

@Component({
  styles: [`
    div>span {
      cursor: pointer;
    }
  `],
  template: `
    <div class="button-group-dropdown" style="text-align: center">
      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="{'status-new': rowData.BillingStatus == 'new', 'status-waiting': rowData.BillingStatus == 'waiting for payment', 'status-paid': rowData.BillingStatus == 'paid', 'status-canceled': rowData.BillingStatus == 'canceled', 'status-returned': ['returned partially', 'returned'].indexOf(rowData.BillingStatus) >= 0}">
        <span [innerText]="rowData.BillingStatus | translate"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li (click)="patch('new')" translate>
          New
        </li>
        <li (click)="patch('waiting for payment')" translate>
          Waiting for payment
        </li>
        <li (click)="patch('paid')" translate>
          Paid
        </li>
        <li (click)="patch('canceled')" translate>
          Canceled
        </li>
        <li (click)="patch('refunded partially')" translate>
          Refunded partially
        </li>
        <li (click)="patch('refunded')" translate>
          Refunded
        </li>
      </ul>
    </div>
  `,
})
export class OrderBillingStatusRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  constructor(public apiService: ApiService){  }

  patch(value) {
    this.apiService['patchOrder'](this.rowData['ID'], 'setBillingStatus', {Value: value}).then(resp => {
      for (let i = 0; i < this.parent.source.data.length; i++) {
        if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
          this.parent.source.data[i]['BillingStatus'] = value;
          this.parent.source.local = true;
          this.parent.source.refresh();
          break;
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }
}
