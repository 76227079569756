import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { SubjectService } from '../../../shared/subject.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { credentials } from '../../../shared/credentials.data';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-block-customer',
  styles: [
    `
      .facon-country::before {
        transform: scale(0.75, 0.75);
      }
    `,
  ],
  template: `
    <div class="customer-container ref" style="position: relative" #ref>
      <div *ngIf="customer">
        <div *ngFor="let type of ['Billing', 'Shipping']">
          <div
            *ngFor="let profile of customer[type + 'Profiles']"
            class="row"
            style="border-bottom: 3px solid white; padding: 10px 0;">
            <div class="col">
              <div class="copy">
                <h2
                  style="display: inline-block"
                  [innerText]="(type + ' profile' | translate) + ' #' + profile.ID"></h2>
                <span class="copy-btn" (click)="copyProfile(profile)"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <app-profile [type]="type.toLowerCase()" (onSaved)="updateProfile(type, $event)" #editor>
                <div
                  class="cursor-pointer primary-color"
                  (click)="editor.onEdit(editor.content, profile['ID'])"
                  translate>
                  Edit
                </div>
              </app-profile>
            </div>
            <div class="col">
              <div class="copy">
                <span class="dark-gray">{{ profile['Name'] }} {{ profile['Lastname'] }}</span>
                <span class="copy-btn" (click)="copyText(profile['Name'] + ' ' + profile['Lastname'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div *ngIf="profile['Company']" class="copy">
                <span class="dark-gray">{{ profile['Company'] }}</span>
                <span class="copy-btn" (click)="copyText(profile['Company'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div class="copy">
                <span class="dark-gray">{{ profile['Address'] }}</span>
                <span class="copy-btn" (click)="copyText(profile['Address'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div class="copy">
                <span class="dark-gray">{{ profile['Zip'] }} {{ profile['City'] }}</span>
                <span class="copy-btn" (click)="copyText(profile['Zip'] + ' ' + profile['City'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div class="copy">
                <span class="dark-gray">{{ profile['CountryName'] }}</span>
                <span class="copy-btn" (click)="copyText(profile['CountryName'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
            </div>
            <div class="col">
              <div class="copy">
                <span class="dark-gray">{{ profile['Phone'] }}</span
                ><span class="copy-btn" (click)="copyText(profile['Phone'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div class="copy">
                <span class="dark-gray">{{ profile['Email'] }}</span
                ><span class="copy-btn" (click)="copyText(profile['Email'])"
                  ><i class="fa fa-clipboard" aria-hidden="true"></i
                ></span>
              </div>
              <div>
                <span class="dark-gray">{{ profile['CreatedAt'] | amDateFormat: 'YYYY-MM-DD HH:mm:ss' }}</span>
              </div>
            </div>
            <div class="col" style="text-align: right"></div>
          </div>
        </div>
        <div class="row" style="padding: 10px 0;">
          <div class="col-md-4">
            <div class="card2">
              <h2 translate>Customer notes</h2>
              <div>
                <div style="margin-bottom: 5px; padding-bottom: 10px">
                  <input
                    class="form-control"
                    style="width: 100%"
                    placeholder="write here internal note"
                    (keydown.enter)="addNote($event.target)" />
                </div>
                <div *ngFor="let note of slice(customer.Notes, limit); let i = index" style="padding-bottom: 10px">
                  <div class="note-header">
                    <span class="body-5 note-author">{{ note.Author }}</span
                    ><span class="body-5 note-date">{{ note.Created | amDateFormat: 'DD.MM.YYYY - HH:mm' }}</span>
                  </div>
                  <div class="note-body">
                    {{ note.Note }}
                    <div style="position: absolute; right: 10px; top: 5px; cursor:pointer;" (click)="deleteNote(i)">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7" cy="7" r="7" fill="#ADB5BD" />
                        <rect
                          x="5.51562"
                          y="4.5249"
                          width="5.6"
                          height="1.4"
                          transform="rotate(45 5.51562 4.5249)"
                          fill="white" />
                        <rect
                          x="9.47656"
                          y="5.51514"
                          width="5.6"
                          height="1.4"
                          transform="rotate(135 9.47656 5.51514)"
                          fill="white" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div *ngIf="customer.Notes" class="border-top" style="margin: 0 -15px;padding-top: 10px;">
                  <div
                    *ngIf="customer.Notes.length > limit"
                    class="link2"
                    style="text-align: center"
                    (click)="limit = 999999">
                    <span style="padding-right: 5px">(+{{ customer.Notes.length - limit }})</span>
                    <span translate>Open customer notes</span>
                  </div>
                  <div *ngIf="limit == 999999" class="link2" style="text-align: center" (click)="limit = 3">
                    <span translate>Close customer notes</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="customer.LatestOrder" class="col-md-4">
            <div class="card2">
              <div>
                <h2 style="display: inline-block" translate>Latest placed order</h2>
                <a
                  class="link2 primary-color"
                  style="float:right"
                  [routerLink]="['/sales/orders']"
                  [queryParams]="{ user_id: customer.ID }"
                  translate
                  >View all orders</a
                >
              </div>
              <div>
                <a
                  class="body-1 order-link primary-color"
                  [routerLink]="['/sales/orders']"
                  [queryParams]="{ id: customer.LatestOrder.ID }"
                  >Order #{{ customer.LatestOrder.ID }}</a
                >
                <div class="body-5 note-date" style="padding-bottom: 10px">
                  {{ customer.LatestOrder.CreatedAt | amDateFormat: 'DD.MM.YYYY - HH:mm' }}
                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom: 5px;">
                  <span class="label2" translate>BillingStatus</span>
                  <div class="button-group-dropdown">
                    <button
                      type="button"
                      class="btn btn-status dropdown-toggle"
                      data-toggle="dropdown"
                      [ngClass]="{
                        'status-new': customer.LatestOrder.BillingStatus == 'new',
                        'status-waiting': customer.LatestOrder.BillingStatus == 'waiting for payment',
                        'status-paid': customer.LatestOrder.BillingStatus == 'paid',
                        'status-canceled': customer.LatestOrder.BillingStatus == 'canceled',
                        'status-returned':
                          ['returned partially', 'returned'].indexOf(customer.LatestOrder.BillingStatus) >= 0
                      }">
                      <span [innerText]="customer.LatestOrder.BillingStatus | translate"></span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'new' })" translate>New</li>
                      <li
                        (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'waiting for payment' })"
                        translate>
                        Waiting for payment
                      </li>
                      <li (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'paid' })" translate>Paid</li>
                      <li (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'canceled' })" translate>
                        Canceled
                      </li>
                      <li
                        (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'refunded partially' })"
                        translate>
                        Refunded partially
                      </li>
                      <li (click)="setBillingStatus(customer.LatestOrder.ID, { Value: 'refunded' })" translate>
                        Refunded
                      </li>
                    </ul>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;padding-bottom: 10px">
                  <span class="label2" translate>ShippingStatus</span>
                  <div class="button-group-dropdown">
                    <button
                      type="button"
                      class="btn btn-status dropdown-toggle"
                      data-toggle="dropdown"
                      [ngClass]="{
                        'status-new': customer.LatestOrder.ShippingStatus == 'new',
                        'status-pending': customer.LatestOrder.ShippingStatus == 'pending',
                        'status-manufacturing': customer.LatestOrder.ShippingStatus == 'manufacturing',
                        'status-shipping': customer.LatestOrder.ShippingStatus == 'shipping',
                        'status-shipped':
                          ['shipped', 'shipped partially'].indexOf(customer.LatestOrder.ShippingStatus) >= 0
                      }">
                      <span
                        [innerText]="
                          customer.LatestOrder.ShippingStatus
                            ? customer.LatestOrder.ShippingStatus
                            : ('not set' | translate)
                        "></span>
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'new' })" translate>New</li>
                      <li (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'pending' })" translate>
                        Pending
                      </li>
                      <li (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'manufacturing' })" translate>
                        Manufacturing
                      </li>
                      <li (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'shipping' })" translate>
                        Shipping
                      </li>
                      <li
                        (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'shipped partially' })"
                        translate>
                        Shipped partially
                      </li>
                      <li (click)="setShippingStatus(customer.LatestOrder.ID, { Value: 'shipped' })" translate>
                        Shipped
                      </li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="customer.LatestOrder.Total" class="total-amount">
                  <span class="label2" translate>Total amount</span>:
                  <span>{{ currency }} {{ customer.LatestOrder.Total.toFixed(2) }}</span> (<span
                    [innerText]="customer.LatestOrder.Items.length + ' ' + ('products' | translate)"></span
                  >)
                </div>
                <div class="border-top" style="margin: 0 -15px; padding-top: 10px;">
                  <div style="text-align: center">
                    <a
                      *ngIf="customer.Token"
                      class="link2 primary-color"
                      [href]="absolutizeUrl.transform('/?token=' + customer.Token)"
                      target="_blank"
                      translate
                      >Log in as customer</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card2" style="margin-bottom: 10px;">
              <h2 translate>Email marketing</h2>
              <div style="text-align: center">
                <div class="button-group-dropdown" style="text-align: center;width: 100%;">
                  <button
                    type="button"
                    class="btn btn-status dropdown-toggle"
                    data-toggle="dropdown"
                    [ngClass]="customer.AllowReceiveEmails ? 'subscribed' : 'not-subscribed'">
                    <span
                      [innerHTML]="customer.AllowReceiveEmails ? 'Subscribed' : ('Not subscribed' | translate)"></span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li (click)="setAllowReceiveEmails(customer.ID, { Value: true })" translate>Subscribed</li>
                    <li (click)="setAllowReceiveEmails(customer.ID, { Value: false })" translate>Not subscribed</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card2">
              <h2 translate>Tags</h2>
              <div>
                <app-tag-input
                  [(ngModel)]="customer.Tags"
                  (ngModelChange)="patchCustomer(customer.ID, 'setTags', { Values: $event })"></app-tag-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!customer" style="text-align: center">
        <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
      </div>
    </div>
  `,
})
export class CustomerBlockComponent implements OnInit {
  @Input('id') id;
  @Input('author') author;
  customer;
  currency = credentials.currency;
  limit = 3;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const those = this;
    this.apiService
      .getCustomer(this.id)
      .then(customer => {
        those.customer = customer;
      })
      .catch(err => {
        those.error('get customer', err);
      });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  copyProfile(profile) {
    this.copyText(
      profile.Name +
        ' ' +
        profile.Lastname +
        '\n' +
        profile.Address +
        '\n' +
        profile.Zip +
        ' ' +
        profile.City +
        '\n' +
        profile.Phone +
        '\n' +
        profile.Email
    );
  }

  copyText(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied to clipboard', 'Clipboard');
  }

  slice(array, limit) {
    if (array && array.length > 0) {
      return array.slice(0, limit);
    }
    return array;
  }

  addNote(note) {
    const those = this;
    if (note && note.value) {
      this.customer.Notes = this.customer.Notes || [];
      this.customer.Notes.unshift({ Created: new Date(), Author: those.author, Note: note.value });
      this.apiService
        .patchCustomer(this.customer.ID, 'setNotes', { Value: this.customer.Notes })
        .then(resp => {
          note.value = '';
        })
        .catch(err => {
          those.error('add note', err);
        });
    }
  }

  deleteNote(index) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete note?';
    modal.componentInstance.body = `Are you sure you want to delete note? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.customer.Notes.splice(index, 1);
        those.apiService
          .patchCustomer(those.customer.ID, 'setNotes', { Value: those.customer.Notes })
          .then(resp => {})
          .catch(err => {
            those.error('delete note', err);
          });
      },
      reason => {}
    );
  }

  setBillingStatus(id, raw) {
    const those = this;
    this.apiService
      .patchOrder(id, 'setBillingStatus', raw)
      .then(resp => {
        this.customer.LatestOrder.BillingStatus = raw.Value;
      })
      .catch(err => {
        those.error('patch order', err);
      });
  }

  setShippingStatus(id, raw) {
    const those = this;
    this.apiService
      .patchOrder(id, 'setShippingStatus', raw)
      .then(resp => {
        this.customer.LatestOrder.ShippingStatus = raw.Value;
      })
      .catch(err => {
        those.error('patch order', err);
      });
  }

  setAllowReceiveEmails(id, raw) {
    const those = this;
    this.apiService
      .patchCustomer(id, 'setAllowReceiveEmails', raw)
      .then(resp => {
        this.customer.AllowReceiveEmails = raw.Value;
      })
      .catch(err => {
        those.error('patch customer', err);
      });
  }

  patchCustomer(id, action, raw) {
    const those = this;
    this.apiService
      .patchCustomer(id, action, raw)
      .then(resp => {})
      .catch(err => {
        those.error('patch customer', err);
      });
  }

  updateProfile(type, profile) {
    if (this.customer) {
      const index = this.customer[type + 'Profiles'].findIndex(item => item.ID === profile.ID);
      this.customer[type + 'Profiles'][index] = profile;
    }
  }
}
