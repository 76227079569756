import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { credentials } from '../../../shared/credentials.data';

@Component({
  selector: 'app-dimensions-block',
  template: `
    <div class="card" style="margin-bottom: 20px;">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 20px" translate>Dimensions</h2>
          </div>
        </div>
        <div class="row" style="padding-bottom: 10px;">
          <div class="col-sm-6">
            <div class="form-group" style="margin-bottom: 0">
              <label class="mb-1" translate>Format</label>
              <app-select
                [options]="patterns"
                [formControl]="form.get('Pattern')"
                (ngModelChange)="parse()"></app-select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" style="margin-bottom: 0">
              <label class="mb-1" translate>Measurement</label>
              <div class="form-row">
                <div class="col-8">
                  <input
                    type="text"
                    placeholder="{{ 'value' | translate }}"
                    (focusout)="parse()"
                    class="form-control"
                    [formControl]="form.get('Dimensions')" />
                </div>
                <div class="col-4">
                  <app-select [options]="lengthMeasurementUnits" [formControl]="form.get('DimensionUnit')"></app-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="mb-1" for="width" translate>Width</label>
              <input
                class="form-control"
                id="width"
                type="text"
                (click)="$event.target['select']()"
                (focusin)="trim(this.form.get('Width'))"
                (focusout)="format(this.form.get('Width'), 1) && print()"
                Currency="true"
                [formControl]="form.get('Width')" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="mb-1" for="height" translate>Height</label>
              <input
                class="form-control"
                id="height"
                type="text"
                (click)="$event.target['select']()"
                (focusin)="trim(this.form.get('Height'))"
                (focusout)="format(this.form.get('Height'), 1) && print()"
                Currency="true"
                [formControl]="form.get('Height')" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="mb-1" for="depth" translate>Depth</label>
              <input
                class="form-control"
                id="depth"
                type="text"
                (click)="$event.target['select']()"
                (focusin)="trim(this.form.get('Depth'))"
                (focusout)="format(this.form.get('Depth'), 1) && print()"
                Currency="true"
                [formControl]="form.get('Depth')" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="volume" translate>Volume</label>
              <input
                class="form-control mb-2"
                id="volume"
                type="text"
                (click)="$event.target['select']()"
                (focusin)="trim(this.form.get('Volume'))"
                (focusout)="format(this.form.get('Volume'), 2)"
                placeholder="{{ 'm3' | translate }}"
                Currency="true"
                [formControl]="form.get('Volume')" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group" style="margin-bottom: 0">
              <label for="weight" translate>Weight</label>
              <span
                class="tooltip-question"
                style="padding-left: 5px;vertical-align: sub;"
                placement="top"
                [ngbTooltip]="
                  'Enter the weight of this product so that it can be used to calculate shipping costs when ordering'
                    | translate
                ">
                <svg width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                </svg>
              </span>
              <div class="form-row">
                <div class="col-8">
                  <input
                    class="form-control"
                    id="weight"
                    type="text"
                    (click)="$event.target['select']()"
                    (focusin)="trim(this.form.get('Weight'))"
                    (focusout)="format(this.form.get('Weight'), 2)"
                    placeholder="{{ 'kg' | translate }}"
                    Currency="true"
                    [formControl]="form.get('Weight')" />
                </div>
                <div class="col-4">
                  <app-select
                    [options]="[
                      { Name: 'g', Label: 'g' },
                      { Name: 'kg', Label: 'kg' }
                    ]"
                    [formControl]="form.get('WeightUnit')"></app-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="packages" translate>Packaging items</label>
            <span
              class="tooltip-question"
              style="padding-left: 5px;vertical-align: sub;"
              placement="top"
              [ngbTooltip]="
                'Enter the amounts of colli to give the customer information in how many pieces the product comes'
                  | translate
              ">
              <svg width="16px" height="16px">
                <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
              </svg>
            </span>
            <input
              class="form-control"
              id="packages"
              type="text"
              (click)="$event.target['select']()"
              (focusin)="trim(this.form.get('Packages'))"
              (focusout)="format(this.form.get('Packages'))"
              [formControl]="form.get('Packages')" />
          </div>
        </div>
      </div>
    </div>
  `,
})
export class DimensionsBlockComponent implements OnInit {
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('form') form: FormGroup;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();

  patterns = credentials.patterns;
  lengthMeasurementUnits = credentials.lengthMeasurementUnits;

  ngOnInit() {}

  refresh(type?) {
    this.onSaved.emit(type);
  }

  trim(control) {
    if (control && control.value !== undefined) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control, decimal?) {
    const value = Number.parseFloat(control.value ? control.value : 0)
      .toFixed(decimal)
      .replace(/([0-9]+\.[0-9]+?)0*$/, '$1');
    if (value) {
      control.setValue(value);
    }
    return true;
  }

  parse() {
    console.log('parse');
    const pattern = this.form.get('Pattern').value;
    const value = this.form.get('Dimensions').value;
    if (value) {
      let res;
      switch (pattern) {
        case 'whd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[3]);
            this.form.get('Depth').setValue(+res[5]);
          }
          break;
        case 'wdh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1]);
            this.form.get('Height').setValue(+res[5]);
            this.form.get('Depth').setValue(+res[3]);
          }
          break;
        case 'hwd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3]);
            this.form.get('Height').setValue(+res[1]);
            this.form.get('Depth').setValue(+res[5]);
          }
          break;
        case 'hdw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3]);
            this.form.get('Height').setValue(+res[5]);
            this.form.get('Depth').setValue(+res[1]);
          }
          break;
        case 'dwh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5]);
            this.form.get('Height').setValue(+res[1]);
            this.form.get('Depth').setValue(+res[3]);
          }
          break;
        case 'dhw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5]);
            this.form.get('Height').setValue(+res[3]);
            this.form.get('Depth').setValue(+res[1]);
          }
          break;
        default:
          break;
      }
    }
  }

  print() {
    const pattern = this.form.get('Pattern').value;
    switch (pattern) {
      case 'whd':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Depth').value)
          );
        break;
      case 'wdh':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Height').value)
          );
        break;
      case 'hwd':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Depth').value)
          );
        break;
      case 'hdw':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Width').value)
          );
        break;
      case 'dwh':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Height').value)
          );
        break;
      case 'dhw':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Width').value)
          );
        break;
      default:
        break;
    }
    return true;
  }
}
