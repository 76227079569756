import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { OrdersDataSource } from './orders.data-source';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectedRenderComponent } from '../../../shared/render/selected-render.component';
import { DateRenderComponent } from '../../../shared/render/date-render.component';
import { SubjectService } from '../../../shared/subject.service';
import { OrderBlockComponent } from './order-block.component';
import { OrderIdRenderComponent } from './order-id-render.component';
import { OrderNameRenderComponent } from './order-name-render.component';
import { OrderTotalRenderComponent } from './order-total-render.component';
import { OrderTagsRenderComponent } from './order-tags-render.component';
import { OrderBillingStatusRenderComponent } from './order-billing-status-render.component';
import { OrderShippingStatusRenderComponent } from './order-shipping-status-render.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class OrdersComponent implements OnInit, OnDestroy {
  @ViewChildren('block') blocks: QueryList<OrderBlockComponent>;
  @ViewChild('editor') editor;
  id;
  tree;
  settings;
  source: OrdersDataSource;
  term;
  form;
  modal;
  closeResult;
  visible = false;
  filtered = true;
  timer;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Orders',
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '2',
      Name: 'new',
      Label: 'New',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Created',
            Label: 'Created',
            Type: 'date',
            Value: {
              Label: 'Last 7 days',
              Value: 'last 7 days',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'unpaid',
      Label: 'Unpaid',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'BillingStatus',
            Label: 'Billing Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'new',
                name: 'New',
              },
              {
                id: 'waiting for payment',
                name: 'Waiting for payment',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '4',
      Name: 'canceled',
      Label: 'Canceled',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'BillingStatus',
            Label: 'Billing Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'canceled',
                name: 'Canceled',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '5',
      Name: 'shipped',
      Label: 'Shipped',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'ShippingStatus',
            Label: 'Shipping Status',
            Type: 'multiselect',
            Values: [
              {
                id: 'shipped',
                name: 'Shipped',
              },
            ],
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    ID: {
      title: 'Id',
      type: 'custom',
      renderComponent: OrderIdRenderComponent,
      filter: false,
      sort: true,
    },
    Created: {
      title: 'Created',
      type: 'custom',
      renderComponent: DateRenderComponent,
      filter: false,
      sort: true,
    },
    Name: {
      title: 'Customer',
      type: 'custom',
      renderComponent: OrderNameRenderComponent,
      filter: false,
      sort: false,
    },
    BillingStatus: {
      title: 'Billing Status',
      type: 'custom',
      renderComponent: OrderBillingStatusRenderComponent,
      filter: false,
      sort: true,
    },
    ShippingStatus: {
      title: 'Shipping Status',
      type: 'custom',
      renderComponent: OrderShippingStatusRenderComponent,
      filter: false,
      sort: true,
    },
    Items: {
      title: 'Items',
      filter: false,
      sort: true,
    },
    Total: {
      title: 'Total',
      type: 'custom',
      renderComponent: OrderTotalRenderComponent,
      filter: false,
      sort: true,
    },
    Tags: {
      title: 'Tags',
      type: 'custom',
      renderComponent: OrderTagsRenderComponent,
      filter: false,
      sort: false,
    },
  };

  filters = [
    {
      Name: 'Created',
      Label: 'Created',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'BillingStatus',
      Label: 'Billing Status',
      Type: 'multiselect',
      Values: [
        {
          id: 'new',
          name: 'New',
        },
        {
          id: 'waiting for payment',
          name: 'Waiting for payment',
        },
        {
          id: 'paid',
          name: 'Paid',
        },
        {
          id: 'canceled',
          name: 'Canceled',
        },
        {
          id: 'refunded partially',
          name: 'Refunded partially',
        },
        {
          id: 'refunded',
          name: 'Refunded',
        },
      ],
    },
    {
      Name: 'Items',
      Label: 'Items',
      Placeholder: '>0',
      Value: '',
    },
    {
      Name: 'ShippingStatus',
      Label: 'Shipping Status',
      Type: 'multiselect',
      Values: [
        {
          id: 'new',
          name: 'New',
        },
        {
          id: 'pending',
          name: 'Pending',
        },
        {
          id: 'manufacturing',
          name: 'Manufacturing',
        },
        {
          id: 'shipping',
          name: 'Shipping',
        },
        {
          id: 'shipped partially',
          name: 'Shipped partially',
        },
        {
          id: 'shipped',
          name: 'Shipped',
        },
      ],
    },
    {
      Name: 'Tag',
      Label: 'Tag',
      Type: 'multiselect',
      Values: [],
    },
    {
      Name: 'Total',
      Label: 'Total',
      Placeholder: '>0',
      Value: '',
    },
  ];

  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Items',
      Label: 'Items',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
    {
      Name: 'Total',
      Label: 'Total',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
  ];

  orderIds = [];
  author = 'me';
  subscription: Subscription;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit() {
    const those = this;
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info && info.User && info.User.Login) {
        those.author = info.User.Login;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
