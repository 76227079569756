import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../shared/api.service';

@Component({
  template: `
      <div *ngIf="!rowData['term']" class="custom" #ref><span class="btn btn-xs btn-primary up" style="margin-right: 5px;" (click)="up()"><i class="fa fa-angle-up" aria-hidden="true"></i></span><span class="btn btn-xs btn-primary down" (click)="down()"><i class="fa fa-angle-down" aria-hidden="true"></i></span></div>
  `,
})
export class CategorySortRenderComponent implements OnInit, ViewCell, AfterViewInit {
  @ViewChild('ref') ref;
  parent;
  @Input() value: string;
  @Input() rowData: any;
  timer;

  constructor(public apiService: ApiService){

  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.rowData['Updated']){
      let element = this.ref.nativeElement.parentElement;
      while (element) {
        if (element.classList.contains('ng2-smart-row')) {
          break;
        }
        element = element.parentElement;
      }
      element.style.outline = '1px solid red';
      //element.style.backgroundColor = 'gray';
      setTimeout(() => {
        this.rowData['Updated'] = false;
        element.style.outline = 'none';
        //element.style.backgroundColor = 'initial';
      }, 1500);
    }
  }

  up() {
    const a = {ID: 0, Sort: 0};
    const b = {ID: 0, Sort: 0};
    if (this.timer) {
      clearTimeout(this.timer);
    }
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (i > 0) {
          if (this.parent.source.data[i - 1]['level'] != this.parent.source.data[i]['level']) {
            return;
          }
          a.ID = this.parent.source.data[i]['ID'];
          a.Sort = this.parent.source.data[i - 1]['Sort'];
          b.ID = this.parent.source.data[i - 1]['ID'];
          b.Sort = this.parent.source.data[i]['Sort'];
          this.parent.source.data[i - 1]['Sort'] = b['Sort'];
          this.parent.source.data[i]['Sort'] = a['Sort'];
          this.parent.source.data[i]['Updated'] = true;
          //
          const item = this.parent.source.data[i];
          this.parent.source.data[i] = this.parent.source.data[i - 1];
          this.parent.source.data[i - 1] = item;
          break;
        }else{
          return;
        }
      }
    }
    //this.parent.source.data.sort((a, b) => { if (a['Sort'] > b['Sort']) { return 1; }else if (a['Sort'] < b['Sort']) { return -1; }else{ return 0; }});
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all([
        this.apiService.patchCategory(a.ID, 'setSort', {Sort: a.Sort}).catch(err => {
          console.log(err);
        }),
        this.apiService.patchCategory(b.ID, 'setSort', {Sort: b.Sort}).catch(err => {
          console.log(err);
        }),
      ]).catch(err => {
        console.log(err);
      });
    }, 1000);
  }

  down() {
    const a = {ID: 0, Sort: 0};
    const b = {ID: 0, Sort: 0};
    if (this.timer) {
      clearTimeout(this.timer);
    }
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (i < this.parent.source.data.length - 1) {
          if (this.parent.source.data[i]['level'] != this.parent.source.data[i + 1]['level']) {
            return;
          }
          a.ID = this.parent.source.data[i]['ID'];
          a.Sort = this.parent.source.data[i + 1]['Sort'];
          b.ID = this.parent.source.data[i + 1]['ID'];
          b.Sort = this.parent.source.data[i]['Sort'];
          this.parent.source.data[i + 1]['Sort'] = b['Sort'];
          //this.parent.source.data[i + 1]['Updated'] = true;
          this.parent.source.data[i]['Sort'] = a['Sort'];
          this.parent.source.data[i]['Updated'] = true;
          //
          const item = this.parent.source.data[i];
          this.parent.source.data[i] = this.parent.source.data[i + 1];
          this.parent.source.data[i + 1] = item;
          break;
        }else{
          return;
        }
      }
    }
    //this.parent.source.data.sort((a, b) => { if (a['Sort'] > b['Sort']) { return 1; }else if (a['Sort'] < b['Sort']) { return -1; }else{ return 0; }});
    this.parent.source.local = true;
    this.parent.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all([
        this.apiService.patchCategory(a.ID,'setSort', {Sort: a.Sort}).catch(err => {
          console.log(err);
        }),
        this.apiService.patchCategory(b.ID, 'setSort', {Sort: b.Sort}).catch(err => {
          console.log(err);
        }),
      ]).catch(err => {
        console.log(err);
      });
    }, 1000);
  }

}
