<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/sales/customers']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1 [innerHtml]="('Customer' | translate) + ': ' + (customer ? (customer['Name'] + ' ' + customer['Lastname']) : 'New')"></h1>
        </div>
        <div *ngIf="customer && form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body" style="padding: 15px 15px 0 15px;">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name" class="mb-1" translate>First Name</label>
                  <input class="form-control" id="name" type="text" (change)="change('Name', $event)" formControlName="Name">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="lastname" class="mb-1" translate>Last Name</label>
                  <input class="form-control" id="lastname" type="text" (change)="change('Lastname', $event)" formControlName="Lastname">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="company" class="mb-1" translate>Company</label>
                  <input class="form-control" id="company" type="text" (change)="change('Company', $event)" formControlName="Company">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="email" class="mb-1" translate>Email</label>
                  <input class="form-control" id="email" type="email" (change)="change('Email', $event)" formControlName="Email">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phone" class="mb-1" translate>Phone Number</label>
                  <div>
                    <app-phone-input id="phone" (change)="change('Phone', $event)" formControlName="Phone"></app-phone-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="margin-bottom: 30px;">
              <div class="col">
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="allow-receive-emails" formControlName="AllowReceiveEmails">
                  <label for="allow-receive-emails" class="mb-1" translate>AllowReceiveEmails</label>
                </div>
                <div class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="allow-receive-sms" formControlName="AllowReceiveSMS">
                  <label for="allow-receive-sms" class="mb-1" translate>AllowReceiveSMS</label>
                </div>
                <div *ngIf="!customer" class="form-group">
                  <input type="checkbox" class="checkbox_animated" id="alternative-billing" (change)="toggle()" formControlName="SplitProfiles">
                  <label for="alternative-billing" class="mb-1" translate>Alternative billing address</label>
                </div>
              </div>
            </div>
            <div *ngFor="let type of types; let i = index">
              <div class="row">
                <div class="col">
                  <h2 style="padding-bottom: 10px;" [innerHTML]="(types.length > 1 ? type + ' ' : '') + 'Address' | translate"></h2>
                </div>
              </div>
              <div *ngIf="form.get(type + 'Profiles').length > 0" [attr.id]="type + '-accordion'" [formArrayName]="type + 'Profiles'">
                <div *ngFor="let profile of form.get(type + 'Profiles').controls; let j = index" class="row">
                  <div class="col">
                    <div class="card" style="margin-bottom: 10px;" [formGroupName]="j">
                      <div class="card-header" [attr.aria-invalid]="type + '-heading-' + j" style="padding: 0">
                        <h5 class="mb-0">
                          <div class="btn" style="position: relative; text-align: left; width: 100%" data-toggle="collapse" [attr.data-target]="'#' + type + '-collapse-' + j" [attr.aria-controls]="type + '-collapse-' + j">
                            <div *ngIf="profile.get('ID').value; else new">
                              <span>#{{ profile.get('ID').value }} {{ profile.get('Country').value }}, {{ profile.get('Zip').value }}, {{ profile.get('Address').value }}, {{ profile.get('City').value }}</span>
                            </div>
                            <ng-template #new>
                              <span translate>New Profile</span>
                            </ng-template>
                            <span title="delete" class="btn-sm btn-default facon-minus" style="font-size: 16px;position: absolute; right: 0px; top: 4px" (click)="removeAddress(type, j, $event)"></span>
                          </div>
                        </h5>
                      </div>

                      <div [attr.id]="type + '-collapse-' + j" class="collapse" [ngClass]="{show: j === 0}" [attr.aria-labelledby]="type + '-heading-' + j" [attr.data-parent]="'#' + type + '-accordion'">
                        <div class="card-body" style="padding-bottom: 0px;">
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-country-'+j" class="mb-1" translate>Country</label>
                                <app-country-input [attr.id]="type+'-country-'+j" formControlName="Country"></app-country-input>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-name-'+j" class="mb-1" translate>First Name</label>
                                <input class="form-control" [attr.id]="type+'-name-'+j" type="text" formControlName="Name">
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-lastname-'+j" class="mb-1" translate>Last Name</label>
                                <input class="form-control" [attr.id]="type+'-lastname-'+j" type="text" formControlName="Lastname">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-company-'+j" class="mb-1" translate>Company</label>
                                <input class="form-control" [attr.id]="type+'-company-'+j" type="text" formControlName="Company">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-address-'+j" class="mb-1" translate>Address</label>
                                <input class="form-control" [attr.id]="type+'-address-'+j" type="text" formControlName="Address" required>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-city-'+j" class="mb-1" translate>City</label>
                                <input class="form-control" [attr.id]="type+'-city-'+j" type="text" formControlName="City" required>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label [attr.for]="type+'-zip-'+j" class="mb-1" translate>ZIP</label>
                                <input class="form-control" [attr.id]="type+'-zip-'+j" type="text" formControlName="Zip" required>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label [attr.for]="type+'-phone-'+j" class="mb-1" translate>Phone Number</label>
                                <app-phone-input [attr.id]="type+'-phone-'+j" formControlName="Phone"></app-phone-input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom" style="margin-bottom: 20px;padding-bottom: 20px;">
                <div class="col">
                  <span class="btn-sm btn-default facon-plus" style="padding: 0 5px;text-transform: none;" title="add" (click)="addAddress(type, {})" [innerHTML]="' Add ' + (customer && customer[type + 'Profiles'] && customer[type + 'Profiles'].length > 0 ? 'another ' : '') + 'address' | translate"></span>
                </div>
              </div>
            </div>
            <div *ngIf="customer">
              <div class="row">
                <div class="col">
                  <h2 style="padding-bottom: 10px;" translate>Notes</h2>
                </div>
              </div>
              <div class="row border-bottom" style="padding-bottom: 10px;margin-bottom: 20px;">
                <div class="col">
                  <div style="margin-bottom: 5px; padding-bottom: 10px">
                    <input class="form-control" style="width: 100%" placeholder="write here internal note" (keydown.enter)="addNote($event.target)"/>
                  </div>
                  <div *ngFor="let note of customer.Notes; let i = index" style="padding-bottom: 10px">
                    <div class="note-header">
                      <span class="body-5 note-author">{{note.Author}}</span><span class="body-5 note-date">{{note.Created | amDateFormat:'DD.MM.YYYY - HH:mm'}}</span>
                    </div>
                    <div class="note-body">
                      {{note.Note}}
                      <div style="position: absolute; right: 10px; top: 5px; cursor:pointer;" (click)="deleteNote(i)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="7" cy="7" r="7" fill="#ADB5BD"/>
                          <rect x="5.51562" y="4.5249" width="5.6" height="1.4" transform="rotate(45 5.51562 4.5249)" fill="white"/>
                          <rect x="9.47656" y="5.51514" width="5.6" height="1.4" transform="rotate(135 9.47656 5.51514)" fill="white"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
            <div class="row">
              <div class="col">
                <h2 style="padding-bottom: 10px;" translate>Tags</h2>
              </div>
            </div>
            <div class="row border-bottom" style="padding-bottom: 20px">
              <div class="col">
                <div class="form-group">
                  <app-tag-input formControlName="Tags"></app-tag-input>
                </div>
              </div>
            </div>
            <!-- -->
            <div class="row" style="padding: 20px;">
              <div class="col">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="!form.valid || !form.touched" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
                <a class="btn btn-cancel" [routerLink]="['/sales/customers']" translate>Cancel</a>
                <button *ngIf="customer" class="btn btn-sm btn-default delete" type="button" style="padding: 8px;" (click)="onDelete()">
                  <svg style="height: 18px; width: 18px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
