import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';

@Component({
  template: `
    <div *ngIf="rowData['File']; else otherwise">
        <a *ngIf="rowData['File']" [href]="rowData['File']" target="_blank">{{name}}</a>
    </div>
    <ng-template #otherwise>
      <div>{{name}}</div>
    </ng-template>
  `,
})
export class FilePathRenderComponent implements ViewCell, OnInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  name;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe){ }

  ngOnInit() {
    this.name = this.rowData['Path'].substring(this.rowData['Path'].lastIndexOf('/') + 1);
    this.rowData['Path'] = this.absolutizeUrl.transform('/storage' + this.rowData['Path']);
  }

}
