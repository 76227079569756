import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';

@Component({
  styles: [
    `
      div > a {
        padding-right: 5px;
      }
      .description {
        font-size: smaller;
      }
      .description > a {
        color: gray !important;
      }
      .description > a:not(:last-child)::after {
        content: ',';
      }
    `,
  ],
  template: `
    <div style="display: flex; vertical-align: middle">
      <div
        [style]="{
          backgroundImage:
            'url(' +
            absolutizeUrl.transform(
              '/api/v1/resize?path=' +
                (thumbnail ? thumbnail + '&updated=' + rowData['UpdatedAt'] : '/assets/images/no-image.svg') +
                ')'
            ),
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'inline-block',
          height: '60px',
          minWidth: '60px',
          marginRight: '10px',
          verticalAlign: 'middle'
        }"></div>
      <div style="display:inline-block;vertical-align: middle">
        <a class="link" [routerLink]="['/products/' + rowData['ID']]" style="cursor: pointer">{{ value }}</a>
        <div *ngIf="variations && variations.length > 0" class="description">
          <a
            *ngFor="let variation of variations"
            [routerLink]="['/products', rowData['ID'], 'variations', variation.id]"
            >{{ variation.title }}</a
          >
        </div>
      </div>
    </div>
  `,
})
export class ProductTitleRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  thumbnail;
  variations = [];

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngOnInit() {
    if (this.rowData['Thumbnail']) {
      const thumbnail = this.rowData['Thumbnail'];
      const res = thumbnail.split(/,/);
      if (res && res.length === 1) {
        this.thumbnail = '/storage/' + thumbnail;
      } else {
        this.thumbnail = res[1].split(/ /)[0];
      }
    }
    if (this.rowData.VariationIds && this.rowData.Variations) {
      const ids = this.rowData.VariationIds.split(/,/);
      const titles = this.rowData.Variations.split(/,/);
      ids.forEach((id, index) => {
        this.variations.push({ id, title: titles[index] });
      });
    }
  }
}
