import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class PagesComponent implements OnInit {
  @ViewChild('tinymce') activeEditor;
  loading1 = true;
  loading2 = false;
  home = '/';
  pages;
  page;
  config = {};
  content;
  modal;
  closeResult;
  editForm;
  createForm;
  visible = false;
  content2 = 'aaa';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        // Perform actions
        if (this.page) {
          setTimeout(() => {
            this.close();
            this.router.navigate(['/pages']);
          }, 100);
        }
      }
    });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    this.config = {
      selector: 'textarea.tinymce',
      baseURL: those.absolutizeUrl.transform('/assets/tinymce'),
      //document_base_url: those.absolutizeUrl.transform('/admin'),
      //relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | code | help',
      plugins: ['code', 'link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        those.apiService
          .postImage(data)
          .then(resp => {
            those.success('Create image', resp);
            success(resp['Url']);
          })
          .catch(err => {
            those.error('Create image', err);
            failure(err['ERROR'] ? err['ERROR'] : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 500,
      branding: false,
    };
    this.refresh();
  }

  debug() {
    console.log('value', this.editForm.get('Content').value);
  }

  split(str) {
    let arr = [];
    if (str.length > 0 && str[0] === '/') {
      str = str.substring(1);
    }
    arr = str.split(/\//);
    const chunks = [];
    for (let i = 0; i < arr.length; i++) {
      chunks.push({ path: arr.slice(0, i + 1).join('/'), name: arr[i] });
    }
    return chunks;
  }

  refresh() {
    const those = this;
    those.loading1 = true;
    this.apiService
      .getContents(those.home, 0)
      .then(list => {
        those.loading1 = false;
        those.pages = list;
      })
      .catch(err => {
        those.error('Load contents', err);
      });
  }

  cd(path) {
    console.log('cd', path);
    this.home = path;
    this.refresh();
  }

  open(path, duplicate?: boolean) {
    const those = this;
    this.loading1 = true;
    this.loading2 = true;
    this.apiService
      .getContent(path)
      .then(page => {
        page['Url'] = page['Url'].replace(/\/index$/, '');
        those.page = page;
        those.content = page['Content'];
        //
        those.editForm = this.formBuilder.group({
          Type: [{ disabled: true, value: 'Page' }],
          Title: [page['Title'] ? page['Title'] : 'Untitled', Validators.required],
          Name: [page['Name'] ? page['Name'].replace(/\.html$/, '') : 'untitled', Validators.required],
          Description: [page['Description'] ? page['Description'] : ''],
          Draft: [page['Draft'] ? true : false],
          Content: [page['Content']],
        });
        //
        if (duplicate) {
          those.editForm.get('Title').setValue(those.editForm.get('Title').value + ' (Copy)');
          those.editForm.get('Name').setValue(those.editForm.get('Name').value.replace(/\.(html)$/, '-copy.$1'));
        }
        //
        those.loading1 = false;
        those.loading2 = false;
        //window.history.pushState(null, 'Pages', 'pages?' + Math.ceil(Math.round(1000)));
      })
      .catch(err => {
        those.error('Load content', err);
      });
  }

  close() {
    this.page = null;
    this.refresh();
  }

  draft(path) {
    const those = this;
    this.apiService
      .patchContent(path, { Action: 'draft' })
      .then(resp => {
        those.refresh();
      })
      .catch(err => {
        those.error('Delete content', err);
      });
  }

  delete(path) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete content?';
    modal.componentInstance.body = `Are you sure you want to delete content? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .patchContent(path, { Action: 'remove' })
          .then(resp => {
            those.success('Delete content', resp);
            those.refresh();
          })
          .catch(err => {
            those.error('Delete content', err);
          });
      },
      reason => {}
    );
  }

  onCreateFolder(content) {
    const those = this;
    this.createForm = this.formBuilder.group({
      Type: ['folder'],
      Title: ['New Folder', Validators.required],
      Name: ['new-folder', Validators.required],
    });
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onCreateFile(content) {
    const those = this;
    this.createForm = this.formBuilder.group({
      Type: ['file'],
      Title: ['New Page', Validators.required],
      Name: ['new-page', Validators.required],
    });
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  change(form, event) {
    form.get('Name').setValue(
      event.target.value
        .toLowerCase()
        .replace(/ö/gi, 'oe')
        .replace(/ü/gi, 'ue')
        .replace(/ä/gi, 'ae')
        .replace(/ß/gi, 'ss')
        .replace(/[^-a-z0-9\(\)]+/gi, '-')
    );
  }

  onEditFormSubmit() {
    const those = this;
    const raw = those.editForm.getRawValue();
    if (raw['Type'] == 'Page') {
      raw['Name'] += '.html';
    }
    if (!this.page['Name']) {
      this.apiService
        .postContent(those.home + '/', raw)
        .then(resp => {
          those.success('Create page', resp);
          those.page = null;
          those.refresh();
        })
        .catch(err => {
          those.error('Create page', err);
        });
    } else {
      this.apiService
        .putContent(those.home + '/' + raw['Name'], raw)
        .then(resp => {
          those.success('Save page', resp);
        })
        .catch(err => {
          those.error('Save page', err);
        });
    }
  }

  onCreateFormSubmit() {
    const those = this;
    const raw = those.createForm.getRawValue();
    const prefix = ((those.home === '/' ? '' : those.home) + '/').replace(/\/{2,}/, '/');
    this.apiService
      .patchContent(prefix + raw['Name'] + (raw['Type'] === 'file' ? '.html' : ''), {
        Action: 'create',
        Type: raw['Type'],
        Title: raw['Title'],
      })
      .then(resp => {
        those.success('Created', resp);
        those.modal.close('Saved');
        if (raw['Type'] === 'file') {
          those.open(prefix + raw['Name'] + '.html');
        } else {
          those.cd(prefix + raw['Name']);
        }
      })
      .catch(err => {
        those.error('Create', err);
      });
  }
}
