import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {Router} from '@angular/router';

@Component({
  template: `
    <a class="link" [routerLink]="['/sales/orders']" [queryParams]="{search: 'order:' + rowData['OrderId']}" style="cursor: pointer">#{{value}}</a>
  `,
})
export class TransactionOrderRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;

}
