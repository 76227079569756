import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { credentials } from '../../../shared/credentials.data';

@Component({
  template: `
    <div style="display: flex; gap: 7px; align-items: center;">
      <div>
        <img
          [attr.src]="'/assets/fonts/flag-icon/' + rowData['BillingProfileCountry'] + '.svg'"
          style="width: 22px;height: 14px;" />
      </div>
      <div style="display: inline-block; line-height: 1.2">
        <div style="font-weight: 500;">
          #{{ rowData['UserId'] }} {{ rowData['BillingProfileName'] }} {{ rowData['BillingProfileLastname'] }}
        </div>
        <div>
          <span>{{ rowData['BillingProfileCity'] }}, {{ rowData['BillingProfileCountryName'] }}</span>
        </div>
      </div>
    </div>
  `,
})
export class OrderNameRenderComponent implements OnInit, ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  countries = credentials.countries;

  ngOnInit(): void {
    const country = this.countries.find(
      item => this.rowData['Country'] && item['Code'] === this.rowData['Country'].toUpperCase()
    );
    if (country) {
      this.rowData['CountryName'] = country['Name'];
    }
  }
}
