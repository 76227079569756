import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { ProductTitleRenderComponent } from './product-title-render.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { ProductsDataSource } from './products.data-source';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { from, Observable, timer as observableTimer, of, Subscription, Subject } from 'rxjs';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { SubjectService } from '../../shared/subject.service';
import { debounceTime } from 'rxjs/operators';
import { FavoriteRenderComponent } from '../../shared/render/favorite-render.component';
import { EnabledRenderComponent } from '../../shared/render/enabled-render.component';
import { DragulaService } from 'ng2-dragula';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';
import { SelectedRenderComponent } from '../../shared/render/selected-render.component';
import { ListRenderComponent } from '../../shared/render/list-render.component';
import {TitleRenderComponent} from "../../shared/render/title-render.component";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class ProductsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('content') content;
  queryParamsSubscription: Subscription;
  cid;
  tree;
  settings;
  source: ProductsDataSource;
  row;
  category;
  product;
  form;
  modal;
  closeResult;
  visible = false;
  filtered = true;
  categoryOptions: IMultiSelectOption[] = [];
  timer;
  status;
  selected;
  selectedAll = false;

  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Products',
      Columns: [
        {
          Name: 'Title',
          Label: 'Product',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'Enabled',
          Label: 'Status',
          Selected: true,
        },
        {
          Name: 'Sku',
          Label: 'Sku',
          Selected: true,
        },
        {
          Name: 'Stock',
          Label: 'Stock',
          Selected: true,
        },
        {
          Name: 'Category',
          Label: 'Category',
          Selected: true,
        },
        {
          Name: 'Vendor',
          Label: 'Vendor',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
      // {field: 'Created', direction: 'desc'}
    },
    {
      Id: '2',
      Name: 'featured',
      Label: 'Featured',
      Columns: [
        {
          Name: 'Title',
          Label: 'Product',
          Selected: true,
        },
        {
          Name: 'Favorite',
          Label: 'Favorite',
          Selected: true,
        },
        {
          Name: 'Enabled',
          Label: 'Status',
          Selected: true,
        },
        {
          Name: 'Sku',
          Label: 'Sku',
          Selected: true,
        },
        {
          Name: 'Stock',
          Label: 'Stock',
          Selected: true,
        },
      ],
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Favorite',
            Label: 'Featured',
            Type: 'select',
            Value: {
              Label: 'Yes',
              Value: 'true',
            },
          },
        ],
      },
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'tables',
      Label: 'Tables',
      Filter: [],
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
      Term: 'Tisch',
    },
  ];
  preset;
  sort;
  filters = [
    {
      Name: 'Category',
      Label: 'Category',
      Type: 'multiselect',
      Values: [],
    },
    {
      Name: 'Created',
      Label: 'Created',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'Favorite',
      Label: 'Featured',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'favorite',
          Label: 'Yes',
          Value: 'true',
        },
        {
          Name: 'not-favorite',
          Label: 'No',
          Value: 'false',
        },
      ],
    },
    {
      Name: 'Enabled',
      Label: 'Status',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'active',
          Label: 'Active',
          Value: 'true',
        },
        {
          Name: 'inactive',
          Label: 'Inactive',
          Value: 'false',
        },
      ],
    },
    {
      Name: 'Sku',
      Label: 'Sku',
      Placeholder: 'sku-123',
      Value: '',
    },
    {
      Name: 'Stock',
      Label: 'Stock',
      Placeholder: '>0',
      Value: '',
    },
    {
      Name: 'Updated_At',
      Label: 'Updated',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last30days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
      ],
    },
    {
      Name: 'Vendor',
      Label: 'Vendor',
      Type: 'multiselect',
      Values: [],
    },
  ];
  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Updated_At',
      Label: 'Last updated',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Title',
      Label: 'Title',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
    {
      Name: 'Favorite',
      Label: 'Featured',
      Order: [
        {
          Type: 'asc',
          Label: 'Start from not featured',
        },
        {
          Type: 'desc',
          Label: 'Start from featured',
        },
      ],
    },
    {
      Name: 'Base_Price',
      Label: 'Price',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
    {
      Name: 'Sku',
      Label: 'SKU',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
    {
      Name: 'Stock',
      Label: 'Stock',
      Order: [
        {
          Type: 'desc',
          Label: 'Highest to lowest',
        },
        {
          Type: 'asc',
          Label: 'Lowest to highest',
        },
      ],
    },
  ];
  bulks = [
    {
      Name: 'set-active',
      Label: 'Set active',
    },
    {
      Name: 'set-inactive',
      Label: 'Set inactive',
    },
  ];
  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    Title: {
      title: 'Product',
      type: 'custom',
      renderComponent: TitleRenderComponent,
      filter: false,
      sort: true,
    },
    Favorite: {
      title: '',
      type: 'custom',
      renderComponent: FavoriteRenderComponent,
      filter: false,
      sort: true,
    },
    Enabled: {
      title: 'Status',
      type: 'custom',
      renderComponent: EnabledRenderComponent,
      filter: false,
      sort: false,
    },
    /*BasePrice: {
      title: 'Price',
      type: 'custom',
      renderComponent: ProductPriceRenderComponent,
      filter: false,
      sort: false
    },*/
    Sku: {
      title: 'Sku',
      filter: false,
      sort: true,
    },
    Stock: {
      title: 'Stock',
      filter: false,
      sort: true,
      hidden: true,
    },
    Category: {
      title: 'Category',
      type: 'custom',
      renderComponent: ListRenderComponent,
      filter: false,
      sort: true,
    },
    Vendor: {
      title: 'Vendor',
      filter: false,
      sort: true,
    },
  };

  perPage = 10;
  widths;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    private subjectService: SubjectService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService,
    private dragulaService: DragulaService,
    private localStorageService: LocalStorageService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    //this.filter['status'] = this.filters['status'][0];
    this.sort = this.sorts[1];
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params['cid']) {
        those.cid = params['cid'];
        console.log('cid', those.cid);
        //
        those.apiService
          .getCategory(params['cid'])
          .then(category => {
            this.subjectService.breadcrumbsSubject.next({ title: category.Title });
            those.category = category;
          })
          .catch(err => {
            those.error('Load category', err);
          });
      } else {
        const refresh = this.cid !== params['cid'];
        those.cid = undefined;
        if (refresh) {
          window.location.reload();
        }
      }
    });
    //
    //this.createSource();
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (history && history.state && history.state.action) {
      if (history.state.action === 'create') {
        this.onCreate(this.content);
      } else if (history.state.action === 'edit' && history.state.id) {
        this.onEdit(this.content, history.state.id);
      }
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Description: [''],
      Thumbnail: [''],
      Category: [],
    });
    //
    those.loadData({ filter: '' }, '', 1);
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getProduct(id)
      .then(product => {
        those.product = product;
        those.form = those.formBuilder.group({
          ID: [product['ID']],
          Name: [{ disabled: true, value: product['Name'] }, Validators.required],
          Title: [product['Title'], Validators.required],
          Description: [product['Description'] ? product['Description'] : ''],
          Thumbnail: [product['Thumbnail'] ? those.absolutizeUrl.transform(product['Thumbnail']) : ''],
          Category: [(product['Category'] || []).map(item => item['ID'])],
        });
        those.loadData({ filter: '' }, those.form.get('Categories').value.join(','), 1);
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
          }
        );
      })
      .catch(err => {
        console.log('err', err);
        those.error('Load categories', err);
      });
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  upload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
    console.log('Thumbnail', this.form.get('Thumbnail'));
  }

  refresh(hard?: boolean) {
    if (hard) {
      console.log(`let's try soft reload`);
      this.source = undefined;
      //this.createSource();
      //window.location.reload();
      return;
    } else {
      this.source.refresh();
    }
  }

  onAddProduct() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', 'true');
    if (this.category) {
      data.append('Categories', this.category.ID);
    }
    data.append('Title', 'New Product');
    this.apiService
      .postProduct(data)
      .then(product => {
        those.router.navigate(['/products/' + product.ID]);
      })
      .catch(err => {
        those.error('Create product', err);
      });
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Description', this.form.get('Description').value);
    data.append('Category', this.form.get('Category').value.join(','));
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postProduct(data)
        .then(resp => {
          those.success('Create product', resp);
          those.modal.close('Saved');
          those.source.refresh();
        })
        .catch(err => {
          those.error('Create product', err);
        });
    } else {
      this.apiService
        .putProduct(those.product['ID'], data)
        .then(resp => {
          those.success('Update product', resp);
          those.modal.close('Saved');
          those.source.refresh();
        })
        .catch(err => {
          those.error('Update product', err);
        });
    }
  }

  observableSource(keyword: any): Observable<any[]> {
    console.log('observableSource', keyword);
    const those = this;
    console.log('a');
    const result = from(
      those.apiService.postCategoriesAutocomplete(keyword),
      those.form.get('Categories').value.join(',')
    );
    console.log('b');
    return result;
  }

  listFormatter(data: any): string {
    return `${data['Path']} <b>${data['Title']}</b>`;
  }

  valueFormatter(data: any): string {
    return `${data['Title']}`;
  }

  loadData(event, include?: string, delay?: number) {
    const those = this;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(
      () => {
        those.apiService.postCategoriesAutocomplete(event.filter, include).then(results => {
          if (results) {
            those.categoryOptions = results.map(item => {
              return { id: item['ID'], name: item['Path'] + ' ' + item['Title'] };
            });
          }
        });
      },
      delay ? delay : 300
    );
    //
  }

  onEditPosition(to) {
    if (to <= 0 || to > this.source.data.length) {
      this.error('Invalid value', { status: 500, error: { ERROR: 'The value is out of range' } });
      return;
    }
    const toId = this.source.data[to - 1]['ID'];
    const toSort = this.source.data[to - 1]['Sort'];
    const from = this.source.data.findIndex(item => item.ID === this.selected[0].ID);
    const fromId = this.source.data[from]['ID'];
    const fromSort = this.source.data[from]['Sort'];
    const row = this.source.data.splice(from, 1);
    this.apiService.patchProduct(toId, 'setSort', { CategoryId: Number(this.cid), Sort: fromSort }).catch(err => {
      console.log(err);
    });
    this.apiService.patchProduct(fromId, 'setSort', { CategoryId: Number(this.cid), Sort: toSort }).catch(err => {
      console.log(err);
    });
    this.source.data.splice(to - 1, 0, row[0]);
    this.source.local = true;
    this.source.refresh();
  }
}
