import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';

@Component({
  styles: [
    `
      div > a {
        padding-right: 5px;
      }
    `,
  ],
  template: `
    <div style="vertical-align: middle">
      <div
        [style]="{
          backgroundImage:
            'url(' +
            absolutizeUrl.transform(
              (thumbnail ? thumbnail : '/api/v1/resize?path=/assets/images/no-image.svg') + ')'
            ),
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'inline-block',
          height: '60px',
          width: '60px',
          marginRight: '10px',
          verticalAlign: 'middle'
        }"></div>
      <div style="display:inline-block;vertical-align: middle">
        <a
          class="link"
          style="cursor: pointer"
          [routerLink]="['/products/' + rowData['ProductId'] + '/variations/' + rowData['ID']]"
          >{{ value }}</a
        >
      </div>
    </div>
  `,
})
export class VariationTitleRenderComponent implements ViewCell, OnInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  thumbnail;

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngOnInit() {
    if (this.rowData['Thumbnail']) {
      const thumbnail = this.rowData['Thumbnail'];
      const res = thumbnail.split(/,/);
      if (res && res.length > 1) {
        this.thumbnail = res[1].split(/ /)[0];
      }
    }
  }
}
