<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Payment Settings</h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-8 col-xl-6">
      <div class="card">
        <div class="card-body">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 20px;">
              <div class="col">
                <div class="row border-bottom" style="padding-bottom: 20px; margin-bottom: 20px;">
                  <div class="col">
                    <input type="hidden" formControlName="Default">
                    <div class="form-group">
                      <label for="country" class="mb-1" translate>Country</label>
                      <app-country-input id="country" formControlName="Country"></app-country-input>
                    </div>
                    <!--div class="form-group">
                      <label for="VAT" class="mb-1" translate>VAT</label>
                      <input class="form-control" id="VAT" type="number" min="0.0" max="100.0" step="0.01" formControlName="VAT">
                    </div-->
                  </div>
                </div>
                <div class="row border-bottom" style="padding-bottom: 20px; margin-bottom: 20px;">
                  <div class="col">
                    <div formGroupName="Stripe">
                      <div style="display: flex;justify-content: space-between;">
                        <h6 translate>Stripe</h6>
                        <div class="button-group-dropdown" style="display: inline-block">
                          <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Stripe').get('Enabled').value ? 'status-active' : 'status-inactive'">
                            <span [innerHTML]="form.get('Stripe').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                            <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu">
                            <li (click)="form.get('Stripe').get('Enabled').setValue(true)">
                              Active
                            </li>
                            <li (click)="form.get('Stripe').get('Enabled').setValue(false)">
                              Inactive
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="PublishedKey" class="mb-1">PublishedKey</label>
                        <input class="form-control" id="PublishedKey" type="text" formControlName="PublishedKey">
                      </div>
                      <div class="form-group">
                        <label for="SecretKey" class="mb-1">SecretKey</label>
                        <input class="form-control" id="SecretKey" type="text" formControlName="SecretKey">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-bottom" style="padding-bottom: 20px; margin-bottom: 20px;">
                  <div class="col">
                    <div formGroupName="Mollie">
                      <div style="display: flex;justify-content: space-between;">
                        <h6 translate>Mollie</h6>
                        <div class="button-group-dropdown" style="display: inline-block">
                          <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Mollie').get('Enabled').value ? 'status-active' : 'status-inactive'">
                            <span [innerHTML]="form.get('Mollie').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                            <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu">
                            <li (click)="form.get('Mollie').get('Enabled').setValue(true)">
                              Active
                            </li>
                            <li (click)="form.get('Mollie').get('Enabled').setValue(false)">
                              Inactive
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="PublishedKey" class="mb-1">Key</label>
                        <input class="form-control" id="Key" type="text" formControlName="Key">
                      </div>
                      <div class="form-group">
                        <label for="SecretKey" class="mb-1">ProfileID</label>
                        <input class="form-control" id="ProfileID" type="text" formControlName="ProfileID">
                      </div>
                      <div class="form-group">
                        <label class="mb-1">Methods</label>
                        <div class="methods" formArrayName="PaymentMethods">
                          <div *ngFor="let method of molliePaymentMethods;index as i" style="display: flex; align-items: center; padding-bottom: 10px" [formGroupName]="i">
                            <div style="flex-grow: 1;"><input class="form-control" [placeholder]="method['Name']" [title]="method['Name']" formControlName="Title"></div>
                            <div style="padding-left: 5px"><input class="checkbox_animated" [id]="method['Name']" type="checkbox" [title]="method['Name']" formControlName="Enabled"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row border-bottom" style="padding-bottom: 20px; margin-bottom: 20px;">
                  <div class="col">
                    <div formGroupName="AdvancePayment">
                      <div style="display: flex;justify-content: space-between;">
                        <h6 translate>Advance Payment</h6>
                        <div class="button-group-dropdown" style="display: inline-block">
                          <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('AdvancePayment').get('Enabled').value ? 'status-active' : 'status-inactive'">
                            <span [innerHTML]="form.get('AdvancePayment').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                            <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu">
                            <li (click)="form.get('AdvancePayment').get('Enabled').setValue(true)">
                              Active
                            </li>
                            <li (click)="form.get('AdvancePayment').get('Enabled').setValue(false)">
                              Inactive
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="PublishedKey" class="mb-1" translate>Payment details</label>
                        <textarea class="form-control" rows="5" formControlName="Details"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div formGroupName="OnDelivery">
                      <div style="display: flex;justify-content: space-between;">
                        <h6 translate>Payment on delivery</h6>
                        <div class="button-group-dropdown" style="display: inline-block">
                          <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('OnDelivery').get('Enabled').value ? 'status-active' : 'status-inactive'">
                            <span [innerHTML]="form.get('OnDelivery').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                            <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu">
                            <li (click)="form.get('OnDelivery').get('Enabled').setValue(true)">
                              Active
                            </li>
                            <li (click)="form.get('OnDelivery').get('Enabled').setValue(false)">
                              Inactive
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="padding: 0 30px;">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
