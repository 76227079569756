import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../shared/api.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-position',
  template: `
      <ng-content></ng-content>
      <ng-template #content let-modal>
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel" translate>
                  Set position
              </h5>
              <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="modal-body">
                  <div class="form">
                      <div class="form-group">
                          <label for="position" class="mb-1" translate>Position</label>:
                          <input class="form-control" id="position" type="text" formControlName="Position">
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-default" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')" translate>Close</button>
                  <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
              </div>
          </form>
      </ng-template>
  `,
  styles: [`
    span {
        font-weight: bolder;
    }
  `],
  providers: [ AbsolutizeUrlPipe ]
})
export class PositionComponent implements OnInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  tag;
  modal;
  closeResult;
  form;
  timer;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, private absolutizeUrl: AbsolutizeUrlPipe) { }

  ngOnInit(): void {
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEdit(content, position) {
    const those = this;
    those.form = those.formBuilder.group({
      Position: [position, Validators.required],
    });
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then((result) => {
      those.closeResult = `Closed with: ${result}`;
      those.onSaved.emit(this.form.get('Position').value);
    }, (reason) => {
      those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      those.onClosed.emit();
    });
  }

  onSubmit() {
    this.modal.close('Saved');
    return true;
  }
}
