import { Injectable } from '@angular/core';
import {SubjectService} from './subject.service';
import {ApiService} from './api.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Injectable()
export class AuthService {
  subscription: Subscription;
  info = undefined;

  constructor(private router: Router,
              private subjectService: SubjectService,
              private apiService: ApiService) {
  }

  public isAuthenticated() {
    const those = this;
    //
    if (!this.subscription) {
      this.subscription = this.subjectService.infoSubject.subscribe(info => {
        those.info = info;
      });
    }
    if (this.info) {
      return new Promise((resolve) => {
        resolve(!!this.info);
      });
    } else {
      return new Promise((resolve) => {
        this.apiService.getInfo().then(info => {
          those.subjectService.infoSubject.next(info);
          resolve(true);
        }).catch(err => {
          those.subjectService.infoSubject.next(false);
          resolve(false);
        });
      });
    }
  }
}
