import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-rate',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!rate || !rate['ID']" class="modal-title" id="exampleModalLabel" translate>Add Rate</h5>
        <h5 *ngIf="rate && rate['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Rate</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label class="d-block" for="enabled">
                <input class="checkbox_animated" id="enabled" type="checkbox" formControlName="Enabled" />
                {{ 'Enabled' | translate }}
              </label>
            </div>
            <div class="form-group">
              <label for="value" class="mb-1" translate>Title</label>
              <input
                *ngIf="rate && rate['Value']"
                type="text"
                class="form-control"
                [value]="rate['Value']['Title']"
                disabled />
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label *ngIf="!salePrice" class="mb-1" translate>Price</label>
                  <label *ngIf="salePrice" class="mb-1" translate>BasePrice</label>
                  <input
                    class="form-control"
                    id="baseprice"
                    type="text"
                    (focusin)="trim(form.get('BasePrice'))"
                    (focusout)="format(form.get('BasePrice'))"
                    formControlName="BasePrice" />
                </div>
              </div>
              <div *ngIf="salePrice" class="col">
                <div class="form-group">
                  <label for="saleprice" class="mb-1" translate>SalePrice</label>
                  <input
                    class="form-control"
                    id="saleprice"
                    type="text"
                    (focusin)="trim(form.get('SalePrice'))"
                    (focusout)="format(form.get('SalePrice'))"
                    formControlName="SalePrice" />
                </div>
              </div>
            </div>

            <div class="form-row align-items-center" style="padding-bottom: 10px;">
              <div class="col-md-6">
                <label for="availability" class="mb-1" translate>Availability</label>:
                <select class="form-control" id="availability" formControlName="Availability">
                  <option value="available">{{ 'Available' | translate }}</option>
                  <option value="pre-order">{{ 'Pre-order' | translate }}</option>
                  <option value="out of stock">{{ 'Out of stock' | translate }}</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="sku" class="mb-1">Sku:</label>
              <input class="form-control" id="sku" type="text" formControlName="Sku" />
            </div>
            <div class="form-group">
              <label for="stock" class="mb-1">Stock:</label>
              <input class="form-control" id="stock" type="number" formControlName="Stock" min="0" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class RateComponent implements OnInit, AfterViewInit {
  //@Input('object') object;
  @Input('basePrice') basePrice;
  @Input('salePrice') salePrice;
  @Input('property') property;
  @Input('optionId') optionId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  rate;
  values;
  modal;
  closeResult;
  form;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onEdit(content, id, extra?: any) {
    const those = this;
    this.apiService
      .getRate(id)
      .then(rate => {
        those.rate = rate;
        those.form = those.formBuilder.group({
          ID: [rate['ID']],
          Enabled: [rate['Enabled']],
          PropertyId: [{ disabled: true, value: rate['PropertyId'] }],
          ValueId: [{ disabled: true, value: rate['ValueId'] }, Validators.required],
          BasePrice: [rate['BasePrice'], Validators.required],
          SalePrice: [rate['SalePrice']],
          Availability: [rate['Availability'] ? rate['Availability'] : 'available'],
          Sku: [rate['Sku'] ? rate['Sku'] : ''],
          Stock: [rate['Stock'] ? rate['Stock'] : 0],
        });
        //
        this.format(this.form.get('BasePrice'));
        this.format(this.form.get('SalePrice'));
        //
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load rate', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete price?';
    modal.componentInstance.body = `Are you sure you want to delete price? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteRate(id)
          .then(resp => {
            those.success('Delete price', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete price', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    raw['ID'] = +raw['ID'];
    raw['ValueId'] = +raw['ValueId'];
    raw['BasePrice'] = +raw['BasePrice'];
    raw['SalePrice'] = +raw['SalePrice'];
    this.apiService
      .putRate(raw['ID'], raw)
      .then(resp => {
        those.success('Update price', resp);
        those.modal.close('Saved');
        those.onSaved.emit();
      })
      .catch(err => {
        those.error('Update price', err);
      });
  }

  trim(control) {
    if (control && control.value) {
      control.setValue(control.value.replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }
}
