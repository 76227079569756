import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RateComponent} from '../../../shared/modals/rate/rate.component';
import {credentials} from '../../../shared/credentials.data';

@Component({
  selector: 'app-rate-inline',
  styles: [`
    .values-short-view{
      display: inline-block;
      vertical-align: text-bottom;
    }
    .values-short-view .value-short-view:not(:last-child):after {
      content: ', ';
    }
    .value-short-view {
      color: #616573;
      display: inline-block;
      padding-right: 5px;
    }
    .value-short-view .key:after{
      content: ': '
    }
  `],
  template: `
    <div class="row">
      <div class="col">
        <app-value-inline [property]="property" [optionId]="optionId" [form]="form.get('Value')" [tinymceConfig]="tinymceConfig"></app-value-inline>
      </div>
    </div>
  `
})
export class RateInlineComponent implements OnInit {
  //@Input('object') object;
  @Input('basePrice') basePrice;
  @Input('salePrice') salePrice;
  @Input('property') property;
  @Input('optionId') optionId;
  @Input('form') form;
  @Input('tinymceConfig') tinymceConfig;
  currency = credentials.currency;

  ngOnInit() { }
}
