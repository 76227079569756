import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediasDataSource } from '../../../components/medias/medias.data-source';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SelectedRenderComponent } from '../../render/selected-render.component';
import { FavoriteRenderComponent } from '../../render/favorite-render.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { MediaActionsRenderComponent } from '../../../components/medias/media-actions-render.component';
import { MediaNameRenderComponent } from '../../../components/medias/media-name-render.component';
import { MediaSizeRenderComponent } from '../../../components/medias/media-size-render.component';
import { MediaDateRenderComponent } from '../../../components/medias/media-date-render.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { LocalStorageService } from 'angular-2-local-storage';
import {SubjectService} from '../../subject.service';

@Component({
  selector: 'app-media-library',
  templateUrl: './medias.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class MediaLibraryComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      const widths = {};
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          const classes = item.classList
            .toString()
            .split(/\s+/)
            .filter(cl => ['ng2-smart-th', 'ng-star-inserted'].indexOf(cl) < 0);
          if (classes && classes.length > 0) {
            const style = item.getAttribute('style');
            const res = style.match(/width: (\d+)px;?/);
            if (res) {
              widths[classes[0]] = res[1];
            }
          }
        }
      });
      if (widths && Object.keys(widths).length > 0) {
        this.widths = widths;
        this.localStorageService.set('medias2_columns_widths', JSON.stringify(widths));
      }
    }
  }
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('postId') postId;
  @Input('multiple') multiple = true;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild(DropzoneDirective) dropzone: DropzoneDirective;
  id;
  tab = 'upload';
  view = 'tile';
  media;
  form;
  modal;
  closeResult;
  timer;
  term;
  defaultFilter; // {ContentType: "!^image/"}
  source: MediasDataSource;
  settings;
  visible = false;
  filtered = true;
  //
  searchChanged: Subject<string> = new Subject<string>();
  searchSubscription: Subscription;
  selected;
  sorts = [
    {
      Name: 'name:asc',
      Label: 'Name asc',
      Field: 'Name',
      Direction: 'asc',
    },
    {
      Name: 'name:desc',
      Label: 'Name desc',
      Field: 'Name',
      Direction: 'desc',
    },
    {
      Name: 'updated:asc',
      Label: 'Updated asc',
      Field: 'Updated',
      Direction: 'asc',
    },
    {
      Name: 'updated:desc',
      Label: 'Updated desc',
      Field: 'Updated',
      Direction: 'desc',
    },
  ];
  sort;
  filter = {
    On: true,
    Favorite: undefined,
    ContentTypes: [],
    UpdatedIn: '',
  };
  dropzoneConfig: DropzoneConfigInterface = {
    autoReset: 3000,
    clickable: true,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    parallelUploads: 1,
    timeout: 300000,
  };
  preview;
  perPage = 10;
  widths;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const those = this;
    //
    const url = new URL(this.apiService.getUrl() + '/medias/');
    if (this.productId) {
      url.searchParams.set('pid', this.productId);
    }
    if (this.variationId) {
      url.searchParams.set('vid', this.variationId);
    }
    this.dropzoneConfig.url = url.toString();
    this.dropzoneConfig.headers = this.apiService.getAuthorizationHeader();
    this.dropzoneConfig.paramName = 'Body';
    //
    this.searchSubscription = this.searchChanged.pipe(debounceTime(1000)).subscribe(() => {
      those.source.refresh();
    });
    //
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        edit: false,
        delete: false,
      },
      columns: {
        Selected: {
          title: '',
          type: 'custom',
          renderComponent: SelectedRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        /*ID: {
          title: 'Id',
          filter: false,
          sort: false
        },*/
        Name: {
          title: this.translate.instant('Name'),
          type: 'custom',
          renderComponent: MediaNameRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: true,
        },
        Favorite: {
          title: '',
          type: 'custom',
          renderComponent: FavoriteRenderComponent,
          onComponentInitFunction: instance => {
            instance.method = 'patchMedia';
            instance.parent = those;
          },
          filter: false,
          sort: true,
        },
        Size: {
          title: this.translate.instant('Size'),
          type: 'custom',
          renderComponent: MediaSizeRenderComponent,
          filter: false,
          sort: true,
        },
        Updated: {
          title: this.translate.instant('Updated'),
          type: 'custom',
          renderComponent: MediaDateRenderComponent,
          filter: false,
          sort: true,
        },
        Actions: {
          title: '',
          type: 'custom',
          renderComponent: MediaActionsRenderComponent,
          filter: false,
          sort: false,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    // set columns widths
    this.widths = JSON.parse(this.localStorageService.get('medias2_columns_widths'));
    if (this.widths && Object.keys(this.widths).length > 0) {
      for (const [key, value] of Object.entries(this.widths)) {
        if (this.settings.columns[key] && value) {
          this.settings.columns[key]['width'] = value + 'px';
        }
      }
    }
    this.source = new MediasDataSource(this.http, this.apiService, this.subjectService, this.absolutizeUrl, this.localStorageService);
    this.source.callback = () => {
      const table = document.querySelector('.modal-table table');
      const cols = table.querySelectorAll('thead tr.ng2-smart-titles th:not(.Selected):not(.Favorite)');
      [].forEach.call(cols, col => {
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');
        resizer.style.height = `${table['offsetHeight']}px`;
        col.appendChild(resizer);
        window['createResizableColumn'](col, resizer);
      });
    };
    this.source.perPage = 10;
    this.source.parent = this;
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  refresh(hard?: boolean) {
    if (hard) {
      window.location.reload();
      return;
    } else {
      this.source.refresh();
    }
  }

  toggle(tab) {
    if (tab === 'remote') {
      this.form = this.formBuilder.group({
        Name: [''],
        Title: [''],
        Alt: [''],
        Description: [''],
        File: [''],
        Orig: ['', Validators.required],
      });
      this.preview = undefined;
    }
    this.tab = tab;
  }

  select(index, event) {
    const those = this;
    setTimeout(() => {
      if (!this.multiple) {
        those.source.data.forEach((item, i) => {
          if (i !== index) {
            item.Selected = false;
          }
        });
      }
      those.selected = those.source.data.filter(item => item.Selected);
      those.source.local = true;
      those.source.refresh();
    }, 100);
  }
  resetColumns(event) {
    const ths = document.querySelectorAll('.modal-table .ng2-smart-titles th');
    if (ths && ths.length > 0) {
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          item.removeAttribute('style');
        }
      });
    }
    this.widths = undefined;
    this.localStorageService.remove('medias2_columns_widths');
    event.stopPropagation();
  }
  bulk(action?, id?) {
    const those = this;
    switch (action) {
      case 'click':
        if (!this.multiple) {
          those.source.data.forEach(item => {
            if (id && item.ID !== id) {
              item.Selected = false;
            }
          });
        }
        those.selected = those.source.data.filter(item => item['Selected']);
        those.source.local = true;
        those.source.refresh();
        break;
      case 'set-enabled':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchPost(those.source.data[i]['ID'], 'setEnabled', { Value: true }).then(() => {
                  those.source.data[i]['Enabled'] = true;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-disabled':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchPost(those.source.data[i]['ID'], 'setEnabled', { Value: false }).then(() => {
                  those.source.data[i]['Enalbed'] = false;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'delete':
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        const name = those.source.data.filter(item => item['Selected']).length <= 1 ? 'file' : 'files';
        modal.componentInstance.title = `Delete ${name}?`;
        modal.componentInstance.body = `Are you sure you want to delete ${name}? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            (async function loop() {
              for (let i = 0; i < those.source.data.length; i++) {
                if (those.source.data[i]['Selected']) {
                  const id = those.source.data[i]['ID'];
                  await new Promise(resolve =>
                    those.apiService.deleteMedia(id).then(() => {
                      those.source.data[i]['Enabled'] = false;
                      those.source.data = those.source.data.filter(item => item.ID !== id);
                      resolve();
                    })
                  );
                }
              }
              await new Promise(resolve => {
                those.source.local = true;
                those.source.refresh();
                resolve();
              });
            })();
            those.selected = undefined;
          },
          reason => {}
        );
        break;
      default:
        this.selected = this.source.data.filter(item => item['Selected']);
    }
  }

  setFilter(field, value?) {
    if (this.filter) {
      if (['Favorite'].indexOf(field) >= 0) {
        this.filter[field] = value ? String(value) : undefined;
      } else {
        this.filter[field] = value;
      }
    }
    this.apply();
  }

  apply() {
    console.log('apply');
    const filters = [];
    if (this.filter && this.filter.On) {
      for (const [k, v] of Object.entries(this.filter)) {
        console.log('k', k, 'v', v);
        if (k === 'On') {
        } else if (['ContentType'].indexOf(k) >= 0) {
          if (v['Value']) {
            filters.push({ field: k, search: v['Value'].join(';') });
          }
        } else if (k === 'Favorite') {
          if (v) {
            filters.push({ field: k, search: v });
          }
        } else {
          filters.push({ field: k, search: v });
        }
      }
    }
    const sort = [];
    if (this.sort) {
      const pair = this.sort.split(':');
      sort.push({ field: pair[0], direction: pair.length > 1 ? pair[1] : 'asc' });
    } else {
      sort.push({ field: 'ID', direction: 'desc' });
    }
    this.filtered = false;
    this.source.reset();
    setTimeout(() => {
      this.source.setFilter(filters, true, false);
      this.source.setSort(sort, true);
      this.filtered = true;
    }, 250);
  }

  onOpen(content, defaultFilter) {
    console.log('onOpen', defaultFilter);
    const those = this;
    those.toggle('upload');
    those.defaultFilter = defaultFilter;
    those.source.defaultFilter = defaultFilter;
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onImageUploadClick(event) {
    if (!event.target.closest('.dz-message-icon')) {
      event.stopPropagation();
    }
  }
  onImageUploadError($event) {
    if ($event && $event[1]) {
      if ($event[1]['ERROR']) {
        this.error('Create image', { status: 500, error: $event[1] });
      } else {
        this.error('Create image', { status: 500, error: { ERROR: $event[1] } });
      }
    } else {
      this.error('Create image', { status: 500, error: { ERROR: 'Something went wrong' } });
    }
  }
  onImageUploadComplete() {
    const those = this;
    setTimeout(() => {
      those.toggle('upload');
      those.source.refresh();
    }, 3000);
  }

  changed(event) {
    const those = this;
    const url = new URL(event.srcElement.value);
    if (url) {
      this.form.get('Name').setValue(url.pathname.substring(url.pathname.lastIndexOf('/') + 1));
      this.apiService
        .postFetch({ Url: url })
        .then(resp => {
          this.form.get('File').setValue(resp.File);
          if (resp.ContentType && resp.ContentType.indexOf('image/') >= 0) {
            resp.Thumbnail = this.absolutizeUrl.transform(resp.File);
          } else if (resp.ContentType === 'application/pdf') {
            resp.Thumbnail = this.absolutizeUrl.transform('/api/v1/resize?path=/admin/assets/images/file-pdf.svg');
          } else if (resp.ContentType === 'application/zip') {
            resp.Thumbnail = this.absolutizeUrl.transform('/api/v1/resize?path=/admin/assets/images/file-zip.svg');
          } else if (
            ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(
              resp.ContentType
            ) >= 0
          ) {
            resp.Thumbnail = this.absolutizeUrl.transform('/api/v1/resize?path=/admin/assets/images/file-doc.svg');
          } else if (
            ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(
              resp.ContentType
            ) >= 0
          ) {
            resp.Thumbnail = this.absolutizeUrl.transform('/api/v1/resize?path=/admin/assets/images/file-xls.svg');
          } else {
            resp.Thumbnail = this.absolutizeUrl.transform('/api/v1/resize?path=/admin/assets/images/file.svg');
          }
          those.preview = resp;
        })
        .catch(err => {
          those.error('Fetch image', err);
        });
    }
  }

  onInsert() {
    this.modal.close('Insert');
    this.onSelected.emit(this.selected);
  }

  onSubmit() {
    const those = this;
    this.apiService
      .getHttp()
      .get(this.apiService.getUrl() + '/fetch?path=' + this.form.get('File').value, {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe(resp => {
        const data = new FormData();
        data.append('Name', this.form.get('Name').value);
        data.append('Orig', this.form.get('Orig').value);
        data.append('Body', resp.body);
        those.apiService
          .postMedia(data)
          .then(resp2 => {
            those.success('Create media', resp2);
            those.selected = [resp2];
            if (those.source) {
              those.source.refresh();
            }
            those.toggle('upload');
          })
          .catch(err => {
            those.error('Create media', err);
          });
      });
    return;
  }

  setPerPage(value: number) {
    this.settings.pager.perPage = value;
    this.localStorageService.set('medias_per_page', JSON.stringify(value));
    this.refresh(true);
  }

  /*onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Orig', this.form.get('Name').value);
    data.append('Body', this.form.get('Body').value);
    // create
    this.apiService.postMedia(data).then(resp => {
      those.success('Create media', resp);
    }).catch(err => {
      those.error('Delete media', err);
    });
  }*/
}
