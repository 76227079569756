import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { credentials } from '../../credentials.data';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-price',
  styles: [
    `
      .btn-default:hover {
        text-decoration: underline;
      }
      .btn-default > svg {
        margin-right: 5px;
      }
      .price-thumbnail {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      .price-items {
        max-height: 225px;
        overflow-y: auto;
      }
      .price-item {
        margin: 0 0 8px 0;
        padding: 0 0 8px 0;
      }
      .price-item:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      .price-item > div {
        display: inline-flex;
        padding: 5px;
        width: 100%;
      }
      .price-item > div.selected {
        background-color: rgba(92, 34, 207, 0.2);
        border-radius: 5px;
      }
      .price-item .price-item-values {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow-x: scroll;
      }
      .price-item .price-item-value:not(:last-child):after {
        content: '/';
        padding: 0 5px;
      }
      .properties {
      }
      .properties > div {
        display: inline-block;
        position: relative;
      }
      .unset {
        background-color: white;
        cursor: pointer;
        position: absolute;
        right: -10px;
        top: -10px;
        border: 1px solid black;
        border-radius: 50%;
        line-height: 1;
        padding: 0 3px;
      }
      .selected {
        font-weight: 500;
      }
      .tabs-nav {
        display: flex;
        justify-content: space-between;
      }
      .tabs-nav > div:first-child {
        padding-left: 0;
      }
    `,
  ],
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <div style="display: flex;align-items: center;">
          <span *ngIf="price && !price.ID" [innerHTML]="'New' | translate"></span>
          <div *ngIf="price" class="properties">
            <div *ngFor="let property of properties; let i = index" class="button-group-dropdown">
              <button
                *ngIf="price.Rates[i]"
                type="button"
                class="btn dropdown-toggle color-primary-violet"
                style="padding: 0 5px;"
                data-toggle="dropdown">
                <span [innerHTML]="property.Title + ': ' + price.Rates[i].Value.Title"></span>
                <span class="caret"></span>
              </button>
              <ul
                *ngIf="prices"
                class="dropdown-menu dropdown-menu-right"
                style="max-height: 400px;overflow-y: scroll;">
                <li *ngFor="let rate of property.Rates; let j = index" (click)="go(i, rate.ID)">
                  {{ rate.Value.Title }}
                </li>
              </ul>
            </div>
            <div *ngIf="form" class="button-group-dropdown">
              <button
                type="button"
                class="btn btn-status dropdown-toggle"
                data-toggle="dropdown"
                [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'"
                style="vertical-align: initial;">
                <span [innerHTML]="form.get('Enabled').value ? 'active' : ('inactive' | translate)"></span>
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li (click)="form.get('Enabled').setValue(true)">Active</li>
                <li (click)="form.get('Enabled').setValue(false)">Inactive</li>
              </ul>
            </div>
          </div>
        </div>
        <div style="min-width: 85px">
          <div class="button-icon" [ngClass]="{ disabled: !hasPrev, locked: loading }" (click)="prev()">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 18L9 12L15 6"
                stroke="#292F46"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <div class="button-icon" [ngClass]="{ disabled: !hasNext, locked: loading }" (click)="next()">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 18L15 12L9 6"
                stroke="#292F46"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
            <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5" />
              <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5" />
            </svg>
          </div>
        </div>
      </div>
      <form
        *ngIf="form; else loadingScreen"
        class="needs-validation price-editor"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group price-thumbnail mb-0 pb-2" [ngClass]="{ disabled: !price || !price.ID }">
                  <div
                    *ngIf="
                      form.controls['Thumbnail'] &&
                      form.controls['Thumbnail'].value &&
                      !form.controls['Thumbnail'].touched
                    "
                    style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                    <a [href]="form.controls['Thumbnail'].value" target="_blank"
                      ><img
                        [src]="form.controls['Thumbnail'].value"
                        style="max-height: 250px;border-radius: 5px;margin-bottom: 5px;"
                    /></a>
                    <div class="unset" (click)="form.controls['Thumbnail'].value = ''">&times;</div>
                  </div>
                  <app-file-uploader
                    *ngIf="!form.controls['Thumbnail'].value || form.controls['Thumbnail'].touched"
                    class="flat"
                    [message]="'Drag to start uploading'"
                    (filesLoaded)="upload($event)"></app-file-uploader>
                </div>
              </div>
              <div class="col-md-6">
                <div *ngIf="filteredPrices" class="pb-2">
                  <div class="body-4-medium pb-2" translate>Variants</div>
                  <div style="position:relative;padding-bottom: 10px;">
                    <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z"
                          fill="#616573" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      class="form-control search"
                      style="padding-left: 32px;"
                      placeholder="{{ 'search' | translate }}"
                      (change)="search($event)"
                      (keyup.enter)="search($event, true)"
                      (keyup)="search($event)" />
                  </div>
                  <div class="price-items">
                    <div
                      *ngFor="let filteredPrice of filteredPrices; let i = index"
                      [attr.id]="'price-' + filteredPrice.ID"
                      class="price-item">
                      <div
                        [ngClass]="{ selected: filteredPrice.ID == price.ID }"
                        style="cursor: pointer"
                        (click)="select(filteredPrice.ID)">
                        <div
                          [style]="{
                            backgroundImage:
                              'url(' +
                              absolutizeUrl.transform(
                                '/api/v1/resize?path=' +
                                  (filteredPrice['Thumbnail']
                                    ? '/storage' + filteredPrice['Thumbnail']
                                    : '/assets/images/no-image.svg') +
                                  ')'
                              ),
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            cursor: 'pointer',
                            display: 'inline-block',
                            height: '40px',
                            width: '40px',
                            marginRight: '10px',
                            verticalAlign: 'middle'
                          }"></div>
                        <div class="price-item-values">
                          <div *ngFor="let rate of filteredPrice.Rates; let j = index" class="body-3 price-item-value">
                            {{ rate.Value.Title }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5 translate>Price</h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Price</label>
                  <!--input class="form-control" type="text" currencyMask
                         (click)="select($event)"
                         [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                         [placeholder]="'0.00'"-->
                  <input
                    class="form-control"
                    type="text"
                    currencyMask
                    (click)="$event.target['select']()"
                    [options]="{
                      prefix: prefix,
                      decimal: decimal,
                      thousands: thousands,
                      inputMode: 1,
                      nullable: true,
                      allowZero: true
                    }"
                    [placeholder]="'0.00'"
                    formControlName="BasePrice" />
                  <div *ngIf="form.controls['BasePrice'].errors" class="error">
                    <div
                      *ngIf="
                        form.controls['BasePrice'].errors['pattern'] &&
                        form.controls['BasePrice'].errors['pattern']['requiredPattern']
                      ">
                      Valid price value required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>MSRP</label>
                  <input
                    class="form-control"
                    type="text"
                    currencyMask
                    (click)="$event.target['select']()"
                    [options]="{
                      prefix: prefix,
                      decimal: decimal,
                      thousands: thousands,
                      inputMode: 1,
                      nullable: true,
                      allowZero: true
                    }"
                    [placeholder]="'0.00'"
                    formControlName="ManufacturerPrice" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="sale-price" class="mb-1" translate>SalePrice (including tax)</label>
                  <input
                    class="form-control"
                    id="sale-price"
                    type="text"
                    currencyMask
                    (click)="$event.target['select']()"
                    [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                    [placeholder]="'0.00'"
                    formControlName="SalePrice" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Cost per item</label>
                  <input
                    class="form-control"
                    type="text"
                    currencyMask
                    (click)="$event.target['select']()"
                    [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                    [placeholder]="'0.00'"
                    formControlName="ItemPrice" />
                </div>
              </div>
            </div>
            <div *ngIf="form.get('SalePrice').value" class="row">
              <div class="col">
                <h5 style="display: inline-block; padding-right: 5px" translate>Limited Time Sale Price</h5>
                <span
                  class="tooltip-question"
                  placement="top"
                  [ngbTooltip]="'Enter date if sale price is temporary' | translate">
                  <svg width="16px" height="16px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                  </svg>
                </span>
              </div>
            </div>
            <div *ngIf="form.get('SalePrice').value" class="row">
              <div *ngIf="form.get('Start')" class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Start Date</label>
                  <dp-date-picker
                    class="d-block"
                    mode="daytime"
                    theme="dp-material"
                    [config]="{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      firstDayOfWeek: 'mo',
                      showTwentyFourHours: true,
                      showMultipleYearsNavigation: true
                    }"
                    [formControl]="form.get('Start')"></dp-date-picker>
                </div>
              </div>
              <div *ngIf="form.get('End')" class="col">
                <div class="form-group">
                  <label class="mb-1" translate>End Date</label>
                  <dp-date-picker
                    class="d-block"
                    mode="daytime"
                    theme="dp-material"
                    [config]="{
                      format: 'YYYY-MM-DD HH:mm:ss',
                      firstDayOfWeek: 'mo',
                      showTwentyFourHours: true,
                      showMultipleYearsNavigation: true
                    }"
                    [formControl]="form.get('End')"></dp-date-picker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="padding: 5px 15px">
                <div class="tabs-nav">
                  <div
                    class="btn-sm btn-default"
                    [ngClass]="{ selected: tab == 'advanced-pricing' }"
                    style="display: flex;align-items: center;"
                    (click)="tab = tab === 'advanced-pricing' ? undefined : 'advanced-pricing'">
                    <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiDollarCircle"></use>
                    </svg>
                    <div translate>Advanced Pricing</div>
                  </div>
                  <div
                    class="btn-sm btn-default"
                    [ngClass]="{ selected: tab == 'inventory' }"
                    style="display: flex;align-items: center;"
                    (click)="tab = tab === 'inventory' ? undefined : 'inventory'">
                    <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiCube"></use>
                    </svg>
                    <div translate>Inventory & Shipping</div>
                  </div>
                  <div
                    class="btn-sm btn-default"
                    [ngClass]="{ selected: tab == 'description' }"
                    style="display: flex;align-items: center;"
                    (click)="tab = tab === 'description' ? undefined : 'description'">
                    <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiMessageSquareDetail"></use>
                    </svg>
                    <div translate>Description</div>
                  </div>
                  <div
                    class="btn-sm btn-default"
                    [ngClass]="{ sort: tab == 'sort' }"
                    style="display: flex;align-items: center;"
                    (click)="tab = tab === 'sort' ? undefined : 'sort'">
                    <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-sort"></use>
                    </svg>
                    <div translate>Sort</div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="tab == 'advanced-pricing'" class="row border-top pt-2">
              <div class="col">
                <div class="row pb-2">
                  <div class="col">
                    <label class="body-1-regular d-block">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        (change)="toggle('advancedPurchasability', $event.target['checked'])"
                        formControlName="AdvancedPurchasability" />
                      {{ 'Advanced Purchasability' | translate }}
                    </label>
                  </div>
                </div>
                <div *ngIf="form.get('AdvancedPurchasability').value">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="mb-1" translate>Minimum Order Quantity</label>
                        <input
                          type="number"
                          class="form-control"
                          (click)="$event.target['select']()"
                          (focusout)="format(form.get('MinQuantity'), 0)"
                          formControlName="MinQuantity" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label class="mb-1" translate>Maximum Order Quantity</label>
                        <input
                          type="number"
                          class="form-control"
                          (click)="$event.target['select']()"
                          (focusout)="format(form.get('MaxQuantity'), 0)"
                          formControlName="MaxQuantity" />
                      </div>
                    </div>
                  </div>
                  <div class="row" style="padding-bottom: 10px;">
                    <div class="col">
                      <span
                        class="link2"
                        style="padding-right: 5px"
                        (click)="hasPurchasableMultiply = !hasPurchasableMultiply"
                        translate
                        >Only purchasable in multiples</span
                      >
                      <i *ngIf="!hasPurchasableMultiply" class="fa fa-caret-right" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div *ngIf="hasPurchasableMultiply" class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="number"
                          step="1"
                          min="0"
                          (click)="$event.target['select']()"
                          formControlName="PurchasableMultiply" />
                      </div>
                    </div>
                  </div>
                  <div class="row border-bottom" style="margin-bottom: 10px"><div class="col"></div></div>
                </div>
                <div class="row pb-2">
                  <div class="col">
                    <label class="body-1-regular d-block">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        (change)="toggle('measurement', $event.target['checked'])"
                        formControlName="Measurement" />
                      {{ 'Show product price per unit' | translate }}
                    </label>
                  </div>
                </div>
                <div *ngIf="form.get('Measurement').value">
                  <div *ngIf="form.get('BaseMeasurement').value && form.get('TotalMeasurement').value" class="row pb-2">
                    <div class="col pl-0">
                      <div class="price-per-unit">
                        <span class="body-1-regular color-primary-text-gray pr-1" translate>Price per unit:</span
                        ><span>
                          {{ currency
                          }}{{
                            (
                              (form.get('BasePrice').value * form.get('BaseMeasurement').value) /
                              form.get('TotalMeasurement').value
                            ).toFixed(2)
                          }}/{{ form.get('BaseMeasurement').value }}{{ form.get('BaseMeasurementUnit').value }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row border-bottom" style="padding: 5px 0;margin-bottom: 10px">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col">
                          <div class="form-group" style="margin-bottom: 0">
                            <label class="mb-1" translate>Total product measurement</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group">
                            <input
                              class="form-control"
                              type="number"
                              step="1"
                              min="0"
                              (click)="$event.target['select']()"
                              (focusout)="format(form.get('TotalMeasurement'), 0)"
                              formControlName="TotalMeasurement" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-select
                              [options]="weightMeasurementUnits"
                              formGroupName="TotalMeasurementUnit"></app-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col">
                          <div class="form-group" style="margin-bottom: 0">
                            <label class="mb-1" translate>Base measurement</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group">
                            <input
                              class="form-control"
                              type="number"
                              step="1"
                              min="0"
                              (click)="$event.target['select']()"
                              (focusout)="format(form.get('BaseMeasurement'), 0)"
                              formControlName="BaseMeasurement" />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <app-select
                              [options]="weightMeasurementUnits"
                              formControlName="BaseMeasurementUnit"></app-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col">
                    <label class="body-1-regular d-block">
                      <input class="checkbox_animated" type="checkbox" formControlName="Discount" />
                      {{ 'Show bulk pricing' | translate }}
                    </label>
                  </div>
                </div>
                <div *ngIf="form.get('Discount').value">
                  <div class="row" style="padding: 5px 0;">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="mb-1" translate>Discount Type</label>
                        <app-select [options]="discounts" formControlName="DiscountType"></app-select>
                      </div>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col" style="padding: 0">
                      <table [formGroup]="form" class="table" style="height: 100%; margin-bottom: 0">
                        <thead>
                          <tr>
                            <th translate>Min Quantity</th>
                            <th translate>
                              <span *ngIf="form.get('DiscountType').value == 'percent'" translate>% Discount</span>
                              <span
                                *ngIf="form.get('DiscountType').value == 'value'"
                                [innerHtml]="currency + ' ' + ('Off/Unit' | translate)"></span>
                            </th>
                            <th translate>Unit Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody [formArrayName]="'Tiers'">
                          <tr *ngFor="let tierControl of form.get('Tiers')['controls']; index as i" [formGroupName]="i">
                            <td>
                              <app-cell-input [formControlName]="'MinQuantity'"></app-cell-input>
                            </td>
                            <td>
                              <app-cell-input
                                (change)="changeDiscount($event, tierControl)"
                                [formControlName]="'Discount'"></app-cell-input>
                            </td>
                            <td>
                              <app-cell-input
                                (change)="changeValue($event, tierControl)"
                                [formControlName]="'Value'"></app-cell-input>
                            </td>
                            <td style="text-align: center;vertical-align: middle;">
                              <span
                                class="btn-sm btn-default facon-minus"
                                style="font-size: 16px;"
                                title="delete"
                                (click)="deleteTier(i)"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span
                        class="btn-sm btn-default facon-plus"
                        style="padding: 0 5px;text-transform: none;"
                        title="add"
                        (click)="addTier()"
                        translate>
                        Add Tier</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="tab == 'inventory'" class="row border-top pt-3">
              <div class="col">
                <div class="row" style="padding-bottom: 10px;">
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 0">
                      <label class="mb-1" translate>Format</label>
                      <app-select [options]="patterns" (ngModelChange)="parse()" formControlName="Pattern"></app-select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 0">
                      <label class="mb-1" translate>Measurement</label>
                      <div class="form-row">
                        <div class="col-8">
                          <input
                            type="text"
                            placeholder="{{ 'value' | translate }}"
                            (focusout)="parse()"
                            class="form-control"
                            formControlName="Dimensions" />
                        </div>
                        <div class="col-4">
                          <app-select [options]="lengthMeasurementUnits" formControlName="DimensionUnit"></app-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="mb-1" for="width" translate>Width</label>
                      <input
                        class="form-control"
                        id="width"
                        type="number"
                        min="0"
                        step="0.1"
                        (click)="$event.target['select']()"
                        (focusin)="trim(form.get('Width'))"
                        (focusout)="format(form.get('Width'), 1) && print()"
                        Currency="true"
                        formControlName="Width" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="mb-1" for="height" translate>Height</label>
                      <input
                        class="form-control"
                        id="height"
                        type="number"
                        min="0"
                        step="0.1"
                        (click)="$event.target['select']()"
                        (focusin)="trim(form.get('Height'))"
                        (focusout)="format(form.get('Height'), 1) && print()"
                        Currency="true"
                        formControlName="Height" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="mb-1" for="depth" translate>Depth</label>
                      <input
                        class="form-control"
                        id="depth"
                        type="number"
                        min="0"
                        step="0.1"
                        (click)="$event.target['select']()"
                        (focusin)="trim(form.get('Depth'))"
                        (focusout)="format(form.get('Depth'), 1) && print()"
                        Currency="true"
                        formControlName="Depth" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="volume" translate>Volume</label>
                      <input
                        type="number"
                        min="0.000"
                        step="0.001"
                        class="form-control mb-2"
                        id="volume"
                        (click)="$event.target['select']()"
                        (focusin)="trim(form.get('Volume'))"
                        (focusout)="format(form.get('Volume'), 2)"
                        placeholder="{{ 'm3' | translate }}"
                        Currency="true"
                        formControlName="Volume" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 0">
                      <label for="weight" translate>Weight</label>
                      <div class="form-row">
                        <div class="col-8">
                          <input
                            type="number"
                            min="0.00"
                            step="0.01"
                            class="form-control mb-2"
                            id="weight"
                            (click)="$event.target['select']()"
                            (focusin)="trim(form.get('Weight'))"
                            (focusout)="format(form.get('Weight'), 2)"
                            placeholder="{{ 'kg' | translate }}"
                            Currency="true"
                            formControlName="Weight" />
                        </div>
                        <div class="col-4">
                          <app-select [options]="weightMeasurementUnits" formControlName="WeightUnit"></app-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="packages" translate>Packaging items</label>
                      <input
                        type="number"
                        min="1"
                        step="1"
                        class="form-control mb-2"
                        id="packages"
                        (click)="$event.target['select']()"
                        (focusin)="trim(form.get('Packages'))"
                        (focusout)="format(form.get('Packages'))"
                        formControlName="Packages" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="sku" class="mb-1">Sku</label>
                      <input
                        class="form-control"
                        id="sku"
                        type="text"
                        (click)="$event.target['select']()"
                        formControlName="Sku" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="barcode" class="mb-1">Barcode</label>
                      <input
                        class="form-control"
                        id="barcode"
                        type="text"
                        (click)="$event.target['select']()"
                        formControlName="Barcode" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="availability" class="mb-1" translate>Availability</label>
                      <app-select [options]="availabilities" formControlName="Availability"></app-select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="stock" class="mb-1">Stock</label>
                      <input
                        class="form-control"
                        id="stock"
                        type="number"
                        min="0"
                        (click)="$event.target['select']()"
                        formControlName="Stock" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label style="padding-right: 5px">
                      <input class="checkbox_animated" type="checkbox" formControlName="TrackQuantity" />
                      {{ 'Track Quantity' | translate }}
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label style="padding-right: 5px">
                      <input class="checkbox_animated" type="checkbox" formControlName="Overselling" />
                      {{ 'Continue selling when out of stock' | translate }}
                    </label>
                    <span
                      class="tooltip-question"
                      placement="top"
                      [ngbTooltip]="'ability to sell more than in stock (according to inventory)' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                  </div>
                </div>
                <div *ngIf="form.get('TrackQuantity').value">
                  <div *ngIf="form.get('ID').value" class="row" style="padding-bottom: 10px">
                    <div class="col">
                      <div *ngIf="price.HasTransfers">
                        <app-transfers
                          [productId]="productId"
                          [variationId]="variationId"
                          [priceId]="price.ID"
                          #transfers></app-transfers>
                        <span
                          class="btn-sm btn-default"
                          style="padding-left: 0"
                          data-toggle="modal"
                          data-original-title="test"
                          data-target="#exampleModal"
                          (click)="transfers.onOpen(transfers.content)"
                          translate>
                          <svg
                            width="20"
                            height="13"
                            viewBox="0 0 20 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.90702 0.5C6.12134 0.5 2.68828 2.55923 0.155035 5.90397C-0.0516783 6.178 -0.0516783 6.56083 0.155035 6.83486C2.68828 10.1836 6.12134 12.2429 9.90702 12.2429C13.6927 12.2429 17.1258 10.1836 19.659 6.83889C19.8657 6.56486 19.8657 6.18203 19.659 5.908C17.1258 2.55923 13.6927 0.5 9.90702 0.5ZM10.1786 10.506C7.66561 10.6632 5.59037 8.60394 5.74845 6.10143C5.87815 4.03817 7.56022 2.3658 9.63546 2.23685C12.1484 2.07968 14.2237 4.13892 14.0656 6.64143C13.9318 8.70066 12.2498 10.373 10.1786 10.506ZM10.0529 8.59588C8.69917 8.68051 7.58049 7.57231 7.66966 6.22636C7.73856 5.11413 8.64648 4.21548 9.76516 4.14295C11.1189 4.05832 12.2376 5.16652 12.1484 6.51247C12.0755 7.62873 11.1676 8.52738 10.0529 8.59588Z"
                              fill="#5C22CF" />
                          </svg>
                          {{ 'View inventory history' | translate }}
                        </span>
                      </div>
                    </div>
                    <div class="col" style="text-align: right">
                      <app-edit-transfer
                        [productId]="productId"
                        [variationId]="variationId"
                        [priceId]="price.ID"
                        [stock]="form.get('Stock').value"
                        (onSaved)="refresh(price.ID)"
                        #transferEditor>
                        <span
                          class="btn-sm btn-default facon-plus"
                          (click)="transferEditor.onCreate(transferEditor.content)"
                          translate>
                          Add Transfer</span
                        >
                      </app-edit-transfer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="tab == 'description'" class="row border-top pt-3">
              <div class="col">
                <div class="form-group">
                  <app-tinymce
                    [options]="tinymceConfig"
                    (focus)="$event.target?.iframeElement?.classList?.add('focused')"
                    (blur)="$event.target?.iframeElement?.classList?.remove('focused')"
                    formControlName="Description"></app-tinymce>
                </div>
              </div>
            </div>
            <div *ngIf="tab == 'sort'" class="row border-top pt-3">
              <div class="col">
                <div>
                  <div class="form-group">
                    <label for="sort" class="mb-1">Sort</label>
                    <input
                      class="form-control"
                      id="sort"
                      type="number"
                      min="0"
                      (click)="$event.target['select']()"
                      formControlName="Sort" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: normal;align-self: flex-end;">
          <button
            id="save"
            type="button"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            (click)="onSubmit()">
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span [innerHtml]="submit | translate"></span>
          </button>
          <button
            id="save"
            type="button"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid"
            (click)="onSubmit(true)">
            <svg>
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
            </svg>
            <span [innerHtml]="submit + ' & Close' | translate"></span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
          <button
            *ngIf="price.ID"
            class="btn btn-danger"
            style="margin-left: auto"
            type="button"
            data-dismiss="modal"
            (click)="onDelete(price.ID)"
            translate>
            Delete
          </button>
        </div>
      </form>
      <ng-template #loadingScreen>
        <div class="modal-body">
          <div style="text-align: center;">
            <img src="/assets/images/loading.svg" style="width: 100px;" alt="Loading..." />
          </div>
        </div>
      </ng-template>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class PriceComponent implements OnInit, AfterViewInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('properties') properties;
  @Input('optionId') optionId;
  @Input('decimal') decimal = ',';
  @Input('prefix') prefix = '';
  @Input('thousands') thousands = '.';
  @Input('dimensionUnit') dimensionUnit = 'cm';
  @Input('weightUnit') weightUnit = 'kg';
  @Input('pattern') pattern = 'whd';
  @Input('tinymceConfig') tinymceConfig;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  property;
  price;
  prices = [];
  filteredPrices = [];
  values;
  modal;
  closeResult;
  form;
  tab = '';
  hasPurchasableMultiply = false;
  tiers = {};
  currency = credentials.currency;
  term;
  hasNext = false;
  hasPrev = false;
  submit = 'Save';
  loading = false;

  patterns = credentials.patterns;
  availabilities = credentials.availabilities;
  lengthMeasurementUnits = credentials.lengthMeasurementUnits;
  weightMeasurementUnits = credentials.weightMeasurementUnits;
  discounts = [
    {
      Name: 'percent',
      Label: '% Discount',
    },
    {
      Name: 'value',
      Label: 'Off/Unit',
    },
  ];

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.discounts && this.discounts.length > 1) {
      this.discounts[1].Label = this.currency + ' ' + this.discounts[1].Label;
    }
  }

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  preload(): Promise<any> {
    const those = this;
    return Promise.all([this.apiService.getPrices({ product_id: those.productId, variation_id: those.variationId })]);
  }

  onCreate(content) {
    const those = this;
    those
      .preload()
      .then(resp => {
        console.log('resp', resp);
        this.form = this.formBuilder.group({
          ID: [''],
          Enabled: [true],
          ProductId: [this.productId],
          VariationId: [this.variationId],
          Description: [''],
          Thumbnail: [''],
          BasePrice: [0, Validators.required],
          ManufacturerPrice: [0],
          ItemPrice: [0],
          SalePrice: [0],
          Pattern: [''],
          Dimensions: [''],
          DimensionUnit: [this.dimensionUnit],
          Width: [0],
          Height: [0],
          Depth: [0],
          Volume: [0.0],
          Weight: [0.0],
          WeightUnit: [this.weightUnit],
          Packages: [1],
          Sku: [''],
          Barcode: [''],
          Availability: ['available'],
          Stock: 0,
        });
        //
        this.form.addControl('Rates', new FormArray([]));
        const arr = <FormArray>this.form.get('Rates');
        arr.clear();
        for (let i = 0; i < this.properties.length; i++) {
          arr.push(
            new FormGroup({
              ID: new FormControl(0),
            })
          );
        }
        this.format(this.form.get('BasePrice'));
        this.format(this.form.get('SalePrice'));
      })
      .catch(err => {
        those.error('Load price', err);
      });
    //
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onEdit(content, id, extra?: any) {
    const those = this;
    this.refresh(id);
    //
    this.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete price?';
    modal.componentInstance.body = `Are you sure you want to delete price? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deletePrice(id)
          .then(resp => {
            those.success('Delete price', resp);
            those.modal.close('Deleted');
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete price', err);
          });
      },
      reason => {}
    );
  }

  createForm(price) {
    const form = this.formBuilder.group({
      ID: [price.ID],
      Enabled: [price.Enabled],
      ProductId: [price.ProductId],
      VariationId: [price.VariationId],
      Description: [price.Description ? price.Description : ''],
      Thumbnail: [price.Thumbnail ? this.absolutizeUrl.transform('/storage/' + price.Thumbnail) : ''],
      BasePrice: [price.BasePrice, Validators.required],
      ManufacturerPrice: [price.ManufacturerPrice],
      ItemPrice: [price.ItemPrice],
      OnSale: [price.OnSale ? true : false],
      SalePrice: [price.SalePrice ? price.SalePrice : null],
      Start: [
        price.Start && price.Start.indexOf('0001-01-01') === -1
          ? moment(price.Start).format('YYYY-MM-DD HH:mm:ss')
          : null,
      ],
      End: [price.End && price.End.indexOf('0001-01-01') ? moment(price.End).format('YYYY-MM-DD HH:mm:ss') : null],
      AdvancedPurchasability: [price.AdvancedPurchasability ? true : false],
      MinQuantity: [price.MinQuantity ? price.MinQuantity : 0],
      MaxQuantity: [price.MaxQuantity ? price.MaxQuantity : 0],
      PurchasableMultiply: [price.PurchasableMultiply ? price.PurchasableMultiply : 0],
      Measurement: [price.Measurement ? true : false],
      BaseMeasurement: [price.BaseMeasurement ? price.BaseMeasurement : 0],
      BaseMeasurementUnit: [price.BaseMeasurementUnit ? price.BaseMeasurementUnit : 'kg'],
      TotalMeasurement: [price.TotalMeasurement ? price.TotalMeasurement : 0],
      TotalMeasurementUnit: [price.TotalMeasurementUnit ? price.TotalMeasurementUnit : 'kg'],
      AdvancedPricing: [price.DiscountType ? true : false],
      Discount: [price.Discount ? true : false],
      DiscountType: [price.DiscountType ? price.DiscountType : 'percent'],
      Pattern: [price.Pattern ? price.Pattern : this.pattern],
      Dimensions: [price.Dimensions ? price.Dimensions : ''],
      DimensionUnit: [price.DimensionUnit ? price.DimensionUnit : this.dimensionUnit],
      Width: [price.Width ? price.Width : 0],
      Height: [price.Height ? price.Height : 0],
      Depth: [price.Depth ? price.Depth : 0],
      Volume: [price.Volume ? price.Volume : 0.0],
      Weight: [price.Weight ? price.Weight : 0.0],
      WeightUnit: [price.WeightUnit ? price.WeightUnit : this.weightUnit],
      Packages: [price.Packages ? price.Packages : ''],
      Sku: [price.Sku ? price.Sku : ''],
      Barcode: [price.Barcode ? price.Barcode : ''],
      Availability: [price.Availability ? price.Availability : 'available'],
      Stock: [price.Stock ? price.Stock : 0],
      TrackQuantity: [price.TrackQuantity ? price.TrackQuantity : false],
      Overselling: [price.Overselling ? price.Overselling : false],
      Sort: [price.Sort ? price.Sort : 0],
    });

    form.addControl(
      'Rates',
      this.formBuilder.array(
        (price.Rates || []).map(rate => {
          return this.formBuilder.group({
            ID: [rate.ID],
          });
        })
      )
    );

    form.addControl(
      'Tiers',
      this.formBuilder.array(
        (price.Tiers || []).map(tier => {
          return this.formBuilder.group({
            MinQuantity: [tier.MinQuantity, [Validators.required]],
            Discount: [tier.Discount, [Validators.required]],
          });
        })
      )
    );

    return form;
  }

  getValues(propertyId) {
    const rates = [];
    for (let i = 0; i < this.prices.length; i++) {
      const rate = this.prices[i].Rates.find(item => item.PropertyId === propertyId);
      if (rate && rates.findIndex(item => item.ID === rate.Value.ID) < 0) {
        rates.push(rate.Value);
      }
    }
    return rates;
  }

  search(event, prevent = false) {
    const those = this;
    const value = event.target['value'].toLowerCase();
    this.filteredPrices = this.prices.filter(
      item =>
        item.Rates.filter(rate => !value || (rate.Value && rate.Value.Title.toLowerCase().indexOf(value) >= 0)).length >
        0
    );
    if (prevent) {
      const index = this.filteredPrices.findIndex(item => item.ID === those.price.ID);
      if (this.filteredPrices.length > 0 && index < 0) {
        this.select(this.filteredPrices[0].ID);
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }

  go(index, rateId) {
    const those = this;
    const rates = [...this.price.Rates];
    rates[index] = { ...rates[index], ...this.properties[index].Rates.find(item => item.ID === rateId) };
    this.apiService
      .getPrices({ rids: rates.map(item => item.ID).join(',') })
      .then(prices => {
        if (!prices || prices.length === 0) {
          const modal = this.modalService.open(ConfirmComponent, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md',
            centered: true,
          });
          modal.componentInstance.title = 'Do you want to create new price?';
          modal.componentInstance.body = 'Are you sure you want to create new price? Existing will be deleted';
          modal.componentInstance.confirm = 'Create';
          modal.result.then(
            result => {
              those.price.Rates = rates;
              const price = { ...those.price, ...{ ID: undefined, Enabled: true, Rates: rates, Overselling: true } };
              those.price = price;
              those.form = those.createForm(price);
              those.hasPurchasableMultiply = false;
              those.submit = 'Create';
            },
            reason => {}
          );
        } else {
          those.price.Rates = rates;
          those.select(prices[prices.length - 1].ID);
          those.submit = 'Save';
        }
      })
      .catch(err => {
        those.error('Load price', err);
      });
  }

  next() {
    const those = this;
    const index = this.filteredPrices.findIndex(item => item.ID === those.price.ID);
    if (index < this.filteredPrices.length - 1) {
      this.select(this.filteredPrices[index + 1].ID);
    }
  }

  prev() {
    const those = this;
    const index = this.filteredPrices.findIndex(item => item.ID === those.price.ID);
    if (index > 0) {
      this.select(this.filteredPrices[index - 1].ID);
    }
  }

  select(id) {
    console.log('select', id);
    const those = this;
    this.loading = true;
    this.apiService
      .getPrice(id)
      .then(price => {
        console.log('price', price);
        those.form = those.createForm(price);
        those.hasPurchasableMultiply = price.PurchasableMultiply ? true : false;
        those.price = price;
        const index = those.filteredPrices.findIndex(item => item.ID === those.price.ID);
        those.hasPrev = index > 0;
        those.hasNext = index < those.filteredPrices.length - 1;
        const element = document.querySelector('#price-' + id);
        if (element) {
          document.querySelector('.price-items').scrollTop = element['offsetTop'] - 150;
        }
        those.loading = false;
      })
      .catch(err => {
        those.error('Load price', err);
      });
  }

  refresh(id) {
    const those = this;
    those
      .preload()
      .then(resp => {
        those.prices = resp[0];
        those.filteredPrices = resp[0];
        those.apiService
          .getPrice(id)
          .then(price => {
            those.form = those.createForm(price);
            those.hasPurchasableMultiply = price.PurchasableMultiply ? true : false;
            those.price = price;
            const index = those.filteredPrices.findIndex(item => item.ID === those.price.ID);
            those.hasPrev = index > 0;
            those.hasNext = index < those.prices.length - 1;
            setTimeout(() => {
              const element = document.querySelector('#price-' + id);
              if (element) {
                document.querySelector('.price-items').scrollTop = element['offsetTop'] - 150;
              }
            }, 100);
          })
          .catch(err => {
            those.error('Load price', err);
            console.log('err', err);
          });
      })
      .catch(err => {
        those.error('Load prices', err);
        console.log('err', err);
      });
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  getPattern(name: string) {
    const pattern = this.patterns.find(item => item.Name === name);
    if (pattern) {
      return pattern;
    }
    return this.patterns[0];
  }

  setPattern(name: string) {
    this.form.get('Pattern').setValue(name);
    this.parse();
  }

  getAvailability(name: string) {
    const pattern = this.availabilities.find(item => item.Name === name);
    if (pattern) {
      return pattern;
    }
    return this.availabilities[0];
  }

  trim(control) {
    if (control && control.value !== undefined) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control, decimal?) {
    const value = Number.parseFloat(control.value ? control.value : 0)
      .toFixed(decimal)
      .replace(/([0-9]+\.[0-9]+?)0*$/, '$1');
    if (value) {
      control.setValue(value);
    }
    return true;
  }

  parse() {
    console.log('parse');
    const pattern = this.form.get('Pattern').value;
    const value = this.form.get('Dimensions').value;
    if (value) {
      let res;
      switch (pattern) {
        case 'whd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[3]);
            this.form.get('Depth').setValue(+res[5]);
          }
          break;
        case 'wdh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1]);
            this.form.get('Height').setValue(+res[5]);
            this.form.get('Depth').setValue(+res[3]);
          }
          break;
        case 'hwd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3]);
            this.form.get('Height').setValue(+res[1]);
            this.form.get('Depth').setValue(+res[5]);
          }
          break;
        case 'hdw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3]);
            this.form.get('Height').setValue(+res[5]);
            this.form.get('Depth').setValue(+res[1]);
          }
          break;
        case 'dwh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5]);
            this.form.get('Height').setValue(+res[1]);
            this.form.get('Depth').setValue(+res[3]);
          }
          break;
        case 'dhw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5]);
            this.form.get('Height').setValue(+res[3]);
            this.form.get('Depth').setValue(+res[1]);
          }
          break;
        default:
          break;
      }
    }
  }

  print() {
    const pattern = this.form.get('Pattern').value;
    switch (pattern) {
      case 'whd':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Depth').value)
          );
        break;
      case 'wdh':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Height').value)
          );
        break;
      case 'hwd':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Depth').value)
          );
        break;
      case 'hdw':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Width').value)
          );
        break;
      case 'dwh':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Width').value) +
              ' x ' +
              Number(this.form.get('Height').value)
          );
        break;
      case 'dhw':
        this.form
          .get('Dimensions')
          .setValue(
            Number(this.form.get('Depth').value) +
              ' x ' +
              Number(this.form.get('Height').value) +
              ' x ' +
              Number(this.form.get('Width').value)
          );
        break;
      default:
        break;
    }
    return true;
  }

  toggle(name, value) {
    switch (name) {
      case 'sale':
        if (value) {
          if (typeof this.form.get('Start').value === 'string') {
            this.form.get('Start').setValue(moment(this.form.get('Start').value).format('YYYY-MM-DD HH:mm:ss'));
          } else {
            this.form.get('Start').setValue(moment().format('YYYY-MM-DD 00:00:00'));
          }
          if (typeof this.form.get('End').value === 'string') {
            this.form.get('End').setValue(moment(this.form.get('End').value).format('YYYY-MM-DD HH:mm:ss'));
          } else {
            this.form.get('End').setValue(moment().format('YYYY-MM-DD 23:59:59'));
          }
        }
        break;
    }
  }

  addTier() {
    const tiers = this.form.get('Tiers') as FormArray;
    const minQuantity = tiers.length > 0 ? Number(tiers.at(tiers.length - 1).get('MinQuantity').value) + 1 : 2;
    const discount = tiers.length > 0 ? Number(tiers.at(tiers.length - 1).get('Discount').value) + 1 : 0;
    const value =
      this.form.get('DiscountType').value === 'percent'
        ? this.form.get('BasePrice').value * (1 - discount / 100)
        : this.form.get('BasePrice').value - discount;
    tiers.push(
      this.formBuilder.group({
        MinQuantity: [minQuantity, [Validators.required]],
        Discount: [discount, [Validators.required]],
        Value: [value.toFixed(2)],
      })
    );
  }

  deleteTier(index) {
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete tier?';
    modal.componentInstance.body = `Are you sure you want to delete tier? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        const tiers = this.form.get('Tiers') as FormArray;
        tiers.removeAt(index);
        tiers.markAsDirty();
      },
      reason => {}
    );
  }

  changeDiscount(event, group) {
    const those = this;
    if (event && event.target && event.target.value) {
      setTimeout(() => {
        if (those.form.get('DiscountType').value === 'percent') {
          group.get('Value').setValue((those.form.get('BasePrice').value * (1 - event.target.value / 100)).toFixed(2));
        } else {
          group.get('Value').setValue((those.form.get('BasePrice').value - event.target.value).toFixed(2));
        }
        group.get('Value').updateValueAndValidity();
      }, 100);
    }
  }

  changeValue(event, group) {
    const those = this;
    if (event && event.target && event.target.value) {
      setTimeout(() => {
        if (those.form.get('DiscountType').value === 'percent') {
          group
            .get('Discount')
            .setValue(((1 - event.target.value / those.form.get('BasePrice').value) * 100).toFixed(0));
        } else {
          group.get('Discount').setValue((those.form.get('BasePrice').value - event.target.value).toFixed(0));
        }
        group.get('Discount').updateValueAndValidity();
      }, 100);
    }
  }

  onSubmit(close = false) {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Description', this.form.get('Description').value);
    data.append(
      'Rates',
      this.form
        .get('Rates')
        .value.map(item => item['ID'])
        .join(',')
    );
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('BasePrice', this.form.get('BasePrice').value);
    data.append('ManufacturerPrice', this.form.get('ManufacturerPrice').value);
    data.append('ItemPrice', this.form.get('ItemPrice').value);
    data.append('OnSale', this.form.get('OnSale').value);
    data.append('SalePrice', this.form.get('SalePrice').value);
    if (this.form.get('Start').value) {
      data.append('Start', moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    if (this.form.get('End').value) {
      data.append('End', moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ'));
    }
    data.append('AdvancedPurchasability', this.form.get('AdvancedPurchasability').value);
    data.append('MinQuantity', this.form.get('MinQuantity').value);
    data.append('MaxQuantity', this.form.get('MaxQuantity').value);
    data.append('PurchasableMultiply', this.form.get('PurchasableMultiply').value);
    data.append('Measurement', this.form.get('Measurement').value);
    data.append('BaseMeasurement', this.form.get('BaseMeasurement').value);
    data.append('BaseMeasurementUnit', this.form.get('BaseMeasurementUnit').value);
    data.append('TotalMeasurement', this.form.get('TotalMeasurement').value);
    data.append('TotalMeasurementUnit', this.form.get('TotalMeasurementUnit').value);
    data.append('Discount', this.form.get('Discount').value);
    data.append('DiscountType', this.form.get('DiscountType').value);
    data.append(
      'Tiers',
      JSON.stringify(
        this.form
          .get('Tiers')
          .getRawValue()
          .map(tier => {
            tier.MinQuantity = Number(tier.MinQuantity);
            tier.Discount = Number(tier.Discount);
            return tier;
          })
      )
    );
    data.append('Pattern', this.form.get('Pattern').value);
    data.append('Dimensions', this.form.get('Dimensions').value);
    data.append('DimensionUnit', this.form.get('DimensionUnit').value);
    data.append('Width', this.form.get('Width').value);
    data.append('Depth', this.form.get('Depth').value);
    data.append('Height', this.form.get('Height').value);
    data.append('Volume', this.form.get('Volume').value);
    data.append('Weight', this.form.get('Weight').value);
    data.append('WeightUnit', this.form.get('WeightUnit').value);
    data.append('Packages', this.form.get('Packages').value);
    data.append('Sku', this.form.get('Sku').value);
    data.append('Barcode', this.form.get('Barcode').value);
    data.append('Availability', this.form.get('Availability').value);
    data.append('Stock', this.form.get('Stock').value);
    data.append('TrackQuantity', this.form.get('TrackQuantity').value);
    data.append('Overselling', this.form.get('Overselling').value);
    data.append('Sort', this.form.get('Sort').value);
    if (!this.form.get('ID').value) {
      // create
      data.append('ProductId', this.productId);
      data.append('VariationId', this.variationId);
      this.apiService
        .postPrice(data)
        .then(price => {
          those.refresh(price.ID);
          those.submit = 'Save';
          those.success('Create price', price);
          those.select(price.ID);
          if (close) {
            those.modal.close('Saved');
            those.onSaved.emit();
          }
        })
        .catch(err => {
          those.error('Create price', err);
        });
    } else {
      // update
      this.apiService
        .putPrice(those.form.get('ID').value, data)
        .then(resp => {
          those.success('Update price', resp);
          if (close) {
            those.modal.close('Saved');
            those.onSaved.emit();
          }
        })
        .catch(err => {
          those.error('Update price', err);
        });
    }
  }
}
