import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class PublisherComponent implements OnInit {
  settings;
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder, private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, private translate: TranslateService) {

  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getPublisherSettings().then(resp => {
      those.settings = resp;
      those.form = this.formBuilder.group({
        Enabled: [resp.Enabled ? true : false],
        Bin: [resp['Bin'] ? resp['Bin'] : '', Validators.required],
        Name: [resp.Name ? resp.Name : ''],
        ApiToken: [resp.ApiToken ? resp.ApiToken : ''],
        AccountId: [resp.AccountId ? resp.AccountId : ''],
        WorkersDev: [resp.WorkersDev ? true : false],
        Route: [resp.Route ? resp.Route : ''],
        ZoneId: [resp.ZoneId ? resp.ZoneId : ''],
      });
    }).catch(err => {
      those.error('Update publisher settings', err);
    });
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Bin', this.form.get('Bin').value);
    data.append('ApiToken', this.form.get('ApiToken').value);
    data.append('AccountId', this.form.get('AccountId').value);
    data.append('WorkersDev', this.form.get('WorkersDev').value);
    data.append('Route', this.form.get('Route').value);
    data.append('ZoneId', this.form.get('ZoneId').value);
    this.apiService.putPublisherSettings(data).then(resp => {
      those.success('Update publisher settings', resp);
    }).catch(err => {
      those.error('Update publisher settings', err);
    });
  }
}
