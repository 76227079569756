import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {credentials} from './credentials.data';
import {Router} from '@angular/router';
import {catchError, map, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {throwError} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler){
    const those = this;
    const address = String(window.location.href).split('/')[2].split(':');
    const update = {withCredentials: true};
    if (address[0] === 'localhost' || +address[1] === 4200 || +address[1] === 4201) {
      update['setHeaders'] = {Authorization: credentials.authorization};
    }
    req = req.clone(update);
    return next.handle(req).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        //
      }
    }), catchError((err: any) => {
      if (err instanceof HttpErrorResponse && err.status === 403) {
        try {
          if (req.url.match(/no\-interruption=true/)) {
            return throwError(err);
          }else{
            const params = {};
            if (!window.location.pathname.match(/^\/auth\/login/)) {
              params['ref'] = window.location.pathname;
            }
            this.router.navigate([ '/auth/login' ], {queryParams: params});
          }
          return of(err as any);
        } catch (e) {
          console.log('e', e);
        }
      }
      return throwError(err);
    }));
  }
}
