import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ITreeOptions, TreeComponent } from 'angular-tree-component';
import { SubjectService } from '../../../shared/subject.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class CategoryComponent implements OnInit, OnDestroy {
  @ViewChild('categoriesTree', { read: TreeComponent }) categoriesTree: TreeComponent;
  paramsSubscription: Subscription;
  queryParamsSubscription: Subscription;
  loading = false;
  parentId;
  id;
  category;
  form;
  config;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };
  categories;
  lock1 = false;
  //thumbnail;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params.parent_id || params.pid) {
        those.parentId = params.parent_id || params.pid;
      }
    });
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params.id) {
        if (params.id === 'new') {
          those.form = those.formBuilder.group({
            ID: [''],
            Enabled: [true],
            Name: ['', Validators.required],
            Title: ['', Validators.required],
            Description: [''],
            //Thumbnail: [''],
            Media: [''],
            Location: [''],
            Content: [''],
            Show: [false],
            Categories: [String(those.parentId ? those.parentId : 0)],
          });
          //
          this.apiService
            .getCategories(0, 999, false)
            .then(result => {
              // Categories
              those.categories = this.filterCategories(JSON.parse(JSON.stringify(result)));
              those.categoriesTree.treeModel.update();
              setTimeout(() => {
                those.setSelectedNodes(this.categoriesTree, [String(those.parentId)]);
              }, 100);
            })
            .catch(err => {
              those.error('Get categories', err);
            });
        } else {
          those.loading = true;
          those.id = params.id;
          those.apiService
            .getCategory(those.id)
            .then(category => {
              those.loading = false;
              those.category = category;
              //this.thumbnail = category.Thumbnail.split(',');
              those.form = those.formBuilder.group({
                ID: [category.ID],
                Enabled: [category.Enabled ? true : false],
                Name: [category.Name, Validators.required],
                Title: [category.Title, Validators.required],
                Description: [category.Description],
                //Thumbnail: [category['Thumbnail'] ? category['Thumbnail'] : 0],
                Media: [category['Media'] ? category['Media'] : 0],
                Location: [category.Location ? category.Location : ''],
                Content: [category.Content ? category.Content : ''],
                Categories: [(category.ParentId ? [{ ID: category.ParentId }] : []).map(item => item.ID).join(',')],
                Show: [category.Show ? true : false],
              });
              this.subjectService.breadcrumbsSubject.next({ title: category.Title });
              //
              this.apiService
                .getCategories(0, 999, false)
                .then(result => {
                  // Categories
                  those.categories = this.filterCategories(JSON.parse(JSON.stringify(result)));
                  those.categoriesTree.treeModel.update();
                  setTimeout(() => {
                    those.setSelectedNodes(
                      this.categoriesTree,
                      (category.ParentId ? [{ ID: category.ParentId }] : []).map(item => item.ID)
                    );
                  }, 100);
                })
                .catch(err => {
                  those.error('Get categories', err);
                });
              //
            })
            .catch(err => {
              those.error('Load category', err);
              history.back();
            });
        }
      }
    });
    this.config = {
      auto_focus: false,
      baseURL: those.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        those.apiService
          .postImage(data)
          .then(resp => {
            those.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            those.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 500,
    };
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  change(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLocaleLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  delete() {
    this.form.get('Media').setValue('');
    this.form.get('Media').markAllAsTouched();
    this.form.get('Media').updateValueAndValidity();
    this.form.get('Media').markAsDirty();
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Media: file,
    });
    this.form.get('Media').markAllAsTouched();
    this.form.get('Media').updateValueAndValidity();
  }

  filterCategories(tree) {
    let f;
    f = nodes => {
      const nodes2 = [];
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].id = nodes[i].ID;
        nodes[i].type = nodes[i].Type;
        nodes[i].name = nodes[i].Title;
        if (nodes[i].Type === 'category') {
          const children = f(nodes[i].Children || []);
          if (children && children.length > 0) {
            nodes[i].children = children;
          }
          delete nodes[i].Children;
          nodes2.push(nodes[i]);
        }
      }
      return nodes2;
    };
    return f(tree.Children || []);
  }

  getSelectedNodes(tree) {
    const acc = [];
    let f;
    f = nodes => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id) {
          const node = tree.treeModel.getNodeById(nodes[i].id);
          if (node && node.isSelected) {
            acc.push(nodes[i].id);
          }
        }
        if (nodes[i].children) {
          f(nodes[i].children);
        }
      }
    };
    f(tree.treeModel.nodes);
    return acc;
  }

  setSelectedNodes(tree, ids) {
    for (let i = 0; i < ids.length; i++) {
      let node = tree.treeModel.getNodeById(ids[i]);
      if (node) {
        node.setIsSelected(true);
        while (node.parent) {
          node.parent.expand();
          node = node.parent;
        }
      }
    }
  }

  selectNode(id) {
    let node = this.categoriesTree.treeModel.getNodeById(id);
    node.setIsSelected(true);
    while (node.parent) {
      node.parent.expand();
      node = node.parent;
    }
  }

  onChangeCategories1(tree, ids, $event) {
    const those = this;
    let categories = ids
      .split(/,\s*/)
      .filter(item => item != this.category['ID'])
      .map(item => +item);
    this.lock1 = true;
    let f;
    f = nodes => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id) {
          const node = tree.treeModel.getNodeById(nodes[i].id);
          if (node) {
            const selected = categories.indexOf(node.id) > -1;
            if (selected) {
              those.selectNode(node.id);
            } else {
              node.setIsSelected(selected);
            }
          }
        }
        if (nodes[i].children) {
          f(nodes[i].children);
        }
      }
    };
    f(this.categories);
    this.form.get('Categories').setValue(categories.join(','));
    this.lock1 = false;
  }

  onChangeCategories2(tree, name, ids, $event) {
    if (($event.eventName === 'select' || $event.eventName === 'deselect') && !this.lock1) {
      const categories1 = ids.split(/,\s*/).map(item => +item);
      categories1.sort((a, b) => a - b);
      let categories2 = this.getSelectedNodes(tree).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      categories2.sort((a, b) => a - b);
      categories2.forEach(item => {
        if (item !== $event.node.data.ID) {
          this.lock1 = true;
          const node = tree.treeModel.getNodeById(item);
          node.setIsSelected(false);
          this.lock1 = false;
        }
      });
      if ($event.eventName === 'select') {
        categories2 = [$event.node.data.ID];
      }
      if (JSON.stringify(categories1) !== JSON.stringify(categories2)) {
        this.form.get(name).setValue(categories2.join(','));
        this.form.get(name).updateValueAndValidity();
        this.form.get(name).markAsDirty();
      }
    }
  }

  back() {
    this.router.navigate(['/categories']);
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    /*if (this.form.get('Thumbnail')) {
      data.append('Thumbnail', this.form.get('Thumbnail').value);
    }*/
    data.append('Media', this.form.get('Media').value);
    data.append('Description', this.form.get('Description').value);
    data.append('Location', this.form.get('Location').value);
    data.append('Content', this.form.get('Content').value);
    const ids = this.getSelectedNodes(this.categoriesTree);
    if (ids && ids.length) {
      data.append('ParentId', String(ids[0]));
    }
    data.append('Show', this.form.get('Show').value);
    // data.append('ParentId', this.form.get('Categories'));
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postCategory(data)
        .then(resp => {
          those.success('Create category', resp);
          those.router.navigate(['/categories']);
        })
        .catch(err => {
          those.error('Delete categories', err);
        });
    } else {
      // update
      this.apiService
        .putCategory(this.form.get('ID').value, data)
        .then(resp => {
          those.success('Update category', resp);
          those.router.navigate(['/categories']);
        })
        .catch(err => {
          those.error('Update category', err);
        });
    }
  }
}
