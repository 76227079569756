import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { CouponsComponent } from './coupons/coupons.component';
import { CouponComponent } from './coupons/coupon/coupon.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        data: {
          title: 'All Orders',
          breadcrumb: 'Orders',
          subtitle: 'All Orders',
          description:
            'In the order overview you will find all orders created so far. From here you can manage your orders, edit them or create new orders.',
        },
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: 'All Transactions',
          breadcrumb: 'Transactions',
          subtitle: 'All Transactions',
          description:
            'In the transaction overview, you will find all transactions associated with orders. From here you can manage or edit your transactions.',
        },
      },
      {
        path: 'coupons',
        component: CouponsComponent,
        data: {
          title: 'All Coupons',
          breadcrumb: 'Coupons',
          subtitle: 'All Coupons',
          description:
            'With the help of the coupon overview you can manage and generate your coupons. You can store a validity period for the coupons. In addition, you can realize automatic coupons, such as for the registration to the newsletter. With this library you can manage your coupons and have an overview of which coupons can still be redeemed.',
        },
      },
      {
        path: 'coupons/:id',
        component: CouponComponent,
        data: {
          title: 'Coupon',
          breadcrumb: 'Coupon',
        },
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          title: 'All Customers',
          breadcrumb: 'Customers',
          subtitle: 'All Customers',
          description:
            'In the customer overview you will find all customers created so far. From here you can manage your customers, edit them or create new customers.',
        },
      },
      {
        path: 'customers/:id',
        component: CustomerComponent,
        data: {
          title: 'Customer',
          breadcrumb: 'Customer',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesRoutingModule {}
