import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { FormsDataSource } from './forms.data-source';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnabledRenderComponent } from '../../shared/render/enabled-render.component';
import { SelectedRenderComponent } from '../../shared/render/selected-render.component';
import { TitleRenderComponent } from '../../shared/render/title-render.component';
import { LocalStorageService } from 'angular-2-local-storage';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { FormMessagesRenderComponent } from './form-messages-render.component';
import { ConfirmComponent } from '../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class FormsComponent implements OnInit, OnDestroy {
  tree;
  settings;
  source: FormsDataSource;
  term;
  filtered = false;
  form;
  termChanged: Subject<string> = new Subject<string>();
  termSubscription: Subscription;
  selected;
  public closeResult: string;
  visible = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    const those = this;
    this.termSubscription = this.termChanged.pipe(debounceTime(1000)).subscribe(() => {
      those.source.refresh();
    });
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        edit: false,
        columnTitle: '',
        delete: false,
        position: 'right',
      },
      columns: {
        Selected: {
          title: '',
          type: 'custom',
          renderComponent: SelectedRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        Title: {
          title: those.translate.instant('Title'),
          type: 'custom',
          renderComponent: TitleRenderComponent,
          onComponentInitFunction: instance => {
            instance.prefix = '/forms/';
            instance.thumbnail = undefined;
          },
        },
        Messages: {
          title: those.translate.instant('Messages'),
          type: 'custom',
          renderComponent: FormMessagesRenderComponent,
        },
        Enabled: {
          title: those.translate.instant('Status'),
          type: 'custom',
          renderComponent: EnabledRenderComponent,
          onComponentInitFunction: instance => {
            instance.method = 'patchMenu';
            instance.parent = those;
          },
          filter: false,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      },
    };
    this.source = new FormsDataSource(those.http, those.apiService, those.absolutizeUrl);
    this.source.parent = those;
  }

  ngOnDestroy(): void {
    this.termSubscription.unsubscribe();
  }

  onDelete(event) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete form?';
    modal.componentInstance.body = `Are you sure you want to delete form? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteForm(event.data['ID'])
          .then(resp => {
            those.success('Delete form', resp);
            those.source.refresh();
          })
          .catch(err => {
            those.error('Delete form', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  upload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  refresh(hard?: boolean) {
    if (hard) {
      window.location.reload();
      return;
    } else {
      this.source.refresh();
    }
  }

  bulk(action?) {
    const those = this;
    switch (action) {
      case 'set-enabled':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchMenu(those.source.data[i]['ID'], 'setEnabled', { Value: true }).then(() => {
                  those.source.data[i]['Enabled'] = true;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-disabled':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchMenu(those.source.data[i]['ID'], 'setEnabled', { Value: false }).then(() => {
                  those.source.data[i]['Enabled'] = false;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'delete':
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        const name = those.source.data.filter(item => item['Selected']).length <= 1 ? 'form' : 'forms';
        modal.componentInstance.title = `Delete ${name}?`;
        modal.componentInstance.body = `Are you sure you want to delete ${name}? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            (async function loop() {
              for (let i = 0; i < those.source.data.length; i++) {
                if (those.source.data[i]['Selected']) {
                  const id = those.source.data[i]['ID'];
                  await new Promise(resolve =>
                    those.apiService.deleteForm(id).then(() => {
                      those.source.data[i]['Enabled'] = false;
                      those.source.data = those.source.data.filter(item => item.ID !== id);
                      resolve();
                    })
                  );
                }
              }
              await new Promise(resolve => {
                those.source.local = true;
                those.source.refresh();
                resolve();
              });
            })();
            those.selected = undefined;
          },
          reason => {}
        );
        break;
      default:
        this.selected = this.source.data.filter(item => item['Selected']);
    }
  }

  setPerPage(value: number) {
    this.settings.pager.perPage = value;
    this.localStorageService.set('posts_per_page', JSON.stringify(value));
    this.refresh(true);
  }
}
