<div class="container-fluid" style="padding-top: 30px">
  <div *ngIf="item" class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/forms']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>{{'Form' | translate}}: {{item['Title']}}</h1>
        </div>
        <div>
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body" style="padding: 15px 15px 0 15px;">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <h2 translate>Form</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Display Name</label>
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title">
                </div>
                <div class="form-group">
                  <label for="description" class="mb-1" translate>Description</label>
                  <textarea class="form-control" id="description" formControlName="Description"></textarea>
                </div>
                <div class="form-group">
                  <label for="type" class="mb-1" translate>Type</label>
                  <select class="form-control" id="type" formControlName="Type">
                    <option value="" translate>Default</option>
                    <option value="newsletters" translate>Newsletters</option>
                    <option value="samples" translate>Samples</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row border-top">
              <div class="col buttons" style="padding: 20px 0">
                <button type="button" class="btn btn-cancel" style="padding-right: 10px" (click)="router.navigate(['/forms/'])">Cancel
                </button>
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="(!form.valid || !form.dirty)" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
