import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles:[`
    ul{
        list-style: none;
        text-align: left;
    }
    ul li {
        display: inline-block;
        margin: 0 3px 3px 0px;
        padding: 0 3px;
    }
    ul li:not(:last-child):after{
        content: ', ';
    }
  `],
  template: `
      <ul *ngIf="values">
          <li *ngFor="let value of values">{{value['Value']}}</li>
      </ul>
  `,
})
export class CouponDiscountsRenderComponent implements OnInit, ViewCell {
  separator = ', ';
  values;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    const values = this.rowData['ValuesValues'] ? this.rowData['ValuesValues'].split(this.separator) : [];
    this.values = values.map((item, i) => {
      const value = {Value: item};
      return value;
    });
  }
}
