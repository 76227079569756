import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-image',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!image || !image['ID']" class="modal-title" id="exampleModalLabel" translate>Add Image</h5>
        <h5 *ngIf="image && image['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Image</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label for="name" class="mb-1">Name:</label>
              <input class="form-control" id="name" type="text" formControlName="Name" />
            </div>
            <div class="form-group mb-0">
              <label for="image" class="mb-1" translate>Image</label>:
              <div
                *ngIf="image && image['Url'] && !form.controls['Image'].touched"
                style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                <img
                  [src]="
                    absolutizeUrl.transform(
                      image['Thumbnail']
                        ? image['Thumbnail'].split(',').length > 1
                          ? image['Thumbnail'].split(',')[1].split(' ')[0]
                          : image['Thumbnail'].split(',')[0]
                        : ''
                    )
                  "
                  style="max-height: 300px;border-radius: 5px;margin-bottom: 5px;" />
                <a [href]="image['Url']" style="float: right" target="_blank">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </div>
              <app-file-uploader id="image" (filesLoaded)="upload($event)"></app-file-uploader>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class ImageComponent implements OnInit, AfterViewInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  image;
  modal;
  closeResult;
  form;
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required],
      Image: [''],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    console.log('OnEdit');
    const those = this;
    this.apiService
      .getImage(id)
      .then(value => {
        those.image = value;
        let thumbnail;
        const urls = value['Thumbnail'].split(',');
        thumbnail = urls[0];
        if (urls && urls.length > 1) {
          thumbnail = urls[1].split(' ')[0];
        }
        those.form = those.formBuilder.group({
          ID: [value['ID']],
          Name: [value['Name'], Validators.required],
          Image: [value['Image'] ? those.absolutizeUrl.transform(thumbnail) : ''],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        console.log('err', err);
        those.error('Load image', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete image?';
    modal.componentInstance.body = `Are you sure you want to delete image? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteImage(id)
          .then(resp => {
            those.success('Delete image', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete image', err);
          });
      },
      reason => {}
    );
  }

  upload(files) {
    const image = files[0];
    if (this.form.get('Name').value === '') {
      if (image.name.indexOf('.') > 0) {
        this.form.get('Name').setValue(image.name.replace(/\..+$/, ''));
      } else {
        this.form.get('Name').setValue(image.name);
      }
    }
    this.form.patchValue({
      Image: image,
    });
    this.form.get('Image').markAllAsTouched();
    this.form.get('Image').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Image', this.form.get('Image').value);
    if (!this.form.get('ID').value) {
      // create
      const params = {};
      if (this.variationId) {
        params['vid'] = this.variationId;
      } else if (this.productId) {
        params['pid'] = this.productId;
      }
      this.apiService
        .postImage(data, params)
        .then(resp => {
          those.success('Create image', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create image', err);
        });
    } else {
      // update
      this.apiService
        .putImage(those.form.get('ID').value, data)
        .then(resp => {
          those.success('Update image', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update image', err);
        });
    }
  }
}
