import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Observable, OperatorFunction} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TagInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TagInputComponent
    }
  ]
})
export class TagInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() input;
  tags = [];
  selected = [];
  onChange = (selected) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor(private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    const those = this;
    this.apiService.getTags().then(tags => {
      those.tags = tags;
    }).catch(err => {
      this.error('Load tags', err);
    });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  formatter = (tag) => tag.Title;

  search: OperatorFunction<string, readonly {id, name}[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length > 0),
    map(term => {
      const result = this.tags.filter(tag => new RegExp(term, 'mi').test(tag.Title)).slice(0, 10);
      if (result.length === 0) {
        const name = term.toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-').replace(/-{1,}/, '-');
        result.push({ID: 0, Name: name, Title: term});
      }
      return result;
    })
  )

  add() {
    const those = this;
    this.selected = this.selected || [];
    if (typeof this.input === 'string') {
      // new
      const newTag = {
        ID: 0,
        Name: this.input.toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-').replace(/-{1,}/, '-'),
        Title: this.input,
      };
      this.apiService.postTag(newTag).then(resp => {
        those.markAsTouched();
        those.onChange(those.tags);
      }).catch(err => {
        those.error('Create tag', err);
      });
      /*if (this.addNew) {
        this.addNew.emit(newTag);
      }*/
      this.selected.push(newTag);
    }else if (typeof this.input === 'object') {
      // existing
      const index = this.tags.findIndex(item => item.ID === this.input.ID);
      if (index >= 0) {
        const arr = this.tags.splice(index, 1);
        this.selected.push(arr[0]);
      }
      this.markAsTouched();
      this.onChange(this.selected);
    }
    // this.tagsChange.emit(this.selectedTags);
    this.input = undefined;
  }

  select(event){
    const those = this;
    const tag = event.item;
    this.selected = this.selected || [];
    const index = this.tags.findIndex(item => item.ID === tag.ID);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    if (!tag.ID) {
      this.apiService.postTag(tag).then(resp => {
        tag.ID = resp.ID;
        if (!those.selected.find(item => item.ID === tag.ID && item.Name === tag.Name)) {
          those.selected.push(tag);
        }
        those.markAsTouched();
      }).catch(err => {
        those.error('Create tag', err);
      });
    }else{
      if (!this.selected.find(item => item.ID === tag.ID && item.Name === tag.Name)) {
        this.selected.push(tag);
      }
    }
    this.markAsTouched();
    this.onChange(this.selected);
    setTimeout(() => { this.input = undefined; }, 10);
  }

  remove(tag) {
    const index = this.selected.findIndex(item => {
      if (tag.ID > 0) {
        return item.ID === tag.ID;
      }else{
        return item.Name === tag.Name;
      }
    });
    if (index >= 0) {
      const arr = this.selected.splice(index, 1);
      if (tag.ID > 0 && !this.tags.find(item => item.ID === tag.ID)) {
        this.tags.push(arr[0]);
      }
    }
    this.markAsTouched();
    this.onChange(this.selected);
    setTimeout(() => { this.input = undefined; }, 10);
  }

  writeValue(selected) {
    this.selected = selected;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
