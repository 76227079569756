import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styles: [
    `
      div {
        text-align: center;
        width: 100%;
      }
    `,
  ],
  template: `
    <div>
      <label class="switch_animated">
        <input type="checkbox" (ngModelChange)="patch($event)" [(ngModel)]="checked" />
        <span></span>
      </label>
    </div>
  `,
})
export class StandardRenderComponent implements ViewCell, OnInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  checked;

  constructor(public apiService: ApiService) {}

  ngOnInit() {
    this.checked = this.value ? true : false;
  }

  patch(value) {
    this.apiService
      .patchOption(this.rowData['ID'], 'setStandard', { Value: value })
      .then(() => {})
      .catch(err => {
        console.log(err);
      });
  }
}
