import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../utils.pipe';
import {ApiService} from '../../api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SubjectService} from '../../subject.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-preview',
  template: `
    <ng-content></ng-content>
    <ng-template #content1 let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1" translate>
          Preview
        </h5>
        <button type="button" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="panel-group" id="accordion1">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a class="accordion-toggle" data-toggle="collapse" href="#collapseOne1">
                  <span translate>Prepare</span>
                  <div style="float: right">
                    <div *ngIf="loading1">
                      <img src="./assets/images/loading.svg" alt="Loading..." style="width: 30px">
                    </div>
                    <div *ngIf="!loading1">
                      <div *ngIf="status1 == 'OK'; else else1">
                        <i class="fa fa-check" style="color: green;" aria-hidden="true"></i>
                      </div>
                      <ng-template #else1>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </ng-template>
                    </div>
                  </div>
                </a>
              </h4>
            </div>
            <div id="collapseOne1" class="panel-collapse collapse in">
              <div class="panel-body" style="border: 1px solid lightgray;border-radius: 5px;font-family: monospace;max-height: 250px;overflow: auto;white-space: pre;">
                {{output1}}
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <a class="accordion-toggle" data-toggle="collapse" href="#collapseTwo1">
                  <span translate>Render</span>
                  <div style="float: right">
                    <div *ngIf="loading2">
                      <img src="./assets/images/loading.svg" alt="Loading..." style="width: 30px">
                    </div>
                    <div *ngIf="!loading2">
                      <div [ngSwitch]="status2">
                        <div *ngSwitchCase="'OK'">
                          <i class="fa fa-check" style="color: green;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchCase="undefined">
                          <i class="fa fa-clock-o" style="color: black;" aria-hidden="true"></i>
                        </div>
                        <div *ngSwitchDefault>
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </h4>
            </div>
            <div id="collapseTwo1" class="panel-collapse collapse">
              <div class="panel-body" style="border: 1px solid lightgray;border-radius: 5px;font-family: monospace;max-height: 250px;overflow: auto;white-space: pre;">
                {{output2}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="status1 == 'OK' && status2 == 'OK'" class="modal-footer" style="justify-content: center;">
        <button *ngIf="status3 == 'READY'" class="btn btn-primary" type="button" data-dismiss="modal" (click)="go('/api/v1/preview', true)" translate>Open</button>
      </div>
    </ng-template>
  `
})
export class PreviewComponent implements OnInit, OnDestroy {
  @Input('debug') debug = false;
  @ViewChild('content') content;
  subscription: Subscription;
  info;

  previewing = -1;
  modal;
  loading1 = true;
  status1 = undefined;
  output1;
  loading2 = false;
  status2 = undefined;
  output2;
  loading3 = false;
  status3 = undefined;
  output3;
  closeResult;

  constructor(private apiService: ApiService, private subjectService: SubjectService, private modalService: NgbModal, private translate: TranslateService, private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, ) {
  }

  ngOnInit(): void {
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      if (info['User']) {
        info['User']['Role'] = ['Root', 'Admin', 'Manager'][info['User']['Role']];
      }
      this.info = info;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {closeButton: true, timeOut: 15000});
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else {
      return `with: ${reason}`;
    }
  }

  onPreview(content) {
    if (this.info['HasChanges'] && this.info['HasChanges']['Updated']) {
      if (this.info['Debug']) {
        this.status1 = undefined;
        this.status2 = undefined;
        this.status3 = undefined;
        this.loading1 = true;
        this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        this.apiService.postPrepare({}).then(resp => {
          this.loading1 = false;
          this.output1 = resp['Output'];
          this.status1 = resp['Status'];
          this.loading2 = true;
          this.apiService.postRender({}).then(resp => {
            this.loading2 = false;
            this.output2 = resp['Output'];
            this.status2 = resp['Status'];
            this.status3 = 'READY';
          }).catch(err => {
            this.error('Render', err);
            this.loading2 = false;
            this.output2 = err.error?.Output;
            this.status2 = 'Fail';
          });
        }).catch(err => {
          this.error('Prepare', err);
          this.loading1 = false;
          this.output1 = err.error?.Output;
          this.status1 = 'Fail';
        });
        this.modal.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }else{
        if (this.previewing < 2) {
          this.status1 = undefined;
          this.status2 = undefined;
          this.status3 = undefined;
          this.previewing = 0;
          this.loading1 = true;
          this.apiService.postPrepare({}).then(resp => {
            this.loading1 = false;
            this.output1 = resp['Output'];
            this.status1 = resp['Status'];
            this.previewing = 1;
            this.loading2 = true;
            this.apiService.postRender({}).then(resp => {
              this.loading2 = false;
              this.output2 = resp['Output'];
              this.status2 = resp['Status'];
              this.previewing = 2;
              this.loading3 = true;
              this.go('/api/v1/preview', true);
              setTimeout(() => this.previewing = -1, 1000);
            }).catch(err => {
              console.log('err', err);
              this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
              this.modal.result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
              this.error('Render', err);
              this.loading2 = false;
              this.output2 = err.error?.Output;
              this.status2 = 'Fail';
            });
          }).catch(err => {
            console.log('err', err);
            this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
            this.modal.result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
            this.error('Prepare', err);
            this.loading1 = false;
            this.output1 = err.error?.Output;
            this.status1 = 'Fail';
          });
        }else{
          this.apiService.getInfo().then(info => {
            this.subjectService.infoSubject.next(info);
          }).catch(err => { });
          this.go('/api/v1/preview', true);
          this.previewing = -1;
        }
      }
    }else{
      this.apiService.getInfo().then(info => {
        this.subjectService.infoSubject.next(info);
      }).catch(err => { });
      this.go('/api/v1/preview', true);
    }
  }

  publish() {
    const those = this;
    those.loading3 = true;
    those.apiService.postPublish({}).then(resp => {
      those.loading3 = false;
      those.output3 = resp['Output'];
      those.status3 = resp['Status'];
      those.apiService.getInfo().then(info => {
        those.subjectService.infoSubject.next(info);
      }).catch(err => {
        those.error('Publish', err);
      });
    }).catch(err => {
      those.error('Publish', err);
      those.loading3 = false;
      those.output3 = err.error?.Output;
      those.status3 = 'Fail';
    });
  }

  go(url, path?) {
    window.open(this.absolutizeUrl.transform(url + (path ? '?path=' + window.location.pathname : '')), '_blank');
  }

}
