<div id="tab-basic-information" class="container-fluid" style="padding-top: 30px">
  <div class="row pb-4">
    <div [ngClass]="{'col-md-3': !maximized, 'col-lg-2': !maximized, 'hidden': maximized}"></div>
    <div [ngClass]="{'col-md-9': !maximized, 'col-lg-10': !maximized, 'col': maximized}">
      <div *ngIf="product" class="back-title-button">
        <a class="back" [routerLink]="['/products/' + pid]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>{{product['Title']}}: {{variation ? variation['Title'] : ('New Variation' | translate)}}</h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" style="margin: 0 10px 0 10px;" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setEnabled(true)">
                Active
              </li>
              <li (click)="setEnabled(false)">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="z-index: 10">
  <form *ngIf="form" class="row productneeds-validation" [formGroup]="form">
    <div [ngClass]="{'col-md-3': !maximized, 'col-lg-2': !maximized, 'hidden': maximized}">
      <div>
        <div class="menu-header" (click)="displayed=!displayed">
          <svg width="24px" height="24px">
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-navigation-right"></use>
          </svg>
        </div>
        <nav id="navbar-left" class="navbar navbar-light bg-light fixed-desktop" [ngClass]="{'displayed': displayed, 'not-displayed': !displayed}" style="margin: 0 -30px;padding: 0 30px 30px 30px">
          <nav class="nav nav-pills flex-column">
            <div class="product-nav">
              <a class="nav-link" href="#tab-basic-information" class="nav-link active" (click)="scroll('tab-basic-information')" translate>Basic Information</a>
              <a *ngIf="variation" class="nav-link" href="#tab-media" (click)="scroll('tab-media')" translate>Media</a>
              <a *ngIf="variation" class="nav-link" href="#tab-inventory" (click)="scroll('tab-inventory')" translate>Inventory</a>
              <a class="nav-link" href="#tab-dimensions" (click)="scroll('tab-dimensions')" translate>Dimensions</a>
              <a *ngIf="variation" class="nav-link" href="#tab-parameters" (click)="scroll('tab-parameters')" translate>Custom Fields</a>
              <a *ngIf="variation" class="nav-link" href="#tab-properties" (click)="scroll('tab-properties')"><span translate>Options</span><span *ngIf="variation && variation['Properties'] && variation['Properties'].length > 0"> ({{variation['Properties'].length}})</span></a>
              <a *ngIf="variation" class="nav-link" href="#tab-prices" (click)="scroll('tab-prices')"><span translate>Prices</span><span *ngIf="variation && variation['Prices'] && variation['Prices'].length > 0"> ({{variation['Prices'].length}})</span></a>
              <a *ngIf="variation" class="nav-link" href="#tab-posts" (click)="scroll('tab-posts')" translate>Infoboxes</a>
            </div>
            <div class="submenu-actions" style="padding-top: 25px">
              <div style="padding-top:5px; text-align: center">
                <button type="button" class="btn btn-primary facon" style="height: 44px;width: 100%;" [title]="'Save' | translate" [disabled]="!form.valid || !form.dirty || !changed" (click)="onSubmit()" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span translate>Save</span>
                </button>
              </div>
              <div *ngIf="(!form.valid || !form.dirty)" style="padding-top:5px; text-align: center">
                <app-preview #previewer>
                  <button type="button" class="btn btn-secondary"
                          [ngClass]="{'facon': previewer.previewing === -1, 'step-0': previewer.previewing === 0, 'step-30': previewer.previewing === 1, 'step-100': previewer.previewing === 2}"
                          style="width: 100%;" data-toggle="modal" data-original-title="test"
                          [ngStyle]="{height: previewer.previewing === -1 ? '44px' : 'initial'}"
                          data-target="#exampleModal" (click)="previewer.onPreview(previewer.content)">
                    <svg>
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-view"></use>
                    </svg>
                    <span translate>Preview</span>
                  </button>
                </app-preview>
              </div>
              <div *ngIf="variation" style="padding-top: 20px; text-align: center;">
                <button class="btn btn-copy" [ngClass]="{locked: form.dirty}" style="width: 34px;height: 34px;padding: 3px 0 0 0;margin-right: 10px;" [disabled]="form.dirty" (click)="onClone()">
                  <svg width="12px" height="12px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy"></use>
                  </svg>
                </button>
                <button class="btn btn-delete" style="width: 34px;height: 34px;padding: 3px 0 0 0;" (click)="onDelete(variation.ID)">
                  <svg width="16px" height="16px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
    <div [ngClass]="{'col-md-9': !maximized, 'col-lg-10': !maximized, 'col': maximized}">
      <div *ngIf="form" class="row">
        <div [ngClass]="{'col-lg-8': !maximized, 'col-lg-12': maximized, maximized: maximized}">
          <!-- -->
          <div>
            <div class="card" style="z-index: 9">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h2 style="margin-bottom: 20px" translate>Basic Information</h2>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="title" class="mb-1" translate>Title</label>
                      <input class="form-control" id="title" type="text" (keyup)="onChangeTitle($event)" formControlName="Title">
                    </div>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" class="mb-1" translate>Permalink</label>
                      <input class="form-control" id="name" type="text" formControlName="Name">
                    </div>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12" style="min-height: 300px">
                    <label class="mb-1" translate>Description</label>
                    <span class="tooltip-question" style="padding-left: 5px;vertical-align: sub;" placement="top" [ngbTooltip]="'This content will be displayed on product page independent of selected variation, good for common information and promotion' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <app-tinymce [options]="tinymceConfig" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Description"></app-tinymce>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Media -->
          <div *ngIf="variation" id="tab-media">
            <app-media-block [type]="'variation'" [variationId]="variation['ID']" [medias]="variation['Medias']" [mediaIdFormControl]="form.get('MediaId')"></app-media-block>
          </div>
          <!-- Inventory -->
          <div *ngIf="variation" id="tab-inventory">
            <app-inventory-block [variationId]="variation['ID']" [form]="form" [hasTransfers]="variation.HasTransfers?true:false" (onSaved)="refresh($event)"></app-inventory-block>
          </div>
          <!-- Dimensions -->
          <div id="tab-dimensions">
            <app-dimensions-block [form]="form"></app-dimensions-block>
          </div>
          <!-- Parameters / Custom Fields -->
          <div *ngIf="variation" id="tab-parameters">
            <app-parameters-block [variationId]="variation['ID']" [parameters]="variation['Parameters']" [parametersFormControl]="form.get('Parameters')"></app-parameters-block>
          </div>
          <!-- Properties -->
          <div *ngIf="variation" id="tab-properties">
            <app-properties-block [variationId]="variation.ID" [basePrice]="variation.BasePrice" [salePrice]="variation.SalePrice" [properties]="variation.Properties" [form]="form" [info]="info" [tinymceConfig]="tinymceConfig" (onSaved)="refresh('properties')"></app-properties-block>
          </div>
          <!-- Prices -->
          <div *ngIf="variation" id="tab-prices" style="position: relative">
            <app-prices-block [properties]="variation.Properties" [(prices)]="variation.Prices" [variationId]="variation.ID"
                              [basePriceFormControl]="form.get('BasePrice')" [salePriceFormControl]="form.get('SalePrice')"
                              [manufacturerPriceFormControl]="form.get('ManufacturerPrice')" [itemPriceFormControl]="form.get('ItemPrice')"
                              [onSaleFormControl]="form.get('OnSale')"
                              [startFormControl]="form.get('Start')" [endFormControl]="form.get('End')"
                              [form]="form"
                              [decimal]="decimal"
                              [prefix]="prefix"
                              [thousands]="thousands"
                              [dimensionUnit]="dimensionUnit"
                              [weightUnit]="weightUnit"
                              [pattern]="pattern"
                              [tinymceConfig]="tinymceConfig"></app-prices-block>
            <div style="position: absolute; right: -15px; top: 5px">
              <div class="btn btn-fourth" (click)="maximized=!maximized">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 2.5H17.5V7.5" stroke="#616573" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.5 17.5H2.5V12.5" stroke="#616573" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5001 2.5L11.6667 8.33333" stroke="#616573" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2.5 17.5001L8.33333 11.6667" stroke="#616573" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <!-- Blocks -->
          <div *ngIf="variation" id="tab-posts">
            <app-blocks-block [variationId]="variation['ID']" [form]="form" [blocks]="variation['Blocks']" [properties]="variation['Properties']"></app-blocks-block>
          </div>
        </div>
        <div [ngClass]="{'col-lg-4': !maximized, 'col-lg-12': maximized}">
          <div *ngIf="product && product.Variations" class="card">
            <div class="card-body" style="padding-bottom: 0">
              <div class="row">
                <div class="col">
                  <h2 style="margin-bottom: 20px" translate>Variations</h2>
                </div>
                <div class="col">
                  <div style="display: flex; justify-content: end">
                    <div class="button-icon" [ngClass]="{disabled: !hasPrev, locked: loading}" (click)="prev()">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="button-icon" [ngClass]="{disabled: !hasNext, locked: loading}" (click)="next()">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18L15 12L9 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="product && product.Variations && product.Variations.length > 2" style="position:relative;padding-bottom: 10px;">
                <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
                  </svg>
                </div>
                <input type="text" class="form-control search" style="padding-left: 32px;" placeholder="{{'search' | translate}}" (change)="search($event)" (keyup.enter)="search($event)" (keyup)="search($event)" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="variation-items">
                <div *ngFor="let filteredVariation of filteredVariations; let i = index;" [attr.id]="'variation-' + filteredVariation.ID" class="variation-item">
                  <div [ngClass]="{selected: filteredVariation.ID == variation.ID}" style="cursor: pointer" (click)="select(filteredVariation.ID)">
                    <div [style]="{backgroundImage: 'url(' +
                                mediaThumbnail.transform(filteredVariation['Media']) + ')', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', cursor: 'pointer', display: 'inline-block', height: '40px', width: '40px', marginRight: '10px', verticalAlign: 'middle'}">
                    </div>
                    <div class="variation-item-title">{{filteredVariation.Title}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h2 style="margin-bottom: 20px;" translate>Internal Note</h2>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <textarea class="form-control" id="notes" rows="3" placeholder="{{'Here you can put a note for employees' | translate}}" formControlName="Notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="!form" class="row">
    <div class="col">
      <div style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
    </div>
  </div>
</div>
