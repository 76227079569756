<div *ngIf="form" [formGroup]="form">
  <div *ngIf="custom" formGroupName="Post">
    <div class="row pb-2">
      <div class="col">
        <label class="mb-1" translate>Title</label>
        <div><input class="form-control" type="text" formControlName="Title"></div>
      </div>
      <!--div class="col">
        <label class="mb-1" translate>Thumbnail</label>
        <div><app-thumbnail-input formControlName="Thumbnail"></app-thumbnail-input></div>
      </div-->
    </div>
    <div class="row pb-2">
      <div class="col">
        <label class="mb-1" translate>Content</label>
        <app-tinymce [options]="tinymceConfig" (focus)="$event.target?.iframeElement?.classList?.add('focused')" (blur)="$event.target?.iframeElement?.classList?.remove('focused')" formControlName="Content"></app-tinymce>
      </div>
    </div>
  </div>
  <div *ngIf="!custom" >
    <div class="row pb-2">
      <div class="col">
        <label for="posts" class="mb-1" translate>Infobox Name</label>
        <a [routerLink]="['/posts/' + form.get('PostId').value]" class="cursor-pointer" style="float: right" target="_blank">
          <svg class="fill-primary-violet" style="height: 16px; width: 16px;">
            <use [attr.xlink:href]="'/assets/icons/menu-symbol-defs.svg#icon-popup-link'"></use>
          </svg>
        </a>
        <ngx-bootstrap-multiselect *ngIf="options" id="posts" class="multiselect single-selected" [options]="options" [texts]="texts"
                                 [settings]="settings" (ngModelChange)="change($event)" formControlName="PostId"></ngx-bootstrap-multiselect>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col">
        <label class="mb-1" translate>Display Name</label>
        <div><input class="form-control" type="text" formControlName="Title"></div>
      </div>
    </div>
  </div>
  <div class="row pb-2">
    <div class="col">
      <label class="mb-1" translate>Location</label>
      <app-select [options]="locations" formControlName="Location"></app-select>
    </div>
  </div>
</div>
