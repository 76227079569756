import {AfterViewInit, Component, Input} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {fromEvent, Subscription} from 'rxjs';

@Component({
  styles: [`
    div{
      min-width: 20px;
      padding-top: 6px;
    }
  `],
  template: `
    <div><input type="checkbox" [attr.id]="'prices-' + rowData['ID'] + '-selected'" class="checkbox_animated" [(ngModel)]="rowData['Selected']" (ngModelChange)="change()"></div>
  `,
})
export class PriceSelectedRenderComponent implements ViewCell, AfterViewInit {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  ngAfterViewInit(): void {
    const id = 'prices-' + this.rowData['ID'] + '-selected';
    setTimeout(() => {
      const element = document.querySelector('#' + id);
      if (element) {
        const tr = element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
        if (tr) {
          if (this.rowData['Selected']) {
            tr.classList.add('selected2');
          }else{
            tr.classList.remove('selected2');
          }
        }
      }
    }, 100);
  }

  change() {
    const id = 'prices-' + this.rowData['ID'] + '-selected';
    setTimeout(() => {
      const element = document.querySelector('#' + id);
      if (element) {
        const tr = element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
        if (tr) {
          if (this.rowData['Selected']) {
            tr.classList.add('selected2');
          }else{
            tr.classList.remove('selected2');
          }
        }
      }
    }, 100);
    if (this.parent) {
      this.parent.bulk();
    }else{
      console.log('parent not set');
    }
  }
}
