import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-comment',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" translate>View Comment</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group d-flex flex-row justify-content-between">
              <ngb-rating [(rate)]="currentRate" [max]="5" readonly="true"></ngb-rating>
            </div>
            <div class="form-group">
              <label for="headline" class="mb-1" translate>Title</label>
              <input
                class="form-control"
                id="headline"
                type="text"
                (keyup)="onChange($event)"
                formControlName="Title" />
            </div>
            <div class="form-group">
              <label for="review" class="mb-1" translate>Message</label>
              <textarea class="form-control" id="review" type="text" formControlName="Body" rows="5"></textarea>
            </div>
            <div class="form-group">
              <label class="mb-1" translate>Images</label>
              <div>
                <div *ngFor="let image of images; let i = index" style="display: inline-block">
                  <a
                    [href]="
                      absolutizeUrl.transform(image ? image : '/api/v1/resize?path=/assets/images/no-image.svg')
                    "
                    target="_blank"
                    ><div
                      [style]="{
                        backgroundImage:
                          'url(' +
                          absolutizeUrl.transform(
                            (image ? image : '/api/v1/resize?path=/assets/images/no-image.svg') + ')'
                          ),
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        display: 'inline-block',
                        height: '120px',
                        width: '120px',
                        margin: '5px',
                        verticalAlign: 'middle'
                      }"></div
                  ></a>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="d-block" for="filtering" translate>
                {{ 'Published' | translate }}&nbsp;
                <input class="checkbox_animated" id="filtering" type="checkbox" formControlName="Enabled" />
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styleUrls: ['./comment.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class CommentComponent implements OnInit, AfterViewInit {
  @Input('vendorId') vendorId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  comment;
  modal;
  closeResult;
  form;
  timer;
  images = [];
  currentRate = 1;
  selected = 0;
  hovered = 0;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  onChange(event) {
    if (!this.form.get('Id').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/ö/gi, 'oe')
          .replace(/ü/gi, 'ue')
          .replace(/ä/gi, 'ae')
          .replace(/ß/gi, 'ss')
          .replace(/[^-a-z0-9\(\)]+/gi, '-')
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content, times?) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
    });
    //
    those.modal = those.modalService.open(content, { ...times, ...{ ariaLabelledBy: 'modal-basic-title' } });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getComment(id)
      .then(comment => {
        those.comment = comment;
        those.currentRate = comment.Max;
        those.form = those.formBuilder.group({
          Id: [comment['Id']],
          Enabled: [comment['Enabled'] ? true : false],
          Title: [{ disabled: true, value: comment['Title'] }],
          Body: [{ disabled: true, value: comment['Body'] }],
          Max: [comment['Max']],
        });
        if (comment.Images) {
          comment.Images.split(/;/).forEach(image => {
            const urls = image.split(/,/);
            if (urls.length > 1) {
              this.images.push(urls[1].split(/ /)[0]);
            } else {
              this.images.push(urls[0].split(/ /)[0]);
            }
          });
        }
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load comment', err);
      });
  }

  onCustom(id) {
    console.log('id', id);
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete comment?';
    modal.componentInstance.body = `Are you sure you want to delete comment? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteComment(id)
          .then(resp => {
            those.success('Delete Comment', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete Comment', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = those.form.getRawValue();
    this.comment.Max = this.currentRate;
    raw.Max = this.currentRate;
    if (!this.form.get('Id').value) {
      // create
      this.apiService
        .postComment(raw, { vid: this.vendorId })
        .then(resp => {
          those.success('Create comment', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create comment', err);
        });
    } else {
      // update
      this.apiService
        .putComment(this.form.get('Id').value, { ...this.comment, ...raw })
        .then(resp => {
          those.success('Update Comment', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update comment', err);
        });
    }
  }

  onClear() {
    this.currentRate = 0;
  }
}
