import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {Router} from '@angular/router';
import {credentials} from '../../shared/credentials.data';

@Component({
  template: `
      {{currency}}{{toFixed(value)}}
  `,
})
export class VariationBasepriceRenderComponent implements ViewCell, OnInit {
  currency = credentials.currency;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit() { }

  toFixed(value: any) {
    return Number(value).toFixed(2);
  }
}
