import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {Router} from '@angular/router';

@Component({
  template: `
    <span translate>{{displayed}}</span>
  `,
})
export class UserRoleRenderComponent implements OnInit, ViewCell {
  displayed;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    if (+this.value === 0) {
      this.displayed = 'root';
    }else if (+this.value === 1) {
      this.displayed = 'admin';
    }else if (+this.value === 2) {
      this.displayed = 'manager';
    }else{
      this.displayed = 'user';
    }

  }
}
