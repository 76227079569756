import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from './windows.service';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from '../subject.service';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  description?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  redirect?: string;
  page?: string; // the name of pagination line from localstorage
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;

  MENUITEMS: Menu[] = [
    {
      path: '/dashboard/default',
      title: this.translate.instant('Dashboard'),
      description:
        'Analytics dashboard to quickly view all key performance indicators (KPIs) such as sales, orders and products.',
      icon: 'menu-dashboard',
      type: 'link',
      badgeType: 'primary',
      active: false,
    },
    {
      title: this.translate.instant('Orders'),
      icon: 'menu-orders',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/sales/orders',
          title: this.translate.instant('All Orders'),
          description:
            'In the order overview, all orders are displayed with the respective information. Orders can be entered, edited or managed here.',
          type: 'link',
        },
        {
          path: '/sales/transactions',
          title: this.translate.instant('Transactions'),
          description:
            'In the transaction overview, all transactions are displayed with the respective information. Transactions can be managed here.',
          type: 'link',
        },
      ],
      redirect: '/sales/orders',
    },
    {
      title: this.translate.instant('Products'),
      icon: 'menu-products',
      type: 'sub',
      active: false,
      redirect: '/products',
      page: 'products',
      children: [
        {
          path: '/products',
          title: this.translate.instant('All Products'),
          description: 'In the product overview you will find all created products.',
          type: 'link',
          page: 'products',
        },
        {
          path: '/options',
          title: this.translate.instant('Product Options'),
          description:
            'You can find saved product options in the product library. Options from the library can be assigned to multiple products for faster variant creation.',
          type: 'link',
          page: 'options',
        },
        {
          path: '/dimensions',
          title: this.translate.instant('Custom Fields'),
          description:
            'Saved custom fields can be found in the custom field library. Custom fields from the library can be assigned to multiple products for faster and more consistent item creation.',
          type: 'link',
          page: 'dimensions',
        },
        {
          path: '/tags',
          title: this.translate.instant('Tags'),
          description:
            'With tags you can categorize products, customers and much more. You can manage your tags in the tag library.',
          type: 'link',
          page: 'tags',
        },
        // {path: '/variations', title: this.translate.instant('Variations'), type: 'link'},
        // { path: '/properties', title: this.translate.instant('Properties'), type: 'link' },
        // { path: '/prices', title: this.translate.instant('Prices'), type: 'link' },
        // { path: '/values', title: this.translate.instant('Values'), type: 'link' },
        {
          path: '/posts',
          title: this.translate.instant('Infoboxes'),
          description:
            'Infoboxes allow you to give your customers important information about your item. You can manage your infoboxes in the infobox library. Infoboxes from the library can be assigned to multiple products for faster and more consistent article creation.',
          type: 'link',
          page: 'posts',
        },
        {
          path: '/vendors',
          title: this.translate.instant('Vendors'),
          description:
            'By selecting vendors and associated delivery times, you can create consistency across products. In addition, changes in the master also immediately affect all linked products. You can manage your vendors in the vendor library.',
          type: 'link',
          page: 'vendors',
        },
        {
          path: '/categories',
          title: this.translate.instant('Product Categories'),
          description:
            'Categories allow you to group products according to similar properties. So you can decide which categories you want to display in your store and which products should belong to which category. You can manage your categories in the category area.',
          type: 'link',
        },
      ],
    },
    /*{path: '/sales/customers', title: this.translate.instant('Customers'), icon: 'menu-customers', type: 'link'},*/
    {
      title: this.translate.instant('Customers'),
      icon: 'menu-customers',
      type: 'sub',
      children: [
        {
          path: '/sales/customers',
          title: this.translate.instant('All Customers'),
          description:
            'In the customer overview, all customers are displayed with the respective information. Customers can be entered, edited or managed here.',
          type: 'link',
        },
        {
          path: '/comments',
          title: this.translate.instant('All Reviews'),
          description:
            'In customer review overview all reviews are displayed with the respective information. Reviews can be managed here.',
          type: 'link',
          page: 'comments',
        },
        {
          path: '/messages',
          title: this.translate.instant('All Messages'),
          description:
            'In the message area you can manage all your outgoing messages with the corresponding information.',
          type: 'link',
          page: 'messages',
        },
      ],
      redirect: '/sales/customers',
      page: 'customers',
    },
    // {
    //   path: '/pages',
    //   title: this.translate.instant('Pages'),
    //   description: 'In the Pages panel you can manage all the pages of your website. ',
    //   icon: 'menu-pages',
    //   type: 'link',
    // },
    {
      path: '/websites',
      title: this.translate.instant('Websites'),
      description: 'In the Websites panel you can manage all the websites',
      icon: 'menu-pages',
      type: 'link',
    },
    /*{
      title: this.translate.instant('Media'), icon: 'menu-media', type: 'sub', active: false, children: [
        {path: '/media/files', title: this.translate.instant('Files'), type: 'link'},
        {path: '/media/images', title: this.translate.instant('Images'), type: 'link'},
      ]
    },*/
    {
      path: '/media',
      title: this.translate.instant('Media'),
      description:
        'In the media library, you can edit, view, and delete previously uploaded media. Multiple media items can be selected for deletion. You can also search and filter to find the desired media.',
      icon: 'menu-media',
      type: 'link',
      page: 'medias',
    },
    {
      title: this.translate.instant('Marketing'),
      description:
        'In the marketing section you can manage your marketing activities. Here you can manage also your gift vouchers and coupon codes.',
      icon: 'menu-marketing',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/sales/coupons',
          title: this.translate.instant('All Coupons'),
          description:
            'In the coupons overview, all coupons are displayed with the respective information. Coupon codes can be entered, edited or managed here.',
          type: 'link',
          page: 'coupons',
        },
      ],
      redirect: '/sales/coupons',
      page: 'coupons',
    },
    {
      path: '/settings',
      title: this.translate.instant('Settings'),
      description: 'In settings you can manage all your important shop settings. ',
      icon: 'menu-settings',
      type: 'link',
    },
    /*{
      title: this.translate.instant('Settings'), icon: 'menu-settings', type: 'sub', children: [
        {path: '/settings', title: this.translate.instant('All'), type: 'link'},
        {path: '/settings/global', title: this.translate.instant('Global'), type: 'link'},
        {path: '/settings/hugo', title: this.translate.instant('Theme'), type: 'link'},
        {path: '/settings/publisher', title: this.translate.instant('Publisher'), type: 'link'},
        {path: '/menus', title: this.translate.instant('Menus'), type: 'link'},
        {path: '/templates', title: this.translate.instant('Templates'), type: 'link'},
        {path: '/transports/companies', title: this.translate.instant('Shipping & Delivery'), type: 'link'},
        {path: '/transports/zones', title: this.translate.instant('Zones'), type: 'link'},
        {path: '/themes', title: this.translate.instant('Themes'), type: 'link'},
        {path: '/users', title: this.translate.instant('Users'), type: 'link'},
      ]
    },*/
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  constructor(
    @Inject(WINDOW) private window,
    private subjectService: SubjectService,
    private translate: TranslateService
  ) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    const results = this.subjectService.searchSubject.value || [];
    for (let i = 0; i < this.MENUITEMS.length; i++) {
      if (this.MENUITEMS[i].type === 'link') {
        results.push({ path: this.MENUITEMS[i].path, type: 'link', title: this.MENUITEMS[i].title });
      }
      if (this.MENUITEMS[i].children) {
        for (let j = 0; j < this.MENUITEMS[i].children.length; j++) {
          const typ = this.MENUITEMS[i].children[j].type;
          const title = this.MENUITEMS[i].children[j].title;
          if (typ === 'link' && ['Products'].indexOf(title) < 0) {
            results.push({
              path: this.MENUITEMS[i].children[j].path,
              type: 'link',
              title,
              description: this.MENUITEMS[i].children[j].description,
            });
          }
        }
      }
    }
    this.subjectService.searchSubject.next(results);
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
