import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {credentials} from '../../shared/credentials.data';

@Component({
  styles: [`
    .saleprice + .baseprice{
      text-decoration: line-through;
    }
  `],
  template: `
    <div *ngIf="rowData['SalePrice']" class="saleprice">{{currency}} {{salePrice}}</div>
    <div class="baseprice">{{currency}} {{basePrice}}</div>
  `,
})
export class ProductPriceRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;
  currency = credentials.currency;
  basePrice;
  salePrice;

  ngOnInit() {
    this.basePrice = Number(this.rowData['BasePrice']).toFixed(2);
    this.salePrice = Number(this.rowData['SalePrice']).toFixed(2);
  }
}
