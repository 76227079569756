import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import {AbsolutizeUrlPipe, FilterPipe, HighlightPipe, HumanizeSizePipe, MediaThumbnailPipe} from './utils.pipe';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { VariationComponent } from './modals/variation/variation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductComponent } from './modals/product/product.component';
import { PropertyComponent } from './modals/property/property.component';
import { RateComponent } from './modals/rate/rate.component';
import { PriceComponent } from './modals/price/price.component';
import { OptionComponent } from './modals/option/option.component';
import { ValueComponent } from './modals/value/value.component';
import { TagComponent } from './modals/tag/tag.component';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TinymceModule } from 'angular2-tinymce';
import { FileComponent } from './modals/file/file.component';
import { TranslateModule } from '@ngx-translate/core';
import { ParameterComponent } from './modals/parameter/parameter.component';
import { TransportComponent } from './modals/transport/transport.component';
import { ZoneComponent } from './modals/zone/zone.component';
import { ImageComponent } from './modals/image/image.component';
import { ImagesComponent } from './modals/images/images.component';
import { EnabledRenderComponent } from './render/enabled-render.component';
import { DateRenderComponent } from './render/date-render.component';
import { CouponComponent } from './modals/coupon/coupon.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { PriceRenderComponent } from './render/price-render.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { VendorComponent } from './modals/vendor/vendor.component';
import { TimeComponent } from './modals/time/time.component';
import { CommentComponent } from './modals/comment/comment.component';
import { MenuComponent } from './modals/menu/menu.component';
import { PricesComponent } from './modals/prices/prices.component';
import { OrderComponent } from './modals/order/order.component';
import { CurrencyDirective } from './directives/currency.directive';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FilesComponent } from './modals/files/files.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormComponent } from './modals/form/form.component';
import { MessageComponent } from './modals/message/message.component';
import { DragulaModule } from 'ng2-dragula';
import { PublishComponent } from './modals/publish/publish.component';
import { PreviewComponent } from './modals/preview/preview.component';
import { HintComponent } from './modals/hint/hint.component';
import { PostComponent } from './modals/post/post.component';
import { PostsComponent } from './modals/posts/posts.component';
import { VariationsComponent } from './modals/variations/variations.component';
import { BlockComponent } from './modals/block/block.component';
import { ProfileComponent } from './modals/profile/profile.component';
import { NumberRenderComponent } from './render/number-render.component';
import { PositionComponent } from './modals/position/position.component';
import { SelectedRenderComponent } from './render/selected-render.component';
import { MediaLibraryComponent } from './modals/media/medias.component';
import { MediaComponent } from './modals/media/media.component';
import { FavoriteRenderComponent } from './render/favorite-render.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ServiceComponent } from './modals/service/service.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { SelectComponent } from './components/select/select.component';
import { ChooseQuantityComponent } from './components/choose-quantity/choose-quantity.component';
import { TagsLibraryComponent } from './modals/tag/tags.component';
import { TitleRenderComponent } from './render/title-render.component';
import { SenderComponent } from './components/sender/sender.component';
import { CountryInputComponent } from './components/country-input/country-input.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BooleanRenderComponent } from './render/boolean-render.component';
import { StockInputComponent } from './components/stock-input/stock-input.component';
import { TransferComponent } from './modals/transfer/transfer.component';
import { TransfersComponent } from './modals/transfer/transfers.component';
import { DateAgoRenderComponent } from './render/date-ago-render.component';
import { CategoryInputComponent } from './components/category-input/category-input.component';
import { TreeModule } from 'angular-tree-component';
import { ProductInputComponent } from './components/product-input/product-input.component';
import { DemoComponent } from './components/demo/demo.component';
import { TitleInputComponent } from './components/title-input/title-input.component';
import { CellInputComponent } from './components/cell-input/cell-input.component';
import { HotTableModule } from 'ng2-handsontable';
import { ColorInputComponent } from './components/color-input/color-input.component';
import { FileUploaderInlineComponent } from './components/file-uploader/file-uploader-inline.component';
import { ThumbnailInputComponent } from './components/thumbnail-input/thumbnail-input.component';
import { MediaInputComponent } from './components/media-input/media-input.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { MomentModule } from 'ngx-moment';
import { Ng2CompleterModule } from '@akveo/ng2-completer';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { MediaBlockComponent } from './blocks/media/media-block.component';
import { SmartFilterComponent } from './components/smart-filter/smart-filter.component';
import { RenameComponent } from './modals/rename/rename.component';
import { MySmartTableComponent } from './components/my-smart-table/my-smart-table.component';
import { StandardRenderComponent } from './render/standard-render.component';
import { NameRenderComponent } from './render/name-render.component';
import { ListRenderComponent } from './render/list-render.component';

@NgModule({
  declarations: [
    CurrencyDirective,
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    FileUploaderComponent,
    FileUploaderInlineComponent,
    ProductComponent,
    ParameterComponent,
    VariationComponent,
    VariationsComponent,
    PropertyComponent,
    RateComponent,
    PricesComponent,
    PriceComponent,
    TagComponent,
    BlockComponent,
    ConfirmComponent,
    RenameComponent,
    PostComponent,
    PostsComponent,
    OptionComponent,
    ValueComponent,
    CouponComponent,
    FormComponent,
    MessageComponent,
    MenuComponent,
    FileComponent,
    FilesComponent,
    ImageComponent,
    ImagesComponent,
    MediaLibraryComponent,
    MediaComponent,
    TransportComponent,
    ServiceComponent,
    CategoryInputComponent,
    ProductInputComponent,
    TagInputComponent,
    SelectComponent,
    TagsLibraryComponent,
    CountryInputComponent,
    TransfersComponent,
    TransferComponent,
    StockInputComponent,
    PhoneInputComponent,
    ChooseQuantityComponent,
    ZoneComponent,
    VendorComponent,
    TimeComponent,
    CommentComponent,
    OrderComponent,
    PublishComponent,
    PreviewComponent,
    SenderComponent,
    ProfileComponent,
    PositionComponent,
    EnabledRenderComponent,
    FavoriteRenderComponent,
    DateRenderComponent,
    DateAgoRenderComponent,
    NumberRenderComponent,
    BooleanRenderComponent,
    PriceRenderComponent,
    SelectedRenderComponent,
    NameRenderComponent,
    ListRenderComponent,
    TitleRenderComponent,
    StandardRenderComponent,
    HintComponent,
    AbsolutizeUrlPipe,
    MediaThumbnailPipe,
    HighlightPipe,
    HumanizeSizePipe,
    FilterPipe,
    TitleInputComponent,
    ColorInputComponent,
    ThumbnailInputComponent,
    MediaInputComponent,
    CellInputComponent,
    DemoComponent,
    MediaBlockComponent,
    SmartFilterComponent,
    MySmartTableComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxBootstrapMultiselectModule,
    NgSelectModule,
    Ng2CompleterModule,
    NguiAutoCompleteModule,
    DropzoneModule,
    TinymceModule.withConfig({ baseURL: '/assets/tinymce' }),
    Ng2SmartTableModule,
    TranslateModule,
    TreeModule,
    DpDatePickerModule,
    MomentModule,
    NgxCurrencyModule,
    DragulaModule.forRoot(),
    HotTableModule,
    AngularCropperjsModule,
  ],
  providers: [CanDeactivateGuard, NavService, WINDOW_PROVIDERS, MediaThumbnailPipe],
  exports: [
    CurrencyDirective,
    FeatherIconsComponent,
    FileUploaderComponent,
    FileUploaderInlineComponent,
    ProductComponent,
    ParameterComponent,
    VariationComponent,
    VariationsComponent,
    PropertyComponent,
    RateComponent,
    PricesComponent,
    PriceComponent,
    TagComponent,
    BlockComponent,
    ConfirmComponent,
    RenameComponent,
    PostComponent,
    PostsComponent,
    OptionComponent,
    ValueComponent,
    CouponComponent,
    FormComponent,
    MessageComponent,
    MenuComponent,
    FileComponent,
    FilesComponent,
    ImageComponent,
    ImagesComponent,
    MediaLibraryComponent,
    MediaComponent,
    TransportComponent,
    TransfersComponent,
    TransferComponent,
    ServiceComponent,
    TagsLibraryComponent,
    CategoryInputComponent,
    ProductInputComponent,
    CountryInputComponent,
    PhoneInputComponent,
    TagInputComponent,
    SelectComponent,
    TitleInputComponent,
    ColorInputComponent,
    ThumbnailInputComponent,
    MediaInputComponent,
    CellInputComponent,
    StockInputComponent,
    ChooseQuantityComponent,
    ZoneComponent,
    VendorComponent,
    TimeComponent,
    CommentComponent,
    SenderComponent,
    ProfileComponent,
    PositionComponent,
    EnabledRenderComponent,
    FavoriteRenderComponent,
    DateRenderComponent,
    DateAgoRenderComponent,
    NumberRenderComponent,
    BooleanRenderComponent,
    PriceRenderComponent,
    SelectedRenderComponent,
    NameRenderComponent,
    ListRenderComponent,
    TitleRenderComponent,
    StandardRenderComponent,
    AbsolutizeUrlPipe,
    MediaThumbnailPipe,
    HighlightPipe,
    HumanizeSizePipe,
    FilterPipe,
    OrderComponent,
    PublishComponent,
    PreviewComponent,
    HintComponent,
    DemoComponent,
    MediaBlockComponent,
    SmartFilterComponent,
    MySmartTableComponent,
    MySmartTableComponent,
  ],
})
export class SharedModule {}
