<form class="needs-validation" [formGroup]="form">
  <div class="form-group">
    <label for="name" class="mb-1" translate>Name</label>:
    <input type="text" id="name" class="form-control" formControlName="Name">
  </div>
  <div class="form-group">
    <label for="email" class="mb-1" translate>Email</label>:
    <input type="text" id="email" class="form-control" formControlName="Email">
  </div>
  <div class="form-group">
    <label for="data" class="mb-1" translate>Data</label>:
    <textarea id="data" class="form-control" rows="5" placeholder='{}' formControlName="Data"></textarea>
    <div class="description" translate>
      Place data you want to use for testing
    </div>
  </div>
  <div style="text-align: center">
    <button type="button" class="btn btn-secondary" [disabled]="(!form.valid || !form.dirty)" (click)="onSend()" translate>Send</button>
  </div>
</form>
