<div class="tag-input">
  <div>
    <input type="text" class="form-control" style="width: 100%" name="tag" [(ngModel)]="input" [ngModelOptions]="{standalone: true}" (keyup.enter)="add()" (selectItem)="select($event)" [inputFormatter]="formatter" [resultFormatter]="formatter" [ngbTypeahead]="search" [editable]='false' [resultTemplate]="rt" autocomplete="off" [placeholder]="'summer, SALE, etc' | translate"/>
    <ng-template #rt let-r="result" let-t="term">
      <span *ngIf="r.ID == 0">Add new: </span>
      <ngb-highlight [result]="r.Title" [term]="t"></ngb-highlight>
    </ng-template>
  </div>
  <div *ngIf="selected && selected.length > 0">
    <div *ngFor="let tag of selected; let i = index;" class="tag">
      <a class="link" [routerLink]="['/tags/' + tag.ID]" style="cursor: pointer">{{tag.Title}}</a>
      <span (click)="remove(tag)">&times;</span>
    </div>
  </div>
</div>
