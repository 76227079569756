import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'app-color-input',
  template: `<div class="input-color">
    <input class="form-control" type="input" [placeholder]="'enter hex code or select color' | translate" (change)="change(color)" [(ngModel)]="color">
    <div class="input-color-tail">
      <input class="form-control" id="color" type="color" (change)="change(color)" [(ngModel)]="color">
    </div>
  </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ColorInputComponent
    }
  ]
})
export class ColorInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() input;
  @Input() name: string;
  color;
  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  expanded = false;

  ngOnInit(): void { }

  toggle() {
    this.expanded = !this.expanded;
  }

  change(value) {
    this.color = value;
    this.markAsTouched();
    this.onChange(this.color);
  }

  writeValue(value) {
    this.color = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
