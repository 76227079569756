import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';

@Component({
  selector: 'app-settings-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class TaxComponent implements OnInit {
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe) { }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getHugoSettings().then(resp => {
      those.form = this.formBuilder.group({
        VAT: [resp.VAT ? resp.VAT : '19', Validators.required],
      });
    }).catch(err => {
      those.error('Load settings', err);
    });
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putSettings('tax', raw).then(resp => {
      those.success('Update settings', resp);
    }).catch(err => {
      those.error('Update settings', err);
    });
  }
}
