import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {TranslateModule} from '@ngx-translate/core';
import {VendorsComponent} from './vendors.component';
import {VendorComponent} from './vendor/vendor.component';
import {VendorsRoutingModule} from './vendors-routing.module';
import {TinymceModule} from 'angular2-tinymce';
import {TimeComponent} from './vendor/time/time.component';
import {NgxBootstrapMultiselectModule} from 'ngx-bootstrap-multiselect';

@NgModule({
  declarations: [VendorsComponent, VendorComponent, TimeComponent],
  imports: [
    CommonModule,
    VendorsRoutingModule,
    NgbModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapMultiselectModule,
    SharedModule,
    TranslateModule,
    TinymceModule.withConfig({ baseURL: '/assets/tinymce'}),
  ],
  entryComponents: [ ],
  exports: [ ]
})
export class VendorsModule { }
