import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {AbsolutizeUrlPipe, MediaThumbnailPipe} from '../../../shared/utils.pipe';
import { ApiService } from '../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DragulaService } from 'ng2-dragula';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-variations-block',
  styles: [
    `
      .table-variations th:nth-child(1),
      .table-variations td:nth-child(1) {
        /*width: 25px;*/
        padding: 10px;
      }
      .table-variations td:nth-child(4) > span:before {
        vertical-align: middle;
      }
      .buttons {
        display: flex;
        justify-content: end;
        height: 36px;
      }
      .buttons > div {
        display: flex;
        align-items: center;
      }
    `,
  ],
  template: `
    <div class="card">
      <div class="card-body" style="padding-bottom: 10px">
        <div class="row">
          <div class="col">
            <h2 style="margin-bottom: 20px" translate>Variations</h2>
          </div>
        </div>
        <app-edit-variations
          [productId]="productId"
          (onSaved)="refresh('variations')"
          #variationsEditor></app-edit-variations>
        <div class="variations">
          <div class="row">
            <div class="col">
              <label>
                <input
                  class="checkbox_animated"
                  type="checkbox"
                  (change)="change($event.target['value'])"
                  [formControl]="containerFormControl" />
                {{ 'This product has variations' | translate }}
              </label>
              <span
                class="tooltip-question"
                style="padding-left: 5px;vertical-align: sub;"
                placement="top"
                [ngbTooltip]="
                  'If you select variation all previously entered information goes into the first variation' | translate
                ">
                <svg width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                </svg>
              </span>
            </div>
          </div>

          <div *ngIf="containerFormControl.value">
            <div *ngIf="variations">
              <div class="row">
                <div class="col">
                  <label class="mb-1" translate>Type</label>
                </div>
              </div>
              <div class="row" style="margin-bottom: 20px;">
                <div class="col">
                  <app-select
                    [options]="[
                      { Name: 'select', Label: 'Select' },
                      { Name: 'rectangle', Label: 'Rectangle' },
                      { Name: 'swatch', Label: 'Swatch' },
                      { Name: 'radio', Label: 'Radio' }
                    ]"
                    [formControl]="typeFormControl"></app-select>
                </div>
                <div class="col">
                  <app-select
                    *ngIf="['radio', 'swatch'].indexOf(typeFormControl.value) >= 0"
                    [options]="[
                      { Name: 'small', Label: 'Small' },
                      { Name: 'medium', Label: 'Medium' },
                      { Name: 'large', Label: 'Large' }
                    ]"
                    [formControl]="sizeFormControl"></app-select>
                </div>
              </div>
              <!--div class="row" style="padding-bottom: 20px;">
                <div class="col-md-6">
                  <div class="search-block">
                    <input
                      class="form-control search"
                      style="float: right;"
                      placeholder="{{ 'search' | translate }}"
                      (keyup)="filter($event.target['value'])"
                      style="height: 38px" />
                  </div>
                </div>
              </div-->
              <div class="card-fluid-block mb-2">
                <table class="table table-variations mb-0">
                  <thead>
                    <tr>
                      <th style="max-width: 35px;"></th>
                      <th translate>Name</th>
                      <th translate>Display Name</th>
                      <th style="min-width: 110px; text-align: center"></th>
                    </tr>
                  </thead>
                  <tbody
                    dragula="variations"
                    [(dragulaModel)]="variations"
                    (dragulaModelChange)="changeVariations($event)">
                    <tr
                      *ngFor="let variation of variations"
                      class="draggable-wrapper"
                      [ngClass]="{ hidden: variation['filtered'] == false }"
                      data-id="{{ variation['ID'] }}">
                      <td style="max-width: 35px; vertical-align: middle">
                        <div class="btn-sm btn-default" style="display: flex">
                          <div style="height: 16px;">
                            <svg class="fill-black draggable draggable-point" style="height: 16px; width: 16px;">
                              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-bid"></use>
                            </svg>
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: middle">
                        <div style="display: flex; align-items: center;">
                          <div
                            [style]="{
                              backgroundImage:
                                'url(' +
                                mediaThumbnail.transform(variation['Media']) + ')',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              cursor: 'pointer',
                              display: 'inline-block',
                              height: '35px',
                              minWidth: '35px',
                              width: '35px',
                              marginRight: '10px',
                              verticalAlign: 'middle'
                            }"
                            (click)="edit(variation['ID'])"></div>
                          <div
                            class="color-primary-violet"
                            style="cursor: pointer;display:inline-block;vertical-align: middle"
                            (click)="edit(variation['ID'])">
                            {{ variation['Name'] }}
                          </div>
                        </div>
                      </td>
                      <td style="vertical-align: middle;">{{ variation['Title'] }}</td>
                      <td style="vertical-align: middle">
                        <div class="buttons">
                          <div class="btn-sm btn-default" (click)="onCloneVariation(variation)">
                            <svg style="height: 22px; width: 22px;">
                              <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiDuplicate"></use>
                            </svg>
                          </div>
                          <div class="btn-sm btn-default delete" (click)="delete(variation['ID'])">
                            <svg style="height: 16px; width: 16px;">
                              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                            </svg>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col">
                  <app-edit-variation [productId]="productId" (onSaved)="refresh('variations')" #variationEditor>
                    <span class="btn btn-third facon-plus" (click)="onAddVariation()" translate>Add Variation</span>
                  </app-edit-variation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class VariationsBlockComponent implements OnInit, OnDestroy {
  @Input('productId') productId = 0;
  @Input('variations') variations;
  @Input('dimensionUnit') dimensionUnit = 'cm';
  @Input('weightUnit') weightUnit = 'kg';
  @Input('form') form: FormControl;
  @Input('containerFormControl') containerFormControl: FormControl;
  @Input('typeFormControl') typeFormControl: FormControl;
  @Input('sizeFormControl') sizeFormControl: FormControl;
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();
  term = '';
  filtered = 0;
  dragulaSubscription = new Subscription();
  timeout;

  constructor(
    public router: Router,
    private apiService: ApiService,
    private dragulaService: DragulaService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    public mediaThumbnail: MediaThumbnailPipe,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    const those = this;
    this.dragulaService.createGroup('variations', {
      moves: (el, container, handle) => {
        const classes =
          (handle.className &&
            handle.className['baseVal'] &&
            handle.className['baseVal']['split'] &&
            handle.className['baseVal'].split(/\s+/)) ||
          [];
        if (classes.indexOf('draggable') !== -1) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
    this.dragulaSubscription.add(
      this.dragulaService.drop('variations').subscribe(({ name, el, sibling }) => {
        if (el) {
          let element = el.parentNode.firstChild;
          do {
            if (element.nodeType === 3) continue; // text node
            const classList = (element as Element).classList;
            if (classList && classList.contains('draggable-wrapper')) {
              if (classList.contains('gu-transit')) {
                classList.add('draggable-dropped');
              } else {
                classList.remove('draggable-dropped');
              }
            }
          } while ((element = element.nextSibling));
          if (those.timeout) {
            clearTimeout(those.timeout);
            those.timeout = undefined;
          }
          those.timeout = setTimeout(() => {
            el?.classList?.remove('draggable-dropped');
          }, 10000);
        }
        (async function loop() {
          for (let i = 0; i < those.variations.length; i++) {
            if (those.variations[i]['ID'] && those.variations[i]['Sort'] !== i + 1) {
              console.log('id', those.variations[i]['ID'], 'setSort', { Value: i + 1 });
              await new Promise(resolve =>
                those.apiService.patchVariation(those.variations[i]['ID'], 'setSort', { Value: i + 1 }).then(resp => {
                  those.variations[i]['Sort'] = i + 1;
                  resolve();
                })
              );
            }
          }
        })();
      })
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('variations');
    this.dragulaSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  refresh(type?) {
    this.onSaved.emit(type);
  }

  change(value) {
    const those = this;
    if (value === 'on') {
      const modal = those.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Information';
      modal.componentInstance.body = 'All previously entered information goes into the first variation';
      modal.componentInstance.confirm = 'Continue';
      modal.result.then(
        result => {
          those.containerFormControl.setValue(true);
          those.onSaved.emit(true);
        },
        reason => {
          those.containerFormControl.setValue(false);
        }
      );
    }
  }

  filter(term) {
    this.term = term.toLowerCase();
    const terms = this.term
      .toLowerCase()
      .split(/;\s*/g)
      .filter(item => item);
    //
    this.variations.forEach((item, i) => {
      let allowed = this.term === '';
      if (terms.filter(item2 => this.variations[i].Name.toLowerCase().indexOf(item2) >= 0).length > 0) {
        allowed = true;
      }
      if (terms.filter(item2 => this.variations[i].Title.toLowerCase().indexOf(item2) >= 0).length > 0) {
        allowed = true;
      }
      this.variations[i].filtered = allowed;
    });
  }

  changeVariations(event) {}

  edit(id) {
    this.router.navigateByUrl('/products/' + this.productId + '/variations/' + id);
  }

  delete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete variation?';
    modal.componentInstance.body = `Are you sure you want to delete variation? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVariation(id)
          .then(resp => {
            const index = those.variations.findIndex(item => item.ID === id);
            if (index >= 0) {
              those.variations.splice(index, 1);
            }
            if (those.variations.length === 0) {
              those.containerFormControl.setValue(false);
              those.onSaved.emit(true);
            }
          })
          .catch(err => {
            those.error('Delete variation', err);
          });
      },
      reason => {}
    );
  }

  onAddVariation() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', 'true');
    data.append('ProductId', String(this.productId));
    data.append('Sort', this.variations && this.variations.length > 0 ? this.variations.length + 1 : 1);
    this.apiService
      .postVariation(data)
      .then(variation => {
        those.router.navigate(['/products/' + those.productId + '/variations', variation.ID]);
      })
      .catch(err => {
        those.error('Create product', err);
      });
  }

  onCloneVariation(variation) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Clone variation?';
    modal.componentInstance.body = 'Are you sure you want to clone variation?';
    modal.componentInstance.confirm = 'Clone';
    modal.result.then(
      result => {
        let name = variation.Name;
        let title = variation.Title;
        for (;;) {
          let found = false;
          for (let i = 0; i < this.variations.length; i++) {
            if (this.variations[i].Name === name) {
              found = true;
            }
          }
          if (found) {
            const res = name.match(/\((\d+)\)$/);
            if (res) {
              name.replace(new RegExp('\\(' + res[1] + '\\)$', ''), '(' + (res[1] + 1) + ')');
              title.replace(new RegExp('\\(' + res[1] + '\\)$', ''), '(' + (res[1] + 1) + ')');
            } else {
              name += ' (2)';
              title += ' (2)';
            }
          } else {
            break;
          }
        }
        those.apiService
          .patchVariation(variation.ID, 'clone', {
            Name: name,
            Title: title,
          })
          .then((resp: { ID: number }) => {
            those.form.markAsPristine();
            those.form.markAsUntouched();
            those.router.navigate(['/products/' + those.productId + '/variations/' + resp.ID]);
          })
          .catch(err => {
            those.error('Clone variation', err);
          });
      },
      reason => {}
    );
  }
}
