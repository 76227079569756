import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../shared/api.service';
import { SubjectService } from '../../../shared/subject.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class AccountComponent implements OnInit, OnDestroy {
  info;
  subscription: Subscription;
  form;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private subjectService: SubjectService,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const those = this;
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      those.info = info;
      if (!those.form) {
        those.form = those.formBuilder.group({
          Name: [info.User?.Name ? info.User?.Name : ''],
          Lastname: [info.User?.Lastname ? info.User?.Lastname : ''],
          Thumbnail: [info.User?.Thumbnail ? info.User?.Thumbnail : ''],
          OldPassword: [''],
          NewPassword: ['', [those.passwordValidator.bind(this)]],
          NewPassword2: ['', [those.passwordValidator2.bind(this)]],
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(this.translate.instant(message), this.translate.instant(title));
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  passwordValidator(control: FormControl): { [s: string]: boolean } {
    const value = control.value.trim();
    if (!value) {
      return null;
    }
    if (value.length < 6) {
      return { shortPassword: true };
    }
    if (value.length > 32) {
      return { longPassword: true };
    }
    if (!value.match(/[0-9]+/)) {
      return { noDigits: true };
    }
    if (!value.match(/[A-Z]+/)) {
      return { noUpper: true };
    }
    if (!value.match(/[a-z]+/)) {
      return { noLower: true };
    }
    return null;
  }

  passwordValidator2(control: FormControl): { [s: string]: boolean } {
    if (this.form && control.value !== this.form.get('NewPassword').value) {
      return { mismatch: true };
    }
    return null;
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Name', this.form.get('Name').value);
    data.append('Lastname', this.form.get('Lastname').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('OldPassword', this.form.get('OldPassword').value);
    data.append('NewPassword', this.form.get('NewPassword').value);
    data.append('NewPassword2', this.form.get('NewPassword2').value);
    this.apiService
      .putAccountSettings(data)
      .then(resp => {
        those.success('Update account settings', resp);
        if (those.form.get('OldPassword').value !== those.form.get('NewPassword').value) {
          those.router.navigate(['/auth/login']);
        }
      })
      .catch(err => {
        those.error('Update account settings', err);
      });
  }
}
