import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-tier-inline',
  template: `
    <div>
      <div class="row" style="padding-bottom: 10px;">
        <div class="col">
          <label class="mb-1" translate>MinQuantity</label>
          <div><input class="form-control" type="number" [(ngModel)]="tier['MinQuantity']" [ngModelOptions]="{standalone: true}"></div>
        </div>
        <div class="col">
          <label class="mb-1" translate>Discount</label>
          <div><input class="form-control" type="text" [(ngModel)]="tier['Discount']" [ngModelOptions]="{standalone: true}"></div>
        </div>
      </div>
    </div>
  `
})
export class TierInlineComponent implements OnInit {
  @Input('tier') tier;

  constructor() {

  }

  ngOnInit() {

  }
}
