import { Component, Input } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div>
      <a class="link" [routerLink]="['/sales/orders']" [queryParams]="{search: 'coupon:' + rowData['ID']}" style="cursor: pointer" [innerHtml]="rowData['Discounts'] + (rowData['Count'] ? ( ' ' + ('of' | translate) + ' ' + rowData['Count']) : '')"></a>
    </div>
  `,
})
export class CouponUsedRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;

}
