import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-stock-input',
  templateUrl: './stock-input.component.html',
  styleUrls: ['./stock-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StockInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: StockInputComponent
    }
  ]
})
export class StockInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() quantity;
  description;
  onChange = (selected) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor(private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    const those = this;
    console.log('ngOnInit');
    /*this.apiService.getTags().then(tags => {
      those.tags = tags;
    }).catch(err => {
      this.error('Load tags', err);
    });*/
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  writeValue(value) {
    console.log('writeValue', value);
    //this.selected = selected;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
