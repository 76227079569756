import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [`
    div>a{
        padding-right: 5px;
    }
  `],
  template: `
    <div>{{value}}</div>
  `,
})
export class ProductDescriptionRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;

}
