<ng-content></ng-content>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title body-2" id="exampleModalLabel">
      {{(media ? 'Edit Media' : 'Add Media') | translate}}
    </h5>
    <div>
      <div class="button-icon" [ngClass]="{disabled: !hasPrev}" (click)="prev()">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="button-icon" [ngClass]="{disabled: !hasNext}" (click)="next()">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 18L15 12L9 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
        <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5"/>
          <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5"/>
        </svg>
      </div>
    </div>
  </div>
  <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body media-modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="url" class="mb-1" translate>URL</label>
            <div style="display: flex; position: relative;">
              <input class="form-control" id="url" type="text" autocomplete="off" readonly="on" [(ngModel)]="url" [ngModelOptions]="{standalone: true}">
              <div style="padding-left: 5px">
                <button type="button" class="btn btn-copy" style="height: 43px; width: 43px;padding: 3px 0 0 0;" (click)="onCopy()">
                  <svg width="12px" height="12px">
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="{'col-md-5': id && !edit, 'col-md-10': !id || edit, 'offset-md-1': !id || edit}">
          <div class="form-group mb-0" style="height: 100%; padding: 10px 0;">
            <div
              *ngIf="form.controls['Body'] && form.controls['Body'].value && !form.controls['Body'].touched"
              style="border-radius: 5px;margin-bottom: 5px;position: relative;text-align: center;">
              <div *ngIf="edit">
                <angular-cropper [cropperOptions]="config" [imageUrl]="url" #cropper></angular-cropper>
                <div style="display: flex; gap: 5px; padding: 5px 0; justify-content: center;">
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onRotate(cropper, -90)">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiRotateLeft"></use>
                    </svg>
                  </div>
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onRotate(cropper, 90)">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiRotateRight"></use>
                    </svg>
                  </div>
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onFlip(cropper, 'horizontal')">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiMoveHorizontal"></use>
                    </svg>
                  </div>
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onFlip(cropper, 'vertical')">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiMoveVertical"></use>
                    </svg>
                  </div>
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onCrop(cropper)">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                    </svg>
                  </div>
                  <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onCancel(cropper)">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiXCircle"></use>
                    </svg>
                  </div>
                </div>
              </div>
              <div *ngIf="!edit">
                <img
                  [src]="preview"
                  style="border-radius: 5px;margin-bottom: 5px;width:100%">
                <div style="position: absolute;left: 5px;top: 5px;padding: 5px 5px 2px 5px; display: flex; flex-direction: column; gap: 5px;">
                  <a [href]="form.controls['Body'].value" target="_blank">
                    <div class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;">
                      <svg style="width: 16px; height: 16px; fill: white; stroke: white">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize"></use>
                      </svg>
                    </div>
                  </a>
                  <div *ngIf="media && media.ContentType.indexOf('image/') === 0" class="btn btn-sm btn-default bgcolor-primary-violet" style="display: flex; padding: 5px 10px;" (click)="onEditToggle()">
                    <svg style="width: 16px; height: 16px; fill: white; stroke: white">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-edit"></use>
                    </svg>
                  </div>
                </div>
                <div style="cursor: pointer; position: absolute; right: -10px;top: -10px;border: 2px solid gray;border-radius: 50%;background-color: white; width: 20px;line-height: 1;"
                     (click)="form.controls['Body'].value = ''">&times;
                </div>
              </div>
            </div>
            <app-file-uploader *ngIf="!form.controls['Body'].value || form.controls['Body'].touched" [types]="[]" [message]="'Drag to start uploading'" [(files)]="files" class="flat" (filesLoaded)="upload($event)"></app-file-uploader>
          </div>
        </div>
        <div *ngIf="media" class="properties" [ngClass]="{'col-md-7': !edit, 'hidden': edit}">
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>ID</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.ID}}</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Name</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular name-value" [title]="media.Name">{{media.Name}}</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>ContentType</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.ContentType}}</div>
            </div>
          </div>
          <div *ngIf="media.Width && media.Height" class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Dimensions</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.Width}} x {{media.Height}}</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Size</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.Size | humanizeSize}}</div>
            </div>
          </div>
          <div *ngIf="media.Orig" class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Original</div>
            </div>
            <div class="col-md-8">
              <a class="body-4-regular name-value" [href]="media.Orig" [title]="media.Orig" target="_blank">{{media.Orig}}</a>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Created</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.CreatedAt | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Updated</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.UpdatedAt | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</div>
            </div>
          </div>
          <div *ngIf="media.User" class="row mb-2">
            <div class="col-md-4" style="text-align: right">
              <div class="body-4-regular" translate>Uploaded by</div>
            </div>
            <div class="col-md-8">
              <div class="body-4-regular">{{media.User.Login}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="title" class="mb-1" translate>Title</label>
            <input class="form-control" id="title" type="text" [placeholder]="'Enter here your new title' | translate" formControlName="Title">
          </div>
          <div class="form-group">
            <label for="alt" class="mb-1" translate>Alternative text</label>
            <input class="form-control" id="alt" type="text" [placeholder]="'This text will be used by screen readers, search engines, or when the image cannot be loaded.' | translate" formControlName="Alt">
          </div>
          <div class="form-group">
            <label for="caption" class="mb-1" translate>Caption</label>
            <textarea class="form-control" id="caption" rows="3" [placeholder]="'Enter here your caption or legend describing the rest of the contents of its parent element. It is the small block of descriptive text that shows up below your graphic.' | translate" formControlName="Caption"></textarea>
          </div>
          <div class="form-group">
            <label for="description" class="mb-1" translate>Description</label>
            <textarea class="form-control" id="description" [placeholder]="'Enter here your description. This is the descriptive text that shows up on your image attachment page.' | translate" formControlName="Description"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="justify-content: normal;align-self: flex-end;">
      <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="!form.valid" translate>
        <svg>
          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
        </svg>
        <span>Save</span>
      </button>
      <button class="btn btn-cancel" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')" translate>Cancel
      </button>
      <button *ngIf="media" class="btn btn-sm btn-default delete" type="button" style="padding: 8px;" (click)="onDelete(media.ID)">
        <svg style="height: 18px; width: 18px">
          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
        </svg>
      </button>
    </div>
  </form>
  <div *ngIf="!form" class="modal-body media-modal-body">
    <div class="row">
      <div class="col" style="min-height: 50px;">
        <div style="position: absolute; top:5px; text-align: center; width: 100%"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
      </div>
    </div>
  </div>
</ng-template>
