import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class PostsDataSource extends LocalDataSource {
  data: any[];
  lastRequestShown = 0;
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;

  parent: any;
  local = false;
  callback;
  filtering;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  shown(): number {
    return this.lastRequestShown;
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    this.pagingConf['page'] = page || 1;
    this.pagingConf['perPage'] = perPage || 10;
    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    const those = this;
    // Fix duplicates requests
    if (
      this.parent &&
      this.filtering &&
      JSON.stringify({ filterConf: those.parent.filterConf, sortConf: those.parent.sortConf }) !=
        JSON.stringify({ filterConf: those.filterConf, sortConf: those.sortConf })
    ) {
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    // Local sort refresh
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    //
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const request = {};
    if (this.parent.term) {
      request['Search'] = this.parent.term;
    }
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      this.localStorageService.set('posts_page', JSON.stringify(this.pagingConf['page']));
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
        }
      });
    }
    this.parent.visible = false;
    // Inject some params
    const url = new URL(this.apiService.getUrl() + '/posts/list');
    try {
      const curr = new URL(window.location.toString());
      curr.searchParams.forEach((value, key) => {
        if (['cid'].indexOf(key) >= 0) {
          url.searchParams.set(key, value);
        }
      });
    } catch (err) {}
    //
    return this.http
      .post(url.toString(), request, { headers: headersObject, withCredentials: true })
      .pipe(
        map(response => {
          this.lastRequestShown = +response['Data']?.length;
          this.lastRequestFiltered = +response['Filtered'];
          this.lastRequestCount = +response['Filtered'];
          this.lastRequestTotal = +response['Total'];
          this.parent.visible = true;
          this.data = (response['Data'] || []).map(item => {
            item['Selected'] = false;
            return item;
          });
          if (those.callback && typeof those.callback === 'function') {
            setTimeout(() => {
              those.callback.call(those);
            }, 1000);
          }
          return this.data;
        })
      )
      .toPromise();
  }
}
