import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-menu',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!menu || !menu['ID']" class="modal-title" id="exampleModalLabel" translate>Add Menu</h5>
        <h5 *ngIf="menu && menu['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Menu</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label class="d-block" for="enabled">
                <input class="checkbox_animated" id="enabled" type="checkbox" formControlName="Enabled" />
                {{ 'Enabled' | translate }}
              </label>
            </div>
            <div class="form-group">
              <label for="title" class="mb-1" translate>Title</label>:
              <input class="form-control" id="title" type="text" (keyup)="onChange($event)" formControlName="Title" />
            </div>
            <div class="form-group">
              <label for="name" class="mb-1" translate>Name</label>:
              <input class="form-control" id="name" type="text" formControlName="Name" />
            </div>
            <div class="form-group">
              <label for="location" class="mb-1" translate>Location</label>:
              <select class="form-control" formControlName="Location">
                <option value="header">Header</option>
                <option value="filter">Filter</option>
                <option value="card">Card</option>
                <option value="footer">Footer</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-sort: bolder;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class MenuComponent implements OnInit {
  @Input('vendorId') vendorId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  menu;
  modal;
  closeResult;
  form;
  categoriesMenus: IMultiSelectOption[] = [];
  categoriesSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    isLazyLoad: true,
  };
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  onChange(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content, menus?) {
    const those = this;
    //console.log('moment', moment);
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Location: ['', Validators.required],
    });
    //
    those.modal = those.modalService.open(content, { ...menus, ...{ ariaLabelledBy: 'modal-basic-title' } });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getMenu(id)
      .then(menu => {
        those.menu = menu;
        those.form = those.formBuilder.group({
          ID: [menu['ID']],
          Enabled: [menu['Enabled'] ? true : false],
          Name: [menu['Name'], Validators.required],
          Title: [menu['Title'], Validators.required],
          Location: [menu['Location'], Validators.required],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load menu', err);
      });
  }

  onCustom(id) {
    console.log('id', id);
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete menu?';
    modal.componentInstance.body = `Are you sure you want to delete menu? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteMenu(id)
          .then(resp => {
            those.success('Delete menu', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete menu', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = those.form.getRawValue();
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postMenu(raw, { vid: this.vendorId })
        .then(resp => {
          those.success('Create menu', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Create menu', err);
        });
    } else {
      // update
      this.apiService
        .putMenu(this.form.get('ID').value, { ...this.menu, ...raw })
        .then(resp => {
          those.success('Update menu', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update menu', err);
        });
    }
  }
}
