import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../../../shared/api.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {FilePathRenderComponent} from '../../../components/medias/files/file-path-render.components';
import {FileSizeRenderComponent} from '../../../components/medias/files/file-size-render.component';
import {FilesDataSource} from '../../../components/medias/files/files.data-source';
import {DateRenderComponent} from '../../render/date-render.component';

@Component({
  selector: 'app-edit-files',
  template: `
      <ng-content></ng-content>
      <ng-template #content let-modal>
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel" translate>
                  Media Library
              </h5>
              <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
            <div class="btn-popup pull-right">
              <app-edit-file [productId]="id" #editor>
              </app-edit-file>
            </div>
            <div id="batchDelete" class="files-table custom-datatable">
              <div class="table-responsive" [ngClass]="!visible?'transparent':''">
                <ng2-smart-table (custom)="onCustomAction($event.data['ID'])" [settings]="settings" [source]="source"></ng2-smart-table>
                <div *ngIf="source && source.count() > 0" class="records-count">
            <span *ngIf="source.filtered() != source.total()">
              {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
            </span>
                  <span *ngIf="source.filtered() == source.total()">
              {{'Records' | translate}}: {{source.total()}}
            </span>
                </div>
              </div>
              <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
            </div>
          </div>
      </ng-template>
  `,
  styles: [`span {
        font-weight: bolder;
    }
  `],
  providers: [ AbsolutizeUrlPipe ]
})
export class FilesComponent implements OnInit, AfterViewInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  id;
  file;
  modal;
  closeResult;
  timer;
  source: FilesDataSource;
  settings;
  visible = false;

  constructor(protected http: HttpClient, private apiService: ApiService, private translate: TranslateService, private modalService: NgbModal, private formBuilder: FormBuilder, private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe) {

  }

  ngOnInit(): void {
    this.settings = {
      mode: 'external',
      actions: {
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'yourAction',
            title: '<span class="btn btn-primary btn-xs">' + this.translate.instant('Add') + '</span>'
          },
        ],
        columnTitle: this.translate.instant('Actions'),
        position: 'right',
      },
      columns: {
        ID: {
          title: 'Id',
        },
        Created: {
          title: this.translate.instant('Created'),
          type: 'custom',
          renderComponent: DateRenderComponent,
        },
        Path: {
          title: this.translate.instant('Path'),
          type: 'custom',
          renderComponent: FilePathRenderComponent,
        },
        Type: {
          title: this.translate.instant('Type'),
        },
        Name: {
          title: this.translate.instant('Name'),
        },
        Size: {
          title: this.translate.instant('Size'),
          type: 'custom',
          renderComponent: FileSizeRenderComponent,
        },
      },
      pager: {
        display: true,
        perPage: 10,
      }
    };
    this.source = new FilesDataSource(this.http, this.apiService, this.absolutizeUrl);
    this.source.parent = this;
    //this.source.arguments = this.arguments;
  }

  ngAfterViewInit(): void {
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onOpen(content) {
    const those = this;
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then((result) => {
      console.log(`Closed with: ${result}`);
      those.closeResult = `Closed with: ${result}`;
      those.onSaved.emit();
    }, (reason) => {
      console.log(`Dismissed ${those.getDismissReason(reason)}`);
      those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      those.onSaved.emit();
    });
  }

  onCustomAction(id) {
    if (this.productId) {
      this.apiService.patchProduct(this.productId, 'addFile', {File: id}).then(resp => {
        this.success('Add file', resp);
        this.onSaved.emit();
      }).catch(err => {
        this.error(this.translate.instant('Add file'), err);
      });
    }else if (this.variationId){
      this.apiService.patchVariation(this.variationId, 'addFile', {File: id}).then(resp => {
        this.success('Add file', resp);
        this.onSaved.emit();
      }).catch(err => {
        this.error(this.translate.instant('Add file'), err);
      });
    }
  }
}
