import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SubjectService {
  breadcrumbsSubject = new BehaviorSubject({});
  infoSubject = new BehaviorSubject(undefined);
  searchSubject = new BehaviorSubject(undefined);
  // System wide event bus
  events = new Subject();
}
