import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { ApiService } from '../../../shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-parameter-inline',
  template: `
    <div class="row">
      <div class="col">
        <input
          *ngIf="parameter['DimensionId'] > 0 || parameter['DimensionId'] === undefined"
          type="text"
          class="form-control"
          (change)="change($event.target['value'])"
          (keyup)="check($event.target['value'])"
          [(ngModel)]="parameter['Title']" />
        <ngx-bootstrap-multiselect
          *ngIf="parameter['DimensionId'] === 0"
          id="options"
          class="multiselect single-selected"
          [options]="dimensionsOptions"
          [settings]="settings"
          (ngModelChange)="changeDimension($event)"
          [(ngModel)]="parameter['DimensionIds']"></ngx-bootstrap-multiselect>
      </div>
      <div class="col" style="display: flex;align-items: center;width: 100%;">
        <div style="flex: 1;">
          <ngx-bootstrap-multiselect
            *ngIf="parameter['DimensionId'] >= 0"
            id="options"
            class="multiselect single-selected"
            [options]="metricsOptions"
            [settings]="settings"
            (ngModelChange)="changeValue($event)"
            [(ngModel)]="metricId"></ngx-bootstrap-multiselect>
          <input
            *ngIf="parameter['DimensionId'] === undefined"
            type="text"
            class="form-control"
            (change)="changeCustomValue($event.target['value'])"
            [(ngModel)]="parameter['CustomValue']" />
          <span *ngIf="parameter['Filtering']" style="color: lightgray; float: right;" title="used in filter"
            ><i class="fa fa-filter" aria-hidden="true"></i
          ></span>
        </div>
        <div *ngIf="parameter['Title'] !== ''" style="width: 38px;text-align: right;">
          <span
            class="btn-sm btn-default facon-minus"
            style="font-size: 16px;"
            title="delete"
            (click)="deleteParameter(parameter['ID'])"></span>
        </div>
      </div>
    </div>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class ParameterInlineComponent implements OnInit {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('parameters') parameters = [];
  @Input('parametersFormControl') parametersFormControl: FormControl;
  @Input('parameter') parameter;
  @Input('dimensions') dimensions;
  @Input('index') index;
  @Input('parent') parent;

  dimensionsOptions;

  settings: IMultiSelectSettings = {
    autoUnselect: true,
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'fontawesome',
    selectionLimit: 1,
    closeOnSelect: true,
  };
  metricsOptions: IMultiSelectOption[];

  first = true;
  metricId;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const those = this;
    if (this.dimensions?.length > 0) {
      those.dimensionsOptions = [];
      those.dimensionsOptions.push(
        ...this.dimensions.map(item => {
          return { id: item['ID'], name: item['Title'] + ' (' + item['Name'] + ') ' };
        })
      );
    }
    this.metricId = [this.parameter.MetricId];
    if (this.parameter.DimensionId) {
      this.apiService
        .getMetrics(this.parameter.DimensionId)
        .then(resp => {
          this.metricsOptions = resp.map(item => {
            return { id: item.ID, name: item.Title };
          });
        })
        .catch(err => {
          this.error('Load values', err);
        });
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  check(value) {
    console.log('check', value);
    if (value && this.index === this.parameters.length - 1) {
      this.parameters = this.parameters || [];
      if (this.parameters.filter(item => item.Title === '' && item.DimensionId === undefined).length === 0) {
        this.parameters.push({ Title: '', DimensionId: undefined, CustomValue: '' });
      }
    }
    this.parametersFormControl.markAllAsTouched();
    this.parametersFormControl.updateValueAndValidity();
    this.parametersFormControl.markAsDirty();
  }

  change(value) {
    if (!this.parameter.ID || !this.parameter.DimensionId) {
      this.parameter.Name = value.toLowerCase() /*.replace(/ö/ig, 'oe')
        .replace(/ü/ig, 'ue').replace(/ä/ig, 'ae')
        .replace(/ß/ig, 'ss').replace(/[^-a-z0-9\(\)]+/ig, '-')*/;
    }
    this.parametersFormControl.markAllAsTouched();
    this.parametersFormControl.updateValueAndValidity();
    this.parametersFormControl.markAsDirty();
  }

  changeDimension(event) {
    const those = this;
    if (event?.length > 0) {
      const index = this.dimensions.findIndex(item => item.ID === event[0]);
      if (index >= 0) {
        const dimension = this.dimensions[index];
        const metrics = dimension.Metrics;
        if (metrics?.length > 0) {
          this.metricsOptions = metrics.map(item => {
            return { id: item.ID, name: item.Title };
          });
          if (dimension.Default) {
            setTimeout(() => {
              those.metricId = [dimension.Default];
              those.changeValue(those.metricId);
            }, 100);
          }
        }
      }
    }
  }

  changeValue(event) {
    const those = this;
    if (event && event.length > 0 && event[0] > 0 && this.parameter.MetricId !== event[0]) {
      if (!this.parameter.ID) {
        // create
        const dimension = this.dimensions.find(item => item.ID === those.parameter.DimensionIds[0]);
        this.apiService
          .postParameter({
            ...this.parameter,
            ...{
              ProductId: those.productId,
              VariationId: those.variationId,
              Name: dimension.Name,
              Title: dimension.Title,
              DimensionId: dimension.ID,
              MetricId: event[0],
            },
          })
          .then(parameter => {
            those.parameter = parameter;
            those.parent.addParameter({ Title: '', DimensionId: 0, CustomValue: '' }, true);
          })
          .catch(err => {
            those.error('Create parameter', err);
          });
      } else {
        // update
        this.apiService
          .patchParameter(this.parameter.ID, 'setMetric', { Value: event[0] })
          .then(resp => {
            those.parameter.DimensionId = event;
          })
          .catch(err => {
            those.error('Patch parameter', err);
          });
      }
      //
    }
  }

  changeCustomValue(value) {
    const those = this;
    if (this.parameter.Name && value) {
      if (!this.parameter.ID) {
        // create
        this.apiService
          .postParameter({ ...this.parameter, ...{ ProductId: those.productId, VariationId: those.variationId } })
          .then(parameter => {
            those.parameter = parameter;
          })
          .catch(err => {
            those.error('Create parameter', err);
          });
      } else {
        // update
        this.apiService
          .putParameter(this.parameter.ID, {
            ...this.parameter,
            ...{ ProductId: those.productId, VariationId: those.variationId },
          })
          .then(parameter => {
            those.parameter = parameter;
          })
          .catch(err => {
            those.error('Update parameter', err);
          });
      }
    }
  }

  deleteParameter(id) {
    const those = this;
    if (id) {
      const modal = this.modalService.open(ConfirmComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      modal.componentInstance.title = 'Delete custom field?';
      modal.componentInstance.body = `Are you sure you want to delete custom field? This can't be undone.`;
      modal.componentInstance.confirm = 'Delete';
      modal.result.then(
        result => {
          those.apiService
            .deleteParameter(id)
            .then(resp => {
              const index = those.parameters.findIndex(item => item.ID === id);
              if (index >= 0) {
                those.parameters.splice(index, 1);
              }
            })
            .catch(err => {
              those.error('Delete parameter', err);
            });
        },
        reason => {}
      );
    } else {
      const index = those.parameters.findIndex(item => item.ID === id);
      if (index >= 0) {
        those.parameters.splice(index, 1);
      }
    }
  }
}
