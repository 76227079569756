import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {credentials} from '../../../shared/credentials.data';

@Component({
  template: `
      <span>{{currency}} {{displayed}}</span>
  `,
})
export class OrderTotalRenderComponent implements OnInit, ViewCell {
  currency = credentials.currency;
  displayed;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    this.displayed = parseFloat(this.value ? this.value : '0').toFixed(2);
  }
}
