import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-appearance',
  templateUrl: './appearance.component.html',
  styleUrls: ['./appearance.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class AppearanceComponent implements OnInit {
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, public translate: TranslateService) { }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getSettings('appearance').then(resp => {
      those.form = those.formBuilder.group({
        Title: [resp.Title ? resp.Title : '', Validators.required],
        Description: [resp.Params?.Description ? resp.Params?.Description : ''],
        Keywords: [resp.Params?.Keywords ? resp.Params?.Keywords : ''],
        Logo: [resp.Params?.Logo ? resp.Params?.Logo : '/img/logo.png'],
        Theme: [resp.Theme ? resp.Theme : ''],
        LanguageCode: [resp.LanguageCode ? resp.LanguageCode : 'en'],
        Paginate: [resp.Paginate ? resp.Paginate : 10],
        Currency: [resp.Currency ? resp.Currency : '', Validators.pattern(/^[a-z]{3}$/)],
        Symbol: [resp.Symbol ? resp.Symbol : ''],
        SymbolPosition: [resp.SymbolPosition ? resp.SymbolPosition : 'left', Validators.required],
        Thousand: [resp.Thousand ? resp.Thousand : '.', Validators.required],
        Decimal: [resp.Decimal ? resp.Decimal : ',', Validators.required],
        DecimalFixed: resp.DecimalFixed ? resp.DecimalFixed : 0,
      });
    }).catch(err => {
      those.error('Get settings', err);
    });
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Logo: file
    });
    this.form.get('Logo').markAllAsTouched();
    this.form.get('Logo').updateValueAndValidity();
  }

  unset() {
    this.form.get('Logo').setValue('');
    this.form.get('Logo').markAsTouched();
    this.form.get('Logo').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    /*const raw = this.form.getRawValue();
    if (raw.Thousand === raw.Decimal){
      if (raw.Thousand === '.') {
        raw.Decimal = ',';
      }else if (raw.Thousand === ',') {
        raw.Decimal = '.';
      }
    }*/
    const data = new FormData();
    data.append('Title', this.form.get('Title').value);
    data.append('Description', this.form.get('Description').value);
    data.append('Keywords', this.form.get('Keywords').value);
    data.append('Theme', this.form.get('Theme').value);
    data.append('Logo', this.form.get('Logo').value);
    data.append('Paginate', this.form.get('Paginate').value);
    data.append('LanguageCode', this.form.get('LanguageCode').value);
    data.append('Currency', this.form.get('Currency').value);
    data.append('Symbol', this.form.get('Symbol').value);
    data.append('SymbolPosition', this.form.get('SymbolPosition').value);
    data.append('Thousand', this.form.get('Thousand').value);
    data.append('Decimal', this.form.get('Decimal').value);
    data.append('DecimalFixed', this.form.get('DecimalFixed').value);
    this.apiService.putSettings('appearance', data).then(resp => {
      those.success('Update settings', resp);
    }).catch(err => {
      those.error('Update settings', err);
    });
  }
}
