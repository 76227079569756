import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-transport',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!transport || !transport['ID']" class="modal-title" id="exampleModalLabel" translate>
          Add Transport Company
        </h5>
        <h5 *ngIf="transport && transport['ID']" class="modal-title" id="exampleModalLabel" translate>
          Edit Transport Company
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label class="d-block" for="filtering" translate>
                {{ 'Enabled' | translate }}:&nbsp;
                <input class="checkbox_animated" id="filtering" type="checkbox" formControlName="Enabled" />
              </label>
            </div>
            <div class="form-group">
              <label for="title" class="mb-1" translate>Title</label>:
              <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
            </div>
            <div class="form-group">
              <label for="name" class="mb-1" translate>Name</label>:
              <input class="form-control" id="name" type="text" formControlName="Name" />
            </div>
            <div class="form-group mb-0">
              <label for="thumbnail" class="mb-1" translate>Thumbnail</label>:
              <div
                *ngIf="
                  form.controls['Thumbnail'] && form.controls['Thumbnail'].value && !form.controls['Thumbnail'].touched
                "
                style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                <img
                  [src]="form.controls['Thumbnail'].value"
                  style="max-height: 300px;border-radius: 5px;margin-bottom: 5px;" />
                <div
                  style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                  (click)="form.controls['Thumbnail'].value = ''">
                  &times;
                </div>
              </div>
              <app-file-uploader id="thumbnail" (filesLoaded)="upload($event)"></app-file-uploader>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
      span.description {
        font-weight: normal;
        color: lightgray;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class TransportComponent implements OnInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  transport;
  modal;
  closeResult;
  form;
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Thumbnail: [''],
      Weight: [0],
      Volume: [0],
      Order: [],
      Item: [],
      Kg: [0],
      M3: [0],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getTransport(id)
      .then(transport => {
        those.transport = transport;
        those.form = those.formBuilder.group({
          ID: [transport.ID],
          Enabled: [transport.Enabled ? true : false],
          Name: [{ disabled: true, value: transport.Name }, Validators.required],
          Title: [transport.Title, Validators.required],
          Thumbnail: [transport.Thumbnail ? those.absolutizeUrl.transform('/storage' + transport.Thumbnail) : ''],
          Weight: [transport.Weight ? transport.Weight : 0],
          Volume: [transport.Volume ? transport.Volume : 0],
          Order: [transport.Order ? transport.Order : ''],
          Item: [transport.Item ? transport.Item : ''],
          Kg: [transport.Kg],
          M3: [transport.M3],
          Free: [transport.Free ? true : false],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load categories', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete transport?';
    modal.componentInstance.body = `Are you sure you want to delete transport? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteTransport(id)
          .then(resp => {
            those.success('Delete transport', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete transport', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Weight', this.form.get('Weight').value);
    data.append('Volume', this.form.get('Volume').value);
    const order = this.form.get('Order').value;
    data.append('Order', order ? order : '');
    const item = this.form.get('Item').value;
    data.append('Item', item ? item : '');
    data.append('Kg', this.form.get('Kg').value);
    data.append('M3', this.form.get('M3').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postTransport(data)
        .then(resp => {
          those.success('Create transports', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Delete transports', err);
        });
    } else {
      // update
      this.apiService
        .putTransport(this.form.get('ID').value, data)
        .then(resp => {
          those.success('Update transports', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update transports', err);
        });
    }
  }
}
