import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <div style="min-width: 100px;">
      <div *ngIf="rowData.Width">{{ rowData.Width }} * {{ rowData.Height }}</div>
      <div>{{ n | humanizeSize }}</div>
    </div>
  `,
})
export class MediaSizeRenderComponent implements OnInit, ViewCell {
  n: number;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    this.n = parseInt(this.value, 10);
  }
}
