import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CategoriesRoutingModule } from './categories-routing.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {CategoriesComponent} from './categories.component';
import {CategoryTitleRenderComponent} from './category-title-render.component';
import {TranslateModule} from '@ngx-translate/core';
import {TinymceModule} from 'angular2-tinymce';
import {SharedModule} from '../../shared/shared.module';
import {CategoryComponent} from './category/category.component';
import {TreeModule} from 'angular-tree-component';
import {CategorySortRenderComponent} from './category-sort-render.component';
import {CategorySelectedRenderComponent} from './category-selected-render.component';
import {CategoryProductsRenderComponent} from './category-products-render.component';
import {CategoryEnabledRenderComponent} from './category-enabled-render.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [
    CategoriesComponent,
    CategorySelectedRenderComponent,
    CategoryTitleRenderComponent,
    CategoryProductsRenderComponent,
    CategorySortRenderComponent,
    CategoryEnabledRenderComponent,
    CategoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CKEditorModule,
    CategoriesRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    TinymceModule.withConfig({ baseURL: '/assets/tinymce'}),
    SharedModule,
    TreeModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class CategoriesModule { }
