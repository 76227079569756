import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  styles: [
    `
      div {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
      }
      span {
        white-space: nowrap;
      }
      div > span:not(:last-child)::after {
        content: ', ';
      }
    `,
  ],
  template: `<div>
    <span *ngFor="let value of values">{{ value }}</span>
  </div> `,
})
export class ListRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;

  values = [];

  ngOnInit() {
    this.values = this.value.split(/;\s+/);
  }
}
