import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DragulaService } from 'ng2-dragula';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { credentials } from '../../../shared/credentials.data';
import { TranslateService } from '@ngx-translate/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

declare var $: any;

@Component({
  selector: 'app-transport',
  templateUrl: './variation.component.html',
  styleUrls: ['./variation.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class VariationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DropzoneDirective) dropzone: DropzoneDirective;
  paramsSubscription: Subscription;
  queryParamsSubscription: Subscription;
  loading = false;
  productId;
  id;
  variation;
  form;
  config;
  updating = false;

  timesOptions: IMultiSelectOption[] = [];
  timesSettings: IMultiSelectSettings = {
    autoUnselect: true,
    closeOnSelect: true,
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 5,
    selectionLimit: 1,
  };
  texts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };

  dropzoneConfig: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    parallelUploads: 1,
  };

  customization;

  currency = credentials.currency;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private translate: TranslateService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    /*this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] === 'new') {
          those.form = those.formBuilder.group({
            ID: [''],
            Name: ['', Validators.required],
            Title: ['', Validators.required],
            Description: [''],
            Thumbnail: [''],
            BasePrice: [0, Validators.required],
            SalePrice: [0],
            Start: [moment().format('YYYY-MM-DD 00:00:00'), Validators.required],
            End: [moment('2099-12-31').format('YYYY-MM-DD 00:00:00'), Validators.required],
            Dimensions: [''],
            Weight: [0],
            Availability: ['available'],
            Sending: ['instant'],
            Sku: []
          });
          //
          this.format(this.form.get('BasePrice'));
          this.format(this.form.get('SalePrice'));
        }else{
          those.loading = true;
          those.id = params['id'];
          those.apiService.getVariation(those.id).then(variation => {
            those.apiService.getProduct(variation['ProductId']).then(product => {
              this.product = product;
              those.loading = false;
              those.variation = variation;
              those.form = those.formBuilder.group({
                ID: [variation['ID']],
                Name: [{disabled: true, value: variation['Name']}, Validators.required],
                Title: [variation['Title'], Validators.required],
                Description: [variation['Description'] ? variation['Description'] : ''],
                Thumbnail: [variation['Thumbnail'] ? those.absolutizeUrl.transform('/storage' + variation['Thumbnail']) : ''],
                BasePrice: [variation['BasePrice'], Validators.required],
                SalePrice: [variation['SalePrice'] ? variation['SalePrice'] : 0.0],
                Start: [variation['Start'] ? moment(variation['Start']).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD 00:00:00'), Validators.required],
                End: [variation['End'] ? moment(variation['End']).format('YYYY-MM-DD HH:mm:ss') : moment('2099-12-31').format('YYYY-MM-DD 00:00:00'), Validators.required],
                Dimensions: [variation['Dimensions']],
                Weight: [variation['Weight']],
                Availability: [variation['Availability'] ? variation['Availability'] : 'available'],
                Sending: [variation['Sending'] ? variation['Sending'] : 'instant'],
                Sku: [variation['Sku']]
              });
              //
              this.format(this.form.get('BasePrice'));
              this.format(this.form.get('SalePrice'));
            }).catch(err => {
              those.error('Load product', err);
            });
          }).catch(err => {
            those.error('Load variation', err);
          });
        }
      }
    });*/
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        those
          .preload()
          .then(() => {
            if (params['id'] === 'new') {
              // create
              this.variation = { ID: 0 };
              those.form = those.formBuilder.group({
                ID: [0],
                Name: [''],
                Title: [''],
                Description: [''],
                ProductId: params['pid'],
                Availability: ['available'],
                Sending: ['instant'],
                Pattern: ['whd'],
                Dimensions: ['Dimensions'],
                Width: [0],
                Height: [0],
                Depth: [0],
                Weight: [''],
                Sku: [''],
                BasePrice: [0],
                SalePrice: [0],
              });
              setTimeout(() => {
                those.onSubmit();
              }, 100);
            } else {
              // load
              those.id = params['id'];
              those.refresh();
            }
          })
          .catch(err => {
            those.error('Get data', err);
          });
      }
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      if (params['product_id']) {
        those.productId = params['product_id'];
      } else if (params['pid']) {
        those.productId = params['pid'];
      }
    });
    this.config = {
      auto_focus: false,
      baseURL: those.absolutizeUrl.transform('/assets/tinymce'),
      //document_base_url: those.absolutizeUrl.transform('/admin'),
      //relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        those.apiService
          .postImage(data)
          .then(resp => {
            those.success('Create image', resp);
            success(resp['Url']);
          })
          .catch(err => {
            those.error('Create image', err);
            failure(err['ERROR'] ? err['ERROR'] : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 500,
    };
    //
    this.dragulaService.createGroup('images', {
      moves: function (el, container, handle) {
        const classes = handle.className.split(/\s+/);
        if (classes.indexOf('draggable') !== -1) {
          // Touch devices fix
          $(document).on('touchstart', e => {
            e.preventDefault();
          });
          // //
          return true;
        }
        return false;
      },
    });
  }

  ngAfterViewInit(): void {
    $('body').scrollspy({ offset: 100, target: '#navbar-left' });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.dragulaService.destroy('images');
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  preload(): Promise<any> {
    const those = this;
    return Promise.all([
      those.apiService
        .getTimes()
        .then(times => {
          those.timesOptions = times.map(item => {
            return { id: item['ID'], name: item['Title'] };
          });
        })
        .catch(err => {
          those.error('Load times', err);
        }),
    ]);
  }

  onAddTime() {
    const those = this;
    this.apiService
      .getTimes()
      .then(times => {
        those.timesOptions = times.map(item => {
          return { id: item['ID'], name: item['Title'] };
        });
        if (times) {
          those.form.get('Times').setValue(times[times.length - 1]['ID']);
        }
      })
      .catch(err => {
        those.error('Load times', err);
      });
  }

  refresh(type?) {
    const those = this;
    this.apiService
      .getVariation(this.id)
      .then(variation => {
        if (type === 'properties') {
          those.variation['Properties'] = variation['Properties'];
          return;
        } else if (type === 'files') {
          those.variation['Files'] = variation['Files'];
          return;
        } else if (type === 'images') {
          those.variation['Images'] = variation['Images'];
          return;
        }
        //
        variation['Start'] = variation['Start'] == '0001-01-01T00:00:00Z' ? '' : variation['Start'];
        variation['End'] = variation['End'] == '0001-01-01T00:00:00Z' ? '' : variation['End'];
        //
        those.variation = variation;
        those.form = those.formBuilder.group({
          ID: [variation['ID']],
          ProductId: [variation['ProductId']],
          Name: [variation['Name'], [Validators.required, those.validatorName.bind(this)]],
          Title: [variation['Title'], [Validators.required, those.validatorTitle.bind(this)]],
          Thumbnail: [variation['Thumbnail'] ? those.absolutizeUrl.transform('/storage' + variation['Thumbnail']) : ''],
          Description: [variation['Description'] ? variation['Description'] : ''],
          BasePrice: [variation['BasePrice'] ? variation['BasePrice'] : 0.0],
          SalePrice: [variation['SalePrice'] ? variation['SalePrice'] : 0.0],
          Start: [
            variation['Start']
              ? moment(variation['Start']).format('YYYY-MM-DD HH:mm:ss')
              : moment('2020-01-01').format('YYYY-MM-DD 00:00:00'),
            Validators.required,
          ],
          End: [
            variation['End']
              ? moment(variation['End']).format('YYYY-MM-DD HH:mm:ss')
              : moment('2099-12-31').format('YYYY-MM-DD 00:00:00'),
            Validators.required,
          ],
          Pattern: [variation['Pattern'] ? variation['Pattern'] : ''],
          Dimensions: [variation['Dimensions'] ? variation['Dimensions'] : ''],
          Width: [variation['Width'] ? variation['Width'] : 0],
          Height: [variation['Height'] ? variation['Height'] : 0],
          Depth: [variation['Depth'] ? variation['Depth'] : 0],
          Weight: [variation['Weight'] ? variation['Weight'] : 0.0],
          Availability: [variation['Availability'] ? variation['Availability'] : 'available'],
          //Sending: [variation['Sending'] ? variation['Sending'] : 'instant'],
          Times: [variation['TimeId'] ? [variation['TimeId']] : []],
          Sku: [variation['Sku'] ? variation['Sku'] : ''],
          Customization: [variation['Customization'] ? variation['Customization'] : '{}'],
        });
        //
        this.format(this.form.get('BasePrice'));
        this.format(this.form.get('SalePrice'));
        //
        const url = new URL(those.apiService.getUrl() + '/images/');
        url.searchParams.set('vid', this.id);
        those.dropzoneConfig.url = url.toString();
        those.dropzoneConfig.headers = those.apiService.getAuthorizationHeader();
        those.dropzoneConfig.paramName = 'Image';
        //
        those.customization = JSON.parse(variation['Customization'] || '{}');
      })
      .catch(err => {
        those.error('Load variation', err);
      });
  }

  validatorName(control: FormControl): { [s: string]: boolean } {
    if (this.form && this.variation && this.variation['New']) {
      if (this.variation['Name'] === control.value) {
        control.markAsTouched();
        this.form.markAsDirty();
        return { invalid: true };
      }
    }
    return null;
  }

  validatorTitle(control: FormControl): { [s: string]: boolean } {
    if (this.form && this.variation && this.variation['New']) {
      if (this.variation['Title'] === control.value) {
        control.markAsTouched();
        this.form.markAsDirty();
        return { invalid: true };
      }
    }
    return null;
  }

  onChangeTitle(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  upload(files) {
    this.updating = true;
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
    this.form.get('Thumbnail').markAsDirty();
  }

  changeImages(event) {
    this.customization['Images'] = this.customization['Images'] || {};
    this.customization['Images']['Order'] = event.map(item => item['ID']);
    this.form.get('Customization').setValue(JSON.stringify(this.customization));
    this.form.get('Customization').markAsDirty();
  }

  onImageUploadError($event) {
    this.error('Create image', { status: 500, error: { ERROR: 'Something went wrong' } });
  }

  onImageUploadComplete() {
    console.log('onImageUploadComplete');
    this.dropzone.reset();
    this.refresh('images');
  }

  /*select() {
    console.log('select');
    if (this.form.get('Availability').value === 'available' && !this.form.get('Sending').value) {
      this.form.get('Sending').setValue('instant');
    }
  }*/

  parse() {
    const pattern = this.form.get('Pattern').value;
    const value = this.form.get('Dimensions').value;
    if (value) {
      let res;
      switch (pattern) {
        case 'whd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        case 'wdh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        case 'hwd':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        case 'hdw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        case 'dwh':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        case 'dhw':
          res = value.match(/([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]+)([0-9\.\,]+)([^0-9\.\,]*)/);
          if (res && res.length >= 4) {
            this.form.get('Width').setValue(+res[5] * (res[6].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Height').setValue(+res[3] * (res[4].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
            this.form.get('Depth').setValue(+res[1] * (res[2].trim().toLocaleLowerCase().startsWith('m') ? 100 : 1));
          }
          break;
        default:
          break;
      }
    }
  }

  trim(control) {
    if (control) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  scroll(id) {
    const yOffset = -100;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    return false;
  }

  onDelete() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete variation?';
    modal.componentInstance.body = `Are you sure you want to delete variation? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        const id = this.form.get('ID').value;
        those.apiService
          .deleteVariation(id)
          .then(resp => {
            those.success('Delete variation', resp);
            those.form.markAsPristine();
            those.router.navigateByUrl('/products/' + this.form.get('ProductId').value);
          })
          .catch(err => {
            those.error('Delete variation', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('ProductId', this.form.get('ProductId').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postVariation(data)
        .then(variation => {
          those.router.navigate(['/variations', variation['ID']]);
        })
        .catch(err => {
          those.error('Create variation', err);
        });
    } else {
      // update
      data.append('Name', this.form.get('Name').value);
      data.append('Title', this.form.get('Title').value);
      data.append('Thumbnail', this.form.get('Thumbnail').value);
      data.append('Description', this.form.get('Description').value);
      data.append('BasePrice', this.form.get('BasePrice').value);
      data.append('SalePrice', this.form.get('SalePrice').value);
      data.append('Start', moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ'));
      data.append('End', moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ'));
      data.append('Pattern', this.form.get('Pattern').value);
      data.append('Dimensions', this.form.get('Dimensions').value);
      data.append('Width', this.form.get('Width').value);
      data.append('Height', this.form.get('Height').value);
      data.append('Depth', this.form.get('Depth').value);
      data.append('Weight', this.form.get('Weight').value);
      data.append('Availability', this.form.get('Availability').value);
      //data.append('Sending', this.form.get('Sending').value);
      data.append('TimeId', this.form.get('Times').value ? this.form.get('Times').value.join(',') : '');
      data.append('Sku', this.form.get('Sku').value);
      data.append('Customization', JSON.stringify(this.customization));
      this.apiService
        .putVariation(those.form.get('ID').value, data)
        .then(resp => {
          those.success('Update variation', resp);
          those.form.markAsPristine();
        })
        .catch(err => {
          those.error('Update variation', err);
        });
    }
  }
}
