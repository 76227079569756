<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <div class="btn-popup pull-right" style="margin-bottom: 0">
          <a class="btn btn-plain btn-add" routerLink="/menus/new" translate> Add menu</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row" style="padding-bottom: 10px">
            <div class="col-md-4">
              <div class="table-top" style="position: relative">
                <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
                  </svg>
                </div>
                <input type="text" class="form-control search" placeholder="{{'search' | translate}}" (keyup)="termChanged.next()" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
          <div *ngIf="selected && selected.length > 0" class="row">
            <div class="col" style="display: flex; align-items: center; gap: 5px;">
              <div class="body-2">
                <span style="padding-right: 5px;">{{selected.length}}</span>
                <span translate>of</span> {{source.filtered()}} <span translate>options selected</span>
              </div>
              <div class="button-group-dropdown">
                <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                  <span translate>Choose actions</span>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li class="body-2" (click)="bulk('set-enabled')" translate>Set active</li>
                  <li class="body-2" (click)="bulk('set-disabled')" translate>Set inactive</li>
                </ul>
              </div>
              <div class="btn btn-sm btn-default delete" style="display: flex; padding: 7px;" (click)="bulk('delete')">
                <svg style="height: 18px; width: 18px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                </svg>
              </div>
            </div>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive" [ngClass]="!visible?'transparent':''">
              <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
              <div *ngIf="source && source.count() > 0" class="records-count">
                <span *ngIf="source.filtered() != source.total()">
                  {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                </span>
                <span *ngIf="source.filtered() == source.total()">
                  {{'Records' | translate}}: {{source.total()}}
                </span>
                <div class="per-page" style="text-align: center">
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(10)">10</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(20)">20</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(50)">50</span>
                  <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(100)">100</span>
                </div>
              </div>
            </div>
            <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
