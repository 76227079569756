import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rename',
  styleUrls: ['./rename.component.scss'],
  templateUrl: './rename.component.html',
})
export class RenameComponent implements OnInit {
  title = 'Raname';
  message = 'Filter name';
  placeholder;
  value = '';
  description;
  confirm = 'Save';
  cancel = 'Cancel';

  constructor(public modal: NgbActiveModal, private elem: ElementRef) {}

  ngOnInit() {
    const those = this;
    setTimeout(() => {
      const element = those.elem;
      if (element) {
        const input = element.nativeElement.querySelector('input');
        if (input) {
          input['select']();
        }
      }
    }, 100);
  }
}
