import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../shared/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [ AbsolutizeUrlPipe ]
})
export class NotificationComponent implements OnInit {
  form;

  constructor(private apiService: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,
              private toastr: ToastrService, public absolutizeUrl: AbsolutizeUrlPipe, public translate: TranslateService) { }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  ngOnInit() {
    const those = this;
    this.apiService.getSettings('notification').then(resp => {
      those.form = those.formBuilder.group({
        Enabled: [resp.Enabled ? resp.Enabled : false],
        Email: those.formBuilder.group({
          Enabled: [resp.Email?.Enabled ? resp.Email?.Enabled : false],
          Name: [resp.Email?.Name ? resp.Email?.Name : ''],
          Email: [resp.Email?.Email ? resp.Email?.Email : ''],
          Key: [resp.Email?.Key ? resp.Email?.Key : ''],
        })
      });
    }).catch(err => {
      those.error('Get settings', err);
    });
  }

  setFormValue(field, value){
    this.form.get(field).setValue(value);
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    this.apiService.putSettings('notification', raw).then(resp => {
      those.success('Update settings', resp);
    }).catch(err => {
      those.error('Update settings', err);
    });
  }
}
