<div class="container-fluid">
  <div class="card">
    <div *ngIf="product" class="card-header">
      <h5><a [routerLink]="['/products/' + product['ID']]" class="crumb link">{{product['Title']}}</a> :: <span class="crumb" translate>Variations</span></h5>
    </div>
    <div class="card-body">
      <div class="btn-popup pull-right" style="margin-bottom: 10px;">
        <app-edit-variation (onSaved)="refresh()" #editor></app-edit-variation>
      </div>
      <div id="batchDelete" class="category-table custom-datatable">
        <div class="table-responsive" [ngClass]="!visible?'transparent':''">
          <ng2-smart-table (create)="onCreate()" (edit)="onEdit($event.data)" (delete)="onDelete($event['data']['ID'])" [settings]="settings" [source]="source"></ng2-smart-table>
          <div *ngIf="source && source.count() > 0" class="records-count">
            <span *ngIf="source.filtered() != source.total()">
              {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
            </span>
            <span *ngIf="source.filtered() == source.total()">
                {{'Records' | translate}}: {{source.total()}}
            </span>
          </div>
        </div>
        <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
      </div>
    </div>
  </div>
</div>
