import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenusComponent } from './menus.component';
import {MenuComponent} from './menu/menu.component';

const routes: Routes = [
  {
    path: '',
    component: MenusComponent,
    data: {
      title: 'Menus',
      breadcrumb: ''
    }
  },
  {
    path: ':id',
    component: MenuComponent,
    data: {
      title: 'Menu',
      breadcrumb: 'Menu'
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenusRoutingModule { }
