import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    {{n | humanizeSize}}
  `,
})
export class FileSizeRenderComponent implements OnInit, ViewCell {
  n: number;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    this.n = parseInt(this.value, 10);
  }
}
