<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Notification Settings</h1>
        </div>
        <div *ngIf="form">
          <div class="button-group-dropdown" style="display: inline-block">
            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Enabled').value ? 'status-active' : 'status-inactive'">
              <span [innerHTML]="form.get('Enabled').value ? 'active' : 'inactive' | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setFormValue('Enabled', true);">
                Active
              </li>
              <li (click)="setFormValue('Enabled', false);">
                Inactive
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 20px;">
              <div class="col">
                <div class="form" style="margin-bottom: 20px" formGroupName="Email">
                  <div style="display: flex;align-items: center; padding-bottom: 10px">
                    <h2 style="margin-bottom: 0" translate>Emails</h2>
                    <div class="button-group-dropdown" style="display: inline-block">
                      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Email').get('Enabled').value ? 'status-active' : 'status-inactive'">
                        <span [innerHTML]="form.get('Email').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li (click)="form.get('Email').get('Enabled').setValue(true)">
                          Active
                        </li>
                        <li (click)="form.get('Email').get('Enabled').setValue(false)">
                          Inactive
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="notification-email-name" class="mb-1" translate>Sender Name</label>
                    <input class="form-control" id="notification-email-name" type="text" formControlName="Name">
                  </div>
                  <div class="form-group">
                    <label for="notification-email-email" class="mb-1" translate>Sender Email</label>
                    <input class="form-control" id="notification-email-email" type="text" formControlName="Email">
                  </div>
                  <div class="form-group">
                    <label for="notification-email-key" style="padding-right: 5px" class="mb-1" translate>Key</label>
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'API KEY from sendgrid.com' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                    <input class="form-control" id="notification-email-key" type="text" formControlName="Key">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="padding: 0 30px;">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
