import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {AbsolutizeUrlPipe} from '../../shared/utils.pipe';

@Component({
  template: `
    <a class="link" [routerLink]="['/messages']" [queryParams]="{search: 'form:' + rowData['ID']}" style="cursor: pointer">{{value}}</a>
  `,
})
export class FormMessagesRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;
  body;
  limit = 128;
  medias = [];

  constructor(public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngOnInit() {
    //
  }

}
