import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EnabledRenderComponent } from '../../../shared/render/enabled-render.component';
import { SelectedRenderComponent } from '../../../shared/render/selected-render.component';
import { CouponUsedRenderComponent } from './coupon-used-render.component';
import { DateRenderComponent } from '../../../shared/render/date-render.component';
import { PriceRenderComponent } from '../../../shared/render/price-render.component';
import { NameRenderComponent } from '../../../shared/render/name-render.component';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  providers: [AbsolutizeUrlPipe],
})
export class CouponsComponent implements OnInit {
  presets = [
    {
      Id: '1',
      Name: 'all',
      Label: 'All Coupons',
      Filter: {
        On: true,
        Fields: [],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '2',
      Name: 'active',
      Label: 'Active',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Enabled',
            Label: 'Status',
            Type: 'select',
            Value: {
              Label: 'Yes',
              Value: 'true',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '3',
      Name: 'inactive',
      Label: 'Inactive',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Enabled',
            Label: 'Status',
            Type: 'select',
            Value: {
              Label: 'No',
              Value: 'false',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '4',
      Name: 'expired',
      Label: 'Expired',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'End',
            Label: 'End',
            Type: 'date',
            Value: {
              Label: 'yesterday',
              Value: ' - yesterday',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '5',
      Name: 'scheduled',
      Label: 'Scheduled',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Start',
            Label: 'Start',
            Value: {
              Label: 'tomorrow',
              Value: 'tomorrow - ',
            },
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
    {
      Id: '6',
      Name: 'used',
      Label: 'Used',
      Filter: {
        On: true,
        Fields: [
          {
            Name: 'Discounts',
            Label: 'Used',
            Value: '>0',
          },
        ],
      },
      PerPage: 10,
      Sort: [
        {
          field: 'Created',
          direction: 'desc',
        },
      ],
    },
  ];

  bulks = [
    {
      Name: 'set-active',
      Label: 'Set active',
    },
    {
      Name: 'set-inactive',
      Label: 'Set inactive',
    },
  ];

  columns = {
    Selected: {
      title: '',
      type: 'custom',
      renderComponent: SelectedRenderComponent,
      filter: false,
      sort: false,
    },
    Title: {
      title: 'Display Name',
      type: 'custom',
      renderComponent: NameRenderComponent,
      filter: false,
      sort: true,
    },
    Enabled: {
      title: 'Status',
      type: 'custom',
      renderComponent: EnabledRenderComponent,
      filter: false,
      sort: false,
    },
    Used: {
      title: 'Used',
      type: 'custom',
      renderComponent: CouponUsedRenderComponent,
      filter: false,
      sort: false,
    },
    Start: {
      title: 'Start',
      type: 'custom',
      renderComponent: DateRenderComponent,
      filter: false,
      sort: true,
    },
    End: {
      title: 'End',
      type: 'custom',
      renderComponent: DateRenderComponent,
      filter: false,
      sort: true,
    },
    OrderSum: {
      title: 'Order amount',
      type: 'custom',
      renderComponent: PriceRenderComponent,
      filter: false,
      sort: true,
    },
    DiscountSum: {
      title: 'Discount amount',
      type: 'custom',
      renderComponent: PriceRenderComponent,
      filter: false,
      sort: true,
    },
  };

  filters = [
    {
      Name: 'Start',
      Label: 'Start',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
        {
          Name: 'last 90 days',
          Label: 'Last 90 days',
          Value: 'last 90 days',
        },
        {
          Name: 'last 12 months',
          Label: 'Last 12 months',
          Value: 'last 12 months',
        },
      ],
    },
    {
      Name: 'End',
      Label: 'End',
      Type: 'date',
      Values: [
        {
          Name: 'today',
          Label: 'Today',
          Value: 'today',
        },
        {
          Name: 'last 7 days',
          Label: 'Last 7 days',
          Value: 'last 7 days',
        },
        {
          Name: 'last 30 days',
          Label: 'Last 30 days',
          Value: 'last 30 days',
        },
      ],
    },
    {
      Name: 'Enabled',
      Label: 'Status',
      Type: 'select',
      Values: [
        {
          Name: 'any',
          Label: 'Any',
          Value: '',
        },
        {
          Name: 'active',
          Label: 'Active',
          Value: 'true',
        },
        {
          Name: 'inactive',
          Label: 'Inactive',
          Value: 'false',
        },
      ],
    },
    {
      Name: 'Discounts',
      Label: 'Used',
      Placeholder: '>0',
      Value: '',
    },
  ];
  sorts = [
    {
      Name: 'Created',
      Label: 'Created',
      Order: [
        {
          Type: 'desc',
          Label: 'Newest to Oldest',
        },
        {
          Type: 'asc',
          Label: 'Oldest to Newest',
        },
      ],
    },
    {
      Name: 'Title',
      Label: 'Display Name',
      Order: [
        {
          Type: 'asc',
          Label: 'From A to Z',
        },
        {
          Type: 'desc',
          Label: 'From Z to A',
        },
      ],
    },
  ];

  constructor(public router: Router, public route: ActivatedRoute, protected http: HttpClient) {}

  ngOnInit() {}
}
