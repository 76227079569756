import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { VariationsRoutingModule } from './variations-routing.module';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {SharedModule} from '../../shared/shared.module';
import {VariationPropertiesRenderComponent} from './variation-properties-render.component';
import {VariationsComponent} from './variations.component';
import {VariationProductTitleRenderComponent} from './variation-product-title-render.component';
import {VariationTitleRenderComponent} from './variation-title-render.component';
import {VariationBasepriceRenderComponent} from './variation-baseprice-render.component';
import {TranslateModule} from '@ngx-translate/core';
import {VariationComponent} from './variation/variation.component';
import {DragulaModule} from 'ng2-dragula';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgxBootstrapMultiselectModule} from 'ngx-bootstrap-multiselect';
import {MomentModule} from 'ngx-moment';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [VariationsComponent, VariationTitleRenderComponent, VariationBasepriceRenderComponent,
    VariationProductTitleRenderComponent, VariationPropertiesRenderComponent, VariationComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    VariationsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    TranslateModule,
    MomentModule,
    DragulaModule.forRoot(),
    DpDatePickerModule,
    NgxBootstrapMultiselectModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ],
  entryComponents: [
    VariationTitleRenderComponent,
    VariationProductTitleRenderComponent,
    VariationPropertiesRenderComponent,
  ],
  exports: [
    VariationTitleRenderComponent,
    VariationProductTitleRenderComponent,
    VariationPropertiesRenderComponent,
  ]
})
export class VariationsModule { }
