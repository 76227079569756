import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { ITreeOptions, TreeComponent } from 'angular-tree-component';

@Component({
  selector: 'app-product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProductInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ProductInputComponent,
    },
  ],
})
export class ProductInputComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('tree', { read: TreeComponent }) tree: TreeComponent;
  @Input('exclude') exclude = []; // ids
  @Input() input;
  products = [];
  selected = [];
  csv = '';
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };
  lock = false;
  loading = true;
  onChange = selected => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor(private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    const those = this;
    this.apiService
      .getCategories(0, 999, false)
      .then(result => {
        those.products = those.filter(JSON.parse(JSON.stringify(result)));
        those.tree.treeModel.update();
        setTimeout(() => {
          those.setSelectedNodes(
            those.tree,
            (those.selected || []).map(item => item.ID)
          );
          those.loading = false;
        }, 100);
      })
      .catch(err => {
        those.error('Get categories', err);
        console.log('err', err);
      });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  filter(tree) {
    const those = this;
    let f;
    f = nodes => {
      const nodes2 = [];
      if (nodes?.length > 0) {
        for (const node of nodes) {
          node.id = node.Type === 'product' ? node.ID : node.ID * -1;
          if (!those.exclude || those.exclude.indexOf(node.id) < 0) {
            node.type = node.Type;
            node.name = node.Title;
            const children = f(node.Children || []);
            if (children && children.length > 0) {
              node.children = children;
            }
            nodes2.push(node);
          }
        }
      }
      return nodes2;
    };
    return f(tree.Children);
  }

  getSelectedNodes(tree) {
    const acc = [];
    let f;
    f = nodes => {
      nodes.forEach((item, i) => {
        if (item.id) {
          const node = tree.treeModel.getNodeById(item.id);
          if (node && node.isSelected) {
            acc.push(item.id);
          }
        }
        if (item.children) {
          f(nodes[i].children);
        }
      });
    };
    f(tree.treeModel.nodes);
    return acc;
  }

  setSelectedNodes(tree, ids) {
    for (const id of ids) {
      let node = tree.treeModel.getNodeById(id);
      if (node) {
        node.setIsSelected(true);
        while (node.parent) {
          node.parent.expand();
          node = node.parent;
        }
      }
    }
  }

  selectNode(id) {
    let node = this.tree.treeModel.getNodeById(id);
    node.setIsSelected(true);
    while (node.parent) {
      node.parent.expand();
      node = node.parent;
    }
  }

  onChange1(tree, ids) {
    const those = this;
    const categories = ids.split(/,\s*/).map(item => Number(item));
    this.lock = true;
    let f;
    f = nodes => {
      for (const node of nodes) {
        if (node.id) {
          const node2 = tree.treeModel.getNodeById(node.id);
          if (node2) {
            const selected = categories.indexOf(node2.id) > -1;
            if (selected) {
              those.selectNode(node2.id);
            } else {
              node2.setIsSelected(selected);
            }
          }
        }
        if (node.children) {
          f(node.children);
        }
      }
    };
    f(this.products);
    those.selected = [];
    categories.forEach(item => {
      const node = those.findNode({ children: those.products }, Number(item));
      if (node) {
        those.selected.push({
          ID: node.ID,
          Type: node.Type,
          Name: node.Name,
          Path: node.Path,
          Sort: node.Sort,
          Thumbnail: node.Thumbnail,
          Title: node.Title,
        });
      }
    });
    if (!those.loading) {
      this.markAsTouched();
      this.onChange(this.selected);
    }
    this.lock = false;
  }

  onChange2(tree, ids, $event) {
    const those = this;
    if (($event.eventName === 'select' || $event.eventName === 'deselect') && !this.lock) {
      const categories1 = ids.map(item => +item.ID);
      categories1.sort((a, b) => a - b);
      const categories2 = this.getSelectedNodes(tree).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      categories2.sort((a, b) => a - b);
      if (JSON.stringify(categories1) !== JSON.stringify(categories2)) {
        those.csv = categories2.join(',');
        those.selected = [];
        categories2.forEach(item => {
          const node = those.findNode({ children: those.products }, Number(item));
          if (node) {
            those.selected.push({
              ID: node.ID,
              Type: node.Type,
              Name: node.Name,
              Path: node.Path,
              Sort: node.Sort,
              Thumbnail: node.Thumbnail,
              Title: node.Title,
            });
          }
        });
        if (!those.loading) {
          this.markAsTouched();
          this.onChange(this.selected);
        }
      }
    }
  }

  findNode(root, id) {
    let i;
    let child;
    let result;
    if (id === root.ID) {
      return root;
    } else {
      if (root.children) {
        for (i = 0; i < root.children.length; i += 1) {
          child = root.children[i];
          result = this.findNode(child, id);
          if (result !== false) {
            return result;
          }
        }
      }
      return false;
    }
  }

  writeValue(value) {
    this.csv = value.map(item => item.ID).join(',');
    this.selected = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
