import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbsolutizeUrlPipe} from '../../../shared/utils.pipe';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../shared/api.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {credentials} from '../../credentials.data';
import {LocalStorageService} from 'angular-2-local-storage';
import {Subscription} from 'rxjs';
import {SubjectService} from '../../subject.service';

@Component({
  selector: 'app-edit-prices',
  styles: [`
    table {
      text-align: left;
      position: relative;
      border-collapse: collapse;
    }

    th, td {
      padding: 0.25rem;
    }

    th {
      background: white;
      position: sticky;
      box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.25);
    }

    thead > tr:nth-child(1) > th {
      top: -1px;
    }

    thead > tr:nth-child(2) > th {
      top: 24px;
    }

    span.btn-sm.btn-default.delete {
      color: red;
      cursor: pointer;
      font-size: 12pt;
    }
  `],
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title body-2" id="exampleModalLabel">
          Prices
        </h5>
        <div>
          <div class="button-icon" (click)="download()" [title]="'download' | translate">
            <svg width="16px" height="16px">
              <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiDownload"></use>
            </svg>
          </div>
          <label for="upload" class="button-icon" [title]="'upload' | translate">
            <svg width="16px" height="16px">
              <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiUpload"></use>
            </svg>
            <input type='file' id="upload" style="display: none;" (change)="upload($event)">
          </label>
          <div class="button-icon" (click)="toggle()">
            <svg *ngIf="!fullSize" style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-maximize-2"></use>
            </svg>
            <svg *ngIf="fullSize" style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-minimize-2"></use>
            </svg>
          </div>
          <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
            <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5"/>
              <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5"/>
            </svg>
          </div>
        </div>
      </div>

      <div class="modal-body">
        <hot-table *ngIf="data && data.length > 0 && !loading" [columns]="columns" [data]="data" [options]="options"></hot-table>
        <div *ngIf="loading" style="text-align: center"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
      </div>
      <div class="modal-footer" style="justify-content: normal;">
        <button type="button" id="save" class="btn btn-primary facon" [title]="'Save' | translate" (click)="onSave()">
          <svg>
            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
          </svg>
          <span style="vertical-align: text-bottom" translate>Save</span>
        </button>
        <button class="btn btn-cancel" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')"
                translate>Close
        </button>
      </div>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe]
})
export class PricesComponent implements OnInit, OnDestroy {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @Input('properties') properties;
  @Input('optionId') optionId;
  @Input('basePrice') basePrice;
  @Input('salePrice') salePrice;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  property;
  price;
  prices = [];
  huge = false;
  values;
  modal;
  closeResult;
  info;
  subscription: Subscription;
  form;
  filters = {};
  absolute = false;
  term;
  all;
  selected = 0;
  prefix = '+ ' + credentials.currency + ' ';
  decimal = ',';
  thousands = '.';
  action = '';
  fullSize = false;
  //
  columns = [];
  data: any[];
  options: any;
  loading = false;

  constructor(private apiService: ApiService, private subjectService: SubjectService, private modalService: NgbModal,
              private formBuilder: FormBuilder, private toastr: ToastrService,
              private localStorageService: LocalStorageService, private absolutizeUrl: AbsolutizeUrlPipe) {
    this.options = {
      //height: 396,
      height: window.innerHeight - 200,
      stretchH: 'all',
      rowHeaders: true,
      /*colHeaders: true,
      columnSorting: true,
      contextMenu: true*/
    };
  }

  ngOnInit(): void {
    this.subscription = this.subjectService.infoSubject.subscribe(info => {
      this.absolute = info['AbsolutePrice'];
      this.prefix = credentials.currency + ' ';
      if (info['Decimal']) {
        this.decimal = info['Decimal'];
      }
      if (info['Thousands']) {
        this.thousands = info['Thousands'];
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, {closeButton: true, timeOut: 15000});
  }

  refresh() {
    const those = this;
    this.loading = true;
    setTimeout(() => {
      those.loading = false;
    }, 50);
  }

  toggle() {
    if (this.fullSize) {
      this.content.elementRef.nativeElement.ownerDocument.querySelector('.modal-dialog').classList.remove('fullsize');
    } else {
      this.content.elementRef.nativeElement.ownerDocument.querySelector('.modal-dialog').classList.add('fullsize');
    }
    this.fullSize = !this.fullSize;
    this.refresh();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  download() {
    console.log('download');
    const header = this.columns.map(item => item.data);
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const csv = this.data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'prices.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  upload(event) {
    console.log('upload');
    const those = this;
    const file = event.target.files[0];
    console.log('upload', file);
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log('result', reader.result);
      const rows = String(reader.result).split('\n');
      if (those.data.length !== rows.length - 1) {
        those.error('Bad file', {status: 500, error: {ERROR: 'Uploaded content do not match to table'}});
      }
      const header = [];
      for (let i = 0; i < rows.length; i++) {
        const cells = rows[i].trim().split(/[,;]/);
        if (i === 0) {
          header.push(...cells);
        }else{
          const id = Number(cells[0]);
          const index = those.data.findIndex(item => item.ID === id);
          if (index >= 0) {
            for (let j = 0; j < header.length; j++) {
              if (typeof those.data[index][header[j]] === 'number') {
                those.data[index][header[j]] = Number(cells[j]);
              }else{
                those.data[index][header[j]] = cells[j];
              }
            }
          }
        }
      }
      those.refresh();
    };
    reader.readAsText(file);
  }

  onEdit(content, ids?: any[]) {
    const those = this;
    this.columns = [{
      data: 'ID',
      type: 'numeric',
      title: 'ID',
      readOnly: true,
    }];
    if (this.properties) {
      for (let i = 0; i < this.properties.length; i++) {
        this.columns.push({
          data: those.properties[i].Name,
          title: those.properties[i].Title,
          readOnly: true,
        });
      }
    }
    this.columns.push(...[{
      data: 'BasePrice',
      type: 'numeric',
      title: 'BasePrice',
    }, {
      data: 'ManufacturerPrice',
      type: 'numeric',
      title: 'ManufacturerPrice',
    }, {
      data: 'ItemPrice',
      type: 'numeric',
      title: 'ItemPrice',
    }, {
      data: 'SalePrice',
      type: 'numeric',
      title: 'SalePrice',
    }, {
      data: 'Start',
      /*type: 'date',
      dateFormat: 'YYYY-MM-DD HH:mm:ss',*/
      title: 'Start',
    }, {
      data: 'End',
      /*type: 'date',
      dateFormat: 'YYYY-MM-DD HH:mm:ss',*/
      title: 'End',
    }, {
      data: 'MinQuantity',
      type: 'numeric',
      title: 'MinQuantity',
    }, {
      data: 'MaxQuantity',
      type: 'numeric',
      title: 'MaxQuantity',
    }, {
      data: 'Measurement',
      type: 'numeric',
      title: 'Measurement',
    }, {
      data: 'BaseMeasurement',
      type: 'numeric',
      title: 'BaseMeasurement',
    }, {
      data: 'BaseMeasurementUnit',
      title: 'BaseMeasurementUnit',
      /*type: 'dropdown',
      source: ['g', 'kg']*/
    }, {
      data: 'TotalMeasurement',
      type: 'numeric',
      title: 'TotalMeasurement',
    }, {
      data: 'TotalMeasurementUnit',
      title: 'TotalMeasurementUnit',
      /*type: 'dropdown',
      source: ['g', 'kg']*/
    }, {
      data: 'Dimensions',
      title: 'Dimensions',
    }, {
      data: 'DimensionUnit',
      title: 'DimensionUnit',
      /*type: 'dropdown',
      source: ['cm', 'm']*/
    }, {
      data: 'Pattern',
      title: 'Pattern',
    }, {
      data: 'Width',
      type: 'numeric',
      title: 'Width',
    }, {
      data: 'Height',
      type: 'numeric',
      title: 'Height',
    }, {
      data: 'Depth',
      type: 'numeric',
      title: 'Depth',
    }, {
      data: 'Volume',
      type: 'numeric',
      title: 'Volume',
    }, {
      data: 'Weight',
      type: 'numeric',
      title: 'Weight',
    }, {
      data: 'WeightUnit',
      title: 'WeightUnit',
      /*type: 'dropdown',
      source: ['g', 'kg']*/
    }, {
      data: 'Packages',
      type: 'numeric',
      title: 'Packages',
    }, {
      data: 'Availability',
      title: 'Availability',
      /*type: 'dropdown',
      source: ['available', 'preorder', 'out-of-stock']*/
    }, {
      data: 'Sku',
      title: 'Sku',
    }, {
      data: 'Barcode',
      title: 'Barcode',
    }, {
      data: 'Overselling',
      type: 'checkbox',
      title: 'Overselling',
    }, {
      data: 'Stock',
      type: 'numeric',
      title: 'Stock',
    }, {
      data: 'Sort',
      type: 'numeric',
      title: 'Sort'
    }]);
    this.apiService.postPricesExport({Ids: ids}).then(prices => {
      those.data = prices.map(item => {
        const price = {};
        for (let i = 0; i < those.columns.length; i++) {
          if (those.columns[i].data) {
            if (item[those.columns[i].data]) {
              price[those.columns[i].data] = item[those.columns[i].data];
            } else if (those.columns[i].type === 'numeric') {
              price[those.columns[i].data] = those.columns[i].default || 0;
            }
          }
        }
        for (let i = 0; i < this.properties.length; i++) {
          const rate = item.Rates.find(item2 => item2.PropertyId === those.properties[i].ID);
          if (rate) {
            price[those.properties[i].Name] = rate.Value.Title;
          }
        }
        if (price['Start'] && price['Start'] === '0001-01-01T00:00:00Z') {
          price['Start'] = '';
        }
        if (price['End'] && price['End'] === '0001-01-01T00:00:00Z') {
          price['End'] = '';
        }
        return price;
      });
      those.options.height = window.innerHeight - 200 > (those.data.length + 1) * 25 ?
        (those.data.length + 1) * 25 : window.innerHeight - 200;
      //
      those.modal = those.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static'
      });
      those.modal.result.then((result) => {
        those.closeResult = `Closed with: ${result}`;
        those.onSaved.emit();
      }, (reason) => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      });
    }).catch(err => {
      those.error('Load prices', err);
    });
  }

  onSave() {
    const those = this;
    const data = those.data.map(item => {
      for (const field of those.columns) {
        if (['Measurement', 'Overselling'].indexOf(field.data) >= 0) {
          item[field.data] = Boolean(item[field.data]);
        }
      }
      return item;
    });
    console.log('data', data);
    this.apiService.postPrices(data).then(resp => {
      those.success('Update prices', resp);
      those.modal.close('Saved');
      those.onSaved.emit();
    }).catch(err => {
      those.error('Update prices', err);
    });
  }
}
