import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-cell-input',
  styles: [
    `
      .cell-input-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
      }
    `,
  ],
  template: `<div class="cell-input-wrapper">
    <div *ngIf="!editing" style="cursor: pointer" (dblclick)="editing = !editing">{{ value }}</div>
    <input
      *ngIf="editing"
      [attr.type]="type"
      class="form-control"
      autofocus
      (change)="change($event)"
      (focusout)="editing = !editing"
      (keydown.enter)="enter($event)"
      (keydown.tab)="next($event)"
      [(ngModel)]="value" />
  </div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CellInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CellInputComponent,
    },
  ],
})
export class CellInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() input;
  @Input() name: string;
  @Input('type') type = 'text';
  value;
  onChange = value => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  expanded = false;

  editing = false;

  ngOnInit(): void {}

  enter(event) {
    console.log('enter', event);
    this.editing = !this.editing;
    event.preventDefault();
    event.stopPropagation();
  }

  next(event) {
    let td = event.target.parentElement.parentElement.parentElement;
    let tr = td.parentElement;
    let div =
      td.nextSibling && td.nextSibling['querySelector'] ? td.nextSibling.querySelector('app-cell-input') : false;
    let shift = 0;
    while (!div && td && tr) {
      if (td.nextSibling) {
        td = td.nextSibling;
      } else {
        tr = tr.nextSibling;
        shift++;
        td = tr.firstChild;
      }
      if (td && td['querySelector']) {
        div = td.querySelector('app-cell-input');
      }
    }
    if (div) {
      div.querySelector('div>div').dispatchEvent(
        new MouseEvent('dblclick', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  change(event) {
    const value = event.target['value'];
    this.markAsTouched();
    this.onChange(value);
    event.preventDefault();
    event.stopPropagation();
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
