import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { merge, Observable, OperatorFunction, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ConfirmComponent } from '../../../shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
  providers: [AbsolutizeUrlPipe, NgbTypeaheadConfig],
})
export class VendorComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: true }) instance;
  paramsSubscription: Subscription;
  id;
  vendor;
  form;
  loading = false;
  formSubscription: Subscription;
  tinymceConfig;
  tags;
  tag;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: NgbModal,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params.id === 'new') {
          those.form = those.formBuilder.group({
            Enabled: [true],
            Name: ['', Validators.required],
            Title: ['', Validators.required],
            //Thumbnail: [''],
            Media: [0],
            ShowName: [false],
            ShowLogo: [false],
            Content: [''],
            Times: those.formBuilder.array([]),
          });
          this.formSubscription = this.form.valueChanges.subscribe(x => {
            those.form.markAllAsTouched();
          });
        } else {
          this.id = params.id;
          this.loading = true;
          this.apiService
            .getVendor(this.id)
            .then(vendor => {
              those.loading = false;
              those.vendor = vendor;
              those.form = those.formBuilder.group({
                ID: [vendor['ID']],
                Enabled: [vendor['Enabled'] ? true : false],
                Name: [vendor['Name'], Validators.required],
                Title: [vendor['Title'], Validators.required],
                //Thumbnail: [vendor['Thumbnail'] ? vendor['Thumbnail'] : 0],
                Media: [vendor['Media'] ? vendor['Media'] : 0],
                ShowName: [vendor['ShowName'] ? true : false],
                ShowLogo: [vendor['ShowLogo'] ? true : false],
                Content: [vendor['Content'] ? vendor['Content'] : 0],
                Times: those.formBuilder.array([]),
              });
              //
              if (vendor['Times']) {
                const arr = <FormArray>this.form.get('Times');
                vendor['Times'].forEach(item => {
                  arr.push(
                    new FormGroup({
                      Enabled: new FormControl(item['Enabled']),
                      Name: new FormControl(item['Name']),
                      Title: new FormControl(item['Title'], [Validators.required]),
                      Description: new FormControl(item['Description']),
                      ShowBadge: new FormControl(item['ShowBadge']),
                      ShowList: new FormControl(item['ShowList']),
                    })
                  );
                });
              }
              //
              this.formSubscription = this.form.valueChanges.subscribe(x => {
                those.form.markAllAsTouched();
              });
            })
            .catch(err => {
              those.error('Load vendor', err);
            });
        }
      }
    });

    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      // document_base_url: those.absolutizeUrl.transform('/admin'),
      // relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | removeformat | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | table | ' +
        'forecolor backcolor emoticons',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp.Url);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
      branding: false,
    };
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  formatter = tag => tag.Title;

  search: OperatorFunction<string, readonly { id; name }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term => this.tags.filter(tag => new RegExp(term, 'mi').test(tag.Title)).slice(0, 10))
    );

  back(pitfall) {
    const state = this.location.getState();
    if (state && state['navigationId'] > 1) {
      this.location.back();
    } else {
      this.router.navigate([pitfall]);
    }
  }

  setFormValue(field, value) {
    this.form.get(field).setValue(value);
  }

  change(event, type = 'name') {
    if (type === 'name') {
      if (event.target.value && !this.form.get('Title').value) {
        this.form.get('Title').setValue(event.target.value);
      }
    } else if (type === 'title') {
      if (event.target.value && !this.form.get('Name').value) {
        this.form.get('Name').setValue(event.target.value);
      }
    }
  }

  debug() {
    console.log('debug', this.form.get('Thumbnail'));
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Media: file,
    });
    this.form.get('Media').markAsDirty();
  }

  unset() {
    this.form.get('Media').setValue('');
    this.form.get('Media').markAsTouched();
    this.form.get('Media').updateValueAndValidity();
  }

  patchTimeField(id, field, value) {
    const those = this;
    for (let i = 0; i < this.vendor.Times.length; i++) {
      if (this.vendor.Times[i].ID === id) {
        this.apiService
          .patchTime(id, 'set' + field, { Value: value })
          .then(resp => {
            those.success('Save time', resp);
            those.vendor.Times[i][field] = value;
          })
          .catch(err => {
            those.error('Save time', err);
          });
        break;
      }
    }
  }

  removeTime(id) {
    const those = this;
    for (let i = 0; i < this.vendor.Times.length; i++) {
      if (this.vendor.Times[i].ID === id) {
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        modal.componentInstance.title = 'Delete time?';
        modal.componentInstance.body = `Are you sure you want to delete time? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            those.apiService
              .deleteTime(id)
              .then(resp => {
                those.success('Delete time', resp);
                those.vendor.Times.splice(i, 1);
              })
              .catch(err => {
                those.error('Delete time', err);
              });
          },
          reason => {}
        );
        break;
      }
    }
  }

  trim(control) {
    if (control) {
      control.setValue(String(control.value).replace(/\.00$/, '').replace(/^0$/, ''));
    }
  }

  format(control) {
    let value = Number.parseFloat(control.value);
    if (value) {
      control.setValue(value.toFixed(2));
    } else {
      control.setValue('0.00');
    }
  }

  onDelete() {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete vendor?';
    modal.componentInstance.body = `Are you sure you want to delete vendor? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVendor(those.vendor.ID)
          .then(resp => {
            those.success('Delete vendor', resp);
            those.router.navigate(['/vendors']);
          })
          .catch(err => {
            those.error('Delete vendor', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('ShowName', this.form.get('ShowName').value);
    data.append('ShowLogo', this.form.get('ShowLogo').value);
    data.append('Content', this.form.get('Content').value);
    data.append('Media', this.form.get('Media').value);
    if (!this.vendor) {
      // create
      this.apiService
        .postVendor(data)
        .then(resp => {
          those.success('Create vendors', resp);
          those.router.navigate(['/vendors']);
        })
        .catch(err => {
          those.error('Create vendors', err);
        });
    } else {
      // update
      this.apiService
        .putVendor(this.vendor.ID, data)
        .then(resp => {
          those.vendor = resp;
          those.success('Update vendors', resp);
        })
        .catch(err => {
          those.error('Update vendors', err);
        });
    }
  }
}
