import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import {ApiService} from '../../shared/api.service';
import {AbsolutizeUrlPipe} from '../../shared/utils.pipe';

@Injectable()
export class MenusDataSource extends LocalDataSource {
  parent: any;
  local = false;
  data: any[];
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;

  constructor(protected http: HttpClient, private apiService: ApiService, private absolutizeUrl: AbsolutizeUrlPipe) {
    super();
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  getElements(): Promise<any> {
    // Local sort refresh
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    //
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.parent.term) {
      request['Search'] = this.parent.term;
    }
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach((fieldConf) => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach((fieldConf) => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.visible = false;
    //
    url += '/menus/list';
    return this.http.post(url, request, {headers: headersObject, withCredentials: true}).pipe(map(response => {
      those.lastRequestFiltered = +response['Filtered'];
      those.lastRequestCount = +response['Filtered'];
      those.lastRequestTotal = +response['Total'];
      those.parent.visible = true;
      those.data = (response['Data'] || []);
      return this.data;
    })).toPromise();
  }
}
