import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { credentials } from '../../credentials.data';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-zone',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!zone || !zone['ID']" class="modal-title" id="exampleModalLabel" translate>Add Zone</h5>
        <h5 *ngIf="zone && zone['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Zone</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <label class="d-block" for="filtering">
                {{ 'Enabled' | translate }}:&nbsp;
                <input class="checkbox_animated" id="filtering" type="checkbox" formControlName="Enabled" />
              </label>
            </div>
            <div class="form-group">
              <label for="title" class="mb-1" translate>Title</label>:
              <input class="form-control" id="title" type="text" formControlName="Title" />
            </div>
            <div class="form-group">
              <label for="country" class="mb-1" translate>Country</label>:
              <select class="form-control" formControlName="Country">
                <option *ngFor="let country of countries" [value]="country['Code']">
                  {{ country['Name'] | translate }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="zip" class="mb-1" translate>ZIP</label>:
              <input
                class="form-control"
                id="zip"
                type="text"
                autocomplete="off"
                placeholder="{{ '123456 or 1XXXXX' | translate }}"
                formControlName="ZIP" />
              <div class="description" translate>use 'x' to any digit</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class ZoneComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  zones;
  tariffs;
  zone;
  modal;
  closeResult;
  form;
  timer;
  countries = credentials.countries;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Title: [''],
      Country: ['DE', Validators.required],
      ZIP: ['', [those.validatorZip.bind(this)]],
      Tariffs: those.formBuilder.array([]),
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  validatorZip(control: FormControl): { [s: string]: boolean } {
    if (this.form) {
      if (control.value === '') {
      } else if (control.value.match(/^[0-9]+$/)) {
      } else if (control.value.match(/^[0-9]+X+$/)) {
      } else {
        return { invalid: true };
      }
    }
    return null;
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getZone(id)
      .then(zone => {
        those.zone = zone;
        those.form = those.formBuilder.group({
          ID: [zone['ID']],
          Enabled: [zone['Enabled'] ? true : false],
          Title: [zone['Title']],
          Country: [{ disabled: true, value: zone['Country'] }, Validators.required],
          ZIP: [zone['ZIP'], [those.validatorZip.bind(this)]],
        });
        //
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load zone', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete zone?';
    modal.componentInstance.body = `Are you sure you want to delete zone? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteZone(id)
          .then(resp => {
            those.success('Delete zone', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete zone', err);
          });
      },
      reason => {}
    );
  }

  onSubmit() {
    const those = this;
    const raw = { ...this.zone, ...those.form.getRawValue() };
    raw['Country'] = raw['Country'].toUpperCase();
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postZone(raw)
        .then(resp => {
          those.success('Create zone', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Delete zone', err);
        });
    } else {
      // update
      this.apiService
        .putZone(this.form.get('ID').value, raw)
        .then(resp => {
          those.success('Update zone', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update zone', err);
        });
    }
  }
}
