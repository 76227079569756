import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { Router, UrlSerializer } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-parameter',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <div style="display: flex;align-items: center;">
          <h5 class="modal-title body-2" id="exampleModalLabel">
            {{ (!parameter || !parameter['ID'] ? 'Add Custom Field (Library)' : 'Edit Custom Field') | translate }}
          </h5>
        </div>
        <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
          <svg width="12" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="11.33" y1="0.530452" x2="0.530334" y2="11.3301" stroke="#292F46" stroke-width="1.5" />
            <line x1="11.4692" y1="11.33" x2="0.669505" y2="0.530334" stroke="#292F46" stroke-width="1.5" />
          </svg>
        </div>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group" style="position: relative">
            <label for="options" class="mb-1" translate>Option</label>
            <div>
              <ngx-bootstrap-multiselect
                *ngIf="dimensionsOptions"
                id="options"
                class="multiselect single-selected"
                [options]="dimensionsOptions"
                [settings]="settings"
                formControlName="DimensionId"></ngx-bootstrap-multiselect>
            </div>
          </div>
          <!--div class="form-group">
            <label for="name" class="mb-1" translate>Option Name</label>
            <input class="form-control" id="name" type="text" formControlName="Name" />
          </div-->
          <div class="form-group">
            <label for="title" class="mb-1" translate>Display Name</label>
            <input class="form-control" id="title" type="text" (change)="change($event)" formControlName="Title" />
          </div>
          <div *ngIf="form.get('DimensionId').value != 0" class="form-group" style="position: relative;">
            <label for="value" class="mb-1" translate>Value</label>
            <div>
              <ngx-bootstrap-multiselect
                *ngIf="metricsOptions"
                id="options"
                class="multiselect single-selected"
                [options]="metricsOptions"
                [settings]="settings"
                formControlName="MetricId"></ngx-bootstrap-multiselect>
            </div>
          </div>
          <div *ngIf="parameter['MetricId'] != 0" class="form-group mb-0">
            <label class="d-block" for="filtering">
              <input class="checkbox_animated" id="filtering" type="checkbox" formControlName="Filtering" />
              {{ 'To use in filter' | translate }}
            </label>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: normal;align-self: flex-end;">
          <button
            id="save"
            type="submit"
            class="btn btn-primary facon"
            [title]="'Save' | translate"
            [disabled]="!form.valid">
            <span>+ Add</span>
          </button>
          <button
            class="btn btn-cancel"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Cancel
          </button>
        </div>
      </form>
    </ng-template>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class ParameterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('productId') productId;
  @Input('variationId') variationId;
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onCreated: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  parameter;
  dimensions;
  dimensionsOptions: IMultiSelectOption[];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'fontawesome',
    selectionLimit: 1,
    closeOnSelect: true,
  };
  metricsOptions: IMultiSelectOption[];
  modal;
  closeResult;
  form;
  optionId;
  valueId;
  optionSubscription;
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.data && event.data.action) {
      console.log('onMessage', event.data);
      const those = this;
      if ('created' === event.data.action && event.data.option) {
        those.apiService
          .getDimensions()
          .then(dimensions => {
            those.dimensions = dimensions;
            those.dimensionsOptions = [];
            those.dimensionsOptions.push(
              ...dimensions.map(item => {
                return { id: item['ID'], name: item['Title'] + ' (' + item['Name'] + ') ' };
              })
            );
            those.form.get('DimensionId').setValue([event.data.option['ID']]);
          })
          .catch(err => {
            console.error('Load options', err);
          });
      }
      if ('updated' === event.data.action && event.data.option) {
        const dimensionId =
          this.form.get('DimensionId').value && this.form.get('DimensionId').value[0]
            ? this.form.get('DimensionId').value[0]
            : 0;
        if (dimensionId) {
          those.apiService
            .getMetrics(dimensionId)
            .then(metrics => {
              those.metricsOptions = metrics.map(item => {
                return { id: item['ID'], name: item['Title'] };
              });
              if (metrics && metrics.length > 0) {
                those.form.get('MetricId').setValue([metrics[metrics.length - 1]['ID']]);
              }
            })
            .catch(err => {
              those.error('Load values', err);
            });
        }
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private serializer: UrlSerializer
  ) {}

  ngOnInit(): void {
    const those = this;
    those.apiService
      .getDimensions()
      .then(options => {
        those.dimensions = options;
        those.dimensionsOptions = [];
        those.dimensionsOptions.push(
          ...options.map(item => {
            return { id: item['ID'], name: item['Title'] + ' (' + item['Name'] + ') ' };
          })
        );
      })
      .catch(err => {
        console.error('Load options', err);
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  refresh(type?) {
    const those = this;
    if (type === 'option') {
      those.apiService
        .getDimensions()
        .then(resp => {
          those.dimensions = resp;
          those.dimensionsOptions = resp.map(item => {
            return { id: item['ID'], name: item['Title'] + ' (' + item['Name'] + ') ' };
          });
          if (resp && resp.length > 0) {
            setTimeout(() => {
              this.parameter['Option'] = resp[resp.length - 1];
              this.parameter['DimensionId'] = resp[resp.length - 1]['ID'];
              this.valueId = [resp[resp.length - 1]['ID']];
            }, 100);
          }
          setTimeout(() => {
            this.parameter['Value'] = {};
            this.parameter['MetricId'] = 0;
            this.valueId = [0];
          }, 100);
        })
        .catch(err => {
          console.error('Load options', err);
        });
    } else if (type === 'value') {
      those.apiService
        .getMetrics(this.parameter['DimensionId'])
        .then(metrics => {
          those.metricsOptions = metrics.map(item => {
            return { id: item['ID'], name: item['Title'] };
          });
          if (metrics && metrics.length > 0) {
            setTimeout(() => {
              this.parameter['Metric'] = metrics[0];
              this.parameter['MetricId'] = metrics[0]['ID'];
              this.valueId = [metrics[0]['ID']];
            }, 100);
          }
        })
        .catch(err => {
          those.error('Load values', err);
        });
    }
  }

  onCreate(content) {
    const those = this;
    this.parameter = { Type: 'select', DimensionId: 0 };
    this.form = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      DimensionId: [[], Validators.required],
      MetricId: [[], Validators.required],
      Filtering: [false],
      ProductId: [this.productId],
      VariationId: [this.variationId],
    });
    this.optionSubscription = this.form.get('DimensionId').valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        const option = those.dimensions.find(item => item.ID === value[0]);
        if (option) {
          this.form.get('Name').setValue(option.Name);
          this.form.get('Title').setValue(option.Title);
        }
      }
      those.apiService
        .getMetrics(value[0])
        .then(values => {
          those.metricsOptions = values.map(item => {
            return { id: item['ID'], name: item['Title'] };
          });
          if (values && values.length > 0) {
            those.form.get('MetricId').setValue([values[0]['ID']]);
          }
        })
        .catch(err => {
          those.error('Load values', err);
        });
    });
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    those.modal.result.then(
      result => {
        those.onCreated.emit(those.parameter);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
        those.onClosed.emit();
      }
    );
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete option?';
    modal.componentInstance.body = `Are you sure you want to delete option? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteParameter(id)
          .then(resp => {
            those.success('Delete option', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete option', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    this.form.get('Name').setValue(
      event.target.value
        .toLowerCase()
        .replace(/[!@#$%^&*\s]+/g, '-')
        .replace(/-{1,}/, '-')
    );
  }

  open(path) {
    const url = this.router.createUrlTree([path]);
    window.open(this.serializer.serialize(url), '_blank');
  }

  onSubmit() {
    const those = this;
    const raw = this.form.getRawValue();
    raw['DimensionId'] = raw['DimensionId'] ? raw['DimensionId'][0] : 0;
    raw['MetricId'] = raw['MetricId'] ? raw['MetricId'][0] : 0;
    this.apiService
      .postParameter(raw)
      .then(parameter => {
        those.parameter = parameter;
        those.modal.close('Created');
      })
      .catch(err => {
        those.error('Create parameter', err);
      });
  }
}
