import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { ApiService } from '../../../shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-vendor-block',
  template: `
    <div *ngIf="empty">
      <div *ngIf="all && allOptions" class="form-group">
        <div class="vendor" style="padding: 5px 5px 0px 5px;">
          <ngx-bootstrap-multiselect
            id="all"
            class="multiselect single-selected"
            [options]="allOptions"
            [settings]="settings"
            [texts]="texts"
            [(ngModel)]="item"
            (ngModelChange)="change($event)"></ngx-bootstrap-multiselect>
        </div>
      </div>
    </div>
    <div *ngIf="!empty">
      <div *ngIf="vendors && vendorsOptions" class="form-group">
        <label for="vendors" class="mb-1" translate>Vendor</label>
        <div class="vendor" style="padding: 5px 5px 0px 5px;">
          <ngx-bootstrap-multiselect
            id="vendors"
            class="multiselect single-selected"
            [options]="vendorsOptions"
            [settings]="settings"
            [texts]="texts"
            [formControl]="vendorFormControl"></ngx-bootstrap-multiselect>
        </div>
      </div>
      <div *ngIf="vendors && timesOptions" class="form-group">
        <label for="times" class="mb-1" translate>Delivery Times</label>
        <div class="times" style="padding: 5px 5px 0px 5px;">
          <ngx-bootstrap-multiselect
            id="times"
            class="multiselect single-selected"
            [options]="timesOptions"
            [settings]="settings"
            [texts]="texts"
            [formControl]="timeFormControl"></ngx-bootstrap-multiselect>
        </div>
      </div>
    </div>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class VendorBlockComponent implements OnInit, OnDestroy {
  @Input('vendorFormControl') vendorFormControl: FormControl;
  @Input('timeFormControl') timeFormControl: FormControl;
  empty = true;
  all = [];
  vendors = [];
  times = [];
  allOptions: IMultiSelectOption[];
  vendorsOptions: IMultiSelectOption[];
  timesOptions: IMultiSelectOption[];
  settings: IMultiSelectSettings = {
    autoUnselect: true,
    closeOnSelect: true,
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 5,
    selectionLimit: 1,
  };
  texts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };
  item;
  vendorSubscription: Subscription;
  timeSubscription: Subscription;
  lock = false;

  constructor(private apiService: ApiService, private toastr: ToastrService, private translate: TranslateService) {}

  ngOnInit() {
    const those = this;
    this.empty =
      (!this.vendorFormControl || !this.vendorFormControl.value || this.vendorFormControl.value.length === 0) &&
      (!this.timeFormControl || !this.timeFormControl.value || this.timeFormControl.value.length === 0);
    Promise.all([those.apiService.getVendors(), those.apiService.getTimes()])
      .then(results => {
        those.all = results[0]
          .map(item => ({ ...item, ...{ type: 'vendor' } }))
          .concat(results[1].map(item => ({ ...item, ...{ type: 'time' } })));
        those.vendors = results[0];
        those.times = results[1];
        those.vendorsOptions = those.vendors.map(item => {
          return { id: item.ID, name: `#${item.ID} ${item.Title}`, type: 'vendor' };
        });
        those.allOptions = those.vendorsOptions;
        those.allOptions = those.vendors
          .map(item => {
            return { id: item.ID, name: `#${item.ID} Vendor ${item.Title}`, type: 'vendor' };
          })
          .concat(
            those.times.map(item => {
              return { id: item.ID, name: `#${item.ID} Delivery Time ${item.Title}`, type: 'time' };
            })
          )
          .sort((a, b) => {
            return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
          });
        if (those.vendorFormControl.value && those.vendorFormControl.value.length > 0) {
          const vendor = those.vendors.find(item => item.ID === those.vendorFormControl.value[0]);
          if (vendor && vendor.Times) {
            those.timesOptions = vendor.Times.map(item => {
              return { id: item.ID, name: `#${item.ID} ${item.Title}` };
            });
            those.timeFormControl.setValue([vendor.Times[0].ID]);
          }
        }
        those.vendorSubscription = those.vendorFormControl.valueChanges.subscribe(value => {
          if (value.length === 0) {
            those.timesOptions = those.times.map(item => {
              return { id: item.ID, name: `#${item.ID} ${item.Title}`, type: 'time' };
            });
            those.lock = true;
            setTimeout(() => {
              those.lock = false;
            }, 100);
            those.timeFormControl.setValue([]);
          } else {
            const vendor = those.vendors.find(item => item.ID === value[0]);
            const time = those.times.find(item => item.ID === value[0]);
            if (vendor && vendor.Times) {
              those.timesOptions = vendor.Times.map(item => {
                return { id: item.ID, name: `#${item.ID} ${item.Title}` };
              });
              if (!those.lock) {
                those.lock = true;
                setTimeout(() => {
                  those.lock = false;
                }, 100);
                those.timeFormControl.setValue([vendor.Times[0].ID]);
              }
            } else if (time) {
              if (!those.lock) {
                those.lock = true;
                setTimeout(() => {
                  those.lock = false;
                }, 100);
                those.timeFormControl.setValue([time.ID]);
                those.vendorFormControl.setValue([time.VendorId]);
              }
            }
          }
        });
        those.timeSubscription = those.timeFormControl.valueChanges.subscribe(values => {
          if (!those.lock) {
            if (values.length > 0) {
              const time = those.times.find(item => item.ID === values[0]);
              if (time) {
                those.lock = true;
                setTimeout(() => {
                  those.lock = false;
                }, 100);
                those.vendorFormControl.setValue([time.VendorId]);
              }
            }
          }
        });
      })
      .catch(err => {
        this.error('Load', err);
      });
  }

  ngOnDestroy() {
    if (this.vendorSubscription) {
      this.vendorSubscription.unsubscribe();
    }
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  change(value) {
    if (value && value.length > 0) {
      const selected = this.all.find(item => item.ID === value[0]);
      if (selected) {
        if (selected.type === 'vendor') {
          this.vendorFormControl.setValue([selected.ID]);
          const times = this.times.filter(item => item.VendorId === selected.ID);
          if (times && times.length > 0) {
            this.timeFormControl.setValue([times[0].ID]);
          }
        } else {
          this.vendorFormControl.setValue([selected.VendorId]);
          this.timeFormControl.setValue([selected.ID]);
        }
        this.empty = false;
      }
    }
  }

  debug(event, note) {
    console.log('debug', event, note);
  }
}
