import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { DragulaService } from 'ng2-dragula';
import { LocalStorageService } from 'angular-2-local-storage';
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RenameComponent } from '../../modals/rename/rename.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RemoteDataSource } from './remote-data-source';

@Component({
  selector: 'app-my-smart-table',
  templateUrl: './my-smart-table.component.html',
  styleUrls: ['./my-smart-table.component.scss'],
})
export class MySmartTableComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  click(event) {
    const column = event.target.closest('.column-block');
    if (!column) {
      this.columnExpanded = false;
    }
    const filter = event.target.closest('.filter-block');
    if (!filter) {
      this.filterExpanded = false;
    }
    if (this.preset?.Filter?.Fields?.length > 0) {
      for (let i = 0; i < this.preset.Filter.Fields.length; i++) {
        const name = this.preset.Filter.Fields[i].Name;
        if (!event.target.closest('[data-field=' + name + ']')) {
          this.preset.Filter.Fields[i].Expanded = false;
        }
      }
    }
    const sort = event.target.closest('.sort-block');
    if (!sort) {
      this.sortExpanded = false;
    }
  }
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          const classes = item.classList
            .toString()
            .split(/\s+/)
            .filter(cl => ['ng2-smart-th', 'ng-star-inserted'].indexOf(cl) < 0);
          if (classes && classes.length > 0) {
            const style = item.getAttribute('style');
            const res = style.match(/width: (\d+)px;?/);
            if (res) {
              const index = this.preset.Columns.findIndex(item2 => item2.Name === classes[0]);
              if (index >= 0) {
                this.preset.Columns[index].Width = res[1];
              }
            }
          }
        }
      });
    }
  }
  @Input('type') type = 'products';
  @Input('bulks') bulks;
  @Input('columns') columns;
  @Output() columnsChange = new EventEmitter<boolean>();
  columnExpanded = false;
  @Input('parent') parent;
  @Input('presets') presets;
  @Input('preset') preset;
  @Input('filters') filters;
  filter;
  filterExpanded = false;
  @Input('sorts') sorts;
  sort;
  sortExpanded = false;
  @Input('draggable') draggable = false;
  @Input('source') source: DataSource;
  @Output() sourceChange = new EventEmitter<DataSource>();
  @Input('term') term;
  @Output() termChange = new EventEmitter<boolean>();

  params: Params;

  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
  };

  multiSelectTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    defaultTitle: 'Category',
    allSelected: 'All selected',
  };

  billingMultiSelectTexts: IMultiSelectTexts;
  categoryMultiSelectTexts: IMultiSelectTexts;
  contentTypeMultiSelectTexts: IMultiSelectTexts;
  shippingMultiSelectTexts: IMultiSelectTexts;
  statusMultiSelectTexts: IMultiSelectTexts;
  tagMultiSelectTexts: IMultiSelectTexts;
  valueMultiSelectTexts: IMultiSelectTexts;
  vendorMultiSelectTexts: IMultiSelectTexts;
  //
  field;
  value;
  //
  termChanged: Subject<string> = new Subject<string>();
  queryParamsSubscription: Subscription;
  dragulaSubscription: Subscription;
  termSubscription: Subscription;

  resized = false;
  expanded = false;

  selected;
  selectedAll = false;
  settings;
  perPage = 10;

  row;
  timer;
  hideCols;

  visible;
  filtered;

  filterConf;
  pagingConf;
  sortConf;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private apiService: ApiService,
    protected http: HttpClient,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private dragulaService: DragulaService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const those = this;

    this.billingMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Billing' };
    this.categoryMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Category' };
    this.contentTypeMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'ContentType' };
    this.shippingMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Shipping' };
    this.statusMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Status' };
    this.tagMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Tag' };
    this.valueMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Value' };
    this.vendorMultiSelectTexts = { ...this.multiSelectTexts, defaultTitle: 'Vendor' };

    // Load presets
    try {
      const presets = JSON.parse(this.localStorageService.get(this.type + '_presets'));
      if (presets) {
        for (let i = 0; i < presets.length; i++) {
          this.presets.push(presets[i]);
        }
      }
    } catch (err) {}

    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      /*if (params['search']) {
        those.preset.term = params['search'];
      }*/
      this.params = params;
    });

    this.dragulaSubscription = this.dragulaService.drop().subscribe(value => {
      those.setColumns();
    });

    this.termSubscription = this.termChanged.pipe(debounceTime(1000)).subscribe(() => {
      those.source.refresh();
    });

    if (this.presets.length > 0 && !this.preset) {
      this.onPresetSet(this.presets[0]);
    }
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
    if (this.dragulaSubscription) {
      this.dragulaSubscription.unsubscribe();
    }
    if (this.termSubscription) {
      this.termSubscription.unsubscribe();
    }
  }

  refresh(full?: boolean) {
    if (full) {
      this.source = undefined;
      this.visible = false;
      this.createSource();
    } else {
      this.source.refresh();
    }
  }

  createSource() {
    const those = this;
    this.settings = {
      mode: 'external',
      draggable: those.draggable,
      actions: {
        add: false,
        columnTitle: those.translate.instant('Actions'),
        position: 'right',
        edit: false,
        delete: false,
      },
      pager: {
        display: true,
      },
    };
    // Start with default columns list
    let columns = { ...this.columns };
    const hasColumns = this.preset.Columns?.length > 0;
    Object.keys(columns).forEach(item => {
      columns[item]['onComponentInitFunction'] = instance => {
        instance.parent = those;
      };
      const index = this.preset.Columns?.findIndex(item2 => item2.Name === item);
      if ((index === undefined || index === -1) && columns[item]['title']) {
        this.preset.Columns = this.preset.Columns || [];
        this.preset.Columns.push({
          Name: item,
          Label: columns[item]['title'],
          Selected: !hasColumns,
        });
      }
    });
    // apply preset settings
    if (this.preset?.Columns?.length > 0) {
      // sort and hide
      for (let i = 0; i < this.preset.Columns.length; i++) {
        const name = this.preset.Columns[i]['Name'];
        if (columns[name] && this.preset.Columns[i]['Selected'] !== undefined) {
          columns[name].Selected = this.preset.Columns[i]['Selected'];
          if (this.preset.Columns[i]['Selected'] === false) {
            delete columns[name];
          }
        }
        // set width
        if (columns[name] && this.preset.Columns[i]['Width']) {
          columns[name].width = this.preset.Columns[i]['Width'] + 'px';
        }
      }
      // build
      columns = Object.keys(columns)
        // 'Selected' should not participate in sort
        .filter(item => item !== 'Selected')
        .sort((a, b) => {
          if (
            this.preset.Columns.findIndex(item => item.Name === a) >
            this.preset.Columns.findIndex(item => item.Name === b)
          ) {
            return 1;
          } else {
            return -1;
          }
        })
        .reduce(
          (obj, key) => {
            obj[key] = columns[key];
            return obj;
          },
          // 'Selected' always first
          {
            Selected: those.columns.Selected,
          }
        );
    }
    //
    this.settings.columns = columns;
    this.settings.pager.perPage = this.preset.PerPage || 10;
    // Filters
    const filters = [];
    if (this.preset.Filter && this.preset.Filter.On) {
      for (let i = 0; i < this.preset.Filter.Fields.length; i++) {
        const name = this.preset.Filter.Fields[i].Name;
        const type = this.preset.Filter.Fields[i].Type;
        if (type === 'multiselect') {
          filters.push({ field: name, search: this.preset.Filter.Fields[i]['Value']?.join(';') });
        } else if (['date', 'select'].indexOf(type) >= 0) {
          filters.push({ field: name, search: this.preset.Filter.Fields[i]['Value']['Value'] });
        } else {
          filters.push({ field: name, search: this.preset.Filter.Fields[i]['Value'] });
        }
      }
    }
    // Sort
    /*const sort = [];
    if (this.preset.Sort) {
      sort.push({
        field: this.preset.Sort.Name,
        direction: this.preset.Sort.Direction ? this.preset.Sort.Direction : 'asc',
      });
      sort.push(...this.preset.Sort);
    } else {
      sort.push({ field: 'Created', direction: 'desc' });
    }*/
    //
    try {
      this.filterConf = { filters, andOperator: true };
      //this.sortConf = sort;
      const source = new RemoteDataSource(those.http, those.apiService);
      source.type = this.type;
      source['filtering'] = true;
      source['filterConf'] = this.filterConf;
      // sortConf
      source['sortConf'] =
        (!this.preset.Sort || this.preset.Sort.length === 0) && this.sorts.length > 0
          ? [
              {
                field: this.sorts[0].Name,
                direction: this.sorts[0].Order[0].Type,
              },
            ]
          : this.preset.Sort || [];
      //
      source['callback'] = () => {
        const table = document.querySelector('table');
        const thead = table.querySelector('thead');
        if (thead) {
          const cols = thead.querySelectorAll('tr.ng2-smart-titles th:not(.Selected):not(.Favorite)');
          [].forEach.call(cols, col => {
            const existing = col.querySelectorAll('.resizer');
            for (let i = 0; i < existing.length; i++) {
              existing[i].remove();
            }
            const resizer = document.createElement('div');
            resizer.classList.add('resizer');
            resizer.style.height = `${
              ['customers', 'orders'].indexOf(those.type) >= 0 ? thead['offsetHeight'] : table['offsetHeight']
            }px`;
            col.appendChild(resizer);
            window['createResizableColumn'](col, resizer);
          });
        }
      };
      source['parent'] = those;
      //
      if (filters?.length > 0) {
        source['filtering'] = true;
      }
      this.source = source;
      this.sourceChange.emit(this.source);
    } catch (err) {
      console.log(err);
    } finally {
      those.filtered = true;
    }
  }

  findIndex(array, name) {
    return array.findIndex(item => item.Name === name);
  }

  getOrders(array, name) {
    const index = this.findIndex(array, name);
    if (index >= 0) {
      return this.sorts[index] ? this.sorts[index]['Order'] : [];
    }
    return [];
  }

  availableFilters(array) {
    return array.filter(item => this.preset.Filter?.Fields?.findIndex(item2 => item2.Name === item.Name) < 0);
  }

  singularify(key) {
    if (key === 'categories') {
      return 'category';
    }
    return key.replace(/s$/i, '');
  }

  /* Bulk */
  onBulkAction(action?) {
    const those = this;
    switch (action) {
      case 'set-active':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patch(those.type, those.source['data'][i]['ID'], 'setEnabled', {
                    Value: true,
                  })
                  .then(resp => {
                    those.source['data'][i]['Enabled'] = true;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-inactive':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patch(those.type, those.source['data'][i]['ID'], 'setEnabled', {
                    Value: false,
                  })
                  .then(resp => {
                    those.source['data'][i]['Enabled'] = false;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-standard':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patch(those.type, those.source['data'][i]['ID'], 'setStandard', {
                    Value: true,
                  })
                  .then(resp => {
                    those.source['data'][i]['Standard'] = true;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-not-standard':
        (async function loop() {
          for (let i = 0; i < those.source['data'].length; i++) {
            if (those.source['data'][i]['Selected']) {
              await new Promise(resolve =>
                those.apiService
                  .patch(those.type, those.source['data'][i]['ID'], 'setStandard', {
                    Value: false,
                  })
                  .then(resp => {
                    those.source['data'][i]['Standard'] = false;
                    resolve();
                  })
              );
            }
          }
          await new Promise(resolve => {
            those.source['local'] = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'delete':
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        const name =
          those.source['data'].filter(item => item['Selected']).length <= 1
            ? those.singularify(those.type)
            : those.type;
        modal.componentInstance.title = `Delete ${name}?`;
        modal.componentInstance.body = `Are you sure you want to delete ${name}? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            (async function loop() {
              for (let i = 0; i < those.source['data'].length; i++) {
                if (those.source['data'][i]['Selected']) {
                  const id = those.source['data'][i]['ID'];
                  await new Promise(resolve =>
                    those.apiService.delete(those.type, id).then(() => {
                      those.source['data'][i]['Enabled'] = false;
                      those.source['data'] = those.source['data'].filter(item => item.ID !== id);
                      resolve();
                    })
                  );
                }
              }
              await new Promise(resolve => {
                those.source['local'] = true;
                those.source.refresh();
                resolve();
              });
            })();
            this.selected = undefined;
          },
          reason => {}
        );
        break;
      default:
        this.selected = this.source['data'].filter(item => item['Selected']);
    }
  }

  /* Columns */

  setColumns(event?) {
    this.refresh(true);
    if (event) {
      event.stopPropagation();
    }
  }

  onColumnsReset(event) {
    const ths = document.querySelectorAll('.ng2-smart-titles th');
    if (ths && ths.length > 0) {
      ths.forEach(item => {
        if (item.hasAttribute('style')) {
          item.removeAttribute('style');
        }
      });
    }
    for (let i = 0; i < this.preset.Columns.length; i++) {
      delete this.preset.Columns[i].Width;
    }
    this.resized = false;
    event.stopPropagation();
    //this.onPresetSaves();
  }

  /* Presets */
  onPresetApply() {
    if (!this.source) {
      this.createSource();
    } else {
      //this.source['reset']();
      this.refresh(true);
    }
  }
  onPresetClick(preset, event?) {
    if (!this.preset || this.preset.Id !== preset.Id) {
      this.onPresetSet(preset);
    }
  }
  onPresetDblclick(preset) {
    if (this.preset?.Id === preset.Id) {
      this.onPresetRename();
    }
  }
  onPresetSet(preset) {
    this.preset = preset;
    this.perPage = preset.PerPage || 10;
    this.resized = preset.Columns?.filter(item => item.Width).length > 0;
    this.term = preset.Term ? preset.Term : this.params && this.params['search'] ? this.params['search'] : '';
    this.onPresetApply();
  }

  onPresetDelete(preset) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = `Delete ${preset.Label}`;
    modal.componentInstance.body = `Are you sure you want to delete ${preset.Label}?`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        if (result === 'Confirmed') {
          const index = those.presets.findIndex(item => item.Id === those.preset.Id);
          if (index >= 0) {
            those.presets.splice(index, 1);
          }
          those.localStorageService.set(
            those.type + '_presets',
            JSON.stringify(those.presets.filter(item => item.Type === 'custom'))
          );
          those.presets = those.presets.filter(item => item.Id !== preset.Id);
          those.onPresetSet(those.presets[0]);
        }
      },
      reason => {}
    );
  }

  onPresetRename() {
    const those = this;
    const modal = this.modalService.open(RenameComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    const name = this.preset.Label;
    modal.componentInstance.title = `Rename ${name}?`;
    modal.componentInstance.description = `Saved filters will be saved as tabs at the top of this page.`;
    modal.componentInstance.message = `Filter name`;
    modal.componentInstance.placeholder = `enter your filter name`;
    modal.componentInstance.value = name;
    modal.componentInstance.confirm = 'Save';
    modal.result.then(
      result => {
        if (result) {
          those.preset.Label = result;
        }
      },
      reason => {}
    );
  }

  onPresetSave() {
    const those = this;
    if (this.preset.Type !== 'custom') {
      const preset = {
        ...JSON.parse(JSON.stringify(this.preset)),
        Id: new Date().getTime().toString(36),
        Type: 'custom',
        Label: this.translate.instant('Custom Filter'),
      };
      while (true) {
        const res = preset.Label.match(/(\d+)$/);
        if (res) {
          preset.Label = preset.Label.replace(/(\d+)$/, Number(res[1]) + 1);
        } else {
          preset.Label += ' 2';
        }
        if (this.presets.findIndex(item => item.Label === preset.Label) < 0) {
          break;
        }
      }
      if (this.term) {
        preset.Term = this.term;
      }
      const modal = this.modalService.open(RenameComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'md',
        centered: true,
      });
      const name = preset.Label;
      modal.componentInstance.title = `Save as ${name}?`;
      modal.componentInstance.description = `Saved filters will be saved as tabs at the top of this page.`;
      modal.componentInstance.message = `Filter name`;
      modal.componentInstance.placeholder = `enter your filter name`;
      modal.componentInstance.value = name;
      modal.componentInstance.confirm = 'Save';
      modal.result.then(
        result => {
          if (result) {
            preset.Label = result;
            those.presets.splice(those.presets.length, 0, preset);
            those.onPresetSet(preset);
            //
            those.localStorageService.set(
              those.type + '_presets',
              JSON.stringify(those.presets.filter(item => item.Type === 'custom'))
            );
          }
        },
        reason => {}
      );
    } else {
      this.preset.Term = this.term;
      those.localStorageService.set(
        those.type + '_presets',
        JSON.stringify(those.presets.filter(item => item.Type === 'custom'))
      );
    }
  }

  /* Filters */
  onFilterSet(index, value?) {
    if (this.preset && this.preset.Filter && this.preset.Filter.Fields) {
      const type = this.preset.Filter.Fields[index].Type;
      if (type === 'date') {
        if (!value || value.Value === 'custom') {
          let start = '';
          try {
            start = this.preset.Filter.Fields[index].Start.format('YYYY-MM-DD');
          } catch (err) {}
          let end = '';
          try {
            end = this.preset.Filter.Fields[index].End.format('YYYY-MM-DD');
          } catch (err) {}
          let label = `${start} - ${end}`;
          if (!start && end) {
            label = 'till ' + end;
          } else if (start && !end) {
            label = 'from ' + start;
          } else if (!start && !end) {
            return;
          }
          this.preset.Filter.Fields[index].Value = { Label: label, Value: `${start} - ${end}` };
        } else if (value) {
          this.preset.Filter.Fields[index].Value = value;
        }
      } else {
        if (this.preset.Filter.Fields[index]) {
          this.preset.Filter.Fields[index].Value = value;
        }
      }
    }
    this.onPresetApply();
  }

  onFilterSelect(filter) {
    this.preset.Filter.Fields.push({
      Name: filter.Name,
      Label: filter.Label,
      Kind: filter.Kind,
      Type: filter.Type,
      Placeholder: filter.Placeholder,
      Value: filter.Value,
    });
    setTimeout(() => {
      const filters = document.querySelectorAll('.filter-existing');
      if (filters && filters.length > 0) {
        const button = filters[filters.length - 1].querySelector('button');
        if (button) {
          button.click();
          return;
        }
        const input = filters[filters.length - 1].querySelector('input');
        if (input) {
          input.focus();
          return;
        }
      }
    }, 100);
  }

  onFilterDelete(index) {
    this.preset.Filter.Fields.splice(index, 1);
    this.onPresetApply();
  }

  onFiltersDelete() {
    this.preset.Filter.Fields = [];
    this.onPresetApply();
  }

  onSortChange(field?, direction?) {
    if (field) {
      this.source['sortConf'][0]['field'] = field;
    }
    if (direction) {
      this.source['sortConf'][0]['direction'] = direction;
    }
    this.source.refresh();
  }

  onPerPageSet(value: number) {
    this.preset.PerPage = value;
    //this.onPresetSave();
    this.refresh(true);
  }

  onSelectAllToggle(event) {
    for (let i = 0; i < this.source['data'].length; i++) {
      this.source['data'][i]['Selected'] = event;
    }
    this.source['local'] = true;
    this.source.refresh();
    this.selected = this.source['data'].filter(item => item['Selected']);
  }

  onRowDragStart(event) {
    this.row = event;
  }

  onRowDrop(event) {
    const those = this;
    const source = this.row.data.ID;
    const destination = event.data.ID;
    const ids = [];
    const sorts = [];
    let from, to;
    for (let i = 0; i < this.source['data'].length; i++) {
      if (this.source['data'][i]['ID'] === Number(source)) {
        from = i;
      }
      if (this.source['data'][i]['ID'] === Number(destination)) {
        to = i;
      }
      ids.push(this.source['data'][i]['ID']);
      sorts.push(this.source['data'][i]['Sort']);
    }
    const idsOrig = [...ids];
    const sortsOrig = [...sorts];
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    const id = ids.splice(from, 1);
    ids.splice(to, 0, id[0]);
    console.log('ids', JSON.stringify(ids), 'sorts', JSON.stringify(sorts));
    const update = [];
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] !== idsOrig[i] || sorts[i] !== sortsOrig[i]) {
        const params = {};
        Object.keys(those.params).forEach(key => {
          if (!isNaN(those.params[key])) {
            params[key] = Number(those.params[key]);
          }
        });
        update.push(
          those.apiService.patch(those.type, ids[i], 'setSort', { ...params, Sort: Number(sorts[i]) }).catch(err => {
            console.log(err);
          })
        );
      }
    }
    const row = this.source['data'].splice(from, 1);
    this.source['data'].splice(to, 0, row[0]);
    for (let i = 0; i < this.source['data'].length; i++) {
      this.source['data'][i]['Sort'] = sorts[i];
    }
    this.source['local'] = true;
    this.source.refresh();
    //
    this.timer = setTimeout(() => {
      Promise.all(update).catch(err => {
        console.log(err);
      });
    }, 1000);
  }
}
