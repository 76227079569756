<div class="smart-filter">
  <div class="row" style="padding-bottom: 20px">
    <div class="col">
      <div class="presets">
        <span *ngFor="let item of presets; let i = index" class="body-2 preset" [ngClass]="{selected: (!preset && i == 0) || (preset && preset.Id == item.Id)}" (click)="setPreset(item)" [innerHTML]="item.Label | translate"></span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 mb-2">
      <div class="table-top" style="position: relative">
        <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
          </svg>
        </div>
        <input type="text" class="form-control search" placeholder="{{'search' | translate}}" (keyup)="termChanged.next()" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
      </div>
    </div>
  </div>
  <!-- Filter -->
  <div class="row" style="padding-bottom: 10px">
    <div class="col" style="display: flex; gap: 5px;">

      <!-- Selected -->
      <div *ngIf="selected && selected.length > 0" class="selected">
        <div style="display: flex; align-items: center; gap: 5px;">
          <div class="body-2">
            <span style="padding-right: 5px;">{{selected.length}}</span>
            <span translate>of</span> {{source['filtered']()}} <span translate>products selected</span>
          </div>
          <div class="btn btn-sm btn-default delete" style="display: flex; padding: 7px;" (click)="bulk('delete')">
            <svg style="height: 18px; width: 18px">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
            </svg>
          </div>
          <div class="button-group-dropdown">
            <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
              <span translate>Choose actions</span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li class="body-2" (click)="bulk('set-active')" translate>Set active</li>
              <li class="body-2" (click)="bulk('set-inactive')" translate>Set inactive</li>
              <!--li *ngIf="cid && selected && selected.length == 1" class="body-2" (click)="bulk('put-to')">
                <app-edit-position (onSaved)="onEditPosition($event)" #positionEditor>
                  <span data-target="#exampleModal" (click)="positionEditor.onEdit(positionEditor.content, 1)" translate> Set position </span>
                </app-edit-position>
              </li-->
            </ul>
          </div>
        </div>
        <!--div class="col">
          <div *ngIf="selected" class="button-group">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" translate>Bulk <span class="caret"></span></button>
            <ul class="dropdown-menu">
              <li style="cursor: pointer; padding: 5px 10px;" (click)="bulk('set-public')"><span>Set public</span></li>
              <li style="cursor: pointer; padding: 5px 10px;" (click)="bulk('set-draft')"><span>Set draft</span></li>
            </ul>
          </div>
        </div-->
      </div>
      <!-- /Selected -->

      <div *ngIf="preset && preset.Filter && preset.Filter" class="filters">

        <!--div class="filter" style="vertical-align: middle">
          <input type="text" class="form-control" style="height: 30px; max-width: 140px" placeholder="{{'Custom filter name' | translate}}" [(ngModel)]="preset.Label">
        </div-->

        <div *ngFor="let field of preset.Filter.Fields; let i = index" class="filter-existing">
          <!-- Multiselect -->
          <div *ngIf="['Category', 'Vendor'].indexOf(field.Name) >= 0" class="filter filter-categories button-group-dropdown" [ngClass]="{'filter-categories' : field.Name === 'Category', 'filter-vendors' : field.Name === 'Vendor'}">
            <ngx-bootstrap-multiselect class="multiselect" [ngClass]="{selected: field.Value && field.Value.length}" [options]="filters[findIndex(filters, field.Name)].Values"
                                       [settings]="multiselectSettings" [texts]="multiSelectTexts" (ngModelChange)="setFilter(i, $event)" [(ngModel)]="field.Value"></ngx-bootstrap-multiselect>
          </div>
          <!-- /Multiselect -->

          <!-- Select -->
          <div *ngIf="['Enabled', 'Favorite'].indexOf(field.Name) >=0 " class="filter button-group-dropdown select">
            <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
              <span [innerText]="field.Label | translate"></span>
              <span *ngIf="field.Value" class="before-colon" [innerHtml]="field.Value.Label | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let v of filters[findIndex(filters, field.Name)].Values; let j = index" class="body-2" (click)="setFilter(i, v)" translate>
                {{v.Label}}
              </li>
            </ul>
          </div>
          <!-- /Select -->

          <div class="filter-existing__delete" (click)="onFilterDelete(i)">
            <svg style="stroke: red;" width="9" height="9" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0466 11.664L6 7.61864L1.95341 11.664C1.84738 11.7705 1.72135 11.855 1.58257 11.9127C1.44379 11.9703 1.29499 12 1.14471 12C0.994428 12 0.845626 11.9703 0.706846 11.9127C0.568067 11.855 0.442043 11.7705 0.33601 11.664V11.664C0.229512 11.558 0.145007 11.4319 0.087345 11.2932C0.029683 11.1544 0 11.0056 0 10.8553C0 10.705 0.029683 10.5562 0.087345 10.4174C0.145007 10.2786 0.229512 10.1526 0.33601 10.0466L4.38136 6L0.33601 1.95341C0.229512 1.84738 0.145007 1.72135 0.087345 1.58257C0.029683 1.44379 0 1.29499 0 1.14471C0 0.994428 0.029683 0.845626 0.087345 0.706846C0.145007 0.568067 0.229512 0.442043 0.33601 0.33601V0.33601C0.442043 0.229512 0.568067 0.145007 0.706846 0.087345C0.845626 0.029683 0.994428 0 1.14471 0C1.29499 0 1.44379 0.029683 1.58257 0.087345C1.72135 0.145007 1.84738 0.229512 1.95341 0.33601L6 4.38136L10.0466 0.33601C10.1526 0.229512 10.2786 0.145007 10.4174 0.087345C10.5562 0.029683 10.705 0 10.8553 0C11.0056 0 11.1544 0.029683 11.2932 0.087345C11.4319 0.145007 11.558 0.229512 11.664 0.33601V0.33601C11.7705 0.442043 11.855 0.568067 11.9127 0.706846C11.9703 0.845626 12 0.994428 12 1.14471C12 1.29499 11.9703 1.44379 11.9127 1.58257C11.855 1.72135 11.7705 1.84738 11.664 1.95341L7.61864 6L11.664 10.0466C11.7705 10.1526 11.855 10.2786 11.9127 10.4174C11.9703 10.5562 12 10.705 12 10.8553C12 11.0056 11.9703 11.1544 11.9127 11.2932C11.855 11.4319 11.7705 11.558 11.664 11.664V11.664C11.558 11.7705 11.4319 11.855 11.2932 11.9127C11.1544 11.9703 11.0056 12 10.8553 12C10.705 12 10.5562 11.9703 10.4174 11.9127C10.2786 11.855 10.1526 11.7705 10.0466 11.664V11.664Z" fill="#ff0000"/>
            </svg>
          </div>
        </div>

        <!--div class="filter-wrapper">
          <div class="filter button-group-dropdown">
            <button type="button" class="btn btn-filter dropdown-toggle" style="background-color: white; margin: 0;" data-toggle="dropdown">
              <span [innerText]="(field ? field: 'Select filter') | translate"></span>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let key of objectKeys(filters)" (click)="field = key" translate>
                {{key}}
              </li>
            </ul>
          </div>
        </div-->

        <!--div class="filter filter-button button-group-dropdown">
          <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" style="margin: 0;" (click)="addFilter()">
            <svg class="stroke-primary-text-gray" style="margin: 0 0 0 4px;height: 16px;width: 16px; transform: rotate(90deg); vertical-align: initial;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-settings-product-settings"></use>
            </svg>
          </button>
        </div-->

        <!-- Filter -->
        <div class="filter filter-button button-group-dropdown filter-block" [ngClass]="{show: filterExpanded}" style="position: relative">
          <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" aria-expanded="false" [attr.aria-expanded]="filterExpanded" (click)="filterExpanded=!filterExpanded">
            <svg class="stroke-primary-text-gray" style="height: 16px;width: 16px; transform: rotate(90deg); vertical-align: initial;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-settings-product-settings"></use>
            </svg>
            <span translate>Filter</span>
          </button>
          <div class="dropdown-menu filter-new" [ngClass]="{show: filterExpanded}">
            <div class="filter-new__label body-4-regular" translate>
              Filter by
            </div>
            <div class="filter-new__body pb-2">
              <div class="filter-new__key button-group-dropdown">
                <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                  <span [innerText]="filter?.Label ? filter.Label : 'Select filter' | translate"></span>
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let filter of availableFilters(filters); let i = index" class="body-2" (click)="onFilterChange(filter)" translate>{{filter.Label}}</li>
                </ul>
              </div>
              <!-- -->
              <div *ngIf="filter" class="filter-new__value filter button-group-dropdown">
                <!-- Multiselect -->
                <ngx-bootstrap-multiselect *ngIf="['Category', 'Vendor'].indexOf(filter.Name) >= 0" class="multiselect" [ngClass]="{selected: filter.Values && filter.Values.length}" [options]="filter.Values"
                                           [settings]="multiselectSettings" [texts]="multiSelectTexts" [(ngModel)]="filter.Value"></ngx-bootstrap-multiselect>
                <!-- Select -->
                <div *ngIf="['Enabled', 'Favorite'].indexOf(filter.Name) >= 0" class="filter button-group-dropdown">
                  <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                    <span [innerText]="filter.Value ? filter.Value.Label : 'Select value' | translate"></span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li *ngFor="let v of filters[findIndex(filters, filter.Name)].Values; let i = index" class="body-2" (click)="filter.Value = v" translate>
                      {{v.Label}}
                    </li>
                  </ul>
                </div>

              </div>
              <!-- -->
              <div *ngIf="filter" class="filter-new__close" (click)="onFilterClear()">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0466 11.664L6 7.61864L1.95341 11.664C1.84738 11.7705 1.72135 11.855 1.58257 11.9127C1.44379 11.9703 1.29499 12 1.14471 12C0.994428 12 0.845626 11.9703 0.706846 11.9127C0.568067 11.855 0.442043 11.7705 0.33601 11.664C0.229512 11.558 0.145007 11.4319 0.087345 11.2932C0.029683 11.1544 0 11.0056 0 10.8553C0 10.705 0.029683 10.5562 0.087345 10.4174C0.145007 10.2786 0.229512 10.1526 0.33601 10.0466L4.38136 6L0.33601 1.95341C0.229512 1.84738 0.145007 1.72135 0.087345 1.58257C0.029683 1.44379 0 1.29499 0 1.14471C0 0.994428 0.029683 0.845626 0.087345 0.706846C0.145007 0.568067 0.229512 0.442043 0.33601 0.33601C0.442043 0.229512 0.568067 0.145007 0.706846 0.087345C0.845626 0.029683 0.994428 0 1.14471 0C1.29499 0 1.44379 0.029683 1.58257 0.087345C1.72135 0.145007 1.84738 0.229512 1.95341 0.33601L6 4.38136L10.0466 0.33601C10.1526 0.229512 10.2786 0.145007 10.4174 0.087345C10.5562 0.029683 10.705 0 10.8553 0C11.0056 0 11.1544 0.029683 11.2932 0.087345C11.4319 0.145007 11.558 0.229512 11.664 0.33601C11.7705 0.442043 11.855 0.568067 11.9127 0.706846C11.9703 0.845626 12 0.994428 12 1.14471C12 1.29499 11.9703 1.44379 11.9127 1.58257C11.855 1.72135 11.7705 1.84738 11.664 1.95341L7.61864 6L11.664 10.0466C11.7705 10.1526 11.855 10.2786 11.9127 10.4174C11.9703 10.5562 12 10.705 12 10.8553C12 11.0056 11.9703 11.1544 11.9127 11.2932C11.855 11.4319 11.7705 11.558 11.664 11.664C11.558 11.7705 11.4319 11.855 11.2932 11.9127C11.1544 11.9703 11.0056 12 10.8553 12C10.705 12 10.5562 11.9703 10.4174 11.9127C10.2786 11.855 10.1526 11.7705 10.0466 11.664Z" fill="#292F46"/>
                </svg>
              </div>
            </div>
            <div *ngIf="filter && filter.Name" class="filter-new__footer">
              <span class="filter-new__add body-3 color-primary-violet" (click)="onFilterAdd(filter)">+ Add filter</span>
            </div>
          </div>
        </div>
        <!-- /Filter -->

        <!-- Columns -->
        <div *ngIf="columns" class="filter filter-button button-group-dropdown filter-block">
          <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
            <svg style="height: 20px; width: 20px; vertical-align: bottom;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-columns"></use>
            </svg>
            <span translate>Columns</span>
          </button>
          <ul class="columns dropdown-menu" dragula="columns" [(dragulaModel)]="columns">
            <li *ngFor="let column of columns; let i = index" class="column" (click)="$event.stopPropagation()" [attr.data-name]="column.Name">
              <div>
                <input type="checkbox" [attr.id]="'column-' + i" class="checkbox_animated" [(ngModel)]="column['Selected']">
                <label [attr.for]="'column-' + i" class="mb-1" translate>{{column.Label}}</label>
              </div>
              <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="15" height="22" rx="2" fill="#5C22CF" fill-opacity="0.3"/>
                <circle cx="4.77778" cy="4.77778" r="1.77778" fill="white"/>
                <circle cx="4.77778" cy="10.9999" r="1.77778" fill="white"/>
                <circle cx="4.77778" cy="17.2222" r="1.77778" fill="white"/>
                <circle cx="10.1108" cy="4.77778" r="1.77778" fill="white"/>
                <circle cx="10.1108" cy="10.9999" r="1.77778" fill="white"/>
                <circle cx="10.1108" cy="17.2222" r="1.77778" fill="white"/>
              </svg>
            </li>
            <li><span class="btn btn-primary" style="width: 100%" (click)="setColumns($event)">Save</span></li>
            <li style="text-align: center"><span class="btn btn-default" style="color: black;" (click)="resetColumns($event)">Reset</span></li>
            <li style="text-align: center"><span class="btn btn-default" style="color: black;" (click)="clearColumns($event)">Clear</span></li>
          </ul>
        </div>
        <!-- /Columns -->

        <!-- Sort -->
        <div class="filter filter-button button-group-dropdown sort-block" [ngClass]="{show: sortExpanded}" style="position: relative">
          <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" aria-expanded="false" [attr.aria-expanded]="expanded" (click)="sortExpanded=!sortExpanded">
            <svg class="stroke-primary-text-gray" style="height: 16px;width: 16px; vertical-align: initial;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-sort"></use>
            </svg>
            <span translate>Sort</span>
          </button>
          <div class="dropdown-menu sort" [ngClass]="{show: sortExpanded}">
            <div class="sort__label body-4-regular" translate>
              Sort by
            </div>
            <div class="sort__body pb-2">
              <div *ngFor="let s of sorts; let i = index">
                <input type="radio" [attr.id]="'sort-' + i" name="sort" class="radio_animated" [value]="s" (ngModelChange)="onSortChange(s.Name)" [(ngModel)]="sort">
                <label [attr.for]="'sort-' + i" class="body-5-regular">
                  {{s.Label | translate}}
                </label>
              </div>
            </div>
            <div *ngIf="sort && sort.Name" class="sort__footer">
              <div *ngFor="let order of sort.Order; let i = index" class="sort__direction" [ngClass]="{selected: sort.Direction === order.Type}" (click)="onSortChange(sort.Name, order.Type)">
                <svg class="stroke-primary-text-gray" style="height: 16px;width: 16px; transform: rotate(-90deg); vertical-align: initial;" [ngStyle]="{transform: order.Type === 'asc' ? 'rotate(-90deg)' : 'rotate(90deg)'}">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-navigation-right-arrow"></use>
                </svg>
                <div class="body-3" [innerText]="order.Label | translate"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Sort -->

        <!-- Save -->
        <div class="filter filter-button button-group-dropdown">
          <button type="button" class="btn btn-filter facon no-expand-icon dropdown-toggle" (click)="savePresets()">
            <svg style="height: 20px; width: 20px; vertical-align: bottom;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-save"></use>
            </svg>
            <span translate>Save</span>
          </button>
        </div>
        <!-- /Save -->

        <!-- Other -->
        <div *ngIf="preset.Type === 'custom'" class="filter filter-button button-group-dropdown">
          <button type="button" class="btn btn-filter no-expand-icon dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
            <svg style="margin: 0 0 0 4px; height: 20px; width: 20px; vertical-align: bottom;">
              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-other-more---horizontal"></use>
            </svg>
          </button>
          <ul class="dropdown-menu">
            <li (click)="onFiltersDelete()" translate>
              Clear
            </li>
            <li (click)="renamePreset()" translate>
              Rename
            </li>
            <li style="color: red;" (click)="deletePreset()" translate>
              Delete
            </li>
          </ul>
        </div>
        <!-- /Other -->

        <!--div class="filter button-group-dropdown">
          <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
            <span translate>Status</span>
            <span *ngIf="preset.Filter && preset.Filter.Fields && preset.Filter.Fields.Enabled" class="before-colon" [innerHTML]="preset.Filter.Fields.Enabled.Label | translate"></span>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let s of filters[1].Values; let i = index" class="body-2" (click)="setFilter('Enabled', s)" translate>
              {{s.Label}}
            </li>
          </ul>
        </div-->

      </div>
    </div>
  </div>
</div>
