import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ApiService } from '../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { credentials } from 'src/app/shared/credentials.data';
import { PricesDataSource } from './prices.data-source';
import { HttpClient } from '@angular/common/http';
import { PriceSelectedRenderComponent } from './price-selected-render.component';
import { PriceDynamicRenderComponent } from './price-dynamic-render.component';
import { PriceEditableRenderComponent } from './price-editable-render.component';
import { PriceThumbnailRenderComponent } from './price-thumbnail-render.component';
import { PriceOptionsRenderComponent } from './price-options-render.component';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';
import * as moment from 'moment';
import { SubjectService } from '../../../shared/subject.service';
import { IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { DragulaService } from 'ng2-dragula';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/shared/modals/confirm/confirm.component';

@Component({
  selector: 'app-prices-block',
  styleUrls: ['./prices-block.component.scss'],
  template: `
    <div class="card" style="margin-bottom: 20px;">
      <div class="card-body" style="padding-bottom: 10px">
        <div class="row">
          <div class="col">
            <h2 translate>Prices</h2>
          </div>
        </div>
        <div *ngIf="!prices || prices.length == 0">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="base-price" class="mb-1" translate>Price (including tax)</label>
                <input
                  class="form-control"
                  id="base-price"
                  type="text"
                  currencyMask
                  (click)="$event.target['select']()"
                  [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                  [placeholder]="'0.00'"
                  [formControl]="basePriceFormControl" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1" translate>MSRP</label>
                <input
                  class="form-control"
                  type="text"
                  currencyMask
                  (click)="$event.target['select']()"
                  [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                  [placeholder]="'0.00'"
                  [formControl]="manufacturerPriceFormControl" />
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col">
              <div class="form-group">
                <label for="sale-price" class="mb-1" translate>SalePrice (including tax)</label>
                <input
                  class="form-control"
                  id="sale-price"
                  type="text"
                  currencyMask
                  (click)="$event.target['select']()"
                  [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                  [placeholder]="'0.00'"
                  [formControl]="salePriceFormControl" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="mb-1" translate>Cost per item</label>
                <input
                  class="form-control"
                  type="text"
                  currencyMask
                  (click)="$event.target['select']()"
                  [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
                  [placeholder]="'0.00'"
                  [formControl]="itemPriceFormControl" />
              </div>
            </div>
          </div>
          <div *ngIf="salePriceFormControl.value" class="row pb-2">
            <div class="col">
              <h5 style="display: inline-block; padding-right: 5px" translate>Limited Time Sale Price</h5>
              <span
                class="tooltip-question"
                placement="top"
                [ngbTooltip]="'Enter date if sale price is temporary' | translate">
                <svg width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                </svg>
              </span>
            </div>
          </div>
          <div *ngIf="salePriceFormControl.value" class="row pb-2">
            <div *ngIf="form.get('Start')" class="col">
              <div class="form-group">
                <label class="mb-1" translate>Start Date</label>
                <dp-date-picker
                  class="d-block"
                  mode="daytime"
                  theme="dp-material"
                  [config]="{
                    format: 'YYYY-MM-DD HH:mm:ss',
                    firstDayOfWeek: 'mo',
                    showTwentyFourHours: true,
                    showMultipleYearsNavigation: true
                  }"
                  [formControl]="startFormControl"></dp-date-picker>
              </div>
            </div>
            <div *ngIf="form.get('End')" class="col">
              <div class="form-group">
                <label class="mb-1" translate>End Date</label>
                <dp-date-picker
                  class="d-block"
                  mode="daytime"
                  theme="dp-material"
                  [config]="{
                    format: 'YYYY-MM-DD HH:mm:ss',
                    firstDayOfWeek: 'mo',
                    showTwentyFourHours: true,
                    showMultipleYearsNavigation: true
                  }"
                  [formControl]="endFormControl"></dp-date-picker>
              </div>
            </div>
          </div>
          <div class="row pb-1">
            <div class="col">
              <label class="d-block">
                <input
                  class="checkbox_animated"
                  type="checkbox"
                  (change)="toggle('advancedPurchasability', $event.target['checked'])"
                  [formControl]="form.get('AdvancedPurchasability')" />
                {{ 'Advanced Purchasability' | translate }}
              </label>
            </div>
          </div>
          <div *ngIf="form.get('AdvancedPurchasability').value">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Minimum Order Quantity</label>
                  <span
                    class="tooltip-question"
                    style="padding-left: 5px;vertical-align: sub;"
                    placement="top"
                    [ngbTooltip]="
                      'Setting this to a positive integer will enforce a minimum quantity limit when customers are ordering this product from your store'
                        | translate
                    ">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                  <input
                    class="form-control"
                    type="text"
                    (click)="$event.target['select']()"
                    (focusout)="format(form.get('MinQuantity'), 0)"
                    [formControl]="form.get('MinQuantity')" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="mb-1" translate>Maximum Order Quantity</label>
                  <span
                    class="tooltip-question"
                    style="padding-left: 5px;vertical-align: sub;"
                    placement="top"
                    [ngbTooltip]="
                      'Setting this to a positive integer will enforce a maximum quantity limit when customers are ordering this product from your store'
                        | translate
                    ">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                  <input
                    class="form-control"
                    type="text"
                    (click)="$event.target['select']()"
                    (focusout)="format(form.get('MaxQuantity'), 0)"
                    [formControl]="form.get('MaxQuantity')" />
                </div>
              </div>
            </div>
            <div class="row" style="padding-bottom: 10px;">
              <div class="col">
                <span
                  class="link2"
                  style="padding-right: 5px"
                  (click)="hasPurchasableMultiply = !hasPurchasableMultiply"
                  translate
                  >Only purchasable in multiples</span
                >
                <i *ngIf="!hasPurchasableMultiply" class="fa fa-caret-right" aria-hidden="true"></i>
              </div>
            </div>
            <div *ngIf="hasPurchasableMultiply" class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    (click)="$event.target['select']()"
                    [formControl]="form.get('PurchasableMultiply')" />
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="margin-bottom: 10px"><div class="col"></div></div>
          </div>
          <div class="row pb-1">
            <div class="col">
              <label>
                <input class="checkbox_animated" type="checkbox" [formControl]="form.get('Measurement')" />
                {{ 'Show product price per unit' | translate }}
              </label>
            </div>
          </div>
          <div *ngIf="form.get('Measurement') && form.get('Measurement').value">
            <div *ngIf="form.get('BaseMeasurement').value && form.get('TotalMeasurement').value" class="row pb-2">
              <div class="col pl-0">
                <div class="price-per-unit">
                  <span class="color-primary-text-gray pr-1" translate>Price per unit:</span
                  ><span>
                    {{ currency
                    }}{{
                      (
                        (form.get('BasePrice').value * form.get('BaseMeasurement').value) /
                        form.get('TotalMeasurement').value
                      ).toFixed(2)
                    }}/{{ form.get('BaseMeasurement').value }}{{ form.get('BaseMeasurementUnit').value }}</span
                  >
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="padding: 5px 0;margin-bottom: 10px">
              <div class="col-md-6">
                <div class="row">
                  <div class="col">
                    <div class="form-group" style="margin-bottom: 0">
                      <label class="mb-1" translate>Total product measurement</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        (click)="$event.target['select']()"
                        (focusout)="format(form.get('TotalMeasurement'), 0)"
                        [formControl]="form.get('TotalMeasurement')" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <app-select
                        [options]="weightMeasurementUnits"
                        [formControl]="form.get('TotalMeasurementUnit')"></app-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col">
                    <div class="form-group" style="margin-bottom: 0">
                      <label class="mb-1" translate>Base measurement</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="text"
                        (click)="$event.target['select']()"
                        (focusout)="format(form.get('BaseMeasurement'), 0)"
                        [formControl]="form.get('BaseMeasurement')" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <app-select
                        [options]="weightMeasurementUnits"
                        [formControl]="form.get('BaseMeasurementUnit')"></app-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="mb-0">
                <input class="checkbox_animated" type="checkbox" [formControl]="form.get('Discount')" />
                {{ 'Show bulk pricing' | translate }}
              </label>
              <span
                class="tooltip-question"
                style="padding-left: 5px;vertical-align: sub;"
                placement="top"
                [ngbTooltip]="
                  'Create bulk pricing rules to offer price discounts based on quantity breaks' | translate
                ">
                <svg width="16px" height="16px">
                  <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                </svg>
              </span>
            </div>
          </div>
          <div *ngIf="form.get('Discount') && form.get('Discount').value">
            <div class="row" style="padding: 5px 0;">
              <div class="col-6">
                <div class="form-group">
                  <label class="mb-1" translate>Discount Type</label>
                  <app-select [options]="discounts" [formControl]="form.get('DiscountType')"></app-select>
                </div>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col" style="padding: 0">
                <table [formGroup]="form" class="table" style="height: 100%; margin-bottom: 0">
                  <thead>
                    <tr>
                      <th translate>Min Quantity</th>
                      <th translate>
                        <span *ngIf="form.get('DiscountType').value == 'percent'" translate>% Discount</span>
                        <span
                          *ngIf="form.get('DiscountType').value == 'value'"
                          [innerHtml]="currency + ' ' + ('Off/Unit' | translate)"></span>
                      </th>
                      <th translate>Unit Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody [formArrayName]="'Tiers'">
                    <tr *ngFor="let tierControl of form.get('Tiers')['controls']; index as i" [formGroupName]="i">
                      <td>
                        <app-cell-input [formControlName]="'MinQuantity'"></app-cell-input>
                      </td>
                      <td>
                        <app-cell-input
                          (change)="changeDiscount($event, tierControl)"
                          [formControlName]="'Discount'"></app-cell-input>
                      </td>
                      <td>
                        <app-cell-input
                          (change)="changeValue($event, tierControl)"
                          [formControlName]="'Value'"></app-cell-input>
                      </td>
                      <td style="text-align: center;vertical-align: middle;">
                        <span
                          class="btn-sm btn-default facon-minus"
                          style="font-size: 16px;"
                          title="delete"
                          (click)="deleteTier(i)"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span
                  class="btn-sm btn-default facon-plus"
                  style="padding: 0 5px;text-transform: none;"
                  title="add"
                  (click)="addTier()"
                  translate>
                  Add Tier</span
                >
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="properties && properties.length > 0">
          <!-- -->
          <div>
            <app-edit-prices
              [productId]="productId"
              [variationId]="variationId"
              [properties]="properties"
              (onSaved)="refresh()"
              (onClosed)="refresh()"
              #pricesEditor></app-edit-prices>
          </div>
          <!-- -->
          <div *ngIf="prices && prices.length > 0; else noprices" style="position: relative">
            <div *ngIf="form.get('Properties') && !form.get('Properties').dirty; else waitingforsave">
              <div *ngIf="!loading">
                <div class="row border-top mb-2">
                  <div class="col"></div>
                </div>
                <div class="row pb-2">
                  <div class="col">
                    <div class="filters" style="display: inline-block;">
                      <!--div class="body-1-regular color-primary-text-gray pr-2" translate>Select</div-->
                      <div
                        class="btn btn-fourth px-2"
                        style="padding: 0 10px 0 0!important"
                        (click)="setFilters('all')"
                        translate>
                        All
                      </div>
                      <div
                        class="btn btn-fourth px-2"
                        style="padding: 0 10px 0 0!important;"
                        (click)="setFilters('none')"
                        translate>
                        None
                      </div>
                      <div
                        *ngFor="let property of properties; let i = index"
                        class="filter filter-vendors button-group-dropdown">
                        <ngx-bootstrap-multiselect
                          class="multiselect"
                          [options]="filters[property.ID]"
                          [settings]="multiselectSettings"
                          [texts]="{ defaultTitle: property.Title }"
                          (ngModelChange)="setFilter(property.ID, $event)"
                          [(ngModel)]="filter[property.ID]"></ngx-bootstrap-multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col" style="display: flex;align-items: center;">
                    <div class="table-top" style="display: flex; position: relative; width: 100%">
                      <div style="flex-grow: 1;">
                        <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z"
                              fill="#616573" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          class="form-control search"
                          placeholder="{{ 'search' | translate }}"
                          (keyup)="searchChanged.next()"
                          [(ngModel)]="term"
                          [ngModelOptions]="{ standalone: true }" />
                      </div>
                      <button
                        *ngIf="!selectedExisting || selectedExisting.length == 0"
                        type="button"
                        class="btn btn-filter facon no-expand-icon dropdown-toggle ml-3"
                        style="height: 44px;width: 120px;margin: 0;float:right"
                        data-toggle="modal"
                        data-original-title="test"
                        data-target="#columnsModal"
                        (click)="onEditColumns(columnsContent)">
                        <svg style="margin-right: 5px; height: 20px; width: 20px; vertical-align: bottom;">
                          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-filters-columns"></use>
                        </svg>
                        <span translate>Columns</span>
                      </button>
                      <ng-template #columnsContent let-modal>
                        <div class="modal-header">
                          <h5 class="modal-title body-2" id="columnsModalLabel">Columns</h5>
                          <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
                            <svg
                              width="12"
                              height="20"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <line
                                x1="11.33"
                                y1="0.530452"
                                x2="0.530334"
                                y2="11.3301"
                                stroke="#292F46"
                                stroke-width="1.5" />
                              <line
                                x1="11.4692"
                                y1="11.33"
                                x2="0.669505"
                                y2="0.530334"
                                stroke="#292F46"
                                stroke-width="1.5" />
                            </svg>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col">
                              <h5 class="body-2" style="text-align: center" translate>Active</h5>
                              <div
                                class="columns"
                                style="max-height: 300px; overflow-y: scroll"
                                dragula="columns"
                                [(dragulaModel)]="columns">
                                <div
                                  *ngFor="let column of columns; let i = index"
                                  class="column"
                                  (click)="$event.stopPropagation()"
                                  [attr.data-name]="column.Name">
                                  <!--input type="checkbox" [attr.id]="'column-' + i" class="checkbox_animated"
                                         [(ngModel)]="column['Selected']"-->
                                  <label translate>{{ column.Label }} </label>
                                  <span (click)="onRemoveColumn(column)">&times;</span>
                                </div>
                              </div>
                            </div>
                            <div *ngFor="let group of groups; let i = index" class="col">
                              <h5 class="body-2" style="text-align: center" translate>{{ group.Label }}</h5>
                              <div class="library">
                                <div
                                  *ngFor="
                                    let column of filterColumns(defaultColumns, group.Name, columns);
                                    let j = index
                                  "
                                  (click)="onAddColumn(column)"
                                  [attr.data-name]="column.Name">
                                  <label class="mb-1 body-4-regular" style="display: flex">
                                    <input
                                      type="checkbox"
                                      class="checkbox_animated"
                                      (change)="onChangeColumn(column)"
                                      [(ngModel)]="column['Selected']" />
                                    {{ column.Label | translate }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer" style="justify-content: normal;">
                          <button
                            type="button"
                            id="save"
                            class="btn btn-primary facon"
                            [title]="'Save' | translate"
                            (click)="onSaveColumns()"
                            translate>
                            <svg>
                              <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                            </svg>
                            <span style="vertical-align: text-bottom">Save</span>
                          </button>
                          <button
                            class="btn btn-cancel"
                            type="button"
                            data-dismiss="modal"
                            (click)="modal.dismiss('Cross click')"
                            translate>
                            Cancel
                          </button>
                          <button
                            class="btn btn-danger"
                            style="margin-left: auto"
                            type="button"
                            data-dismiss="modal"
                            (click)="onResetColumns()"
                            translate>
                            Reset
                          </button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div *ngIf="selectedExisting && selectedExisting.length > 0" class="col">
                    <div
                      class="position-absolute"
                      style="right: 5px;top: 0;padding: 6px; background-color: white;line-height: 0;width: 24px; height: 24px;"
                      data-toggle="modal"
                      data-original-title="test"
                      data-target="#hotkeysModal"
                      (click)="onEditHotkeys(hotkeysContent)"
                      translate>
                      <svg style="height: 14px;width: 14px;">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-gear"></use>
                      </svg>
                    </div>
                    <div style="max-height: 50px;overflow-y: scroll;">
                      <div
                        *ngFor="let item of hotkeys; let i = index"
                        class="body-7-medium hotkey"
                        [ngClass]="{
                          selected: hotkey && hotkey.Name == item.Name,
                          hidden: !hasColumnByName(columns, item.Name)
                        }"
                        (click)="onSetHotkey(item)"
                        translate>
                        {{ item.Label }}
                      </div>
                    </div>
                    <ng-template #hotkeysContent let-modal>
                      <div class="modal-header">
                        <h5 class="modal-title body-2" id="hotkeysModalLabel">Hotkeys</h5>
                        <div class="button-icon" ngbAutofocus (click)="modal.dismiss('Cross click')">
                          <svg
                            width="12"
                            height="20"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <line
                              x1="11.33"
                              y1="0.530452"
                              x2="0.530334"
                              y2="11.3301"
                              stroke="#292F46"
                              stroke-width="1.5" />
                            <line
                              x1="11.4692"
                              y1="11.33"
                              x2="0.669505"
                              y2="0.530334"
                              stroke="#292F46"
                              stroke-width="1.5" />
                          </svg>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="body-2" style="text-align: center" translate>Active</h5>
                            <div
                              class="columns"
                              style="max-height: 300px; overflow-y: scroll"
                              dragula="columns"
                              [(dragulaModel)]="columns">
                              <div
                                *ngFor="let hotkey of hotkeys; let i = index"
                                class="column"
                                (click)="$event.stopPropagation()"
                                [attr.data-name]="hotkey.Name">
                                <!--input type="checkbox" [attr.id]="'column-' + i" class="checkbox_animated"
                                       [(ngModel)]="column['Selected']"-->
                                <label translate>{{ hotkey.Label }} </label>
                                <span (click)="onRemoveHotkey(hotkey)">&times;</span>
                              </div>
                            </div>
                          </div>
                          <div *ngFor="let group of groups; let i = index" class="col">
                            <h5 class="body-2" style="text-align: center" translate>{{ group.Label }}</h5>
                            <div class="library">
                              <div
                                *ngFor="let hotkey of filterHotkeys(defaultHotkeys, group.Name, hotkeys); let j = index"
                                (click)="onAddHotkey(hotkey)"
                                [attr.data-name]="hotkey.Name">
                                <label class="mb-1 body-4-regular">
                                  <input
                                    type="checkbox"
                                    class="checkbox_animated"
                                    (change)="onChangeHotkey(hotkey)"
                                    [(ngModel)]="hotkey['Selected']" />
                                  {{ hotkey.Label | translate }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer" style="justify-content: normal;">
                        <button
                          type="button"
                          id="save"
                          class="btn btn-primary facon"
                          [title]="'Save' | translate"
                          (click)="onSaveHotkeys()"
                          translate>
                          <svg>
                            <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                          </svg>
                          <span style="vertical-align: text-bottom">Save</span>
                        </button>
                        <button
                          class="btn btn-cancel"
                          type="button"
                          data-dismiss="modal"
                          (click)="modal.dismiss('Cross click')"
                          translate>
                          Cancel
                        </button>
                        <button
                          class="btn btn-danger"
                          style="margin-left: auto"
                          type="button"
                          data-dismiss="modal"
                          (click)="onResetHotkeys()"
                          translate>
                          Reset
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedExisting && selectedExisting.length > 0" class="row pb-3">
                  <div class="col">
                    <label class="mb-1" for="bulk-action" translate>Bulk Action</label>
                    <div style="position: relative">
                      <ngx-bootstrap-multiselect
                        class="multiselect single-selected"
                        [options]="fields"
                        [settings]="bulkActionsSettings"
                        [texts]="{ defaultTitle: 'select field' }"
                        (ngModelChange)="setField($event)"
                        [(ngModel)]="field"></ngx-bootstrap-multiselect>
                    </div>
                  </div>
                  <div class="col">
                    <label class="mb-1" for="bulk-edit" translate>Bulk Edit</label>
                    <div *ngIf="hotkey && (!hotkey.Type || ['number', 'text'].indexOf(hotkey.Type) >= 0)">
                      <input
                        type="text"
                        id="bulk-edit"
                        class="form-control"
                        [disabled]="!hotkey"
                        (keypress.enter)="bulk('set', hotkey.Name)"
                        [(ngModel)]="value" />
                    </div>
                    <div *ngIf="hotkey && hotkey.Type == 'currency'">
                      <input
                        type="text"
                        id="bulk-edit"
                        class="form-control"
                        [disabled]="!hotkey"
                        (keypress.enter)="bulk('set', hotkey.Name)"
                        [(ngModel)]="value"
                        [options]="{
                          prefix: prefix,
                          decimal: decimal,
                          thousands: thousands,
                          inputMode: 1,
                          nullable: true
                        }"
                        [placeholder]="'0.00'"
                        currencyMask />
                    </div>
                    <div
                      *ngIf="hotkey && hotkey.Type == 'checkbox'"
                      style="display: flex;align-items: center;min-height: 44px;">
                      <label style="margin-bottom: 0">
                        <input
                          type="checkbox"
                          class="checkbox_animated"
                          style="vertical-align: middle;"
                          [(ngModel)]="value" />
                        {{ hotkey.Label | translate }}
                      </label>
                    </div>
                    <div *ngIf="hotkey && hotkey.Type == 'select'">
                      <app-select [options]="hotkey.Values" [(ngModel)]="value"></app-select>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedExisting && selectedExisting.length > 0" class="row pb-2">
                  <div class="col" style="height: 44px; padding-top: 10px">
                    <div>
                      <span style="padding-right: 5px;">{{ selectedExisting.length }}</span>
                      <span translate>of</span> {{ source.filtered() }}
                      <span translate>selected</span>
                      <button
                        class="btn btn-delete"
                        style="min-width: 34px; height: 34px;margin-left: 10px; padding: 3px 0 0 0;"
                        (click)="bulk('delete')">
                        <svg width="16px" height="16px">
                          <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="col" style="display: flex;justify-content: space-between;">
                    <button *ngIf="value" class="btn btn-primary facon" (click)="bulk('set', hotkey.Name)">
                      <svg>
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                      </svg>
                      <span translate>Save</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-filter"
                      style="height: 44px;white-space: nowrap; margin-left: auto"
                      (click)="bulk('open-editor')">
                      Open Bulk Editor
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col" style="padding: 0;">
                    <app-edit-price
                      [productId]="productId"
                      [variationId]="variationId"
                      [properties]="properties"
                      [decimal]="decimal"
                      [prefix]="prefix"
                      [thousands]="thousands"
                      [dimensionUnit]="dimensionUnit"
                      [weightUnit]="weightUnit"
                      [pattern]="pattern"
                      [tinymceConfig]="tinymceConfig"
                      (onSaved)="refresh()"
                      #priceEditor></app-edit-price>
                    <div
                      id="batchDelete"
                      class="prices-table custom-datatable table-fixed-first-last"
                      style="position: relative;min-height: 100px;">
                      <div [ngClass]="{ transparent: !visible }" style="position: relative">
                        <input
                          class="checkbox_animated"
                          style="position: absolute;z-index: 9;top: 19px;left: 16px;width: inherit;"
                          type="checkbox"
                          (change)="allCheck()"
                          [(ngModel)]="allExisting" />
                        <ng2-smart-table
                          class="prices"
                          [settings]="settings"
                          [source]="source"
                          (rowSelect)="unsetSelected($event)"></ng2-smart-table>
                      </div>
                      <div [ngClass]="{ transparent: !visible }" style="overflow: inherit;">
                        <div *ngIf="source && source.count() > 0" class="records-count" style="padding: 0 10px">
                          <div class="pb-1">
                            <span *ngIf="source.filtered() != source.total()">
                              {{ 'Filtered' | translate }}: {{ source.filtered() }} {{ 'of' | translate }}
                              {{ source.total() }} {{ 'records' | translate }}
                            </span>
                            <span *ngIf="source.filtered() == source.total()">
                              {{ 'Records' | translate }}: {{ source.total() }}
                            </span>
                          </div>
                          <div class="per-page" style="width: 75px;">
                            <div class="select-dropdown dropdown">
                              <button
                                id="dropdownButton"
                                class="btn dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                {{ perPage }}
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownButton">
                                <span
                                  *ngFor="let n of [10, 20, 50, 100]; let i = index"
                                  class="dropdown-item"
                                  (click)="setPerPage(n)"
                                  >{{ n }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!visible" style="position: absolute;top: 35px;left: 46%;">
                        <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--div *ngIf="unsaved" class="row border-top" style="padding: 20px 0 10px 0;margin-top: 20px;">
                  <div class="col">
                    <div class="btn btn-primary facon" [ngClass]="{disabled: loading}" (click)="onSave()">
                      <svg>
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                      </svg>
                      <span translate>Save</span>
                    </div>
                  </div>
                  <div class="col" style="text-align: right">
                    <div class="btn btn-cancel" translate>Delete prices</div>
                  </div>
                </div-->
              </div>
            </div>
            <ng-template #waitingforsave>
              <div class="row border-top" style="padding: 15px 0;">
                <div class="col">
                  <div *ngIf="!loading">
                    <div style="text-align: center">
                      <svg class="fill-primary-violet" style="height: 50px; width: 50px;">
                        <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiFileBlank"></use>
                      </svg>
                      <div class="body-3 color-text-gray" style="padding: 10px 0 20px 0">
                        <span translate>Please save changes for prices to be updated</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="loading" style="text-align: center">
                    <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <ng-template #noprices>
            <div class="row border-top" style="padding: 15px 0;">
              <div class="col">
                <div *ngIf="!loading">
                  <div style="text-align: center">
                    <svg class="fill-primary-violet" style="height: 50px; width: 50px;">
                      <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiSelectMultiple"></use>
                    </svg>
                    <div class="body-3 color-text-gray" style="padding: 10px 0 20px 0">
                      <span translate>You do not have any prices yet</span><br />
                      <span translate
                        >If you finish to configure prices,
                        <span class="color-primary-violet" style="cursor: pointer" (click)="onGenerate()"
                          >click here</span
                        >
                        to generate prices</span
                      >
                    </div>
                  </div>
                </div>
                <div *ngIf="loading" style="text-align: center">
                  <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  `,
  providers: [AbsolutizeUrlPipe],
})
export class PricesBlockComponent implements OnInit, OnDestroy {
  @Input('productId') productId = 0;
  @Input('variationId') variationId = 0;
  @Input('properties') properties;
  @Input('prices') prices = [];
  @Output() pricesChange: EventEmitter<any[]> = new EventEmitter();
  @Input('basePriceFormControl') basePriceFormControl: FormControl;
  @Input('manufacturerPriceFormControl') manufacturerPriceFormControl: FormControl;
  @Input('itemPriceFormControl') itemPriceFormControl: FormControl;
  @Input('onSaleFormControl') onSaleFormControl: FormControl;
  @Input('salePriceFormControl') salePriceFormControl: FormControl;
  @Input('startFormControl') startFormControl: FormControl;
  @Input('endFormControl') endFormControl: FormControl;
  @Input('form') form: FormGroup;
  @Input('decimal') decimal = ',';
  @Input('prefix') prefix = '';
  @Input('thousands') thousands = '.';
  @Input('dimensionUnit') dimensionUnit = 'cm';
  @Input('weightUnit') weightUnit = 'kg';
  @Input('pattern') pattern = 'whd';
  @Input('tinymceConfig') tinymceConfig;
  @ViewChild('pricesEditor') pricesEditor;
  @ViewChild('priceEditor') priceEditor;
  currency = credentials.currency;
  settings;
  source: PricesDataSource;
  term;
  eventsSubscription: Subscription;
  searchChanged: Subject<string> = new Subject<string>();
  searchSubscription: Subscription;
  selectedExisting = [];
  allExisting = false;
  loading = false;
  visible = false;
  hasPurchasableMultiply = false;
  tiers = {};
  multiselectSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 2,
    fixedTitle: true,
  };
  bulkActionsSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    selectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true,
    closeOnClickOutside: true,
  };
  filter = {};
  filters = {};
  //
  title = 'Columns';
  modal;
  closeResult;
  columns;
  fields;
  discounts = [
    {
      Name: 'percent',
      Label: '% Discount',
    },
    {
      Name: 'value',
      Label: 'Off/Unit',
    },
  ];
  weightMeasurementUnits = credentials.weightMeasurementUnits;
  groups = [
    {
      Name: 'pricing',
      Label: 'Pricing',
    },
    {
      Name: 'advanced-pricing',
      Label: 'Advanced Pricing',
    },
    {
      Name: 'inventory-and-shipping',
      Label: 'Inventory & Shipping',
    },
    {
      Name: 'rest',
      Label: 'Rest',
    },
  ];
  defaultColumns = [
    {
      Name: 'BasePrice',
      Type: 'currency',
      Label: 'Price',
      Group: 'pricing',
      Selected: true,
    },
    {
      Name: 'ManufacturerPrice',
      Type: 'currency',
      Label: 'Manufacturer Price',
      Group: 'pricing',
      Selected: false,
    },
    {
      Name: 'ItemPrice',
      Type: 'currency',
      Label: 'Item Price',
      Group: 'pricing',
      Selected: false,
    },
    {
      Name: 'SalePrice',
      Type: 'currency',
      Label: 'Sale Price',
      Group: 'pricing',
      Selected: true,
    },
    {
      Name: 'MinQuantity',
      Type: 'number',
      Label: 'Min. Quantity',
      Group: 'advanced-pricing',
      Selected: false,
    },
    {
      Name: 'MaxQuantity',
      Type: 'number',
      Label: 'Max. Quantity',
      Group: 'advanced-pricing',
      Selected: false,
    },
    {
      Name: 'BaseMeasurement',
      Type: 'number',
      Label: 'Base Measurement',
      Group: 'advanced-pricing',
      Selected: false,
    },
    {
      Name: 'BaseMeasurementUnit',
      Type: 'select',
      Label: 'Base Measurement Unit',
      Group: 'advanced-pricing',
      Values: [
        { Name: 'g', Label: 'g' },
        { Name: 'kg', Label: 'kg' },
      ],
      Selected: false,
    },
    {
      Name: 'TotalMeasurement',
      Type: 'number',
      Label: 'Total Measurement',
      Group: 'advanced-pricing',
      Selected: false,
    },
    {
      Name: 'TotalMeasurementUnit',
      Type: 'select',
      Label: 'Total Measurement Unit',
      Group: 'advanced-pricing',
      Values: [
        { Name: 'g', Label: 'g' },
        { Name: 'kg', Label: 'kg' },
      ],
      Selected: false,
    },
    {
      Name: 'Dimensions',
      Label: 'Dimensions',
      Group: 'inventory-and-shipping',
      Placeholder: '1 x 2 x 3',
      Selected: false,
    },
    {
      Name: 'DimensionUnit',
      Type: 'select',
      Label: 'Dimension Unit',
      Group: 'inventory-and-shipping',
      Values: [
        { Name: 'cm', Label: 'cm' },
        { Name: 'm', Label: 'm' },
      ],
      Selected: false,
    },
    {
      Name: 'Pattern',
      Label: 'Pattern',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Width',
      Type: 'number',
      Label: 'Width',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Height',
      Type: 'number',
      Label: 'Height',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Depth',
      Type: 'number',
      Label: 'Depth',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Volume',
      Type: 'number',
      Label: 'Volume',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Weight',
      Type: 'number',
      Label: 'Weight',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'WeightUnit',
      Type: 'select',
      Label: 'Weight Unit',
      Group: 'inventory-and-shipping',
      Values: [
        { Name: 'g', Label: 'g' },
        { Name: 'kg', Label: 'kg' },
      ],
      Selected: false,
    },
    {
      Name: 'Packages',
      Type: 'number',
      Label: 'Packages',
      Group: 'inventory-and-shipping',
      Selected: false,
    },
    {
      Name: 'Availability',
      Type: 'select',
      Label: 'Availability',
      Group: 'rest',
      Values: [
        {
          Name: 'available',
          Label: 'Available',
        },
        {
          Name: 'preorder',
          Label: 'Preorder',
        },
        {
          Name: 'out-of-stock',
          Label: 'Out of Stock',
        },
      ],
      Selected: false,
    },
    {
      Name: 'Sku',
      Type: 'text',
      Label: 'Sku',
      Group: 'rest',
      Selected: true,
    },
    {
      Name: 'Barcode',
      Type: 'text',
      Label: 'Barcode',
      Group: 'rest',
      Selected: false,
    },
    {
      Name: 'Overselling',
      Type: 'checkbox',
      Label: 'Overselling',
      Group: 'rest',
      Selected: false,
    },
    {
      Name: 'Stock',
      Type: 'number',
      Label: 'Stock',
      Group: 'rest',
      Selected: true,
    },
    {
      Name: 'Sort',
      Type: 'number',
      Label: 'Sort',
      Group: 'rest',
      Selected: false,
    },
  ];
  hotkeys = [];
  defaultHotkeys = [];
  hotkey;
  field;
  value;
  perPage = 10;

  constructor(
    private apiService: ApiService,
    private subjectService: SubjectService,
    private toastr: ToastrService,
    private translate: TranslateService,
    protected http: HttpClient,
    public absolutizeUrl: AbsolutizeUrlPipe,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dragulaService: DragulaService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    const those = this;
    this.perPage = JSON.parse(this.localStorageService.get('prices_per_page')) || 10;
    this.defaultHotkeys = JSON.parse(JSON.stringify(this.defaultColumns));
    this.hotkeys =
      JSON.parse(this.localStorageService.get('product_prices_hotkeys')) ||
      this.defaultHotkeys.filter(item => item.Selected);
    this.columns =
      JSON.parse(this.localStorageService.get('product_prices_columns')) ||
      this.defaultColumns.filter(item => item.Selected);
    this.fields = this.columns.map(item => ({ id: item.Name, name: item.Label }));
    const hotkey = JSON.parse(this.localStorageService.get('product_prices_hotkey'));
    if (hotkey && this.columns.findIndex(item => item.Name === hotkey.Name) >= 0) {
      this.hotkey = hotkey;
      this.field = [hotkey.Name];
    }
    //
    if (this.discounts && this.discounts.length > 1) {
      this.discounts[1].Label = this.currency + ' ' + this.discounts[1].Label;
    }
    //
    if (this.properties) {
      for (let i = 0; i < this.properties.length; i++) {
        this.filters[this.properties[i].ID] = this.properties[i].Rates.map(rate => ({
          id: rate.Value.ID,
          name: rate.Value.Title,
        }));
        this.filter[this.properties[i].ID] = this.properties[i].Rates.map(rate => rate.Value.ID);
      }
    }
    //
    this.searchSubscription = this.searchChanged.pipe(debounceTime(1000)).subscribe(() => {
      those.allExisting = false;
      those.selectedExisting = [];
      those.source.refresh();
    });
    //
    this.settings = this.createSettings();
    this.source = new PricesDataSource(those.http, those.apiService, those.localStorageService);
    this.source.parent = those;
    //
    this.eventsSubscription = this.subjectService.events.subscribe(event => {
      switch (event['type']) {
        case 'refresh-properties':
          console.log('prices-block', 'received event', event, 'run generate');
          if (this.productId) {
            this.apiService
              .getProduct(this.productId)
              .then(product => {
                those.properties = [...product.Properties];
                those.prices = [...product.Prices];
                those.onGenerate();
              })
              .catch(err => {
                those.error('Load product', err);
              });
          } else if (this.variationId) {
            this.apiService
              .getVariation(this.variationId)
              .then(variation => {
                those.properties = [...variation.Properties];
                those.prices = [...variation.Prices];
                those.onGenerate();
              })
              .catch(err => {
                those.error('Load variation', err);
              });
          }
          break;
        default:
          console.log('prices-block', 'received event', event, 'nothing to do with');
      }
    });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(this.translate.instant(message), this.translate.instant(title), {
      closeButton: true,
      timeOut: 15000,
    });
  }

  createSettings() {
    const those = this;
    const settings = {
      mode: 'external',
      draggable: false,
      actions: {
        add: false,
        columnTitle: those.translate.instant('Actions'),
        position: 'right',
        edit: false,
        delete: false,
      },
      columns: {
        Selected: {
          title: '',
          type: 'custom',
          renderComponent: PriceSelectedRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
        Thumbnail: {
          title: '',
          type: 'custom',
          renderComponent: PriceThumbnailRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
          },
          filter: false,
          sort: false,
        },
      },
      pager: {
        display: true,
      },
    };
    if (this.properties) {
      for (let i = 0; i < this.properties.length; i++) {
        settings.columns[this.properties[i]['Title']] = {
          title: this.properties[i]['Title'],
          type: 'custom',
          renderComponent: PriceDynamicRenderComponent,
          onComponentInitFunction: instance => {
            instance.index = i;
          },
          filter: false,
          sort: true,
        };
      }
    }
    for (const column of this.columns) {
      if (column.Selected) {
        settings.columns[column.Name] = {
          title: those.translate.instant(column.Label),
          type: 'custom',
          renderComponent: PriceEditableRenderComponent,
          onComponentInitFunction: instance => {
            instance.parent = those;
            instance.name = column.Name;
            instance.type = column.Type ? column.Type : 'text';
            instance.values = column.Values;
            instance.placeholder = column.Placeholder ? column.Placeholder : '';
            instance.decimal = those.decimal;
            instance.prefix = those.prefix;
            instance.thousands = those.thousands;
          },
          filter: false,
          sort: true,
        };
      }
    }
    settings.columns['Options'] = {
      title: '',
      type: 'custom',
      renderComponent: PriceOptionsRenderComponent,
      onComponentInitFunction: instance => {
        instance.parent = those;
      },
      filter: false,
      sort: false,
    };
    return settings;
  }

  addTier() {
    const tiers = this.form.get('Tiers') as FormArray;
    const minQuantity = tiers.length > 0 ? Number(tiers.at(tiers.length - 1).get('MinQuantity').value) + 1 : 2;
    const discount = tiers.length > 0 ? Number(tiers.at(tiers.length - 1).get('Discount').value) + 1 : 0;
    const value =
      this.form.get('DiscountType').value === 'percent'
        ? this.basePriceFormControl.value * (1 - discount / 100)
        : this.basePriceFormControl.value - discount;
    tiers.push(
      this.formBuilder.group({
        MinQuantity: [minQuantity, [Validators.required]],
        Discount: [discount, [Validators.required]],
        Value: [value.toFixed(2)],
      })
    );
  }

  deleteTier(index) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete tier?';
    modal.componentInstance.body = `Are you sure you want to delete tier? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        const tiers = those.form.get('Tiers') as FormArray;
        tiers.removeAt(index);
        tiers.markAsDirty();
      },
      reason => {}
    );
  }

  changeDiscount(event, group) {
    const those = this;
    if (event && event.target && event.target.value) {
      setTimeout(() => {
        if (those.form.get('DiscountType').value === 'percent') {
          group.get('Value').setValue((those.basePriceFormControl.value * (1 - event.target.value / 100)).toFixed(2));
        } else {
          group.get('Value').setValue((those.basePriceFormControl.value - event.target.value).toFixed(2));
        }
        group.get('Value').updateValueAndValidity();
      }, 100);
    }
  }

  changeValue(event, group) {
    const those = this;
    if (event && event.target && event.target.value) {
      setTimeout(() => {
        if (those.form.get('DiscountType').value === 'percent') {
          group
            .get('Discount')
            .setValue(((1 - event.target.value / those.basePriceFormControl.value) * 100).toFixed(0));
        } else {
          group.get('Discount').setValue((those.basePriceFormControl.value - event.target.value).toFixed(0));
        }
        group.get('Discount').updateValueAndValidity();
      }, 100);
    }
  }

  toggle(name, value) {
    switch (name) {
      case 'sale':
        if (value) {
          if (typeof this.form.get('Start').value === 'string') {
            this.form.get('Start').setValue(moment(this.form.get('Start').value).format('YYYY-MM-DD HH:mm:ss'));
          } else {
            this.form.get('Start').setValue(moment().format('YYYY-MM-DD 00:00:00'));
          }
          if (typeof this.form.get('End').value === 'string') {
            this.form.get('End').setValue(moment(this.form.get('End').value).format('YYYY-MM-DD HH:mm:ss'));
          } else {
            this.form.get('End').setValue(moment().format('YYYY-MM-DD 23:59:59'));
          }
        }
        break;
    }
  }

  setField(field) {
    const index = this.columns.findIndex(item => item.Name === field[0]);
    if (index >= 0) {
      this.hotkey = this.columns[index];
      console.log('hotkey', this.hotkey);
      this.localStorageService.set('product_prices_hotkey', JSON.stringify(this.hotkey));
    }
  }

  setFilters(action: string) {
    this.allExisting = false;
    this.selectedExisting = [];
    if (this.properties) {
      for (let i = 0; i < this.properties.length; i++) {
        this.filter[this.properties[i].ID] =
          action === 'all' ? this.properties[i].Rates.map(rate => rate.Value.ID) : [];
      }
    }
    this.source.reset();
  }

  setFilter(field, value?) {
    const curr = {};
    curr[field] = value;
    const filters = [];
    for (const [k, v] of Object.entries({ ...this.filter, ...curr })) {
      filters.push({ field: 'property:' + k, search: (k === field ? value : v).join(',') });
    }
    this.allExisting = false;
    this.selectedExisting = [];
    this.source.reset();
    setTimeout(() => {
      this.source.setFilter(filters, true, true);
    }, 250);
  }

  refresh() {
    this.source.refresh();
    this.allExisting = false;
    this.selectedExisting = [];
  }

  onEdit(id) {
    const those = this;
    if (this.form.get('Prices').dirty) {
      this.onSave(() => {
        those.priceEditor.onEdit(those.priceEditor.content, id);
      });
    } else {
      this.priceEditor.onEdit(this.priceEditor.content, id);
    }
  }

  onCreate() {
    const those = this;
    const filtered = this.prices.filter(item => item.Selected);
    this.loading = true;
    this.apiService
      .postPrice(filtered)
      .then(prices => {
        those.loading = false;
        those.prices = prices;
        those.pricesChange.emit(those.prices);
      })
      .catch(err => {
        those.error('Create prices', err);
      });
  }

  onGenerate() {
    //console.log('onGenerate', JSON.stringify(this.properties));
    const those = this;
    this.loading = true;
    const rates = [];
    for (let i = 0; i < this.properties.length; i++) {
      rates.push(this.properties[i].Rates);
    }
    //console.log('rates', rates);
    const sets = [];
    const max = rates.length - 1;

    function helper(arr, i) {
      for (let j = 0, l = rates[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(rates[i][j]);
        if (i === max) {
          sets.push(a);
        } else {
          helper(a, i + 1);
        }
      }
    }

    helper([], 0);
    //console.log('sets', sets);
    const defaultPrice = {
      BasePrice: those.form.get('BasePrice').value,
      ManufacturerPrice: those.form.get('ManufacturerPrice').value,
      ItemPrice: those.form.get('ItemPrice').value,
      OnSale: those.form.get('OnSale').value,
      SalePrice: those.form.get('SalePrice').value,
      AdvancedPurchasability: those.form.get('AdvancedPurchasability').value,
      MinQuantity: those.form.get('MinQuantity').value,
      MaxQuantity: those.form.get('MaxQuantity').value,
      PurchasableMultiply: those.form.get('PurchasableMultiply').value,
      Measurement: those.form.get('Measurement').value,
      BaseMeasurement: those.form.get('BaseMeasurement').value,
      BaseMeasurementUnit: those.form.get('BaseMeasurementUnit').value,
      TotalMeasurement: those.form.get('TotalMeasurement').value,
      TotalMeasurementUnit: those.form.get('TotalMeasurementUnit').value,
      Pattern: those.form.get('Pattern').value,
      Dimensions: those.form.get('Dimensions').value,
      DimensionUnit: those.form.get('DimensionUnit').value,
      Width: those.form.get('Width').value,
      Height: those.form.get('Height').value,
      Depth: those.form.get('Depth').value,
      Volume: those.form.get('Volume').value,
      Weight: those.form.get('Weight').value,
      WeightUnit: those.form.get('WeightUnit').value,
      Packages: those.form.get('Packages').value,
      Availability: those.form.get('Availability').value,
      Overselling: those.form.get('Overselling').value,
    };
    if (this.form.get('Start').value) {
      defaultPrice['Start'] = moment(this.form.get('Start').value).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    if (this.form.get('End').value) {
      defaultPrice['End'] = moment(this.form.get('End').value).format('YYYY-MM-DDTHH:mm:ssZ');
    }
    const prices = [];
    for (let i = 0; i < sets.length; i++) {
      //console.log('i', i, 'set', sets[i]);
      let price;
      const existingPrice = this.prices.find(item => {
        const rates1 = item.Rates.map(item2 => item2.ID);
        rates1.sort();
        const key1 = rates1.join(',');
        const rates2 = sets[i].map(item2 => item2.ID);
        rates2.sort();
        const key2 = rates2.join(',');
        return key1 === key2;
      });
      if (existingPrice) {
        price = { ...existingPrice };
        price.Existing = true;
        //console.log('found');
      } else {
        price = { ...defaultPrice };
        price.Enabled = true;
        price.Rates = sets[i];
        price.New = true;
        //console.log('not found');
      }
      prices.push({
        ...price,
        ...{
          ProductId: this.productId,
          VariationId: this.variationId,
          Sort: i + 1,
        },
      });
    }
    //console.log('prices', prices);
    //console.log('new', prices.filter(item => item.New).length);
    //console.log('existing', prices.filter(item => item.Existing).length);
    this.apiService
      .postPrice(prices)
      .then(response => {
        //console.log('response', response);
        those.settings = this.createSettings();
        those.loading = false;
        those.prices = response;
        those.pricesChange.emit(those.prices);
        those.source.refresh();
      })
      .catch(err => {
        those.error('Create prices', err);
      });
  }

  getDiscount(name: string) {
    const discount = this.discounts.find(item => item.Name === name);
    if (discount) {
      return discount;
    }
    return this.discounts[0];
  }

  setPerPage(value: number) {
    const those = this;
    this.allExisting = false;
    this.selectedExisting = [];
    this.loading = true;
    this.perPage = value;
    this.settings.pager.perPage = value;
    this.localStorageService.set('prices_per_page', JSON.stringify(value));
    setTimeout(() => {
      those.loading = false;
    }, 100);
  }

  bulk(action?, field?, input?) {
    const those = this;
    switch (action) {
      case 'set-active':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchPrice(those.source.data[i]['ID'], 'setEnabled', { Enabled: true }).then(resp => {
                  those.source.data[i]['Enabled'] = true;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set-inactive':
        (async function loop() {
          for (let i = 0; i < those.source.data.length; i++) {
            if (those.source.data[i]['Selected']) {
              await new Promise(resolve =>
                those.apiService.patchPrice(those.source.data[i]['ID'], 'setEnabled', { Enabled: false }).then(resp => {
                  those.source.data[i]['Enabled'] = false;
                  resolve();
                })
              );
            }
          }
          await new Promise(resolve => {
            those.source.local = true;
            those.source.refresh();
            resolve();
          });
        })();
        break;
      case 'set':
        those.apiService
          .patchPrices('set', {
            Ids: those.selectedExisting.map(item => item.ID),
            Field: field,
            Value: String(those.value),
          })
          .then(resp => {
            for (let i = 0; i < those.source.data.length; i++) {
              if (those.source.data[i]['Selected']) {
                those.source.data[i][field] = those.value;
              }
            }
            those.source.local = true;
            those.source.refresh();
            those.value = '';
          })
          .catch(err => {
            those.error('Update prices', err);
          });
        break;
      case 'open-editor':
        those.pricesEditor.onEdit(
          those.pricesEditor.content,
          those.selectedExisting.map(item => item.ID)
        );
        break;
      case 'delete':
        const modal = this.modalService.open(ConfirmComponent, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'md',
          centered: true,
        });
        modal.componentInstance.title = 'Delete prices?';
        modal.componentInstance.body = `Are you sure you want to delete prices? This can't be undone.`;
        modal.componentInstance.confirm = 'Delete';
        modal.result.then(
          result => {
            const prices = [];
            for (let i = 0; i < those.selectedExisting.length; i++) {
              prices.push(those.selectedExisting[i]['ID']);
            }
            those.apiService
              .patchPrices('delete', { Ids: prices })
              .then(resp => {
                those.prices = those.prices.filter(item => prices.indexOf(item.ID) < 0);
                those.pricesChange.emit(those.prices);
                those.selectedExisting = [];
                those.source.refresh();
                those.refresh();
              })
              .catch(err => {
                those.error('Delete prices', err);
              });
          },
          reason => {}
        );
        break;
      default:
        this.selectedExisting = this.source.data.filter(item => item['Selected']);
    }
  }

  allCheck() {
    for (let i = 0; i < this.source.data.length; i++) {
      this.source.data[i].Selected = this.allExisting;
    }
    this.source.local = true;
    this.source.refresh();
    this.selectedExisting = this.allExisting ? this.source.columns.Ids.map(item => ({ ID: item })) : [];
  }

  trim(value) {
    return String(value).replace(/\.00$/, '').replace(/^0$/, '');
  }

  format(value, decimal?) {
    return Number.parseFloat(value)
      .toFixed(decimal)
      .replace(/([0-9]+\.[0-9]+?)0*$/, '$1');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filterHotkeys(defaultHotkeys: any[], groupName: string, selected: any[]): any[] {
    const hotkeys = defaultHotkeys.filter(item => item.Group === groupName);
    for (let i = 0; i < hotkeys.length; i++) {
      const index = selected.findIndex(item => item.Name === hotkeys[i].Name);
      if (index >= 0) {
        hotkeys[i].Selected = selected[index].Selected;
      }
    }
    return hotkeys;
  }

  onEditHotkeys(content) {
    const those = this;
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onAddHotkey(hotkey) {
    const index = this.hotkeys.findIndex(item => item.Name === hotkey.Name);
    if (index < 0) {
      this.hotkeys.push({ ...hotkey, ...{ Selected: true } });
    }
  }

  onChangeHotkey(hotkey) {
    if (hotkey.Selected) {
      this.onAddHotkey(hotkey);
    } else {
      this.onRemoveHotkey(hotkey);
    }
  }

  onRemoveHotkey(hotkey) {
    const index = this.hotkeys.findIndex(item => item.Name === hotkey.Name);
    if (index >= 0) {
      this.hotkeys.splice(index, 1);
    }
  }

  onResetHotkeys() {
    this.hotkeys = this.defaultHotkeys.filter(item => item.Selected);
    this.onSaveHotkeys();
  }

  onSaveHotkeys() {
    this.localStorageService.set('product_prices_hotkeys', JSON.stringify(this.hotkeys));
    this.modal.close('Saved');
  }

  onSetHotkey(hotkey) {
    this.hotkey = hotkey;
    this.field = [hotkey.Name];
    this.localStorageService.set('product_prices_hotkey', JSON.stringify(hotkey));
  }

  hasColumnByName(columns, name) {
    return columns.findIndex(item => item.Name === name) >= 0;
  }

  filterColumns(defaultColumns: any[], groupName: string, selected: any[]): any[] {
    const columns = defaultColumns.filter(item => item.Group === groupName);
    for (let i = 0; i < columns.length; i++) {
      const index = selected.findIndex(item => item.Name === columns[i].Name);
      if (index >= 0) {
        columns[i].Selected = selected[index].Selected;
      }
    }
    return columns;
  }

  onEditColumns(content) {
    const those = this;
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then(
      result => {
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onAddColumn(column) {
    const index = this.columns.findIndex(item => item.Name === column.Name);
    if (index < 0) {
      this.columns.push({ ...column, ...{ Selected: true } });
    }
  }

  onChangeColumn(column) {
    if (column.Selected) {
      this.onAddColumn(column);
    } else {
      this.onRemoveColumn(column);
    }
  }

  onRemoveColumn(column) {
    const index = this.columns.findIndex(item => item.Name === column.Name);
    if (index >= 0) {
      this.defaultColumns.filter(item => item.Name === column.Name)[0].Selected = false;
      this.columns.splice(index, 1);
    }
  }

  onResetColumns() {
    this.columns = this.defaultColumns.filter(item => item.Selected);
    this.onSaveColumns();
  }

  onSaveColumns() {
    this.localStorageService.set('product_prices_columns', JSON.stringify(this.columns));
    this.hotkeys = JSON.parse(JSON.stringify(this.columns));
    this.fields = this.hotkeys.map(item => ({ id: item.Name, name: item.Label }));
    this.loading = true;
    this.modal.close('Saved');
    this.settings = this.createSettings();
    this.loading = false;
    this.refresh();
  }

  unsetSelected(event) {
    const element = document.querySelector('#prices-' + event.data['ID'] + '-selected');
    if (element) {
      const tr =
        element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
          .parentElement;
      if (tr) {
        setTimeout(() => {
          tr.classList.remove('selected');
        }, 100);
      }
    }
    return false;
  }

  onSave(callback?) {
    const those = this;
    const ids = those.source.data.filter(item => item.Changed).map(item => item.ID);
    if (ids && ids.length > 0) {
      this.apiService
        .postPricesExport({ Ids: ids })
        .then(prices => {
          const updated = [];
          for (let i = 0; i < prices.length; i++) {
            const price = those.source.data.find(item => item.ID === prices[i].ID);
            if (price) {
              for (const [key, value] of Object.entries(price)) {
                if (typeof prices[i][key] === 'number') {
                  prices[i][key] = Number(value);
                } else {
                  prices[i][key] = value;
                }
              }
              updated.push(prices[i]);
            }
          }
          those.apiService
            .postPrices(updated)
            .then(resp => {
              those.form.get('Prices').markAsPristine();
              if (callback) {
                callback();
              }
              those.success('Save prices', resp);
              those.refresh();
            })
            .catch(err => {
              those.error('Save prices', err);
            });
        })
        .catch(err => {
          those.error('Load prices', err);
        });
    }
  }
}
