import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  template: `
    <div><a class="link" [routerLink]="['/sales/coupons/' + rowData['ID']]" style="cursor: pointer">{{value}}</a></div>
  `,
})
export class CouponTitleRenderComponent implements ViewCell {
  @Input() value: string;
  @Input() rowData: any;

}
