<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Global Settings</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body" style="padding-bottom: 20px;">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 20px;">
              <div class="col">
                <div class="form-group">
                  <label for="preview" class="mb-1" style="padding-right: 5px" translate>Preview</label>
                  <span class="tooltip-question" placement="top" [ngbTooltip]="'domain to use for preview' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                  <input class="form-control" id="preview" type="text" placeholder="{{'preview domain name' | translate}}" formControlName="Preview">
                </div>
                <div class="form-group">
                  <label for="products" class="mb-1" style="padding-right: 5px" translate>Products</label>
                  <span class="tooltip-question" placement="top" [ngbTooltip]="'catalog root name' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                  <input class="form-control" id="products" type="text" placeholder="products" formControlName="Products">
                </div>
                <div class="form-group">
                  <label class="d-block" for="flat-url">
                    <input class="checkbox_animated" id="flat-url" type="checkbox" formControlName="FlatUrl">
                    {{'Flat Url' | translate}}
                    <span class="tooltip-question" placement="top" [ngbTooltip]="'remove catalog root name from path' | translate">
                      <svg width="16px" height="16px">
                        <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                      </svg>
                    </span>
                  </label>
                </div>
                <div style="padding-top:10px; text-align: center">
                  <span class="btn btn-default" (click)="onDump()" translate>Download Dump</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="padding: 0 30px;">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
