import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediasRoutingModule } from './medias-routing.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {FilesComponent} from './files/files.component';
import {FilePathRenderComponent} from './files/file-path-render.components';
import {FileSizeRenderComponent} from './files/file-size-render.component';
import {ImagesComponent} from './images/images.component';
import {ImageSizeRenderComponent} from './images/image-size-render.component';
import {MomentModule} from 'ngx-moment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {ImageNameRenderComponent} from './images/image-name-render.component';
import {MediaActionsRenderComponent} from './media-actions-render.component';
import {MediaNameRenderComponent} from './media-name-render.component';
import {MediasComponent} from './medias.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MediaSizeRenderComponent} from './media-size-render.component';
import {MediaDateRenderComponent} from './media-date-render.component';
import {NgxBootstrapMultiselectModule} from 'ngx-bootstrap-multiselect';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};


@NgModule({
  declarations: [FilesComponent, FilePathRenderComponent, FileSizeRenderComponent, ImagesComponent,
    ImageNameRenderComponent, ImageSizeRenderComponent, MediasComponent, MediaNameRenderComponent,
    MediaDateRenderComponent, MediaSizeRenderComponent, MediaActionsRenderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MediasRoutingModule,
    DropzoneModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    NgxBootstrapMultiselectModule,
    MomentModule,
    SharedModule,
    TranslateModule,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class MediasModule { }
