<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Appearance</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row border-bottom" style="padding-bottom: 20px;margin-bottom: 20px;">
              <div class="col">
                <div class="form">
                  <div class="form-group">
                    <label for="title" class="mb-1" translate>Title</label>
                    <input class="form-control" id="title" type="text" formControlName="Title">
                  </div>
                  <div class="form-group">
                    <label for="description" class="mb-1" translate>Description</label>
                    <textarea class="form-control" id="description" rows="3" formControlName="Description"></textarea>
                  </div>
                  <div class="form-group">
                    <label for="keywords" class="mb-1" translate>Keywords</label>
                    <textarea class="form-control" id="keywords" rows="3" formControlName="Keywords"></textarea>
                  </div>
                  <div class="form-group mb-0">
                    <label for="thumbnail" class="mb-1" translate>Logo</label>
                    <div
                      *ngIf="form.controls['Logo'] && form.controls['Logo'].value && !form.controls['Logo'].dirty"
                      style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                      <a [href]="form.controls['Logo'].value" target="_blank"><img
                        [src]="absolutizeUrl.transform(form.controls['Logo'].value)"
                        style="max-height: 100px;border-radius: 5px;margin-bottom: 5px;"></a>
                      <div style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                           (click)="unset()">&times;
                      </div>
                    </div>
                    <app-file-uploader *ngIf="!form.controls['Logo'].value || form.controls['Logo'].dirty"  id="thumbnail" (filesLoaded)="upload($event)"></app-file-uploader>
                  </div>
                  <div class="form-group">
                    <label for="theme" class="mb-1" translate>Theme</label>
                    <select id="theme" class="form-control" formControlName="Theme">
                      <option value="default">default</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="language" class="mb-1" translate>Language</label>
                    <select id="language" class="form-control" formControlName="LanguageCode">
                      <option value="de">Deutsch</option>
                      <option value="en">English</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="paginate" class="mb-1" translate>Paginate</label>
                    <input class="form-control" id="paginate" type="number" min="5" max="999" formControlName="Paginate">
                  </div>
                </div>
              </div>
            </div>
            <div class="row border-bottom" style="margin-bottom: 20px;">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="currency" class="mb-1" style="padding-right: 5px" translate>Currency</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'international currency code (3 chars lowercase)' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <input class="form-control" id="currency" type="text" formControlName="Currency">
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="symbol" class="mb-1" style="padding-right: 5px" translate>Symbol</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'$, €' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <input class="form-control" id="symbol" type="text" formControlName="Symbol">
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1" style="padding-right: 5px" translate>Position</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'$123 or 123$' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <div class="radio-group" style="display: flex;height: 44px;align-items: center;">
                        <div class="radio-group-item" style="padding-right: 20px">
                          <label for="left">
                            <input class="radio_animated" style="margin-right: 10px;" id="left" type="radio"
                                   value="left" formControlName="SymbolPosition">
                            <span>{{'left' | translate}}</span>
                          </label>
                        </div>
                        <div class="radio-group-item">
                          <label for="right">
                            <input class="radio_animated" style="margin-right: 10px;" id="right" type="radio"
                                   value="right" formControlName="SymbolPosition">
                            <span>{{'right' | translate}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1" style="padding-right: 5px;" translate>Thousand</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'thousand splitter in currency field' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <div class="radio-group" style="display: flex;height: 44px;align-items: center;">
                        <div class="radio-group-item" style="padding-right: 20px">
                          <label for="empty">
                            <input class="radio_animated" style="margin-right: 10px;" id="empty" type="radio"
                                   value="empty" formControlName="Thousand">
                            <span>{{'empty' | translate}}</span>
                          </label>
                        </div>
                        <div class="radio-group-item" style="padding-right: 20px">
                          <label for="space">
                            <input class="radio_animated" style="margin-right: 10px;" id="space" type="radio"
                                   value="space" formControlName="Thousand">
                            <span>{{'space' | translate}}</span>
                          </label>
                        </div>
                        <div class="radio-group-item" style="padding-right: 20px">
                          <label for="comma">
                            <input class="radio_animated" style="margin-right: 10px;" id="comma" type="radio"
                                   value="," formControlName="Thousand">
                            <span>{{'comma' | translate}}</span>
                          </label>
                        </div>
                        <div class="radio-group-item">
                          <label for="dot">
                            <input class="radio_animated" style="margin-right: 10px;" id="dot" type="radio"
                                   value="." formControlName="Thousand">
                            <span>{{'dot' | translate}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col">
                    <div class="form-group">
                      <label class="mb-1" style="padding-right: 5px;" translate>Decimal</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'decimal splitter in currency field' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <div class="radio-group" style="display: flex;height: 44px;align-items: center;">
                        <div class="radio-group-item" style="padding-right: 20px">
                          <label for="comma1">
                            <input class="radio_animated" style="margin-right: 10px;" id="comma1" type="radio"
                                   value="," formControlName="Decimal">
                            <span>{{'comma' | translate}}</span>
                          </label>
                        </div>
                        <div class="radio-group-item">
                          <label for="dot1">
                            <input class="radio_animated" style="margin-right: 10px;" id="dot1" type="radio"
                                   value="." formControlName="Decimal">
                            <span>{{'dot' | translate}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="fixed" class="mb-1" style="padding-right: 5px;" translate>Decimal part</label>
                      <span class="tooltip-question" placement="top" [ngbTooltip]="'decimal part length' | translate">
                    <svg width="16px" height="16px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-prompt"></use>
                    </svg>
                  </span>
                      <input type="number" min="0" max="2" step="1" class="form-control" id="fixed" formControlName="DecimalFixed">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="padding: 0 30px;">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
