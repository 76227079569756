<div class="container-fluid">
  <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-2">
        <nav id="navbar-left" class="navbar navbar-light bg-light">
          <nav class="nav nav-pills flex-column">
            <div>
              <a class="nav-link" href="#tab-basic-information" class="nav-link active" (click)="scroll('tab-basic-information')" translate>Basic Information</a>
              <a class="nav-link" href="#tab-price" (click)="scroll('tab-price')" translate>Price</a>
              <a class="nav-link" href="#tab-properties" (click)="scroll('tab-properties')" translate>Properties</a>
              <a class="nav-link" href="#tab-images" (click)="scroll('tab-images')" translate>Images</a>
              <a class="nav-link" href="#tab-files" (click)="scroll('tab-files')" translate>Files</a>
            </div>
            <div style="padding-top:20px; text-align: center">
              <button type="button" class="btn btn-default" style="padding: 0.5rem;" [disabled]="form.dirty" (click)="router.navigateByUrl('/products/' + variation['ProductId'])" translate>Back</button>
              <button type="button" class="btn btn-primary" style="padding: 0.5rem;" [disabled]="!form.valid || !form.dirty" (click)="onSubmit()" translate>Save</button>
            </div>
            <div style="padding-top:10px; text-align: center">
              <button type="button" class="btn btn-danger" style="padding: 0.5rem;width: 100%" (click)="onDelete()" translate>Delete</button>
            </div>
          </nav>
        </nav>
        <div class="button-save-2" style="text-align: center;padding: 0 0 15px 0;">
          <button type="button" class="btn btn-primary" [disabled]="!form.valid || !form.dirty" (click)="onSubmit()" translate>Save</button>
        </div>
      </div>
      <div class="col-md-10 order-first order-md-last">
        <div data-spy="scroll" data-target="#navbar-example3">
          <div id="tab-basic-information" style="margin-top: -100px;padding-top: 100px">
            <div class="card" style="z-index: 9">
              <div class="card-header" style="padding: 15px 30px">
                <h4 style="margin-bottom: 0" translate>Basic Information</h4>
              </div>
              <div class="card-body">
                <div class="form-row align-items-center" style="padding-bottom: 10px">
                  <div class="col-md-6">
                    <label for="title" class="mb-1" translate>Title</label>:
                    <input class="form-control" id="title" type="text" (keyup)="onChangeTitle($event)" formControlName="Title">
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px">
                  <div class="col-md-6">
                    <label for="name" class="mb-1" translate>Name</label>:
                    <input class="form-control" id="name" type="text" formControlName="Name">
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="description" class="mb-1" translate>Description</label>:
                      <textarea class="form-control" id="description" rows="3" formControlName="Description"></textarea>
                    </div>
                  </div>
                </div>

                <div *ngIf="form.controls['Thumbnail']" class="form-row align-items-center" style="padding-bottom: 10px">
                  <div class="col-md-6">
                    <label for="thumbnail" class="mb-1" translate>Thumbnail</label>:
                    <div *ngIf="form.controls['Thumbnail'] && form.controls['Thumbnail'].value && !form.controls['Thumbnail'].touched && !updating"
                         style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center">
                      <img [src]="form.controls['Thumbnail'].value"
                           style="max-height: 300px;border-radius: 5px;margin-bottom: 5px;">
                      <div style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                           (click)="form.controls['Thumbnail'].value = ''">&times;
                      </div>
                    </div>
                    <app-file-uploader *ngIf="!form.controls['Thumbnail'].value || form.controls['Thumbnail'].touched" id="thumbnail" (filesLoaded)="upload($event)"></app-file-uploader>
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col-md-4">
                    <label for="availability" class="mb-1" translate>Availability</label>:
                    <select class="form-control" id="availability" formControlName="Availability">
                      <option value="available">{{'Available' | translate}}</option>
                      <option value="pre-order">{{'Pre-order' | translate}}</option>
                      <option value="out of stock">{{'Out of stock' | translate}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label for="times" class="mb-1" translate>Delivery Times</label>:
                  <div class="times">
                    <ngx-bootstrap-multiselect id="times" class="multiselect" [options]="timesOptions"
                                             [settings]="timesSettings" [texts]="texts" formControlName="Times"></ngx-bootstrap-multiselect>
                    <div style="display: inline-block;padding: 0 10px">
                      <app-edit-time (onSaved)="onAddTime()" #timeEditor>
                        <button type="button" class="btn btn-primary" style="padding: 5px;vertical-align: baseline;" data-toggle="modal" data-original-title="test"
                                data-target="#exampleModal" (click)="timeEditor.onCreate(timeEditor.content)" translate>Add
                        </button>
                      </app-edit-time>
                    </div>
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col-md-6">
                    <label class="mb-1" for="dimensions" translate>Dimensions</label>:
                    <div class="form-row" style="padding-bottom: 5px;">
                      <div class="col-md-6">
                        <select id="dimensions" class="form-control" (change)="parse()" formControlName="Pattern">
                          <option value="whd" translate>Width x Height x Depth</option>
                          <option value="wdh" translate>Width x Depth x Height</option>
                          <option value="hwd" translate>Height x Width x Depth</option>
                          <option value="hdw" translate>Height x Depth x Width</option>
                          <option value="dwh" translate>Depth x Width x Height</option>
                          <option value="dhw" translate>Depth x Height x Width</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <input type="text" placeholder="{{'value' | translate}}" (focusout)="parse()" class="form-control" formControlName="Dimensions">
                      </div>
                    </div>
                    <div class="form-row" >
                      <div class="col-md-4">
                        <label class="mb-1" for="width" translate>Width</label>:
                        <input class="form-control" id="width" type="number" min="0" step="0.1" (focusin)="trim(form.get('Width'))" formControlName="Width">
                      </div>
                      <div class="col-md-4">
                        <label class="mb-1" for="height" translate>Height</label>:
                        <input class="form-control" id="height" type="number" min="0" step="0.1" (focusin)="trim(form.get('Height'))" formControlName="Height">
                      </div>
                      <div class="col-md-4">
                        <label class="mb-1" for="depth" translate>Depth</label>:
                        <input class="form-control" id="depth" type="number" min="0" step="0.1" (focusin)="trim(form.get('Depth'))" formControlName="Depth">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row align-items-center" style="padding-bottom: 10px">
                  <div class="col-md-4">
                    <label for="weight" class="mb-1" translate>Weight</label>:
                    <input class="form-control" id="weight" type="text" (focusin)="trim(form.get('Weight'))" placeholder="{{'kg' | translate}}" formControlName="Weight">
                  </div>
                </div>

                <div class="form-row align-items-center">
                  <div class="col-md-4">
                    <label for="sku" class="mb-1" translate>Sku</label>:
                    <input class="form-control" id="sku" type="text" formControlName="Sku">
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div id="tab-price" style="margin-top: -100px;padding-top: 100px">
            <div class="card" style="z-index: 9">
              <div class="card-header" style="padding: 15px 30px">
                <h4 style="margin-bottom: 0" translate>Price</h4>
              </div>
              <div class="card-body">
                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col-md-4">
                    <label for="base-price" class="mb-1" translate>BasePrice</label>:
                    <input class="form-control" id="base-price" type="text" (focusin)="trim(form.get('BasePrice'))" (focusout)="format(form.get('BasePrice'))" formControlName="BasePrice">
                  </div>
                </div>
                <div class="form-row align-items-center" style="padding-bottom: 10px;">
                  <div class="col-md-4">
                    <label for="saleprice" class="mb-1" translate>SalePrice</label>:
                    <input class="form-control" id="saleprice" type="text" (focusin)="trim(form.get('SalePrice'))" (focusout)="format(form.get('SalePrice'))"
                           formControlName="SalePrice">
                  </div>
                  <div *ngIf="variation && form.get('SalePrice').value != 0" class="col-md-4">
                    <label for="start" class="mb-1" translate>Start</label>:
                    <div><dp-date-picker id="start" mode="daytime" theme="dp-material" [config]="{format:'YYYY-MM-DD HH:mm:ss', firstDayOfWeek:'mo', showTwentyFourHours: true, showMultipleYearsNavigation: true}" formControlName="Start"></dp-date-picker></div>
                  </div>
                  <div *ngIf="variation && form.get('SalePrice').value != 0" class="col-md-4">
                    <label for="end" class="mb-1" translate>End</label>:
                    <div><dp-date-picker id="end" mode="daytime" theme="dp-material" [config]="{format:'YYYY-MM-DD HH:mm:ss', firstDayOfWeek:'mo', showTwentyFourHours: true, showMultipleYearsNavigation: true}" formControlName="End"></dp-date-picker></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="tab-properties" style="margin-top: -100px;padding-top: 100px">
            <div class="card" style="z-index: 9">
              <div class="card-header" style="padding: 15px 30px">
                <h4 style="margin-bottom: 0" translate>Properties</h4>
              </div>
              <div class="card-body">
                <app-edit-property [variationId]="variation['ID']" (onSaved)="refresh('properties')" #propertyEditor></app-edit-property>
                <div class="row">
                  <div class="col-md-12">
                    <div class="masonry">
                      <div *ngFor="let property of variation['Properties']" class="item" data-id="{{property['ID']}}">
                        <div class="property">
                          <h5 class="property-title">
                            <span title="Property #{{property['ID']}}">{{property['Title']}}</span>: <a class="link" [routerLink]="['/options/' + property['Option']['ID']]">{{property['Option']['Title']}}</a>
                            <div class="d-flex flex-column justify-content-between">
                              <span *ngIf="property['Filtering']" style="color: lightgray; float: right;" title="used in filter"><i class="fa fa-filter" aria-hidden="true"></i></span>
                              <div class="actions">
                                <span class="btn-sm btn-default edit" title="edit" (click)="propertyEditor.onEdit(propertyEditor.content, property['ID'])"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                <span class="btn-sm btn-default delete" title="delete" (click)="propertyEditor.onDelete(property['ID'])"><i class="fa fa-trash" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </h5>
                          <app-edit-rate [optionId]="property['Option']['ID']" (onSaved)="refresh('properties')" #priceEditor></app-edit-rate>
                          <ul class="prices" style="list-style: none;padding-left: 15px;">
                            <li *ngFor="let price of property['Prices']" class="price-title price" [ngClass]="{out_of_stock: price['Availability'] == 'out of stock'}" style="position: relative" data-id="{{price['ID']}}">
                              <img *ngIf="price['Value'] && price['Value']['Thumbnail']" [src]="absolutizeUrl.transform('/storage' + price['Value']['Thumbnail'])" style="display:block; max-width: 64px">
                              <span class="price-title-value" title="Price #{{price['ID']}}">{{price['Value']['Title']}}</span>
                              <div class="actions">
                                <span class="btn-sm btn-default edit" title="edit" (click)="priceEditor.onEdit(priceEditor.content, price['ID'])"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                <span class="btn-sm btn-default delete" title="delete" (click)="priceEditor.onDelete(price['ID'])"><i class="fa fa-trash" aria-hidden="true"></i></span>
                              </div>
                              <span style="font-family: monospace; font-weight: bolder; position:absolute; right: 5px">{{currency}}{{price['Price'].toFixed(2)}}</span>
                            </li>
                          </ul>
                          <div style="padding: 10px 10px 0px 10px; text-align: center">
                            <!--span class="btn-sm btn-primary" style="cursor:pointer;" (click)="priceEditor.onCreate(priceEditor.content)" translate>Add Price</span-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="padding: 10px 10px 5px 10px; text-align: center">
                  <span class="btn btn-primary" style="font-weight: 500;padding: 0 5px;text-transform: none;" title="add" (click)="propertyEditor.onCreate(propertyEditor.content)" translate>Add Property</span>
                </div>
              </div>
            </div>
          </div>

          <div id="tab-images" class="card">
            <div class="card-header" style="padding: 15px 30px">
              <h4 style="margin-bottom: 0" translate>Images</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-6">
                  <app-edit-image [variationId]="id" (onSaved)="refresh('images')" #imageEditor></app-edit-image>
                  <div>
                    <table class="table">
                      <thead>
                      <tr><th translate>Created</th><th translate>Image</th><th translate>Actions</th></tr>
                      </thead>
                      <tbody dragula="images" [(dragulaModel)]="variation['Images']" (dragulaModelChange)="changeImages($event)">
                      <tr *ngFor="let image of variation['Images']; let i = index" [attr.data-index]="i">
                        <td>{{image['CreatedAt'] | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                        <td style="text-align: center">
                          <img [src]="absolutizeUrl.transform('/storage' + image['Path'])" style="max-height: 120px;">
                          <div *ngIf="image['Width'] || image['Height']">{{image['Width']}} x {{image['Height']}}</div>
                          <div *ngIf="image['Size']">{{image['Size'] | humanizeSize}}</div>
                        </td>
                        <td>
                          <span class="btn btn-xs btn-primary" title="delete" (click)="imageEditor.onDelete(image['ID'])"><i class="fa fa-trash" aria-hidden="true"></i></span>
                          <span class="btn btn-xs btn-primary draggable draggable-point" style="float: right" title="move"><i class="fa fa-sort draggable draggable-point" aria-hidden="true"></i></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="dropzone-custom">
                      <div class="dropzone dropzone-primary" (error)="onImageUploadError($event)" (queueComplete)="onImageUploadComplete()" [dropzone]="dropzoneConfig">
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600" translate>Drop files here or click to upload</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id="tab-files" class="card">
            <div class="card-header" style="padding: 15px 30px">
              <h4 style="margin-bottom: 0" translate>Files</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-12">
                  <app-edit-file [variationId]="id" (onSaved)="refresh('files')" #fileEditor></app-edit-file>
                  <div>
                    <table *ngIf="variation['Files']" class="table table-files table-responsive">
                      <thead>
                      <tr><th translate>Created</th><th translate>Name</th><th translate>Type</th><th translate>Size</th><th translate>Actions</th></tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let file of variation['Files']">
                        <td>{{file['CreatedAt'] | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                        <td>{{file['Name']}}</td>
                        <td>{{file['Type']}}</td>
                        <td>
                          <div *ngIf="file['Size']">{{file['Size'] | humanizeSize}}</div>
                        </td>
                        <td class="buttons" style="text-align: right">
                          <span class="btn btn-xs btn-primary" title="delete" (click)="fileEditor.onDelete(file['ID'])"><i class="fa fa-trash" aria-hidden="true"></i></span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div style="padding: 10px 10px 0px 10px; text-align: center">
                      <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                              data-target="#exampleModal" (click)="fileEditor.onCreate(fileEditor.content)" translate>Add File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- -->
  </form>
</div>
<div *ngIf="loading" style="position: absolute;top: 100px;left: 46%;"><img src="/assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>

