import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-edit-vendor',
  template: `
    <ng-content></ng-content>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 *ngIf="!vendor || !vendor['ID']" class="modal-title" id="exampleModalLabel" translate>Add Vendor</h5>
        <h5 *ngIf="vendor && vendor['ID']" class="modal-title" id="exampleModalLabel" translate>Edit Vendor</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form">
                <div class="form-group">
                  <label class="d-block" for="filtering" translate>
                    {{ 'Enabled' | translate }}:&nbsp;
                    <input class="checkbox_animated" id="filtering" type="checkbox" formControlName="Enabled" />
                  </label>
                </div>
                <div class="form-group">
                  <label for="title" class="mb-1" translate>Title</label>:
                  <input class="form-control" id="title" type="text" (keyup)="change($event)" formControlName="Title" />
                </div>
                <div class="form-group">
                  <label for="name" class="mb-1" translate>Name</label>:
                  <input class="form-control" id="name" type="text" formControlName="Name" />
                </div>
                <div class="form-group mb-0">
                  <label for="thumbnail" class="mb-1" translate>Thumbnail</label>:
                  <div
                    *ngIf="
                      form.controls['Thumbnail'] &&
                      form.controls['Thumbnail'].value &&
                      !form.controls['Thumbnail'].touched
                    "
                    style="border: 1px solid lightgray;border-radius: 5px;margin-bottom: 5px;padding: 5px;position: relative;text-align: center;">
                    <img
                      [src]="form.controls['Thumbnail'].value"
                      style="max-height: 300px;border-radius: 5px;margin-bottom: 5px;" />
                    <div
                      style="cursor: pointer; position: absolute; right: 5px; top: 5px"
                      (click)="form.controls['Thumbnail'].value = ''">
                      &times;
                    </div>
                  </div>
                  <app-file-uploader id="thumbnail" (filesLoaded)="upload($event)"></app-file-uploader>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <label for="content" class="mb-1" translate>Content</label>:
                <app-tinymce
                  id="content"
                  [options]="tinymceConfig"
                  (focus)="$event.target?.iframeElement?.classList?.add('focused')"
                  (blur)="$event.target?.iframeElement?.classList?.remove('focused')"
                  formControlName="Content"></app-tinymce>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default"
            type="button"
            data-dismiss="modal"
            (click)="modal.dismiss('Cross click')"
            translate>
            Close
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="!form.valid" translate>Save</button>
        </div>
      </form>
    </ng-template>
  `,
  styles: [
    `
      span {
        font-weight: bolder;
      }
      span.description {
        font-weight: normal;
        color: lightgray;
      }
    `,
  ],
  providers: [AbsolutizeUrlPipe],
})
export class VendorComponent implements OnInit {
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSaved: EventEmitter<any> = new EventEmitter();
  vendor;
  modal;
  closeResult;
  tinymceConfig;
  form;
  timer;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    this.tinymceConfig = {
      auto_focus: false,
      baseURL: this.absolutizeUrl.transform('/assets/tinymce'),
      //document_base_url: those.absolutizeUrl.transform('/admin'),
      //relative_urls : true,
      menubar: false,
      toolbar:
        'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | print preview media fullpage | table | ' +
        'forecolor backcolor emoticons | help',
      plugins: ['link', 'image', 'lists', 'table', 'textcolor'],
      image_uploadtab: true,
      images_upload_handler: (blobInfo, success, failure, progress) => {
        const data = new FormData();
        data.append('Image', blobInfo.blob(), blobInfo.filename());
        //
        this.apiService
          .postImage(data)
          .then(resp => {
            this.success('Create image', resp);
            success(resp['Url']);
          })
          .catch(err => {
            this.error('Create image', err);
            failure(err.ERROR ? err.ERROR : 'Something wrong');
          });
      },
      convert_urls: false,
      height: 300,
    };
  }

  success(title, resp) {
    let message = 'OK';
    if (resp.MESSAGE) {
      message = resp.MESSAGE;
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error.ERROR) {
      message = err.error.ERROR;
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCreate(content) {
    const those = this;
    this.form = this.formBuilder.group({
      ID: [''],
      Enabled: [true],
      Name: ['', Validators.required],
      Title: ['', Validators.required],
      Thumbnail: [''],
      Content: [''],
    });
    //
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  onEdit(content, id) {
    const those = this;
    this.apiService
      .getVendor(id)
      .then(vendor => {
        those.vendor = vendor;
        those.form = those.formBuilder.group({
          ID: [vendor['ID']],
          Enabled: [vendor['Enabled'] ? true : false],
          Name: [{ disabled: true, value: vendor['Name'] }, Validators.required],
          Title: [vendor['Title'], Validators.required],
          Thumbnail: [vendor['Thumbnail'] ? those.absolutizeUrl.transform('/storage' + vendor['Thumbnail']) : ''],
          Content: [vendor['Content'] ? vendor['Content'] : ''],
        });
        those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
        those.modal.result.then(
          result => {
            those.closeResult = `Closed with: ${result}`;
            those.onSaved.emit();
          },
          reason => {
            those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
            those.onClosed.emit();
          }
        );
      })
      .catch(err => {
        those.error('Load vendor', err);
      });
  }

  onDelete(id) {
    const those = this;
    const modal = this.modalService.open(ConfirmComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = 'Delete vendor?';
    modal.componentInstance.body = `Are you sure you want to delete vendor? This can't be undone.`;
    modal.componentInstance.confirm = 'Delete';
    modal.result.then(
      result => {
        those.apiService
          .deleteVendor(id)
          .then(resp => {
            those.success('Delete vendors', resp);
            those.onSaved.emit();
          })
          .catch(err => {
            those.error('Delete vendors', err);
          });
      },
      reason => {}
    );
  }

  change(event) {
    if (!this.form.get('ID').value) {
      this.form.get('Name').setValue(
        event.target.value
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-')
      );
    }
  }

  upload(files) {
    const file = files[0];
    this.form.patchValue({
      Thumbnail: file,
    });
    this.form.get('Thumbnail').markAllAsTouched();
    this.form.get('Thumbnail').updateValueAndValidity();
  }

  onSubmit() {
    const those = this;
    const data = new FormData();
    data.append('Enabled', this.form.get('Enabled').value);
    data.append('Name', this.form.get('Name').value);
    data.append('Title', this.form.get('Title').value);
    data.append('Thumbnail', this.form.get('Thumbnail').value);
    data.append('Content', this.form.get('Content').value);
    if (!this.form.get('ID').value) {
      // create
      this.apiService
        .postVendor(data)
        .then(resp => {
          those.success('Create vendors', resp);
          those.modal.close('Saved');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Delete vendors', err);
        });
    } else {
      // update
      this.apiService
        .putVendor(this.form.get('ID').value, data)
        .then(resp => {
          those.success('Update vendors', resp);
          those.modal.close('Updated');
          those.onSaved.emit();
        })
        .catch(err => {
          those.error('Update vendors', err);
        });
    }
  }
}
