<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col">
        <h1 *ngIf="route.snapshot.data['title']" [innerText]="route.snapshot.data['title'] | translate"></h1>
      </div>
      <div class="col">
        <div class="btn-popup pull-right" style="margin-bottom: 0">
          <span class="btn btn-default" style="cursor: pointer" (click)="error('In progress', {status: 500, error: {ERROR: 'not implemented yet'}})">Import</span>
          <span class="btn btn-default" style="cursor: pointer" (click)="error('In progress', {status: 500, error: {ERROR: 'not implemented yet'}})">Export</span>
          <span class="btn btn-plain btn-add" (click)="onCreate()" translate> Add Category</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div *ngIf="route.snapshot.data['subtitle'] || route.snapshot.data['description']" class="row">
            <div class="col-sm-12">
              <h2 *ngIf="route.snapshot.data['subtitle']" [innerText]="route.snapshot.data['subtitle'] | translate"></h2>
              <div *ngIf="route.snapshot.data['description']" class="body-3 mb-4" [innerText]="route.snapshot.data['description'] | translate">
              </div>
            </div>
          </div>
          <div class="row" style="padding-bottom: 10px">
            <div class="col-md-4">
              <div class="table-top" style="position: relative">
                <div class="d-none d-md-block" style="position: absolute;left: 10px;top: 12px;">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7855 16.752L14.0238 12.9902C15.1664 11.6156 15.8555 9.85078 15.8555 7.92773C15.852 3.55781 12.2977 0 7.92773 0C3.55781 0 0 3.55781 0 7.92773C0 12.2977 3.55781 15.8555 7.92773 15.8555C9.85078 15.8555 11.6156 15.1664 12.9902 14.0238L16.752 17.7855C16.8961 17.9297 17.0824 18 17.2688 18C17.4551 18 17.6414 17.9297 17.7855 17.7855C18.0703 17.5008 18.0703 17.0367 17.7855 16.752ZM1.4625 7.92773C1.4625 4.36289 4.36289 1.46602 7.92422 1.46602C11.4855 1.46602 14.3859 4.36641 14.3859 7.92773C14.3859 11.4891 11.4855 14.3895 7.92422 14.3895C4.36289 14.3895 1.4625 11.4891 1.4625 7.92773Z" fill="#616573"/>
                  </svg>
                </div>
                <input type="text" class="form-control search" placeholder="{{'search' | translate}}" (keyup)="search($event)" [(ngModel)]="term" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-4" style="text-align: right">
              <!--span style="cursor: pointer" (click)="onUndo()">click here to undo latest change</span-->
              <button *ngIf="changes && changes.length > 0" type="button" id="undo" class="btn btn-filter facon no-expand-icon dropdown-toggle" style="margin: 0;" (click)="onUndo()">
                <svg style="margin: 0 0 0 4px; height: 20px; width: 20px; vertical-align: bottom;">
                  <use xlink:href="/assets/icons/bi-symbol-defs.svg#icon-BiUndo"></use>
                </svg>
              </button>
              <!--div class="button-group" style="text-align: right">
                  <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" translate>Sort <span class="caret"></span></button>
                  <ul class="dropdown-menu">
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('created_at', 'asc')"><span>Created asc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('created_at', 'desc')"><span>Created desc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('title', 'asc')"><span>Title asc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('title', 'desc')"><span>Title desc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('base_price', 'asc')"><span>Price asc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('base_price', 'desc')"><span>by Price desc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('sku', 'asc')"><span>by Sku asc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('sku', 'desc')"><span>by Sku desc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('stock', 'asc')"><span>by Stock asc</span></li>
                    <li style="padding: 5px 10px; cursor: pointer;" (click)="sort('stock', 'desc')"><span>by Stock desc</span></li>
                  </ul>
                </div-->
            </div>
          </div>
          <div class="row">
            <div class="col" style="margin-top: auto; margin-bottom: auto">
              <div *ngIf="selected && selected.length > 0" style="padding-bottom: 10px">
                <div class="body-2" style="display: inline-block; padding-right: 10px">
                  <span style="padding-right: 5px;">{{selected.length}}</span>
                  <span translate>categories selected</span>
                </div>
                <div class="button-group-dropdown" style="display: inline-block;padding-right: 10px">
                  <button type="button" class="btn btn-filter dropdown-toggle" style="margin: 0;" data-toggle="dropdown">
                    <span translate>Choose actions</span>
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="body-2" (click)="bulk('set-active')" translate>Set active</li>
                    <li class="body-2" (click)="bulk('set-inactive')" translate>Set inactive</li>
                    <li class="body-2" (click)="bulk('copy')" translate>Copy</li>
                  </ul>
                </div>
                <div style="display: inline-block;">
                  <span class="btn btn-sm btn-default delete" style="padding: 8px;" (click)="bulk('delete')">
                    <svg style="height: 18px; width: 18px">
                      <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-delete"></use>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="batchDelete" class="categories-table custom-datatable">
            <div class="table-responsive" [ngClass]="!visible?'transparent':''">
              <ng2-smart-table (create)="onCreate()" (edit)="onEdit($event)" (custom)="onCustom($event)" (delete)="onDelete($event)" (rowDragStart)="onRowDragStart($event)" (rowDragEnter)="onRowDragEnter($event)" (rowDragLeave)="onRowDragLeave($event)"  (rowDrop)="onRowDrop($event)" [settings]="settings" [source]="source" #table></ng2-smart-table>
              <div *ngIf="tree && tree['ID'] != 0" style="text-align: center">
                <button type="button" class="btn btn-primary" [routerLink]="['/products']" [queryParams]="{id: tree['ID']}">Show <span *ngIf="tree['Products']">{{tree['Products']}}</span> Products</button>
              </div>
              <div style="display: flex; justify-content: space-between; align-items: end;">
                <div>
                  <div *ngIf="source && source.count() > 0" class="records-count">
                    <span *ngIf="source.filtered() != source.total()">
                      {{'Filtered' | translate}}: {{source.filtered()}} {{'of' | translate}} {{source.total()}} {{'records' | translate}}
                    </span>
                    <span *ngIf="source.filtered() == source.total()">
                      {{'Records' | translate}}: {{source.total()}}
                    </span>

                      <div class="per-page" style="text-align: center">
                        <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(10)">10</span>
                        <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(20)">20</span>
                        <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(50)">50</span>
                        <span class="btn btn-primary btn-xs" style="margin: 0 5px" (click)="setPerPage(100)">100</span>
                      </div>
                  </div>
                </div>
                <div *ngIf="widths" class="columns-reset" (click)="resetColumns($event)">reset width of columns</div>
              </div>
            </div>
            <div *ngIf="!visible" style="position: absolute;top: 100px;left: 46%;"><img src="assets/images/loading.svg" alt="Loading..." style="width: 100px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
