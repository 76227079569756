<!-- Container-fluid starts-->
<div class="container-fluid" style="padding-top: 30px">
  <div class="row">
    <div class="col">
      <div class="back-title-button">
        <a class="back" [routerLink]="['/settings']">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#292F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <div>
          <h1>Media Settings</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <form *ngIf="form" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="row border-bottom" style="padding-bottom: 15px; margin-bottom: 15px">
              <div class="col">
                <div style="padding: 10px;" formGroupName="Resize">
                  <div style="display: flex;align-items: center; padding-bottom: 10px">
                    <h2 style="margin-bottom: 0" translate>Resize</h2>
                    <div class="button-group-dropdown" style="display: inline-block">
                      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Resize').get('Enabled').value ? 'status-active' : 'status-inactive'">
                        <span [innerHTML]="form.get('Resize').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li (click)="form.get('Resize').get('Enabled').setValue(true)">
                          Active
                        </li>
                        <li (click)="form.get('Resize').get('Enabled').setValue(false)">
                          Inactive
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="Quality" class="mb-1" translate>Quality</label>
                    <input class="form-control" style="max-width: 150px;" id="Quality" type="number" min="5" max="100" step="1" formControlName="Quality">
                  </div>

                  <div class="row">
                    <div class="col">
                      <div formGroupName="Thumbnail">
                        <div style="display: flex;align-items: center; padding-bottom: 10px">
                          <h6 style="margin-bottom: 0" translate>Thumbnail</h6>
                          <div class="button-group-dropdown" style="display: inline-block">
                            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Resize').get('Thumbnail').get('Enabled').value ? 'status-active' : 'status-inactive'">
                              <span [innerHTML]="form.get('Resize').get('Thumbnail').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li (click)="form.get('Resize').get('Thumbnail').get('Enabled').setValue(true)">
                                Active
                              </li>
                              <li (click)="form.get('Resize').get('Thumbnail').get('Enabled').setValue(false)">
                                Inactive
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group" style="margin-bottom: 0;">
                          <label for="resize-thumbnail-size" class="mb-1" translate>Size</label>
                          <input class="form-control" id="resize-thumbnail-size" type="text" formControlName="Size">
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div formGroupName="Image">
                        <div style="display: flex;align-items: center; padding-bottom: 10px">
                          <h6 style="margin-bottom: 0" translate>Image</h6>
                          <div class="button-group-dropdown" style="display: inline-block">
                            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Resize').get('Image').get('Enabled').value ? 'status-active' : 'status-inactive'">
                              <span [innerHTML]="form.get('Resize').get('Image').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li (click)="form.get('Resize').get('Image').get('Enabled').setValue(true)">
                                Active
                              </li>
                              <li (click)="form.get('Resize').get('Image').get('Enabled').setValue(false)">
                                Inactive
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group" style="margin-bottom: 0;">
                          <label for="resize-image-size" class="mb-1" translate>Size</label>
                          <input class="form-control" id="resize-image-size" type="text" formControlName="Size">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row border-bottom" style="padding-bottom: 20px; margin-bottom: 20px;">
              <div class="col">
                <div style="padding: 10px;" formGroupName="Storage">
                  <div style="display: flex;align-items: center; padding-bottom: 10px">
                    <h2 style="margin-bottom: 0" translate>Storage</h2>
                    <div class="button-group-dropdown" style="display: inline-block">
                      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Storage').get('Enabled').value ? 'status-active' : 'status-inactive'">
                        <span [innerHTML]="form.get('Storage').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li (click)="form.get('Storage').get('Enabled').setValue(true)">
                          Active
                        </li>
                        <li (click)="form.get('Storage').get('Enabled').setValue(false)">
                          Inactive
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="description" style="padding-bottom: 10px"> External storage to speed up content delivery</div>
                  <div class="row">
                    <div class="col-md-6">
                      <div formGroupName="S3">
                        <div style="display: flex;align-items: center; padding-bottom: 20px">
                          <h6 style="margin-bottom: 0" translate>AWS S3</h6>
                          <div class="button-group-dropdown" style="display: inline-block">
                            <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="form.get('Storage').get('S3').get('Enabled').value ? 'status-active' : 'status-inactive'">
                              <span [innerHTML]="form.get('Storage').get('S3').get('Enabled').value ? 'active' : 'inactive' | translate"></span>
                              <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li (click)="form.get('Storage').get('S3').get('Enabled').setValue(true)">
                                Active
                              </li>
                              <li (click)="form.get('Storage').get('S3').get('Enabled').setValue(false)">
                                Inactive
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="storage-s3-accesskeyid" class="mb-1" translate>AccessKeyID</label>
                          <input class="form-control" id="storage-s3-accesskeyid" type="text" formControlName="AccessKeyID">
                        </div>
                        <div class="form-group">
                          <label for="storage-s3-secretaccesskey" class="mb-1" translate>SecretAccessKey</label>
                          <input class="form-control"id="storage-s3-secretaccesskey" type="text" formControlName="SecretAccessKey">
                        </div>
                        <div class="form-group">
                          <label for="storage-s3-region" class="mb-1" translate>Region</label>
                          <input class="form-control" id="storage-s3-region" type="text" formControlName="Region">
                        </div>
                        <div class="form-group">
                          <label for="storage-s3-bucket" class="mb-1" translate>Bucket</label>
                          <input class="form-control" id="storage-s3-bucket" type="text" formControlName="Bucket">
                        </div>
                        <div class="form-group">
                          <label for="storage-s3-cdn" class="mb-1" translate>CDN</label>
                          <input class="form-control" id="storage-s3-cdn" type="text" formControlName="CDN">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col" style="padding: 0 30px;">
                <button type="submit" id="save" class="btn btn-primary facon" [title]="'Save' | translate" [disabled]="form.invalid" translate>
                  <svg>
                    <use xlink:href="/assets/icons/menu-symbol-defs.svg#icon-general-copy2"></use>
                  </svg>
                  <span style="vertical-align: text-bottom">Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
