import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import {AbsolutizeUrlPipe, MediaThumbnailPipe} from '../../shared/utils.pipe';
import {SubjectService} from '../../shared/subject.service';

@Component({
  styles: [
    `
      .image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 60px;
        width: 60px;
        margin-right: 10px;
        vertical-align: middle;
        transition: transform 0.2s;
      }
      div > a {
        padding-right: 5px;
      }
    `,
  ],
  template: `
    <div class="image-wrapper" style="vertical-align: middle">
      <a [href]="absolutizeUrl.transform(rowData['Url'])" target="_blank" style="float:left; width: 75px;">
        <div
          *ngIf="preview"
          class="image"
          [style]="{
            backgroundImage:
              'url(' +
              absolutizeUrl.transform(
                (preview
                  ? preview
                  : '/assets/images/no-image.svg') + ')'
              )
          }"></div>
      </a>
      <div style="display: flex; align-items: center; height: 60px; margin-left: 75px; vertical-align: middle">
        <a class="link2" (click)="edit(rowData.ID)">
          {{ rowData.Title ? rowData.Title : value }}
        </a>
      </div>
    </div>
  `,
})
export class MediaNameRenderComponent implements OnInit, ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;
  preview;

  constructor(public subjectService: SubjectService, public absolutizeUrl: AbsolutizeUrlPipe, public mediaThumbnail: MediaThumbnailPipe) {}

  ngOnInit() {
    if (this.rowData.ContentType.indexOf('image/') >= 0) {
      this.preview = this.mediaThumbnail.transform(this.rowData);
    } else if (this.rowData.ContentType === 'application/pdf') {
      this.preview = '/admin/assets/images/file-pdf.svg';
    } else if (this.rowData.ContentType === 'application/zip') {
      this.preview = '/admin/assets/images/file-zip.svg';
    } else if (
      ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].indexOf(
        this.rowData.ContentType
      ) >= 0
    ) {
      this.preview = '/admin/assets/images/file-doc.svg';
    } else if (
      ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].indexOf(
        this.rowData.ContentType
      ) >= 0
    ) {
      this.preview = '/admin/assets/images/file-xls.svg';
    } else {
      this.preview = '/admin/assets/images/file.svg';
    }
    this.rowData['Preview'] = this.preview;
    this.rowData['Url'] = this.rowData['Url'] || this.absolutizeUrl.transform(this.rowData['Path']);
  }

  edit(id) {
    if (this.parent && this.parent.parent && this.parent.parent.editor) {
      this.parent.parent.editor.onEdit(this.parent.parent.editor.content, id);
    }
  }
}
