import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { AccountComponent } from './account/account.component';
import { SharedModule } from '../../shared/shared.module';
import { GlobalComponent } from './global/global.component';
import { TranslateModule } from '@ngx-translate/core';
import { PublisherComponent } from './publisher/publisher.component';
import { TreeModule } from 'angular-tree-component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TinymceModule } from 'angular2-tinymce';
import { SettingsComponent } from './settings.component';
import { PaymentComponent } from './payment/payment.component';
import { NotificationComponent } from './notification/notification.component';
import { MediaComponent } from './media/media.component';
import { ProductComponent } from './product/product.component';
import { AppearanceComponent } from './appearance/appearance.component';
import { TaxComponent } from './tax/tax.component';

@NgModule({
  declarations: [
    SettingsComponent,
    AppearanceComponent,
    GlobalComponent,
    AccountComponent,
    MediaComponent,
    NotificationComponent,
    PaymentComponent,
    ProductComponent,
    PublisherComponent,
    TaxComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SettingsRoutingModule,
    SharedModule,
    TranslateModule,
    Ng2SmartTableModule,
    TreeModule,
    TinymceModule.withConfig({ baseURL: '/assets/tinymce' }),
  ],
})
export class SettingsModule {}
