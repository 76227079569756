import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';
import {PagesComponent} from './pages.component';
import {TreeModule} from 'angular-tree-component';
import {SharedModule} from '../../shared/shared.module';
import {MomentModule} from 'ngx-moment';
import {TinymceModule} from 'angular2-tinymce';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [PagesComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    SharedModule,
    TreeModule.forRoot(),
    TinymceModule.withConfig({ baseURL: '/assets/tinymce'}),
    TranslateModule,
  ]
})
export class PagesModule { }
