import {Component} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {credentials} from '../../../shared/credentials.data';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneInputComponent
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator {
  code;
  phone;

  onChange = (value) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  countries = [...credentials.countries];

  constructor() { }

  change() {
    if (this.phone && this.phone.substr(0, 1) === '+') {
      for (let i = this.phone.length - 1; i > 0; i--) {
        const country = this.countries.find(item => item.Phone === this.phone.substr(0, i));
        if (country) {
          this.code = country.Phone;
          this.phone = this.phone.replace(this.code, '').trim();
          break;
        }
      }
    }
    this.phone = this.phone.replace(/^0+/, '');
    this.markAsTouched();
    this.onChange((this.code ? this.code : '') + this.phone);
  }

  writeValue(value) {
    if (value) {
      for (let i = value.length - 1; i > 0; i--) {
        const country = this.countries.find(item => item.Phone === value.substr(0, i));
        if (country) {
          this.code = country.Phone;
          this.phone = value.replace(this.code, '').trim();
          break;
        }
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
