import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbsolutizeUrlPipe } from '../../utils.pipe';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-tags-library',
  styleUrls: ['./tags.component.scss'],
  templateUrl: './tags.component.html',
  providers: [AbsolutizeUrlPipe],
})
export class TagsLibraryComponent implements OnInit, OnDestroy {
  @Input() input = [];
  @ViewChild('content') content;
  @Output() public onClosed: EventEmitter<any> = new EventEmitter();
  @Output() public onSelected: EventEmitter<any> = new EventEmitter();
  modal;
  closeResult;
  term;
  searchChanged: Subject<string> = new Subject<string>();
  searchSubscription: Subscription;
  visible = false;
  tags = [];
  selected = [];
  filtered = [];
  order = true;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit() {
    const those = this;
    //
    this.searchSubscription = this.searchChanged.pipe(debounceTime(500)).subscribe(() => {
      those.filter();
    });
    //
    this.apiService
      .getTags()
      .then(tags => {
        those.tags = tags;
        those.filter();
      })
      .catch(err => {
        this.error('Load tags', err);
      });
    //
    this.selected = [...this.input];
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleSort() {
    this.order = !this.order;
    this.filter();
  }

  filter() {
    const those = this;
    this.filtered = this.tags
      .filter(
        item =>
          !this.term || String(item.ID) === this.term || item.Title.toLowerCase().indexOf(this.term.toLowerCase()) >= 0
      )
      .filter(item => !those.selected.find(item2 => item2.ID === item.ID))
      .sort((a, b) => {
        if (those.order) {
          return a.Title < b.Title ? 1 : -1;
        } else {
          return a.Title > b.Title ? 1 : -1;
        }
      });
  }

  equals(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
  }

  contains(array, term) {
    return array.findIndex(item => item.Title.toLowerCase() === term.toLowerCase()) >= 0;
  }

  onOpen(content) {
    const those = this;
    those.modal = those.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' });
    those.modal.result.then(
      result => {
        console.log(`Closed with: ${result}`);
        those.closeResult = `Closed with: ${result}`;
      },
      reason => {
        console.log(`Dismissed ${those.getDismissReason(reason)}`);
        those.closeResult = `Dismissed ${those.getDismissReason(reason)}`;
      }
    );
  }

  add(term) {
    const those = this;
    this.apiService
      .postTag({
        ID: 0,
        Name: term
          .toLowerCase()
          .replace(/[!@#$%^&*\s]+/g, '-')
          .replace(/-{1,}/, '-'),
        Title: term,
      })
      .then(tag => {
        those.tags.push(tag);
        those.selected.push(tag);
        those.term = '';
        those.searchChanged.next();
      })
      .catch(err => {
        those.error('Create tag', err);
      });
  }

  insert(tag) {
    const index = this.filtered.findIndex(item => item.ID === tag.ID);
    if (index >= 0) {
      const arr = this.filtered.splice(index, 1);
      this.selected.push(arr[0]);
    }
  }

  remove(tag) {
    const index = this.selected.findIndex(item => {
      if (tag.ID > 0) {
        return item.ID === tag.ID;
      } else {
        return item.Name === tag.Name;
      }
    });
    if (index >= 0) {
      const arr = this.selected.splice(index, 1);
      if (tag.ID > 0 && !this.filtered.find(item => item.ID === tag.ID)) {
        this.filter();
      }
    }
  }

  onInsert() {
    this.modal.close('Insert');
    this.onSelected.emit(this.selected);
  }
}
