import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';

@Component({
  styles: [`
    input::after {

    }
  `],
  template: `
    <div><input type="checkbox" class="checkbox_animated checkbox_animated" [(ngModel)]="rowData['Selected']" (ngModelChange)="change($event)"></div>
  `,
})
export class CategorySelectedRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  change(event) {
    /*if (this.rowData['expanded']){
      if (event) {
        console.log('check all');
        for (let i = 0; i < this.rowData['Children'].length; i++){
          this.rowData['Children'][i]['Selected'] = true;
        }
        this.parent.source.local = true;
        this.parent.source.refresh();
      }else{
        console.log('uncheck all');
        for (let i = 0; i < this.rowData['Children'].length; i++){
          this.rowData['Children'][i]['Selected'] = false;
        }
        this.parent.source.local = true;
        this.parent.source.refresh();
      }
    }*/
    this.parent.bulk();
  }
}
