import { timer as observableTimer, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from '../../shared/api.service';
import { AbsolutizeUrlPipe } from '../../shared/utils.pipe';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class CategoriesDataSource extends LocalDataSource {
  local = false;
  parent: any;
  data: any[];
  id = 0;
  delay = 1;
  lastRequestFiltered = 0;
  lastRequestCount = 0;
  lastRequestTotal = 0;
  categories;
  callback;

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  filtered(): number {
    return this.lastRequestFiltered;
  }

  count(): number {
    return this.lastRequestCount;
  }

  total(): number {
    return this.lastRequestTotal;
  }

  setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    page = JSON.parse(this.localStorageService.get('categories_page')) || 1;
    perPage = JSON.parse(this.localStorageService.get('categories_per_page')) || 10;

    this.pagingConf['page'] = page;
    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  getElements(): Promise<any> {
    if (this.local) {
      this.local = false;
      return new Promise<any>((resolve, reject) => {
        resolve(this.data);
      });
    }
    const those = this;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let url = this.apiService.getUrl();
    const request = {};
    if (this.sortConf) {
      request['Sort'] = {};
      this.sortConf.forEach(fieldConf => {
        request['Sort'][fieldConf.field] = fieldConf.direction.toLowerCase();
      });
    }
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      this.localStorageService.set('categories_page', JSON.stringify(this.pagingConf['page']));
      request['Start'] = (this.pagingConf['page'] - 1) * this.pagingConf['perPage'];
      request['Length'] = this.pagingConf['perPage'];
    }
    let filtered = false;
    if (this.filterConf.filters) {
      request['Filter'] = {};
      this.filterConf.filters.forEach(fieldConf => {
        if (fieldConf['search']) {
          request['Filter'][fieldConf['field']] = fieldConf['search'];
          filtered = true;
        }
      });
    }
    those.parent.filtered = filtered;
    those.parent.visible = false;
    //
    url += '/categories?depth=999&no-products=true';
    return this.http
      .get(url, { headers: headersObject, withCredentials: true })
      .pipe(
        map(response => {
          this.categories = response;
          const children = response['Children'];
          those.lastRequestFiltered = +response['10'];
          those.lastRequestCount = +response['10'];
          those.lastRequestTotal = +response['10'];
          setTimeout(() => {
            those.parent.visible = true;
          }, those.delay);
          those.delay = 1;
          const array = (this.data =
            children.map(item => {
              return { ...item, ...{ level: 0 } };
            }) || []);
          if (array.length > 0) {
            array[array.length - 1]['last'] = true;
          }
          if (those.callback && typeof those.callback === 'function') {
            setTimeout(() => {
              those.callback.call(those);
            }, 1000);
          }
          return array;
        })
      )
      .toPromise();
  }
}
