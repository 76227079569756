import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <a
      class="link primary-color"
      [routerLink]="['/sales/orders']"
      [queryParams]="{ search: 'customer:' + rowData['ID'] }"
      style="cursor: pointer"
      >{{ value }}</a
    >
  `,
})
export class CustomerOrdersRenderComponent implements OnInit, ViewCell {
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {}
}
