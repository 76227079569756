import { AfterViewInit, Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { credentials } from '../../../shared/credentials.data';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { ApiService } from '../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  styles: [
    `
      span.switcher {
        display: flex;
        align-items: center;
        font-size: 16pt;
        cursor: pointer;
        padding-right: 8px;
      }
      .label2 {
        padding-right: 5px;
      }
      .label2::after {
        content: ':';
      }
      .grand-total span:last-child {
        float: right;
      }
      .equal {
        display: flex;
        flex-wrap: wrap;
      }
      .dropdown-menu {
        padding: 0;
      }
      .dropdown-menu > li {
        cursor: pointer;
        padding: 0.5rem;
      }
      .dropdown-menu > li:hover {
        background-color: lightgray;
      }
      .subscription {
        padding: 0.25rem 1rem;
      }
    `,
  ],
  template: `
    <div class="customer-switcher" [attr.data-id]="rowData['ID']" [attr.data-expanded]="rowData['expanded']" #ref>
      <div class="order-switcher" style="cursor: pointer; display: flex;" (click)="toggle()">
        <span
          *ngIf="!rowData['expanded']"
          class="switcher primary-color"
          data-expanded="false"
          data-id="{{ rowData['ID'] }}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#5C22CF" />
            <line x1="4.76562" y1="8.0332" x2="11.2323" y2="8.0332" stroke="#5C22CF" stroke-linecap="square" />
            <line x1="7.96484" y1="4.7666" x2="7.96484" y2="11.2333" stroke="#5C22CF" stroke-linecap="square" />
          </svg>
        </span>
        <span
          *ngIf="rowData['expanded']"
          class="switcher primary-color"
          data-expanded="true"
          data-id="{{ rowData['ID'] }}">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="7.5" fill="white" stroke="#5C22CF" />
            <line x1="4.76562" y1="8.0332" x2="11.2323" y2="8.0332" stroke="#5C22CF" stroke-linecap="square" />
          </svg>
        </span>
        <a class="primary-color" [routerLink]="'/sales/customers/' + value">{{ value }}</a>
      </div>
    </div>
  `,
})
export class CustomerIdRenderComponent implements AfterViewInit, ViewCell, OnDestroy {
  parent;
  @ViewChild('ref') ref: ElementRef;
  @Input() value: string;
  @Input() rowData: any;
  displayed = 0;
  data = null;
  limit = 3;

  currency = credentials.currency;

  constructor(public apiService: ApiService, public absolutizeUrl: AbsolutizeUrlPipe) {}

  ngAfterViewInit() {
    const tr1 = this.ref.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
    tr1.setAttribute('data-id', this.rowData['ID']);
    if (this.rowData['expanded']) {
      const target = document.querySelector(
        ".customer-switcher[data-id='" + this.rowData.ID + "'][data-expanded='true']"
      );
      if (target) {
        const tr = target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
        let tr2 = document.querySelector(".customer-row-expended[data-id='" + this.rowData.ID + "']");
        if (!tr2) {
          tr2 = document.createElement('tr');
          tr2.setAttribute('data-id', this.rowData['ID']);
          tr2.className = 'customer-row-expended';
          const td2 = document.createElement('td');
          td2.setAttribute('colspan', String(tr.children.length));
          td2.style['text-align'] = 'left';
          const div = document.querySelector(
            ".customers-expanded .customer-expanded[data-id='" + this.rowData.ID + "']"
          );
          td2.append(div);
          tr2.append(td2);
          tr.parentNode.insertBefore(tr2, tr.nextSibling);
        } else {
          tr.parentNode.insertBefore(tr2, tr1.nextSibling);
        }
      }
    } else {
      const tr = document.querySelector(".customer-row-expended[data-id='" + this.rowData.ID + "']");
      if (tr) {
        tr.remove();
      }
    }
  }

  ngOnDestroy(): void {}

  toggle() {
    const those = this;
    if (!this.rowData['expanded']) {
      this.parent['parent'].customerIds.push(this.rowData['ID']);
      for (let i = 0; i < those.parent.source.data.length; i++) {
        if (those.parent.source.data[i]['ID'] === those.rowData['ID']) {
          those.parent.source.data[i]['expanded'] = true;
          those.parent.source.local = true;
          those.parent.source.refresh();
          break;
        }
      }
    } else {
      for (let i = 0; i < this.parent.source.data.length; i++) {
        if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
          this.parent.source.data[i]['expanded'] = false;
          this.parent.source.local = true;
          this.parent.source.refresh();
          break;
        }
      }
    }
  }
}
