import { environment } from '../../environments/environment';

const username = 'admin';
//const password = 'goshop_demo_#Rho3';
//const password = 'goshoppass';
const password = 'goshoppass';

export const credentials = {
  name: 'Salesbit Admin',
  build: 20230619173325,
  releaseNotes:
    `Here may be any content event
  multiline` +
    'with ' +
    'plus sign',
  defaultPort: 18092,
  defaultHttpsPort: 18492,
  authorization: 'Basic ' + btoa(username + ':' + password),
  //apiUrl: 'http://127.0.0.1:18092/api/v1',
  //apiUrl: 'https://shop.servhost.org/api/v1',
  //apiUrl: 'https://dasmoebelhaus.de/api/v1',
  //apiUrl: 'https://staging.dasmoebelhaus.de/api/v1',
  apiUrl: environment.apiUrl,
  editorUrl: environment.editorUrl,
  //apiUrl: 'https://salesbit-server-223mufe5ka-ey.a.run.app/api/v1',
  currency: '€',
  countries: [
    { Code: 'AF', Name: 'Afghanistan', Phone: '+93' },
    {
      Code: 'AL',
      Name: 'Albania',
      Phone: '+355',
    },
    { Code: 'DZ', Name: 'Algeria', Phone: '+213' },
    {
      Code: 'AD',
      Name: 'Andorra',
      Phone: '+376',
    },
    { Code: 'AO', Name: 'Angola', Phone: '+244' },
    {
      Code: 'AG',
      Name: 'Antigua and Barbuda',
      Phone: '+1268',
    },
    { Code: 'AR', Name: 'Argentina', Phone: '+54' },
    {
      Code: 'AM',
      Name: 'Armenia',
      Phone: '+374',
    },
    { Code: 'AU', Name: 'Australia', Phone: '+61' },
    {
      Code: 'AT',
      Name: 'Austria',
      Phone: '+43',
    },
    { Code: 'AZ', Name: 'Azerbaijan', Phone: '+994' },
    {
      Code: 'BS',
      Name: 'Bahamas',
      Phone: '+1242',
    },
    { Code: 'BH', Name: 'Bahrain', Phone: '+973' },
    {
      Code: 'BD',
      Name: 'Bangladesh',
      Phone: '+880',
    },
    { Code: 'BB', Name: 'Barbados', Phone: '+1246' },
    {
      Code: 'BY',
      Name: 'Belarus',
      Phone: '+375',
    },
    { Code: 'BE', Name: 'Belgium', Phone: '+32' },
    {
      Code: 'BZ',
      Name: 'Belize',
      Phone: '+501',
    },
    { Code: 'BJ', Name: 'Benin', Phone: '+229' },
    { Code: 'BT', Name: 'Bhutan', Phone: '+975' },
    {
      Code: 'BO',
      Name: 'Bolivia (Plurinational State of)',
      Phone: '+591',
    },
    { Code: 'BA', Name: 'Bosnia and Herzegovina', Phone: '+387' },
    {
      Code: 'BW',
      Name: 'Botswana',
      Phone: '+267',
    },
    { Code: 'BR', Name: 'Brazil', Phone: '+55' },
    {
      Code: 'BN',
      Name: 'Brunei Darussalam',
      Phone: '+673',
    },
    { Code: 'BG', Name: 'Bulgaria', Phone: '+359' },
    {
      Code: 'BF',
      Name: 'Burkina Faso',
      Phone: '+226',
    },
    { Code: 'BI', Name: 'Burundi', Phone: '+257' },
    {
      Code: 'CV',
      Name: 'Cabo Verde',
      Phone: '+238',
    },
    { Code: 'KH', Name: 'Cambodia', Phone: '+855' },
    {
      Code: 'CM',
      Name: 'Cameroon',
      Phone: '+237',
    },
    { Code: 'CA', Name: 'Canada', Phone: '+1' },
    {
      Code: 'CF',
      Name: 'Central African Republic',
      Phone: '+236',
    },
    { Code: 'TD', Name: 'Chad', Phone: '+235' },
    { Code: 'CL', Name: 'Chile', Phone: '+56' },
    {
      Code: 'CN',
      Name: 'China',
      Phone: '+86',
    },
    { Code: 'CO', Name: 'Colombia', Phone: '+57' },
    {
      Code: 'KM',
      Name: 'Comoros',
      Phone: '+269',
    },
    { Code: 'CG', Name: 'Congo', Phone: '+242' },
    {
      Code: 'CD',
      Name: 'Congo,  Democratic Republic of the',
      Phone: '+243',
    },
    { Code: 'CR', Name: 'Costa Rica', Phone: '+506' },
    {
      Code: 'CI',
      Name: "Côte d'Ivoire",
      Phone: '+225',
    },
    { Code: 'HR', Name: 'Croatia', Phone: '+385' },
    { Code: 'CU', Name: 'Cuba', Phone: '+53' },
    {
      Code: 'CY',
      Name: 'Cyprus',
      Phone: '+357',
    },
    { Code: 'CZ', Name: 'Czechia', Phone: '+420' },
    {
      Code: 'DK',
      Name: 'Denmark',
      Phone: '+45',
    },
    { Code: 'DJ', Name: 'Djibouti', Phone: '+253' },
    {
      Code: 'DM',
      Name: 'Dominica',
      Phone: '+1767',
    },
    { Code: 'DO', Name: 'Dominican Republic', Phone: '+1849' },
    {
      Code: 'EC',
      Name: 'Ecuador',
      Phone: '+593',
    },
    { Code: 'EG', Name: 'Egypt', Phone: '+20' },
    {
      Code: 'SV',
      Name: 'El Salvador',
      Phone: '+503',
    },
    { Code: 'GQ', Name: 'Equatorial Guinea', Phone: '+240' },
    {
      Code: 'ER',
      Name: 'Eritrea',
      Phone: '+291',
    },
    { Code: 'EE', Name: 'Estonia', Phone: '+372' },
    {
      Code: 'SZ',
      Name: 'Eswatini',
      Phone: '+268',
    },
    { Code: 'ET', Name: 'Ethiopia', Phone: '+251' },
    {
      Code: 'FJ',
      Name: 'Fiji',
      Phone: '+679',
    },
    { Code: 'FI', Name: 'Finland', Phone: '+358' },
    {
      Code: 'FR',
      Name: 'France',
      Phone: '+33',
    },
    { Code: 'GA', Name: 'Gabon', Phone: '+241' },
    { Code: 'GM', Name: 'Gambia', Phone: '+220' },
    {
      Code: 'GE',
      Name: 'Georgia',
      Phone: '+995',
    },
    { Code: 'DE', Name: 'Germany', Phone: '+49' },
    { Code: 'GH', Name: 'Ghana', Phone: '+233' },
    {
      Code: 'GR',
      Name: 'Greece',
      Phone: '+30',
    },
    { Code: 'GD', Name: 'Grenada', Phone: '+1473' },
    {
      Code: 'GT',
      Name: 'Guatemala',
      Phone: '+502',
    },
    { Code: 'GN', Name: 'Guinea', Phone: '+224' },
    {
      Code: 'GW',
      Name: 'Guinea-Bissau',
      Phone: '+245',
    },
    { Code: 'GY', Name: 'Guyana', Phone: '+595' },
    { Code: 'HT', Name: 'Haiti', Phone: '+509' },
    {
      Code: 'HN',
      Name: 'Honduras',
      Phone: '+504',
    },
    { Code: 'HU', Name: 'Hungary', Phone: '+36' },
    {
      Code: 'IS',
      Name: 'Iceland',
      Phone: '+354',
    },
    { Code: 'IN', Name: 'India', Phone: '+91' },
    {
      Code: 'ID',
      Name: 'Indonesia',
      Phone: '+62',
    },
    { Code: 'IR', Name: 'Iran (Islamic Republic of)', Phone: '+98' },
    {
      Code: 'IQ',
      Name: 'Iraq',
      Phone: '+964',
    },
    { Code: 'IE', Name: 'Ireland', Phone: '+353' },
    {
      Code: 'IL',
      Name: 'Israel',
      Phone: '+972',
    },
    { Code: 'IT', Name: 'Italy', Phone: '+39' },
    {
      Code: 'JM',
      Name: 'Jamaica',
      Phone: '+1876',
    },
    { Code: 'JP', Name: 'Japan', Phone: '+81' },
    { Code: 'JO', Name: 'Jordan', Phone: '+962' },
    {
      Code: 'KZ',
      Name: 'Kazakhstan',
      Phone: '+77',
    },
    { Code: 'KE', Name: 'Kenya', Phone: '+254' },
    {
      Code: 'KI',
      Name: 'Kiribati',
      Phone: '+686',
    },
    { Code: 'KP', Name: "Korea (Democratic People's Republic of)", Phone: '+850' },
    {
      Code: 'KR',
      Name: 'Korea,  Republic of',
      Phone: '+82',
    },
    { Code: 'KW', Name: 'Kuwait', Phone: '+965' },
    {
      Code: 'KG',
      Name: 'Kyrgyzstan',
      Phone: '+996',
    },
    { Code: 'LA', Name: "Lao People's Democratic Republic", Phone: '+856' },
    {
      Code: 'LV',
      Name: 'Latvia',
      Phone: '+371',
    },
    { Code: 'LB', Name: 'Lebanon', Phone: '+961' },
    {
      Code: 'LS',
      Name: 'Lesotho',
      Phone: '+266',
    },
    { Code: 'LR', Name: 'Liberia', Phone: '+231' },
    {
      Code: 'LY',
      Name: 'Libya',
      Phone: '+218',
    },
    { Code: 'LI', Name: 'Liechtenstein', Phone: '+423' },
    {
      Code: 'LT',
      Name: 'Lithuania',
      Phone: '+370',
    },
    { Code: 'LU', Name: 'Luxembourg', Phone: '+352' },
    {
      Code: 'MG',
      Name: 'Madagascar',
      Phone: '+261',
    },
    { Code: 'MW', Name: 'Malawi', Phone: '+265' },
    {
      Code: 'MY',
      Name: 'Malaysia',
      Phone: '+60',
    },
    { Code: 'MV', Name: 'Maldives', Phone: '+960' },
    {
      Code: 'ML',
      Name: 'Mali',
      Phone: '+223',
    },
    { Code: 'MT', Name: 'Malta', Phone: '+356' },
    {
      Code: 'MH',
      Name: 'Marshall Islands',
      Phone: '+692',
    },
    { Code: 'MR', Name: 'Mauritania', Phone: '+222' },
    {
      Code: 'MU',
      Name: 'Mauritius',
      Phone: '+230',
    },
    { Code: 'MX', Name: 'Mexico', Phone: '+52' },
    {
      Code: 'FM',
      Name: 'Micronesia (Federated States of)',
      Phone: '+691',
    },
    { Code: 'MD', Name: 'Moldova,  Republic of', Phone: '+373' },
    {
      Code: 'MC',
      Name: 'Monaco',
      Phone: '+377',
    },
    { Code: 'MN', Name: 'Mongolia', Phone: '+976' },
    {
      Code: 'ME',
      Name: 'Montenegro',
      Phone: '+382',
    },
    { Code: 'MA', Name: 'Morocco', Phone: '+212' },
    {
      Code: 'MZ',
      Name: 'Mozambique',
      Phone: '+258',
    },
    { Code: 'MM', Name: 'Myanmar', Phone: '+95' },
    {
      Code: 'NA',
      Name: 'Namibia',
      Phone: '+264',
    },
    { Code: 'NR', Name: 'Nauru', Phone: '+674' },
    { Code: 'NP', Name: 'Nepal', Phone: '+977' },
    {
      Code: 'NL',
      Name: 'Netherlands',
      Phone: '+31',
    },
    { Code: 'NZ', Name: 'New Zealand', Phone: '+64' },
    {
      Code: 'NI',
      Name: 'Nicaragua',
      Phone: '+505',
    },
    { Code: 'NE', Name: 'Niger', Phone: '+227' },
    {
      Code: 'NG',
      Name: 'Nigeria',
      Phone: '+234',
    },
    { Code: 'MK', Name: 'North Macedonia', Phone: '+389' },
    {
      Code: 'NO',
      Name: 'Norway',
      Phone: '+47',
    },
    { Code: 'OM', Name: 'Oman', Phone: '+968' },
    { Code: 'PK', Name: 'Pakistan', Phone: '+92' },
    {
      Code: 'PW',
      Name: 'Palau',
      Phone: '+680',
    },
    { Code: 'PA', Name: 'Panama', Phone: '+507' },
    {
      Code: 'PG',
      Name: 'Papua New Guinea',
      Phone: '+675',
    },
    { Code: 'PY', Name: 'Paraguay', Phone: '+595' },
    { Code: 'PE', Name: 'Peru', Phone: '+51' },
    {
      Code: 'PH',
      Name: 'Philippines',
      Phone: '+63',
    },
    { Code: 'PL', Name: 'Poland', Phone: '+48' },
    {
      Code: 'PT',
      Name: 'Portugal',
      Phone: '+351',
    },
    { Code: 'QA', Name: 'Qatar', Phone: '+974' },
    { Code: 'RO', Name: 'Romania', Phone: '+40' },
    {
      Code: 'RU',
      Name: 'Russian Federation',
      Phone: '+7',
    },
    { Code: 'RW', Name: 'Rwanda', Phone: '+250' },
    {
      Code: 'KN',
      Name: 'Saint Kitts and Nevis',
      Phone: '+1869',
    },
    { Code: 'LC', Name: 'Saint Lucia', Phone: '+1758' },
    {
      Code: 'VC',
      Name: 'Saint Vincent and the Grenadines',
      Phone: '+1784',
    },
    { Code: 'WS', Name: 'Samoa', Phone: '+685' },
    {
      Code: 'SM',
      Name: 'San Marino',
      Phone: '+378',
    },
    { Code: 'ST', Name: 'Sao Tome and Principe', Phone: '+239' },
    {
      Code: 'SA',
      Name: 'Saudi Arabia',
      Phone: '+966',
    },
    { Code: 'SN', Name: 'Senegal', Phone: '+221' },
    {
      Code: 'RS',
      Name: 'Serbia',
      Phone: '+381',
    },
    { Code: 'SC', Name: 'Seychelles', Phone: '+248' },
    {
      Code: 'SL',
      Name: 'Sierra Leone',
      Phone: '+232',
    },
    { Code: 'SG', Name: 'Singapore', Phone: '+65' },
    {
      Code: 'SK',
      Name: 'Slovakia',
      Phone: '+421',
    },
    { Code: 'SI', Name: 'Slovenia', Phone: '+386' },
    {
      Code: 'SB',
      Name: 'Solomon Islands',
      Phone: '+677',
    },
    { Code: 'SO', Name: 'Somalia', Phone: '+252' },
    {
      Code: 'ZA',
      Name: 'South Africa',
      Phone: '+27',
    },
    { Code: 'SS', Name: 'South Sudan', Phone: '+211' },
    {
      Code: 'ES',
      Name: 'Spain',
      Phone: '+34',
    },
    { Code: 'LK', Name: 'Sri Lanka', Phone: '+94' },
    {
      Code: 'SD',
      Name: 'Sudan',
      Phone: '+249',
    },
    { Code: 'SR', Name: 'Suriname', Phone: '+597' },
    {
      Code: 'SE',
      Name: 'Sweden',
      Phone: '+46',
    },
    { Code: 'CH', Name: 'Switzerland', Phone: '+41' },
    {
      Code: 'SY',
      Name: 'Syrian Arab Republic',
      Phone: '+963',
    },
    { Code: 'TJ', Name: 'Tajikistan', Phone: '+992' },
    {
      Code: 'TZ',
      Name: 'Tanzania,  United Republic of',
      Phone: '+255',
    },
    { Code: 'TH', Name: 'Thailand', Phone: '+66' },
    {
      Code: 'TL',
      Name: 'Timor-Leste',
      Phone: '+670',
    },
    { Code: 'TG', Name: 'Togo', Phone: '+228' },
    { Code: 'TO', Name: 'Tonga', Phone: '+676' },
    {
      Code: 'TT',
      Name: 'Trinidad and Tobago',
      Phone: '+1868',
    },
    { Code: 'TN', Name: 'Tunisia', Phone: '+216' },
    {
      Code: 'TR',
      Name: 'Turkey',
      Phone: '+90',
    },
    { Code: 'TM', Name: 'Turkmenistan', Phone: '+993' },
    {
      Code: 'TV',
      Name: 'Tuvalu',
      Phone: '+688',
    },
    { Code: 'UG', Name: 'Uganda', Phone: '+256' },
    {
      Code: 'UA',
      Name: 'Ukraine',
      Phone: '+380',
    },
    { Code: 'AE', Name: 'United Arab Emirates', Phone: '+971' },
    {
      Code: 'GB',
      Name: 'United Kingdom of Great Britain and Northern Ireland',
      Phone: '+44',
    },
    { Code: 'US', Name: 'United States of America', Phone: '+1' },
    {
      Code: 'UY',
      Name: 'Uruguay',
      Phone: '+598',
    },
    { Code: 'UZ', Name: 'Uzbekistan', Phone: '+998' },
    {
      Code: 'VU',
      Name: 'Vanuatu',
      Phone: '+678',
    },
    { Code: 'VE', Name: 'Venezuela (Bolivarian Republic of)', Phone: '+58' },
    {
      Code: 'VN',
      Name: 'Viet Nam',
      Phone: '+84',
    },
    { Code: 'YE', Name: 'Yemen', Phone: '+967' },
    { Code: 'ZM', Name: 'Zambia', Phone: '+260' },
    {
      Code: 'ZW',
      Name: 'Zimbabwe',
      Phone: '+263',
    },
  ],
  patterns: [
    {
      Name: 'whd',
      Label: 'Width x Height x Depth',
    },
    {
      Name: 'wdh',
      Label: 'Width x Depth x Height',
    },
    {
      Name: 'hwd',
      Label: 'Height x Width x Depth',
    },
    {
      Name: 'hdw',
      Label: 'Depth x Width x Height',
    },
    {
      Name: 'dwh',
      Label: 'Depth x Width x Height',
    },
    {
      Name: 'dhw',
      Label: 'Depth x Height x Width',
    },
  ],
  availabilities: [
    {
      Name: 'available',
      Label: 'Available',
    },
    {
      Name: 'pre-order',
      Label: 'Pre-order',
    },
    {
      Name: 'out of stock',
      Label: 'Out of stock',
    },
  ],
  lengthMeasurementUnits: [
    { Name: 'mm', Label: 'mm' },
    { Name: 'cm', Label: 'cm' },
    { Name: 'm', Label: 'm' },
  ],
  weightMeasurementUnits: [
    { Name: 'g', Label: 'g' },
    { Name: 'kg', Label: 'kg' },
  ],
};
