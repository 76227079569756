import { Component, Input } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../../shared/api.service';

@Component({
  styles: [`
    div>span {
      cursor: pointer;
    }
  `],
  template: `
    <div class="button-group-dropdown" style="text-align: center">
      <button type="button" class="btn btn-status dropdown-toggle" data-toggle="dropdown" [ngClass]="{'status-new': rowData.ShippingStatus == 'new', 'status-pending': rowData.ShippingStatus == 'pending', 'status-manufacturing': rowData.ShippingStatus == 'manufacturing', 'status-shipping': rowData.ShippingStatus == 'shipping', 'status-shipped': ['shipped','shipped partially'].indexOf(rowData.ShippingStatus) >= 0}">
        <span [innerText]="rowData.ShippingStatus ? rowData.ShippingStatus : 'not set' | translate"></span>
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li (click)="patch('new')" translate>
          New
        </li>
        <li (click)="patch('pending')" translate>
          Pending
        </li>
        <li (click)="patch('manufacturing')" translate>
          Manufacturing
        </li>
        <li (click)="patch('shipping')" translate>
          Shipping
        </li>
        <li (click)="patch('shipped partially')" translate>
          Shipped partially
        </li>
        <li (click)="patch('shipped')" translate>
          Shipped
        </li>
      </ul>
    </div>
  `,
})
export class OrderShippingStatusRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  constructor(public apiService: ApiService){  }

  patch(value) {
    this.apiService['patchOrder'](this.rowData['ID'], 'setShippingStatus', {Value: value}).then(resp => {
      for (let i = 0; i < this.parent.source.data.length; i++) {
        if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
          this.parent.source.data[i]['ShippingStatus'] = value;
          this.parent.source.local = true;
          this.parent.source.refresh();
          break;
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }
}
