import { Component, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  template: `
    <div>
      <input type="checkbox" class="checkbox_animated" [(ngModel)]="rowData['Selected']" (ngModelChange)="change()" />
    </div>
  `,
})
export class SelectedRenderComponent implements ViewCell {
  parent;
  @Input() value: string;
  @Input() rowData: any;

  change() {
    if (this.parent && this.parent.onBulkAction) {
      this.parent.onBulkAction('click', this.rowData['ID']);
    }

    const those = this;
    setTimeout(() => {
      if (this.parent && this.parent.bulk) {
        this.parent.bulk('click');
      }
    }, 100);
  }
}
