import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../shared/api.service';
import { SubjectService } from '../../../shared/subject.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AbsolutizeUrlPipe } from '../../../shared/utils.pipe';
import { credentials } from '../../../shared/credentials.data';

@Component({
  selector: 'app-block-order',
  styles: [
    `
      .label2::after {
        content: ': ';
      }
    `,
  ],
  template: `
    <div class="order-container ref" style="position: relative" #ref>
      <div *ngIf="order">
        <div
          *ngFor="let type of ['Billing', 'Shipping']; let i = index"
          class="row"
          style="border-bottom: 3px solid white; padding: 10px 0;">
          <div class="col">
            <div class="copy">
              <h2 style="display: inline-block" [innerText]="type + ' profile' | translate"></h2>
              <span class="copy-btn" (click)="copyProfile(order, type)"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div class="cursor-pointer primary-color" (click)="editor.onEdit(editor.content, id)">Edit</div>
          </div>
          <div class="col">
            <div style="display: flex; gap: 4px">
              <div>
                <img
                  [attr.src]="'/assets/fonts/flag-icon/' + order[type + 'ProfileCountry'] + '.svg'"
                  style="width: 22px;height: 14px;" />
              </div>
              <div>{{ order[type + 'ProfileCountryName'] }}</div>
            </div>
            <div *ngIf="type === 'Billing'" [ngSwitch]="order['BillingProfileMethod']">
              <div *ngSwitchCase="'creditcard'" style="display: flex; gap: 4px; align-items: center">
                <div style="height: 18px;">
                  <svg width="22" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 0C0.895431 0 0 0.89543 0 2V4H25V2C25 0.895431 24.1046 0 23 0H2ZM25 7H0V13C0 14.1046 0.89543 15 2 15H23C24.1046 15 25 14.1046 25 13V7Z"
                      fill="#ADB5BD" />
                  </svg>
                </div>
                <div style="text-transform: capitalize;">{{ order['BillingProfileMethod'] }}</div>
              </div>
              <div *ngSwitchDefault>{{ order['BillingProfileMethod'] }}</div>
            </div>
            <div *ngIf="type === 'Shipping'">
              <div *ngIf="order.ShippingProfileTransport">{{ order.ShippingProfileTransport }}</div>
              <div *ngIf="order.ShippingProfileServices">{{ order.ShippingProfileServices }}</div>
              <div *ngIf="order.ShippingProfileAddressLine">
                <a
                  href="https://www.google.com/maps/place/{{ order.ShippingProfileAddressLine }}"
                  class="primary-color"
                  target="_blank"
                  translate
                  >View on map <i class="fa fa-external-link" aria-hidden="true"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileName'] }} {{ order[type + 'ProfileLastname'] }}</span>
              <span
                class="copy-btn"
                (click)="copyText(order[type + 'ProfileName'] + ' ' + order[type + 'ProfileLastname'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div *ngIf="order[type + 'ProfileCompany']" class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileCompany'] }}</span>
              <span class="copy-btn" (click)="copyText(order[type + 'ProfileCompany'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileAddress'] }}</span>
              <span class="copy-btn" (click)="copyText(order[type + 'ProfileAddress'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileZip'] }} {{ order[type + 'ProfileCity'] }}</span>
              <span class="copy-btn" (click)="copyText(order[type + 'ProfileZip'] + ' ' + order[type + 'ProfileCity'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileCountryName'] }}</span>
              <span class="copy-btn" (click)="copyText(order[type + 'ProfileCountryName'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
          </div>
          <div class="col">
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfilePhone'] }}</span
              ><span class="copy-btn" (click)="copyText(order[type + 'ProfilePhone'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
            <div class="copy">
              <span class="dark-gray">{{ order[type + 'ProfileEmail'] }}</span
              ><span class="copy-btn" (click)="copyText(order[type + 'ProfileEmail'])"
                ><i class="fa fa-clipboard" aria-hidden="true"></i
              ></span>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <div *ngIf="order.Description && order.Description.Items" class="row" style="padding: 10px 0;">
          <div class="col-xs-3 col-md-2">
            <h2 style="display: inline-block" translate>Items</h2>
          </div>
          <div class="col-xs-9 col-md-10">
            <div class="row" style="padding-bottom: 10px;">
              <div *ngFor="let item of order.Description.Items" class="col-xl-3 col-lg-3 col-md-4">
                <div class="card2">
                  <div style="border-bottom: 1px solid lightgray; margin-bottom: 5px; padding-bottom: 10px">
                    <div
                      *ngIf="item['Thumbnail']"
                      [style]="{
                        backgroundImage:
                          'url(' +
                          absolutizeUrl.transform(
                            (item['Thumbnail']
                              ? item['Thumbnail']
                              : '/api/v1/resize?path=/assets/images/no-image.svg') + ')'
                          ),
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        display: 'inline-block',
                        minHeight: '150px',
                        width: '100%',
                        marginRight: '10px',
                        verticalAlign: 'middle'
                      }"></div>
                  </div>
                  <div style="padding-bottom: 10px">
                    <span>{{ item['Quantity'] }}</span> x
                    <a href="{{ item['Path'] | absolutizeUrl }}" class="primary-color" target="_blank">{{
                      item['Title']
                    }}</a>
                  </div>
                  <div
                    *ngIf="
                      item['Variation'] &&
                      item['Variation']['Name'] &&
                      item['Variation']['Name'] != 'default' &&
                      item['Variation']['Title']
                    ">
                    <span class="label2 dark-gray" translate>Variation</span
                    ><span>{{ item['Variation']['Title'] }}</span>
                  </div>
                  <div *ngFor="let property of item['Properties']">
                    <span class="label2 dark-gray">{{ property['Title'] }}</span>
                    <span>{{ property['Value'] }}</span>
                  </div>
                  <div *ngIf="item['Total']">
                    <span class="label2 dark-gray" translate>Price</span
                    ><span>{{ currency }} {{ item['Total'].toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card2">
                  <h2 translate>Tags</h2>
                  <div>
                    <app-tag-input
                      [(ngModel)]="order.Tags"
                      (ngModelChange)="patchOrder(order.ID, 'setTags', { Values: $event })"></app-tag-input>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card2 grand-total">
                  <div style="border-bottom: 1px solid lightgray; margin-bottom: 10px; padding-bottom: 10px;">
                    <div style="display: flex;justify-content: space-between;">
                      <span translate>Billing Status</span><span [innerHtml]="order.BillingStatus | translate"></span>
                    </div>
                    <div style="display: flex;justify-content: space-between;">
                      <span translate>Shipping Status</span><span [innerHtml]="order.ShippingStatus | translate"></span>
                    </div>
                    <div style="display: flex;justify-content: space-between;">
                      <span translate>Subtotal</span><span>{{ currency }} {{ order.Sum.toFixed(2) }}</span>
                    </div>
                    <div *ngFor="let discount of order.Discounts" style="display: flex;justify-content: space-between;">
                      <span translate>Coupon #{{ discount.Coupon.ID }} {{ discount.Coupon.Code }}</span
                      ><span>{{ currency }} -{{ discount.Value.toFixed(2) }}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;">
                      <span translate>Shipping</span>
                      <span *ngIf="order.Delivery">{{ currency }} {{ order.Delivery.toFixed(2) }}</span>
                      <span *ngIf="!order.Delivery" style="text-align: right">FREE SHIPPING</span>
                    </div>
                    <div *ngIf="order.VAT" style="display: flex;justify-content: space-between;">
                      <span translate>Tax</span
                      ><span>{{ currency }} {{ (order.Total * (order.VAT / 100)).toFixed(2) }}</span>
                    </div>
                  </div>
                  <div style="font-size: larger;display: flex;justify-content: space-between;">
                    <span translate>Grand Total</span><span>{{ currency }} {{ order.Total.toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!order" style="text-align: center">
        <img src="assets/images/loading.svg" alt="Loading..." style="width: 100px" />
      </div>
    </div>
  `,
})
export class OrderBlockComponent implements OnInit {
  @Input('id') id;
  @Input('author') author;
  @Input('editor') editor;
  order;
  countries = credentials.countries;
  currency = credentials.currency;
  limit = 3;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiService: ApiService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private toastr: ToastrService,
    public absolutizeUrl: AbsolutizeUrlPipe
  ) {}

  ngOnInit(): void {
    const those = this;
    this.apiService
      .getOrder(this.id)
      .then(order => {
        those.order = order;
        if (those.order.BillingProfileCountry) {
          const country = this.countries.find(
            item => those.order.BillingProfileCountry && item.Code === those.order.BillingProfileCountry.toUpperCase()
          );
          if (country) {
            those.order.BillingProfileCountryName = country.Name;
          }
        }
        if (those.order.ShippingProfileCountry) {
          const country = this.countries.find(
            item => those.order.ShippingProfileCountry && item.Code === those.order.ShippingProfileCountry.toUpperCase()
          );
          if (country) {
            those.order.ShippingProfileCountryName = country.Name;
            those.order.ShippingProfileAddressLine =
              those.order.ShippingProfileAddress +
              ', ' +
              those.order.ShippingProfileZip +
              ' ' +
              those.order.ShippingProfileCity +
              ', ' +
              country.Name;
            those.order.ShippingProfileAddressLine = those.order.ShippingProfileAddressLine.replace(/\//g, ' ').replace(
              /\s+/g,
              '+'
            );
          }
        }
        if (those.order.Description && those.order.Description.Items) {
          for (let i = 0; i < those.order.Description.Items.length; i++) {
            const thumbnails = those.order.Description.Items[i].Thumbnail.split(',');
            those.order.Description.Items[i].Thumbnail = thumbnails.length > 1 ? thumbnails[1] : thumbnails[0];
          }
        }
      })
      .catch(err => {
        those.error('get order', err);
      });
  }

  success(title, resp) {
    let message = 'OK';
    if (resp['MESSAGE']) {
      message = resp['MESSAGE'];
    }
    this.toastr.success(message, title);
  }

  error(title, err) {
    let message = 'Something went wrong';
    if (err.status === 404) {
      message = 'Not Found';
    } else if (err.status === 500 && err.error && err.error['ERROR']) {
      message = err.error['ERROR'];
    }
    this.toastr.error(message, title, { closeButton: true, timeOut: 15000 });
  }

  copyProfile(order, type) {
    this.copyText(
      order[type + 'ProfileName'] +
        ' ' +
        order[type + 'ProfileLastname'] +
        '\n' +
        order[type + 'ProfileAddress'] +
        '\n' +
        order[type + 'ProfileZip'] +
        ' ' +
        order[type + 'ProfileCity'] +
        '\n' +
        order[type + 'ProfilePhone'] +
        '\n' +
        order[type + 'ProfileEmail']
    );
  }

  copyText(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copied to clipboard', 'Clipboard');
  }

  patchOrder(id, action, raw) {
    const those = this;
    this.apiService
      .patchOrder(id, action, raw)
      .then(resp => {})
      .catch(err => {
        those.error('patch order', err);
      });
  }
}
