import { Component, Input, OnInit } from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {Router} from '@angular/router';
import {credentials} from '../../../shared/credentials.data';

@Component({
  template: `
      <span>{{currency}}{{displayed}}</span>
  `,
})
export class TransactionAmountRenderComponent implements OnInit, ViewCell {
  currency = credentials.currency;
  displayed;
  @Input() value: string;
  @Input() rowData: any;

  ngOnInit(): void {
    this.displayed = parseFloat(this.value).toFixed(2);
  }
}
