import {AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ViewCell} from 'ng2-smart-table';
import {ApiService} from '../../../shared/api.service';

@Component({
  styles: [`.editable{
    min-width: 75px
  }
  .center {
    text-align: center;
  }
  .form-control{
    background-color: white;
    min-width: 100px
  }
  `],
  template: `
    <div class="editable" [attr.data-id]="rowData['ID']" [attr.data-name]="name" #wrapper>
      <div [ngClass]="{center: type === 'checkbox'}">
        <input *ngIf="type==='currency'" [attr.id]="'prices-' + rowData['ID']" type="text" class="form-control" type="text" currencyMask
               (click)="select($event)"
               [options]="{ prefix: prefix, decimal: decimal, thousands: thousands, inputMode: 1, nullable: true }"
               [placeholder]="'0.00'"
               (keydown.enter)="next($event)"
               (keydown.tab)="next($event, 'next')"
               (keydown.arrowUp)="next($event, 'up')"
               (keydown.arrowDown)="next($event, 'down')"
               (focusout)="next($event)"
               [(ngModel)]="temp" [ngModelOptions]="{standalone: true}">
        <input *ngIf="['number', 'text'].indexOf(type) >= 0" [attr.id]="'prices-' + rowData['ID']" type="text" class="form-control" [placeholder]="placeholder"
               (click)="select($event)"
               (keydown.enter)="next($event)"
               (focusout)="next($event)"
               (keydown.tab)="next($event, 'next')"
               (keydown.arrowUp)="next($event, 'up')"
               (keydown.arrowDown)="next($event, 'down')"
               [(ngModel)]="temp" [ngModelOptions]="{standalone: true}">
        <input *ngIf="type==='checkbox'" type="checkbox" class="checkbox_animated" style="margin-left: -15px; width: 0"
               (change)="next($event)"
               (keydown.tab)="next($event)"
               [(ngModel)]="temp" [ngModelOptions]="{standalone: true}">
        <select *ngIf="type==='select'" [attr.id]="'prices-' + rowData['ID']" class="form-control"
                (click)="select($event)"
                (change)="next($event)"
                (keydown.tab)="next($event)"
                [(ngModel)]="temp" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let value of values; index as i" [value]="value.Name">{{value.Label | translate}}</option>
        </select>
      </div>
    </div>
  `,
})
export class PriceEditableRenderComponent implements ViewCell, OnInit, AfterViewInit {
  @Input() value: string;
  @Input() rowData: any;
  @ViewChild('wrapper') wrapper: ElementRef;
  parent;
  name;
  type = 'text';
  values = [];
  placeholder = '';
  decimal = ',';
  prefix = '';
  thousands = '.';
  temp;

  constructor(public apiService: ApiService) {
  }

  ngOnInit() {
    const those = this;
    this.temp = this.value;
    if (this.name === 'Availability') {
      this.type = 'select';
    }
  }

  ngAfterViewInit(): void {
    if (this.wrapper && this.wrapper.nativeElement) {
      const td = this.wrapper.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      if (td) {
        //td.style.backgroundColor = '#F4F5F9';
        td.style.border = '1px solid #D0D4E4';
      }
    }
  }

  select(event) {
    if (event && event.target && event.target['select']) {
      setTimeout(() => {
        event.target['select']();
      }, 100);
    }
  }

  accept() {
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (this.parent.source.data[i][this.name] !== this.temp) {
          this.parent.form.get('Prices').markAsDirty();
        }
        this.parent.source.data[i][this.name] = this.temp;
        this.parent.source.data[i][this.name + 'Edit'] = false;
        this.parent.source.data[i]['Changed'] = true;
        // this.parent.source.local = true;
        // this.parent.source.refresh();
        break;
      }
    }
  }

  next(event, direction?: string) {
    let td = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    let tr = td.parentElement;
    let div;
    let shift = 0;
    if (direction === 'up') {
      const tbody = tr.parentElement;
      const index = Array.from(tbody.children).indexOf(tr);
      if (index > 0) {
        const tr2 = tbody.children[index - 1];
        const index2 = Array.from(tr.children).indexOf(td);
        if (index2 >= 0) {
          div = tr2 ? tr2.children[index2].querySelector('.editable') : false;
          shift--;
        }
      }
    } else if (direction === 'down') {
      if (tr && tr.nextSibling) {
        const index = Array.from(tr.children).indexOf(td);
        if (index >= 0) {
          div = tr.nextSibling && tr.nextSibling.children[index] ? tr.nextSibling.children[index].querySelector('.editable') : false;
          shift ++;
        }
      }
    } else {
      div = td.nextSibling && td.nextSibling['querySelector'] ? td.nextSibling.querySelector('.editable') : false;
    }
    while (!div && td && tr) {
      if (td.nextSibling) {
        td = td.nextSibling;
      } else {
        tr = tr.nextSibling;
        shift ++;
        td = tr.firstChild;
      }
      if (td && td.nextSibling && td.nextSibling['querySelector']) {
        div = td.nextSibling.querySelector('.editable');
      }
    }
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        if (this.parent.source.data[i][this.name] !== this.temp) {
          this.parent.form.get('Prices').markAsDirty();
        }
        this.parent.source.data[i][this.name] = this.temp;
        this.parent.source.data[i]['Changed'] = true;
        if (div && ['up', 'down', 'next'].indexOf(direction) >= 0) {
          const id = div.getAttribute('data-id');
          const name = div.getAttribute('data-name');
          const input = document.querySelector('[data-id="' + id + '"][data-name="' + name + '"]>div>*');
          if (input) {
            if (input['click']) {
              input['click']();
            }
            if (input['select']) {
              setTimeout(() => {
                input['select']();
              }, 100);
            }
          }
        }
        break;
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }

  cancel(event){
    for (let i = 0; i < this.parent.source.data.length; i++) {
      if (this.parent.source.data[i]['ID'] === this.rowData['ID']) {
        this.temp = this.parent.source.data[i][this.name];
      }
    }
  }
}
